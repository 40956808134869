.profile-content {
	.table-heading {
		padding: 30px 30px 0 30px;
		background: #333;
		position: relative;
		/*z-index: 3;*/

		@media (max-width: 992px) {
			padding: 30px 15px 0;
		}

		@media (max-width: 992px) {
			padding: 10px 10px 0;
		}

		.information {
			padding: 0 15px 30px;
			-webkit-align-items: center;
	    -ms-flex-align: center;
	    align-items: center;
	    margin: 0;

	    @media (max-width: 992px) {
	    	padding: 10px;
	    }

	    @media (max-width: 768px) {
	    	padding: 10px 0;
	    	margin: 0;
	    }

			.avatar-block {
				display: table;
				max-width: 90px;
				height: 90px;
				-webkit-border-radius: 200px;
				-moz-border-radius: 200px;
				border-radius: 200px;
				overflow: hidden;

				.avatar {
					display: table-cell;
					vertical-align: middle;
				}

				img {
					width: 100%;
					object-fit: cover;
					-webkit-border-radius: 200px;
					-moz-border-radius: 200px;
					border-radius: 200px;
				}
			}

			.info-block {
				.name {
					display: inline-block;
			    position: relative;
			    font-size: 16px;
			    font-weight: 600;
			    text-align: left;
			    color: #fff;

			    @media (max-width: 1199px) {
			    	display: block;
			    	margin-bottom: 5px;
			    }

			    @media (max-width: 992px) {
			    	display: inline-block;
			    }
				}

				.edit-contact-link {
					position: relative;
					display: inline-block;
					text-transform: uppercase;
					font-size: 13px;
					font-weight: 600;
					color: #fff;
					padding-left: 25px;
					height: 20px;

					@media (max-width: 1199px) {
						margin-left: 0;
					}

					@media (max-width: 768px) {
						position: relative;
						top: 5px;
					}

					.icon {
						left: 0;
						margin-left: 0;
						-webkit-transition: all .3s;
						   -moz-transition: all .3s;
						    -ms-transition: all .3s;
						     -o-transition: all .3s;
						        transition: all .3s;
					}

					&:hover {
						color: #4caf50;

						.icon {
							background: url('../img/icons/@2x/icon-edit--green@2x.png') 0 0 no-repeat;
					    -webkit-background-size: auto 100%;
					    -moz-background-size: auto 100%;
					    background-size: auto 100%;
						}
					}

					.ks-text {
						@media (max-width: 768px) {
							display: none;
						}
					}
				}

				.trash-link {
					position: relative;
					display: inline-block;
					text-transform: uppercase;
					font-size: 13px;
					font-weight: 600;
					color: #fff;
					margin-left: 15px;
					padding-left: 25px;
					height: 20px;

					@media (max-width: 768px) {
						margin-left: 10px;
						position: relative;
						top: 5px;
					}

					.icon {
						left: 0;
						margin-left: 0;
						-webkit-transition: all .3s;
						   -moz-transition: all .3s;
						    -ms-transition: all .3s;
						     -o-transition: all .3s;
						        transition: all .3s;
					}

					&:hover {
						color: #ff5722;

						.icon {
							background: url('../img/icons/@2x/icon-delete--red@2x.png') 0 0 no-repeat;
					    -webkit-background-size: auto 100%;
					    -moz-background-size: auto 100%;
					    background-size: auto 100%;
						}
					}

					.ks-text {
						@media (max-width: 768px) {
							display: none;
						}
					}
				}

				.address {
					color: #a3a3a3;
					font-size: 12px;
					line-height: 1em;
					margin: 15px 0;
				}
			}

			.contact-block {
				display: -ms-flexbox;
		    display: -webkit-flex;
		    display: flex;
		    -webkit-align-items: center;
		    -ms-flex-align: center;
		    align-items: center;
		    -webkit-justify-content: center;
		    -ms-flex-pack: center;
		    justify-content: center;

		    @media (max-width: 1200px) {
		    	-webkit-flex-direction: column;
			    -ms-flex-direction: column;
			    flex-direction: column;
		    }

				@media (max-width: 992px) {
					margin-top: 10px;
				}

		    .phone-block,
		    .email-block {
			    display: inline-block;
			    width: 49%;
			    position: relative;
			    padding: 5px 10px 5px 30px;
			    cursor: pointer;
			    height: 26px;
			    background: transparent;
			    color: #000;
			    margin-bottom: 2px;
			    -webkit-transition: all .3s;
			    -moz-transition: all .3s;
			    -ms-transition: all .3s;
			    -o-transition: all .3s;
			    transition: all .3s;
			    opacity: .8;

			    @media (max-width: 1200px) {
			    	width: 100%;
			    	display: block;
			    	height: auto;
			    }

			    @media (max-width: 768px) {
			    	font-size: 11px;
			    }

			    & > .icon {
			    	top: 11px;
			    	left: 0;
			    	margin-left: 0;
			    	margin-top: 0;
			    	opacity: 0.5;

			    	&.icon-mail-white {
			    		top: 8px;
			    	}
			    }

			    .ks-text {
			    	display: block;
			    	word-break: break-all;
			    	word-break:normal;
			    	color: #fff;
			    	line-height: 1.2em;
			    	margin-bottom: 10px;

			    	@media (max-width: 1200px) {
			    		margin-bottom: 0;
			    	}

			    	&:last-child {
			    		margin-bottom: 0;
			    	}
			    }

			    .open-popover {
			    	position: relative;
			    	width: 100%;
			    	height: 10px;

			    	@media (max-width: 1200px) {
			    		margin-top: 5px;
			    	}

			    	.copy-text {
			    		display: block;
			    		height: 10px;
			    	}

			    	.icon {
			    		left: 4px;
			    		margin-left: 0;
			    		-webkit-transform: rotate(90deg);
			    		   -moz-transform: rotate(90deg);
			    		    -ms-transform: rotate(90deg);
			    		     -o-transform: rotate(90deg);
			    		        transform: rotate(90deg);
			    	}

			    	.popover-content {
			    		display: none;
			    	}
			    }
		    }

		    .phone-block {
		    	& > .icon {
		    		width: 16px;
		    		height: 16px;
		    		-webkit-background-size: 100% auto;
		    		-moz-background-size: 100% auto;
		    		background-size: 100% auto;
		    		margin-top: -8px;
		    	}
		    }
			}

			.price-block {
				display: -ms-flexbox;
		    display: -webkit-flex;
		    display: flex;
		    -webkit-flex-direction: row;
		    -ms-flex-direction: row;
		    flex-direction: row;
		    -webkit-flex-wrap: nowrap;
		    -ms-flex-wrap: nowrap;
		    flex-wrap: nowrap;
		    -webkit-justify-content: flex-start;
		    -ms-flex-pack: start;
		    justify-content: flex-start;
		    -webkit-align-content: stretch;
		    -ms-flex-line-pack: stretch;
		    align-content: stretch;
		    -webkit-align-items: flex-start;
		    -ms-flex-align: start;
		    align-items: flex-start;

		    @media (max-width: 992px) {
					margin-top: 10px;
				}

		    .block {
		    	position: relative;
		    	-webkit-flex: 1 1 100%;
			    -ms-flex: 1 1 100%;
			    flex: 1 1 100%;
			    -webkit-align-self: stretch;
			    -ms-flex-item-align: stretch;
			    align-self: stretch;
			    padding-left: 30px;

			    @media (max-width: 768px) {
			    	padding-left: 15px;
			    }

			    &.amount {
			    	&:after {
				    	position: absolute;
				    	left: 0;
				    	top: 50%;
				    	width: 45px;
				    	bottom: 0;
				    	margin-top: -22.5px;
				    	content: "";
				    	background: url('../img/time--white.png') 0 0 no-repeat;
				    	opacity: 0.1;
				    	-webkit-background-size: auto 100%;
				    	-moz-background-size: auto 100%;
				    	background-size: auto 100%;
				    }
			    }

			    &.price {
			    	&:after {
				    	position: absolute;
				    	left: 0;
				    	top: 50%;
				    	width: 45px;
				    	bottom: 0;
				    	margin-top: -22.5px;
				    	content: "";
				    	background: url('../img/euro--white.png') 0 0 no-repeat;
				    	opacity: 0.1;
				    	-webkit-background-size: auto 100%;
				    	-moz-background-size: auto 100%;
				    	background-size: auto 100%;
				    }
			    }
		    }

		    .title {
		    	display: block;
			    font-size: 12px;
			    line-height: 1.2em;
			    color: #adadad;
			    font-weight: 700;
			    margin-bottom: 10px;

			    @media (max-width: 768px) {
			    	font-size: 11px;
			    }
		    }

		    .value {
		    	display: block;
		    	font-size: 16px;
		    	font-weight: 600;
		    	color: #fff;
		    	line-height: 1em;

		    	@media (max-width: 768px) {
			    	font-size: 12px;
			    }
		    }
			}
		}

		.profile-create-block {
			position: relative;
			padding: 15px;
			background: #fff;
			margin: 0;
			border-radius: 15px 15px 0 0;
			border-bottom: 1px solid #e0e0e0;

			@media (max-width: 992px) {
				padding: 10px;
			}

			@media (max-width: 768px) {
	    	padding: 10px 0;
	    	margin: 0;
	    }

			.create-block {
				position: relative;
				z-index: 2;
			}

			.dropdown-toggle {
				position: relative;
  			width: 100%;
  			background: #4caf50;
  			padding: 12.5px 10px 12.5px 10px;
  			text-align: center;
  			text-transform: uppercase;
  			font-weight: 600;
  			-webkit-border-radius: 15px;
  			-moz-border-radius: 15px;
  			border-radius: 15px;
  			-moz-box-shadow: 0 3px 20px -2px rgba(0,0,0,0.3);
				-webkit-box-shadow: 0 3px 20px -2px rgba(0,0,0,0.3);
				box-shadow: 0 3px 20px -2px rgba(0,0,0,0.3);
				-webkit-transition: all .3s;
				-moz-transition: all .3s;
				transition: all .3s;
				display: -ms-flexbox;
		    display: -webkit-flex;
		    display: flex;
		    -webkit-flex-direction: row;
		    -ms-flex-direction: row;
		    flex-direction: row;
		    -webkit-flex-wrap: nowrap;
		    -ms-flex-wrap: nowrap;
		    flex-wrap: nowrap;
		    -webkit-justify-content: center;
		    -ms-flex-pack: center;
		    justify-content: center;
		    -webkit-align-content: stretch;
		    -ms-flex-line-pack: stretch;
		    align-content: stretch;
		    -webkit-align-items: center;
		    -ms-flex-align: center;
		    align-items: center;

		    @media (max-width: 768px) {
		    	padding: 7px 10px 7px 10px;
		    }
			}

			.dropdown-menu {
			  display: none;
        width: 360px;
			  min-width: 360px;
			  height: 285px;
			  margin: 0;
			  right: 0;
			  left: auto;
			  padding: 20px;
			  border: 0 none;
			  -webkit-border-radius: 15px;
			  -moz-border-radius: 15px;
			  border-radius: 15px;
			  box-shadow: 0 15px 60px rgba(0,0,0,0.16);

			  @media (max-width: 768px) {
			  	width: 100%;
			  	min-width: auto;
			  	max-width: 360px;
			  }

			  &.show {
			  	display: -webkit-box;
			    display: -ms-flexbox;
			    display: flex;
			  }

		    .ks-wrapper {
    	    display: -webkit-box;
			    display: -webkit-flex;
			    display: -ms-flexbox;
			    -js-display: flex;
			    display: flex;
			    -webkit-flex-wrap: wrap;
			    -ms-flex-wrap: wrap;
			    flex-wrap: wrap;
		    }

		    .ks-create-new {
		    	position: relative;
    	    display: -webkit-box;
			    display: -webkit-flex;
			    display: -ms-flexbox;
			    -js-display: flex;
			    display: flex;
			    -webkit-box-align: center;
			    -webkit-align-items: center;
			    -ms-flex-align: center;
			    align-items: center;
			    height: 50px;
			    width: 160px;
			    list-style: none;
			    font-weight: 600;
			    padding: 0 24px;
			    -moz-transition: all 0.3s;
			    -o-transition: all 0.3s;
			    -webkit-transition: all 0.3s;
			    transition: all 0.3s;
    	    color: #333;

    	    @media (max-width: 768px) {
    	    	width: 50%;
    	    	padding-right: 0;
    	    }


			    .ks-text {
				    margin-left: 20px;
				    font-weight: 400;
			    }

			    .icon {
			    	left: 25px;
			    	-webkit-transition: all .3s;
			    	   -moz-transition: all .3s;
			    	    -ms-transition: all .3s;
			    	     -o-transition: all .3s;
			    	        transition: all .3s;
			    }

			    &:hover {
		    		color: #4caf50;

			    	.icon {
			    		&.icon-task {
			    			background: url('../img/icons/@2x/icon-tick-border--green@2x.png') 0 0 no-repeat;
			    			-webkit-background-size: auto 100%;
			    			-moz-background-size: auto 100%;
			    			background-size: auto 100%;
			    		}

			    		&.icon-file {
			    			background: url('../img/icons/@2x/icon-file--green@2x.png') 0 0 no-repeat;
			    			-webkit-background-size: auto 100%;
			    			-moz-background-size: auto 100%;
			    			background-size: auto 100%;
			    		}

			    		&.icon-meeting {
			    			background: url('../img/icons/@2x/icon-meeting--green@2x.png') 0 0 no-repeat;
			    			-webkit-background-size: auto 100%;
			    			-moz-background-size: auto 100%;
			    			background-size: auto 100%;
			    		}

			    		&.icon-case {
			    			background: url('../img/icons/@2x/icon-case--green@2x.png') 0 0 no-repeat;
			    			-webkit-background-size: auto 100%;
			    			-moz-background-size: auto 100%;
			    			background-size: auto 100%;
			    		}

			    		&.icon-deadline {
			    			background: url('../img/icons/@2x/icon-calendar--green@2x.png') 0 0 no-repeat;
			    			-webkit-background-size: auto 100%;
			    			-moz-background-size: auto 100%;
			    			background-size: auto 100%;
			    		}

			    		&.icon-invoice {
			    			background: url('../img/icons/@2x/icon-invoice--green@2x.png') 0 0 no-repeat;
			    			-webkit-background-size: auto 100%;
			    			-moz-background-size: auto 100%;
			    			background-size: auto 100%;
			    		}

			    		&.icon-time-entry {
			    			background: url('../img/icons/@2x/icon-time--green@2x.png') 0 0 no-repeat;
			    			-webkit-background-size: auto 100%;
			    			-moz-background-size: auto 100%;
			    			background-size: auto 100%;
			    		}

			    		&.icon-expense {
			    			background: url('../img/icons/@2x/icon-expense--green@2x.png') 0 0 no-repeat;
			    			-webkit-background-size: auto 100%;
			    			-moz-background-size: auto 100%;
			    			background-size: auto 100%;
			    		}

			    		&.icon-message {
			    			background: url('../img/icons/@2x/icon-email--green@2x.png') 0 0 no-repeat;
			    			-webkit-background-size: auto 100%;
			    			-moz-background-size: auto 100%;
			    			background-size: auto 100%;
			    		}

			    		&.icon-edit {
			    			background: url('../img/icons/@2x/icon-edit--green@2x.png') 0 0 no-repeat;
			    			-webkit-background-size: auto 100%;
			    			-moz-background-size: auto 100%;
			    			background-size: auto 100%;
			    		}
			    	}
			    }
		    }
			}
		}

		.similar-nav-block {
			position: relative;
			margin-left: -20%;

			@media (max-width: 1199px) {
				margin-left: 0;
				text-align: center;
			}

			ul {
				display: -ms-flexbox;
		    display: -webkit-flex;
		    display: flex;
		    -webkit-flex-direction: row;
		    -ms-flex-direction: row;
		    flex-direction: row;
		    -webkit-flex-wrap: nowrap;
		    -ms-flex-wrap: nowrap;
		    flex-wrap: nowrap;
		    -webkit-justify-content: center;
		    -ms-flex-pack: center;
		    justify-content: center;
		    -webkit-align-content: flex-start;
		    -ms-flex-line-pack: start;
		    align-content: flex-start;
		    -webkit-align-items: center;
		    -ms-flex-align: center;
		    align-items: center;

		    @media (max-width: 768px) {
		    	display: block;
		    	overflow: hidden;
		    	overflow-x: auto;
		    }

		    &::-webkit-scrollbar {
					height: 10px;
					background-color: #F5F5F5;
				}

		    &::-webkit-scrollbar-track {
				  border-radius: 10px;
				  background: rgba(0,0,0,0.1);
				  border: 1px solid #ccc;
				}

				&::-webkit-scrollbar-thumb {
				  border-radius: 10px;
				  background: linear-gradient(to left, #fff, #e4e4e4);
				  border: 1px solid #aaa;
				}

				&::-webkit-scrollbar-thumb:hover {
				  background: #fff;
				}

		    li {
		    	padding: 0 25px;

		    	@media (max-width: 1199px) {
		    		padding: 0 20px;
		    	}

		    	@media (max-width: 992px) {
		    		padding: 0 7px;
		    	}

		    	@media (max-width: 768px) {
		    		display: table-cell;
		    	}

		    	&:hover,
		    	&.active {
		    		a {
		    			color: #ff5722;
		    		}
		    	}
		    }

		    a {
		    	color: #d3d3d3;
		    	font-weight: 600;
		    	line-height: 45px;
		    }
			}
		}
	}
}
