.two-columns{
  display: flex;
  background: #fff;
  @media screen and (max-width: 1023px){
    flex-direction: column;
  }
}

.sign-outer{
  text-align: center;
  background: url("../img/hero-section-bg.png") no-repeat;
  background-size: cover;
  &.in{
    max-width: 500px;
    width: 100%;
    padding: 50px 100px 30px;
  }
  &.up{
    max-width: 719px;
    width: 100%;
    padding: 50px 90px 30px;
    .form-wrapper{
      form .btn-holder{
        text-align: center;
        .btn-secondary{
          margin-left: 30px;
        }
      }
      .select2-container{
        text-align: left;
      }
      .select2-container--default{
        .select2-selection--single{
          height: 60px;
          border-radius: 12px;
          .select2-selection__rendered{line-height: 60px;}
          .select2-selection__arrow{height: 60px;}
        }
      }
    }
    @media screen and (max-width: 1023px){
      .form-wrapper{width: 80%;}
    }
  }
  header{
    margin-bottom: 63px;

    .logo {
      width: 220px;
    }
  }
  .text-wrapper{
    margin-bottom: 45px;
    color: #9ca3ce;
    .title{
      font-size: 30px;
      line-height: 1;
      color: #fff;
      font-weight: 700;
      margin-bottom: 15px;
    }
    .sub-title{
      font-size: 16px;
      line-height: 1.88;
    }
  }
  .form-wrapper{
    form{
      .form-group{
        margin-bottom: 45px;
        position: relative;
        &.error{
          .error-state{
            opacity: 1;
          }
        }
      }
      .error-state{
        position: absolute;
        border-radius: 12px;
        background-color: #e44646;
        height: 92px;
        top: -30px;
        left: -2px;
        right: -2px;
        padding: 5px 0 0 18px;
        font-size: 12px;
        color: #fff;
        text-align: left;
        opacity: 0;
        transition: opacity 0.5s;
      }
      .form-control{
        height: 60px;
        border-radius: 12px;
        position: relative;
        z-index: 1;
        &.email{
          background: #fff url("../img/icons/icon-input-email-grey.svg") no-repeat 95% 50%;
          background-size: 20px 23px;
        }
        &.password{
          background: #fff url("../img/icons/icon-input-password-grey.svg") no-repeat 95% 50%;
          background-size: 20px 23px;
        }
        &.company{
          background: #fff url("../img/icons/icon-input-company.svg") no-repeat 95% 50%;
          background-size: 20px 23px;
        }
        &:focus{
          box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);
          border: solid 1px #ffdd0d;
        }
      }
      .ks-forgot-password{
        margin-bottom: 30px;
        text-align: left;
        justify-content: space-between;
        align-items: center;
        .custom-checkbox{
          font-size: 12px;
          letter-spacing: 0.4px;
          color: #9ca3ce;
          padding-left: 2.3rem;
          &:hover{
            .custom-control-indicator{
                border-color: #fff;
            }
          }
        }
        .custom-control-indicator{
          background-color: transparent;
          width: 20px;
          height: 20px;
          border-radius: 6px;
          border: solid 1px #9ca3ce;
          &:before{
            background: url("../img/icons/icon-tick--white.png") no-repeat 3px 1px;
          }
        }
        a{
          font-size: 12px;
          font-weight: 700;
        }
      }
      .btn-holder{
        margin-bottom: 60px;
        font-size: 12px;
        color: #9ca3ce;
        text-align: left;
        .btn-submit{
          margin-bottom: 30px;
          font-size: 14px;
          text-transform: uppercase;
          font-weight: 700;
          letter-spacing: 0.8px;
          color: #2b2430;
          border-radius: 12px;
          border: 0;
          background: linear-gradient(to bottom, #ffde0e, #ffaa00);
          box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.16);
          transition: all 0.5s;
          &.large{
            padding: 21px 12px;
            width: 300px;
          }
          &:hover{
            background: linear-gradient(to bottom, #ffaa00, #ffde0e);
            box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.25);
          }
        }
        .btn-secondary{
          width: 94px;
          padding: 15px 10px;
          border:0;
          border-radius: 12px;
          background-color: #3c4577;
          box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.16);
          font-size: 12px;
          font-weight: 700;
          text-transform: uppercase;
          letter-spacing: 0.4px;
          text-align: center;
          color: #fff;
        }
      }
      .condition-text{
        margin: 30px 0;
        line-height: 1.25;
        text-align: center;
        a{
          color: #9ca3ce;
          text-decoration: underline;

          &:hover{
            text-decoration: none;
          }
        }
      }
    }
  }
  footer{
    font-size: 12px;
    color: #9ca3ce;
    .copy-wrapper{margin-bottom: 30px;}
    ul{
      display: flex;
      justify-content: center;
      li{
        margin-right: 12px;
        padding-right: 12px;
        position: relative;
        &:after{
          content: '';
          position: absolute;
          top: 2px;
          right: 0;
          width: 1px;
          height: 15px;
          background-color: #9ca3ce;
        }
        &:last-child{
          margin-right: 0;
          padding-right: 0;
          &:after{display: none;}
        }
      }
      a{
        color: #9ca3ce;
        text-decoration: underline;
        &:hover{text-decoration: none;}
      }
    }
  }
  @media screen and (max-width: 1199px){
    &.in{
      max-width: 400px;
      padding-left: 45px;
      padding-right: 45px;
    }
    &.up{}
  }
  @media screen and (max-width: 1023px){
    &.in{
      max-width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &.up{
      max-width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .form-wrapper{
      width: 45%;
    }
  }
  @media screen and (max-width: 767px){
    .form-wrapper{
      width: 100%;
    }
  }
}

.client-contact-manager-outer{
  background: #fff url("../img/first-section-bg.svg") no-repeat 0 50%;
  width: 100%;
  background-size: 100% 100%;
  padding: 90px 0;
  &.up{
    .media-outer{
      width: 630px;
    }
    .slick-dots{
      bottom: -180px;
    }
    .slick-next,
    .slick-prev{
      bottom: -205px;
    }
    .slick-next{right: 245px;}
    .slick-prev{left: 245px;}
  }
  .media-outer{
    width: 720px;
    height: 405px;
    margin:0 auto ;
    img{
      border-radius: 12px 12px 0 0;
    }
    @media screen and (max-width: 1199px){
      width: 500px;
      &.up{
        .media-outer{width: 410px;}
      }
    }
  }
  .client-contact-manager-title{
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.25;
    color: #000;
  }
  .text-wrapper{
    max-width: 540px;
    margin: 60px auto 0;
    font-size: 18px;
    line-height: 1.67;
    color: #666;
    p{
      margin: 0;
    }
  }
  @media screen and (max-width: 767px){
    display: none;
  }
}

