.calendar-content {
	.breadcrumb {
		display: flex;
		.btn {
	    border-radius: 15px;
  		height: 45px;
  		box-shadow: 0 1px 4px rgba(0,0,0,0.2);
  		background: #fff;
  		border: 0;
  		color: #333 !important;
  		min-width: 45px;
  		margin-right: 15px;

  		&:nth-child(1) {
  			order: 1;
  		}

  		&:nth-child(2) {
  			order: 3;
  		}

  		&:nth-child(3) {
  			order: 2;
  		}

  		&:active,
  		&:hover,
  		&:focus {
  			box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.2) !important;
  			background: none !important;
  			border: 0;
  		}
		}
	}

	.contact-content {
		overflow: visible !important;
	}

	.calendar-content {
		.fc-view {
			& > table {
				min-width: 980px;
				table-layout: fixed;

				.fc-head-container {
					border-top: 0;
				}

				.fc-row {
					&:first-child {
						td {
							border-top: 0;
						}
					}
				}

				td {
					border: 2px solid #ececec;
					position: relative;
					padding: 0;
					transition: all .3s;
					cursor: pointer;


					.fc-day-number {
						float: left;
						color: #999;
						font-weight: 700;
						transition: all .3s;
					}

					.day-body {
						height: 100px;
						background: #fff;
						padding: 30px 5px 5px;
						border: 2px solid transparent;
						transition: all .3s;
						display: -ms-flexbox;
				    display: -webkit-flex;
				    display: flex;
				    -webkit-flex-direction: column;
				    -ms-flex-direction: column;
				    flex-direction: column;
				    -webkit-flex-wrap: nowrap;
				    -ms-flex-wrap: nowrap;
				    flex-wrap: nowrap;
				    -webkit-justify-content: flex-end;
				    -ms-flex-pack: end;
				    justify-content: flex-end;
				    -webkit-align-content: stretch;
				    -ms-flex-line-pack: stretch;
				    align-content: stretch;
				    -webkit-align-items: flex-start;
				    -ms-flex-align: start;
				    align-items: flex-start;

					}

					&:first-child {
						border-left: 0;
					}

					&:last-child {
						border-right: 0;
					}
				}

				.fc-day-top {
					border: 0 !important;
					padding: 5px 0 0 5px;

					&.fc-other-month {
						opacity: 1;

						.fc-day-number {
							color: #cfcfcf;
						}
					}

					&.fc-today {
						.fc-day-number {
							color: #ff5722;
						}
					}
				}

				.fc-day {
					position: relative;

					&:before {
						background: #fff;
						transition: all .3s;
						border: 2px solid transparent;
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 1px;
						content: "";
					}

					&:hover {
						&:before {
							border: 2px solid #ff5722;
						}
					}

					&.fc-today {
						&:before {
							background: #fff6f3;
						}
					}
				}

				.fc-content-skeleton {
					height: 100%;
					padding-bottom: 0;

					tbody {
						position: relative;

					}
				}

				.fc-event-container {

					.fc-content {
						padding-left: 15px;
					}
					
			    .fc-day-grid-event {
			    	position: relative;
		    	  padding: 4px 3px 3px 3px;
				    margin: 2px;
				    background: none;
				    border: 0;

				    &:before {
				    	position: absolute;
				    	top: 50%;
				    	left: 0;
				    	width: 15px;
				    	height: 15px;
				    	margin-top: -7.5px;
				    	content: "";
				    }

			    	&.deadline_type {
			    		&:before {
	    			    background: url(../img/icons/@2x/event-point--red@2x.png) 0 0 no-repeat;
						    width: 15px;
						    height: 15px;
						    -webkit-background-size: auto 100%;
						    background-size: auto 100%;
			    		}
			    	}

			    	&.meeting_type {
			    		&:before {
	    			    background: url(../img/icons/@2x/event-point--cyan@2x.png) 0 0 no-repeat;
						    width: 15px;
						    height: 15px;
						    -webkit-background-size: auto 100%;
						    background-size: auto 100%;
			    		}
			    	}

			    	&.task_type {
			    		&:before {
	    			    background: url(../img/icons/@2x/event-point--yellow@2x.png) 0 0 no-repeat;
						    width: 15px;
						    height: 15px;
						    -webkit-background-size: auto 100%;
						    background-size: auto 100%;
			    		}
			    	}

			    	.fc-title {
			    		color: #000;
			    		font-size: 12px;
			    		text-overflow: ellipsis;
						  overflow: hidden; 
						  width: inherit; 
						  height: 18px;
						  white-space: nowrap;
						  padding-left: 36px;
						  display: block;
			    	}

			    	.fc-time {
			    		position: absolute;
			    		color: #000;
			    	}
			    }
				}

				thead {
					th {
						background: none;
						border: 0;
						color: #666666;
						vertical-align: middle;
						padding: .75rem;
						text-align: left;
					}
				}
			}

			.fc-scroller > .fc-day-grid {
				margin-bottom: 1px;
			}

			.fc-content-skeleton {
				td {
					border: 0 !important;
				}
			}

			.fc-highlight {
				position: relative;
				opacity: 1;
				background: none;

				&:before {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: -1px;
					border: 2px solid #ff5722;
					content: "";
				}
			}
		}
	}
}