.task-priority {
  position: relative;
  display: inline-block;

  &::after {
    position: absolute;
    top: 50%;
    left: 0;
    font-family: "Montserrat",sans-serif;
    font-size: 11px;
    line-height: 20px;
    font-weight: 600;
    color: #fff;
    width: 30px;
    height: 30px;
    text-align: center;
    -webkit-border-radius: 100px;
    border-radius: 100px;
    margin-top: -15px;
    text-indent: 0;
    background: #e6e6e6;
    content: '';
  }

  &.high::after {
    background: #ff5722;
    content: '!!!'
  }

  &.medium::after {
    background: #673ab7;
    content: '!!'
  }

  &.low::after {
    background: #00bcd4;
    content: '!'
  }
}