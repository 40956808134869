.gvin {
	.profile-information {
		min-height: 820px;
	}
}
.notes {
	.profile-information {
		.custom-scroll-table {
			.table {
				min-width: auto !important;

				.checkbox-cell {
					width: 60px !important;
					max-width: 60px !important;
					min-width: 60px !important;
				}

				.action-block {
					&.tippy-active {
						.dropdown-toggle {
							.icon {
								background: url(../img/icons/@2x/icon-menu--white@2x.png) 0 0 no-repeat;
						    background-size: auto 100%;
							}
						}
					}
					.dropdown-toggle {
						height: 44px;

						.ks-info {
							display: block;
							min-height: 20px;
							opacity: .7;
						}
						.icon {
							transition: all .3s;
						}
						&:after {
							display: none;
						}
					}
				}

				th.th,
				td {
					&:last-child {
						min-width: 125px;
					}
				}

				td {
					vertical-align: top !important;
					&.checkbox-cell {
						.custom-checkbox {
							margin-top: 0 !important;
						}
					}
				}

				.name-cell {
					position: relative;
					max-width: auto;
					width: auto;
					min-width: auto;

					.ks-open-details {
						display: block;
						padding-left: 30px;

						&.active {
							.case-name {
								color: #4caf50;
							}
						}
					}

					.icon {
						left: 0;
						margin-left: 0;
					}
				}

				.case-name-cell {
					min-width: 160px;
				}

				.assigned-cell {
					min-width: 180px;
				}

				.description-cell {
			    max-width: 450px;
    			min-width: 250px;
				}

				.attach-cell {
					.inner {
				    display: -ms-flexbox;
				    display: -webkit-flex;
				    display: -webkit-box;
				    display: flex;
				    -webkit-flex-direction: row;
				    -ms-flex-direction: row;
				    -webkit-box-orient: horizontal;
				    -webkit-box-direction: normal;
				    flex-direction: row;
				    -webkit-flex-wrap: wrap;
				    -ms-flex-wrap: wrap;
				    flex-wrap: wrap;
				    -webkit-justify-content: flex-start;
				    -ms-flex-pack: start;
				    -webkit-box-pack: start;
				    justify-content: flex-start;
				    -webkit-align-content: stretch;
				    -ms-flex-line-pack: stretch;
				    align-content: stretch;
				    -webkit-align-items: center;
				    -ms-flex-align: center;
				    -webkit-box-align: center;
				    align-items: center;

				    .btn-file {
				    	-webkit-flex: 1 1 auto;
					    -ms-flex: 1 1 auto;
					    -webkit-box-flex: 1;
					    flex: 1 1 auto;
					    position: relative;
					    padding: 12px 30px 12px 30px;
					    text-align: center;
					    background: none;
					    -webkit-border-radius: 15px;
					    border-radius: 15px;
					    -webkit-transition: all .3s;
					    -o-transition: all .3s;
					    transition: all .3s;
					    display: -ms-flexbox;
					    display: -webkit-flex;
					    display: -webkit-box;
					    display: flex;
					    -webkit-flex-direction: row;
					    -ms-flex-direction: row;
					    -webkit-box-orient: horizontal;
					    -webkit-box-direction: normal;
					    flex-direction: row;
					    -webkit-flex-wrap: nowrap;
					    -ms-flex-wrap: nowrap;
					    flex-wrap: nowrap;
					    -webkit-justify-content: center;
					    -ms-flex-pack: center;
					    -webkit-box-pack: center;
					    justify-content: center;
					    -webkit-align-content: stretch;
					    -ms-flex-line-pack: stretch;
					    align-content: stretch;
					    -webkit-align-items: center;
					    -ms-flex-align: center;
					    -webkit-box-align: center;
					    align-items: center;
					    -webkit-box-flex: 0;
					    -webkit-flex: 0 auto;
					    -ms-flex: 0 auto;
					    flex: 0 auto;
					    font-size: 13px;
					    font-weight: 600;
					    -webkit-box-shadow: 0 2px 2px rgba(0,0,0,0.2);
					    box-shadow: 0 2px 2px rgba(0,0,0,0.2);
					    border: 1px solid #F3F3F3;
					    margin-right: 15px;
					    margin-bottom: 10px;
					    min-height: 45px;

					    &:hover {
					    	box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.2);
					    }
				    }
					}
				}
			}
		}
	}

	.empty-block-notes{
		position: relative;
		max-width:320px;
		margin: 94px auto 0;
		padding-left:45px;
		min-height: 300px;

		.icon {
			top: 10px;
	    background-size: 100% auto;
	    left: 0;
	    width: 30px;
	    height: 30px;
	    opacity: 0.4;
		}

		.ks-text {
			font-size: 13px;
			opacity: 0.4;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.54;
			letter-spacing: normal;
			text-align: left;
			color: #000000;
			float:left;
		}

		.notes_button{
			width:105px;
			margin-top:6px;
			display:block;
			float:right;
			padding-left:0px;
			padding-right:0px;
			padding-top:7px;
			padding-bottom:7px;
			font-size: 12px;
			line-height:16px;
			border-radius: 12px;
			font-weight: 600;
			background: #4caf50;
			text-align: center;
		}
	}
}


