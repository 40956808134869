.ks-contacts .content .pane-content.add-person,
.ks-contacts .content .pane-content.add-person .ks-page-content {
	height: auto;
	min-height: 100%;

	@media (max-width: 768px) {
		padding: 0 5px;
	}
}
.person-form {
	&.add-person {
		.row {
			&.top {
				.btn-back-to {
					color: #000;
				}

				h3 {
					color: #333;
				}
			}
		}
	}
	.row {
		&.top {
			text-align: center;
			margin-bottom: 20px;

			&.top_pad_20{
				padding-bottom:20px;
				margin-bottom:0px;
			}

			@media (max-width: 768px) {
				margin: 0 0 10px;

				&.top_pad_20{
					padding-bottom:10px;
					margin-bottom:0px;
				}
			}

			.btn-back-to {
				position: relative;
				background: none;
				font-size: 12px;
				font-weight: 600;
				padding: 0;
				padding-left: 15px;
				border: 0 none;
				line-height: 31px;
				text-transform: uppercase;
				text-align: left;
				float: left;
				color: #fff;

				@media (max-width: 768px) {
					font-size: 9px;
					font-weight: 700;
					width: 100%;
				}

				.icon {
					left: 0;
					margin-left: 0;
				}
			}

			h3 {
				margin: 0;
				line-height: 30px;
				font-weight: 700;
				text-align: center;
				padding-right: 177px;
				color: #fff;

				@media (max-width: 768px) {
					display: block;
					padding: 0;
					font-size: 14px;
				}
			}
		}
	}

	.form-content {
		display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    // height: 100%;
    margin: 0;
    position: relative;
    background: #fff;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    padding: 10px 0 0;

    @media (max-width: 768px) {
    	padding: 10px 0;
    }

    .container {
    	padding: 0 45px;
    	max-width: 100%;

    	@media (max-width: 1400px) {
    		padding: 0;
    	}
    }

    h4 {
    	margin: 0;
    	padding: 0;
    	font-weight: 600;
    	margin: 30px 0;
    }

    .submit-btn-block {
    	padding: 30px 0;
    	border-top: 1px solid #e6e6e6;
    	margin-top: 40px;

    	.add-extra-info-btn {
    		position: relative;
  			right: 0;
  			width: 100%;
  			background: #fff;
  			padding: 12px 10px 12px 20px;
  			text-align: center;
  			color: #333;
  			text-transform: uppercase;
  			-webkit-border-radius: 15px;
  			-moz-border-radius: 15px;
  			border-radius: 15px;
				box-shadow: 0 2px 6px 0.5px rgba(0, 0, 0, 0.2);
				transition: all .3s;
				display: -ms-flexbox;
		    display: -webkit-flex;
		    display: flex;
		    -webkit-flex-direction: row;
		    -ms-flex-direction: row;
		    flex-direction: row;
		    -webkit-flex-wrap: nowrap;
		    -ms-flex-wrap: nowrap;
		    flex-wrap: nowrap;
		    -webkit-justify-content: center;
		    -ms-flex-pack: center;
		    justify-content: center;
		    -webkit-align-content: stretch;
		    -ms-flex-line-pack: stretch;
		    align-content: stretch;
		    -webkit-align-items: center;
		    -ms-flex-align: center;
		    align-items: center;
		    font-size: 13px;
		    font-weight: 700;

		    &.open {
		    	&:after {
		    		-webkit-transform: rotate(180deg);
		    		   -moz-transform: rotate(180deg);
		    		    -ms-transform: rotate(180deg);
		    		     -o-transform: rotate(180deg);
		    		        transform: rotate(180deg);
		    	}
		    }

		    &.company-btn {
		    	&:hover {
			    	.icon {
			    		&.icon-case {
			    			background: url(../img/icons/@2x/icon-case--green@2x.png) 0 0 no-repeat;
						    -webkit-background-size: 100% auto;
						    -moz-background-size: 100% auto;
						    background-size: 100% auto;
			    		}
			    	}
			    }
		    }

		    @media (max-width: 768px) {
		    	margin-bottom: 10px;
		    }

		    &:after {
		    	position: absolute;
		    	top: 50%;
		    	right: 15px;
		    	margin-top: -3px;
		    	display: inline-block;
		    	content: "";
		    	border: 0;
			    background: url(../img/icons/@2x/angle-down@2x.png) 0 0 no-repeat;
			    -webkit-background-size: 100% auto;
			    -moz-background-size: 100% auto;
			    background-size: 100% auto;
			    width: 9px;
			    height: 6px;
			    -moz-transition: all 0.3s;
			    -o-transition: all 0.3s;
			    -webkit-transition: all 0.3s;
			    transition: all 0.3s;
		    }

  			.icon {
  				left: 15px;
  				margin-left: 0;
  				margin-top: -7px;
  				-moz-transition: all 0.3s;
			    -o-transition: all 0.3s;
			    -webkit-transition: all 0.3s;
			    transition: all 0.3s;

  				&.icon-plus {
	  				background: url(../img/icons/@2x/icon-add@2x.png) 0 0 no-repeat;
	  				-webkit-background-size: 100% auto;
	  				-moz-background-size: 100% auto;
	  				background-size: 100% auto;
  				}
  			}

  			.ks-info {
  				opacity: 1;
  				visibility: visible;
  				min-width: 110px;
  			}

  			&:hover {
  				-webkit-box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.1);
  				-moz-box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.1);
  				box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.1);
  				color: #4caf50;

  				&:after {
  					background: url(../img/icons/@2x/angle-down--green@2x.png) 0 0 no-repeat;
				    -webkit-background-size: 100% auto;
				    -moz-background-size: 100% auto;
				    background-size: 100% auto;
  				}

  				.icon {
  					background: url(../img/icons/@2x/icon-add--green@2x.png) 0 0 no-repeat;
	  				-webkit-background-size: 100% auto;
	  				-moz-background-size: 100% auto;
	  				background-size: 100% auto;
  				}
  			}
    	}

    	.submit-block {
    		.row {
			    -webkit-flex-direction: row;
			    -ms-flex-direction: row;
			    flex-direction: row;
			    -webkit-flex-wrap: nowrap;
			    -ms-flex-wrap: nowrap;
			    flex-wrap: nowrap;
			    -webkit-justify-content: flex-end;
			    -ms-flex-pack: end;
			    justify-content: flex-end;

			    @media (max-width: 768px) {
			    	-webkit-flex-wrap: wrap;
				    -ms-flex-wrap: wrap;
				    flex-wrap: wrap;
			    }
    		}

    		.btn-submit {
	    		position: relative;
	  			right: 0;
	  			width: 100%;
	  			background: #4caf50;
	  			padding: 12px 15px 12px 30px;
	  			text-align: center;
	  			color: #fff;
	  			text-transform: uppercase;
	  			-webkit-border-radius: 15px;
	  			-moz-border-radius: 15px;
	  			border-radius: 15px;
	  			-moz-box-shadow: 0 2px 6px 0.5px rgba(0, 0, 0, 0.3);
					-webkit-box-shadow: 0 2px 6px 0.5px rgba(0, 0, 0, 0.3);
					box-shadow: 0 2px 6px 0.5px rgba(0, 0, 0, 0.3);
					-webkit-transition: all .3s;
					-moz-transition: all .3s;
					transition: all .3s;
					display: -ms-flexbox;
			    display: -webkit-flex;
			    display: flex;
			    -webkit-flex-direction: row;
			    -ms-flex-direction: row;
			    flex-direction: row;
			    -webkit-flex-wrap: nowrap;
			    -ms-flex-wrap: nowrap;
			    flex-wrap: nowrap;
			    -webkit-justify-content: center;
			    -ms-flex-pack: center;
			    justify-content: center;
			    -webkit-align-content: stretch;
			    -ms-flex-line-pack: stretch;
			    align-content: stretch;
			    -webkit-align-items: center;
			    -ms-flex-align: center;
			    align-items: center;
			    font-size: 13px;
			    font-weight: 700;
			    border: 1px solid #4caf50;

	  			.icon {
	  				left: 15px;
	  				margin-left: 0;
	  				margin-top: -7px;
	  				background: url(../img/icons/@2x/icon-tick--white@2x.png) 0 0 no-repeat;
	  				-webkit-background-size: 100% auto;
	  				-moz-background-size: 100% auto;
	  				background-size: 100% auto;
	  				-moz-transition: all 0.3s;
				    -o-transition: all 0.3s;
				    -webkit-transition: all 0.3s;
				    transition: all 0.3s;
	  			}

	  			.ks-info {
	  				opacity: 1;
	  				visibility: visible;
	  				min-width: 110px;
	  			}

	  			&:hover {
	  				-webkit-box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.2);
	  				-moz-box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.2);
	  				box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.2);
	  				background: #53bf57;
	  			}
	    	}

	    	.btn-clear {
	    		position: relative;
	  			right: 0;
	  			width: 100%;
	  			background: #fff;
	  			padding: 12px 10px 12px 20px;
	  			text-align: center;
	  			color: #333;
	  			text-transform: uppercase;
	  			-webkit-border-radius: 15px;
	  			-moz-border-radius: 15px;
	  			border-radius: 15px;
	  			-moz-box-shadow: 0 2px 6px 0.5px rgba(0, 0, 0, 0.2);
					-webkit-box-shadow: 0 2px 6px 0.5px rgba(0, 0, 0, 0.2);
					box-shadow: 0 2px 6px 0.5px rgba(0, 0, 0, 0.2);
					-webkit-transition: all .3s;
					-moz-transition: all .3s;
					transition: all .3s;
					display: -ms-flexbox;
			    display: -webkit-flex;
			    display: flex;
			    -webkit-flex-direction: row;
			    -ms-flex-direction: row;
			    flex-direction: row;
			    -webkit-flex-wrap: nowrap;
			    -ms-flex-wrap: nowrap;
			    flex-wrap: nowrap;
			    -webkit-justify-content: center;
			    -ms-flex-pack: center;
			    justify-content: center;
			    -webkit-align-content: stretch;
			    -ms-flex-line-pack: stretch;
			    align-content: stretch;
			    -webkit-align-items: center;
			    -ms-flex-align: center;
			    align-items: center;
			    font-size: 13px;
			    font-weight: 700;

			    @media (max-width: 576px) {
			    	margin-bottom: 10px;
			    }

	  			.icon {
	  				left: 15px;
	  				margin-left: 0;
	  				margin-top: -7px;
	  				background: url(../img/icons/@2x/icon-remove@2x.png) 0 0 no-repeat;
	  				-webkit-background-size: 100% auto;
	  				-moz-background-size: 100% auto;
	  				background-size: 100% auto;
	  				-moz-transition: all 0.3s;
				    -o-transition: all 0.3s;
				    -webkit-transition: all 0.3s;
				    transition: all 0.3s;
				    width: 16px;
				    height: 16px;
				    margin-top: -8px;
	  			}

	  			.ks-info {
	  				opacity: 1;
	  				visibility: visible;
	  				min-width: 110px;
	  			}

	  			&:hover {
	  				-webkit-box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.1);
	  				-moz-box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.1);
	  				box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.1);
	  				color: #4caf50;

	  				.icon {
	  					background: url(../img/icons/@2x/icon-remove--green@2x.png) 0 0 no-repeat;
		  				-webkit-background-size: 100% auto;
		  				-moz-background-size: 100% auto;
		  				background-size: 100% auto;
	  				}
	  			}
	    	}
    	}
    }

    .attach-case-block {
    	border-top: 1px solid #e6e6e6;
    	margin-top: 40px;
    	display: none;

    	&.open {
    		display: -webkit-box;
		    display: -ms-flexbox;
		    display: flex;
    	}

    	.row {
    		margin-top: 30px;

    		&:nth-child(2) {
    			margin-top: 0;
    		}
    	}

    	.client-portal-settins-btn {
    		position: relative;
  			right: 0;
  			width: 100%;
  			background: #fff;
  			padding: 12px 20px 12px 10px;
  			text-align: center;
  			color: #333;
  			text-transform: uppercase;
  			-webkit-border-radius: 15px;
  			-moz-border-radius: 15px;
  			border-radius: 15px;
  			-moz-box-shadow: 0 2px 6px 0.5px rgba(0, 0, 0, 0.2);
				-webkit-box-shadow: 0 2px 6px 0.5px rgba(0, 0, 0, 0.2);
				box-shadow: 0 2px 6px 0.5px rgba(0, 0, 0, 0.2);
				-webkit-transition: all .3s;
				-moz-transition: all .3s;
				transition: all .3s;
				display: -ms-flexbox;
		    display: -webkit-flex;
		    display: flex;
		    -webkit-flex-direction: row;
		    -ms-flex-direction: row;
		    flex-direction: row;
		    -webkit-flex-wrap: nowrap;
		    -ms-flex-wrap: nowrap;
		    flex-wrap: nowrap;
		    -webkit-justify-content: center;
		    -ms-flex-pack: center;
		    justify-content: center;
		    -webkit-align-content: stretch;
		    -ms-flex-line-pack: stretch;
		    align-content: stretch;
		    -webkit-align-items: center;
		    -ms-flex-align: center;
		    align-items: center;
		    font-size: 13px;
		    font-weight: 700;

		    @media (max-width: 1199px) {
		    	font-size: 10px;
		    }

		    @media (max-width: 768px) {
		    	margin-bottom: 10px;
		    }

		    &:after {
		    	position: absolute;
		    	top: 50%;
		    	right: 10px;
		    	margin-top: -4.5px;
		    	display: inline-block;
		    	content: "";
		    	border: 0;
			    background: url(../img/icons/@2x/angle-right@2x.png) 0 0 no-repeat;
			    -webkit-background-size: auto 100%;
			    -moz-background-size: auto 100%;
			    background-size: auto 100%;
			    width: 6px;
			    height: 9px;
			    -moz-transition: all 0.3s;
			    -o-transition: all 0.3s;
			    -webkit-transition: all 0.3s;
			    transition: all 0.3s;
		    }

  			.ks-info {
  				opacity: 1;
  				visibility: visible;
  				min-width: 110px;
  			}

  			&:hover {
  				-webkit-box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.1);
  				-moz-box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.1);
  				box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.1);
  				color: #4caf50;

  				&:after {
  					background: url(../img/icons/@2x/angle-right--green@2x.png) 0 0 no-repeat;
				    -webkit-background-size: 100% auto;
				    -moz-background-size: 100% auto;
				    background-size: 100% auto;
  				}
  			}
    	}

    	.check-col {
    		.form-group {
    			// line-height: 45px;
    			display: -ms-flexbox;
			    display: -webkit-flex;
			    display: flex;
			    -webkit-flex-direction: row;
			    -ms-flex-direction: row;
			    flex-direction: row;
			    -webkit-flex-wrap: nowrap;
			    -ms-flex-wrap: nowrap;
			    flex-wrap: nowrap;
			    -webkit-justify-content: flex-start;
			    -ms-flex-pack: start;
			    justify-content: flex-start;
			    -webkit-align-content: stretch;
			    -ms-flex-line-pack: stretch;
			    align-content: stretch;
			    -webkit-align-items: center;
			    -ms-flex-align: center;
			    align-items: center;
			    height: 45px;
			    margin-top: 30px;

			    @media (max-width: 992px) {
			    	margin-top: 0;
			    }

			    .ks-text {
			    	margin-left: 15px;
			    	margin-bottom: 0;
			    	cursor: pointer;
			    }
    		}
    	}
    }

    .search-row {
    	text-align: center;
    	padding-bottom: 30px;
    	border-bottom: 1px solid #e6e6e6;

    	.inner-search-row {
    		max-width: 700px;
    		margin: 0 auto;

    	}

    	.search-block {
    		width: 480px;
    		float: left;

    		@media (max-width: 992px) {
    			width: 440px;
    		}

    		@media (max-width: 768px) {
    			float: none;
    			margin: 0 auto;
    		}

    		@media (max-width: 540px) {
    			width: 260px;
    		}
    	}

    	.search_results {
	  		position: absolute;
	  		top: 100%;
	  		right: 115px;
	  		left: 0;
	  		background: #fff;
	  		padding: 5px 0;
	  		box-shadow: 0 6px 20px 0px rgba(0,0,0,0.25);
	  		border-radius: 15px;
	  		z-index: 2;

	  		@media (max-width: 768px) {
	  			top: 46px;
	  		}

	  		@media (max-width: 540px) {
	  			right: 0;
	  		}
    		
    	}

    	.search_results_list {
	  		height: 200px;
	  		overflow-x: auto;
	  		margin-right: 5px;

    		a {
    			display: block;
    			padding: 5px 10px;
    			color: #333;
    			text-align: left;
    			word-break: break-all;

    			&:hover {
    				color: #4caf50;
    			}
    		}
    	}

    	.search-by-country {
    		float: left;
    		width: 200px;
    		margin-right: 20px;

    		@media (max-width: 768px) {
    			width: 100%;
    			float: none;
    			margin: 15px auto 0;
    			max-width: 200px;
    		}
    	}

    	h4 {
    		margin-top: 20px;
    		text-transform: uppercase;
    	}

    	.form-control {
    		float: left;
    		width: 365px;
		    height: 45px;
		    background: #fff;
		    border: 1px solid #e6e6e6;
		    padding: 10px 15px;
		    -webkit-border-radius: 15px;
		    -moz-border-radius: 15px;
		    border-radius: 15px;
		    color: #000;
		    font-size: 14px;
		    font-family: "Open Sans", sans-serif;
		    -webkit-transition: all .3s;
		    -moz-transition: all .3s;
		    -ms-transition: all .3s;
		    -o-transition: all .3s;
		    transition: all .3s;

		    @media (max-width: 991px) {
		    	width: 325px;
		    }

		    @media (max-width: 540px) {
		    	width: 140px;
		    }
    	}

    	// .icon-addon {
    	// 	height: 43px;
    	// 	top: 1px;
    	// 	right: 1px;
    	// }

    	.icon-addon {
		    position: relative;
		    right: 0;
		    float: left;
		    width: 100px;
		    margin-left: 15px;
		    background: #4caf50;
		    padding: 14.5px 10px 14.5px 30px;
		    text-align: center;
		    border: 0;
		    color: #333;
		    text-transform: uppercase;
		    -webkit-border-radius: 15px;
		    -moz-border-radius: 15px;
		    border-radius: 15px;
		    box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.3);
		    transition: all .3s;
		    display: -ms-flexbox;
		    display: -webkit-flex;
		    display: flex;
		    -webkit-flex-direction: row;
		    -ms-flex-direction: row;
		    flex-direction: row;
		    -webkit-flex-wrap: nowrap;
		    -ms-flex-wrap: nowrap;
		    flex-wrap: nowrap;
		    -webkit-justify-content: center;
		    -ms-flex-pack: center;
		    justify-content: center;
		    -webkit-align-content: stretch;
		    -ms-flex-line-pack: stretch;
		    align-content: stretch;
		    -webkit-align-items: center;
		    -ms-flex-align: center;
		    align-items: center;
		    font-size: 13px;
		    font-weight: 700;

		    .ks-text {
		    	color: #fff;
		    	opacity: 1;
		    	-webkit-transition: all .3s;
		    	   -moz-transition: all .3s;
		    	    -ms-transition: all .3s;
		    	     -o-transition: all .3s;
		    	        transition: all .3s;
		    }

		    &:hover {
		    	box-shadow: 0 4px 14px 2px rgba(0,0,0,0.2);
		    	
		    	.ks-text {
		    		opacity: 1;
		    	}
		    }

		    .icon {
		    	opacity: 1;
		    	left: 5px;
		    	margin-left: 0;
		    }
		  }

    	.no-matches-block {
    		margin-top: 15px;
    		float: left;
    		width: 100%;

    		.icon {
    			display: inline-block;
    			width: 13px;
    			height: 13px;
    			text-align: center;
    			border: 1px solid #000000;
    			font-size: 10px;
    			color: #000;
    			line-height: 13px;
    			-webkit-border-radius: 50px;
    			-moz-border-radius: 50px;
    			border-radius: 50px;
    		}

    		.ks-text {
    			font-size: 12px;
    			font-style: italic;
    		}
    	}
    }
	}
}