#dashboard-page {
  .card-block {
    margin: 15px;
    padding: 20px;

    h3 {
      border-bottom: 2px solid #ff5722;
      margin: 0 0 18px 0;
      padding: 0 0 11px;
      color: #000;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.1em;
      position: relative;

      .icon {
        position: absolute;
        top: 50%;
        left: initial;
        margin-top: -14px;
        margin-left: 5px;
      }

      a, span {
        margin-left: 35px;
        color: #000;
      }
    }

    &.deadlines {
      .info-row {
        .icon {
          margin-top: 1px;
          top: initial;
          left: initial;
          margin-left: initial;
        }

        .ks-text {
          font-size: 13px;
          color: #c5c5c5;
          margin-bottom: 5px;
          line-height: 15px;
          margin-left: 25px;

          .priority {
            font-weight: 600;

            &.high {
              color: #ff5722;
            }

            &.normal {
              color: #ff9800;
            }
          }
        }
      }

      .card-link {
        font-weight: 600;
        color: #000;
        margin-top: 12px;
        display: block;
        font-size: 15px;

        &:hover {
          color: #ff5722;
        }
      }
    }

    &.contacts {
      .corp {
        color: #000;
        margin-bottom: 5px;
        line-height: 1em;
        font-weight: 600;
        display: block;
      }

      .name {
        color: #a3a3a3;
        font-size: 13px;
        line-height: 1.5em;
        word-wrap: break-word;
        display: block;
      }

      .card-link {
        display: flex;
        align-items: center;

        .img {
          margin-right: 15px;
        }
      }
    }

    &.matters {
      .info-row {
        .icon {
          margin-top: 1px;
          top: initial;
          left: initial;
          margin-left: initial;
        }

        .ks-text {
          font-size: 13px;
          color: #c5c5c5;
          margin-bottom: 5px;
          line-height: 15px;
          margin-left: 25px;
        }
      }

      .card-link {
        font-weight: 600;
        color: #000;
        margin-top: 12px;
        display: block;
        font-size: 15px;

        &:hover {
          color: #ff5722;
        }
      }
    }

    &.tasks {
      .card-link {
        font-weight: 600;
        color: #000;
        margin-top: 12px;
        display: block;
        font-size: 15px;

        &:hover {
          color: #ff5722;
        }
      }

      .task-priority {
        padding-left: 37px;
        margin-bottom: 10px;
        margin-right: 10px;
      }
    }

    blockquote {
      border-bottom: 1px solid #f2f2f2;
      padding-bottom: 15px;
    }
  }
}

.ks-contacts .content .pane-content.dashboard .contact-content {
  background: #ececec;
}

.dashboard {
  .row {
    &.top {
      h3 {
        padding-right: 0;
      }
    }
  }
  &.profile-content {
    .table-heading {
      min-height: 165px;
      padding-top: 40px;
    }
  }

  .dashboard-block {
    position: relative;
    margin-top: -20px;

    .dashboard-wrapper {
      display: flex;
      margin: 0 -7.5px;

      @media (max-width: 991px) {
        flex-direction: column;
      }
    }

    .card {
      margin: 0 7.5px;
      border: 0;
      background: none;
      border-radius: 18px;
      margin-top: -60px;
      min-height: 480px;
      margin-bottom: 15px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);

      @media (max-width: 991px) {
        flex: 1 100%;
        min-height: auto;
      }

      &.deadlines {
        flex: 1 33.3333%;

        @media (max-width: 991px) {
          flex: 1 100%;
        }

        .card-header {
          background: #ff5722;

          .icon {
            width: 32px;
            height: 32px;
            margin-top: -16px;
            opacity: 0.2;

            @media (max-width: 768px) {
              background-size: 100% auto;
              width: 16px;
              height: 16px;
              margin-top: -8px;
            }
          }

          .btn {
            background: #ff5722;
          }
        }

        .card-blockquote {
          @media (max-width: 768px) {
            padding-right: 0;
          }
          &:hover {
            background: rgba(255, 87, 34, 0.03);;

            .deadline-info,
            .deadline-link {
              color: #ff5722;
            }
          }
          .deadline-link {
            &:before {
              background: url(../img/icons/@2x/icon-time--red@2x.png) 0 0 no-repeat;
              background-size: 100% auto;
            }
          }
        }

        .empty-block {
          .icon {
            width: 32px;
            height: 32px;
            margin-top: 0;
            opacity: 0.2;
          }

          .empty-semi-title {
            .btn {
              background: #ff5722;
            }
          }
        }
      }

      &.meetings {
        flex: 1 33.3333%;

        @media (max-width: 991px) {
          flex: 1 100%;
          margin-top: 0;
        }

        .card-header {
          background: #00bcd4;

          .icon {
            width: 32px;
            height: 32px;
            margin-top: -16px;
            opacity: 0.2;

            @media (max-width: 768px) {
              background-size: 100% auto;
              width: 16px;
              height: 16px;
              margin-top: -8px;
            }
          }

          .btn {
            background: #00bcd4;
          }
        }

        .card-blockquote {
          @media (max-width: 768px) {
            padding-right: 0;
          }
          &:hover {
            background-color: rgba(0, 188, 212, 0.03);

            .deadline-info,
            .deadline-link {
              color: #00bcd4;
            }
          }
          .deadline-link {
            &:before {
              background: url(../img/icons/@2x/icon-time--cyan@2x.png) 0 0 no-repeat;
              background-size: 100% auto;
            }
          }

          .right-block {
            .open-popover-link {
              .more-options {
                &:hover {
                  background: rgba(0, 188, 212, 0.1);
                }
              }
            }
          }
        }

        .empty-block {
          .icon {
            width: 32px;
            height: 32px;
            margin-top: 0;
            opacity: 0.2;
          }

          .empty-semi-title {
            .btn {
              background: #00bcd4;
            }
          }
        }
      }

      &.messages {
        flex: 1 33.3333%;

        @media (max-width: 991px) {
          flex: 1 100%;
          margin-top: 0;
        }

        .card-header {
          background: #2196f3;

          .icon {
            width: 30px;
            height: 25px;
            margin-top: -12.5px;
            opacity: 0.2;

            @media (max-width: 768px) {
              background-size: 100% auto;
              width: 15px;
              height: 12.5px;
              margin-top: -6.25px;
            }
          }

          .btn {
            background: #2196f3;
          }
        }

        .card-blockquote {
          display: block;
          position: relative;
          padding: 15px 0 10px 30px;
          @media (max-width: 768px) {
            padding: 15px 40px 15px 15px;
          }

          &:hover {
            background-color: rgba(33, 150, 243, 0.05);
          }

          &.not-reading {
            .message-title {
              font-weight: 600;
            }
          }

          .message-title {
            display: block;
            font-size: 13px;
            color: #000000;
          }


          .client-name {
            display: block;
            font-size: 13px;
            color: #aaaaaa;
            margin-bottom: 5px;
          }

          .cases {
            position: relative;
            display: block;
            font-size: 13px;
            font-weight: 600;
            color: #aaaaaa;
            padding-left: 25px;

            &:before {
              position: absolute;
              top: 50%;
              left: 0;
              content: "";
              background: url(../img/icons/@2x/icon-case@2x.png) 0 0 no-repeat;
              background-size: 100% auto;
              width: 16px;
              height: 14px;
              margin-top: -7px;
              opacity: .3;
            }
          }

          .count {
            position: absolute;
            top: 50%;
            right: 22px;
            height: 15px;
            border-radius: 15px;
            background-color: #2196f3;
            font-size: 10px;
            font-weight: 600;
            text-align: center;
            padding: 3px 5.5px;
            line-height: 1em;
            color: #ffffff;
            margin-top: -7.5px;
          }
        }

        .empty-block {
          display: flex;
          .icon {
            width: 30px;
            height: 25px;
            margin-top: -12.5px;
            opacity: 0.2;
          }

          .empty-semi-title {
            padding-right: 0;
          }
        }
      }

      &.tasks {
        margin-top: 0;
        flex: 1 67.666666%;

        .card-header {
          background: #ffc107;

          .icon {
            width: 32px;
            height: 32px;
            margin-top: -16px;
            opacity: 0.2;

            @media (max-width: 768px) {
              background-size: 100% auto;
              width: 16px;
              height: 16px;
              margin-top: -8px;
            }
          }
        }

        .custom-scroll-table {
          max-height: 399px;

          .simplebar-track.vertical {
            display: inherit;
            z-index: 4;
          }

          .simplebar-track.horizontal {
            bottom: 0 !important;
            z-index: 4;
          }
        }

        .empty-block {
          min-height: 311px;
          .icon {
            width: 32px;
            height: 32px;
            margin-top: 0;
            opacity: 0.2;
          }

          .empty-semi-title {
            padding-right: 0;
          }
        }
      }

      &.to-do {
        margin-top: 0;
        flex: 1 33.33333%;

        .card-header {
          background: #333333;

          .title {
            color: #fff;
          }

          .icon {
            width: 32px;
            height: 32px;
            margin-top: -16px;

            @media (max-width: 768px) {
              background-size: 100% auto;
              width: 16px;
              height: 16px;
              margin-top: -8px;
            }
          }

          .btn {
            background: #333333;
            transition: all .3s;

            &:before {
              transition: all .3s;
            }

            &.show-add {
              &:before {
                -webkit-transform: rotate(45deg);
                   -moz-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                     -o-transform: rotate(45deg);
                        transform: rotate(45deg);
              }
            }
          }
        }

        .card-body {
          position: relative;
          padding-bottom: 0;
        }

        .main-blockquote-title {
          display: block;
          font-size: 11px;
          font-weight: 600;
          color: #666666;
          border-bottom: 1px solid #e6e6e6;
          text-transform: uppercase;
          padding: 10px 0 5px 15px;

          &.bottom {
            position: relative;
            background: rgba(33, 33, 33, 0.03);
            border-top: 1px solid #e6e6e6;

            .show-all {
              display: inline-block;
              position: absolute;
              top: 50%;
              right: 30px;
              font-size: 11px;
              font-weight: 600;
              color: #000000;
              text-transform: uppercase;
              margin-top: -10px;

              &:before {
                position: absolute;
                top: 50%;
                right: -20px;
                background: url(../img/icons/@2x/angle-right@2x.png) 0 0 no-repeat;
                width: 9px;
                height: 9px;
                -webkit-background-size: auto 100%;
                background-size: auto 100%;
                margin-top: -4.5px;
                content: "";
              }
            }
          }
        }

        .card-blockquote {
          position: relative;
          display: block;
          padding: 11px 60px 11px 20px;

          @media (max-width: 768px) {
            padding: 15px 40px 15px 15px;
          }

          &:hover {
            background-color: rgba(33, 33, 33, 0.03);

            .todo-label {
              .info {
                color: #000000;
              }
            }

            .open-popover-link {
              opacity: 1;
              visibility: visible;
            }
          }

          .empty-done-title {
            display: block;
            font-size: 13px;
            text-align: center;
            color: #aaaaaa;
            margin-right: -40px;
            padding: 20px 10px 8px;
          }
        }

        .empty-block {
          min-height: 300px;
          display: flex;
          padding-right: 20px;

          .icon {
            width: 32px;
            height: 32px;
            margin-top: 0;
            background: url(../img/icons/@2x/icon-to-do@2x.png) 0 0 no-repeat;
            background-size: 100% auto;
          }

          .empty-semi-title {
            .btn {
              background: #333333;
            }
          }
        }
      }

      &.pinned-case {
        margin-top: 0;
        flex: 1 33.3333%;

        .card-header {
          background: #ff9800;

          .icon {
            width: 32px;
            height: 32px;
            margin-top: -16px;
            opacity: 0.2;

            @media (max-width: 768px) {
              background-size: 100% auto;
              width: 16px;
              height: 16px;
              margin-top: -8px;
            }
          }

          .open-popover-link {
            position: absolute;
            top: 50%;
            right: 15px;
            width: 30px;
            height: 30px;
            flex: 0 auto;
            transition: all .3s;
            margin-top: -15px;

            .open-popover {
              width: 30px;
              height: 30px;
              float: right;
            }

            .more-options {
              position: relative;
              display: block;
              height: 30px;
              width: 30px;
              cursor: pointer;
              float: right;
              border-radius: 15px;
              transition: all .3s;

              .icon {
                height: 15px;
                margin-top: -7.5px;
                transition: all .3s;
                background: url(../img/icons/@2x/icon-more--white@2x.png) 0 0 no-repeat;
                background-size: auto 100%;
                opacity: 1;
              }
            }
          }
        }

        .card-blockquote {
          position: relative;
          display: block;
          padding: 12px 30px 30px;

          @media (max-width: 768px) {
            padding: 15px;
          }
        }
      }

      &.new-case-pin {
        margin-top: 0;
        flex: 1 33.33333%;

        .card-body {
          padding-bottom: 0;
          border-radius: 18px;

          @media (max-width: 991px) {
            padding-bottom: 30px;
          }
        }
      }
    }
    .card-header {
      position: relative;
      min-height: 60px;
      border-radius: 18px 18px 0 0;
      padding: 20px 15px 16px;

      .icon {
        left: 15px;
        margin-left: 0;
        background-size: 100% auto;
      }

      .title {
        position: relative;
        display: flex;
        font-size: 13px;
        letter-spacing: 0.3px;
        color: #ffffff;
        padding-left: 43px;
        align-items: center;
        @media (max-width: 768px) {
          padding-left: 23px;
        }

        b {
          font-size: 22px;
          font-weight: normal;
          letter-spacing: -0.2px;
          padding-right: 15px;

          @media (max-width: 768px) {
            font-size: 18px;
            padding-right: 10px;
          }
        }

        i {
          font-style: normal;
        }
      }

      .btn {
        position: absolute;
        top: 50%;
        right: 15px;
        width: 30px;
        height: 30px;
        padding: 0;
        -webkit-border-radius: 100px;
        border-radius: 100px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        opacity: 1;
        -webkit-transition: all .3s;
        -o-transition: all .3s;
        transition: all .3s;
        margin-top: -15px;
        z-index: 2;

        &:hover {
          opacity: 0.8;
        }

        &:before {
          position: absolute;
          top: 50%;
          left: 50%;
          background: url(../img/icons/@2x/icon-plus--white@2x.png) 0 0 no-repeat;
          width: 11px;
          height: 11px;
          -webkit-background-size: 100% auto;
          background-size: 100% auto;
          margin-top: -5.5px;
          margin-left: -5.5px;
          content: "";
          -webkit-transition: all .3s;
          -o-transition: all .3s;
          transition: all .3s;
        }
      }
    }

    .card-body {
      background: #fff;
      padding: 0;
      border-radius: 0 0 18px 18px;
      padding-bottom: 60px;
    }

    .card-blockquote {
      padding: 15px 0 25px 30px;
      display: flex;
      transition: all .3s;
      margin-bottom: 0;

      @media (max-width: 768px) {
        padding: 15px;
      }

      &:hover {
        .right-block {
          .open-popover-link {
            opacity: 1;
            visibility: visible;
          }
        }
      }

      .left-block {
        flex: 1 100%;
      }

      .right-block {
        flex: 0 90px;
        min-width: 90px;

        @media (max-width: 768px) {
          min-width: 75px;
          flex: 0 75px;
        }

        .title {
          display: block;
          font-size: 11px;
          font-weight: 600;
          color: #666666;
          text-transform: uppercase;
          margin-bottom: 15px;
        }

        .flex-block {
          display: flex;
          align-items: center;
          height: 45px;
        }

        .img-block {
          max-width: 45px;
          height: 45px;
          flex: 1 45px;
          margin-right: 15px;
          border-radius: 100px;
          @media (max-width: 768px) {
            margin-right: 0;
          }

          .user {
            display: block;
          }

          img {
            width: 100%;
            border-radius: 100px;
          }
        }


        .open-popover-link {
          position: relative;
          width: 30px;
          height: 30px;
          flex: 0 auto;
          opacity: 0;
          visibility: hidden;
          transition: all .3s;

          .open-popover {
            width: 30px;
            height: 30px;
            float: right;
          }

          .more-options {
            position: relative;
            display: block;
            height: 30px;
            width: 30px;
            cursor: pointer;
            float: right;
            border-radius: 15px;
            transition: all .3s;

            &:hover {
              background: rgba(255, 152, 0, 0.1);
            }

            .icon {
              height: 15px;
              margin-top: -7.5px;
              transition: all .3s;
            }
          }
        }
      }

      .deadline-link {
        position: relative;
        display: block;
        padding-left: 23px;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.4px;
        text-align: left;
        color: #000;
        margin-bottom: 12px;

        &:before {
          position: absolute;
          top: 50%;
          left: 0;
          content: "";
          -webkit-background-size: 100% auto;
          background-size: 100% auto;
          width: 16px;
          height: 16px;
          margin-top: -8px;
        }
      }

      .deadline-info {
        display: block;
        font-size: 13px;
        text-align: left;
        color: #000;
        margin-bottom: 8px;
        transition: all .3s;
      }

      .deadline-info-2 {
        display: block;
        font-size: 13px;
        text-align: left;
        color: #aaaaaa;
      }
    }

    .empty-block {
      min-height: 360px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .icon {
        position: relative;
        top: auto;
        left: auto;
        margin-left: 0;
        background-size: 100% auto;
        margin-bottom: 30px;
      }

      .empty-title {
        display: block;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.4px;
        color: #aaaaaa;
        margin-bottom: 15px;
      }

      .empty-semi-title {
        position: relative;
        display: block;
        font-size: 13px;
        color: #aaaaaa;
        padding-right: 40px;
      }

      .btn {
        position: absolute;
        top: 50%;
        right: 0;
        width: 30px;
        height: 30px;
        padding: 0;
        -webkit-border-radius: 100px;
        border-radius: 100px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        opacity: 1;
        -webkit-transition: all .3s;
        -o-transition: all .3s;
        transition: all .3s;
        margin-top: -15px;

        &:hover {
          opacity: 0.8;
        }

        &:before {
          position: absolute;
          top: 50%;
          left: 50%;
          background: url(../img/icons/@2x/icon-plus--white@2x.png) 0 0 no-repeat;
          width: 11px;
          height: 11px;
          -webkit-background-size: 100% auto;
          background-size: 100% auto;
          margin-top: -5.5px;
          margin-left: -5.5px;
          content: "";
          -webkit-transition: all .3s;
          -o-transition: all .3s;
          transition: all .3s;
        }
      }
    }

    .show-more {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      height: 60px;
      text-align: center;
      border-top: 1px solid #ededed;
      padding: 17px 0;
      border-radius: 0 0 18px 18px;

      &:hover {
        background: rgba(0,0,0,0.2);
      }

      .ks-text {
        position: relative;
        display: inline-block;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 0.3px;
        color: #000000;
        text-transform: uppercase;

        &:before {
          position: absolute;
          top: 50%;
          right: -24px;
          background: url(../img/icons/@2x/angle-right@2x.png) 0 0 no-repeat;
          width: 9px;
          height: 9px;
          -webkit-background-size: auto 100%;
          background-size: auto 100%;
          margin-top: -4.5px;
          content: "";
        }
      }
    }

    .task-table {
      th {
        height: 60px;
        border-top: 0;
      }

      td {
        border: 0;
      }

      th.priority-cell,
      td.priority-cell {
        width: 105px;
      }

      .priority-cell {
        justify-content: center !important;

        .dropdown-toggle {
          padding: 0;
          background: none;
          border: 0;
          box-shadow: none;

          &:hover {
            span {
              border-radius: 100px 0 0 100px;

              &:after {
                right: -15px;
                background-position: 0 50%;
              }
            }
          }

          &:after {
            display: none;
          }

          span {
            position: relative;
            display: block;
            width: 30px;
            height: 30px;
            border-radius: 100px;
            font-size: 11px;
            color: #fff;
            padding: 7px 0;
            z-index: 2;
            transition: all .3s;
            text-indent: -99999999px;

            &:after {
              position: absolute;
              height: 30px;
              width: 15px;
              top: 0;
              right: 0;
              margin: 0;
              content: "";
              border-radius: 0 100px 100px 0;
              background: url(../img/icons/angle-down-full--white.png) -7px 50% no-repeat;
              z-index: 1;
              transition: all .3s;
            }

            &:before {
              position: absolute;
              top: 50%;
              left: 50%;
              font-family: $font-mont;
              font-size: 11px;
              font-weight: 600;
              color: #fff;
              content: "";
              width: 30px;
              height: 30px;
              line-height: 30px;
              margin-top: -15px;
              margin-left: -15px;
              text-indent: 0;
              z-index: 2;
            }

            &.high {
              background: #ff5722;

              &:after {
                background-color: #ff5722;
              }

              &:before {
                content: "!!!";
              }
            }

            &.medium {
              background: #673ab7;

              &:after {
                background-color: #673ab7;
              }

              &:before {
                content: "!!";
              }
            }

            &.low {
              background: #00bcd4;

              &:after {
                background-color: #00bcd4;
              }

              &:before {
                content: "!";
              }
            }

            &.no-priority {
              background: #e6e6e6;

              &:after {
                background-color: #e6e6e6;
              }
            }
          }
        }

        .dropdown-menu {
          box-shadow: 0 6px 14px 2px rgba(0,0,0,0.1);
          border-radius: 15px;
          border: 0;
          width: 195px;

          .dropdown-divider {
            margin: 0;
          }

          .checked,
          .dropdown-item {
            position: relative;
            padding: 10px 15px;
            cursor: pointer;

            &:active {
              background: none;
            }

            span {
              display: block;
              font-size: 13px;
              color: #000;
              padding-left: 30px;

              &:before {
                position: absolute;
                top: 50%;
                left: 15px;
                content: "";
                font-family: $font-mont;
                font-size: 11px;
                color: #fff;
                font-weight: 600;
                width: 20px;
                height: 20px;
                border-radius: 50px;
                margin-top: -10px;
                text-align: center;
              }

              &.high {
                &:before {
                  background: #ff5722;
                  content: "!!!";
                }
              }

              &.medium {
                &:before {
                  background: #673ab7;
                  content: "!!";
                }
              }

              &.low {
                &:before {
                  background: #00bcd4;
                  content: "!";
                }
              }

              &.no-priority {
                &:before {
                  background: #e6e6e6;
                }
              }
            }
          }
        }
      }

      .task-name-cell {
        &:hover {
          cursor: pointer;

          .name {
            color: #ff9800 !important;
          }
        }

        .name-block {
          position: relative;
        }

        .name {
          position: relative;
          display: inline-block;
          font-size: 13px;
          font-weight: 600;
          text-overflow: ellipsis;
          overflow: hidden;
          width: inherit;
          white-space: nowrap;
          transition: all .3s;
          padding: 6px 19px 6px 0;
          max-width: 300px;
        }

        .count {
          position: absolute;
          top: 50%;
          right: 0;
          width: 12px;
          height: 12px;
          background: #ff5722;
          border-radius: 20px;
          font-size: 8px;
          color: #fff;
          text-align: center;
          line-height: 12px;
          font-weight: 600;
          margin-top: -13px;
          font-style: normal;
        }
      }

      .status-cell {
        .dropdown-toggle {
          padding: 0;
          background: none;
          border: 0;
          box-shadow: none;

          &:hover {
            span {
              border-radius: 100px 0 0 100px;

              &:after {
                right: -15px;
                background-position: 0 50%;
              }
            }
          }

          &:after {
            display: none;
          }

          span.working ~ &:after {
            background-color: #2196f3;

          }

          span {
            position: relative;
            display: block;
            width: 120px;
            height: 30px;
            border-radius: 100px;
            font-size: 11px;
            color: #fff;
            padding: 7px 0;
            z-index: 2;
            transition: all .3s;

            &:after {
              position: absolute;
              height: 30px;
              width: 15px;
              top: 0;
              right: 0;
              margin: 0;
              content: "";
              border-radius: 0 100px 100px 0;
              background: url(../img/icons/angle-down-full--white.png) -7px 50% no-repeat;
              z-index: 1;
              transition: all .3s;
            }

            &.working {
              background: #2196f3;
              color:#ffffff;

              &:after {
                background-color: #2196f3;
              }
            }

            &.done {
              background: #4caf50;
              color:#ffffff;

              &:after {
                background-color: #4caf50;
              }
            }

            &.stuck {
              background: #ffc107;
              color:#ffffff;

              &:after {
                background-color: #ffc107;
              }
            }

            &.no-status {
              background: #e6e6e6;
              color:#ffffff;

              &:after {
                background-color: #e6e6e6;
              }
            }

            &.user-label {
              background: #999999;
              color:#ffffff;

              &:after {
                background-color: #999999;
              }
            }
          }
        }

        .dropdown-menu {
          box-shadow: 0 6px 14px 2px rgba(0,0,0,0.1);
          border-radius: 15px;
          border: 0;
          width: 195px;

          .dropdown-divider {
            margin: 0;
          }

          .checked,
          .dropdown-item {
            position: relative;
            padding: 10px 15px;
            cursor: pointer;

            &:active {
              background: none;
            }

            span {
              display: block;
              font-size: 13px;
              color: #000;
              padding-left: 30px;

              &:before {
                position: absolute;
                top: 50%;
                left: 15px;
                content: "";
                width: 20px;
                height: 20px;
                border-radius: 50px;
                margin-top: -10px;
              }

              &.working {
                &:before {
                  background: #2196f3;
                }
              }

              &.done {
                &:before {
                  background: #4caf50;
                }
              }

              &.stuck {
                &:before {
                  background: #ffc107;
                }
              }

              &.no-status {
                &:before {
                  background: #e6e6e6;
                }
              }
            }
          }

          .form-group {
            position: relative;
            padding: 8px 10px 7px;
            margin-bottom: 0;

            &:before {
              position: absolute;
              top: 50%;
              left: 15px;
              content: "";
              width: 20px;
              height: 20px;
              border-radius: 50px;
              margin-top: -10px;
              background: #999999;
            }


            .form-control {
              padding-left: 30px;
              height: 30px;
              border-radius: 8px;
              width: 140px;
              float: left;
              font-size: 13px;
            }

            .btn {
              display: inline-block;
              font-size: 11px;
              font-weight: 600;
              background: none;
              border: 0;
              float: left;
              padding: 7px 10px;
              transition: all .3s;
              border-radius: 8px;

              &:hover {
                background: rgb(221, 221, 221);
              }
            }
          }
        }
      }

      .case-cell {
        .ks-text {
          max-width: 150px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .todo-block {
      &.done {
        background: rgba(33, 33, 33, 0.03);
        padding-bottom: 25px !important;
        .todo-label {
          .info,
          .title {
            color: #aaaaaa !important;
          }
        }
      }
      .todo-input {
        position: absolute;
        top: -100%;
        opacity: 0;
        width: 0;
        height: 0;
        visibility: hidden;
      }
      .todo-input:checked + .todo-label {
        &:after {
          opacity: 0.3;
        }
      }
      .todo-label {
        position: relative;
        display: block;
        padding-left: 40px;
        cursor: pointer;
        margin-bottom: 0;

        &:before {
          position: absolute;
          top: 50%;
          left: 0;
          content: "";
          width: 20px;
          height: 20px;
          background: rgba(33, 33, 33, 0.03);
          border: 1px solid #cccccc;
          border-radius: 4px;
          margin-top: -10px;
          transition: all .3s;

          &:hover {
            border-color: #333;
          }
        }

        &:after {
          position: absolute;
          top: 50%;
          left: 4px;
          content: "";
          background: url(../img/icons/@2x/checkmark@2x.png) 0 0 no-repeat;
          background-size: 100% auto;
          width: 13px;
          height: 13px;
          opacity: 0;
          margin-top: -7.5px;
          transition: all .3s;
        }

        .title {
          display: block;
          font-size: 13px;
          font-weight: 600;
          color: #000000;
          overflow: hidden;
          max-width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .info {
          display: block;
          font-size: 13px;
          color: #aaaaaa;
          transition: all .3s;
        }
      }

      .open-popover-link {
        position: absolute;
        top: 50%;
        right: 15px;
        width: 30px;
        height: 30px;
        flex: 0 auto;
        opacity: 0;
        visibility: hidden;
        transition: all .3s;
        margin-top: -15px;

        @media (max-width: 768px) {
          right: 5px;
        }

        .open-popover {
          width: 30px;
          height: 30px;
          float: right;
        }

        .more-options {
          position: relative;
          display: block;
          height: 30px;
          width: 30px;
          cursor: pointer;
          float: right;
          border-radius: 15px;
          transition: all .3s;

          &:hover {
            background-color: rgba(33, 33, 33, 0.1);
          }

          .icon {
            height: 15px;
            margin-top: -7.5px;
            transition: all .3s;
          }
        }
      }
    }

    .add-todo-block {
      position: absolute;
      top: 36px;
      left: 0;
      right: 0;
      background: #fff;
      box-shadow: 0 15px 60px rgba(0,0,0,0.16);
      padding: 10px 15px;
      z-index: 2;
      display: none;

      .form-group {
        display: flex;
      }

      .btn {
        position: relative;
        padding: 12px 15px 12px 15px;
        text-align: center;
        color: #000;
        text-transform: uppercase;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        border-radius: 15px;
        box-shadow: none;
        -webkit-transition: all .3s;
        -moz-transition: all .3s;
        transition: all .3s;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-content: stretch;
        -ms-flex-line-pack: stretch;
        align-content: stretch;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 13px;
        font-weight: 700;
        background: none;
        margin-left: 15px;

        &:hover,
        &:focus {
          -webkit-box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.2);
          -moz-box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.2);
          box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.2);
        }
      }
    }

    .dashboard-inner-title {
      position: relative;
      display: block;
      text-align: center;
      font-size: 22px;
      font-weight: 600;
      color: #666666;
      padding: 38px 0;

      @media (max-width: 768px) {
        font-size: 18px;
        padding: 15px 0;
      }

      b {
        position: relative;
        font-weight: 600;
        padding: 0 30px;
        background: #ececec;
        z-index: 2;

        @media (max-width: 768px) {
          padding: 0 10px;
        }
      }

      &:before {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        content: "";
        border-top: 1px solid #cccccc;
        z-index: 1;
      }
    }

    .case-block {
      .case-title {
        display: block;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.4px;
        color: #333333;
        margin-bottom: 5px;
      }

      .case-person-info {
        position: relative;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: stretch;
        align-items: center;

        @media (max-width: 576px) {
          -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        }

        &:first-child {
          border-top: 0;
        }
      }

      .person-block {
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;
        padding: 15px;
        padding-left: 0;

        @media (max-width: 576px) {
          -webkit-flex: 1 1 100%;
          -ms-flex: 1 1 100%;
          flex: 1 1 100%;
        }

        .inner {
          display: table;
          height: 45px;
          width: 100%;
          margin: 0;
          padding: 0;

          .avatar,
          .name {
            display: table-cell;
            vertical-align: middle;
          }

          .avatar {
            width: 45px;

            img {
              width: 100%;
              border-radius: 100px;
            }
          }

          .name {
            font-size: 13px;
            color: #a3a3a3;
            padding-left: 15px;

            b {
              display: block;
              font-weight: 600;
              color: #000;
            }
          }
        }
      }

      .person-info {
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;

        @media (max-width: 576px) {
          -webkit-flex: 1 1 100%;
          -ms-flex: 1 1 100%;
          flex: 1 1 100%;
          padding: 0 15px 15px;
        }

        .case-title {
          display: block;
          font-size: 11px;
          color: #666666;
          font-weight: 600;
          text-transform: uppercase;
        }

        .case-val {
          display: block;
          font-size: 13px;
          font-weight: 400;
          color: #999999;
        }
      }

      .case-content {
        display: flex;
        flex-wrap: wrap;
        margin: -15px;

        .item {
          flex: 1 calc(50% - 30px);
          margin: 15px;
          border: 1px solid #e6e6e6;
          border-radius: 12px;
          display: flex;
          min-height: 105px;
          align-items: center;
          justify-content: center;
          transition: all .3s;

          &.tasks {
            &:hover {
              border-color: #ff9800;
            }

            .item-title {
              .count {
                background: #ff9800;
              }
            }
          }

          &.messages {
            &:hover {
              border-color: #00bcd4;
            }
            .item-title {
              .count {
                background: #00bcd4;
              }
            }
          }

          &.deadlines {
            &:hover {
              border-color: #ff5722;
            }
            .item-title {
              .count {
                background: #ff5722;
              }
            }
          }

          &.documents {
            &:hover {
              border-color: #2196f3;
            }
            .item-title {
              .count {
                background: #2196f3;
              }
            }
          }

          &:hover {
            .item-title {
              b {
                color: #000;
              }
            }
          }

          .item-title {
            display: inline-block;
            font-size: 13px;
            letter-spacing: 0.3px;
            color: #666666;
            transition: all .3s;
            text-align: center;

            b {
              position: relative;
              display: inline-block;
              margin-bottom: 7px;
              font-size: 22px;
              font-weight: 600;
              transition: all .3s;
              color: #666;
            }

            small {
              display: block;
              font-size: 13px;
            }

            .count {
              position: absolute;
              top: -5px;
              right: -20px;
              height: 15px;
              -webkit-border-radius: 15px;
              border-radius: 15px;
              font-style: normal;
              font-size: 10px;
              font-weight: 600;
              text-align: center;
              padding: 4px 5.5px;
              line-height: 1em;
              color: #ffffff;
              margin-top: -7.5px;
            }
          }
        }
      }
    }

    .new-case-pin {
      &:hover {
        .card-body {
          background: rgba(0,0,0,0.04);
        }
      }
      .card-body {
        background: #f7f7f7;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: all .3s;

        @media (max-width: 991px) {
          padding: 30px;
        }

        .title {
          display: block;
          font-size: 22px;
          font-weight: 600;
          text-align: center;
          color: #aaaaaa;
          margin-bottom: 30px;
        }

        .icon-pin-case {
          display: block;
          width: 60px;
          height: 60px;
          background: url(../img/icons/@2x/add-pin-btn@2x.png) 0 0 no-repeat;
          background-size: 100% auto;
          margin-bottom: 30px;
        }

        .semi-title {
          display: block;
          font-size: 16px;
          color: #aaa;
          font-weight: 600;
          letter-spacing: 0.4px;
          margin-bottom: 15px;
        }

        .pin-info {
          display: block;
          font-size: 13px;
          color: #aaa;
        }
      }
    }
  }
}

// styles for the page 'Dashboard Manager'

.dashboard-manager {

  .row.top {
    .ks-search-form {
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -22.5px;

      // @media(max-width: 1500px) {
      //   height: 90px;
      //   display: -webkit-box;
      //   display: -webkit-flex;
      //   display: -ms-flexbox;
      //   -js-display: flex;
      //   display: flex;
      //   -webkit-box-align: center;
      //   -webkit-align-items: center;
      //   -ms-flex-align: center;
      //   align-items: center;
      //   margin: 0;
      //   padding: 0 20px;
      // }

      @media (max-width: 1225px) {
        // position: static;
        // padding: 10px 5px;
        // border-bottom: 1px solid #dee0e1;
        // height: auto;
        // width: 100%;
        right: 15px;
      }

      @media (max-width: 768px) {
        display: none;;
      }

      & > .ks-wrapper {
        // @media (max-width: 1300px) {
        //   display: none;
        // }

        @media (max-width: 1225px) {
          width: 100%;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          -js-display: flex;
          display: flex;
          -webkit-box-align: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
        }

        .input-icon {
          @media (max-width: 1225px) {
            width: 100%;
          }
        }
      }

      .ks-search-close,
      .ks-search-open {
        display: none;
        width: 20px;
        height: 20px;

        @media (max-width: 1300px) {
          display: inline-block;
          font-size: 20px;
          position: relative;
          top: 1px;
        }

        @media (max-width: 1225px) {
          // display: none;
        }
      }

      .ks-search-close {
        display: none;
        margin-left: 20px;

        @media (max-width: 1300px) {
          // display: block;
        }

        @media (max-width: 1225px) {
          display: none;
        }
      }

      .form-control {
        width: 320px;
        height: 45px;
        border: 1px solid #212121;
        border-radius: 12px;
        background: #212121;
        font-size: 13px;
        color: #adadad;
        padding-left: 35px;
        transition: all .3s;

        @media (max-width: 1225px) {
          width: 100%;
        }

        &:focus {
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
          border: 1px solid #adadad;
        }
      }

      .icon-addon {
        position: absolute;
        padding: 0;
        top: 0;
        left: 0;
        padding-right: 6px;
        border: 0;
        background: none;
        cursor: pointer;
        color: rgba(58,82,155,.6);
        font-size: 12px;
        width: 36px;
        height: 100%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        -js-display: flex;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;

        .icon {
          opacity: 0.5;
          -webkit-background-size: 100% auto;
          -moz-background-size: 100% auto;
          background-size: 100% auto;
          transition: all .3s;
        }

        &:hover {
          .icon {
            opacity: 1;
          }
        }
      }
    }
  }

  .row-outside {
    position: relative;
    display: flex;
    margin: -60px -7.5px 30px;
    overflow: hidden;

    @media (max-width: 768px) {
      margin-left: -5px;
      margin-right: -5px;
    }

    .card-link {
      position: relative;
      flex: 0 calc(100% - 30px);
      margin: 0 7.5px;
      display: flex;
      min-height: 60px;
      border-radius: 12px 12px 0 0;
      padding: 20px 15px 16px;
      transform: translateY(15px);
      transition: all .3s;

      @media (max-width: 768px) {
        flex: 0 calc(100% - 10px);
        margin: 0 5px;
      }

      &.active {
        transform: translateY(0);
      }

      &:before {
        position: absolute;
        top: 50%;
        right: 25px;
        content: "";
        background: url(../img/icons/@2x/angle-right--white@2x.png) 0 0 no-repeat;
        background-size: 100% auto;
        width: 6px;
        height: 9px;
        margin-top: -4.5px;
        opacity: 0;
        visibility: hidden;
        transition: all .3s;

        @media (max-width: 1280px) {
          right: 10px;
        }
      }

      &:hover {
        opacity: 1;
        transform: translateY(0);

        &:before {
          opacity: 1;
          visibility: visible;
        }
      }

      .icon {
        left: 15px;
        margin-left: 0;
        background-size: 100% auto;

        @media (max-width: 768px) {
          left: 50%;
          margin-left: -10px;
          opacity: 0.7 !important;
        }
      }

      .title {
        position: relative;
        display: flex;
        font-size: 13px;
        letter-spacing: 0.3px;
        color: #ffffff;
        padding-left: 43px;
        align-items: center;

        @media (max-width: 1280px) {
          
          font-size: 11px;
          padding-left: 40px;
        }

        @media (max-width: 768px) {
          padding-left: 23px;
          display: none;
        }

        b {
          font-size: 22px;
          font-weight: normal;
          letter-spacing: -0.2px;
          padding-right: 15px;

          @media (max-width: 1280px) {
            font-size: 18px;
            padding-right: 10px;
          }

          @media (max-width: 991px) {
            display: none;
          }
        }

        i {
          font-style: normal;


        }
      }

      &.employee {
        background: #6338B1;

        .icon {
          width: 32px;
          height: 32px;
          margin-top: -16px;
          opacity: 0.2;

          @media (max-width: 1280px) {
            background-size: 100% auto;
            width: 20px;
            height: 20px;
            margin-top: -10px;
          }
        }
      }

      &.cases {
        background: #ff9800;

        .icon {
          width: 32px;
          height: 32px;
          margin-top: -16px;
          opacity: 0.2;

          @media (max-width: 1280px) {
            background-size: 100% auto;
            width: 20px;
            height: 20px;
            margin-top: -10px;
          }
        }
      }

      &.deadline {
        background: #ff5722;

        .icon {
          width: 32px;
          height: 32px;
          margin-top: -16px;
          opacity: 0.2;

          @media (max-width: 1280px) {
            background-size: 100% auto;
            width: 20px;
            height: 20px;
            margin-top: -10px;
          }
        }
      }

      &.tasks {
        background: #ffc107;

        .icon {
          width: 32px;
          height: 32px;
          margin-top: -15px;
          opacity: 0.3;

          @media (max-width: 1280px) {
            background-size: 100% auto;
            width: 20px;
            height: 20px;
            margin-top: -10px;
          }
        }
      }

      &.meeting {
        background: #ffc107;

        .icon {
          width: 32px;
          height: 32px;
          margin-top: -16px;
          opacity: 0.3;

          @media (max-width: 1280px) {
            background-size: 100% auto;
            width: 20px;
            height: 20px;
            margin-top: -10px;
          }
        }
      }
    }
  }

  .row-inside {
    display: flex;
    margin: -7.5px;
    flex-wrap: wrap;

    .card-inside {
      flex: 0 calc(25% - 15px);
      margin: 7.5px;
      position: relative;
      border-radius: 18px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
      background-color: #ffffff;
      padding-bottom: 60px;

      @media (max-width: 1280px) {
        flex: 0 calc(33.3333% - 15px);
      }

      @media (max-width: 991px) {
        flex: 0 calc(50% - 15px);
      }

      @media (max-width: 768px) {
        flex: 0 calc(100% - 15px);
      }

      .first-line {
        position: relative;
        padding: 23px 20px 21px;

        .person-block {
          -webkit-flex: 1 1 auto;
          -ms-flex: 1 1 auto;
          flex: 1 1 auto;
          -webkit-align-self: auto;
          -ms-flex-item-align: auto;
          align-self: auto;
          padding-left: 0;

          @media (max-width: 576px) {
            -webkit-flex: 1 1 100%;
            -ms-flex: 1 1 100%;
            flex: 1 1 100%;
          }

          .inner {
            display: table;
            height: 45px;
            width: 100%;
            margin: 0;
            padding: 0;

            .avatar,
            .name {
              display: table-cell;
              vertical-align: middle;
            }

            .avatar {
              width: 45px;

              img {
                width: 100%;
                border-radius: 100px;
              }
            }

            .name {
              font-size: 13px;
              color: #a3a3a3;
              padding-left: 15px;

              b {
                display: block;
                font-weight: 600;
                color: #000;
              }
            }
          }
        }

        .emp_offline{
          font-size: 13px;
          font-weight: 400;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.54;
          letter-spacing: normal;
          text-align: left;
          color: #999;
          display:inline-block;
          padding-left:15px;
          position: relative;
          &:before{
            content:'';
            display:block;
            position:absolute;
            width: 8px;
            height: 8px;
            top: 50%;
            left: 0px;
            background: #666666;
            border-radius: 15px;
            margin-top: -4px;
          }
        }

        .emp_online{
          font-size: 13px;
          font-weight: 400;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.54;
          letter-spacing: normal;
          text-align: left;
          color: #999;
          display:inline-block;
          padding-left:15px;
          position: relative;
          &:before{
            content:'';
            display:block;
            position:absolute;
            width: 8px;
            height: 8px;
            top: 50%;
            left: 0px;
            background: #4caf50;
            border-radius: 15px;
            margin-top: -4px;
          }
        }

        .btn {
          position: absolute;
          top: 10px;
          right: 10px;
          width: 30px;
          height: 30px;
          padding: 0;
          -webkit-border-radius: 100px;
          border-radius: 100px;
          -webkit-box-shadow: 0 3px 6px 0 rgba(0,0,0,0.16);
          box-shadow: 0 3px 6px 0 rgba(0,0,0,0.16);
          opacity: 1;
          -webkit-transition: all .3s;
          -o-transition: all .3s;
          transition: all .3s;
          background: #4caf50;

          &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            background: url(../img/icons/@2x/icon-plus--white@2x.png) 0 0 no-repeat;
            width: 11px;
            height: 11px;
            -webkit-background-size: 100% auto;
            background-size: 100% auto;
            margin-top: -5.5px;
            margin-left: -5.5px;
            content: "";
            -webkit-transition: all .3s;
            -o-transition: all .3s;
            transition: all .3s;
          }
        }
      }

      .second-line {
        border-top: 1px solid #e6e6e6;
        padding: 18px 15px;

        .links-group {
          display: flex;
          justify-content: space-between;

          .link-item {
            position: relative;
            display: block;
            text-align: center;
            font-size: 22px;
            font-weight: 600;
            color: #000;
            flex: 0 25%;

            &.cases {
              &:hover {
                color: #ff9800;

                b {
                  color: #ff9800;
                }
              }
            }

            &.deadlines {
              &:hover {
                color: #ff5722;

                b {
                  color: #ff5722;
                }
              }
            }

            &.meetings {
              &:hover {
                color: #00bcd4;

                b {
                  color: #00bcd4;
                }
              }
            }

            &.tasks {
              &:hover {
                color: #ffc107;

                b {
                  color: #ffc107;
                }
              }
            }

            b {
              display: block;
              font-size: 11px;
              letter-spacing: 0.3px;
              color: #000;
              font-weight: 400;
              text-transform: capitalize;
              margin-top: 5px;
              transition: all .3s;
            }

            &.important {
              &:before {
                position: absolute;
                top: -7px;
                // right: 27px;
                margin-left: 20px;
                content: "";
                background: url(../img/icons/@2x/icon-flame--red@2x.png) 0 0 no-repeat;
                background-size: 100% auto;
                width: 14px;
                height: 15px;
              }
            }
          }
        }
      }

      .third-line {
        background: #f7f7f7;
        min-height: 75px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .empty-block {
          display: block;
          font-size: 13px;
          letter-spacing: 0.3px;
          text-align: center;
          color: #999999;
          min-height: auto;
        }

        .inner-block {
          font-size: 13px;
          color: #999999;

          b {
            display: block;
            font-size: 13px;
            font-weight: 600;
            color: #000000;
          }

          .time {
            display: block;
            font-size: 22px;
            color: #000000;
          }
        }
      }
    }
  }
}

.ks-contacts .content .dashboard-manager-deadlines {
  .ks-page-content .custom-scroll-table .simplebar-track.horizontal {
    bottom: 0 !important;
  }
  .row.top h3 {
    padding-right: 177px;

    @media (max-width: 768px) {
      padding-right: 0;
    }
  }

  .row-outside {
    margin-bottom: 0;
  }

  // .custom-scroll-table {
  //   margin: 0 -30px;
  //   width: auto !important;
  // }
  .contact-content {
    .table {
      min-width: 1360px !important;

      tbody {
        tr:hover {
          td {
            background: rgba(255, 87, 34, 0.03);
          }
        }
      }
      th {
        padding: 20px 10px 20px 10px;

        &:first-child {
          padding-left: 30px;
        }
      }

      td {
        padding: 25px 10px 25px 10px;
        vertical-align: top !important;

        &:first-child {
          padding-left: 30px;
        }
      }

      .black {
        color: #000;
      }

      .bold {
        font-weight: 600;
      }

      .case-link {
        &:hover {
          color: #ff5722;
        }
      }

      .important {
        position: relative;
        padding-left: 20px;

        &:before {
          position: absolute;
          top: 50%;
          left: 0;
          content: "";
          background: url(../img/icons/@2x/icon-flame--red@2x.png) 0 0 no-repeat;
          -webkit-background-size: 100% auto;
          background-size: 100% auto;
          width: 14px;
          height: 15px;
          margin-top: -7.5px;
        }
      }

      .deadline-content-cell {
        // flex: 1 0 375px;
        max-width: 475px;
        min-width: 300px;
        width: 375px;
      }

      .deadline-list-block {
        .list-link {
          display: block;
          color: #000;
          font-weight: 600;
          margin-bottom: 5px;

          &:hover {
            color: #ff5722;
          }
        }

        .description {
          text-overflow: ellipsis;
          overflow: hidden; 
          width: 100%;
          height: 1.4em; 
          white-space: nowrap;
          color: #999;
          font-size: 13px;
          margin-bottom: 15px;
        }

        .attachment-block {
          display: -ms-flexbox;
          display: -webkit-flex;
          display: -webkit-box;
          display: flex;
          -webkit-flex-direction: row;
          -ms-flex-direction: row;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          flex-direction: row;
          -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          -webkit-justify-content: flex-start;
          -ms-flex-pack: start;
          -webkit-box-pack: start;
          justify-content: flex-start;
          -webkit-align-content: stretch;
          -ms-flex-line-pack: stretch;
          align-content: stretch;
          -webkit-align-items: center;
          -ms-flex-align: center;
          -webkit-box-align: center;
          align-items: center;

          .btn-file {
            -webkit-flex: 1 1 auto;
            -ms-flex: 1 1 auto;
            -webkit-box-flex: 1;
            flex: 1 1 auto;
            position: relative;
            padding: 12px 15px 12px 25px;
            text-align: center;
            background: none;
            -webkit-border-radius: 15px;
            border-radius: 15px;
            -webkit-transition: all .3s;
            -o-transition: all .3s;
            transition: all .3s;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: -webkit-box;
            display: flex;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            flex-direction: row;
            -webkit-flex-wrap: nowrap;
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-align-content: stretch;
            -ms-flex-line-pack: stretch;
            align-content: stretch;
            -webkit-align-items: center;
            -ms-flex-align: center;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-flex: 0;
            flex: 0 auto;
            font-size: 13px;
            font-weight: 600;
            background: none;
            box-shadow: none;
            border: 0;
            padding: 5px 15px 5px 25px;

            &.hidden {
              padding: 0;
              width: 0;
              height: 0;
            }

            &:focus {
              box-shadow: none;
            }

            .icon {
              left: 5px;
              margin-left: 0;
            }

            .ks-text {
              color: #000;
              font-weight: 600;
              -webkit-transition: all .3s;
              -moz-transition: all .3s;
              -ms-transition: all .3s;
              -o-transition: all .3s;
              transition: all .3s;
            }

            &:hover {
              background: #f2fff3;

              .ks-text {
                color: #4caf50;
              }
            }
          }

          .btn-more {
            background: none;
            margin-bottom: 10px;

            &:focus {
              box-shadow: none;
            }

            &:hover {
              .ks-text {
                color: #000;
              }
            }

            .ks-text {
              color: #828282;
              -webkit-transition: all .3s;
              -moz-transition: all .3s;
              -ms-transition: all .3s;
              -o-transition: all .3s;
              transition: all .3s;
            }
          }
        }
      }

      .progress {
        position: relative;
        display: block;
        width: 100px;
        height: 25px;
        -webkit-border-radius: 100px;
        border-radius: 100px;
        font-size: 11px;
        color: #fff;
        line-height: 1.5em;
        padding: 5px 0;
        z-index: 2;
        -webkit-transition: all .3s;
        -o-transition: all .3s;
        transition: all .3s;
        text-align: center;

        &.in-progress {
          background: #ffc107;
          color: #fff;
        }

        &.done {
          background: #4caf50;
          color: #fff;
        }

        &.not-done {
          background: #ff5722;
          color: #fff;
        }
      }

      .progress-title {
        margin-top: 15px;
        
        &.done {
          color: #4caf50;
        }
      }

      .cell-td {
        position: relative;
        top: -5px;
      }

      .options-cell .btn:hover {
        background: rgba(255, 87, 34, 0.1);
        color: #ff5722;
      }

      .open-popover-link .more-options {
        position: relative;
        display: block;
        height: 30px !important;
        width: 30px !important;
        cursor: pointer;
        -webkit-border-radius: 15px;
        border-radius: 15px;
        -webkit-transition: all .3s;
        -o-transition: all .3s;
        transition: all .3s;
        &:hover {
          background: rgba(255, 87, 34, 0.1);

          .icon {
            background: url(../img/icons/@2x/icon-more--red@2x.png) 0 0 no-repeat !important;
            background-size: auto 100% !important;
          }
        }
        .icon {
          height: 15px;
          margin-top: -7.5px;
          -webkit-transition: all .3s;
          -o-transition: all .3s;
          transition: all .3s;
        }
      }
    }
  }
}

.invite-people {
  .dashboard-block {
    .dashboard-wrapper {
      justify-content: center;
    }

    .card {
      margin-top: 0;
      max-width: 800px;
      min-height: auto;
    }

    .card-body {
      padding-bottom: 0;
    }

    .card-header .title {
      padding-left: 0;
    }
  }

  &.profile-content .table-heading {
    min-height: 100px;
  }

  .invite-content {
    padding: 100px 0;
    text-align: center;

    .invite-title {
      display: block;
      margin: 0;
      padding: 0;
      color: #000;
      font-size: 20px;
      font-weight: 600;
      line-height: 1.2em;
      margin-bottom: 20px;
    }

    .invite-semi-title {
      display: block;
      margin: 0;
      padding: 0;
      color: #000;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.2em;
    }
  }

  .add-person {
    position: relative;
    bottom: 15px;
    left: 15px;
    width: 30px;
    height: 30px;
    padding: 0;
    -webkit-border-radius: 100px;
    border-radius: 100px;
    -webkit-box-shadow: 0 3px 6px 0 rgba(0,0,0,0.16);
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.16);
    opacity: 1;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    background: #00bcd4;

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      background: url(../img/icons/@2x/icon-plus--white@2x.png) 0 0 no-repeat;
      width: 11px;
      height: 11px;
      -webkit-background-size: 100% auto;
      background-size: 100% auto;
      margin-top: -5.5px;
      margin-left: -5.5px;
      content: "";
      -webkit-transition: all .3s;
      -o-transition: all .3s;
      transition: all .3s;
    }
  }

  .table {
    min-width: 800px !important;
  }

  .custom-scroll-table .simplebar-track.horizontal {
    bottom: 20px !important;
  }
}