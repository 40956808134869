.icon{
    &.icon-maximize2{
    position: absolute;
    top: 50%;
    left: 50%;
    //background: url('../img/icons/@2x/icon-forward--cyan@2x.png') 0 0 no-repeat;
    background: url('../img/icons/@2x/icon-maximize--white2@2x.png') 0 0 no-repeat;
    width: 15px;
    height: 15px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -7.5px;
    margin-left: -7.5px;
    }
     &.icon-maximize{
    position: absolute;
    top: 50%;
    left: 50%;
    //background: url('../img/icons/@2x/icon-forward--cyan@2x.png') 0 0 no-repeat;
    background: url('../img/icons/@2x/icon-maximize--white@2x.png') 0 0 no-repeat;
    width: 15px;
    height: 15px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -7.5px;
    margin-left: -7.5px;
    }
    &.icon-arrowinblue{
         position: absolute;
    top: 50%;
    left: 0%;
    //background: url('../img/icons/@2x/icon-forward--cyan@2x.png') 0 0 no-repeat;
    background: url('../img/icons/@2x/icon-arrow-in--blue@2x.png') 0 0 no-repeat;
    width: 8px;
    height: 8px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -4px;
    margin-left: 0px;
    }
     &.icon-collapse{
    position: absolute;
    top: 50%;
    left: 50%;
    //background: url('../img/icons/@2x/icon-forward--cyan@2x.png') 0 0 no-repeat;
    background: url('../img/icons/@2x/icon-collapse--white@2x.png') 0 0 no-repeat;
    width: 15px;
    height: 15px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -7.5px;
    margin-left: -7.5px;
    }
    &.icon-arrowoutgreen{
         position: absolute;
    top: 50%;
    left: 0%;
    //background: url('../img/icons/@2x/icon-forward--cyan@2x.png') 0 0 no-repeat;
    background: url('../img/icons/@2x/icon-arrow-out--green@2x.png') 0 0 no-repeat;
    width: 8px;
    height: 8px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -4px;
    margin-left: 0px;
    }
    &.icon-forward{
    position: absolute;
    top: 50%;
    left: 50%;
    //background: url('../img/icons/@2x/icon-forward--cyan@2x.png') 0 0 no-repeat;
    background: url('../img/icons/@2x/icon-forward@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 12px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -6px;
    margin-left: -8px;
    }
    &.icon-reply-all{
        position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-reply-all@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 12px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -6px;
    margin-left: -8px;
    }
    &.icon-mail-reply{
        position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-reply2@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 12px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -6px;
    margin-left: -8px;
    }
    &.icon-grey-mail{
        position: absolute;
    top: 8px;
    left: 0%;
    background: url('../img/icons/@2x/icon-lg-email@2x.png') 0 0 no-repeat;
    width: 30px;
    height: 24.5px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    // margin-top: -15px;
    // margin-left: -12px;
    }
    &.icon-notifications{
        position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/email-nav-notifications@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -8px;
    margin-left: -8px;
    }
    &.icon-newsletter{
        position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/email-nav-newsletter@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -8px;
    margin-left: -8px;
    }
     &.icon-outbox {
        position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/email-nav-outbox@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -8px;
    margin-left: -8px;
    }
     &.icon-spam {
        position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/email-nav-spam@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -8px;
    margin-left: -8px;
    }
    &.icon-sent {
        position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/email-nav-sent@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -8px;
    margin-left: -8px;
    }
    &.icon-templates {
        position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/email-nav-templates@2x.png') 0 0 no-repeat;
    width: 13px;
    height: 16px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -6.5px;
    margin-left: -8px;
    }
    &.icon-drafts {
        position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/email-nav-drafts@2x.png') 0 0 no-repeat;
    width: 13px;
    height: 16px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -6.5px;
    margin-left: -8px;
    }
	&.icon-inbox {
		position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/email-nav-inbox@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -8px;
    margin-left: -8px;
	}
    &.icon-search-white {
        position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-search--white@2x.png') 0 0 no-repeat;
    width: 13px;
    height: 13px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -7.5px;
    margin-left: -7.5px;
    }
	&.icon-create-user-white {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/@2x/main-nav-icon-contacts@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -7.5px;
    margin-left: -7.5px;
    }						  

	&.icon-search {
		position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-search@2x.png') 0 0 no-repeat;
    width: 13px;
    height: 13px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -7px;
    margin-left: -7px;
	}

	&.icon-dashboard {
		position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/main-nav-icon-dashboard@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -8px;
    margin-left: -8px;
	}

	&.icon-cases {
		position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/main-nav-icon-cases@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -8px;
    margin-left: -8px;
	}

    &.icon-cases-black {
        position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-case@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -8px;
    margin-left: -8px;
    }

  &.icon-cases-orange {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-case--orange@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -8px;
    margin-left: -8px;
  }

  &.icon-case-white {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-case--white@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -8px;
    margin-left: -8px;
  }

	&.icon-documents {
		position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/main-nav-icon-documents@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -8px;
    margin-left: -8px;
	}

	&.icon-contacts {
		position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/main-nav-icon-contacts@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -8px;
    margin-left: -8px;
	}

  &.icon-contacts-black {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-contact@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -8px;
    margin-left: -8px;
  }

	&.icon-contacts-green {
		position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-contact--green@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -8px;
    margin-left: -8px;
	}

	&.icon-invoices {
		position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/main-nav-icon-invoices@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 13px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -6.5px;
    margin-left: -8px;
	}

	&.icon-email {
		position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/main-nav-icon-emails@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 14px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -7px;
    margin-left: -8px;
	}

	&.icon-calendar {
		position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/main-nav-icon-calendar@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 17px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -8.5px;
    margin-left: -8px;
	}

  &.icon-calendar-white {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-calendar--white@2x.png') 0 0 no-repeat;
    width: 15px;
    height: 15px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -7.5px;
    margin-left: -7.5px;
  }

  &.icon-calendar-red {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-calendar--red@2x.png') 0 0 no-repeat;
    width: 15px;
    height: 15px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -7.5px;
    margin-left: -7.5px;
  }

  &.icon-calendar-purple {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-calendar--purple@2x.png') 0 0 no-repeat;
    width: 15px;
    height: 15px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -7.5px;
    margin-left: -7.5px;
  }

  &.icon-calendar-cyan {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-calendar--cyan@2x.png') 0 0 no-repeat;
    width: 15px;
    height: 15px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -7.5px;
    margin-left: -7.5px;
  }

  &.icon-calendar-yellow {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-calendar--yellow@2x.png') 0 0 no-repeat;
    width: 15px;
    height: 15px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -7.5px;
    margin-left: -7.5px;
  }

	&.icon-team {
		position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/main-nav-icon-team@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -8px;
    margin-left: -8px;
	}

&.icon-team-white {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-team--white@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -8px;
    margin-left: -8px;
  }
	&.icon-settings {
		position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/main-nav-icon-settings@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -8px;
    margin-left: -8px;
	}

	&.icon-profile {
		position: absolute;
		top: 50%;
		left: 0;
		background: url('../img/user.png') 0 0 no-repeat;
		width: 15px;
		height: 15px;
		-webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -7.5px;
	}

	&.icon-setting {
		position: absolute;
		top: 50%;
		left: 0;
		background: url('../img/settings.png') 0 0 no-repeat;
		width: 15px;
		height: 15px;
		-webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -7.5px;
	}

	&.icon-help {
		position: absolute;
		top: 50%;
		left: 0;
		background: url('../img/info.png') 0 0 no-repeat;
		width: 15px;
		height: 15px;
		-webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -7.5px;
	}

	&.icon-logout {
		position: absolute;
		top: 50%;
		left: 0;
		background: url('../img/logout.png') 0 0 no-repeat;
		width: 15px;
		height: 15px;
		-webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -7.5px;
	}

	&.icon-clear {
		position: absolute;
		top: 50%;
		left: 0;
		background: url('../img/icons/@2x/icon-close--white@2x.png') 0 0 no-repeat;
		width: 15px;
		height: 15px;
		-webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -7.6px;
	}

  &.icon-clear-black {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/clear-item@2x.png') 0 0 no-repeat;
    width: 15px;
    height: 15px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -7.6px;
    margin-left: -7.6px;
  }

  &.icon-map {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/sidebar-icon-map@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -8px;
    margin-left: -8px;
  }

  &.icon-map-black {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-map@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -8px;
    margin-left: -8px;
  }

  &.icon-letter {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/sidebar-icon-letters@2x.png') 0 0 no-repeat;
    width: 20px;
    height: 14px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -7px;
    margin-left: -10px;
  }

  &.icon-filters {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/sidebar-icon-filter@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -8px;
    margin-left: -8px;
  }

  &.icon-plus {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-plus--white@2x.png') 0 0 no-repeat;
    width: 13px;
    height: 13px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -7.5px;
    margin-left: -7.5px;
  }

  &.icon-plus-black {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-plus@2x.png') 0 0 no-repeat;
    width: 11px;
    height: 11px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -5.5px;
    margin-left: -5.5px;
  }

  &.icon-case {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-case@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 14px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -7px;
    margin-left: -8px;
  }

  &.icon-angle-left {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/angle-left@2x.png') 0 0 no-repeat;
    width: 9px;
    height: 9px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -4.5px;
    margin-left: -4.5px;
  }

  &.icon-angle-left-white {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/angle-left--white@2x.png') 0 0 no-repeat;
    width: 9px;
    height: 9px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -4.5px;
    margin-left: -4.5px;
  }

  &.icon-angle-right {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/angle-right@2x.png') 0 0 no-repeat;
    width: 9px;
    height: 9px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -4.5px;
    margin-left: -4.5px;
  }

  &.icon-home,
  &.icon-work,
  &.icon-phone {
		position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-phone@2x.png') 0 0 no-repeat;
    width: 10px;
    height: 10px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -5px;
    margin-left: -5px;
	}

  &.icon-phone-white {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-phone--white@2x.png') 0 0 no-repeat;
    width: 10px;
    height: 10px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -5px;
    margin-left: -5px;
  }

	&.icon-mobile {
		position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-mobile@2x.png') 0 0 no-repeat;
    width: 10px;
    height: 10px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -5px;
    margin-left: -5px;
	}

	&.icon-dots {
		position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-more@2x.png') 0 0 no-repeat;
    width: 4px;
    height: 10px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -5px;
    margin-left: -2px;
	}

  &.icon-dots-white {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-more--white@2x.png') 0 0 no-repeat;
    width: 4px;
    height: 10px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -5px;
    margin-left: -2px;
  }

  &.icon-edit-contact-white {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-edit--white@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 17px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -8.5px;
    margin-left: -8px;
  }

  &.icon-edit {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-edit@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 17px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -8.5px;
    margin-left: -8px;
  }

  &.icon-edit-white {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-edit--white@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 17px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -8.5px;
    margin-left: -8px;
  }

  &.icon-edit-purple {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-edit--purple@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 17px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -8.5px;
    margin-left: -8px;
  }

  &.icon-appointment-white {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-appointment--white@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -8px;
    margin-left: -8px;
  }

  &.icon-share-team-white {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url("../img/icons/@2x/main-nav-icon-contacts@2x.png") 0 0 no-repeat;
    // background: url('../img/icons/@2x/icon-edit--white@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 17px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -8.5px;
    margin-left: -8px;
  }

  &.icon-qr-code-white {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-qrcode--white@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -8px;
    margin-left: -8px;
  }

  &.icon-delete-white {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-delete--white@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -8px;
    margin-left: -8px;
  }

  &.icon-delete {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-delete@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -8px;
    margin-left: -8px;
  }

  &.icon-mail {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-email@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 13px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -6.5px;
    margin-left: -8px;
  }

  &.icon-mail-white {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-email--white@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 13px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -6.5px;
    margin-left: -8px;
  }

  &.icon-task {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-tick-border@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 15px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -7.5px;
    margin-left: -8px;
  }

  &.icon-task-white {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-task--white@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 15px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -7.5px;
    margin-left: -8px;
  }

  &.icon-file {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-file@2x.png') 0 0 no-repeat;
    width: 14px;
    height: 16px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -8px;
    margin-left: -7px;
  }

  &.icon-meeting {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-meeting@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -8px;
    margin-left: -8px;
  }

  &.icon-team-black {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-team@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -8px;
    margin-left: -8px;
  }

  &.icon-team-purple {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-team--purple@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -8px;
    margin-left: -8px;
  }

  &.icon-meeting-white {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-meeting--white@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -8px;
    margin-left: -8px;
  }

  &.icon-deadline {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-calendar@2x.png') 0 0 no-repeat;
    width: 15px;
    height: 15px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -8px;
    margin-left: -7.5px;
  }

  &.icon-invoice {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-invoice@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 12px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -6px;
    margin-left: -8px;
  }

  &.icon-time-entry {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-time@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -8px;
    margin-left: -8px;
  }
  &.icon-black-desktop {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-device-mac@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 14px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -7px;
    margin-left: -8px;
  }
  &.icon-blue-desktop {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-device-mac-blue@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 14px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -7px;
    margin-left: -8px;
  }
  &.icon-iphone {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-device-iphone@2x.png') 0 0 no-repeat;
    width: 9px;
    height: 16px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -8px;
    margin-left: -4px;
  }
  &.icon-android-green {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-device-android@2x.png') 0 0 no-repeat;
    width: 9px;
    height: 16px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -8px;
    margin-left: -4px;
  }

  &.icon-time-entry-red {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-time--red@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -8px;
    margin-left: -8px;
  }

  &.icon-time-entry-cyan {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-time--cyan@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -8px;
    margin-left: -8px;
  }

  &.icon-expense {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-expense@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 14px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -7px;
    margin-left: -8px;
  }

  &.icon-message {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-email@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 13px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -6.5px;
    margin-left: -8px;
  }

  &.icon-message-white {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-email--white@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 13px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -6.5px;
    margin-left: -8px;
  }

  &.icon-date {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-date@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -8px;
    margin-left: -8px;
  }

  &.icon-attach {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-attach@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 15px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -8px;
    margin-left: -8px;
  }

  &.icon-attach-white {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-attach--white@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 15px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -8px;
    margin-left: -8px;
  }

  &.icon-copy-white {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-copy--white@2x.png') 0 0 no-repeat;
    width: 14px;
    height: 16px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -8px;
    margin-left: -7px;
  }

  &.icon-call-white {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-sm-call--white@2x.png') 0 0 no-repeat;
    width: 14px;
    height: 16px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -8px;
    margin-left: -7px;
  }

  &.icon-log-white {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-sm-history--white@2x.png') 0 0 no-repeat;
    width: 14px;
    height: 16px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -8px;
    margin-left: -7px;
  }

  &.icon-history-black {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-history--black@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 15px;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    margin-top: -7.5px;
    margin-left: -8px;
  }

  &.icon-copy {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-copy@2x.png') 0 0 no-repeat;
    width: 14px;
    height: 16px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -8px;
    margin-left: -7px;
  }

  &.icon-upload {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/cloud-upload@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 14px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -7px;
    margin-left: -8px;
  }

  &.icon-upload-white {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/cloud-upload--white@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 14px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -7px;
    margin-left: -8px;
  }

  &.icon-clear-item {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/clear-item@2x.png') 0 0 no-repeat;
    width: 10px;
    height: 10px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -5px;
    margin-left: -5px;
  }

  &.icon-syncronize {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-syncronize@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 14px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -7px;
    margin-left: -8px;
     &.icon-syncronize-white{
        background: url('../img/icons/@2x/icon-sync--white@2x.png') 0 0 no-repeat;
         -webkit-background-size: auto 100%;
        -moz-background-size: auto 100%;
        background-size: auto 100%;
     }
  }

  &.icon-folder {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-folder@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 15px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -7.5px;
    margin-left: -8px;
  }

  &.icon-folder-filled {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-group--filled@2x.png') 0 0 no-repeat;
    width: 20px;
    height: 18px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -8px;
    margin-left: -10px;
  }

  &.icon-folder-green {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-folder--green@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 15px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -7.5px;
    margin-left: -8px;
  }

  &.icon-folder-white {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-folder--white@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 15px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -7.5px;
    margin-left: -8px;
  }

  &.icon-cloud-down {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-clouddown@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 14px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -7px;
    margin-left: -8px;
  }

  &.icon-cloud-down-white {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-clouddown--white@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 14px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -7px;
    margin-left: -8px;
  }

  &.icon-file-doc {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/@2x/file-doc@2x.png') 0 0 no-repeat;
    width: 13px;
    height: 16px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -8px;
    margin-left: -6.5px;
  }
  
  &.attach-icon-error {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/attach-icon-error@2x.png') 0 0 no-repeat;
    width: 15px;
    height: 15px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -7.5px;
    margin-left: -7.5px;
  }
   &.attach-icon-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/attach-icon-loader@2x.png') 0 0 no-repeat;
    width: 15px;
    height: 15px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -7.5px;
    margin-left: -7.5px;
  }
  &.chechbox-tick--cyan {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/chechbox-tick--cyan@2x.png') 0 0 no-repeat;
    width: 15px;
    height: 15px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -7.5px;
    margin-left: -7.5px;
  }


  &.icon-file-doc-large {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/@2x/file-doc-lg@2x.png') 0 0 no-repeat;
    width: 26px;
    height: 32px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -16px;
    margin-left: -23px;
  }

  &.icon-file-xls {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/@2x/file-xls@2x.png') 0 0 no-repeat;
    width: 13px;
    height: 16px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -8px;
    margin-left: -6.5px;
  }

  &.icon-file-xls-large {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/@2x/file-xls-lg@2x.png') 0 0 no-repeat;
    width: 26px;
    height: 32px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -16px;
    margin-left: -23px;
  }

  &.icon-file-ppt {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/@2x/file-ppt@2x.png') 0 0 no-repeat;
    width: 13px;
    height: 16px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -8px;
    margin-left: -6.5px;
  }

  &.icon-file-ppt-large {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/@2x/file-ppt-lg@2x.png') 0 0 no-repeat;
    width: 26px;
    height: 32px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -16px;
    margin-left: -23px;
  }

  &.icon-file-pdf {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/@2x/file-pdf@2x.png') 0 0 no-repeat;
    width: 13px;
    height: 16px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -8px;
    margin-left: -6.5px;
  }

  &.icon-file-pdf-large {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/@2x/file-pdf-lg@2x.png') 0 0 no-repeat;
    width: 26px;
    height: 32px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -16px;
    margin-left: -23px;
  }

  &.icon-file-text {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/@2x/file-txt@2x.png') 0 0 no-repeat;
    width: 13px;
    height: 16px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -8px;
    margin-left: -6.5px;
  }

  &.icon-file-text-large {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/@2x/file-text-lg@2x.png') 0 0 no-repeat;
    width: 26px;
    height: 32px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -16px;
    margin-left: -23px;
  }

  &.icon-file-zip {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/@2x/file-zip@2x.png') 0 0 no-repeat;
    width: 13px;
    height: 16px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -8px;
    margin-left: -6.5px;
  }

  &.icon-file-zip-large {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/@2x/file-zip-lg@2x.png') 0 0 no-repeat;
    width: 26px;
    height: 32px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -16px;
    margin-left: -23px;
  }

  &.icon-file-pic {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/@2x/file-image@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -8px;
    margin-left: -8px;
  }

  &.icon-file-pic-large {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/@2x/file-pic-lg@2x.png') 0 0 no-repeat;
    width: 26px;
    height: 32px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -16px;
    margin-left: -23px;
  }

  &.icon-file-audio {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/@2x/file-audio@2x.png') 0 0 no-repeat;
    width: 13px;
    height: 16px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -8px;
    margin-left: -6.5px;
  }

  &.icon-file-audio-large {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/@2x/file-audio-lg@2x.png') 0 0 no-repeat;
    width: 26px;
    height: 32px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -16px;
    margin-left: -23px;
  }
  &.icon-checkmark--white{
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/checkmark--white@2x.png') 0 0 no-repeat;
    width: 13px;
    height: 13px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -6.5px;
    margin-left: -6.5px;
  }


  &.icon-file-video {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/@2x/file-video@2x.png') 0 0 no-repeat;
    width: 13px;
    height: 16px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -8px;
    margin-left: -6.5px;
  }

  &.icon-file-video-large {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/@2x/file-video-lg@2x.png') 0 0 no-repeat;
    width: 26px;
    height: 32px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -16px;
    margin-left: -23px;
  }

  &.icon-info {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-info@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -8px;
    margin-left: -8px;
  }

  &.icon-share-white {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-share--white@2x.png') 0 0 no-repeat;
    width: 15px;
    height: 16px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -8px;
    margin-left: -7.5px;
  }

  &.icon-share {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-share@2x.png') 0 0 no-repeat;
    width: 15px;
    height: 16px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -8px;
    margin-left: -7.5px;
  }

  &.icon-move {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-move@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -8px;
    margin-left: -8px;
  }

  &.icon-lock {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-lock@2x.png') 0 0 no-repeat;
    width: 15px;
    height: 16px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -8px;
    margin-left: -7.5px;
  }

  &.icon-history {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-history@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 15px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -7.5px;
    margin-left: -8px;
  }

  &.icon-history-white {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-history--white@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 15px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -7.5px;
    margin-left: -8px;
  }

  &.icon-back {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-back@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 12px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -6px;
    margin-left: -8px;
  }

  &.icon-burger {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-menu@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 11px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    margin-top: -5.5px;
    margin-left: -8px;
  }

  &.icon-folder-normal {
    position: absolute;
    top: 50%;
    left: 50%;
    content: "";
    background: url(../img/@2x/folder-normal@2x.png) 0 0 no-repeat;
    -webkit-background-size: auto 100%;
    background-size: auto 100%;
    width: 16px;
    height: 15px;
    margin-top: -7.5px;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    margin-top: -7.5px;
    margin-left: -8px;
  }

  &.icon-target {
    position: absolute;
    top: 50%;
    left: 50%;
    content: "";
    background: url('../img/icons/@2x/icon-target@2x.png') 0 0 no-repeat;
    background-size: auto 100%;
    width: 16px;
    height: 16px;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    margin-top: -8px;
    margin-left: -8px;
  }

  &.icon-list {
    position: absolute;
    top: 50%;
    left: 50%;
    content: "";
    background: url('../img/icons/@2x/icon-view-list@2x.png') 0 0 no-repeat;
    background-size: auto 100%;
    width: 16px;
    height: 16px;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    margin-top: -8px;
    margin-left: -8px;
  }

  &.icon-grid {
    position: absolute;
    top: 50%;
    left: 50%;
    content: "";
    background: url('../img/icons/@2x/icon-view-grid@2x.png') 0 0 no-repeat;
    background-size: auto 100%;
    width: 16px;
    height: 16px;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    margin-top: -8px;
    margin-left: -8px;
  }

  &.icon-pin {
    position: absolute;
    top: 50%;
    left: 50%;
    content: "";
    background: url('../img/icons/@2x/icon-map-pin@2x.png') 0 0 no-repeat;
    background-size: auto 100%;
    width: 13px;
    height: 16px;
    margin-top: -8px;
    margin-left: -6.5px;
  }
   &.icon-pin-white {
    position: absolute;
    top: 50%;
    left: 50%;
    content: "";
    background: url('../img/icons/@2x/icon-map-pin-white@2x.png') 0 0 no-repeat;
    background-size: auto 100%;
    width: 13px;
    height: 16px;
    margin-top: -8px;
    margin-left: -6.5px;
  }

  &.icon-time-white {
    position: absolute;
    top: 50%;
    left: 50%;
    content: "";
    background: url("../img/icon-time--white.svg") 0 0 no-repeat;
    width: 15px;
    height: 15px;
    background-size: 100% auto;
    margin-top: -7.5px;
    margin-left: -7.5px;
  }

  &.icon-remove {
    position: absolute;
    top: 50%;
    left: 50%;
    content: "";
    background: url("../img/icons/@2x/icon-minus@2x.png") 0 0 no-repeat;
    width: 16px;
    height: 16px;
    background-size: 100% auto;
    margin-top: -8px;
    margin-left: -8px;
  }

  &.icon-close-case-red {
    position: absolute;
    top: 50%;
    left: 50%;
    content: "";
    background: url("../img/icons/@2x/close-case--red@2x.png") 0 0 no-repeat;
    width: 16px;
    height: 16px;
    background-size: 100% auto;
    margin-top: -8px;
    margin-left: -8px;
  }

  &.icon-succeed {
    position: absolute;
    top: 50%;
    left: 50%;
    content: "";
    background: url("../img/icons/@2x/modal-icon-success@2x.png") 0 0 no-repeat;
    width: 30px;
    height: 30px;
    background-size: 100% auto;
    margin-top: -15px;
    margin-left: -15px;
  }

  &.icon-warning {
    position: absolute;
    top: 50%;
    left: 50%;
    content: "";
    background: url("../img/icons/@2x/modal-icon-warning@2x.png") 0 0 no-repeat;
    width: 30px;
    height: 30px;
    background-size: 100% auto;
    margin-top: -15px;
    margin-left: -15px;
  }

  &.icon-error {
    position: absolute;
    top: 50%;
    left: 50%;
    content: "";
    background: url("../img/icons/@2x/modal-icon-error@2x.png") 0 0 no-repeat;
    width: 30px;
    height: 30px;
    background-size: 100% auto;
    margin-top: -15px;
    margin-left: -15px;
  }

  &.icon-pause {
    position: absolute;
    top: 50%;
    left: 50%;
    content: "";
    background: url("../img/icons/@2x/icon-pause@2x.png") 0 0 no-repeat;
    width: 12px;
    height: 15px;
    background-size: 100% auto;
    margin-top: -6px;
    margin-left: -7.5px;
  }

  &.icon-no-calendar {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-lg-calendar-empty--grey@2x.png') 0 0 no-repeat;
    width: 30px;
    height: 30px;
    background-size: 100% auto;
    margin-top: -15px;
    margin-left: -15px;
  }

  &.icon-sync {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-sync--white@2x.png') 0 0 no-repeat;
    width: 15px;
    height: 13px;
    background-size: 100% auto;
    margin-top: -6.5px;
    margin-left: -7.5px;
  }

  &.icon-sync-grey {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-sync--grey@2x.png') 0 0 no-repeat;
    width: 15px;
    height: 13px;
    background-size: 100% auto;
    margin-top: -6.5px;
    margin-left: -7.5px;
  }

  &.icon-add-person {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-addcontact--green@2x.png') 0 0 no-repeat;
    width: 15px;
    height: 13px;
    background-size: 100% auto;
    margin-top: -6.5px;
    margin-left: -7.5px;
  }

  &.icon-add-person-black {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-addcontact--black@2x.png') 0 0 no-repeat;
    width: 15px;
    height: 13px;
    background-size: 100% auto;
    margin-top: -6.5px;
    margin-left: -7.5px;
  }

  &.icon-add-person-white {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-addcontact--white@2x.png') 0 0 no-repeat;
    width: 15px;
    height: 13px;
    background-size: 100% auto;
    margin-top: -6.5px;
    margin-left: -7.5px;
  }

  &.icon-icloud {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/sync-logo-icloud@2x.png') 0 0 no-repeat;
    width: 30px;
    height: 30px;
    background-size: 100% auto;
    margin-top: -15px;
    margin-left: -15px;
  }

  &.icon-google {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/sync-logo-google@2x.png') 0 0 no-repeat;
    width: 30px;
    height: 30px;
    background-size: 100% auto;
    margin-top: -15px;
    margin-left: -15px;
  }

  &.icon-yahoo {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/sync-logo-yahoo@2x.png') 0 0 no-repeat;
    width: 30px;
    height: 30px;
    background-size: 100% auto;
    margin-top: -15px;
    margin-left: -15px;
  }

  &.icon-outlook {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/sync-logo-outlook@2x.png') 0 0 no-repeat;
    width: 30px;
    height: 30px;
    background-size: 100% auto;
    margin-top: -15px;
    margin-left: -15px;
  }

  &.icon-print-white {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-print--white@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    background-size: 100% auto;
    margin-top: -8px;
    margin-left: -8px;
  }

  &.icon-full-white {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-fullscreen--white@2x.png') 0 0 no-repeat;
    width: 14px;
    height: 14px;
    background-size: 100% auto;
    margin-top: -7px;
    margin-left: -7px;
  }

  &.icon-remove-black {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-remove@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    background-size: 100% auto;
    margin-top: -8px;
    margin-left: -8px;
  }

  &.icon-add-company-white {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-addcompany--white@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 14px;
    background-size: 100% auto;
    margin-top: -8px;
    margin-left: -7px;
  }

  &.icon-add-company {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-add-company@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 14px;
    background-size: 100% auto;
    margin-top: -8px;
    margin-left: -7px;
  }

  &.icon-court-cyan {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-court--cyan@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    background-size: 100% auto;
    margin-top: -8px;
    margin-left: -8px;
  }

  &.icon-billing-teal {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-money--teal@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 12px;
    background-size: 100% auto;
    margin-top: -8px;
    margin-left: -6px;
  }

  &.icon-pencil {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-pencil@2x.png') 0 0 no-repeat;
    width: 10px;
    height: 10px;
    background-size: 100% auto;
    margin-top: -5px;
    margin-left: -5px;
    transition: all .3s;
  }

  &.icon-draggable {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-draggable@2x.png') 0 0 no-repeat;
    width: 9px;
    height: 15px;
    background-size: auto 100%;
    margin-top: -7.5px;
    margin-left: -4.5px;
    transition: all .3s;

    &:hover {
      background: url('../img/icons/@2x/icon-draggable-orange@2x.png') 0 0 no-repeat;
      background-size: auto 100%;
    }
    
  }
 
  

  &.icon-sm-text {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-sm-text@2x.png') 0 0 no-repeat;
    width: 10px;
    height: 7px;
    background-size: 100% auto;
    margin-top: -3.5px;
    margin-left: -5px;
    transition: all .3s;
  }

  &.icon-reply {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-reply@2x.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    background-size: 100% auto;
    margin-top: -8px;
    margin-left: -8px;
    transition: all .3s;
  }

  &.icon-to-do {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../img/icons/@2x/icon-to-do--white@2x.png') 0 0 no-repeat;
    width: 30px;
    height: 29px;
    background-size: 100% auto;
    margin-top: -14.5px;
    margin-left: -15px;
    transition: all .3s;
  }
}
