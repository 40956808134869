.ks-contacts {
	padding-top: 90px;
	position: relative;
	height: 100%;
	

	

	@media (max-width: 768px) {
    padding-top: 60px;
  }
  	.main-tabs {
		position: fixed;
		top: 90px;
		left: 0;
		right: 0;
		height: 60px;
		padding: 0 90px;
		background: #333;
		margin-top: 0;
		display: -ms-flexbox;
	    display: -webkit-flex;
	    display: flex;
	    -webkit-flex-direction: row;
	    -ms-flex-direction: row;
	    flex-direction: row;
	    -webkit-flex-wrap: nowrap;
	    -ms-flex-wrap: nowrap;
	    flex-wrap: nowrap;
	    -webkit-justify-content: center;
	    -ms-flex-pack: center;
	    justify-content: center;
	    -webkit-align-content: stretch;
	    -ms-flex-line-pack: stretch;
	    align-content: stretch;
	    -webkit-align-items: center;
	    -ms-flex-align: center;
	    align-items: center;
	    border: 0;
	    z-index: 99999;
	    box-shadow: 0 10px 20px 0px rgba(0,0,0,0.25);

	    height:auto;
	    -webkit-flex-direction: column;
	    -ms-flex-direction: column;
	    flex-direction: column;
	    position: absolute;
	    max-width:240px;
	    padding:0 30px;
	    display:none;
	    
	    &.open{
			display:block;
		}

   

    @media (max-width: 992px) {
    	// padding: 0;
    	// height: 45px;
    }

    @media (max-width: 768px) {
    	top: 60px;
    	height: 45px;
    	display: none;

    }

    .nav-item {
	    -webkit-flex: 1;
	    -ms-flex: 1;
	    flex: 1;
	    -webkit-align-self: stretch;
	    -ms-flex-item-align: stretch;
	    align-self: stretch;
	    text-align: center;
	    max-width: 180px;

	    @media (max-width: 768px) {
	    	height: 45px;
	    }

	    &.next {
	    	.nav-link {
	    		.icon {
		    		@media (max-width: 768px) {
		    			left: 50%;
		    			margin-left: -30px;
		    		}
		    	}
	    	}
	    }

    	&.prev {
    		.nav-link {
    			.icon {
		    		@media (max-width: 768px) {
		    			left: 50%;
		    			margin-left: -30px;
		    		}
		    	}
	    	}
	    }

	    .nav-link {
	    	position: relative;
	    	padding: 20px 10px 20px 35px;
	    	border: 0 none;
	    	-webkit-border-radius: 0;
	    	-moz-border-radius: 0;
	    	border-radius: 0;
	    	color: #fff;
	    	text-transform: uppercase;
	    	opacity: 0.7;
	    	font-size: 13px;
	    	cursor: pointer;

	    	@media (max-width: 992px) {
	    		padding: 12.5px 5px 12.5px 20px;
	    		font-size: 10px;
	    	}

	    	@media (max-width: 768px) {
	    		padding: 12.5px 10px 12.5px 20px;
	    	}

	    	.icon {
	    		display: inline-block;
	    		left: 13px;
	    		margin-left: 0;

	    		@media (max-width: 1225px) {
	    			left: 16px;
	    		}

	    		@media (max-width: 992px) {
	    			left: 5px;
	    		}

	    		@media (max-width: 768px) {
	    			left: 2px;
	    		}
	    	}

	    	&:before {
	    		position: absolute;
    			bottom: 0;
    			left: 0;
    			right: 0;
    			content: "";
    			border-bottom: 4px solid #333;
	    		transition: all .3s;
	    	}

	    	&:hover,
	    	&.active {
	    		background: none;
	    		color: #fff;
	    		opacity: 1;

	    		&:before {
	    			border-bottom: 4px solid #4caf50;
	    		}
	    	}
	    }
    }
	}

	// .main-tabs {
	// 	position: fixed;
	// 	top: 90px;
	// 	left: 0;
	// 	right: 0;
	// 	height: 60px;
	// 	padding: 0 90px;
	// 	background: #333;
	// 	margin-top: 0;
	// 	display: -ms-flexbox;
 //    display: -webkit-flex;
 //    display: flex;
 //    -webkit-flex-direction: row;
 //    -ms-flex-direction: row;
 //    flex-direction: row;
 //    -webkit-flex-wrap: nowrap;
 //    -ms-flex-wrap: nowrap;
 //    flex-wrap: nowrap;
 //    -webkit-justify-content: center;
 //    -ms-flex-pack: center;
 //    justify-content: center;
 //    -webkit-align-content: stretch;
 //    -ms-flex-line-pack: stretch;
 //    align-content: stretch;
 //    -webkit-align-items: center;
 //    -ms-flex-align: center;
 //    align-items: center;
 //    border: 0;
 //    z-index: 99999;
 //    box-shadow: 0 10px 20px 0px rgba(0,0,0,0.25);

   

 //    @media (max-width: 992px) {
 //    	padding: 0;
 //    	height: 45px;
 //    }

 //    @media (max-width: 768px) {
 //    	top: 60px;
 //    	height: 45px;
 //    	display: none;

 //    }

 //    .nav-item {
	//     -webkit-flex: 1;
	//     -ms-flex: 1;
	//     flex: 1;
	//     -webkit-align-self: stretch;
	//     -ms-flex-item-align: stretch;
	//     align-self: stretch;
	//     text-align: center;
	//     max-width: 180px;

	//     @media (max-width: 768px) {
	//     	height: 45px;
	//     }

	//     &.next {
	//     	.nav-link {
	//     		.icon {
	// 	    		@media (max-width: 768px) {
	// 	    			left: 50%;
	// 	    			margin-left: -30px;
	// 	    		}
	// 	    	}
	//     	}
	//     }

 //    	&.prev {
 //    		.nav-link {
 //    			.icon {
	// 	    		@media (max-width: 768px) {
	// 	    			left: 50%;
	// 	    			margin-left: -30px;
	// 	    		}
	// 	    	}
	//     	}
	//     }

	//     .nav-link {
	//     	position: relative;
	//     	padding: 20px 10px 20px 35px;
	//     	border: 0 none;
	//     	-webkit-border-radius: 0;
	//     	-moz-border-radius: 0;
	//     	border-radius: 0;
	//     	color: #fff;
	//     	text-transform: uppercase;
	//     	opacity: 0.7;
	//     	font-size: 13px;
	//     	cursor: pointer;

	//     	@media (max-width: 992px) {
	//     		padding: 12.5px 5px 12.5px 20px;
	//     		font-size: 10px;
	//     	}

	//     	@media (max-width: 768px) {
	//     		padding: 12.5px 10px 12.5px 20px;
	//     	}

	//     	.icon {
	//     		display: inline-block;
	//     		left: 13px;
	//     		margin-left: 0;

	//     		@media (max-width: 1225px) {
	//     			left: 16px;
	//     		}

	//     		@media (max-width: 992px) {
	//     			left: 5px;
	//     		}

	//     		@media (max-width: 768px) {
	//     			left: 2px;
	//     		}
	//     	}

	//     	&:before {
	//     		position: absolute;
 //    			bottom: 0;
 //    			left: 0;
 //    			right: 0;
 //    			content: "";
 //    			border-bottom: 4px solid #333;
	//     		transition: all .3s;
	//     	}

	//     	&:hover,
	//     	&.active {
	//     		background: none;
	//     		color: #fff;
	//     		opacity: 1;

	//     		&:before {
	//     			border-bottom: 4px solid #4caf50;
	//     		}
	//     	}
	//     }
 //    }
	// }

	.mobile-main-tabs {
		position: fixed;
		top: 60px;
		left: 0;
		right: 0;
		height: 45px;
		padding: 0 90px;
		background: #333;
		margin-top: 0;
		display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border: 0;
    z-index: 10;
    box-shadow: 0 10px 20px 0px rgba(0,0,0,0.25);
		display: none;

		@media (max-width: 768px) {
			display: none;
			padding: 0 20px;
		}

		& > .nav-link {
			display: block;
			padding: 0;
			height: 45px;
			position: relative;

			.info {
				display: block;
				padding: 12.5px 0 12.5px 30px;

				.icon {
					left: 0;
					margin-left: 0;
				}
			}

			&::after {
				position: absolute;
				top: 50%;
				right: 0;
				margin-top: -3px;
			}
		}

		.main-tabs {
			position: static;
			top: auto;
			left: auto;
			display: block;
			height: auto;
			padding: 10px;

			.nav-item {
				height: auto;
			}

			.nav-link {
				padding: 10px 10px 10px 30px;
				text-align: left;
			}
		}

		.dropdown-menu {
			margin: 0;
			padding: 0;
			width: 90%;
			max-width: 200px;
		}
	}

  .content {
  	// padding-top: 60px;
  	height: 100%;

  	@media (max-width: 992px) {
	    // padding-top: 45px;
	  }

  	.pane-content {
  		display: -ms-flexbox;
	    display: -webkit-flex;
	    display: flex;
	    -webkit-flex-direction: row;
	    -ms-flex-direction: row;
	    flex-direction: row;
	    -webkit-flex-wrap: nowrap;
	    -ms-flex-wrap: nowrap;
	    flex-wrap: nowrap;
	    -webkit-justify-content: flex-start;
	    -ms-flex-pack: start;
	    justify-content: flex-start;
	    -webkit-align-content: stretch;
	    -ms-flex-line-pack: stretch;
	    align-content: stretch;
	    -webkit-align-items: flex-start;
	    -ms-flex-align: start;
	    align-items: flex-start;
	    background: #ededed;
	    height: 100%;

  		&.hide-sidebar {
  			.ks-sidebar {
  				left: -240px;

  				.wrapper {
  					.collapse-sidebar {
  						width: 45px;
  						height: 45px;
  						right: -45px;
  						-webkit-border-radius: 0 15px 15px 0;
		    			-moz-border-radius: 0 15px 15px 0;
		    			border-radius: 0 15px 15px 0;
		    			-moz-box-shadow: 0 3px 20px -2px rgba(0,0,0,0.3);
							-webkit-box-shadow: 0 3px 20px -2px rgba(0,0,0,0.3);
							box-shadow: 0 3px 20px -2px rgba(0,0,0,0.3);

  						.icon {
  							-webkit-transform: rotate(180deg);
						    -moz-transform: rotate(180deg);
						    -o-transform: rotate(180deg);
						    -ms-transform: rotate(180deg);
						    transform: rotate(180deg);
  						}
  					}
  				}

  				.filter-block {
  					.nav-link {
  						&.add-btn {
  							right: -210px;
  							width: 45px;
  							height: 45px;
  							-webkit-border-radius: 0 15px 15px 0;
			    			-moz-border-radius: 0 15px 15px 0;
			    			border-radius: 0 15px 15px 0;

			    			&:after {
			    				display: none;
			    			}

  							.ks-info {
  								opacity: 0;
  								visibility: hidden;
  								height: 20px;
  							}
  						}
  					}

  					.ks-show-map-btn {
							right: -210px;
							width: 45px;
							height: 45px;
							-webkit-border-radius: 0 15px 15px 0;
		    			-moz-border-radius: 0 15px 15px 0;
		    			border-radius: 0 15px 15px 0;

							.ks-text {
								opacity: 0;
								visibility: hidden;
								height: 20px;
							}
  					}
  				}

  				.show {
						.nav-link {
							&.add-btn {
								-webkit-border-radius: 0 15px 0 0 !important;
		    				-moz-border-radius: 0 15px 0 0 !important;
		    				border-radius: 0 15px 0 0 !important;
		    			}
						}

						.dropdown-menu-add {
							margin-top: 0;
							-webkit-border-radius: 0 15px 15px 15px;
		    			-moz-border-radius: 0 15px 15px 15px;
		    			border-radius: 0 15px 15px 15px;
		    			margin-left: -5px;

							.dropdown-item {
								&:last-child {
									-webkit-border-radius: 0 0 15px 0;
				    			-moz-border-radius: 0 0 15px 0;
				    			border-radius: 0 0 15px 0;
								}
							}
						}
  				}
  			}

  			.ks-page-content {
  				padding-left: 50px;
  				overflow: hidden;
  			}
  		}

  		&.person-form {
  			.ks-page-content {
  				padding: 30px;
  			}
  		}

  		.ks-sidebar {
  			position: fixed;
  			top: 90px;
  			left: 0;
  			bottom: 0;
  			display: -ms-flexbox;
		    display: -webkit-flex;
		    display: flex;
		    -webkit-flex-direction: column;
		    -ms-flex-direction: column;
		    flex-direction: column;
		    -webkit-flex-wrap: nowrap;
		    -ms-flex-wrap: nowrap;
		    flex-wrap: nowrap;
		    -webkit-justify-content: flex-start;
		    -ms-flex-pack: start;
		    justify-content: flex-start;
		    -webkit-align-content: stretch;
		    -ms-flex-line-pack: stretch;
		    align-content: stretch;
		    -webkit-align-items: flex-start;
		    -ms-flex-align: start;
		    align-items: flex-start;
		    min-width: 240px;
		    max-width: 240px;
		    background: #f7f7f7;
		    -webkit-transition: all .3s;
		    -moz-transition: all .3s;
		    transition: all .3s;
		    z-index: 3;

		    @media (max-width: 992px) {
		    	top: 135px;
		    }

		    @media (max-width: 768px) {
		    	top: 107px;
		    	box-shadow: 0 6px 20px 0px rgba(0,0,0,0.25);
		    }

		    .wrapper {
		    	position: relative;
		    	// padding: 30px;

		    	.not-scroll-block {
		    		padding: 30px 30px 0;
		    	}

		    	.scroll-block {
		    		padding: 0 0 30px 30px;

		    		.simplebar-content {
		    			padding-right: 30px;
		    			// margin-bottom: -17px;
		    			width: 180px;
		    		}

		    		.simplebar-track {
		    			top: 25px;
		    		}
		    	}

		    	.collapse-sidebar {
		    		position: absolute;
		    		top: 160px;
		    		right: -15px;
		    		width: 30px;
		    		height: 45px;
		    		background: #f7f7f7;
		    		-webkit-border-radius: 15px;
	    			-moz-border-radius: 15px;
	    			border-radius: 15px;
	    			z-index: 2;

	    			&:hover {
	    				opacity: 1;
	    			}

	    			.icon {
	    				-webkit-transition: all .3s;
	    				-moz-transition: all .3s;
	    				transition: all .3s;
	    			}
		    	}
		    }

		    .scroll-block {
		    	// height: 630px;

		    	.mCSB_scrollTools {

		    	}
		    }

		    .filter-block {
		    	margin-bottom: 40px;
		    	width: 180px;

		    	&.datepicker {
		    		position: relative;
		    		left: -20px;

		    		.datepicker {
		    			width: 210px;
		    		}
		    	}

		    	&.events {
		    		.ks-custom-select-no-search {
		    			& ~ .select2-container--default .select2-selection--single .select2-selection__rendered {
		    				padding-left: 40px;
		    			}
		    		}
		    	}

		    	&.calendars {
		    		.filter-title {
							opacity: 1;

				    	.ks-text,
				    	.icon {
				    		opacity: 0.6;
				    	}
		    		}

		    		.filter-content {
		    			.calendars-block {
		    				background: #fff;
		    				border-radius: 15px;

						    .calendar {
						    	position: relative;
			    				display: table;
							    height: 45px;
							    width: 100%;
							    margin: 0;
							    padding: 0;
							    border-top: 2px solid #f3f3f3;
							    padding: 0 10px 0 15px;

							    &:first-child {
							    	border-top: 0;
							    }

							    .icon,
							    .ks-text,
							    .open-popover {
							    	display: table-cell;
    								vertical-align: middle;
							    }

							    .icon {
							    	width: 15px;
					    	    position: relative;
								    left: auto;
								    top: 14px;
								    margin: 0;
							    }

							    .ks-text {
							    	padding-left: 10px;
							    	font-weight: 600;
							    	font-size: 13px;
							    }

							    .open-popover {
							    	width: 10px;
								    position: relative;

								    .more-options {
						    	    display: block;
									    height: 40px;
									    cursor: pointer;
									    opacity: .7;
								    }
							    }
						    }
		    			}
		    		}
		    	}

		    	.nav-link {
		    		display: block;
		    		position: relative;

		    		&.add-btn {
		    			position: relative;
		    			right: 0;
		    			width: 100%;
		    			background: #4caf50;
		    			padding: 12.5px 10px 12.5px 30px;
		    			text-align: center;
		    			text-transform: uppercase;
		    			font-weight: 600;
		    			-webkit-border-radius: 15px;
		    			-moz-border-radius: 15px;
		    			border-radius: 15px;
		    			-moz-box-shadow: 0 3px 20px -2px rgba(0,0,0,0.3);
							-webkit-box-shadow: 0 3px 20px -2px rgba(0,0,0,0.3);
							box-shadow: 0 3px 20px -2px rgba(0,0,0,0.3);
							-webkit-transition: all .3s;
							-moz-transition: all .3s;
							transition: all .3s;
							display: -ms-flexbox;
					    display: -webkit-flex;
					    display: flex;
					    -webkit-flex-direction: row;
					    -ms-flex-direction: row;
					    flex-direction: row;
					    -webkit-flex-wrap: nowrap;
					    -ms-flex-wrap: nowrap;
					    flex-wrap: nowrap;
					    -webkit-justify-content: center;
					    -ms-flex-pack: center;
					    justify-content: center;
					    -webkit-align-content: stretch;
					    -ms-flex-line-pack: stretch;
					    align-content: stretch;
					    -webkit-align-items: center;
					    -ms-flex-align: center;
					    align-items: center;

					    &.create {
					    	background: #ff5722;
					    }

		    			.icon {
		    				left: 12px;
		    				margin-left: 0;
		    				margin-top: -7px;
		    			}

		    			.ks-info {
		    				opacity: 1;
		    				visibility: visible;
		    				min-width: 110px;
		    				height: 20px;
		    				-webkit-transition: all .3s;
								-moz-transition: all .3s;
								transition: all .3s;
		    			}
		    		}
		    	}

		    	.show {
		    		.nav-link {
		    			-webkit-border-radius: 15px 15px 0 0;
		    			-moz-border-radius: 15px 15px 0 0;
		    			border-radius: 15px 15px 0 0;
		    		}

		    		.dropdown-menu-add {
		    			display: block;
		    			// visibility: visible;
		    			// opacity: 1;
		    		}
		    	}

		    	.dropdown-menu-add {
		    		display: none;
		    		// opacity: 0;
		    		// visibility: hidden;
		    		right: 0;
            font-size: 12px;
            padding: 0;
            overflow: visible;
            border: 0;
            -webkit-box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24);
            -moz-box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24);
            box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24);
            -webkit-border-radius: 0 0 15px 15px;
	    			-moz-border-radius: 0 0 15px 15px;
	    			border-radius: 0 0 15px 15px;
	    			-webkit-transition: opacity .3s, visibility .3s;
						-moz-transition: opacity .3s, visibility .3s;
						transition: opacity .3s, visibility .3s;

						&.create {
							.dropdown-item {
								background: #ff5722;
							}
						}

            .dropdown-item {
              margin: 0;
              border: none;
              height: 50px;
              display: -webkit-box;
              display: -webkit-flex;
              display: -ms-flexbox;
              -js-display: flex;
              display: flex;
              -webkit-box-align: center;
              -webkit-align-items: center;
              -ms-flex-align: center;
              align-items: center;
              line-height: 13px;
              font-weight: 400;
              background: #4caf50;
		    			padding: 12.5px 10px 12.5px 50px;
              position: relative;
              color: #fff;
              border-top: 1px solid #e1e5f0;
              -webkit-transition: all .3s;
              -moz-transition: all .3s;
              transition: all .3s;


              &:first-child {
              	border-top: 0;
              }

              &:last-child {
              	-webkit-border-radius: 0 0 15px 15px;
			    			-moz-border-radius: 0 0 15px 15px;
			    			border-radius: 0 0 15px 15px;
              }

              .icon {
                left: 25px;
              }
            }
		    	}

		    	.ks-show-map-btn {
		    		position: relative;
		    		right: 0;
		    		width: 100%;
		    		display: block;
		    		background: #fff;
	    			padding: 12.5px 10px 12.5px 30px;
	    			text-align: center;
	    			text-transform: uppercase;
	    			font-weight: 600;
	    			color: #000;
	    			-webkit-border-radius: 15px;
	    			-moz-border-radius: 15px;
	    			border-radius: 15px;
  			    -webkit-box-shadow: 0 2px 2px rgba(0,0,0,0.2);
  			    -moz-box-shadow: 0 2px 2px rgba(0,0,0,0.2);
  			    box-shadow: 0 2px 2px rgba(0,0,0,0.2);
  			    border: 1px solid #F3F3F3;

  			    &:active {
  			    	top: 2px;
  			    	-webkit-box-shadow: 0 0 rgba(0,0,0,0.2);
	  			    -moz-box-shadow: 0 0 rgba(0,0,0,0.2);
	  			    box-shadow: 0 0 rgba(0,0,0,0.2);
  			    }

  			    &:hover {
				    	.icon {
				    		background: url('../img/icons/@2x/sidebar-icon-map@2x.png') 0 0 no-repeat;
						    width: 16px;
						    height: 16px;
						    -webkit-background-size: 100% auto;
						    -moz-background-size: 100% auto;
						    background-size: 100% auto;
				    	}

				    	.ks-text {
				    		color: #4caf50;
				    	}
				    }

  			    .icon {
	    				left: 15px;
	    				margin-left: 0;
	    				margin-top: -7px;
	    				transition: all .3s;
	    			}

	    			.ks-text {
	    				display: block;
	    				opacity: 1;
	    				visibility: visible;
	    				min-width: 110px;
	    				width: 138px;
	    				height: 20px;
	    				-webkit-transition: all .3s;
							-moz-transition: all .3s;
							transition: all .3s;
	    			}
		    	}

		    	.filter-title {
		    		position: relative;
		    		font-size: 13px;
		    		color: #333;
		    		display: -ms-flexbox;
				    display: -webkit-flex;
				    display: flex;
				    -webkit-flex-direction: row;
				    -ms-flex-direction: row;
				    flex-direction: row;
				    -webkit-flex-wrap: nowrap;
				    -ms-flex-wrap: nowrap;
				    flex-wrap: nowrap;
				    -webkit-justify-content: flex-start;
				    -ms-flex-pack: start;
				    justify-content: flex-start;
				    -webkit-align-content: stretch;
				    -ms-flex-line-pack: stretch;
				    align-content: stretch;
				    -webkit-align-items: center;
				    -ms-flex-align: center;
				    align-items: center;
				    opacity: 0.6;
				    transition: all .3s;
				    text-transform: uppercase;
				    font-weight: 600;
				    margin-bottom: 15px;

				    .icon {
				    	position: relative;
				    	left: 0;
				    	margin-left: 0;
				    	top: 0;
				    	margin-top: 0;
				    	margin-right: 10px;

				    	&.icon-letter {
				    		top: -2px;
				    	}
				    }

				    .btn {
		    	    position: absolute;
					    top: -6px;
					    right: 0;
					    width: 30px;
					    height: 30px;
					    padding: 0;
					    -webkit-border-radius: 100px;
					    border-radius: 100px;
					    -webkit-box-shadow: 0 2px 4px rgba(0,0,0,0.3);
					    box-shadow: 0 2px 4px rgba(0,0,0,0.3);
					    opacity: 1;
					    background: #ff5722;
					    -webkit-transition: all .3s;
					    -o-transition: all .3s;
					    transition: all .3s;

					    &:hover {
					    	opacity: 0.8;
					    }

					    &:before {
					    	position: absolute;
						    top: 50%;
						    left: 50%;
						    background: url(../img/icons/@2x/icon-plus--white@2x.png) 0 0 no-repeat;
						    width: 13px;
						    height: 13px;
						    -webkit-background-size: 100% auto;
						    background-size: 100% auto;
						    margin-top: -6.5px;
						    margin-left: -7.5px;
						    content: "";
						    -webkit-transition: all .3s;
						    -o-transition: all .3s;
						    transition: all .3s;
					    }
				    }
		    	}

		    	.inner-block {
		    		position: relative;
		    		margin-top: 20px;

		    		&:first-child {
		    			margin-top: 0;
		    		}
		    	}

		    	.filter-content {
		    		.letter-block {
		    			background: #fff;
		    			-webkit-border-radius: 15px;
		    			-moz-border-radius: 15px;
		    			border-radius: 15px;
	  			    -webkit-box-shadow: 0 2px 2px rgba(0,0,0,0.2);
	  			    -moz-box-shadow: 0 2px 2px rgba(0,0,0,0.2);
	  			    box-shadow: 0 2px 2px rgba(0,0,0,0.2);
	  			    border: 1px solid #F3F3F3;
	  			    display: -ms-flexbox;
					    display: -webkit-flex;
					    display: flex;
					    -webkit-flex-direction: row;
					    -ms-flex-direction: row;
					    flex-direction: row;
					    -webkit-flex-wrap: wrap;
					    -ms-flex-wrap: wrap;
					    flex-wrap: wrap;
					    -webkit-justify-content: flex-start;
					    -ms-flex-pack: start;
					    justify-content: flex-start;
					    -webkit-align-content: stretch;
					    -ms-flex-line-pack: stretch;
					    align-content: stretch;
					    -webkit-align-items: flex-start;
					    -ms-flex-align: start;
					    align-items: flex-start;
					    padding: 0 5px;

					    .letter {
					    	color: #DBDBDB;
					    	text-transform: uppercase;
					    	width: 16.666666667%;
					    	text-align: center;
					    	padding: 5px 0;
					    	font-weight: 600;

					    	&.active {
					    		color: #000;
					    	}

					    	&:hover {
					    		color: #333;
					    	}
					    }
		    		}
		    	}

		    	.clear-all {
		    		display: block;
		    		font-size: 14px;
		    		color: #4caf50;
		    		font-weight: 700;
		    		text-transform: uppercase;
		    		text-align: center;
		    	}
		    }

		    .sidebar-accordion {
		    	.card {
		    		border: 0;
		    		margin-bottom: 15px;

		    		&.open {
		    			.card-header {
		    				.btn {
		    					-webkit-border-radius: 15px 15px 0 0;
				    			-moz-border-radius: 15px 15px 0 0;
				    			border-radius: 15px 15px 0 0;
		    				}
		    			}
		    		}
		    	}

		    	.card-header {
		    		padding: 0;
		    		border: 0;

		    		.btn {
		    			position: relative;
			    		display: block;
			    		width: 100%;
			    		background: #fff;
		    			padding: 12.5px 16px 12.5px 16px;
		    			text-align: left;
		    			text-transform: uppercase;
		    			font-weight: 600;
		    			color: #666;
		    			-webkit-border-radius: 15px;
		    			-moz-border-radius: 15px;
		    			border-radius: 15px;
	  			    -webkit-box-shadow: 0 2px 2px rgba(0,0,0,0.2);
	  			    -moz-box-shadow: 0 2px 2px rgba(0,0,0,0.2);
	  			    box-shadow: 0 2px 2px rgba(0,0,0,0.2);
	  			    border: 1px solid #F3F3F3;
	  			    text-decoration: none;
	  			    -webkit-transition: all .3s;
	  			    -moz-transition: all .3s;
	  			    transition: all .3s;

	  			    &:hover {
	  			    	background: #ededed;
	  			    }

	  			    &:active {
	  			    	top: 2px;
	  			    	-webkit-box-shadow: 0 0 rgba(0,0,0,0.2);
		  			    -moz-box-shadow: 0 0 rgba(0,0,0,0.2);
		  			    box-shadow: 0 0 rgba(0,0,0,0.2);
	  			    }

	  			    .icon {
		    				left: 15px;
		    				margin-left: 0;
		    				margin-top: -7px;
		    			}
		    		}
		    	}
		    	.card-body {
		    		padding: 0;
		    		margin: 0;

		    		.select2-container--default .select2-selection--multiple {
		    			border: 0;
		    			-webkit-border-radius: 0 0 15px 15px;
		    			-moz-border-radius: 0 0 15px 15px;
		    			border-radius: 0 0 15px 15px;
		    			// padding: 12.5px 16px 12.5px 16px;
		    		}

		    		.select2-container {
		    			-webkit-border-radius: 0 0 15px 15px;
		    			-moz-border-radius: 0 0 15px 15px;
		    			border-radius: 0 0 15px 15px;
		    			-webkit-box-shadow: 0 2px 2px rgba(0,0,0,0.2);
	  			    -moz-box-shadow: 0 2px 2px rgba(0,0,0,0.2);
	  			    box-shadow: 0 2px 2px rgba(0,0,0,0.2);
	  			    border: 1px solid #F3F3F3;
	  			    width: 100% !important;
	  			    -webkit-transition: all .3s;
	  			    -moz-transition: all .3s;
	  			    transition: all .3s;
		    		}

		    		.select2-container--default .select2-selection--multiple .select2-selection__rendered {
	    			  display: -ms-flexbox;
					    display: -webkit-flex;
					    display: flex;
					    -webkit-flex-direction: column;
					    -ms-flex-direction: column;
					    flex-direction: column;
					    -webkit-flex-wrap: wrap;
					    -ms-flex-wrap: wrap;
					    flex-wrap: wrap;
					    -webkit-justify-content: flex-start;
					    -ms-flex-pack: start;
					    justify-content: flex-start;
					    -webkit-align-content: stretch;
					    -ms-flex-line-pack: stretch;
					    align-content: stretch;
					    -webkit-align-items: flex-start;
					    -ms-flex-align: start;
					    align-items: flex-start;
					    padding: 0;
		    		}

		    		.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
		    			-webkit-align-self: stretch;
					    -ms-flex-item-align: stretch;
					    align-self: stretch;
					    -webkit-order: 2;
					    -ms-flex-order: 2;
					    order: 2;
					    margin: 0;
					    position: relative;
		    		}

		    		.select2-search {
		    			&.select2-search--inline {
		    				-webkit-order: 1 !important;
						    -ms-flex-order: 1 !important;
						    order: 1 !important;
						    padding: 12px 16px;
		    			}
		    		}

		    		.select2-container--default .select2-selection--multiple .select2-selection__choice {
		    			background: none;
		    			border: 0;
		    			padding: 8px 16px;
		    			border-top: 1px solid #e6e6e6;
		    			color: #4caf50;
		    		}

		    		.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    			    position: absolute;
					    top: 50%;
					    right: 15px;
					    background: url(../img/icons/@2x/clear-item@2x.png) 0 0 no-repeat;
					    width: 10px;
					    height: 10px;
					    -webkit-background-size: 100% auto;
					    -moz-background-size: 100% auto;
					    background-size: 100% auto;
					    margin-top: -5px;
					    font-size: 0;
					    opacity: 0.7;

					    &:hover {
					    	opacity: 1;
					    }
		    		}

		    		.select2-container--default .select2-search--inline .select2-search__field {
		    			width: 100% !important;
		    		}
		    	}
		    }
  		}

  		.ks-page-content {
  			overflow-x: hidden;
  			// height: 100%;
  			-webkit-order: 0;
		    -ms-flex-order: 0;
		    order: 0;
		    -webkit-flex: 1 1 auto;
		    -ms-flex: 1 1 auto;
		    flex: 1 1 auto;
		    -webkit-align-self: auto;
		    -ms-flex-item-align: auto;
		    align-self: auto;
		    padding: 30px 30px 30px 290px;
		    -webkit-transition: all .3s;
		       -moz-transition: all .3s;
		        -ms-transition: all .3s;
		         -o-transition: all .3s;
		            transition: all .3s;

		    @media (max-width: 768px) {
		    	padding: 10px 10px 10px 50px;
		    }
  		}

  		.contact-content {
  			position: relative;
  			background: #fff;
  			-webkit-border-radius: 30px;
  			-moz-border-radius: 30px;
  			border-radius: 30px;
	      // height: 100%;
	      overflow-x: hidden;
	      padding-bottom: 54px;
  			-webkit-transition: all .3s;
  			   -moz-transition: all .3s;
  			    -ms-transition: all .3s;
  			     -o-transition: all .3s;
  			        transition: all .3s;

  			.top {
  				.links-group {
  					height: 58px;
  					padding: 0 30px;
  					display: -ms-flexbox;
				    display: -webkit-flex;
				    display: flex;
				    -webkit-flex-direction: row;
				    -ms-flex-direction: row;
				    flex-direction: row;
				    -webkit-flex-wrap: nowrap;
				    -ms-flex-wrap: nowrap;
				    flex-wrap: nowrap;
				    -webkit-justify-content: flex-start;
				    -ms-flex-pack: start;
				    justify-content: flex-start;
				    -webkit-align-content: stretch;
				    -ms-flex-line-pack: stretch;
				    align-content: stretch;
				    -webkit-align-items: center;
				    -ms-flex-align: center;
				    align-items: center;

				    @media (max-width: 768px) {
				    	-webkit-flex-wrap: wrap;
				    	-ms-flex-wrap: wrap;
				    	flex-wrap: wrap;
				    	height: auto;
				    	padding: 10px;
				    }

				    & > a {
				    	display: inline-block;
				    	color: #cccccc;
				    	margin-right: 30px;
				    	font-size: 16px;
				    	font-weight: 600;

				    	@media (max-width: 768px) {
				    		font-size: 12px;
				    		margin-right: 10px;
				    		// margin-bottom: 10px;
				    	}

				    	&.active {
				    		color: #000;
				    	}

				    	&:hover {
				    		color: #333;
				    	}
				    }

				    .custom-checkbox {
				    	position: relative;
				    	margin-left: 30px;

				    	@media (max-width: 768px) {
				    		margin-left: 0;
				    		font-size: 12px;
				    	}

				    	&:before {
				    		position: absolute;
				    		top: 0;
				    		left: -30px;
				    		bottom: 0;
				    		content: "";
				    		width: 1px;
				    		background-color: #f2f2f2;

				    		@media (max-width: 768px) {
				    			display: none;
				    		}
				    	}
				    }
  				}
  			}

  			.custom-scroll-table {
  				position: relative;
  				z-index: 0;
  				// overflow: hidden;
  				background: transparent;
  				width: 100%;
  				background: #fff;
  				border-radius: 0 0 15px 15px;

  				.simplebar-content {
				    overflow-x: auto;
    				overflow-y: hidden;
    				padding-bottom: 40px !important;
  				}

  				.table {
  					min-width: 1100px;
  				}

  				.no-result-block {
  					position: relative;
  					padding: 120px 0 225px;

  					.empty-top-block {
  						position: relative;
  						padding-left: 45px;
  						margin-bottom: 20px;

	  					& > .icon {
	  						left: 0;
						    margin-left: 0;
						    opacity: .2;
						    width: 30px;
						    height: 30px;
						    margin-top: -15px;
	  					}

	  					.ks-text {
	  						display: block;
						    font-size: 13px;
						    color: #999;

						    .block {
						    	display: block;
						    }
	  					}
	  				}

	  				.buttons-group {
					    display: -webkit-box;
					    display: -ms-flexbox;
					    display: flex;
					    -webkit-box-align: center;
					    -ms-flex-align: center;
					    align-items: center;
					    justify-content: flex-start;

					    .btn {
			    	    position: relative;
						    padding: 4.5px 13px;
						    text-align: center;
						    color: #000;
						    text-transform: uppercase;
						    border-radius: 12px;
						    -webkit-box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.3);
						    box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.3);
						    -webkit-transition: all .3s;
						    -o-transition: all .3s;
						    transition: all .3s;
						    display: -ms-flexbox;
						    display: -webkit-flex;
						    display: -webkit-box;
						    display: flex;
						    -webkit-flex-direction: row;
						    -ms-flex-direction: row;
						    -webkit-box-orient: horizontal;
						    -webkit-box-direction: normal;
						    flex-direction: row;
						    -webkit-flex-wrap: nowrap;
						    -ms-flex-wrap: nowrap;
						    flex-wrap: nowrap;
						    -webkit-justify-content: center;
						    -ms-flex-pack: center;
						    -webkit-box-pack: center;
						    justify-content: center;
						    -webkit-align-content: stretch;
						    -ms-flex-line-pack: stretch;
						    align-content: stretch;
						    -webkit-align-items: center;
						    -ms-flex-align: center;
						    -webkit-box-align: center;
						    align-items: center;
						    font-size: 12px;
						    font-weight: 600;
						    background: none;

						    &:hover {
						    	box-shadow: 0 4px 14px 2px rgba(0,0,0,0.2);
						    }

						    .icon {
						    	left: 18px;
						    }

						    &.import {
							    background: #4caf50;
							    color: #fff;
							    padding-left: 35px;

							    .ks-info {
							    	font-size: 12px;
							    	color: #fff;
							    }
						    }

						    &.manual {
							    background: #fff;
							    color: #fff;
							    padding-left: 35px;
							    margin-left: 15px;

							    .ks-info {
							    	font-size: 12px;
							    	color: #4caf50;
							    }
						    }
					    }
	  				}
  				}
  			}

  			.custom-scroll-table {
  				.simplebar-track.vertical {
  					display: none;
  				}
  				.simplebar-track.horizontal {
  					// bottom: 52px;
  				}
  			}

  			.mcustom-scroll-bar-table {
  				.simplebar-track.horizontal {
  					display: none;
  				}
  			}

  			.mcustom-scroll-bar-table {
  				padding-bottom: 15px;

  				.simplebar-content {
  					overflow-x: visible;
  				}
  			}

  			.table {
  				margin: 0;
  				// overflow-x: scroll;

  				&.table-tbody {}

  				thead {
  					th.th {
  						vertical-align: middle;
  						background: #f7f7f7;
  						border-bottom: 0;
  						color: #666666;

  						.th-title {
  							position: relative;
  							font-size: 13px;

  							&.sortable {
  								background-image: url(../img/both.png);
							    cursor: pointer;
							    background-position: right;
							    background-repeat: no-repeat;

							    &.sorted {
							    	color: #000;

							    	&.sorted_desc {
  										background-image: url(../img/desc.png);
  										background-position: right 7px;
							    		background-repeat: no-repeat;
							    	}

							    	&.sorted_asc {
  										background-image: url(../img/asc.png);
  										background-position: right 0px;
							    		background-repeat: no-repeat;
							    	}
							    }
  							}
  						}
  					}

  					tr.search-row {
  						th.th {
  							padding: 0;
  							border-bottom: 2px solid #ccc;
  						}
  					}
  				}

  				tbody {
  					tr:hover,
  					tr.row-checked {
  						td {
  							background: #f7fff8;
  						}
  					}

  					tr:first-child {
  						td {
  							border-top: 0;
  						}
  					}

  					td {
  						vertical-align: middle;
  						border-bottom: 0;

						span {
  							display: block;
				    		color: #a3a3a3;
				    		font-size: 13px;
				    		line-height: 1.5em;
				    		word-wrap: break-word;
  						}
  					}
  				}

  				.checkbox-cell {
  					min-width: 50px;
  					max-width: 50px;
  					width: 50px;
  				}

  				.boniteta-cell {
  					max-width: 120px;
  					width: 100px;
  					min-width: 100px;
  				}

  				.email-cell,
  				.phone-cell {
  					// @media (max-width: 1439px) {
	  					// width: 180px;
	  					max-width: 250px;
	  					min-width: 180px;
  					// }
  				}

  				.name-cell {
  					min-width: 160px;
  					max-width: 200px;
  					width: 160px;
  				}

  				.address-cell {
  					max-width: 220px;
  					min-width: 170px;
  				}

  				.area-cell {
  					width: 100px;
  				}

					.checkbox-cell {
						.custom-checkbox {
							margin-top: 8px;
						}
					}

					.text-center {
						text-align: center;
					}

  				.search-row {
  					border-bottom: 1px solid #dee2e6;

  					&:hover {
  						th.th {
  							background: transparent;
  						}
  					}
  					th.th {
  						position: relative;
  						padding: 0;
  						vertical-align: middle;
  						height: 44px;
  						background: #fff;

  						&:first-child {
  							&:before {
  								display: none;
  							}
  						}

  						&:before {
						    position: absolute;
						    top: 50%;
						    left: 0;
						    bottom: 0;
						    height: 15px;
						    content: "";
						    width: 2px;
						    background: #f2f2f2;
						    z-index: 3;
						    margin-top: -7.5px;
  						}
  					}

  					.action-block {
  						position: relative;
  						height: 44px;
  						
  						&.tippy-active {

  							.dropdown-toggle {
  								position: relative;
  								top: -1px;
  								background: #4caf50;
  								color: #fff;
	  							opacity: 1;
	  							height: 46px;
									-webkit-border-radius: 15px;
									-moz-border-radius: 15px;
	  							border-radius: 15px;
	  							padding: 13px 15px;

	  							&:after {
	  								background: url(../img/icons/@2x/angle-down--white@2x.png) 0 0 no-repeat;
								    -webkit-background-size: 100% auto;
								    -moz-background-size: 100% auto;
								    background-size: 100% auto;
								    opacity: 1;
	  							}

	  							span {
	  								color: #fff;
	  							}
  							}
  						}

  						.dropdown-toggle {
  							background: transparent;
  							padding: 12px 15px;
  							opacity: 0.7;
  							cursor: pointer;
  							-webkit-border-radius: 0;
								-moz-border-radius: 0;
								border-radius: 0;
  							-webkit-transition: all .3s;
  							   -moz-transition: all .3s;
  							    -ms-transition: all .3s;
  							     -o-transition: all .3s;
  							        transition: all .3s;

  							.ks-info {
  								font-weight: 600;
  							}

  							span {
  								color: #333;
  							}

  							&:after {
							    background: url(../img/icons/@2x/angle-down@2x.png) 0 0 no-repeat;
							    -webkit-background-size: 100% auto;
							    -moz-background-size: 100% auto;
							    background-size: 100% auto;
    							position: absolute;
					        right: 15px;
							    top: 50%;
							    margin-top: -3px;
							    opacity: 0.6;
  							}
  						}
  					}
  				}

  				.cell-td {
  					display: -ms-flexbox;
				    display: -webkit-flex;
				    display: flex;
				    -webkit-flex-direction: row;
				    -ms-flex-direction: row;
				    flex-direction: row;
				    -webkit-flex-wrap: nowrap;
				    -ms-flex-wrap: nowrap;
				    flex-wrap: nowrap;
				    -webkit-justify-content: flex-start;
				    -ms-flex-pack: start;
				    justify-content: flex-start;
				    -webkit-align-content: stretch;
				    -ms-flex-line-pack: stretch;
				    align-content: stretch;
				    -webkit-align-items: center;
				    -ms-flex-align: center;
				    align-items: center;

				    &.avatar-block {
				    	-webkit-justify-content: flex-end;
					    -ms-flex-pack: end;
					    justify-content: flex-end;
				    	.avatar {
				    		width: 40px;
				    		height: 40px;
				    		-webkit-border-radius: 100px;
				    		-moz-border-radius: 100px;
				    		border-radius: 100px;
				    		margin-left: 15px;

				    		@media (max-width: 1340px) {
				    			margin-left: 5px;
				    		}
				    	}
				    }

				    &.name-block {
				    	.corp {
				    		display: block;
				    		color: #000;
				    		margin-bottom: 5px;
				    		line-height: 1em;
				    		font-weight: 600;
				    	}
				    }
  				}

  				.email-block,
  				.phone-block {
  					display: inline-block;
  					position: relative;
  					padding: 5px 35px 5px 30px;
  					cursor: pointer;
  					background: transparent;
  					color: #333;
  					margin-bottom: 2px;
  					-webkit-border-radius: 0;
  					-moz-border-radius: 0;
  					border-radius: 0;
  					-webkit-transition: all .3s;
  					   -moz-transition: all .3s;
  					    -ms-transition: all .3s;
  					     -o-transition: all .3s;
  					        transition: all .3s;
						-webkit-border-radius: 15px;
  					-moz-border-radius: 15px;
  					border-radius: 15px;

  					&.tippy-active,
  					&:hover {
  						background: #4caf50;

  						span {
  							color: #fff;
  						}

  						.icon {
  							&.icon-phone {
  								background: url('../img/icons/@2x/icon-phone--white@2x.png') 0 0 no-repeat;
							    -webkit-background-size: 100% auto;
							    -moz-background-size: 100% auto;
							    background-size: 100% auto;
  							}

  							&.icon-mobile {
  								background: url('../img/icons/@2x/icon-mobile--white@2x.png') 0 0 no-repeat;
							    -webkit-background-size: auto 100%;
							    -moz-background-size: auto 100%;
							    background-size: auto 100%;
  							}
  						}
  					}

  					&.open-popover {
  						&:before {
  							position: absolute;
  							top: 50%;
  							right: 10px;
						    border: 0;
						    background: url(../img/icons/@2x/angle-down--white@2x.png) 0 0 no-repeat;
						    -webkit-background-size: 100% auto;
						    -moz-background-size: 100% auto;
						    background-size: 100% auto;
						    width: 9px;
						    height: 6px;
						    content: "";
						    margin-top: -3px;
						    -moz-transition: all 0.3s;
						    -o-transition: all 0.3s;
						    -webkit-transition: all 0.3s;
						    transition: all 0.3s;
  						}
  					}

  					span {
  						-webkit-transition: all .3s;
  					   -moz-transition: all .3s;
  					    -ms-transition: all .3s;
  					     -o-transition: all .3s;
  					        transition: all .3s;
  						color: #333;
  						word-break: break-all;
  					}

  					.popover-content {
  						display: none;
  					}

  					.icon {
  						left: 10px;
  						margin-left: 0;
  					}
  				}

  				.email-block {
  					padding: 5px 35px 5px 10px;
  				}

  				.area-cell {
  					text-align: center;;

  					.area {
  						color: #333;
  					}

  					.open-popover {
  						height: 21px;
  						.popover-content {
  							display: none;
  						}
  					}

  					.text {
  						color: #333;
  						cursor: pointer;
  					}
  				}

  				.options-cell {
  					.open-popover-link {
  						position: relative;
  						width: 34px;
  						height: 31px;
  						-webkit-flex: 1 100%;
					    -ms-flex: 1 100%;
					    flex: 1 100%;

					    &.tippy-active {
					    	.more-options {
					    		.icon {
	  								background: url("../img/icons/@2x/icon-more--green@2x.png") 0 0 no-repeat;
	  								-webkit-background-size: auto 100%;
	  								-moz-background-size: auto 100%;
	  								background-size: auto 100%;
	  							}
					    	}
					    }

					    .open-popover {
			    	    width: 34px;
						    height: 31px;
						    float: right;

						    .popover-content {
						    	display: none;
						    }
					    }

  						.more-options {
  							position: relative;
  							display: block;
  							height: 31px;
  							cursor: pointer;
  							width: 34px;
  							float: right;

  							.icon {
  								-webkit-transition: all .3s;
  								   -moz-transition: all .3s;
  								    -ms-transition: all .3s;
  								     -o-transition: all .3s;
  								        transition: all .3s;
  							}

  							&:hover {
  								.icon {
	  								background: url("../img/icons/@2x/icon-more--green@2x.png") 0 0 no-repeat;
	  								-webkit-background-size: auto 100%;
	  								-moz-background-size: auto 100%;
	  								background-size: auto 100%;
	  							}
  							}
  						}
  					}
  					.btn {
  						-webkit-flex: 0 1 auto;
					    -ms-flex: 0 1 auto;
					    flex: 0 1 auto;
  						-webkit-border-radius: 15px;
  						-moz-border-radius: 15px;
  						border-radius: 15px;
  						font-weight: 600;
  						font-size: 13px;
  						background: none;
  						color: #333;
  						border: 1px solid transparent;
  						-webkit-transition: all .3s;
  						   -moz-transition: all .3s;
  						    -ms-transition: all .3s;
  						     -o-transition: all .3s;
  						        transition: all .3s;

  						&:hover {
  							background: #28a745;
  							color: #fff;
  						}
  					}
  				}
  			}
  		}
  	}
  }
}

.ks-contacts {
	.content {
		.pane-content {
			&.profile-content {
				.ks-page-content {
					padding: 0 !important;

					.custom-scroll-table {
						.simplebar-track.horizontal {
	  					bottom: 52px;
	  				}
					}
				}

				.contact-content {
					padding-bottom: 0;
				}

				.wrapper-content {
					position: relative;
					background: #ececec;
					padding: 0 30px 30px;

					@media (max-width: 992px) {
						padding: 0 10px 30px;
					}
				}
			}
		}
	}
}

.profile-content {
	.profile-information {
		box-shadow: 0 6px 20px 0px rgba(0,0,0,0.25);
		border-radius: 0 0 15px 15px;

		.custom-scroll-table {
			padding-bottom: 60px;
		}
	}
}
