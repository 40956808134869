.progress-block {
  position: relative;
  padding: 50px 15px 15px;

  @media (max-width: 768px) {
    padding: 40px 0 15px;
  }

  /* Mixins */
  @mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* Color Variables */
  $charcoal: #292627;
  $pink: #ff2267;
  $gray: #e6e7e8;

  /* Theme Variables */
  $base-background-color: $charcoal;
  $base-font-color: $gray;
  $base-muted-color: #ececec;

  /* Animations */
  @keyframes bounce {
    0% {
      transform: scale(1);
    }
    
    33% {
      transform: scale(0.9);
    }
    
    66% {
      transform: scale(1.1);
    }
    
    100% {
      transform: scale(1);
    }
  }

   /* Component Styles - Steps */
  .steps {
    display: flex;
    width: 100%;
    margin: 0;
    padding: 0 0 40px 0;
    list-style: none;
    position: relative;

    &:before {
      position: absolute;
      top: 50%;
      left: 0;
      height: 2px;
      content: "";
      background: #000;
    }
  }

  .step {
    @include flex-center;
    flex-direction: column;
    flex: 1;
    position: relative;
    pointer-events: none;

    &--next {
      .step__icon {
        cursor: pointer;
        pointer-events: all;
      }
    }
    
    &--active,
    &--complete {
      .step__icon {
        cursor: pointer;
        pointer-events: all;
      }
    }

    @keyframes doneStep {
      0% {
        background-position: 0 0px;
      }
      100% {
        background-position: -450px 0px;
      }
    }

    @keyframes doneStepForward {
      0% {
        background-position: -450px 0px;
      }
      100% {
        background-position: 0px 0px;
      }
    }

    &:nth-child(1) {
      &:after {
        background: rgba(32,152,243,1);
        background: -moz-linear-gradient(left, rgba(32,152,243,1) 0%, rgba(0,187,212,1) 100%);
        background: -webkit-gradient(left top, right top, color-stop(0%, rgba(32,152,243,1)), color-stop(100%, rgba(0,187,212,1)));
        background: -webkit-linear-gradient(left, rgba(32,152,243,1) 0%, rgba(0,187,212,1) 100%);
        background: -o-linear-gradient(left, rgba(32,152,243,1) 0%, rgba(0,187,212,1) 100%);
        background: -ms-linear-gradient(left, rgba(32,152,243,1) 0%, rgba(0,187,212,1) 100%);
        background: linear-gradient(to right, rgba(32,152,243,1) 0%, rgba(0,187,212,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2098f3', endColorstr='#00bbd4', GradientType=1 );
      }
    }

    &:nth-child(2) {
      &:after {
        background: rgba(0,187,212,1);
        background: -moz-linear-gradient(left, rgba(0,187,212,1) 0%, rgba(0,150,135,1) 100%);
        background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0,187,212,1)), color-stop(100%, rgba(0,150,135,1)));
        background: -webkit-linear-gradient(left, rgba(0,187,212,1) 0%, rgba(0,150,135,1) 100%);
        background: -o-linear-gradient(left, rgba(0,187,212,1) 0%, rgba(0,150,135,1) 100%);
        background: -ms-linear-gradient(left, rgba(0,187,212,1) 0%, rgba(0,150,135,1) 100%);
        background: linear-gradient(to right, rgba(0,187,212,1) 0%, rgba(0,150,135,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00bbd4', endColorstr='#009687', GradientType=1 );
      }
    }

    &:nth-child(3) {
      &:after {
        background: rgba(0,150,135,1);
        background: -moz-linear-gradient(left, rgba(0,150,135,1) 0%, rgba(76,175,80,1) 100%);
        background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0,150,135,1)), color-stop(100%, rgba(76,175,80,1)));
        background: -webkit-linear-gradient(left, rgba(0,150,135,1) 0%, rgba(76,175,80,1) 100%);
        background: -o-linear-gradient(left, rgba(0,150,135,1) 0%, rgba(76,175,80,1) 100%);
        background: -ms-linear-gradient(left, rgba(0,150,135,1) 0%, rgba(76,175,80,1) 100%);
        background: linear-gradient(to right, rgba(0,150,135,1) 0%, rgba(76,175,80,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#009687', endColorstr='#4caf50', GradientType=1 );
      }
    }

    &:nth-child(4) {
      &:after {
        background: rgba(76,175,80,1);
        background: -moz-linear-gradient(left, rgba(76,175,80,1) 0%, rgba(255,153,0,1) 100%);
        background: -webkit-gradient(left top, right top, color-stop(0%, rgba(76,175,80,1)), color-stop(100%, rgba(255,153,0,1)));
        background: -webkit-linear-gradient(left, rgba(76,175,80,1) 0%, rgba(255,153,0,1) 100%);
        background: -o-linear-gradient(left, rgba(76,175,80,1) 0%, rgba(255,153,0,1) 100%);
        background: -ms-linear-gradient(left, rgba(76,175,80,1) 0%, rgba(255,153,0,1) 100%);
        background: linear-gradient(to right, rgba(76,175,80,1) 0%, rgba(255,153,0,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4caf50', endColorstr='#ff9900', GradientType=1 );
      }
    }

    &:nth-child(5) {
      &:after {
        background: rgba(255,152,0,1);
        background: -moz-linear-gradient(left, rgba(255,152,0,1) 0%, rgba(255,86,34,1) 100%);
        background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255,152,0,1)), color-stop(100%, rgba(255,86,34,1)));
        background: -webkit-linear-gradient(left, rgba(255,152,0,1) 0%, rgba(255,86,34,1) 100%);
        background: -o-linear-gradient(left, rgba(255,152,0,1) 0%, rgba(255,86,34,1) 100%);
        background: -ms-linear-gradient(left, rgba(255,152,0,1) 0%, rgba(255,86,34,1) 100%);
        background: linear-gradient(to right, rgba(255,152,0,1) 0%, rgba(255,86,34,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff9800', endColorstr='#ff5622', GradientType=1 );
      }
    }

    &--active {
      &:nth-child(1) {
        .step__label {
          color: #2196f3;
        }

        .step__icon {
          border: 2px solid #2196f3;
          background: #2196f3;

          &:after {
            position: absolute;
            top: -12px;
            left: -12px;
            width: 30px;
            height: 30px;
            content: "";
            background: rgba(33,150,243,0.2);
            border-radius: 30px;
          }

          .icon {
            background: url(../img/animation/step-tick-anim-1.svg) -5px 0 no-repeat;
            animation-name: doneStepForward;
          }
        }
      }

      &:nth-child(2) {
        .step__label {
          color: #00bcd4;
        }
        .step__icon {
          border: 2px solid #00bcd4;
          background: #00bcd4;

          &:after {
            position: absolute;
            top: -12px;
            left: -12px;
            width: 30px;
            height: 30px;
            content: "";
            background: rgba(0,188,212,0.2);
            border-radius: 30px;
          }

          .icon {
            background: url(../img/animation/step-tick-anim-2.svg) -5px 0 no-repeat;
            animation-name: doneStepForward;
          }
        }
      }

      &:nth-child(3) {
        .step__label {
          color: #009688;
        }
        .step__icon {
          border: 2px solid #009688;
          background: #009688;

          &:after {
            position: absolute;
            top: -12px;
            left: -12px;
            width: 30px;
            height: 30px;
            content: "";
            background: rgba(0,150,136,0.2);
            border-radius: 30px;
          }

          .icon {
            background: url(../img/animation/step-tick-anim-3.svg) -5px 0 no-repeat;
            animation-name: doneStepForward;
          }
        }
      }

      &:nth-child(4) {
        .step__label {
          color: #4caf50;
        }
        .step__icon {
          border: 2px solid #4caf50;
          background: #4caf50;

          &:after {
            position: absolute;
            top: -12px;
            left: -12px;
            width: 30px;
            height: 30px;
            content: "";
            background: rgba(76,175,80,0.2);
            border-radius: 30px;
          }

          .icon {
            background: url(../img/animation/step-tick-anim-4.svg) -5px 0 no-repeat;
            animation-name: doneStepForward;
          }
        }
      }

      &:nth-child(5) {
        .step__label {
          color: #ff9800;
        }
        .step__icon {
          border: 2px solid #ff9800;
          background: #ff9800;

          &:after {
            position: absolute;
            top: -12px;
            left: -12px;
            width: 30px;
            height: 30px;
            content: "";
            background: rgba(255,152,0,0.2);
            border-radius: 30px;
          }

          .icon {
            background: url(../img/animation/step-tick-anim-5.svg) -5px 0 no-repeat;
            animation-name: doneStepForward;
          }
        }
      }

      &:nth-child(6) {
        .step__label {
          color: #ff5722;
        }
        .step__icon {
          border: 2px solid #ff5722;
          background: #ff5722;

          &:after {
            position: absolute;
            top: -12px;
            left: -12px;
            width: 30px;
            height: 30px;
            content: "";
            background: rgba(255,87,34,0.2);
            border-radius: 30px;
          }

          .icon {
            background: url(../img/animation/step-tick-anim-6.svg) -5px 0 no-repeat;
            animation-name: doneStepForward;
          }
        }
      }
    }

    &--next {
      &:nth-child(1) {
        .step__label {
          color: #2196f3;
        }

        .step__icon {
          border: 2px solid #2196f3;
          // background: #2196f3;

          .icon {
            // background: url(../img/animation/step-tick-anim-1.svg) -5px 0 no-repeat;
            animation-name: doneStepForward;
          }
        }
      }

      &:nth-child(2) {
        .step__label {
          color: #00bcd4;
        }
        .step__icon {
          border: 2px solid #00bcd4;
          // background: #00bcd4;

          .icon {
            // background: url(../img/animation/step-tick-anim-2.svg) -5px 0 no-repeat;
            animation-name: doneStepForward;
          }
        }
      }

      &:nth-child(3) {
        .step__label {
          color: #009688;
        }
        .step__icon {
          border: 2px solid #009688;
          // background: #009688;

          .icon {
            // background: url(../img/animation/step-tick-anim-3.svg) -5px 0 no-repeat;
            animation-name: doneStepForward;
          }
        }
      }

      &:nth-child(4) {
        .step__label {
          color: #4caf50;
        }
        .step__icon {
          border: 2px solid #4caf50;
          // background: #4caf50;

          .icon {
            // background: url(../img/animation/step-tick-anim-4.svg) -5px 0 no-repeat;
            animation-name: doneStepForward;
          }
        }
      }

      &:nth-child(5) {
        .step__label {
          color: #ff9800;
        }
        .step__icon {
          border: 2px solid #ff9800;
          // background: #ff9800;

          .icon {
            // background: url(../img/animation/step-tick-anim-5.svg) -5px 0 no-repeat;
            animation-name: doneStepForward;
          }
        }
      }

      &:nth-child(6) {
        .step__label {
          color: #ff5722;
        }
        .step__icon {
          border: 2px solid #ff5722;
          // background: #ff5722;

          .icon {
            // background: url(../img/animation/step-tick-anim-6.svg) -5px 0 no-repeat;
            animation-name: doneStepForward;
          }
        }
      }
    }

    &--complete {
      .step__label {
        color: #000;

        b {
          &:not(:first-child) {
            visibility: visible;
            opacity: 1;
          }

          &:first-child {
            opacity: 0;
            visibility: hidden;
          }
        }

      }
      &:nth-child(1) {
        .step__icon {
          .icon {
            background: url(../img/animation/step-tick-anim-1.svg) 0 0 no-repeat;
            animation-name: doneStep;
            
          }
        }
      }

      &:nth-child(2) {
        .step__icon {
          .icon {
            background: url(../img/animation/step-tick-anim-2.svg) 0 0 no-repeat;
            animation-name: doneStep;
            
          }
        }
      }

      &:nth-child(3) {
        .step__icon {
          .icon {
            background: url(../img/animation/step-tick-anim-3.svg) 0 0 no-repeat;
            animation-name: doneStep;
            
          }
        }
      }

      &:nth-child(4) {
        .step__icon {
          .icon {
            background: url(../img/animation/step-tick-anim-4.svg) 0 0 no-repeat;
            animation-name: doneStep;
            
          }
        }
      }

      &:nth-child(5) {
        .step__icon {
          .icon {
            background: url(../img/animation/step-tick-anim-5.svg) 0 0 no-repeat;
            animation-name: doneStep;
            
          }
        }
      }

      &:nth-child(6) {
        .step__icon {
          .icon {
            background: url(../img/animation/step-tick-anim-6.svg) 0 0 no-repeat;
            animation-name: doneStep;
            
          }
        }
      }
    }


    &:not(:last-child) {
      &:before,
      &:after {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        height: 2px;
        content: '';
        transform: translateY(-50%);
        will-change: width;
        z-index: 1;
      }
    }

    &:before {
      width: 100%;
      background-color: $base-muted-color;
    }

    &:after {
      width: 0;
      background-color: $pink;
      transition: width 0.6s ease-in-out,
                    opacity 0.6s ease-in-out;
    }
    
    &--complete {
      &:after {
        width: 100% !important;
        opacity: 1;
        
      }
    }
  }

  .step__icon {
    @include flex-center;
    position: relative;
    width: 10px;
    height: 10px;
    background: #fff;
    border: 2px solid #ececec;
    border-radius: 50%;
    color: transparent;
    font-size: 2rem;
    z-index: 2;

    .icon {
      position: absolute;
      top: -12px;
      left: -12px;
      width: 30px;
      height: 30px;
      content: "";
      animation-duration: 500ms;
      animation-timing-function: steps(15);
      animation-fill-mode: forwards;
      background-repeat: no-repeat;
    }
    
    &:before {
      display: block;
      content: '';
    }
    
    .step--complete.step--active & {
      color: #fff;
      transition: background-color 0.3s ease-in-out,
                  border-color 0.3s ease-in-out,
                  color 0.3s ease-in-out;
    }
    
    .step--incomplete.step--active & {
      border-color: $pink;
      transition-delay: 0.5s;
    }
    
    .step--complete & {
      animation: bounce 0.5s ease-in-out;
      background-color: $pink;
      border-color: $pink;
      color: #fff;
    }
  }

  .step__label {
    position: absolute;
    bottom: -40px;
    left: 50%;
    margin-top: 1rem;
    font-size: 0.8rem;
    line-height: 1.2em;
    text-transform: uppercase;
    transform: translateX(-50%);
    -webkit-transition: all .3s;
       -moz-transition: all .3s;
        -ms-transition: all .3s;
         -o-transition: all .3s;
            transition: all .3s;
    
    @media (max-width: 576px) {
      font-size: 8px;
    }

    b {
      -webkit-transition: all .3s;
         -moz-transition: all .3s;
          -ms-transition: all .3s;
           -o-transition: all .3s;
              transition: all .3s;
      font-weight: 600;

      &:not(:first-child) {
        position: absolute;
        left: 0;
        right: 0;
        opacity: 0;
        visibility: hidden;
      }
    }
    
    .step--incomplete.step--inactive & {
      color: $base-muted-color;
    }
    
    .step--incomplete.step--active & {
      color: #fff;
    }
    
    .step--active & {
      transition: color 0.3s ease-in-out;
      transition-delay: 0.5s;
    }
  }

  .count {
    position: absolute;
    top: -35px;
    left: 0;
    display: block;
    right: 0;
    color: #e6e6e6;
    font-weight: 600;
    text-align: center;
  }

}
