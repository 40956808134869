.compose_button{
	
	position: relative;
	width: 100%;
	background:#00bcd4;
	padding: 12.5px 10px 12.5px 10px;
	text-align: center;
	text-transform: uppercase;
	font-weight: 600;
	-webkit-border-radius: 15px;
	border-radius: 15px;
	-webkit-box-shadow: 0 3px 20px -2px rgba(0,0,0,0.3);
	box-shadow: 0 3px 20px -2px rgba(0,0,0,0.3);
	-webkit-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: -webkit-box;
	display: flex;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	flex-direction: row;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-align-content: stretch;
	-ms-flex-line-pack: stretch;
	align-content: stretch;
	-webkit-align-items: center;
	-ms-flex-align: center;
	-webkit-box-align: center;
	align-items: center;
}
.message_write_text{
	margin-bottom:15px;
}
.mail_mail_attach{
						
						padding-bottom:15px;

						.mail_attach_info{
							font-size: 13px;
							color: #999999;
							margin-bottom:14px;
						}
						.mail_attach_item{
							width: 180px;
							height: 45px;
							padding-top:8px;
							padding-left:37px;
							padding-right:37px;
							position:relative;
							box-sizing:border-box;
							-webkit-box-shadow: 0 2px 2px rgba(0,0,0,0.2);
							box-shadow: 0 2px 2px rgba(0,0,0,0.2);
							border: 1px solid #F3F3F3;
							-webkit-border-radius: 15px;
							border-radius: 15px;
							margin-right:15px;
							margin-bottom:15px;
							display:inline-block;
							text-overflow: ellipsis;
							overflow: hidden;
							line-height: 1.88;
							a{
								text-decoration:none !important;
								.attach_name{
									font-size: 13px;
									font-weight: 600;
									color: #000000;
									

								}
								.icon{
									&.icon-file-doc{
										top:14px;
										left:15px;
										margin-left:0px;
										margin-top:0px;
									}
									&.icon-clear-black{
										width:12px;
										height:12px;
										margin-top:-6px;
										left:auto;
										right:15px;
										top:14px;
										margin-right:0px;
										margin-left:0px;
										margin-top:0px;
									}
									&.icon-cloud-down{
										left:auto;
										right:15px;
										top:14px;
										margin-right:0px;
										margin-left:0px;
										margin-top:0px;
									}

								}
								
							}
						}
					}
.case-messages{
	
	.mail_ul{
		li{
			a{
				position: relative;
				display: block;
				padding: 11px 0 11px 60px;
				font-size: 13px;
				color: #000;
				opacity: .7;
				font-weight: 600;
				.icon {
					left: 30px;
					margin-left: 0;
					-webkit-transition: all .3s;
					-o-transition: all .3s;
					transition: all .3s;
				}
				.count{
					display:inline-block;
					float:right;
					text-align:center;
					margin-right:15px;
					// width: 21px;
					height: 15px;
					padding-left:5px;
					padding-right:5px;
					border-radius: 15px;
					background-color: #00bcd4;
					font-size: 10px;
					font-weight: 600;
					font-style: normal;
					font-stretch: normal;
					line-height: 1.5;
					letter-spacing: normal;
					min-width:15px;

					color: #ffffff;
					@media (max-width: 768px) {
						margin-right: 35px;
					}
				}
			}
			&.active{
				background:rgba(0, 0, 0, 0.03);
			}
		}
	}
	
	
	.profile-information{
		.badge{
			&.post-btn{
				background:#00bcd4;
			}
		}
		min-height:550px;
		.user-information{
			padding-left:0px;
			padding-right:0px;
			padding-top:20px;
			padding-bottom:20px;
			background:#f7f7f7;
			left:0;
			.ks-close{
				display:none;
				@media (max-width: 768px) {
					display:block;
					z-index: 55;
				}
			}
			@media (max-width: 768px) {
				left:-225px;
			}
			.title{
				margin-left:30px;
				margin-bottom: 20px;
			}
		}
		.mail_div{
			margin-left:255px;
			display: -ms-flexbox;
			display: flex;
			-ms-flex-direction: row;
			flex-direction: row;
			position: relative;
			overflow: hidden;
			-ms-flex: 1 1 auto;
			flex: 1 1 auto;
			@media (max-width: 768px){
				margin-left: 30px;
			}
			// .wrapper{
			// 	position:relative;
			// }
			.custom-checkbox>.custom-control-input:checked ~ .custom-control-indicator{
				border-color:#00bcd4 !important;
			}
			.custom-checkbox>.custom-control-input ~ .custom-control-indicator:before{
				background: url(../img/icons/@2x/chechbox-tick--cyan@2x.png) center center no-repeat !important;
				-webkit-background-size: 80% auto !important;
				background-size: 80% auto !important;
			}
			.activity-block{
				max-width: 555px;
				padding-left:0px;
				min-width: 208px;
				min-width: 400px;
				box-sizing: border-box;
				display: -ms-flexbox;
				display: flex;
				-ms-flex-direction: column;
				flex-direction: column;
				width: calc(100% - 520px);
				padding-bottom: 55px;

				@media (max-width: 1250px){
					max-width:100%;
				}
				.open-date-cell{
					min-width:auto !important;
					width:auto !important;
					max-width: 56px;
					@media (max-width: 1250px){
						    max-width: 100px;
					}
				}

			}
			.activity-folder-block{
				padding:0px;
				flex: 1 1 auto;
				width: auto;
				display: -ms-flexbox;
				display: flex;
				-ms-flex-direction: column;
				flex-direction: column;
				position: relative;
				overflow-x: hidden;

				@media (max-width: 1250px){
					display:none;
				}
			}
		}
		.activity-block{
			// padding-left: 255px;
			.wrapper{
				@media (max-width: 992px){
					min-width: auto;
				}
				.ks-breadcrumbs{
					justify-content: left;
					-webkit-align-content: left;
					h2{
						font-size: 22px;
						font-weight: 600;
						font-style: normal;
						font-stretch: normal;
						line-height: 1.36;
						letter-spacing: normal;
						text-align: left;
						padding-left:20px;
						margin-top:0px;
						margin-bottom:0px;
					}
				}
				.checkbox-cell{
					width:60px !important;
					min-width:60px !important;
					max-width:60px !important;
					@media (max-width: 1600px){
						width: 30px !important;
						min-width:30px !important;
						max-width:30px !important;
						padding-left:0px !important;
						padding-right:0px !important;
					}
					

				}
				.count-cell{
					text-align:right;
					width:21px !important;
					min-width:21px !important;
					max-width:21px !important;
					padding-left:0px !important;
					padding-right:0px !important;

				}
				.case-name-cell{
					padding-left:0px !important; 
					@media (max-width: 1250px){
						max-width: 670px;
					}
					@media (max-width: 992px){
						max-width: 500px;
					}
					@media (max-width: 480px){
						max-width: 180px;
					}
					
				}
				.count{
					display:inline-block !important;
					// float:right;

					text-align:center;
					// margin-right:15px;
					// width: 21px;
					padding-left:5px;
					padding-right:5px;
					min-width:15px;

					height: 15px;
					border-radius: 15px;
					background-color: #00bcd4;
					font-size: 10px !important;
					font-weight: 600;
					font-style: normal;
					font-stretch: normal;
					line-height: 1.5 !important;
					letter-spacing: normal;

					color: #ffffff !important;
					@media (max-width: 768px) {
					  	// margin-right: 35px;
					  }
					}
					.mail-name-read{
						width: 100%;
						display: inline-block !important;
						color: #7f87a0;
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
						.mail_inline{
							opacity: 0.8 !important;
							font-size: 13px !important;
							font-weight: 500 !important;
							font-style: normal;
							font-stretch: normal;
							// line-height: 1.15 !important;
							letter-spacing: normal;
							text-align: left;
							color: #000000 !important;
						}
					}
					.mail-name-noread{
						width: 100%;
						display: inline-block !important;
						color: #7f87a0;
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
						.mail_inline{
							font-size: 13px !important;
							font-weight: 600 !important;
							font-style: normal;
							font-stretch: normal;
							line-height: 1.15 !important;
							letter-spacing: normal;
							text-align: left;
							color: #000000 !important;
							text-overflow: ellipsis;
							overflow: hidden;
						}
					}
					.mail-form{
						font-size: 13px !important;
						font-weight: normal;
						font-style: normal;
						font-stretch: normal;
						line-height: 1.54 !important;
						letter-spacing: 0.1px;
						text-align: left;
						color: #aaaaaa !important;
					}
					.table{
						&.no-border{
							tr{
								td, th{
									border-width: 0;
									&.case-name-cell{
										// max-width:323px;
									}
								}
								&.no_read{
									background-color: rgba(33, 33, 33, 0.03);
								}
							} 
							
						}

					}
					.contact-pagination{
						position: relative;
						border-top: none;
						left: auto;
						right: auto;
						bottom: auto;
					}
					.block-more{
						border-top: 1px solid #e6e6e6;
						position: absolute;
						bottom: 0;
						width: 100%;
					}

				}
				.empty-block-mail{
					border:none;
					max-width:320px;
					margin-top:94px;
					margin-left:auto;
					margin-right:auto;
					padding-left:45px;

					position: relative;
					.ks-text{
						font-size: 13px;
						opacity: 0.4;
						font-weight: normal;
						font-style: normal;
						font-stretch: normal;
						line-height: 1.54;
						letter-spacing: normal;
						text-align: left;
						color: #000000;
						float:left;
					}

					.compose_button{
						width:105px;
						margin-top:6px;
						display:block;
						float:right;
						padding-left:0px;
						padding-right:0px;
						padding-top:7px;
						padding-bottom:7px;
						font-size: 12px;
						line-height:16px;
						border-radius: 12px;
						font-weight: 600;
					}
				}
			} 
			.activity-folder-block{
				width:565px;
				border-left:5px solid #f7f7f7;
				@media (max-width: 768px) {
					width: 283px;
					min-width: 283px;
				}

				.empty-block{
						margin-top:115px;
					    margin-top: 154px;
					    padding-left:0px;
					    margin-left:auto;
					    margin-right:auto;
					    text-align:center;
					.ks-text{
						opacity:0.4;
					}
				}
			}

		} 
		.mail_grey_line{
			margin-left:30px;
			margin-right:30px;
			border-top:1px solid #e6e6e6;
			margin-top:15px;
			margin-bottom:15px;

		}


		.mail_mail{
			padding-top: 60px;
			padding-top:15px;
			padding-bottom:55px;
			height:615px;
			.simplebar-content{
				padding-top:0px !important;
				padding-bottom:0px !important;
			}
			.mail_mail_caption{
				font-size: 18px;
				font-weight: 600;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.67;
				letter-spacing: normal;
				text-align: left;
				color: #000000;
				margin-bottom:15px;
			}
			.mail_mail_header{
				border-top:1px solid #e6e6e6;
				padding-top:14px;
			}
			.mail_mail_date, .mail_from_date{
				float:right;
				width: 105px;
				font-size: 13px;
				float:right;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.54;
				letter-spacing: 0.1px;
				text-align: right;
				color: #aaaaaa;
				position:relative;
			}
			.mail_mail_from{
				padding-bottom:10px;
				// margin-right:147px;
				padding-left:15px;
				padding-right:15px;
				.mail_mail_headerf{
					display:block;
					float:left;
					width: 45px;
					font-size: 13px;
					font-weight: normal;
					font-style: normal;
					font-stretch: normal;
					line-height: 1.54;
					letter-spacing: 0.1px;
					text-align: left;
					color: #aaaaaa;

				}
				.mail_mail_headers{
					display:block;
					float:left;
					font-size: 13px;
					font-weight: normal;
					font-style: normal;
					font-stretch: normal;
					line-height: 1.54;
					letter-spacing: 0.1px;
					text-align: left;
					color: #333333;
				}
			}
			.mail_mail_fromblock{
				margin-bottom:15px;
				.mail_mail_from{
					box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
					padding-right:15px;
					padding-top:12px;
				}
			}
			.mail_mail_details_text{
				font-size: 11px;
				font-weight: 600;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.36;
				letter-spacing: normal;
				text-align: left;
				color: #212121;
				float:left;
				margin-left:25px;
				cursor:pointer;
			}
			.mail_mail_icons{
				float:right;
				ul{
					position: relative;
					padding:0;
					margin:0;
					list-style:none;
					li{
						float:left;
						position:relative;
						width:30px;
						height:30px;
						a{
							&:hover{
								.icon{
									&.icon-forward{
										background: url('../img/icons/@2x/icon-forward--cyan@2x.png') 0 0 no-repeat;
										-webkit-background-size: 100% auto;
										-moz-background-size: 100% auto;
										background-size: 100% auto;
									}
									&.icon-reply-all{
										background: url('../img/icons/@2x/icon-reply-all--cyan@2x.png') 0 0 no-repeat;
										-webkit-background-size: 100% auto;
										-moz-background-size: 100% auto;
										background-size: 100% auto;

									}
									&.icon-mail-reply{
										background: url('../img/icons/@2x/icon-reply2-cyan@2x.png') 0 0 no-repeat;
										-webkit-background-size: 100% auto;
										-moz-background-size: 100% auto;
										background-size: 100% auto;

									}
								
								}
							}
						}
						span {
							line-height: 25px;
							text-transform: uppercase;
							font-size: 12px;
							color: #5b5b5b;
							display: block;
							font-weight: 600;
							&.icon{
								&.icon-dots{
										// margin-top: -8px;
									}
								}
						}
					}
				}
			}
			
			.mail_mail_fromto{
				padding-bottom:10px;
			}
			.mail_mail_body{
				margin-top:10px;
				border-top:1px solid #e6e6e6;
				padding-top:28px;
				padding-bottom:20px;
				font-size: 16px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.88;
				letter-spacing: normal;
				text-align: left;
				color: #000000;
				a{
					color: #00bcd4;
					text-decoration:underline;
				}
			}
			.mail_mail_footer{
				border-top:1px solid #e6e6e6;
				padding-top: 16px;
				padding-bottom: 10px;
			}
		}
		
		.mail_mail_footer{
			border-top: 1px solid #e6e6e6;
			position: absolute;
			bottom: 0;
			width: 100%;
			background:#fff;
			padding-top: 14px;
			padding-bottom:10px;
			.mail_mail_icons{
				float:right;
				ul{
					position: relative;
					padding:0;
					margin:0;
					list-style:none;
					li{
						float:left;
						position:relative;

						width:auto;
						height:30px;
						a{

							position:relative;
							padding-left:21px;
							padding-top:0px;
							padding-bottom:0px;
							padding-right:25px;
							.icon{
								left:0px;
								// top:0px;
								margin-top: -5px;
								margin-left:0px;
								&.icon-dots{
									top: 0%;
									left: 50%;
									margin-top: 4px;
									margin-left: -2px;
								}
							}
							span {
								font-size: 11px;
								font-weight: 600;
								font-style: normal;
								font-stretch: normal;
								line-height: 1.36;
								letter-spacing: normal;
								text-align: left;
								color: #212121;
							}
							&:hover{
								.icon{
									&.icon-forward{
										background: url('../img/icons/@2x/icon-forward--cyan@2x.png') 0 0 no-repeat;
										-webkit-background-size: 100% auto;
										-moz-background-size: 100% auto;
										background-size: 100% auto;
									}
									&.icon-reply-all{
										background: url('../img/icons/@2x/icon-reply-all--cyan@2x.png') 0 0 no-repeat;
										-webkit-background-size: 100% auto;
										-moz-background-size: 100% auto;
										background-size: 100% auto;

									}
									&.icon-mail-reply{
										background: url('../img/icons/@2x/icon-reply2-cyan@2x.png') 0 0 no-repeat;
										-webkit-background-size: 100% auto;
										-moz-background-size: 100% auto;
										background-size: 100% auto;

									}

								}
								span {
									color: #00bcd4;
								}
							}
						}

					}
				}
			}
		}
		.mail_mail_details{
				// padding-bottom:30px;
				padding-lefT:15px;
				display:none;
				.mail_details_item{
					padding-bottom:15px;
				}
				.mail_detail_icon{
					width: 30px;
					height: 30px;
					border-radius: 15px;
					background-color: #ffffff;
					border:1px solid #e6e6e6;
					float:left;
					margin-right:15px;
					position:relative;
					  // margin-top:7.5px;
					  // margin-bottom:7.5px;
					  &:after{
					  	position: absolute;
					  	top: 30px;
					  	bottom: -15px;
					  	left: 50%;
					  	content: "";
					  	border-left: 1px solid #e6e6e6;
					  	margin-left: -1px;
					  }
					  &.last_detail_icon{
					  	&:after{
					  		display:none;
					  	}
					  }
					}
					.mail_detail_text{
						float:left;
						padding-top:7px;
						font-size: 13px;
						color: #999999;
					}
					.mail_details_date{
						min-width:120px;
						float:right;
						text-align:right;
						font-size: 13px;
						color: #aaaaaa;
						padding-top:7px;

					}
				}

			}
			.clear{
				clear:both;
			}
			.message_write{
				// .message_write_header{
				// 	cursor:pointer;
				// }
				display:none;
				-webkit-transition: all .3s;
				-o-transition: all .3s;
				transition: all .3s;
				height:52px;
				
				min-width:600px;
				overflow:hidden;
				position:fixed;
				z-index: 999;
				bottom:0px;
				right:30px;
				.message_write_caption{
					position:relative;
					float:left;

					.icon{

						lefT:0px !important;
						margin-lefT:0px !important;

					}
					.write_caption_name{
						padding-left:30px;
						font-size: 16px;
						font-weight: 600;
						font-style: normal;
						font-stretch: normal;
						line-height: 1.88;
						letter-spacing: normal;
						text-align: left;
						color: #ffffff;
					}
				}
				.message_write_buttons{
					float:right;
					position:relative;
					margin-top: 5px;
					.icon{
						position:relative;
						display:block;
						float:left;
						margin-left:15px;
						top:0px !important;
						left:0px !important;
						margin-left:15px !important;
						margin-top:0px !important;

					}
				}
				&.show{
					display:block;
					.message_write_buttons{
						.icon{
							&.icon-collapse{
								background-position:0px -14px;
							}
						}
					}
				}
				&.open{
					height:auto;
					box-shadow: 0 15px 60px 0 rgba(0, 0, 0, 0.1);
					bottom:30px;
					border-radius:12px;
					
					.message_write_buttons{
						.icon{
							&.icon-collapse{
								background-position:0px 0px;
							}
						}
					}
					
				}
				&.big_size{
					height:auto;
					z-index: 1000000;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					min-width:1320px;
					min-width: 95%;
					// margin-left:60px;
					// margin-right:60px;
					overflow:visible;
					border-radius:12px;

					.message_write_buttons{
						.icon{
							&.icon-maximize{
								background: url('../img/icons/@2x/icon-maximize--white2@2x.png') 0 0 no-repeat;
								-webkit-background-size: 100% auto;
								background-size: 100% auto;
							}
						}
					}
				}
				

				.message_write_header{
					padding:12px 15px 10px 15px;
					background:#00bcd4;
					border-radius:12px 12px 0px 0px;
					color:#ffffff;
				}
				.message_write_body{
					padding:15px;
					background:#ffffff;
					border-radius: 0px 0px 12px 12px;
				}
				.message_write_input{
					position:relative;
					margin-bottom:15px;
					span{
						font-size: 13px;
						font-weight: normal;
						font-style: normal;
						font-stretch: normal;
						line-height: 1.54;
						letter-spacing: normal;
						text-align: left;
						color: #999999;
						position:absolute;
						left:15px;
						top:13px;
					}
					input{
						height: 45px;
						border-radius: 12px;
						background-color: #ffffff;
						border:1px solid #e6e6e6;
						padding-left:65px;
						width:100%;
						font-size: 13px;
						  // font-weight: bold;
						  font-style: normal;
						  font-stretch: normal;

						  letter-spacing: normal;
						  text-align: left;
						  color: #000000;

						}
						&.message_write_to{
							input{
								padding-left:49px;
							}
						}
						&.message_write_subject{
							input{
								padding-left:78px;
							}
						}
					}
					.message_write_footer{
						border-top:1px solid #e6e6e6;
						padding-top:15px;
						a{

						}
						.btn{
							width: 105px;
							height: 45px;
							padding:0;
							line-height: 45px;
							border-radius: 12px;
							box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
							background-color: #00bcd4;
							font-size: 13px;
							font-weight: bold;
							font-style: normal;
							float:right;
						}
						.attach-btn{
							
							.ks-text{
								line-height: 45px;
								&:hover{
									color: #00bcd4;
								}
							}
							&:hover{
								.ks-text{
									color: #00bcd4;
								}
								.icon{
									&.icon-deadline{
										background: url(../img/icons/@2x/icon-calendar--cyan@2x.png) 0 0 no-repeat;
										-webkit-background-size: auto 100%;
    									background-size: auto 100%;
									}
									&.icon-attach{
										background: url(../img/icons/@2x/icon-attach-blue@2x.png) 0 0 no-repeat;
										-webkit-background-size: auto 100%;
    									background-size: auto 100%;
									}
								}
							}
							
						} 

					}

				}
.ks-mobile-overlay.mess_active{
	opacity: 1;
	z-index: 99;
	visibility: visible;

}
.attach-btn{
	&.attach-btn-active{
		.icon{
			&.icon-deadline{
				background: url('../img/icons/@2x/icon-calendar--red@2x.png') 0 0 no-repeat;
				 -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
			}
		}
		.ks-text{
			color:#ff5722 !important;
		}
		
	}
}
.modal-profile{
	
	&.modal-attach-date{
		max-width:1140px;
		.modal-footer{
				display:flex;
				padding: 0 15px 15px;
				.btn-cancel{
					box-shadow:none;
				}
				.btn-new-attach{
					border-radius: 12px;
					width:140px;
					  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
					  background-color: #ff5722;
					  color:#ffffff;
				}
			}
		.pane-content{
			&.calendar{
				.contact-content{
					.custom-scroll-table{
						max-height:480px;
						td{
							&.active{
								div{
									border:1px solid #ff5722;
									border: 1px solid #ff5722;
								    width: 100%;
								    height: 100%;
								    margin: -.75rem;
								    position: absolute;
								    box-sizing: content-box;
								}
								
							}
						}
					}
					.top{
						margin-bottom:0px;
						border:none;
					}
				} 
			}
		} 
		.modal-header{
			background: #ff5722;
		}
		.modal-body{
			padding:0;
		}
		.modal-footer {
		    display: -webkit-box;
		    display: -webkit-flex;
		    display: -ms-flexbox;
		    display: flex;
		}
		.links-group {
  					height: 58px;
  					padding: 0 30px;
  					display: -ms-flexbox;
				    display: -webkit-flex;
				    display: flex;
				    -webkit-flex-direction: row;
				    -ms-flex-direction: row;
				    flex-direction: row;
				    -webkit-flex-wrap: nowrap;
				    -ms-flex-wrap: nowrap;
				    flex-wrap: nowrap;
				    -webkit-justify-content: flex-start;
				    -ms-flex-pack: start;
				    justify-content: flex-start;
				    -webkit-align-content: stretch;
				    -ms-flex-line-pack: stretch;
				    align-content: stretch;
				    -webkit-align-items: center;
				    -ms-flex-align: center;
				    align-items: center;

				    @media (max-width: 768px) {
				    	-webkit-flex-wrap: wrap;
				    	-ms-flex-wrap: wrap;
				    	flex-wrap: wrap;
				    	height: auto;
				    	padding: 10px;
				    }

				    & > a {
				    	display: inline-block;
				    	color: #cccccc;
				    	margin-right: 30px;
				    	font-size: 16px;
				    	font-weight: 600;

				    	@media (max-width: 768px) {
				    		font-size: 12px;
				    		margin-right: 10px;
				    		// margin-bottom: 10px;
				    	}

				    	&.active {
				    		color: #000;
				    	}

				    	&:hover {
				    		color: #333;
				    	}
				    }

				    .custom-checkbox {
				    	position: relative;
				    	margin-left: 30px;

				    	@media (max-width: 768px) {
				    		margin-left: 0;
				    		font-size: 12px;
				    	}

				    	&:before {
				    		position: absolute;
				    		top: 0;
				    		left: -30px;
				    		bottom: 0;
				    		content: "";
				    		width: 1px;
				    		background-color: #f2f2f2;

				    		@media (max-width: 768px) {
				    			display: none;
				    		}
				    	}
				    }
				}
	}
	&.modal-attach{
		max-width:1095px;
		.modal-header{
			background: #00bcd4;
		}
		.modal-body{
			background:#f7f7f7;
			padding:15px;
		}
		.form-group{
			position:relative;
			padding:15px;
			border-radius: 12px;
			  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
			  background-color: #ffffff;
			span{
				display:block;
				float:left;
				&.icon{
					position:relative;
					&.icon-file-doc-large, &.attach-icon-loader, &.chechbox-tick--cyan, &.attach-icon-error{
						left:0px;
						top:0px;
						margin-top:0px;
						margin-left:0px;
						margin-right:10px;
					}
					&.attach-icon-loader, &.chechbox-tick--cyan, &.attach-icon-error{
						margin-top:10px;
					}
					&.chechbox-tick--cyan{
						margin-top:7.5px;
					}
				}
				&.ks-text{
					font-size: 18px;
					  font-weight: 600;
					  font-style: normal;
					  font-stretch: normal;
					  line-height: 1.67;
					  letter-spacing: normal;
					  text-align: left;
					  color: #aaaaaa;
					  line-height:32px;
					  margin-right:30px;
					  // padding-left:60px;
				}
				&.ks_text_little{
					font-size: 13px;
					  font-weight: normal;
					  font-style: normal;
					  font-stretch: normal;
					  line-height: 1.15;
					  letter-spacing: 0.3px;
					  text-align: left;
					  color: #aaaaaa;
					  line-height:32px;
				}
			}
			.btn{
				&.cancel{
					float:right;
					position: relative;
				    padding: 6px 16px 0px 15px;
				    text-align: center;
				    color: #000;
				    text-transform: uppercase;
				    -webkit-border-radius: 15px;
				    border-radius: 15px;
				    // -webkit-box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.3);
				    // box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.3);
				    -webkit-transition: all .3s;
				    -o-transition: all .3s;
				    transition: all .3s;
				    display: -ms-flexbox;
				    display: -webkit-flex;
				    display: -webkit-box;
				    display: flex;
				    -webkit-flex-direction: row;
				    -ms-flex-direction: row;
				    -webkit-box-orient: horizontal;
				    -webkit-box-direction: normal;
				    flex-direction: row;
				    -webkit-flex-wrap: nowrap;
				    -ms-flex-wrap: nowrap;
				    flex-wrap: nowrap;
				    -webkit-justify-content: center;
				    -ms-flex-pack: center;
				    -webkit-box-pack: center;
				    justify-content: center;
				    -webkit-align-content: stretch;
				    -ms-flex-line-pack: stretch;
				    align-content: stretch;
				    -webkit-align-items: center;
				    -ms-flex-align: center;
				    -webkit-box-align: center;
				    align-items: center;
				    font-size: 13px;
				    font-weight: 700;
				    background: #fff;
				}
				
			}
			&.success{
				span{
					&.ks-text{
						color: #000000;
					}
					&.ks_text_little{
						color:#00bcd4;
					}
				}
			}
			&.error{
				span{
					
					&.ks_text_little{
						color:#ff5722;
					}
				}
			}
			.col-slider {
				    padding-top: 12px;
				    margin-top:20px;
				    &.col-slider1 {
				    	width:180px;
				    	float:left;
				    	margin-right:30px;
				    }
				     &.col-slider2 {
				     	width:180px;
				    	float:left;
				    	margin-right:30px;
				    }
				     &.col-slider3 {
				     	width:210px;
				    	float:left;
				    	margin-right:30px;
				    	padding-top:0px;
						margin-top: 15px;
				    }
				     &.col-slider4 {
				     	width:137px;
				    	float:left;
				    	margin-right:28px;
				    }
				     &.col-slider5 {
				     	width:210px;
				    	float:left;
				    	padding-top:0px;
						margin-top: 15px;
				    }
				   
					.ks-text{
						font-size: 13px;
						line-height:15px;
					}
				}
			 .col-slider_nopad{
						padding-top:0px;
						margin-top: 20px;
					}
			.expires-group {
				position: relative;

				

				&:before {
					position: absolute;
					top: 50%;
					left: 10px;
					content: "";
					background: url(../img/icons/@2x/icon-date@2x.png) 0 0 no-repeat;
					width: 16px;
					height: 16px;
					background-size: auto 100%;
					margin-top: -8px;
					opacity: .3;
				}

				.form-control {
					position: relative;
					padding-left: 40px;
					background: transparent;
				}
			}

			.ks-checkbox-slider{
				.ks-wrapper{
					top:0px;
				}
				input[type=checkbox]:checked ~ .ks-wrapper{
					background-color: #00bcd4;
				}
				
			} 
			
		}
		.modal-footer{
			display:block;
			padding-top:14px;
			.attach-btn{
				padding-top:7.5px;
				padding-bottom:5px;
			}
			.btn-new{
				float:right;
				box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  				background-color: #00bcd4;
  				color:#ffffff;
  				position:relative;
  				padding-left:38px;
  				.icon{
  					&.icon-checkmark--white{
  						left:15px;
  						margin-left:0px;
  						margin-top: -8.5px;
  					}
  				}
			}
		}
		&.modal-attach-files{
			max-width:1320px;
			.table{
				.action-block {
				.email-block,
  				.phone-block {
  					display: inline-block;
  					position: relative;
  					padding: 5px 35px 5px 30px;
  					cursor: pointer;
  					background: transparent;
  					color: #333;
  					margin-bottom: 2px;
  					-webkit-border-radius: 0;
  					-moz-border-radius: 0;
  					border-radius: 0;
  					-webkit-transition: all .3s;
  					   -moz-transition: all .3s;
  					    -ms-transition: all .3s;
  					     -o-transition: all .3s;
  					        transition: all .3s;
						-webkit-border-radius: 15px;
  					-moz-border-radius: 15px;
  					border-radius: 15px;

  					&.tippy-active,
  					&:hover {
  						background: #4caf50;

  						span {
  							color: #fff;
  						}

  						.icon {
  							&.icon-phone {
  								background: url('../img/icons/@2x/icon-phone--white@2x.png') 0 0 no-repeat;
							    -webkit-background-size: 100% auto;
							    -moz-background-size: 100% auto;
							    background-size: 100% auto;
  							}

  							&.icon-mobile {
  								background: url('../img/icons/@2x/icon-mobile--white@2x.png') 0 0 no-repeat;
							    -webkit-background-size: auto 100%;
							    -moz-background-size: auto 100%;
							    background-size: auto 100%;
  							}
  						}
  					}

  					&.open-popover {
  						&:before {
  							position: absolute;
  							top: 50%;
  							right: 10px;
						    border: 0;
						    background: url(../img/icons/@2x/angle-down--white@2x.png) 0 0 no-repeat;
						    -webkit-background-size: 100% auto;
						    -moz-background-size: 100% auto;
						    background-size: 100% auto;
						    width: 9px;
						    height: 6px;
						    content: "";
						    margin-top: -3px;
						    -moz-transition: all 0.3s;
						    -o-transition: all 0.3s;
						    -webkit-transition: all 0.3s;
						    transition: all 0.3s;
  						}
  					}

  					span {
  						-webkit-transition: all .3s;
  					   -moz-transition: all .3s;
  					    -ms-transition: all .3s;
  					     -o-transition: all .3s;
  					        transition: all .3s;
  						color: #333;
  						word-break: break-all;
  					}

  					.popover-content {
  						display: none;
  					}

  					.icon {
  						left: 10px;
  						margin-left: 0;
  					}
  				}

  				.email-block {
  					padding: 5px 35px 5px 10px;
  				}
  				}
				.checkbox-cell {
							width: 100px;
							max-width: 100px;
							min-width: 100px;
						}

						tbody {
							.row-checked {
								td {
									span {
										// color: #000;
									}
								}
							}

							td {
								span {
									display: block;
								    color: #a3a3a3;
								    font-size: 13px;
								    line-height: 1.5em;
								    word-wrap: break-word;
									&.bold {
										font-weight: 600;
									}

									&.black {
										color: #000;
									}
								}
							}
						}
					}
			
			.action-block{
				  		position: relative;
  						height: 44px;
  						
  						&.tippy-active {

  							.dropdown-toggle {
  								position: relative;
  								top: -1px;
  								background: #4caf50;
  								color: #fff;
	  							opacity: 1;
	  							height: 46px;
									-webkit-border-radius: 15px;
									-moz-border-radius: 15px;
	  							border-radius: 15px;
	  							padding: 13px 15px;

	  							&:after {
	  								background: url(../img/icons/@2x/angle-down--white@2x.png) 0 0 no-repeat;
								    -webkit-background-size: 100% auto;
								    -moz-background-size: 100% auto;
								    background-size: 100% auto;
								    opacity: 1;
	  							}

	  							span {
	  								color: #fff;
	  							}
  							}
  						}

  						.dropdown-toggle {
  							background: transparent;
  							padding: 12px 15px;
  							opacity: 0.7;
  							cursor: pointer;
  							-webkit-border-radius: 0;
								-moz-border-radius: 0;
								border-radius: 0;
  							-webkit-transition: all .3s;
  							   -moz-transition: all .3s;
  							    -ms-transition: all .3s;
  							     -o-transition: all .3s;
  							        transition: all .3s;

  							.ks-info {
  								font-weight: 600;
  							}

  							span {
  								color: #333;
  							}

  							&:after {
							    background: url(../img/icons/@2x/angle-down@2x.png) 0 0 no-repeat;
							    -webkit-background-size: 100% auto;
							    -moz-background-size: 100% auto;
							    background-size: 100% auto;
    							position: absolute;
					        right: 15px;
							    top: 50%;
							    margin-top: -3px;
							    opacity: 0.6;
  							}
  						}
  					}
			
			.modal-body{
				background:#fff;
				.ks-upload-block {
					min-height:480px;
				    position: relative;
				    -webkit-border-radius: 12px;
				    border-radius: 12px;
				    border: dashed 2px #e6e6e6;
				    padding: 14px 20px;
				    display: -webkit-box;
				    display: -webkit-flex;
				    display: -ms-flexbox;
				    -js-display: flex;
				    display: flex;
				    -webkit-box-orient: horizontal;
				    -webkit-box-direction: normal;
				    -webkit-flex-direction: column;
				    -ms-flex-direction: column;
				    flex-direction: column;
				    -webkit-box-align: center;
				    -webkit-align-items: center;
				    -ms-flex-align: center;
				    align-items: center;
				    -webkit-box-pack: center;
				    -webkit-justify-content: center;
				    -ms-flex-pack: center;
				    justify-content: center;

				    img{
				    	opacity: 0.1;
				    	margin-bottom:25px;
				    }
				    .ks-text-bold{
				    	font-size: 18px;
				    	 opacity: 0.8;
						  font-weight: 600;
						  font-style: normal;
						  font-stretch: normal;
						  line-height: 1.67;
						  letter-spacing: normal;
						  text-align: center;
						  color: #000000;
						  margin-bottom:5px;
				    }
				    .ks-text{
				    	font-size: 13px;
						  font-weight: normal;
						  font-style: normal;
						  font-stretch: normal;
						  line-height: 1.54;
						  letter-spacing: normal;
						  text-align: center;
						  color: #000000;
						  opacity: 0.4;
						  margin-bottom:5px;
				    }
				    .btn{
				    	position: relative;
					    font-size: 12px;
					    font-weight: 500;
					    width:180px;
					    -webkit-border-radius: 2px;
					    border-radius: 2px;
					    height: 45px;
					    cursor:pointer;
						  border-radius: 12px;
						  background-color: #00bcd4;
					    
					    color: #fff;
					   
					    -webkit-box-align: center;
					    -webkit-align-items: center;
					    -ms-flex-align: center;
					    align-items: center;
					    -webkit-box-pack: center;
					    -webkit-justify-content: center;
					    -ms-flex-pack: center;
					    justify-content: center;
					    -webkit-transition: none;
					    -o-transition: none;
					    transition: none;
						.ks-text {
						    padding-left: 21px;
						    padding-right: 0 !important;
						    color: #fff;
						}
						input[type="file"] {
						    position: absolute;
						    top: 0;
						    right: 0;
						    min-width: 100%;
						    min-height: 100%;
						    font-size: 26px;
						    text-align: right;
						    opacity: 0;
						    cursor: inherit;
						    display: block;
						}
						.icon {
						    left: 12px;
						    margin-left: 0;
						    width: 20px;
						    height: 17px;
						    margin-top: -7.5px;
						}
				    } 
				    
				}
			}
			.nav-tabs{
					border:none;
					.nav-item{
						border:none !important;
						.nav-link{
							 background:none;
							  border:none !important;
							  color:#cccccc;
							  padding-top:0;
							  font-weight: 600;
							  padding-bottom:0;
							  font-size: 16px;
							   line-height: 1.88;
							   margin-bottom:10px;
							&.active, &:hover{
							font-size: 16px;
							  font-weight: 600;
							  font-style: normal;
							  font-stretch: normal;
							  line-height: 1.88;
							  letter-spacing: normal;
							  text-align: left;
							  color: #000000;

							}
						}
					}
			
				}
			
			.modal-footer{
				display:flex;
				.btn-cancel{
					box-shadow:none;
				}
				.btn-new-attach{
					border-radius: 12px;
					  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
					  background-color: #00bcd4;
					  color:#ffffff;
				}
			}
		}
	}
}
.profile-content{
	&.case-messages{
		.table-heading{
			.similar-nav-block{
				ul{
					li{
					&:hover,
		    		&.active{
						a{
							color:#ff9800;
	
						}
					}
				}
			}
			}
		}
	}
}
.select2-container--open{
		.select2-dropdown--below{
			z-index: 55555555;
		}
	}
.mail_div{
	
	
	.count-cell{
		width:20% !important;
		max-width:none !important;
		min-width:auto !important;
	}
	
}