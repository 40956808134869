.form-group {
	position: relative;
	.no-pd-r {
		@media (max-width: 768px) {
			padding: 0 15px !important;
		}
	}

	&.b-account {
		.col-12 {
			margin-bottom: 10px;
		}
	}
}

.form-title {
	display: block;
	font-size: 13px;
	margin-bottom: 10px;
	min-height: 20px;

	&.generate-link {
		color: #ff9800;
		position: absolute;
		right: 0;
		top: 0;
	}
}

input.form-control {
	height: 45px;
	background: #fff;
	border: 1px solid #e6e6e6 ;
	padding: 10px 15px;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
	color: #000;
	font-family: $font-open-sans;
	-webkit-transition: all .3s;
	   -moz-transition: all .3s;
	    -ms-transition: all .3s;
	     -o-transition: all .3s;
	        transition: all .3s;

	&::-webkit-input-placeholder {
	  color: #a3a3a3;
	}
	&::-moz-placeholder {
	  color: #a3a3a3;
	}
	&:-ms-input-placeholder {
	  color: #a3a3a3;
	}
	&:-moz-placeholder {
	  color: #a3a3a3;
	}

	&:hover {
		-webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
		-moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
		box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
	}

	&:focus {
		color: inherit;
		border: 1px solid #e6e6e6 ;
		-webkit-box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.1);
		-moz-box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.1);
		box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.1);
	}
}

.add-row {
	.form-control {
		position: relative;
		border-right: 0;
		margin-right: -10px;
		padding-right: 20px;
		-webkit-border-radius: 15px 0 0 15px;
		-moz-border-radius: 15px 0 0 15px;
		border-radius: 15px 0 0 15px;
	}
	.adding-row {
    position: relative;
		display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-top: 13px;

    &.has-danger {
    	.inner {
    		padding-bottom: 25px;
    	}

    	.primary-block {
    		label {
    			color: #333;
    		}
    	}
    }

    .inner {
    	width: 100%;
    	position: relative;
			display: -ms-flexbox;
	    display: -webkit-flex;
	    display: flex;
	    -webkit-flex-direction: row;
	    -ms-flex-direction: row;
	    flex-direction: row;
	    -webkit-flex-wrap: nowrap;
	    -ms-flex-wrap: nowrap;
	    flex-wrap: nowrap;
	    -webkit-justify-content: flex-start;
	    -ms-flex-pack: start;
	    justify-content: flex-start;
	    -webkit-align-content: stretch;
	    -ms-flex-line-pack: stretch;
	    align-content: stretch;
	    -webkit-align-items: flex-start;
	    -ms-flex-align: start;
	    align-items: flex-start;
	    margin-top: 13px;
    }

    .primary-block {
    	width: 100%;
    	padding: 15px 10px 0;
    }

    &.wrapping {
    	-webkit-flex-wrap: wrap;
	    -ms-flex-wrap: wrap;
	    flex-wrap: wrap;
    }

    &.without-btn {
    	.form-control {
    		margin-right: 0;
    		border-right: 1px solid #e6e6e6;
    		border-radius: 15px;
    	}

    	.col-12 {
    		@media (max-width: 768px) {
    			margin-bottom: 10px;
    		}
    	}
    }

    &.more-fields {
    	.add-row-link {
    		margin-left: 0;
    		top: 15px;

    		@media (max-width: 992px) {
    			display: inline-block;
			    -webkit-flex: 1 0 100%;
			    -ms-flex: 1 0 100%;
			    -webkit-box-flex: 1;
			    flex: 1 0 100%;
			    width: 100%;
			    top: auto;
    		}
    	}

    	.form-control-feedback {
    		position: relative;
    	}
    }

    .form-control-feedback {
    	position: absolute;
    	bottom: 0;
    	bottom:-18px;
    	left: 0;
    	right: 0;
    }

    &:nth-child(2) {
    	margin-top: 0;
    }

    .row-select {
    	-webkit-flex: 1 0 115px;
	    -ms-flex: 1 0 115px;
	    flex: 1 0 115px;

	    @media (max-width: 768px) {
	    	-webkit-flex: 1 0 80px;
		    -ms-flex: 1 0 80px;
		    flex: 1 0 80px;
	    }
    }

    .add-row-link {
    	position: relative;
    	margin-left: 15px;
    	color: #4caf50;
    	font-weight: 600;
    	padding: 12.5px 10px 12.5px 35px;
    	-webkit-flex: 1 0 140px;
	    -ms-flex: 1 0 140px;
	    flex: 1 0 140px;
	    text-transform: uppercase;
	    -webkit-border-radius: 15px;
	    -moz-border-radius: 15px;
	    border-radius: 15px;

	    @media (max-width: 992px) {
	    	-webkit-flex: 1 0 100px;
		    -ms-flex: 1 0 100px;
		    flex: 1 0 100px;
		    padding-left: 15px;
		    font-size: 11px;
		    margin-left: 10px;
		    padding-right: 5px;
		    text-align: center;
	    }

	    @media (max-width: 768px) {
				-webkit-flex: 1 0 auto;
		    -ms-flex: 1 0 auto;
		    flex: 1 0 auto;
		    padding-left: 15px;
		    font-size: 11px;
		    margin-left: 10px;
		    padding-right: 5px;
		    text-align: center;
		    min-width: 45px;
		    height: 45px;
		    padding: 0;
	    }

	    &:hover {
	    	background: #f7fff8;
	    }

	    .icon {
	    	left: 10px;
	    	margin-left: 0;
	    	background: url("../img/icons/@2x/icon-add--green@2x.png") 0 0 no-repeat;
	    	-webkit-background-size: 100% auto;
		    -moz-background-size: 100% auto;
		    background-size: 100% auto;

		    @media (max-width: 992px) {
		    	left: 3px;
		    }

		    @media (max-width: 768px) {
		    	left: 50%;
		    	padding: 0;
		    	margin-left: -6.5px;
		    }
	    }

	    .ks-text {
	    	@media (max-width: 768px) {
	    		display: none;
	    	}
	    }

	    &.ks-remove-row {
	    	color: #333;
	    	font-weight: 600;

	    	.icon {
	    		left: 8px;
  				margin-left: 0;
  				margin-top: -7px;
  				background: url(../img/icons/@2x/icon-remove@2x.png) 0 0 no-repeat;
  				-webkit-background-size: 100% auto;
  				-moz-background-size: 100% auto;
  				background-size: 100% auto;
  				-moz-transition: all 0.3s;
			    -o-transition: all 0.3s;
			    -webkit-transition: all 0.3s;
			    transition: all 0.3s;
			    width: 16px;
			    height: 16px;
			    margin-top: -8px;

			    @media (max-width: 992px) {
			    	left: 5px;
			    }

			    @media (max-width: 768px) {
			    	left: 50%;
			    	margin-left: -9px;
			    }
	    	}

	    	&:hover {
	    		background: rgba(51,51,51,0.04);
	    	}
	    }
    }
	}
}

.select2 {
	width: 100% !important;
	right: auto !important;
}

.cases-profile .select2-container--default.opensright {
	left: auto !important;
}

.has-danger {
	.select2-container--default .select2-selection--single {
		border: 1px solid #d9534f;
	}

	.select2-container--default .select2-selection--single .select2-selection__rendered {
		color: #d9534f;
	}
}

.cases .select2-container--default.select2-container--open,
.page-case-description .select2-container--default.select2-container--open,
.page-profile-info .select2-container--default.select2-container--open {
	z-index: 1050121;
}

.select2-container--default .select2-selection--single {
	border: 0;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
	height: 45px;
	-webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
	-webkit-transition: box-shadow .3s, color .3s, opacity .3s;
	   -moz-transition: box-shadow .3s, color .3s, opacity .3s;
	    -ms-transition: box-shadow .3s, color .3s, opacity .3s;
	     -o-transition: box-shadow .3s, color .3s, opacity .3s;
	        transition: box-shadow .3s, color .3s, opacity .3s;

	&:hover {
		-webkit-box-shadow: 0 2px 6px 0.5px rgba(0, 0, 0, 0.2);
		-moz-box-shadow: 0 2px 6px 0.5px rgba(0, 0, 0, 0.2);
		box-shadow: 0 2px 6px 0.5px rgba(0, 0, 0, 0.2);

		.select2-selection__rendered {
			color: #4caf50;
		}

		.select2-selection__arrow b {
  		background: url(../img/icons/@2x/angle-down--green@2x.png) 0 0 no-repeat;
  		-webkit-background-size: 100% auto;
		  -moz-background-size: 100% auto;
		  background-size: 100% auto;
		  opacity: 1;
		}
	}
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
	-webkit-box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.1);
	box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.1);

	.select2-selection__arrow b {
		-webkit-transform: rotate(180deg);
		   -moz-transform: rotate(180deg);
		    -ms-transform: rotate(180deg);
		     -o-transform: rotate(180deg);
		        transform: rotate(180deg);
	}
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
	line-height: 45px !important;
	color: #333 !important;
	font-weight: 400;
	padding: 0 15px;
	-webkit-transition: all .3s;
	   -moz-transition: all .3s;
	    -ms-transition: all .3s;
	     -o-transition: all .3s;
	        transition: all .3s;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
	&[title="Only Done"],
	&[title="Meetings"] {
		position: relative;

		&:before {
			position: absolute;
			top: 50%;
			left: 10px;
			content: "";
			background: url('../img/icons/@2x/event-point--cyan@2x.png') 0 0 no-repeat;
			width: 15px;
			height: 15px;
			background-size: auto 100%;
			margin-top: -7.5px;
		}
	}

	&[title="All Deadlines"],
	&[title="All events"] {
		position: relative;

		&:before {
			position: absolute;
			top: 50%;
			left: 10px;
			content: "";
			background: url('../img/icons/@2x/event-point-allevents@2x.png') 0 0 no-repeat;
			width: 15px;
			height: 15px;
			background-size: auto 100%;
			margin-top: -7.5px;
		}
	}

	&[title="Only Not Done"],
	&[title="Deadlines"] {
		position: relative;

		&:before {
			position: absolute;
			top: 50%;
			left: 10px;
			content: "";
			background: url('../img/icons/@2x/event-point--red@2x.png') 0 0 no-repeat;
			width: 15px;
			height: 15px;
			background-size: auto 100%;
			margin-top: -7.5px;
		}
	}

	&[title="Only In Progress"],
	&[title="Tasks"] {
		position: relative;

		&:before {
			position: absolute;
			top: 50%;
			left: 10px;
			content: "";
			background: url('../img/icons/@2x/event-point--yellow@2x.png') 0 0 no-repeat;
			width: 15px;
			height: 15px;
			background-size: auto 100%;
			margin-top: -7.5px;
		}
	}

	&[title="Year"] {
		position: relative;
		padding-left: 40px;

		&:before {
			position: absolute;
			top: 50%;
			left: 10px;
			content: "";
			background: url('../img/icons/@2x/icon-calendar--red@2x.png') 0 0 no-repeat;
			width: 15px;
			height: 15px;
			background-size: auto 100%;
			margin-top: -7.5px;
		}
	}

	&[title="Month"] {
		position: relative;
		padding-left: 40px;

		&:before {
			position: absolute;
			top: 50%;
			left: 10px;
			content: "";
			background: url('../img/icons/@2x/icon-calendar-month--red@2x.png') 0 0 no-repeat;
			width: 15px;
			height: 15px;
			background-size: auto 100%;
			margin-top: -7.5px;
		}
	}

	&[title="Week"] {
		position: relative;
		padding-left: 40px;

		&:before {
			position: absolute;
			top: 50%;
			left: 10px;
			content: "";
			background: url('../img/icons/@2x/icon-calendar-week--red@2x.png') 0 0 no-repeat;
			width: 15px;
			height: 15px;
			background-size: auto 100%;
			margin-top: -7.5px;
		}
	}

	&[title="All period"] {
		position: relative;
		padding-left: 40px;

		&:before {
			position: absolute;
			top: 50%;
			left: 10px;
			content: "";
			background: url('../img/time.png') 0 0 no-repeat;
			width: 15px;
			height: 15px;
			background-size: auto 100%;
			margin-top: -7.5px;
			opacity: .4;
		}
	}

	&[title="All calendars"] {
		position: relative;
		font-weight: 600;

		&:before {
			position: absolute;
			top: 50%;
			left: 10px;
			content: "";
			background: url("../img/icons/@2x/icon-calendar@2x.png") 0 0 no-repeat;
			width: 15px;
			height: 15px;
			background-size: auto 100%;
			margin-top: -7.5px;
			// opacity: .4;
		}
	}

	&[title="High"] {
		position: relative;
		padding-left: 40px;

		&:before {
			position: absolute;
			top: 50%;
			left: 0;
			content: "!!!";
			font-family: "Montserrat",sans-serif;
			font-size: 11px;
			line-height: 20px;
			font-weight: 600;
			color: #fff;
			width: 30px;
			height: 30px;
			text-align: center;
			background: #ff5722;
			border-radius: 100px;
			margin-top: -15px;
			text-indent: 0;
			transition: all .3s;
		}
	}

	&[title="Medium"] {
		position: relative;
		padding-left: 40px;

		&:before {
			position: absolute;
			top: 50%;
			left: 0;
			content: "!!";
			font-family: "Montserrat",sans-serif;
			font-size: 11px;
			line-height: 20px;
			font-weight: 600;
			color: #fff;
			width: 30px;
			height: 30px;
			text-align: center;
			background: #673ab7;
			border-radius: 100px;
			margin-top: -15px;
			text-indent: 0;
			transition: all .3s;
		}
	}

	&[title="Low"] {
		position: relative;
		padding-left: 40px;

		&:before {
			position: absolute;
			top: 50%;
			left: 0;
			content: "!";
			font-family: "Montserrat",sans-serif;
			font-size: 11px;
			line-height: 20px;
			font-weight: 600;
			color: #fff;
			width: 30px;
			height: 30px;
			text-align: center;
			background: #00bcd4;
			border-radius: 100px;
			margin-top: -15px;
			text-indent: 0;
			transition: all .3s;
		}
	}

	&[title="No priority"] {
		position: relative;
		padding-left: 40px;

		&:before {
			position: absolute;
			top: 50%;
			left: 0;
			content: "";
			font-family: "Montserrat",sans-serif;
			font-size: 11px;
			line-height: 20px;
			font-weight: 600;
			color: #fff;
			width: 30px;
			height: 30px;
			text-align: center;
			background: #e6e6e6;
			border-radius: 100px;
			margin-top: -15px;
			text-indent: 0;
			transition: all .3s;
		}
	}
}

.select2-container--default.priority {
	ul {
		margin-right: 0;
	}
	.select2-results__option--highlighted[aria-selected],
	.select2-results__option[aria-selected=true] {
		background: #f7f7f7;
		color: #000;
	}
}

.select2-container--default.priority .select2-dropdown {
	width: 150px !important;
	border-radius: 15px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 45px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  position: absolute;
  top: 50%;
  left: 50%;
  background: url(../img/icons/@2x/angle-down@2x.png) 0 0 no-repeat;
  width: 9px;
  height: 9px;
  -webkit-background-size: 100% auto;
  -moz-background-size: 100% auto;
  background-size: 100% auto;
  margin-top: -4.5px;
  margin-left: -4.5px;
  border: 0;
  opacity: .5;
  -webkit-transition: all .3s;
     -moz-transition: all .3s;
      -ms-transition: all .3s;
       -o-transition: all .3s;
          transition: all .3s;
}

.select2-container--open .select2-dropdown--below {
	-webkit-box-shadow: 0 6px 14px 2px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 6px 14px 2px rgba(0, 0, 0, 0.1);
	box-shadow: 0 6px 14px 2px rgba(0, 0, 0, 0.1);
	border: 0;
	border-radius: 0 0 15px 15px;
}


.select2-results__options {
	// margin-right: 9px;
}

.select2-results__option {
	padding: 12px 16px;
	font-size: 12px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected],
.select2-container--default .select2-results__option[aria-selected=true] {
	background: #f7fff8;
	color: #4caf50;
}

.select2-container--default .select2-results__option:last-child {
	border-radius: 0 0 15px 15px;
}

.select2-results__group {
	display: none !important;
}
.select2-container--default .select2-results__option>ul>.select2-results__option {
	position: relative;
	padding-left: 40px;

	&:before {
		position: absolute;
		top: 50%;
		left: 10px;
		content: "";
	}

	&[id$='all'] {
		&:before {
			background: url('../img/icons/@2x/event-point-allevents@2x.png') 0 0 no-repeat;
			width: 15px;
			height: 15px;
			background-size: auto 100%;
			margin-top: -7.5px;
		}
	}

	&[id$='deadlines'] {
		&:before {
			background: url('../img/icons/@2x/event-point--red@2x.png') 0 0 no-repeat;
			width: 15px;
			height: 15px;
			background-size: auto 100%;
			margin-top: -7.5px;
		}
	}

	&[id$='meetings'] {
		&:before {
			background: url('../img/icons/@2x/event-point--cyan@2x.png') 0 0 no-repeat;
			width: 15px;
			height: 15px;
			background-size: auto 100%;
			margin-top: -7.5px;
		}
	}
	&[id$='tasks'] {
		&:before {
			background: url('../img/icons/@2x/event-point--yellow@2x.png') 0 0 no-repeat;
			width: 15px;
			height: 15px;
			background-size: auto 100%;
			margin-top: -7.5px;
		}
	}

	&[id$='year'] {
		&:before {
			background: url('../img/icons/@2x/icon-calendar--red@2x.png') 0 0 no-repeat;
			width: 15px;
			height: 15px;
			background-size: auto 100%;
			margin-top: -7.5px;
		}
	}

	&[id$='month'] {
		&:before {
			background: url('../img/icons/@2x/icon-calendar-month--red@2x.png') 0 0 no-repeat;
			width: 15px;
			height: 15px;
			background-size: auto 100%;
			margin-top: -7.5px;
		}
	}

	&[id$='week'] {
		&:before {
			background: url('../img/icons/@2x/icon-calendar-week--red@2x.png') 0 0 no-repeat;
			width: 15px;
			height: 15px;
			background-size: auto 100%;
			margin-top: -7.5px;
		}
	}

	&[id$='high'] {
		&:before {
			position: absolute;
			top: 50%;
			left: 10px;
			content: "!!!";
			font-family: "Montserrat",sans-serif;
			font-size: 11px;
			font-weight: 600;
			color: #fff;
			width: 20px;
			height: 20px;
			text-align: center;
			background: #ff5722;
			border-radius: 100px;
			margin-top: -10px;
		}
	}

	&[id$='medium'] {
		&:before {
			position: absolute;
			top: 50%;
			left: 10px;
			content: "!!";
			font-family: "Montserrat",sans-serif;
			font-size: 11px;
			font-weight: 600;
			color: #fff;
			width: 20px;
			height: 20px;
			text-align: center;
			background: #673ab7;
			border-radius: 100px;
			margin-top: -10px;
		}
	}

	&[id$='low'] {
		&:before {
			position: absolute;
			top: 50%;
			left: 10px;
			content: "!";
			font-family: "Montserrat",sans-serif;
			font-size: 11px;
			font-weight: 600;
			color: #fff;
			width: 20px;
			height: 20px;
			text-align: center;
			background: #00bcd4;
			border-radius: 100px;
			margin-top: -10px;
		}
	}

	&[id$='no-priority'] {
		&:before {
			position: absolute;
			top: 50%;
			left: 10px;
			content: "";
			font-family: "Montserrat",sans-serif;
			font-size: 11px;
			font-weight: 600;
			color: #fff;
			width: 20px;
			height: 20px;
			text-align: center;
			background: #e6e6e6;
			border-radius: 100px;
			margin-top: -10px;
		}
	}
}

.form-group {
	.form-control-feedback {
		display: none;
	}
}

.has-danger {
	label,
	.form-control-feedback {
		display: inline-block;
		color: #d9534f;
	}

	.form-control-feedback {
		margin-top: .25rem;
	}

	textarea,
	.form-control {
		background: url(../img/icons/@2x/icon-alert@2x.png);
    padding-right: 2.25rem;
    background-repeat: no-repeat;
    background-position: center right .5625rem;
    -webkit-background-size: 1.125rem 1.125rem;
    background-size: 1.125rem 1.125rem;
		border-color: #d9534f !important;
	}

	&.adding-row {
		padding-bottom: 25px;

		.form-control-danger {
			background-position: center right 15px;
			padding-right: 30px !important;
		}
	}
}

.ks-checkbox-slider {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
  width: 40px;
  min-width: 40px;
  height: 15px;
  position: relative;
  cursor: pointer;
  margin: 0;

  & ~ .ks-text {
  	position: relative;
  	margin-left: 15px;
  	top: -2px;
  }

  &:hover {
  	.ks-wrapper {
  		background: #cccccc;
  	}

  	input[type=checkbox]:checked ~ .ks-wrapper {
  		background: #46a149;
  	}
  }

  input[type=checkbox] {
  	visibility: hidden;
  }

  input[type=checkbox]:checked ~ .ks-wrapper {
  	background: #4caf50;
  }

  input[type=checkbox]:checked ~ .ks-indicator {
  	left: 18px;
  }

  .ks-wrapper {
	  -webkit-border-radius: 50px;
	  -moz-border-radius: 50px;
	  border-radius: 50px;
  	position: absolute;
    width: 40px;
    height: 15px;
    z-index: 1;
    left: 0;
  	background: #e6e6e6;
  	-webkit-transition: all .3s;
  	   -moz-transition: all .3s;
  	    -ms-transition: all .3s;
  	     -o-transition: all .3s;
  	        transition: all .3s;
  }

  .ks-indicator {
  	display: inline-block;
    width: 25px;
    height: 25px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    background: #fff;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease;
    position: absolute;
    top: -6px;
    left: -3px;
    border: 1px solid #ececec;
    z-index: 1;
    -webkit-box-shadow: 0 3px 10px 0.1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 3px 10px 0.1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 3px 10px 0.1px rgba(0, 0, 0, 0.2);
  }
}

.ks-radio-slider {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
  width: 40px;
  min-width: 40px;
  height: 15px;
  position: relative;
  cursor: pointer;
  margin: 0;

  & ~ .ks-text {
  	position: relative;
  	margin-left: 15px;
  	top: -2px;
  }

  &:hover {
  	.ks-wrapper {
  		background: #cccccc;
  	}

  	input[type=radio]:checked ~ .ks-wrapper {
  		background: #46a149;
  	}
  }

  input[type=radio] {
  	visibility: hidden;
  }

  input[type=radio]:checked ~ .ks-wrapper {
  	background: #4caf50;
  }

  input[type=radio]:checked ~ .ks-indicator {
  	left: 18px;
  }

  .ks-wrapper {
	  -webkit-border-radius: 50px;
	  -moz-border-radius: 50px;
	  border-radius: 50px;
  	position: absolute;
    width: 40px;
    height: 15px;
    z-index: 1;
    left: 0;
  	background: #e6e6e6;
  	-webkit-transition: all .3s;
  	   -moz-transition: all .3s;
  	    -ms-transition: all .3s;
  	     -o-transition: all .3s;
  	        transition: all .3s;
  }

  .ks-indicator {
  	display: inline-block;
    width: 25px;
    height: 25px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    background: #fff;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease;
    position: absolute;
    top: -6px;
    left: -3px;
    border: 1px solid #ececec;
    z-index: 1;
    -webkit-box-shadow: 0 3px 10px 0.1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 3px 10px 0.1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 3px 10px 0.1px rgba(0, 0, 0, 0.2);
  }
}

.badge {
	position: relative;

	&.loading-btn {
		&:before {
			margin-top: -10px !important;
		}
	}

	&.post-btn {
	  text-transform: none;
	  font-weight: 600;
	  font-size: 12px;
	  color: #fff;
	  text-transform: uppercase;
	  -webkit-transition: all .3s;
	  -moz-transition: all .3s;
	  -ms-transition: all .3s;
	  -o-transition: all .3s;
	  transition: all .3s;
	  background: #4caf50;
	  border: 0;
	  padding: 9px 21px;
	  -webkit-border-radius: 15px;
	  -moz-border-radius: 15px;
	  border-radius: 15px;

	  &:focus,
	  &:hover {
	  	opacity: 0.8;
	  	outline: 0;
	  }

	  &.disabled {
	  	cursor: default;
	  	background: #cbcbcb;

	  	&:hover {
	  		opacity: 1;
	  	}
	  }

	  &:disabled {
	  	cursor: default;
	  	opacity: .65;
	  }
	}
}


textarea {
  border: 1px solid #e6e6e6;
  background: #fff;
  padding: 15px;
  resize: none;
  width: 100%;
  color: #000;
  min-height: 105px;
  font-size: 14px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  overflow: hidden;
	font-family: $font-open-sans;

	&::-webkit-input-placeholder {
	  color: #A3A3A3;
	}
	&::-moz-placeholder {
	  color: #A3A3A3;
	}
	&:-ms-input-placeholder {
	  color: #A3A3A3;
	}
	&:-moz-placeholder {
	  color: #A3A3A3;
	}
}

.btn {
	&.btn-primary {
		position: relative;
    width: 100%;
    background: #4caf50;
    padding: 12.5px 10px 12.5px 10px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    -webkit-border-radius: 15px;
    border-radius: 15px;
    -webkit-box-shadow: 0 3px 20px -2px rgba(0,0,0,0.3);
    box-shadow: 0 3px 20px -2px rgba(0,0,0,0.3);
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -webkit-box;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    border: 0;
		&.btn-primary-white{
    		background:#ffffff;
    		color:#000;

    		&:active{
    			background:#ffffff;
    		}
    	}
    	&.btn-primary-nobg{
    		background:none;
    		color:#000;
    		box-shadow:none;
    	}				 
	}
}