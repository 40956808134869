.ks-contacts .content .pane-content.profile-content .contact-content {
	height: -webkit-calc(100% - 60px);
	height: -moz-calc(100% - 60px);
	height: calc(100% - 60px);
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
	overflow: visible;
}

.ks-contacts .content .pane-content.profile-content .ks-page-content {
	min-height: 650px;
	overflow-y: auto;

	@media (max-width: 768px) {
		padding: 0 5px 10px;
		overflow: hidden;
	}
}
.sync_grey_icon{
	text-align:center;
	margin-bottom:25px;
}
.sync_grey_caption{
	font-size: 18px;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.67;
	letter-spacing: normal;
	text-align: center;
	color: #000000;
	margin-bottom:12px;
}
.sync_grey_text{
	margin-bottom:30px;
	font-size: 13px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.54;
	letter-spacing: normal;
	text-align: center;
	color: #000000;
	padding-left:100px;
	padding-right:100px;
}
.goo_drive, .dropbox{
	display:block;
	max-width:240px;
	line-height:75px;
	height:75px;
	text-align:center;
	border-radius: 12px;
	border: solid 1px #e6e6e6;
}
.goo_drive img, .dropbox img{
	line-height:75px;
	vertical-align:middle;
}

.documents {
	.profile-information {
		overflow: hidden;
		min-height: 500px;

		.activity-block {
			.hidden-xl-up {
				padding: 10px 15px;
				border-bottom: 1px solid #f2f2f2;
				text-align: right;

				@media (max-width: 1250px) {
					display: block;
				}
			}
		}

		.user-information {
			padding: 30px 30px 30px 15px;
			min-width: auto;
			width: 255px;
			left: -225px;
			position: absolute;
			top: 0;
			bottom: 0;
			z-index: 2;
			border-right: 1px solid #f1f1f1;
			min-height: auto;

			&:hover {
				.ks-close {
					opacity: 1;
					visibility: visible;
				}
			}

			.wrapper {
				padding: 0;
			}

			&.show {
				&:hover {
					.ks-close {
						opacity: 1;
						visibility: visible;
					}
				}
				.ks-close {
					opacity: 0;
					visibility: hidden;

					@media (max-width: 768px) {
						opacity: 1 !important;
						visibility: visible !important;
					}

					.icon {
						-webkit-transform: rotate(0);
						-moz-transform: rotate(0);
						-ms-transform: rotate(0);
						-o-transform: rotate(0);
						transform: rotate(0);
					}
				}

				& ~ .activity-block {
					padding-left: 255px;

					@media (max-width: 1200px) {
						padding-left: 30px;
					}
				}
			}

			.ks-close {
				display: block;
				bottom: 0;
				width: 30px;
				height: auto;
				background: #f1f1f1;
				opacity: 1;
				visibility: visible;
				-webkit-transition: all .3s;
				-moz-transition: all .3s;
				-ms-transition: all .3s;
				-o-transition: all .3s;
				transition: all .3s;

				.icon {
					-webkit-transform: rotate(180deg);
					-moz-transform: rotate(180deg);
					-ms-transform: rotate(180deg);
					-o-transform: rotate(180deg);
					transform: rotate(180deg);
					-webkit-transition: all .3s;
					-moz-transition: all .3s;
					-ms-transition: all .3s;
					-o-transition: all .3s;
					transition: all .3s;
				}

				&:hover,
				&:focus {
					background: #e0e0e0;
				}
			}

			.title {
				color: #949494;
				margin-left: 15px;
				text-transform: uppercase;
			}

			.ks-tree {
				margin-top: 35px;
				list-style: none;
				margin: 0;
				padding: 0;

				li {
					& > .folder-link {
						position: relative;
						display: block;
						padding: 11px 0 11px 45px;
						font-size: 13px;
						color: #000;
						opacity: .7;
						font-weight: 600;

						&:before {
							position: absolute;
							top: 50%;
							left: 15px;
							content: "";
							background: url('../img/@2x/folder-normal@2x.png') 0 0 no-repeat;
							background-size: auto 100%;
							width: 16px;
							height: 15px;
							margin-top: -7.5px;
							-webkit-transition: all .3s;
							-moz-transition: all .3s;
							-ms-transition: all .3s;
							-o-transition: all .3s;
							transition: all .3s;
						}

						&:hover {
							opacity: 1;
						}
					}

					&.ks-open {
						& > .folder-link {
							&:before {
								background: url('../img/@2x/file-expanded@2x.png') 0 0 no-repeat;
								background-size: auto 100%;
								width: 16px;
								height: 15px;
							}
						}

						&.ks-has-submenu {
							& > .add-folder-link {
								display: none;
							}
						}
					}

					&.ks-active {
						.folder-link {
							background: #f7f6e9;
							opacity: 1;

							&:before {
								background: url('../img/@2x/folder-normal@2x.png') 0 0 no-repeat;
								background-size: auto 100%;
								width: 16px;
								height: 15px;
							}
						}
					}

					.add-folder-link {
						position: relative;
						display: block;
						padding: 11px 0 11px 45px;
						font-size: 13px;
						color: #000;
						opacity: .7;
						font-weight: 600;
						text-transform: uppercase;

						&:before {
							position: absolute;
							top: 50%;
							left: 15px;
							content: "";
							background: url('../img/icons/@2x/icon-addfolder@2x.png') 0 0 no-repeat;
							background-size: auto 100%;
							width: 16px;
							height: 15px;
							margin-top: -7.5px;
							-webkit-transition: all .3s;
							-moz-transition: all .3s;
							-ms-transition: all .3s;
							-o-transition: all .3s;
							transition: all .3s;
						}

						&:hover {
							opacity: 1;
						}
					}

					li {
						padding-left: 10px;

						li {
							padding-left: 10px;

							li {
								padding-left: 10px;

								li {
									padding-left: 10px;
								}
							}
						}
					}

					&.ks-has-submenu.ks-open.ks-has-submenu  {
						& > .ks-submenu {
							display: block;
						}
					}
				}

				.ks-submenu {
					display: none;
				}
			}

			.ks-menu {
				margin-top: 35px;
				list-style: none;
				margin: 0;
				padding: 0;
				margin-top: 80px;

				&:nth-child(4) {
					margin-top: 0;
				}

				li {
					& > .folder-link {
						position: relative;
						display: block;
						padding: 11px 0 11px 45px;
						font-size: 13px;
						color: #000;
						opacity: .7;
						font-weight: 600;

						.icon {
							left: 15px;
							margin-left: 0;
							-webkit-transition: all .3s;
							-moz-transition: all .3s;
							-ms-transition: all .3s;
							-o-transition: all .3s;
							transition: all .3s;
						}


						&:hover {
							opacity: 1;
							&.folder-trash {
								color: #ff5722;

								.icon {
									&.icon-delete {
										background: url(../img/icons/@2x/icon-delete--red@2x.png) 0 0 no-repeat;
										-webkit-background-size: auto 100%;
										background-size: auto 100%;
									}
								}
							}

							&.folder-sync {
								.icon {
									-webkit-animation: fa-spin-2 2s infinite linear;
									-moz-animation: fa-spin-2 2s infinite linear;
									animation: fa-spin-2 2s infinite linear;
								}
							}

							@-webkit-keyframes fa-spin-2 {
								0% {
									-webkit-transform: rotate(0deg);
									-moz-transform: rotate(0deg);
									transform: rotate(0deg);
								}
								100% {
									-webkit-transform: rotate(-360deg);
									-moz-transform: rotate(-360deg);
									transform: rotate(-360deg);
								}
							}

							@keyframes fa-spin-2 {
								0% {
									-webkit-transform: rotate(0deg);
									-moz-transform: rotate(0deg);
									transform: rotate(0deg);
								}
								100% {
									-webkit-transform: rotate(-360deg);
									-moz-transform: rotate(-360deg);
									transform: rotate(-360deg);
								}
							}

						}
					}

					.add-folder-link {
						position: relative;
						display: block;
						padding: 11px 0 11px 45px;
						font-size: 13px;
						color: #000;
						opacity: .7;
						font-weight: 600;
						text-transform: uppercase;

						&:before {
							position: absolute;
							top: 50%;
							left: 15px;
							content: "";
							background: url('../img/icons/@2x/icon-addfolder@2x.png') 0 0 no-repeat;
							background-size: auto 100%;
							width: 16px;
							height: 15px;
							margin-top: -7.5px;
							-webkit-transition: all .3s;
							-moz-transition: all .3s;
							-ms-transition: all .3s;
							-o-transition: all .3s;
							transition: all .3s;
						}

						&:hover {
							opacity: 1;
						}
					}
				}
			}
		}

		.activity-block {
			padding-left: 30px;
			position: relative;
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			-js-display: flex;
			display: flex;
			-webkit-box-orient: horizontal;
			-webkit-box-direction: normal;
			-webkit-flex-direction: row;
			-ms-flex-direction: row;
			flex-direction: row;
			background: #fff;
			-webkit-transition: all .3s;
			-moz-transition: all .3s;
			-ms-transition: all .3s;
			-o-transition: all .3s;
			transition: all .3s;
			

			.wrapper {
				width: 100%;
				padding: 0;

				@media (max-width: 992px) {
					min-width: 800px;
				}

				.ks-cards-toggle {
					@media (max-width: 1250px) {
						display: inline-block;
					}
				}

				.ks-breadcrumbs {
					display: -ms-flexbox;
					display: -webkit-flex;
					display: flex;
					-webkit-flex-direction: row;
					-ms-flex-direction: row;
					flex-direction: row;
					-webkit-flex-wrap: nowrap;
					-ms-flex-wrap: nowrap;
					flex-wrap: nowrap;
					-webkit-justify-content: center;
					-ms-flex-pack: center;
					justify-content: center;
					-webkit-align-content: stretch;
					-ms-flex-line-pack: stretch;
					align-content: stretch;
					-webkit-align-items: center;
					-ms-flex-align: center;
					align-items: center;
					border-bottom: 1px solid #f2f2f2;
					height: 60px;
					padding-right: 15px;

					.back-link {
						position: relative;
						-webkit-flex: 0 1 60px;
						-ms-flex: 0 1 60px;
						flex: 0 1 60px;
						height: 60px;
						border-right: 1px solid #f2f2f2;
					}

					.breadcrumb {
						-webkit-flex: 1 1 auto;
						-ms-flex: 1 1 auto;
						flex: 1 1 auto;
						padding: 0;
						margin: 0;
						background: none;
						border-radius: 0;

						.breadcrumb-item {
							position: relative;
							padding-left: 40px;
							padding-right: 15px;
							color: #000;
							margin-left: 3px;
							cursor: pointer;

							&:hover {
								a {
									opacity: 1;
								}
							}

							&:first-child {
								&:before {
									display: none;
								}
							}

							&:before {
								position: absolute;
								left: -3px;
								top: 50%;
								content: "";
								padding: 0;
								background: url(../img/icons/@2x/angle-right@2x.png) 0 0 no-repeat;
								width: 4px;
								height: 6px;
								background-size: auto 100%;
								margin-top: -3px;
								margin-left: -2px;
								opacity: .4;
							}

							a {
								opacity: .5;
							}

							.icon {
								left: 15px;
								margin-left: 0;
							}

							.ks-text {
								color: #000;
								font-weight: 600;
							}

							&.ks-active {
								.icon {
									background: url(../img/icons/@2x/icon-folder--green@2x.png) 0 0 no-repeat;
									width: 16px;
									height: 15px;
									-webkit-background-size: auto 100%;
									background-size: auto 100%;
								}
								.ks-text {
									color: #4caf50;
								}
							}
						}
					}

					.ks-btn-file {
						position: relative;
						font-size: 12px;
						line-height: 22px;
						font-weight: 500;
						border-radius: 15px;
						padding: 10px 25px;
						background: #4caf50;
						color: #fff;
						height: 45px;
						-webkit-box-align: center;
						-webkit-align-items: center;
						-ms-flex-align: center;
						align-items: center;
						-webkit-box-pack: center;
						-webkit-justify-content: center;
						-ms-flex-pack: center;
						justify-content: center;
						-webkit-transition: none;
						-o-transition: none;
						transition: none;
						-webkit-flex: 0 1 135px;
						-ms-flex: 0 1 135px;
						flex: 0 1 135px;
						overflow: hidden;

						.icon {
							left: 12px;
							margin-left: 0;
							width: 20px;
							height: 17px;
							margin-top: -7.5px;
						}

						.ks-text {
							padding-left: 21px;
							padding-right: 0 !important;
							color: #fff;
							font-size: 13px;
							font-weight: 600;
						}

						input[type='file'] {
							position: absolute;
							top: 0;
							right: 0;
							min-width: 100%;
							min-height: 100%;
							font-size: 26px;
							text-align: right;
							opacity: 0;
							cursor: inherit;
							display: block;
						}
					}
				}
			}
			.custom-scroll-table {
				.table {
					min-width: auto !important;

					.checkbox-cell {
						width: 60px !important;
						max-width: 60px !important;
						min-width: 60px !important;
					}

					.action-block {
						&.tippy-active {
							.dropdown-toggle {
								.icon {
									background: url(../img/icons/@2x/icon-menu--white@2x.png) 0 0 no-repeat;
									background-size: auto 100%;
								}
							}
						}
						.dropdown-toggle {
							height: 44px;

							.ks-info {
								display: block;
								min-height: 20px;
								opacity: .7;
							}
							.icon {
								transition: all .3s;
							}
							&:after {
								display: none;
							}
						}
					}

					th,
					td {
						&:last-child {
							min-width: 125px;
						}
					}

					.name-cell {
						position: relative;
						max-width: auto;
						width: auto;
						min-width: auto;

						.ks-open-details {
							display: block;
							padding-left: 30px;

							&.active {
								.case-name {
									color: #4caf50;
								}
							}
						}

						.icon {
							left: 0;
							margin-left: 0;
						}
					}

					.case-name-cell {
						min-width: 160px;
					}

					.owner-cell {
						min-width: 180px;
						padding-left: 0;
						padding-right: 0;
					}

					.size-cell {
						min-width: 70px;
					}
				}
			}
		}

		.activity-folder-block {
			position: relative;
			width: 330px;
			right: 0;
			min-width: 300px;
			background: #fff;
			-webkit-transition: all .3s;
			-o-transition: all .3s;
			transition: all .3s;
			-webkit-border-radius: 0 0 15px;
			border-radius: 0 0 15px;
	    // padding: 30px;
	    border-left: 1px solid #e6e6e6;

	    &.show {
	    	@media (max-width: 1250px) {
	    		right: 0;
	    	}
	    }

	    .simplebar-scroll-content {
	    	padding-right: 50px !important;

	    	.simplebar-content {
	    		margin-bottom: -15px;
	    	}
	    }

	    @media (max-width: 1250px) {
	    	position: absolute;
	    	top: 0;
	    	bottom: 0;
	    	right: -330px;
	    }

	    @media (max-width: 768px) {
	    	z-index: 2;
	    	width: 283px;
	    	min-width: 283px;
	    	// padding: 20px;
	    }

	    .simplebar-content {
	    	position: relative;
	    	padding: 30px;

	    	@media (max-width: 768px) {
	    		padding: 20px;
	    	}
	    }

	    .ks-close {
	    	display: none;
	    	position: absolute;
	    	top: 0;
	    	left: 0;
	    	width: 30px;
	    	height: 30px;
	    	z-index: 2;

	    	@media (max-width: 1250px) {
	    		display: inline-block;
	    	}
	    }

	    .title {
	    	color: #949494;
	    	margin-left: 15px;
	    	font-weight: 600;
	    	margin: 0;
	    	font-size: 14px;
	    	text-transform: uppercase;
	    }

	    .folder-block {
	    	padding: 30px 0;
	    	border-bottom: 1px solid #e6e6e6;

	    	.top {
	    		position: relative;
	    		padding-left: 25px;
	    		margin-bottom: 20px;

	    		.icon {
	    			left: 0;
	    			margin-left: 0;
	    		}

	    		.ks-text {
	    			display: block;
	    			font-size: 13px;
	    			color: #c5c5c5;
	    			margin-bottom: 5px;
	    			line-height: 15px;
	    		}
	    	}

	    	.info {
	    		font-family: $font-open-sans;
	    		font-style: italic;
	    		font-size: 14px;
	    		margin: 5px 0 20px;
	    		color: #999;

	    		.name {
	    			font-family: $font-mont;
	    			font-style: normal;
	    			color: #000;
	    		}
	    	}

	    	.bottom {
	    		display: -ms-flexbox;
	    		display: -webkit-flex;
	    		display: flex;
	    		-webkit-flex-direction: row;
	    		-ms-flex-direction: row;
	    		flex-direction: row;
	    		-webkit-flex-wrap: nowrap;
	    		-ms-flex-wrap: nowrap;
	    		flex-wrap: nowrap;
	    		-webkit-justify-content: flex-start;
	    		-ms-flex-pack: start;
	    		justify-content: flex-start;
	    		-webkit-align-content: stretch;
	    		-ms-flex-line-pack: stretch;
	    		align-content: stretch;
	    		-webkit-align-items: flex-start;
	    		-ms-flex-align: start;
	    		align-items: flex-start;

	    		.files-block {
	    			position: relative;
	    			-webkit-flex: 1 1 auto;
	    			-ms-flex: 1 1 auto;
	    			flex: 1 1 auto;
	    			padding-left: 25px;

	    			.icon {
	    				left: 2px;
	    				margin-left: 0;
	    			}

	    			.ks-text {
	    				line-height: 30px;
	    				font-weight: 600;
	    				color: #000;
	    			}
	    		}

	    		.buttons-group {
	    			-webkit-flex: 0 1 90px;
	    			-ms-flex: 0 1 90px;
	    			flex: 0 1 90px;

	    			.link {
	    				position: relative;
	    				display: inline-block;
	    				width: 45px;
	    				height: 30px;
	    				float: left;

	    				&:before {
	    					position: absolute;
	    					top: 50%;
	    					bottom: 0;
	    					left: 0;
	    					height: 15px;
	    					margin-top: -7.5px;
	    					content: "";
	    					border-left: 2px solid #f2f2f2;
	    				}

	    				&:first-child {
	    					&:before {
	    						display: none;
	    					}
	    				}
	    			}
	    		}
	    	}
	    }
	}

	.details-block {
		position: absolute;
		top: 0;
		bottom: 0;
		right: -330px;
		width: 300px;
		min-width: 330px;
		background: #fff;
		-webkit-transition: all .3s;
		-o-transition: all .3s;
		transition: all .3s;
		-webkit-border-radius: 0 0 15px;
		border-radius: 0 0 15px;
		border-left: 1px solid #e6e6e6;

		.simplebar-scroll-content {
			padding-right: 50px !important;

			.simplebar-content {
	    		// margin-bottom: -15px;
	    	}
	    }

	    @media (max-width: 1250px) {
	    	position: absolute;
	    	top: 0;
	    	bottom: 0;
	    	right: -330px;
	    }

	    @media (max-width: 768px) {
	    	z-index: 2;
	    	width: 283px;
	    	min-width: 283px;
	    }

	    &.show {
	    	display: -webkit-box;
	    	display: -ms-flexbox;
	    	display: flex;
	    	right: 0;

	    	& ~ .activity-folder-block {
	    		right: -330px;
	    	}
	    }

	    .ks-close {
	    	display: none;
	    	position: absolute;
	    	top: 0;
	    	left: 0;
	    	width: 30px;
	    	height: 30px;
	    	z-index: 2;

	    	@media (max-width: 992px) {
	    		display: inline-block;
	    	}
	    }

	    .simplebar-content {
	    	position: relative;
	    }

	    .card {
	    	border: 0;
	    	-webkit-border-radius: 0;
	    	-moz-border-radius: 0;
	    	border-radius: 0;

	    	&.details {
	    		.card-header {
	    			&:before {
	    				border-bottom: 2px solid #4caf50;
	    			}
	    		}

	    		.top {
	    			position: relative;
	    			padding-left: 45px;
	    			margin-bottom: 30px;

	    			.ks-text {
	    				font-size: 17px;
	    				color: #000;
	    				font-weight: 600;
	    				line-height: 32px;
	    			}

	    			.icon {
	    				left: 0;
	    				margin-left: 0;
	    			}
	    		}

	    		.middle {
	    			margin-bottom: 20px;

	    			.block {
	    				display: inline-block;
	    				width: 50%;
	    				float: left;

	    				.val,
	    				.title {
	    					display: block;
	    					color: #aaaaaa;
	    					font-family: $font-open-sans;
	    					font-style: italic;
	    					margin-bottom: 10px;
	    				}

	    				.val {
	    					color: #000;
	    					font-style: normal;
	    					margin-bottom: 0;
	    				}
	    			}
	    		}

	    		.bottom {
	    			display: -ms-flexbox;
	    			display: -webkit-flex;
	    			display: flex;
	    			-webkit-flex-direction: row;
	    			-ms-flex-direction: row;
	    			flex-direction: row;
	    			-webkit-flex-wrap: nowrap;
	    			-ms-flex-wrap: nowrap;
	    			flex-wrap: nowrap;
	    			-webkit-justify-content: flex-start;
	    			-ms-flex-pack: start;
	    			justify-content: flex-start;
	    			-webkit-align-content: stretch;
	    			-ms-flex-line-pack: stretch;
	    			align-content: stretch;
	    			-webkit-align-items: flex-start;
	    			-ms-flex-align: start;
	    			align-items: flex-start;

	    			.btn-upload {
	    				-webkit-flex: 1 1 auto;
	    				-ms-flex: 1 1 auto;
	    				flex: 1 1 auto;
	    				margin-right: 15px;
	    				position: relative;
	    				background: #4caf50;
	    				padding: 12px 15px 12px 30px;
	    				text-align: center;
	    				color: #fff;
	    				text-transform: uppercase;
	    				border-radius: 15px;
	    				box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.3);
	    				transition: all .3s;
	    				display: -ms-flexbox;
	    				display: -webkit-flex;
	    				display: -webkit-box;
	    				display: flex;
	    				-webkit-flex-direction: row;
	    				-ms-flex-direction: row;
	    				-webkit-box-orient: horizontal;
	    				-webkit-box-direction: normal;
	    				flex-direction: row;
	    				-webkit-flex-wrap: nowrap;
	    				-ms-flex-wrap: nowrap;
	    				flex-wrap: nowrap;
	    				-webkit-justify-content: center;
	    				-ms-flex-pack: center;
	    				-webkit-box-pack: center;
	    				justify-content: center;
	    				-webkit-align-content: stretch;
	    				-ms-flex-line-pack: stretch;
	    				align-content: stretch;
	    				-webkit-align-items: center;
	    				-ms-flex-align: center;
	    				-webkit-box-align: center;
	    				align-items: center;
	    				font-size: 13px;
	    				font-weight: 600;

	    				&:hover {
	    					opacity: .8;
	    				}

	    				.icon {
	    					left: 15px;
	    					margin-left: 0;
	    					margin-top: -7px;

	    					@media (max-width: 768px) {
	    						left: 10px;
	    					}
	    				}

	    				.ks-text {
	    					opacity: 1;
	    					visibility: visible;
	    					min-width: 110px;
	    				}
	    			}

	    			.btn-share {
	    				-webkit-flex: 0 1 60px;
	    				-ms-flex: 0 1 60px;
	    				flex: 0 1 60px;
	    				position: relative;
	    				background: #4caf50;
	    				padding: 12px 15px 12px 30px;
	    				text-align: center;
	    				color: #fff;
	    				text-transform: uppercase;
	    				border-radius: 15px;
	    				box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.3);
	    				transition: all .3s;
	    				display: -ms-flexbox;
	    				display: -webkit-flex;
	    				display: -webkit-box;
	    				display: flex;
	    				-webkit-flex-direction: row;
	    				-ms-flex-direction: row;
	    				-webkit-box-orient: horizontal;
	    				-webkit-box-direction: normal;
	    				flex-direction: row;
	    				-webkit-flex-wrap: nowrap;
	    				-ms-flex-wrap: nowrap;
	    				flex-wrap: nowrap;
	    				-webkit-justify-content: center;
	    				-ms-flex-pack: center;
	    				-webkit-box-pack: center;
	    				justify-content: center;
	    				-webkit-align-content: stretch;
	    				-ms-flex-line-pack: stretch;
	    				align-content: stretch;
	    				-webkit-align-items: center;
	    				-ms-flex-align: center;
	    				-webkit-box-align: center;
	    				align-items: center;
	    				font-size: 13px;
	    				font-weight: 700;
	    				height: 45px;

	    				&:hover {
	    					opacity: .8;
	    				}
	    			}
	    		}
	    	}

	    	&.share {
	    		.card-header {
	    			&:before {
	    				border-bottom: 2px solid #673ab7;
	    			}
	    			.btn {
	    				background: #673ab7;
	    			}
	    		}
	    	}

	    	.card-header {
	    		position: relative;
	    		background: none;
	    		-webkit-border-radius: 0;
	    		-moz-border-radius: 0;
	    		border-radius: 0;
	    		padding: 20px 20px 20px 60px;
	    		height: 60px;
	    		border: 0;

	    		&:before {
	    			position: absolute;
	    			left: 20px;
	    			right: 20px;
	    			bottom: 0;
	    			content: "";
	    		}

	    		.ks-text {
	    			display: inline-block;
	    			margin: 0;
	    			padding: 0;
	    			color: #000;
	    			font-size: 16px;
	    			font-weight: 600;
	    			line-height: 1.1em;
	    		}

	    		& > .icon {
	    			left: 30px;
	    			margin-left: 0;
	    		}

	    		.btn {
	    			position: relative;
	    			top: -6px;
	    			float: right;
	    			width: 30px;
	    			height: 30px;
	    			padding: 0;
	    			-webkit-border-radius: 100px;
	    			-moz-border-radius: 100px;
	    			border-radius: 100px;
	    			-webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
	    			-moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
	    			box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
	    			opacity: 1;
	    			-webkit-transition: all .3s;
	    			-moz-transition: all .3s;
	    			-ms-transition: all .3s;
	    			-o-transition: all .3s;
	    			transition: all .3s;

	    			&:hover {
	    				opacity: 0.8;
	    			}

	    			&:before {
	    				position: absolute;
	    				top: 50%;
	    				left: 50%;
	    				background: url(../img/icons/@2x/icon-plus--white@2x.png) 0 0 no-repeat;
	    				width: 13px;
	    				height: 13px;
	    				-webkit-background-size: 100% auto;
	    				-moz-background-size: 100% auto;
	    				background-size: 100% auto;
	    				margin-top: -6.5px;
	    				margin-left: -7.5px;
	    				content: "";
	    				-webkit-transition: all .3s;
	    				-moz-transition: all .3s;
	    				-ms-transition: all .3s;
	    				-o-transition: all .3s;
	    				transition: all .3s;
	    			}

	    			&.open-block {
	    				&:before {
	    					-webkit-transform: rotate(45deg);
	    					-moz-transform: rotate(45deg);
	    					-ms-transform: rotate(45deg);
	    					-o-transform: rotate(45deg);
	    					transform: rotate(45deg);
	    				}
	    			}

		    		// .icon {
		    		// 	margin-top: -6.5px;
		    		// 	margin-left: -6.5px;
		    		// }
		    	}
		    }

		    .card-block {
		    	border-bottom: 2px solid #f2f2f2;

		    	blockquote {
		    		margin: 1.42857em 30px 30px;

		    		@media (max-width: 768px) {
		    			margin: 20px;
		    		}
		    	}
		    }

		    .card-actions {
		    	.file-action {
		    		display: -ms-flexbox;
		    		display: -webkit-flex;
		    		display: flex;
		    		-webkit-flex-direction: row;
		    		-ms-flex-direction: row;
		    		flex-direction: row;
		    		-webkit-flex-wrap: wrap;
		    		-ms-flex-wrap: wrap;
		    		flex-wrap: wrap;
		    		-webkit-justify-content: center;
		    		-ms-flex-pack: center;
		    		justify-content: center;
		    		-webkit-align-content: stretch;
		    		-ms-flex-line-pack: stretch;
		    		align-content: stretch;
		    		-webkit-align-items: flex-start;
		    		-ms-flex-align: start;
		    		align-items: flex-start;
		    		padding: 0 30px;

		    		@media (max-width: 768px) {
		    			padding: 0 15px;
		    		}

		    		.action-link {
		    			width: 25%;
		    			position: relative;
		    			margin: 10px 0;
		    			padding: 10px 0;
		    			text-align: center;
		    			border-radius: 15px;

		    			&:hover {
		    				background: #f2fff3;
		    				.icon {
		    					&.icon-folder {
		    						background: url(../img/icons/@2x/icon-folder--green@2x.png) 0 0 no-repeat;
		    						width: 16px;
		    						height: 15px;
		    						background-size: auto 100%;
		    					}

		    					&.icon-edit {
		    						background: url(../img/icons/@2x/icon-edit--green@2x.png) 0 0 no-repeat;
		    						background-size: auto 100%;
		    					}

		    					&.icon-copy {
		    						background: url(../img/icons/@2x/icon-copy--green@2x.png) 0 0 no-repeat;
		    						background-size: auto 100%;
		    					}

		    					&.icon-move {
		    						background: url(../img/icons/@2x/icon-move--green@2x.png) 0 0 no-repeat;
		    						background-size: auto 100%;
		    					}

		    					&.icon-lock {
		    						background: url(../img/icons/@2x/icon-lock--green@2x.png) 0 0 no-repeat;
		    						background-size: auto 100%;
		    					}

		    					&.icon-task {
		    						background: url("../img/icons/@2x/icon-tick-border--green@2x.png") 0 0 no-repeat;
		    						background-size: auto 100%;
		    					}

		    					&.icon-history {
		    						background: url(../img/icons/@2x/icon-history--green@2x.png) 0 0 no-repeat;
		    						background-size: auto 100%;
		    					}

		    					&.icon-delete {
		    						background: url(../img/icons/@2x/icon-trash--green@2x.png) 0 0 no-repeat;
		    						background-size: auto 100%;
		    					}
		    				}

		    				.ks-text {
		    					color: #4caf50;
		    				}
		    			}

		    			.icon {
		    				position: relative;
		    				top: 0;
		    				left: 0;
		    				margin-top: 0;
		    				margin-left: 0;
		    				display: block;
		    				margin: 0 auto;
		    				-webkit-transition: all .3s;
		    				-moz-transition: all .3s;
		    				-ms-transition: all .3s;
		    				-o-transition: all .3s;
		    				transition: all .3s;
		    			}

		    			.ks-text {
		    				display: block;
		    				color: #000;
		    				text-transform: uppercase;
		    				margin-top: 7px;
		    				font-size: 11px;
		    				font-weight: 600;
		    				-webkit-transition: all .3s;
		    				-moz-transition: all .3s;
		    				-ms-transition: all .3s;
		    				-o-transition: all .3s;
		    				transition: all .3s;
		    			}
		    		}
		    	}
		    }
		    .person-block {
		    	border-bottom: 2px solid #f3f3f3;
		    	padding: 15px 0 15px 30px;

		    	@media (max-width: 768px) {
		    		padding-left: 20px;
		    	}

		    	.top {
		    		display: table;
		    		height: 45px;
		    		width: 100%;
		    		margin: 0;
		    		padding: 0;

		    		.open-popover,
		    		.avatar,
		    		.name {
		    			display: table-cell;
		    			vertical-align: middle;
		    		}

		    		.avatar {
		    			width: 45px;

		    			img {
		    				width: 100%;
		    				border-radius: 100px;
		    			}
		    		}

		    		.name {
		    			font-size: 13px;
		    			color: #a3a3a3;
		    			padding-left: 15px;

		    			b {
		    				display: block;
		    				font-weight: 600;
		    				color: #000;
		    			}
		    		}

		    		.open-popover {
		    			width: 45px;
		    			position: relative;
		    			border-left: 2px solid #f3f3f3;

		    			.more-options {
		    				display: block;
		    				height: 45px;
		    				cursor: pointer;
		    			}

		    			.icon {
		    				left: 50%;
		    				margin-left: -2px;
		    			}
		    		}
		    	}
		    }
		}
	}
}
}

.documents-empty {
	.profile-information {
		.user-information {
			.empty-block {
				position: relative;
				padding-left: 57px;
				margin-left: 15px;

				.icon {
					width: 50px;
					height: 45px;
					margin-top: -22.5px;
					margin-left: -25px;
					opacity: .1;
					left: 0;
					margin-left: 0;
				}

				.ks-text {
					display: block;
					font-size: 13px;
					color: #000;
					opacity: .4;
				}
			}

			.ks-menu {
				margin-top: 30px;

				&:nth-child(4) {
					margin-top: 100px;
				}
			}
		}

		.activity-folder-block {
			padding: 30px; 

			@media (max-width: 768px) {
				padding: 20px;
			}
			.empty-block {
				margin-top: 30px;
				position: relative;
				padding-left: 60px;

				.icon {
					width: 50px;
					height: 45px;
					margin-top: -22.5px;
					margin-left: -25px;
					opacity: .1;
					left: 0;
					margin-left: 0;
				}

				.ks-text {
					display: block;
					font-size: 13px;
					color: #000;
					opacity: .7;
					padding-left: 10px;
				}
			}
		}

		.activity-block {
			.empty-block {
				display: -ms-flexbox;
				display: -webkit-flex;
				display: flex;
				-webkit-flex-direction: row;
				-ms-flex-direction: row;
				flex-direction: row;
				-webkit-flex-wrap: nowrap;
				-ms-flex-wrap: nowrap;
				flex-wrap: nowrap;
				-webkit-justify-content: center;
				-ms-flex-pack: center;
				justify-content: center;
				-webkit-align-content: stretch;
				-ms-flex-line-pack: stretch;
				align-content: stretch;
				-webkit-align-items: center;
				-ms-flex-align: center;
				align-items: center;
				position: relative;
				min-height: 390px;
				margin: 20px;
				border-radius: 15px;
				border: 2px dashed #e6e6e6;
				text-align: center;

				.icon-folder {
					position: relative;
					left: 0;
					margin-left: 0;
					top: 0;
					margin-top: 0;
					background: url(../img/@2x/empty-folder@2x.png) 0 0 no-repeat;
					width: 90px;
					height: 75px;
					background-size: auto 100%;
					margin: 0 auto 30px;
					opacity: .3;
				}

				.ks-title {
					display: block;
					color: #333;
					font-size: 16px;
					font-weight: 600;
				}

				.ks-btn-separator {
					margin: 5px;
					display: block;
				}

				.ks-btn-file {
					position: relative;
					font-size: 12px;
					font-weight: 500;
					border-radius: 15px;
					padding: 10px 25px;
					background: #4caf50;
					color: #fff;
					height: 45px;
					-webkit-box-align: center;
					-webkit-align-items: center;
					-ms-flex-align: center;
					align-items: center;
					-webkit-box-pack: center;
					-webkit-justify-content: center;
					-ms-flex-pack: center;
					justify-content: center;
					-webkit-transition: none;
					-o-transition: none;
					transition: none;
					-webkit-flex: 0 1 135px;
					-ms-flex: 0 1 135px;
					flex: 0 1 135px;
					border: 0;

					.icon {
						left: 12px;
						margin-left: 0;
						width: 20px;
						height: 17px;
						margin-top: -7.5px;
					}

					.ks-text {
						padding-left: 21px;
						padding-right: 0 !important;
						color: #fff;
						font-size: 13px;
						font-weight: 600;
					}

					input[type='file'] {
						position: absolute;
						top: 0;
						right: 0;
						min-width: 100%;
						min-height: 100%;
						font-size: 26px;
						text-align: right;
						opacity: 0;
						cursor: inherit;
						display: block;
					}
				}
			}
		}
	}
}
.add-sync-detail {
	.cancel_left{
		display: flex;
		justify-content: left;
		margin-right: auto;
	}
	.cancel_right{
		display: flex;
		justify-content: right;
	}
	.custom-checkbox>.custom-control-input:checked ~ .custom-control-indicator, .custom-checkbox:hover>.custom-control-input:not(:disabled) ~ .custom-control-indicator{
		border-color:#2196f3;
	}
	.custom-checkbox>.custom-control-input ~ .custom-control-indicator:before{
		    background: url(../img/icons/@2x/icon-tick--blue@2x.png) 0 0 no-repeat;
		    -webkit-background-size: 100% auto;
    		background-size: 100% auto;

	}
	.table {
			thead {
				th{
					font-size: 11px;
					color:#666666;
					font-weight:normal;
					vertical-align:middle;
					border-bottom: solid 1px #e6e6e6;
				}
			}
			tbody{
				tr{
					td{
						.name-cell {
							position: relative;
						    max-width: auto;
						    width: auto;
						    min-width: auto;
							
							.ks-open-details {
								    display: block;
								    padding-left: 30px;
								    .icon {
									    left: 0;
									    margin-left: 0;
									}
							}
						}
						span{
							color:#aaaaaa;	
							font-size: 13px;
							&.black {
							    color: #000;	
							}
							&.bold {
								font-weight: 600;
							}
						}
						
					}
				}
			}
			
		
	}
	.ks-breadcrumbs {
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-align-content: stretch;
		-ms-flex-line-pack: stretch;
		align-content: stretch;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-bottom: 1px solid #f2f2f2;
		height: 60px;
		padding-right: 15px;

		.back-link {
			position: relative;
			-webkit-flex: 0 1 60px;
			-ms-flex: 0 1 60px;
			flex: 0 1 60px;
			height: 60px;
			border-right: 1px solid #f2f2f2;
		}
		

		.breadcrumb {
			-webkit-flex: 1 1 auto;
			-ms-flex: 1 1 auto;
			flex: 1 1 auto;
			padding: 0;
			margin: 0;
			background: none;
			border-radius: 0;

			.breadcrumb-item {
				position: relative;
				padding-left: 40px;
				padding-right: 15px;
				color: #000;
				margin-left: 3px;
				cursor: pointer;

				&:hover {
					a {
						opacity: 1;
						.icon {
							background: url(../img/icons/@2x/icon-folder--blue@2x.png) 0 0 no-repeat;
							width: 16px;
							height: 15px;
							-webkit-background-size: auto 100%;
							background-size: auto 100%;
						}
						.ks-text {
							color: #2196f3;
						}
					}
				}

				&:first-child {
					&:before {
						display: none;
					}
				}

				&:before {
					position: absolute;
					left: -3px;
					top: 50%;
					content: "";
					padding: 0;
					background: url(../img/icons/@2x/angle-right@2x.png) 0 0 no-repeat;
					width: 4px;
					height: 6px;
					background-size: auto 100%;
					margin-top: -3px;
					margin-left: -2px;
					opacity: .4;
				}

				a {
					opacity: .5;
				}

				.icon {
					left: 15px;
					margin-left: 0;
				}

				.ks-text {
					color: #000;
					font-weight: 600;

					&:hover{
						color:#2196f3;
					}
				}
				

				&.ks-active {
					.icon {
						background: url(../img/icons/@2x/icon-folder@2x.png) 0 0 no-repeat;
						width: 16px;
						height: 15px;
						-webkit-background-size: auto 100%;
						background-size: auto 100%;
					}
					.ks-text {
						color: #000000;
					}
				}
			}
		}
	}
}