.ks-contacts {
	.content {
		.pane-content{
			&.cases {
				.contact-content {
					.table {
						thead {
							th.th {
								height: 56px;

								.th-title {
									padding: 6px 0;
								}
							}

							tr.search-row {
								th.th {
									height: 47px;
									border-bottom: 1px solid #ccc;
								}
							}
						}
						tr {
							display: flex;
							justify-content: center;
							align-items: center;

							td.checkbox-cell,
							th.checkbox-cell {
								flex: 1 60px;
								min-width: 60px;
							}

							td.priority-cell2,
							td.priority-cell,
							th.priority-cell2,
							th.priority-cell {
								flex: 1 105px;
								min-width: 80px;
							}

							td.task-name-cell,
							th.task-name-cell,
							th.assigned-cell,
							td.assigned-cell {
								flex: 1 21%;
								min-width: 300px !important;
								width: auto;
								max-width: none;
							}

							td.date-cell,
							th.date-cell {
								flex: 1 14%;
								min-width: 210px !important;
								max-width: none;
								width: auto;
							}

							th.status-cell,
							td.status-cell {
								flex: 1 144px;
								min-width: 150px;
							}

							td.area-cell,
							th.area-cell {
								flex: 1 10%;
								min-width: 100px;
							}

							td.options-cell,
							th.options-cell {
								flex: 1 14%;
							}
						}

					}
				}
			}
		}
	}
}

.ks-contacts .content .pane-content.cases .contact-content .custom-scroll-table .simplebar-content {
	padding-bottom: 50px !important;
}

.ks-contacts .content .pane-content.cases .contact-content .table tbody tr:hover td,
.ks-contacts .content .pane-content.cases .contact-content .table tbody tr.row-checked td {
	// background: #fffaf2;
	background: none;
}

.ks-contacts .content .pane-content.cases .contact-content .table tbody .card-body tr:hover td,
.ks-contacts .content .pane-content.cases .contact-content .table tbody .card-body tr.row-checked td {
	background: #fffaf2;
}

.ks-contacts .content .pane-content.cases .contact-content .table tbody .card-body tr td {
	min-height: 53px;
}

.ks-contacts .content .pane-content.cases .contact-content .table .options-cell .open-popover-link .open-popover {
	width: 30px;
}

.ks-contacts .content .pane-content.cases .contact-content .table .options-cell .open-popover-link .more-options {
  position: relative;
  display: block;
  height: 31px;
  cursor: pointer;
  width: 30px;
  float: right;
  border-radius: 15px;
  transition: all .3s;

  &:hover {
  	background: rgba(255, 152, 0, 0.1);
  	.icon {
	    background: url(../img/icons/@2x/icon-dots-more--orange@2x.png) 0 0 no-repeat;
	    width: 4px;
	    height: 15px;
	    -webkit-background-size: auto 100%;
	    background-size: auto 100%;
  	}
  }

  .icon {
    height: 15px;
		margin-top: -7.5px;
    transition: all .3s;
  }
}

.cases {
	.person-form .row.top {
		padding: 12.5px 0;
	}
	.profile-information {
		.custom-scroll-table {
			.table {
				td {
					vertical-align: middle !important;
				}
			}
		}
	}

	.table-thead {
		.set-daterange-time {
			.custom-search-block {
				position: relative;

				&:before {
			    position: absolute;
			    top: 50%;
			    left: 10px;
			    content: "";
			    background: url(../img/icons/@2x/icon-date@2x.png) 0 0 no-repeat;
			    width: 16px;
			    height: 16px;
			    -webkit-background-size: auto 100%;
			    background-size: auto 100%;
			    margin-top: -8px;
			    opacity: .3;
			    z-index: 11;
				}

				& > .form-control {
					padding-left: 40px;
				}
			}
			.set-date-block {
    		display: none;
    		position: absolute;
    		top: 0;
    		left: 0;
    		width: 500px;
    		box-shadow: 0 6px 20px 0px rgba(0,0,0,0.25);
    		background: #fff;
    		display: flex;
    		flex-wrap: wrap;
    		border-radius: 15px;
    		// opacity: 0;
    		// visibility: hidden;
    		transition: all .3s;
    		z-index: 99;

    		&.show {
    			opacity: 1;
    			visibility: visible;
    		}

    		.timepicker-block,
    		.datepicker-block {
    			flex: 0 0 50%;
    			padding: 15px;
    		}

    		.head {
    			position: relative;
    			text-align: right;
			    padding-bottom: 10px;
			    border-bottom: 2px solid #f2f2f2;
			    margin-bottom: 20px;
			    font-weight: 400;
			    font-size: 13px;

			    .title {
			    	position: absolute;
			    	top: 50%;
			    	left: 0;
			    	color: #ccc;
				    font-weight: 400;
				    font-size: 13px;
				    padding-left: 25px;
				    margin-top: -13px;

				    .icon {
				    	left: 0;
				    	margin-left: 0;
				    	opacity: .3;
				    }
			    }

			    input {
	    	    background: 0;
				    border: 0;
				    text-align: right;
				    font-size: 13px;
				    color: #666666;
				    font-weight: 400;
			    }
    		}

    		.timepicker-block {
    			.inner-block {
    				box-shadow: none;
    				border-radius: 0;
    				margin-top: 0;

    				.simplebar-track.vertical {
    					display: block !important;
    				}
    			}
    			.timepicker-inner .inner-block .value:hover,
    			.timepicker-inner .inner-block .value.active {
    				background: #ff9800;
    				color: #fff;
    			}

    			.timepicker-inner .inner-block .value:hover {
    				background: rgba(#ff9800, 0.1);
    				color: #ff9800;
    			}

    			.timepicker-inner .simplebar-scrollbar {
    				background: #ff9800;
    			}
    		}

    		.datepicker-block {
    			.datepicker {
    				td,
				    th {
				      background: none !important;
				      border: 0;

				      &.dow {
				        padding: 5px 0;
				        color: #cccccc !important;
				      }

				      &.active {
				        background: #ff9800 !important;
				        color: #fff !important;
				      }

				      &.today {
				        background: rgba(#ff9800, 0.1) !important;
				        color: #ff9800 !important;

				        &.active {
				        	background: #ff9800 !important;
				        	color: #fff !important;
				        }

				        &:hover {
				          color: #fff !important;        
				        }
				      }

				      &:last-child {
				      	min-width: auto !important;
				      }

				      &.day {
				        &:hover {
				          background: #ff9800 !important;
				          color: #fff;
				        }
				      }
				    }
    			}
    		}
    	}
		}
	}

	.table-heading {
		.similar-nav-block {
			margin-left: 0;

			@media (max-width: 768px) {
				margin-bottom: 15px;
			}

			ul {
				a {
					color: #000;
				}

				li {
					&:hover,
					&.active {
						a {
							color: #ff9800;
						}
					}
				}
			}
		}

		.profile-create-block {
			padding: 15px 0;

			.create-block {
				display: flex;
			}

			.btn {
		    position: relative;
		    padding: 12px 20px 12px 30px;
		    text-align: center;
		    color: #000;
		    text-transform: uppercase;
		    border-radius: 15px;
		    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
		    transition: all .3s;
		    display: -ms-flexbox;
		    display: -webkit-flex;
		    display: flex;
		    -webkit-flex-direction: row;
		    -ms-flex-direction: row;
		    flex-direction: row;
		    -webkit-flex-wrap: nowrap;
		    -ms-flex-wrap: nowrap;
		    flex-wrap: nowrap;
		    -webkit-justify-content: center;
		    -ms-flex-pack: center;
		    justify-content: center;
		    -webkit-align-content: stretch;
		    -ms-flex-line-pack: stretch;
		    align-content: stretch;
		    -webkit-align-items: center;
		    -ms-flex-align: center;
		    align-items: center;
		    font-size: 13px;
		    font-weight: 700;
		    background: none;
		    min-height: 45px;
		    min-width: 45px;

		    @media (max-width: 768px) {
		    	padding: 4.5px 0;
		    }

		    span {
		    	@media (max-width: 1199px) {
		    		display: none;
		    	}
		    }

		    &:hover,
		    &:focus {
		    	-webkit-box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.2);
		    	-moz-box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.2);
		    	box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.2);
		    }

		    &.btn-group {
		    	position: relative;
			    margin-left: 15px;
			    padding-left: 46px;

			    &:before {
	    	    position: absolute;
				    top: 50%;
				    left: 20px;
				    background: url('../img/icons/@2x/icon-addfolder@2x.png') 0 0 no-repeat;
				    width: 16px;
				    height: 15px;
				    -webkit-background-size: 100% auto;
				    -moz-background-size: 100% auto;
				    background-size: 100% auto;
				    margin-top: -8px;
				    margin-left: -7.5px;
				    content: "";

				    @media (max-width: 1199px) {
				    	left: 50%;
				    	margin-left: -8px;
				    }
			    }

		    	&:hover,
			    &:focus {
			    	-webkit-box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.2);
			    	-moz-box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.2);
			    	box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.2);
			    }
		    }

		    &.btn-new {
    	    position: relative;
    	    background: #ff9800;
			    color: #fff;
			    padding-left: 46px;

			    &:before {
	    	    position: absolute;
				    top: 50%;
				    left: 20px;
				    background: url('../img/icons/@2x/icon-plus--white@2x.png') 0 0 no-repeat;
				    width: 10px;
				    height: 10px;
				    -webkit-background-size: 100% auto;
				    -moz-background-size: 100% auto;
				    background-size: 100% auto;
				    margin-top: -5px;
				    margin-left: -5px;
				    content: "";

				    @media (max-width: 1199px) {
				    	left: 50%;
				    	margin-left: -5px;
				    }
			    }
		    }
			}

			.btn-file,
			.btn-calendar {
				box-shadow: none;
			}

			.custom-search-block {
				flex: 1 210px;

				input {
					&.form-control {
						border: 1px solid #e6e6e6;
						padding-right: 36px;
					}
				}

				.icon-addon {
					top: 1px;
					right: 1px;
					bottom: 2px;
					height: auto;
				}
			}
		}
	}
	.group-head {
		td {
			padding: 0;
			width: 100%;
		}

		.card-header {
			position: relative;
			background: #fff;
			padding: 0;

			&:hover {
				.edit-group-link {
					opacity: 1;
					visibility: visible;
				}
			}

			.card-link {
				position: relative;
				display: block;
				opacity: .7;
				padding: 22px 10px 22px 73px;

				&:hover {
					opacity: 1;
					background: #fffaf2;

					.btn-edit {
						opacity: 1;
						visibility: visible;
					}
				}

				&.collapsed {
					&:before {
						transform: rotate(0);	
					}

					.icon-folder {
						background: url(../img/icons/@2x/icon-group--filled@2x.png) 0 0 no-repeat;
						background-size: auto 100%;
					}
				}

				&:before {
			    position: absolute;
			    top: 50%;
			    left: 18px;
			    background: url(../img/icons/@2x/angle-down@2x.png) 0 0 no-repeat;
			    width: 9px;
			    height: 6px;
			    -webkit-background-size: 100% auto;
			    background-size: 100% auto;
			    margin-top: -3px;
			    transition: all .3s;
			    content: "";
          transform: rotate(180deg);
				}

				.ks-text {
					display: inline-block !important;
					color: #000 !important;
    			font-weight: 600;
    			font-size: 13px;
    			text-transform: uppercase;
    			transition: all .3s;
				}

				.icon-folder {
			    left: 43px;
			    margin-left: 0;
			    transition: all .3s;
				}

				.btn-edit {
					position: absolute;
					margin-left: 15px;
					outline: 0;
					top: 50%;
					margin-top: -15px;
					width: 30px;
					height: 30px;
					border: solid 1px rgba(0, 0, 0, 0.1);
					background: transparent;
					transition: all .3s;
					border-radius: 100px;
					opacity: 0;
					visibility: hidden;

					&:hover {
						background: rgba(255, 152, 0, 0.1);
						border-color: rgba(255, 152, 0, 0.1);

						.icon {
							background: url('../img/icons/@2x/icon-pencil--orange@2x.png') 0 0 no-repeat;
							background-size: 100% auto;
						}
					}
				}

			}
			.edit-group-link {
				position: absolute;
				right: 3px;
				top: 50%;
				margin-top: -15.5px;
				display: -ms-flexbox;
		    display: -webkit-flex;
		    display: flex;
		    -webkit-flex-direction: row;
		    -ms-flex-direction: row;
		    flex-direction: row;
		    -webkit-flex-wrap: nowrap;
		    -ms-flex-wrap: nowrap;
		    flex-wrap: nowrap;
		    -webkit-justify-content: flex-start;
		    -ms-flex-pack: start;
		    justify-content: flex-start;
		    -webkit-align-content: stretch;
		    -ms-flex-line-pack: stretch;
		    align-content: stretch;
		    -webkit-align-items: center;
		    -ms-flex-align: center;
		    align-items: center;
		    opacity: 0;
		    visibility: hidden;
		    transition: all .3s;

		    .btn-success {
		    	-webkit-flex: 0 1 auto;
			    -ms-flex: 0 1 auto;
			    -webkit-box-flex: 0;
			    flex: 0 1 auto;
			    -webkit-border-radius: 15px;
			    border-radius: 15px;
			    font-weight: 600;
			    font-size: 13px;
			    background: none;
			    color: #333;
			    border: 1px solid transparent;
			    -webkit-transition: all .3s;
			    -o-transition: all .3s;
			    transition: all .3s;
			    position: relative;
			    padding-left: 25px;
			    text-transform: uppercase;

			    &:before {
			    	position: absolute;
				    top: 50%;
				    left: 10px;
				    background: url(../img/icons/@2x/icon-plus@2x.png) 0 0 no-repeat;
				    width: 10px;
				    height: 10px;
				    -webkit-background-size: 100% auto;
				    background-size: 100% auto;
				    margin-top: -5px;
				    margin-left: -5px;
				    content: "";
				    transition: all .3s;
			    }

			    &:focus,
			    &:hover {
	    	    background: rgba(255,152,0,0.1);
    				color: #ff9800;
    				box-shadow: none;

    				&:before {
    					background: url(../img/icons/@2x/icon-plus--orange@2x.png) 0 0 no-repeat;
					    width: 10px;
					    height: 10px;
					    -webkit-background-size: 100% auto;
					    background-size: 100% auto;
    				}
			    }
		    }

		    .open-popover-link {
    	    position: relative;
			    width: 30px;
			    height: 31px;
			    -webkit-flex: 1 100%;
			    -ms-flex: 1 100%;
			    -webkit-box-flex: 1;
			    flex: 1 100%;

			    .open-popover {
	    	    width: 34px;
				    height: 31px;
				    float: right;
			    }

			    .more-options {
	    	    position: relative;
				    display: block;
				    height: 31px;
				    cursor: pointer;
				    width: 30px;
				    float: right;
				    border-radius: 15px;
				    transition: all .3s;

				    &:hover {
				    	background: rgba(255, 152, 0, 0.1);
				    	.icon {
		    		    background: url(../img/icons/@2x/icon-dots-more--orange@2x.png) 0 0 no-repeat;
						    width: 4px;
						    height: 15px;
						    -webkit-background-size: auto 100%;
						    background-size: auto 100%;
				    	}
				    }

				    .icon {
		    	    height: 15px;
  						margin-top: -7.5px;
					    transition: all .3s;
				    }
			    }
		    }
			}
		}
	}
	.drag-group {
		& > td {
			padding: 0;
			width: 100%;
		}

		.card-body {
			padding: 0;

			.checkbox-cell {
				position: relative;
				padding-right: 0;

				.custom-checkbox {
					top: 5px;
					
					&>.custom-control-input:checked ~ .custom-control-indicator,
					&:hover>.custom-control-input:not(:disabled) ~ .custom-control-indicator {
						border-color: #ff9800;
					}

					&>.custom-control-input ~ .custom-control-indicator:before {
						background: url("../img/icons/@2x/checkbox-checked--orange@2x.png") 0 0 no-repeat;
						background-size: 100% auto;
				    width: 13px;
				    height: 10px;
				    position: absolute;
				    top: 50%;
				    margin-top: -5px;
				    left: 50%;
				    margin-left: -6.5px;
					}
				}

				span {
					&.icon {
						display: inline-block !important;
						position: relative;
						left: auto;
						margin-top: 0;
						margin-left: 0;
						margin-right: 12px;
						cursor: pointer;
					}
				}
			}

			.priority-cell {
				justify-content: center !important;

				// .select2 {
				// 	width: 30px !important;
					
				// 	&.select2-container--open,
				// 	&:hover {
				// 		.select2-selection--single {
				// 			.select2-selection__rendered {
				// 				&:before {
				// 					border-radius: 100px 0 0 100px;
				// 				}
				// 			}
				// 			.select2-selection__arrow {
				// 				right: -22px;
				// 				border-radius: 0 100px 100px 0;
				// 			}
				// 		}
				// 	}

				// 	.select2-selection--single {
				// 		height: 30px;
				// 		box-shadow: none;

				// 		.select2-selection__arrow {
				// 			height: 30px;
				// 			width: 22px;
				// 			top: 0;
				// 			transition: all .3s;
				// 			right: 3px;

				// 			b {
				// 				position: absolute;
				// 		    top: 50%;
				// 		    left: 50%;
				// 		    background: url(../img/icons/@2x/angle-down-full--white@2x.png) 0 0 no-repeat;
				// 		    width: 6px;
				// 		    height: 3px;
				// 		    background-size: auto 100%;
				// 		    margin-top: -3px;
				// 		    margin-left: -1.5px;
				// 		    border: 0;
				// 		    -webkit-transition: all .3s;
				// 		    -o-transition: all .3s;
				// 		    transition: all .3s;
				// 			}
				// 		}

				// 		.select2-selection__rendered {
				// 			padding: 0;
				// 			line-height: 30px;
				// 			text-indent: 100%;
				// 			z-index: 1;
				// 		}
				// 	}

				// }

				.dropdown-toggle {
					padding: 0;
					background: none;
					border: 0;
					box-shadow: none;

					&:hover {
						span {
							border-radius: 100px 0 0 100px;

							&:after {
								right: -15px;
								background-position: 0 50%;
							}
						}
					}

					&:after {
						display: none;
					}

					span {
						position: relative;
						display: block;
						width: 30px;
						height: 30px;
						border-radius: 100px;
						font-size: 11px;
						color: #fff;
						padding: 7px 0;
						z-index: 2;
						transition: all .3s;
						text-indent: -99999999px;

						&:after {
							position: absolute;
					    height: 30px;
					    width: 15px;
					    top: 0;
					    right: 0;
					    margin: 0;
					    content: "";
					    border-radius: 0 100px 100px 0;
					    background: url(../img/icons/angle-down-full--white.png) -7px 50% no-repeat;
					    z-index: 1;
					    transition: all .3s;
						}

						&:before {
							position: absolute;
							top: 50%;
							left: 50%;
							font-family: $font-mont;
							font-size: 11px;
							font-weight: 600;
							color: #fff;
							content: "";
							width: 30px;
							height: 30px;
							line-height: 30px;
							margin-top: -15px;
							margin-left: -15px;
							text-indent: 0;
							z-index: 2;
						}

						&.high {
							background: #ff5722;

							&:after {
								background-color: #ff5722;
							}

							&:before {
								content: "!!!";
							}
						}

						&.medium {
							background: #673ab7;

							&:after {
								background-color: #673ab7;
							}

							&:before {
								content: "!!";
							}
						}

						&.low {
							background: #00bcd4;

							&:after {
								background-color: #00bcd4;
							}

							&:before {
								content: "!";
							}
						}

						&.no-priority {
							background: #e6e6e6;

							&:after {
								background-color: #e6e6e6;
							}
						}
					}
				}

				.dropdown-menu {
					box-shadow: 0 6px 14px 2px rgba(0,0,0,0.1);
					border-radius: 15px;
					border: 0;
					width: 195px;

					.dropdown-divider {
						margin: 0;
					}

					.checked,
					.dropdown-item {
						position: relative;
						padding: 10px 15px;
						cursor: pointer;

						&:active {
							background: none;
						}

						span {
							display: block;
							font-size: 13px;
							color: #000;
							padding-left: 30px;

							&:before {
								position: absolute;
								top: 50%;
								left: 15px;
								content: "";
								font-family: $font-mont;
								font-size: 11px;
								color: #fff;
								font-weight: 600;
								width: 20px;
								height: 20px;
								border-radius: 50px;
								margin-top: -10px;
								text-align: center;
							}

							&.high {
								&:before {
									background: #ff5722;
									content: "!!!";
								}
							}

							&.medium {
								&:before {
									background: #673ab7;
									content: "!!";
								}
							}

							&.low {
								&:before {
									background: #00bcd4;
									content: "!";
								}
							}

							&.no-priority {
								&:before {
									background: #e6e6e6;
								}
							}
						}
					}
				}
			}

			.task-name-cell {
				&:hover {
					cursor: pointer;

					.btn-edit {
						opacity: 1;
						visibility: visible;
					}

					.name {
						color: #ff9800 !important;
					}
				}

				.name-block {
					position: relative;
				}

				.name {
					position: relative;
			    display: inline-block;
					font-size: 13px;
					font-weight: 600;
			    text-overflow: ellipsis;
			    overflow: hidden;
			    width: inherit;
			    white-space: nowrap;
					transition: all .3s;
					padding: 6px 19px 6px 0;
				}

				.count {
					position: absolute;
					top: 50%;
					right: 0;
					width: 12px;
					height: 12px;
					background: #ff5722;
					border-radius: 20px;
					font-size: 8px;
					color: #fff;
					text-align: center;
					line-height: 12px;
					font-weight: 600;
					margin-top: -13px;
					font-style: normal;
				}

				.btn-edit {
					position: absolute;
					right: -5px;
					top: 50%;
					margin-top: -15px;
					width: 30px;
					height: 30px;
					border: solid 1px rgba(0, 0, 0, 0.1);
					background: #fffaf2;
					transition: all .3s;
					border-radius: 100px;
					opacity: 0;
					visibility: hidden;

					.icon {
						left: 50%;
						margin-left: -5px;
					}

					&:hover {
						background: #FFF5E5;
						// border-color: #FFF5E5;

						.icon {
							background: url('../img/icons/@2x/icon-pencil--orange@2x.png') 0 0 no-repeat;
							background-size: 100% auto;
						}
					}
				}
			}

			.assigned-cell {
				position: relative;
				padding: 0 !important;

				&:hover {
					.assigned-avatar-block {
						transform: translateY(0);
						background: #fffaf2;
					}

					.btn-edit {
						opacity: 1;
						visibility: visible;
					}
				}

				.assigned-inner {
					position: relative;
					overflow: hidden;
					padding: 1.21rem .75rem;
				}

				.assigned-row {
					display: block;
			    color: #a3a3a3;
			    font-size: 13px;
			    line-height: 1.5em;
			    word-wrap: break-word;
			  }

				.assigned-avatar-block {
					position: absolute;
					left: 10px;
					right: 0;
					display: flex;
					top: 50%;
					margin-top: -15px;
					background: #fff;
					// transform: translateY(50px);
					// transition: all .3s;

					.user {
						position: relative;
						display: inline-block;
						flex: 0 30px;
						margin-right: 5px;

						&.creator {
							&:before {
								position: absolute;
								bottom: 0;
								right: 0;
								content: "";
								background: url(../img/@2x/creator-star@2x.png) 0 0 no-repeat;
								width: 10px;
								height: 10px;
								background-size: auto 100%;
							}
						}

						&.more {
							&:before {
								position: absolute;
								top: 0;
								left: 0;
								right: 0;
								bottom: 0;
								content: "";
								background: rgba(0,0,0,0.4);
								border-radius: 100px;
							}

							.count {
								position: absolute;
								top: 0;
								left: 0;
								right: 0;
								display: block;
								text-align: center;
								font-size: 12px;
								color: #fff;
								line-height: 30px;
								font-style: normal;
							}
						}

						img {
							border-radius: 100px;
						}
					}
				}

				.btn-edit {
					position: absolute;
					right: 15px;
					outline: 0;
					top: 50%;
					margin-top: -15px;
					width: 30px;
					height: 30px;
					border: solid 1px rgba(0, 0, 0, 0.1);
					background: transparent;
					transition: all .3s;
					border-radius: 100px;
					opacity: 0;
					visibility: hidden;

					.icon {
						left: 50%;
						margin-left: -5px;
					}

					&:hover {
						background: rgba(255, 152, 0, 0.1);
						border-color: rgba(255, 152, 0, 0.1);

						.icon {
							background: url('../img/icons/@2x/icon-pencil--orange@2x.png') 0 0 no-repeat;
							background-size: 100% auto;
						}
					}
				}

				.search-block {
					position: absolute;
					top: 10px;
					left: 0;
					right: 0;
	    		box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.16);
	    		border-radius: 12px 12px 0 0;
	    		opacity: 0;
	    		visibility: hidden;
	    		transition: all .3s;
	    		z-index: 10;

	    		&.top {
	    			box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
	    			border-radius: 0 0 12px 12px;

	    			.search_results {
	    				top: auto;
	    				bottom: 100%;
	    				box-shadow: 0 -6px 20px 0px rgba(0,0,0,0.25);
	    				border-radius: 12px 12px 0 0;
	    			}

	    			.form-control {
	    				border-radius: 0 0 12px 12px;
	    			}
	    		}

	    		&.show {
	    			opacity: 1;
	    			visibility: visible;
	    		}

		    	.search_results {
			  		position: absolute;
			  		top: 100%;
			  		right: 0;
			  		left: 0;
			  		background: #fff;
			  		padding: 5px 0;
			  		box-shadow: 0 6px 20px 0px rgba(0,0,0,0.25);
			  		border-radius: 0 0 12px 12px;
			  		z-index: 2;
		    		
		    	}

		    	.search_results_list {
			  		height: 150px;
			  		overflow-x: auto;

			  		.item {
	    				position: relative;
		    			display: -ms-flexbox;
					    display: -webkit-flex;
					    display: flex;
					    -webkit-flex-direction: row;
					    -ms-flex-direction: row;
					    flex-direction: row;
					    -webkit-flex-wrap: nowrap;
					    -ms-flex-wrap: nowrap;
					    flex-wrap: nowrap;
					    -webkit-justify-content: flex-start;
					    -ms-flex-pack: start;
					    justify-content: flex-start;
					    -webkit-align-content: stretch;
					    -ms-flex-line-pack: stretch;
					    align-content: stretch;
					    -webkit-align-items: center;
					    -ms-flex-align: center;
					    align-items: center;
					    border-top: 2px solid #f2f2f2;

					    &:first-child {
					    	border-top: 0;
					    }

					    &:hover {
					    	.person-block {
					    		background: #faf9fd;
					    	}

					    	.btn {
	    						background: #673ab7;
						    	color: #fff;
						    	box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.3);
	    					}
					    }

					    .btn {
					    	position: absolute;
					    	top: 50%;
					    	right: 15px;
					    	margin-top: -22.5px;

						    padding: 12px 15px 12px 15px;
						    text-align: center;
						    text-transform: uppercase;
						    border-radius: 12px;
				        background: #fff;
						    color: #000;
						    transition: all .3s;
						    display: -ms-flexbox;
						    display: -webkit-flex;
						    display: -webkit-box;
						    display: flex;
						    -webkit-flex-direction: row;
						    -ms-flex-direction: row;
						    -webkit-box-orient: horizontal;
						    -webkit-box-direction: normal;
						    flex-direction: row;
						    -webkit-flex-wrap: nowrap;
						    -ms-flex-wrap: nowrap;
						    flex-wrap: nowrap;
						    -webkit-justify-content: center;
						    -ms-flex-pack: center;
						    -webkit-box-pack: center;
						    justify-content: center;
						    -webkit-align-content: stretch;
						    -ms-flex-line-pack: stretch;
						    align-content: stretch;
						    -webkit-align-items: center;
						    -ms-flex-align: center;
						    -webkit-box-align: center;
						    align-items: center;
						    font-size: 13px;
						    font-weight: 700;

						    @media (max-width: 576px) {
						    	padding: 5px;
						    	font-size: 12px;
						    	top: 24px;
						    	right: 2px;
						    }

						    // &:hover,
						    // &:focus {
						    // 	background: #673ab7;
						    // 	color: #fff;
						    // 	box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.3);
						    // }
					    }
					  }

			  		.person-block {
			  			transition: all .3s;
	    				-webkit-flex: 1 1 auto;
					    -ms-flex: 1 1 auto;
					    flex: 1 1 auto;
					    -webkit-align-self: auto;
					    -ms-flex-item-align: auto;
					    align-self: auto;
	    				padding: 15px;

	    				@media (max-width: 576px) {
	    				 	padding-top: 30px;
	    				 	padding-bottom: 10px;
	    				}

	    				.inner {
	    					display: table;
						    height: 45px;
						    width: 100%;
						    margin: 0;
						    padding: 0;

						    .avatar,
						    .name {
						    	display: table-cell;
						    	vertical-align: middle;
						    }

						    .name {
				    	    font-size: 13px;
							    color: #a3a3a3;

							    b {
					    	    display: block;
								    font-weight: 600;
								    color: #000;
							    }
						    }
	    				}
	    			}		    		
		    	}

		    	.form-control {
				    height: 45px;
				    background: #fff;
				    padding: 10px 15px;
				    border-radius: 12px 12px 0 0;
				    color: #000;
				    font-size: 14px;
				    font-family: "Open Sans", sans-serif;
				    transition: all .3s;
		    	}

		    	.icon-addon {
		    		height: 43px;
		    		top: 1px;
		    		right: 1px;
		    	}
	    	}
			}

			.area-cell,
			.date-cell {
				span {
					padding: 6px 0;
				}
			}

			.status-cell {
				.dropdown-toggle {
					padding: 0;
					background: none;
					border: 0;
					box-shadow: none;

					&:hover {
						span {
							border-radius: 100px 0 0 100px;

							&:after {
								right: -15px;
								background-position: 0 50%;
							}
						}
					}

					&:after {
						display: none;
					}

					span.working ~ &:after {
						background-color: #2196f3;

					}

					span {
						position: relative;
						display: block;
						width: 120px;
						height: 30px;
						border-radius: 100px;
						font-size: 11px;
						color: #fff;
						padding: 7px 0;
						z-index: 2;
						transition: all .3s;

						&:after {
							position: absolute;
					    height: 30px;
					    width: 15px;
					    top: 0;
					    right: 0;
					    margin: 0;
					    content: "";
					    border-radius: 0 100px 100px 0;
					    background: url(../img/icons/angle-down-full--white.png) -7px 50% no-repeat;
					    z-index: 1;
					    transition: all .3s;
						}

						&.working {
							background: #2196f3;
							color:#ffffff;

							&:after {
								background-color: #2196f3;
							}
						}

						&.done {
							background: #4caf50;
							color:#ffffff;

							&:after {
								background-color: #4caf50;
							}
						}

						&.stuck {
							background: #ffc107;
							color:#ffffff;

							&:after {
								background-color: #ffc107;
							}
						}

						&.no-status {
							background: #e6e6e6;
							color:#ffffff;

							&:after {
								background-color: #e6e6e6;
							}
						}

						&.user-label {
							background: #999999;
							color:#ffffff;

							&:after {
								background-color: #999999;
							}
						}
					}
				}

				.dropdown-menu {
					box-shadow: 0 6px 14px 2px rgba(0,0,0,0.1);
					border-radius: 15px;
					border: 0;
					width: 195px;

					.dropdown-divider {
						margin: 0;
					}

					.checked,
					.dropdown-item {
						position: relative;
						padding: 10px 15px;
						cursor: pointer;

						&:active {
							background: none;
						}

						span {
							display: block;
							font-size: 13px;
							color: #000;
							padding-left: 30px;

							&:before {
								position: absolute;
								top: 50%;
								left: 15px;
								content: "";
								width: 20px;
								height: 20px;
								border-radius: 50px;
								margin-top: -10px;
							}

							&.working {
								&:before {
									background: #2196f3;
								}
							}

							&.done {
								&:before {
									background: #4caf50;
								}
							}

							&.stuck {
								&:before {
									background: #ffc107;
								}
							}

							&.no-status {
								&:before {
									background: #e6e6e6;
								}
							}
						}
					}

					.form-group {
						position: relative;
						padding: 8px 10px 7px;
						margin-bottom: 0;

						&:before {
							position: absolute;
							top: 50%;
							left: 15px;
							content: "";
							width: 20px;
							height: 20px;
							border-radius: 50px;
							margin-top: -10px;
							background: #999999;
						}


						.form-control {
							padding-left: 30px;
							height: 30px;
							border-radius: 8px;
							width: 140px;
							float: left;
							font-size: 13px;
						}

						.btn {
							display: inline-block;
							font-size: 11px;
							font-weight: 600;
							background: none;
							border: 0;
							float: left;
							padding: 7px 10px;
							transition: all .3s;
							border-radius: 8px;

							&:hover {
								background: rgb(221, 221, 221);
							}
						}
					}
				}
			}

			.options-cell {
				.btn {
					&:hover {
				    background: rgba(255,152,0,0.1) !important;
    				color: #ff9800 !important;
					}

					&:focus {
						box-shadow: none;
					}
				}
			}
		}
	}
}