.clear{
	clear:both;
}
.profile-employee{
	.open-popover-link{
		position: relative;
		width: 34px;
		opacity: 0;
		height: 31px;
		    // -webkit-flex: 1 100%;
		    // -ms-flex: 1 100%;
		    // -webkit-box-flex: 1;
		    // flex: 1 100%;
		    -webkit-flex: 0 1 auto;
		    -ms-flex: 0 1 auto;
		    -webkit-box-flex: 0;
		    flex: 0 1 auto;
		    margin-right: -17px;
		    .open-popover {
		    	width: 34px;
		    	height: 31px;
		    	float: right;
		    	.more-options {
		    		position: relative;
		    		display: block;
		    		height: 31px;
		    		cursor: pointer;
		    		width: 34px;
		    		float: right;
		    	}
		    }
		}
		.emp_online{
			font-size: 12px;
			font-weight: 600;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.25;
			letter-spacing: normal;
			text-align: left;
			color: #4caf50;
			text-transform: uppercase;
			display:inline-block;
			margin-left:15px;
			padding-left:15px;
			position: relative;
			&:before{
				content:'';
				display:block;
				position:absolute;
				width:10px;
				height:10px;
				top:3px;
				left:0px;
				background: #4caf50;
				border-radius: 15px;
			}
		}
		.emp_offline{
			font-size: 12px;
			font-weight: 600;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.25;
			letter-spacing: normal;
			text-align: left;
			color: #666666;
			text-transform: uppercase;
			display:inline-block;
			margin-left:15px;
			padding-left:15px;
			position: relative;
			&:before{
				content:'';
				display:block;
				position:absolute;
				width:10px;
				height:10px;
				top:3px;
				left:0px;
				background: #666666;
				border-radius: 15px;
			}
		}
		.emp_role{
			display:inline-block;
			margin-right:10px;

			&.emp_role_li{
				padding-left:14px;
				position:relative;

				&:before{
					width: 4px;
					height: 4px;
					display:block;
					content:'';
					border-radius:50%;
					position: absolute;
					background:rgba(255,255,255,0.4);
					top:4px;
					left:0px;
				}
			}
		}
		.value_under{
			color: #ffffff;
			font-size: 13px;
			font-weight: 600;
			padding-top:10px;
			display:inline-block;
		}
		.cont_block_emp{
			position:relative;
			margin-bottom:20px;
			padding-left:34px;
			@media (max-width: 768px) {
				padding-top:20px;
			}

			.icon-pin-white{
				top: 0px;
				left: 4px;
				margin-left: 0;
				margin-top: 0;
				opacity: 0.5;
			}
			.ks-text{
				display: block;
				word-break: break-all;
				color: #fff;
				line-height: 1.2em;
				font-size: 13px;
			}
		}
		.table-heading{
			.information{
				.contact-block{
					.phone-block{
						.ks-text{
							font-size: 16px;
						}

					}
				}
			}

		}


		&.profile-content{
			.table-heading{
				.similar-nav-block{
					ul{
						li{
							a{
								color:#000000;
								font-size: 16px;
							}
							&:hover,
							&.active{
								a{
									color:#4caf50;
								}
							}
						}

					}
				}

			}
			.information{
				.price-block{
					.block{
						&.price, 
						&.amount{
							align-self: center;
							&:after{
								background-size: contain;
								-webkit-background-size: contain;
							}
						}
					}
				}
			}
			
			.profile-information{
				.card-blocks{
					.card{
						.card_block_item{
							padding-left: 30px;
							padding-right: 30px;
							&:hover{
								background-color: rgba(255,152,0,0.05);
								.open-popover-link{
									opacity: 1;
								}
							}
						}

					}
				}
				.user-information{
					padding-top:0px;
					padding-bottom:0px;
					padding-left:0px;
					padding-right:0px;
					.open-popover-link{
						float:right;
						flex: none;
						float: right;
						.open-popover{
							display: block; 
							position: relative; 
							padding: 0; 
							border: none; 
						}
					}
					
				}
				.cards-block.user-information{
					padding-left: 30px;
					padding-right: 30px;
					.card-security,
					.card-timesheet{
						.card-header{
							padding-left:25px;
							padding-right:0px;
							border-bottom:solid 2px #666666;
							margin-bottom:30px;
							&.green_line_under{
								border-bottom: solid 2px #009688;
							}
							.icon{
								left:0px;
							}

						}
					}


				}
			} 
		}

		.edit_options{
			font-size: 13px;
			line-height: 1.5em;
			word-wrap: break-word;
			color: #000000 !important;
			text-transform: uppercase;
			font-weight: 600;
			padding-left: 26px;
			position: relative;
			white-space: nowrap;
			background: url(../img/icons/@2x/icon-edit@2x.png) 0 0 no-repeat;
			-webkit-background-size: auto 100%;
			background-size: auto 100%;
			-webkit-transition: all .3s;
			-o-transition: all .3s;
			transition: all .3s;
			float:right;
		}
		.card_block_item{
			margin-bottom:30px;
			position:relative;
			padding-left:44px;

			&.green_card_item{
				margin-bottom:15px;
				padding-left:0px;
			}
			.icon-black-desktop, .icon-blue-desktop, .icon-android-green, .icon-iphone{
				top:3px;
				left:0px;
				margin-left:0px;
				margin-top:0px;
			}

			.device_desc{
				text-transform:uppercase;
				color: #666666;
				font-size: 11px;
				font-weight: 600;
				margin-bottom:10px;

				&:hover{
					color: #000000;
				}
			}
			.device_date{
				float:left;
				opacity: 0.6;
				color: #000000;
				font-size: 13px;
				margin-right:10px;
			}
			.device_date_right{
				padding-left:14px;
				position:relative;
				font-size: 13px;
				color:rgba(0,0,0,0.6);
				float:left;

				&:before{
					content:'';
					display:block;
					position: absolute;
					width:4px;
					lefT:0px;
					top:9px;
					height:4px;
					border-radius:50%;
					background:rgba(0,0,0,0.4);
				}
			}



		}
		.show-more-employee{
			display: block;
			text-align: center;
			padding: 10px 0;
			margin: 0 10px;
			border-top: 1px solid #f2f2f2;
			text-transform: uppercase;
			color: #333;
			font-weight: 700;
			font-size: 13px;
		}
		.timesheet_caption{
			font-size: 11px;
			font-weight: 600;
			font-style: normal;
			text-transform:uppercase;
			font-stretch: normal;
			color: #666666;
			margin-bottom:10px;
		}
		.timesheet_desc{
			font-size: 11px;
			color:rgba(0,0,0,0.6);
		}

		.assigned_cases{

			.card-blockquote{
				font-size: 13px;
				font-weight: 600;
				font-style: normal;
				font-stretch: normal;
				padding-top:15px;
				padding-bottom:15px;

				line-height: 1.54;
				color: #000000;
				margin:0 !important;
				padding-left:30px;
				padding-right:30px;

				display: -ms-flexbox;
				display: -webkit-flex;
				display: -webkit-box;
				display: flex;
				-webkit-flex-direction: row;
				-ms-flex-direction: row;
				-webkit-box-orient: horizontal;
				-webkit-box-direction: normal;
				flex-direction: row;
				-webkit-flex-wrap: nowrap;
				-ms-flex-wrap: nowrap;
				flex-wrap: nowrap;
				-webkit-justify-content: flex-start;
				-ms-flex-pack: start;
				-webkit-box-pack: start;
				justify-content: flex-start;
				-webkit-align-content: stretch;
				-ms-flex-line-pack: stretch;
				align-content: stretch;
				-webkit-align-items: center;
				-ms-flex-align: center;
				-webkit-box-align: center;
				align-items: center;


				&:hover{
					background-color: rgba(255,152,0,0.05);
					.open-popover-link{
						opacity: 1;
					}
				}
			}
		}



		.assigned_cases_name{
	// -webkit-flex: 0 1 auto;
 //    -ms-flex: 0 1 auto;
 //    -webkit-box-flex: 0;
 //    flex: 0 1 auto;

 -webkit-flex: 1 100%;
 -ms-flex: 1 100%;
 -webkit-box-flex: 1;
 flex: 1 100%;

 font-weight: 600;
 font-size: 13px;
 background: none;
 color: #333;

 -webkit-transition: all .3s;
 -o-transition: all .3s;
 transition: all .3s;
}
.profile-information{
	

	.inner-block {
		background: #fff;
		-webkit-border-radius: 15px;
		border-radius: 15px;
		-webkit-box-shadow: 0 2px 2px rgba(0,0,0,0.2);
		box-shadow: 0 2px 2px rgba(0,0,0,0.2);
		margin-top: 30px;
		.block-content{
			.row{
				padding-top:0px !important;
				padding-bottom:15px !important;
			}
			.meeting {
    			.icon-block {
    				background: #00bcd4;

    				&:before {
    					background: #00bcd4;
    					opacity: 0.16;
    				}
    			}
    		}
    		.docs, .share {
    			.icon-block {
    				background: #2196f3;
    				background-position:center center;

    				&:before {
    					background: #2196f3;
    					opacity: 0.16;
    				}
    			}
    		}
    		.mail {
    			.icon-block {
    				background: #00bcd4;

    				&:before {
    					background: #00bcd4;
    					opacity: 0.16;
    				}
    			}
    		}
    		.time {
    			.icon-block {
    				background: #ff5722;

    				&:before {
    					background: #ff5722;
    					opacity: 0.16;
    				}
    			}
    		}
    		.cases {
    			.icon-block {
    				background: #ff9800;

    				&:before {
    					background: #ff9800;
    					opacity: 0.16;
    				}
    			}
    		}
    		.ok {
    			.icon-block {
    				background: #ff9800;

    				&:before {
    					background: #ff9800;
    					opacity: 0.16;
    				}
    			}
    		}
		}
		.block-information{
			font-size: 16px;
			  font-weight: 600;
			  font-style: normal;
			  font-stretch: normal;
			  line-height: 1.88;
			  letter-spacing: normal;
			  text-align: left;
			  color:#999999;
			  padding-top:6px;
			  &:before{
			  	display:none;
			  }
			  .right_date{
			  	float:right;
			  	width:165px;
			  	text-align:right;
			  	font-size: 13px;
			  	color: #aaaaaa;
			  	font-weight:normal;
			  }
			  a{
			  	color:#000000;
			  	&:hover{
			  		color:#4caf50;
			  	}

				}
			  }
		.block-title {
			padding: 20px;
			border-bottom: 2px solid #f2f2f2;
			height: 60px;
			 .select-block {
		    	width:180px;
		    	float:right;
		    	margin-top:-13px;
		    	@media (max-width: 768px) {
		    		width:80px;
		    	}
		    }
			

		}
		&.chart-block {
			.block-content {
				padding: 30px;

				@media (max-width: 768px) {
					padding: 10px;
				}
			}

			.head {
				display: -ms-flexbox;
				display: -webkit-flex;
				display: flex;
				-webkit-flex-direction: row;
				-ms-flex-direction: row;
				flex-direction: row;
				-webkit-flex-wrap: nowrap;
				-ms-flex-wrap: nowrap;
				flex-wrap: nowrap;
				-webkit-justify-content: flex-start;
				-ms-flex-pack: start;
				justify-content: flex-start;
				-webkit-align-content: stretch;
				-ms-flex-line-pack: stretch;
				align-content: stretch;
				-webkit-align-items: flex-start;
				-ms-flex-align: start;
				align-items: flex-start;
				margin-bottom: 50px;

				@media (max-width: 768px) {
					flex-wrap: wrap;
					justify-content: center;
				}

				.legend-block {
					flex: 1 0 auto;
					display: -ms-flexbox;
					display: -webkit-flex;
					display: flex;
					-webkit-flex-direction: row;
					-ms-flex-direction: row;
					flex-direction: row;
					-webkit-flex-wrap: nowrap;
					-ms-flex-wrap: nowrap;
					flex-wrap: nowrap;
					-webkit-justify-content: flex-start;
					-ms-flex-pack: start;
					justify-content: flex-start;
					-webkit-align-content: stretch;
					-ms-flex-line-pack: stretch;
					align-content: stretch;
					-webkit-align-items: flex-start;
					-ms-flex-align: start;
					align-items: flex-start;

					@media (max-width: 768px) {
						flex: 1 100%;
						margin-bottom: 15px;
					}

					@media (max-width: 576px) {
						flex-wrap: wrap;
					}

					.block {
						display: table;
						height: 45px;
						vertical-align: middle;
						margin-left: 50px;

						@media (max-width: 1440px) {
							margin-left: 20px;
						}

						@media (max-width: 1280px) {
							margin-left: 10px;
						}

						@media (max-width: 768px) {
							flex: 1 auto;
							margin: 0;
						}

						@media (max-width: 576px) {
							flex: 1 50%;
							margin-bottom:10px;
							height:auto;
						}


						&.remaining {
							.title,
							.value {
								color: #ff9800;
							}
						}

						&.completed {
							.title,
							.value {
								color: #4caf50;
							}
						}

						&.overdue {
							.title,
							.value {
								color: #2196f3;
							}
						}

						&.deadlines {
							.title,
							.value {
								color: #ff5722;
							}
						}
						&.closed {
							.title,
							.value {
								color: #aaaaaa;
							}
						}

						&:first-child {
							margin-left: 0;
						}

						.title,
						.value {
							display: block;
							vertical-align: middle;
						}

						.value {
							font-size: 30px;
							line-height: 1em;
						}

						.title {
							font-size: 13px;
							font-weight: 400;
							color: #666666;
							line-height: 1em;
			    		// padding-left: 10px;

			    		b {
			    			display: block;
			    			font-weight: normal;
			    		}
			    	}
			    }
			}

			.select-block {
				flex: 0 180px;
				order: 1;

				@media (max-width: 768px) {
					flex: 1 100%;
					max-width: 180px;
				}
			}
		}

		.chart {
			position: relative;
			margin: auto;
			height: auto;
			// width: 57vw;
			// width: 46vw;

			@media (max-width: 576px) {
				width: 79vw;
			}
		}
	}
}
}
.contact-pagination{
	position:relative;
	border-top:none;
	left:auto;
	right:auto;
	bottom:auto;
	.page-link{
		&:hover{
			color:#4caf50;
		}
		
	}
}

}