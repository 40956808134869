.tag-dropdown {
	.select2-dropdown {
		-moz-box-shadow: 0 4px 10px 0 rgba(0,0,0,0.3);
		-webkit-box-shadow: 0 4px 10px 0 rgba(0,0,0,0.3);
		box-shadow: 0 4px 10px 0 rgba(0,0,0,0.3);
		border: 0;
		-webkit-border-radius: 15px;
		-moz-border-radius: 15px;
		border-radius: 15px;
	}

	.select2-results__options {
		-webkit-border-radius: 15px;
		-moz-border-radius: 15px;
		border-radius: 15px;
	}

	.select2-results__option[aria-selected=true] {
		background: #f7f7f7;
		color: #4caf50;
	}

	.select2-results__option--highlighted[aria-selected] {
		color: #4caf50;
		background: none;
	}
}