.user-settings{
	.profile-content{
		.table-heading{
			.similar-nav-block{
				ul{
					li{
						a{
							font-size:16px;
							color:#000000;
							white-space: nowrap;
							&:hover{
									color:#4caf50;
								}
						}
						&.active{
							a {
								color:#4caf50;
								&:hover{
									color:#4caf50;
								}
							}
						}

					}
				}
			}
		}
	}
	.ks-contacts{
		.main-tabs{
			.nav-item{
				.nav-link{
					&:hover,
		    		&.active {
		    			&:before {
		    				border-color:#666666;
		    			}
		    		}
				}
			}
		} 
	}
	.pagination{
		.page-link{
			&:hover{
				color:#4caf50;
				opacity:1;
			}
		}
	}
}




.user_settings_information{
	.case-name-cell{
		.avatar {
	    	width: 30px;
	    	float:left;
	    	margin-right:16px;

	    	img {
	    		width: 100%;
	    		-webkit-border-radius: 100px;
	    		-moz-border-radius: 100px;
	    		border-radius: 100px;
	    	}
	    }
	    .case-name{
	    	padding-top:5px;
	    } 			
	}
	.badge.badge-status{
		&.inactive{
			width:90px;
			height: 30px;
			line-height:30px;
			font-size:12px;
			color: #aaa !important;
			border-radius: 15px;
		}
		&.active{
			width:90px;
			height: 30px;
			line-height:30px;
			font-size:12px;
			background-color: #4caf50;
			color:#ffffff !important;
		}
	}
	.copy-text{
		color:#666666;
	}

	.edit_options{
		color:#000000 !important;
		font-size:12px;
		text-transform:uppercase;
		line-height:20px;
		font-weight: 600;
		padding-left:26px;
		position:relative;
		white-space:nowrap;
		background: url('../img/icons/@2x/icon-edit@2x.png') 0 0 no-repeat;
		background-size: auto 100%;
		 transition: all .3s;


	}
	.options-cell{
		.cell-td{
			opacity:0;
			transition: all .3s;

			&:hover{
				
				.edit_options{
					background: url('../img/@2x/icon-edit-green@2x.png') 0 0 no-repeat;
					background-size: auto 100%;
					color:#4caf50 !important;
				}
				.icon {
		  			background: url("../img/icons/icon-more--green.png") 0 0 no-repeat;
		  			-webkit-background-size: auto 100%;
		  			-moz-background-size: auto 100%;
		  			background-size: auto 100%;
		  		}
			}
			@media (max-width: 768px) {
				opacity:1;
			}

		}
		
	}
	tr{
		&:hover{
			.cell-td{
				opacity:1;
			}
			td{
				& > span {
					color:#000000 !important;
				}
			}
		}
	}

}