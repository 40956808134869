.custom-checkbox {
	position: relative;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  cursor: pointer;
  margin-bottom: 0;

  .custom-control-input {
  	position: absolute;
    z-index: -1;
    opacity: 0;
  }

  .custom-control-indicator {
    position: absolute;
    left: 0;
    display: block;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: 50% 50%;
    -moz-background-size: 50% 50%;
    background-size: 50% 50%;

    top: 0;
    background-color: #fff;
    width: 18px;
    height: 18px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    border: solid 1px #ccc;
    font-size: 12px;
    color: #fff;
    -webkit-transition: all .3s;
       -moz-transition: all .3s;
        -ms-transition: all .3s;
         -o-transition: all .3s;
            transition: all .3s;
  }

  .custom-control-description {
  	margin-left: 5px;
  }

  & > .custom-control-input:checked~.custom-control-indicator,
  &:hover>.custom-control-input:not(:disabled)~.custom-control-indicator {
  	border-color: #4caf50;
  }

  & > .custom-control-input~.custom-control-indicator:before {
  	background-color: transparent;
    color: #25628f;
    background: url('../img/icons/@2x/checkbox-checked@2x.png') 0 0 no-repeat;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    width: 17px;
    height: 16px;
    content: "";
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    line-height: 16px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .3s;
       -moz-transition: all .3s;
        -ms-transition: all .3s;
         -o-transition: all .3s;
            transition: all .3s;
  }

  & > .custom-control-input:checked~.custom-control-indicator:before {
    opacity: 1;
    visibility: visible;
  }
}

.custom-search-block {
	position: relative;

	input.form-control {
    float: none;
		border: 0;
		height: 44px;
		font-size: 12px;
		padding: 10px 10px 10px 14px;
		-webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
		position: relative;
		-webkit-box-shadow: 0;
		-moz-box-shadow: 0;
		box-shadow: 0;
    font-family: $font-mont;
		-webkit-transition: all .3s;
		   -moz-transition: all .3s;
		    -ms-transition: all .3s;
		     -o-transition: all .3s;
		        transition: all .3s;

		&:focus {
			z-index: 10;
			border: 0;
			-webkit-box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.1);
			-moz-box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.1);
			box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.1);
			-webkit-border-radius: 15px;
			-moz-border-radius: 15px;
			border-radius: 15px;
		}
	}

&.custom-user-search{
    
     border: solid 1px #e6e6e6;
     border-radius: 15px;
 
  }			   
	.icon-addon {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    padding-right: 6px;
    color: rgba(58,82,155,.6);
    font-size: 12px;
    width: 36px;
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: #fff;
    border: 0;
    cursor: pointer;
    z-index: 10;
    -webkit-border-radius: 0 15px 15px 0;
    -moz-border-radius: 0 15px 15px 0;
    border-radius: 0 15px 15px 0;
    -webkit-transition: all .3s;
       -moz-transition: all .3s;
        -ms-transition: all .3s;
         -o-transition: all .3s;
            transition: all .3s;
    
    &:focus {
      border: 0;
      outline: 0;
    }
    &:disabled {
      cursor: default;

      .icon {
        opacity: 0.4;
      }
    }

    &:hover {
    	.icon {
    		opacity: 1;
    	}

      &:disabled {
        cursor: default;
        
        .icon {
          opacity: 0.4;
        }
      }
    }

    .icon {
    	opacity: 0.4;
    	-webkit-transition: all .3s;
       -moz-transition: all .3s;
        -ms-transition: all .3s;
         -o-transition: all .3s;
            transition: all .3s;
    }
	}

  .select2-container--default .select2-selection--single {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #A6A9A9;
    font-size: 12px;
  }
  &.custom-user-select{
    .select2-container--default .select2-selection--single {
      -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
      -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
    }
  }					   

  &.custom-datepicker, &.custom-datepicker2 {
    .datepicker-title {
      position: relative;

      .title {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding-left: 40px;
        color: #A6A9A9;
        z-index: 2;
        font-weight: 400;
        font-size: 12px;
        background: #fff;
        cursor: pointer;
        -webkit-transition: all .3s;
           -moz-transition: all .3s;
            -ms-transition: all .3s;
             -o-transition: all .3s;
                transition: all .3s;

        &.title_border{
          border: solid 1px #e6e6e6;
          border-radius: 15px;
        }			   
        &:hover {
          z-index: 10;
          box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.1);
          border-radius: 15px;
        }

        .ks-text {
          line-height: 45px;
        }

        .icon {
          left: 14px;
          margin-left: 0;
          opacity: 0.3;
        }
      }
    }
  }
}

.tippy-tooltip {
	background: rgba(0, 0, 0, 0.8);
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
	// padding: 10px 0;

  .inline {
    display: block;
    // padding: 5px 10px;
    cursor: pointer;

    .open-popover {
      position: relative;
      padding: 5px 15px;
      margin: 0 -5px;
    }
  }

	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		text-align: left;

		li {
			margin-top: 7px;

			&:first-child {
				margin-top: 0;
			}

      span {
        display: block;
        padding: 3px;
      }

			a {
				display: block;
				opacity: 0.7;
				text-transform: uppercase;
				font-weight: 600;
				padding: 5px 10px;
				background: transparent;

				&:hover {
					opacity: 1;
					background: rgba(0,0,0,0.3);
				}
			}
		}
	}

	&.action-tooltip-theme {
		width: 180px;
	}

  &.person-tooltip-theme {
    border-radius: 9px;
  }

	&.event-tooltip-theme,
  &.event-tooltip-cases-theme {
    left: 8px;
    width: 220px;

    ul {
      text-align: left;

      a {
        position: relative;
        padding-left: 30px;
        text-align: left !important;

        .icon {
          left: 7px;
          margin-left: 0;
        }
      }
    }
  }

  &.case-event-tooltip-theme {
    ul {
      text-align: left;

      a {
        position: relative;
        padding-left: 30px;
        text-align: left !important;

        .icon {
          left: 7px;
          margin-left: 0;
        }
      }
    }
  }

	&.copied-theme {
		margin-left: 10px;
    padding: 10px;
	}

  &.phone-tooltip-theme {
    padding: 7px 0;
    top: 48px;

    .tippy-arrow {
      margin-top: -83px;
    }

    ul {
      text-align: left;

      a {
        position: relative;
        padding-left: 30px;

        .icon {
          left: 7px;
          margin-left: 0;
        }
      }
    }
  }

  &.edit-role-theme {
    padding: 15px 20px;
  }
}

.tippy-popper[x-placement^=top] .action-popover-theme .tippy-arrow,
.tippy-popper[x-placement^=bottom] .action-popover-theme .tippy-arrow,
.tippy-popper[x-placement^=bottom] .action-tooltip-theme .tippy-arrow {
  left: auto !important;
  right: 41px;

  @media (max-width: 1225px) {
  	left: 58px !important;
  	right: auto;
  }
}

.tippy-popper[x-placement^=bottom] .event-tooltip-cases-theme .tippy-arrow,
.tippy-popper[x-placement^=bottom] .event-tooltip-theme .tippy-arrow,
.tippy-popper[x-placement^=top] .event-tooltip-cases-theme .tippy-arrow,
.tippy-popper[x-placement^=top] .event-tooltip-theme .tippy-arrow {
  left: auto !important;
  right: 10px;
}

.tippy-popper {
  transition: visibility .3s, opacity .3s;
  z-index: 999999999 !important;
}

.dropdown-tippy-theme {
  background: #fff;
  box-shadow: 0 6px 20px 0px rgba(0,0,0,0.25);
  transform: translateY(0) !important;
  // width: 100%;
  // width: 155px;

  .icon {
    left: 10px !important;
    margin-left: 0;
    -webkit-transition: all .3s;
       -moz-transition: all .3s;
        -ms-transition: all .3s;
         -o-transition: all .3s;
            transition: all .3s;
  }

  .popover-content {
    &.email {
      .open-popover {
        padding: 5px 20px 5px 5px !important;
      }
    }
  }

  .open-popover {
    padding: 5px 20px 5px 30px !important;
    text-align: left;


    &.tippy-active,
    &:hover {
      .copy-text {
        color: #4caf50;
      }

      .icon {
        &.icon-phone {
          background: url('../img/icons/@2x/icon-phone--green@2x.png') 0 0 no-repeat;
          -webkit-background-size: 100% auto;
          -moz-background-size: 100% auto;
          background-size: 100% auto;
        }

        &.icon-mobile {
          background: url('../img/icons/@2x/icon-mobile--green@2x.png') 0 0 no-repeat;
          -webkit-background-size: auto 100%;
          -moz-background-size: auto 100%;
          background-size: auto 100%;
        }
      }
    }
    .copy-text {
      color: #000;
      padding: 0;
      -webkit-transition: all .3s;
         -moz-transition: all .3s;
          -ms-transition: all .3s;
           -o-transition: all .3s;
              transition: all .3s;
    }
  }
  .tippy-backdrop {
    background: #fff;
    transition: all .3s !important;
  }
}
.tippy-tooltip[data-animatefill] .tippy-content {
  transition: all .3s;
}

body:not(.tippy-touch) .tippy-tooltip[data-animatefill][data-state=hidden] .tippy-content,
body:not(.tippy-touch) .tippy-tooltip[data-animatefill][data-state=hidden] .tippy-content {
  clip-path: none;
}

.popover-content {
  &.more-area {
    ul {
      text-align: center;
    }
  }
}

.contact-pagination {
	position: absolute;
	bottom: 30px;
	left: 30px;
	right: 30px;
	border-top: 1px solid #e6e6e6;
  background: #fff;
  z-index: 2;
  height: 54px;
  border-radius: 0 0 15px 15px;

  .load-more-btn {
    position: relative;
    background: #4caf50;
    padding: 10px 20px;
    border: 1px solid #4caf50;
    margin: 5px auto;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    -moz-box-shadow: 0 3px 20px -2px rgba(0,0,0,0.3);
    -webkit-box-shadow: 0 3px 20px -2px rgba(0,0,0,0.3);
    box-shadow: 0 3px 20px -2px rgba(0,0,0,0.3);
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;

    @media (max-width: 768px) {
      padding: 0 10px;
      font-size: 12px;
    }

    &:hover {
      opacity: .8;
    }

    &.disabled {
      &:hover {
        color: #fff;
        background: #4caf50;
        cursor: default;
        opacity: .65;
      }
    }
  }

}

.pagination {
	margin: 0;
	height: 53px;

  @media (max-width: 768px) {
    height: 45px;
  }

  .page-item {
    &.disabled {
      .page-link {
        .icon {
          opacity: .4;
        }
      }
    }
  }

	.page-link {
		border: 0;
		height: 53px;
		display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #000;
    background: none;
    font-weight: 600;
    opacity: 1;
    text-transform: uppercase;
    -webkit-transition: all .3s;
       -moz-transition: all .3s;
        -ms-transition: all .3s;
         -o-transition: all .3s;
            transition: all .3s;

    @media (max-width: 768px) {
      height: 45px;
      padding: 0 5px;
    }

    &:hover,
    &.active {
    	opacity: .5;
    }

    &.text-back {
    	padding: 7px 7px 7px 20px;


    	.icon {
    		left: 5px;
    		margin-left: 0;
    	}
    }

    &.text-next {
    	padding: 7px 20px 7px 7px;

    	.icon {
    		left: auto;
    		right: 5px;
    		margin-left: 0;
    	}
    }
	}

  .page-item {
  	margin: 0 10px;

    @media (max-width: 768px) {
      font-size: 12px;
      margin: 0 2px;
    }
  }
}

.ks-mobile-overlay {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  left: 0;
  top: 60px;
  width: 100%;
  height: 100%;
  z-index: 3;
  background: rgba(0,0,0,.3);
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s;

  &.ks-open {
    opacity: 1;
    visibility: visible;
  }
}

#page-overlay {
  background: #ffffff;
  color: #666666;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 5000;
  top: 0;
  left: 0;
  float: left;
  text-align: center;
  padding-top: 25%;
}

.ks-map {
  position: fixed;
  top: 150px;
  width: 100%;
  left: 155%;
  height: 100%;
  background: #fff;
  border-left: solid 1px rgba(57,81,155,.2);
  z-index: 10;
  -webkit-transition: .4s ease;
  -moz-transition: .4s ease;
  transition: .4s ease;

  @media (max-width: 992px) {
    top: 135px;
  }

  @media (max-width: 768px) {
    top: 105px;
  }

  &.ks-open {
    left: 45px;
  }

  .ks-show-map-btn {
    cursor: pointer;
    position: absolute;
    font-size: 21px;
    padding: 8px 10px;
    top: 5px;
    width: 34px;
    height: 45px;
    background: #25628f;
    border: solid 1px #25628f;
    -webkit-border-radius: 15px 0 0 15px;
    -moz-border-radius: 15px 0 0 15px;
    border-radius: 15px 0 0 15px;
    left: -35px;
    border-right: none;
    color: #fff;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;

    .icon {
      background: url(../img/icons/@2x/angle-right--white@2x.png) 0 0 no-repeat;
      -webkit-background-size: auto 100%;
      -moz-background-size: auto 100%;
      background-size: auto 100%;
    }
  }
}


.daterangepicker {
  margin-top: -45px;
  background: transparent;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  -webkit-box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.1);
  border: 0;
  margin-left: 2px;
  padding: 0;
  width: 160px;

  @media (max-width: 1400px) {
    margin-top: 0;
    background: #fff;
    overflow: hidden;
  }

  @media (max-width: 992px) {
    margin-top: 0;
    margin-left: 0;
    background: #fff;
    width: 170px;
    border: 1px solid rgba(0,0,0,.15);
    padding: 4px;
    margin-right: 0;
  }

  &.show-calendar {
    width: 637px;
    @media (max-width: 992px) {
      width: 278px;
    }
  }
  
  &.opensright {
    margin-right: 30px;

    @media (max-width: 768px) {
      margin-right: 0;
    }
  }

  &.opensleft {
    .ranges {
      padding: 0 15px 15px 0;

      ul {
        text-align: right;
      }

      .range_inputs {
        padding-right: 0;
        padding-left: 15px;
      }

      &:before {
        right: auto;
        left: 0;
        width: 35px;
      }

      &:after {
        left: 0;
        right: auto;
      }

      li[data-range-key="Custom Range"]:after {
        right: auto;
        left: 10px;
        -webkit-transform: rotate(180deg);
           -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
             -o-transform: rotate(180deg);
                transform: rotate(180deg);
      }
    }
  }

  &:after,
  &:before {
    opacity: 0;
    visibility: hidden;

    @media (max-width: 992px) {
      // opacity: 1;
      // visibility: visible;
      right: 50% !important;
      margin-right: -6px;
    }
  }

  &:after {
    @media (max-width: 992px) {
      margin-right: -5px;
    }
  }

  .ranges {
    margin-top: 45px;
    border-top: 2px solid #f3f3f3;
    background: #fff;
    margin: 45px 0 0;
    position: relative;
    padding: 0 0 15px 15px;
    -webkit-border-radius: 0 0 15px 15px;
    -moz-border-radius: 0 0 15px 15px;
    border-radius: 0 0 15px 15px;

    @media (max-width: 1400px) {
      margin-top: 0;
      border-top: 0;
    }

    @media (max-width: 992px) {
      margin-top: 0;
      width: 100%;
      float: left;
    }

    &:after {
      position: absolute;
      top: -45px;
      right: 0;
      bottom: 0;
      content: "";
      border-right: 2px solid #f3f3f3;
      @media (max-width: 1400px) {
        top: 0;
        bottom: -200px;
      }

      @media (max-width: 992px) {
        display: none;
      }
    }

    &:before {
      position: absolute;
      top: -45px;
      right: 0;
      width: 50px;
      background: #fff;
      height: 43px;
      content: "";

      @media (max-width: 1400px) {
        display: none;
      }

      @media (max-width: 992px) {
        display: none;
      }
    }

    ul {
      width: 145px;
      margin: 5px 0;

      @media (max-width: 992px) {
        width: 100%;
      }
    }

    li {
      background: none;
      border: 0;
      color: #333;
      margin: 0 0 1.5px;
      font-weight: 600;
      padding: 5px 0;
      -webkit-transition: all .3s;
         -moz-transition: all .3s;
          -ms-transition: all .3s;
           -o-transition: all .3s;
              transition: all .3s;

      @media (max-width: 992px) {
        padding: 2.5px 0;
      }
      
      &:hover,
      &.active {
        background: #f7fff8;
        color: #4caf50;
      }

      &[data-range-key="Custom Range"] {
        position: relative;

        &:after {
          position: absolute;
          top: 50%;
          right: 10px;
          content: '';
          background: url('../img/icons/@2x/angle-right@2x.png') 0 0 no-repeat;
          -webkit-background-size: auto 100%;
          -moz-background-size: auto 100%;
          background-size: auto 100%;
          width: 6px;
          height: 9px;
          margin-top: -4.5px;
          opacity: 0.3;
        }
      }
    }

    .range_inputs {
      padding-right: 15px;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-content: center;
      -ms-flex-line-pack: center;
      align-content: center;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;

      .btn {
        text-transform: uppercase;
        -webkit-flex: 1 1 100%;
        -ms-flex: 1 1 100%;
        flex: 1 1 100%;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;
        margin-left: 5px;
        font-weight: 600;
        border-radius: 10px;

        &.cancelBtn {
          background: none;
          color: #000
        }

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  .calendar {
    background: #fff;
    margin: 0;
    position: relative;
    top: 1px;
    padding-bottom: 19px;

    @media (max-width: 992px) {
      width: 100%;
    }

    &.left {
      -webit-border-radius: 15px 0 0 15px;
      -moz-border-radius: 15px 0 0 15px;
      border-radius: 15px 0 0 15px;

      .daterangepicker_input {
        position: relative;

        &:after {
          position: absolute;
          top: 5px;
          bottom: 5px;
          right: 5px;
          border-right: 2px solid #f2f2f2;
          content: "";

          @media (max-width: 992px) {
            display: none;
          }
        }
      }

      .input-mini {
        -webit-border-radius: 15px 0 0 0;
        -moz-border-radius: 15px 0 0 0;
        border-radius: 15px 0 0 0;
      }
    }

    &.right {
      -webit-border-radius: 0 15px 15px 0;
      -moz-border-radius: 0 15px 15px 0;
      border-radius: 0 15px 15px 0;

      .input-mini {
        -webit-border-radius: 0 15px 0 0;
        -moz-border-radius: 0 15px 0 0;
        border-radius: 0 15px 0 0;
      }
    }
  }

  .input-mini {
    border: 0 none !important;
    text-align: right;
    height: 44px;
    margin: 0;
    padding: 0 14px;
  }

  .daterangepicker_input {
    border-bottom: 2px solid #f3f3f3;

    input[name="daterangepicker_start"] ~ .fa {
      &:after {
        position: absolute;
        top: 10px;
        left: 5px;
        content: "From";
        font-size: 12px;
        color: #A6A9A9;
        line-height: 1em;
        font-style: normal;
        font-family: "Montserrat", sans-serif;
      }

      &:before {
        display: none;
      }
    }

    input[name="daterangepicker_end"] ~ .fa {
      &:after {
        position: absolute;
        top: 10px;
        left: 5px;
        content: "To";
        font-size: 12px;
        color: #A6A9A9;
        line-height: 1em;
        font-style: normal;
        font-family: "Montserrat", sans-serif;
      }

      &:before {
        display: none;
      }
    }
  }

  .calendar-table {
    // padding-top: 20px;

    .prev {
      background: url(../img/icons/@2x/angle-left@2x.png) 50% 50% no-repeat;
      -webkit-background-size: 7px;
      -moz-background-size: 7px;
      background-size: 7px;
      opacity: 0.3;
    }

    .next {
      background: url(../img/icons/@2x/angle-right@2x.png) 50% 50% no-repeat;
      -webkit-background-size: 7px;
      -moz-background-size: 7px;
      background-size: 7px;
      opacity: 0.3;
    }
  }

  th.month {
    margin-bottom: 10px;
  }

  thead {
    tr {
      &:nth-child(2) {
        th {
          padding: 5px 0;
          color: #cccccc;

          @media (max-width: 992px) {
            padding: 0;
          }
        }
      }
    }
  }

  td.available {
    font-size: 13px;
  }

  

  td.off {
    background: #fff;
  }

  td,
  th {
    width: 30px;
    height: 30px;
    font-size: 13px;

    @media (max-width: 992px) {
      width: 20px;
      height: 20px;
    }
  }

  td.in-range {
    background: #f7fff8;
  }

  td.active {
    background: #4caf50;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;

    &:hover {
      background: #4caf50;
    }
  }
}

.badge {
  &.badge-status {
    display: inline-block;
    height: 30px;
    padding: 5px 0;
    width: 75px;
    background: #eee;
    border: 1px solid #eee;
    color: #aaa !important;
    text-transform: uppercase;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;

    &.open {
      background: #fff;
      color: #4caf50 !important;
    }
  }
}


.hide-sidebar {
  .loading {
    &:before {
      margin-left: 0;
    }
  }
}

.loading {
  &:before {
    position: fixed;
    top: 50%;
    left: 50%;
    content: "\f110";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #000;
    font-size: 30px;
    -webkit-animation: fa-spin 2s infinite linear;
    -moz-animation: fa-spin 2s infinite linear;
    animation: fa-spin 2s infinite linear;
    z-index: 4;
    -webkit-transition: all .3s;
       -moz-transition: all .3s;
        -ms-transition: all .3s;
         -o-transition: all .3s;
            transition: all .3s;
  }

  &:after {
    position: fixed;
    background: rgba(255,255,255,0.7);
    top: 107px;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    z-index: 3;
  }
}

.loading-btn {
  padding-left: 30px !important;
  &:before {
    position: absolute;
    top: 50%;
    left: 6px !important;
    content: "\f110" !important;
    font-family: FontAwesome;
    margin: 0 !important;
    padding: 0 !important;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    background: none !important;
    color: #fff;
    font-size: 20px;
    width: auto !important;
    height: auto !important;
    margin-top: -15px !important;
    -webkit-animation: fa-spin 2s infinite linear;
    -moz-animation: fa-spin 2s infinite linear;
    animation: fa-spin 2s infinite linear;
    z-index: 4;
    -webkit-transition: all .3s;
       -moz-transition: all .3s;
        -ms-transition: all .3s;
         -o-transition: all .3s;
            transition: all .3s;
  }
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.custom-scroll-table.year,
.filter-block.datepicker,
.page-profile-info {
  .datepicker {
    width: 100%;

    &.datepicker-orient-bottom {
      width: inherit;
      box-shadow: 0 6px 20px 0px rgba(0,0,0,0.25);
    }

    td,
    th {
      background: none !important;
      &.dow {
        padding: 5px 0;
        color: #cccccc !important;
      }

      &.active {
        background: #ff5722 !important;
        color: #fff !important;
      }

      &.today {
        background: #ffede7 !important;
        color: #ff5722 !important;

        &:hover {
          color: #fff !important;        
        }
      }

      &.day {
        &:hover {
          background: #ff5722 !important;
          color: #fff;
        }
      }
    }
  }
}
.datepicker {
  border-radius: 15px;
  &:after,
  &:before {
    display: none;
  }
  table {
    width: 100%;
  }

  .prev {
    position: relative;
    color: #fff;

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -5px;
      margin-left: -3.5px;
      content: "";
      width: 7px;
      height: 10px;
      background: url(../img/icons/@2x/angle-left@2x.png) 50% 50% no-repeat;
      -webkit-background-size: auto 100%;
      -moz-background-size: auto 100%;
      background-size: auto 100%;
      opacity: 0.3;
      z-index: 2;
    }
  }

  .next {
    position: relative;
    color: #fff;

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -5px;
      margin-left: -3.5px;
      content: "";
      width: 7px;
      height: 10px;
      background: url(../img/icons/@2x/angle-right@2x.png) 50% 50% no-repeat;
      -webkit-background-size: auto 100%;
      -moz-background-size: auto 100%;
      background-size: auto 100%;
      opacity: 0.3;
      z-index: 2;
    }
  }

  td {
    span {
      &.focused {
        background: #ffede7 !important;
        color: #ff5722 !important;

        &:hover {
          color: #fff !important;        
        }
      }

      &.active {
        background: #ff5722 !important;
        color: #fff !important
      }

      &:hover {
        background: #ff5722 !important;
        color: #fff;
      }
    }
  }

  td,
  th {
    width: 30px;
    height: 28px;
    padding: 0;

    &.dow {
      padding: 5px 0;
      color: #cccccc;
    }

    &.active {
      background: #4caf50 !important;
      color: #fff !important;
    }

    &.today {
      background: #f2fff3 !important;
      color: #4caf50 !important;

      &:hover {
        color: #fff !important;        
      }
    }

    &.day {
      &:hover {
        background: #4caf50 !important;
        color: #fff;
      }
    }
  }

  td {
    font-size: 13px;
  }
}


.timepicker-block {
  @media (max-width: 768px) {
    margin-top: 20px;
  }
  .timepicker-wrapper {
    padding: 4px;
  }
  .timepicker-titles {
    margin-bottom: 35px;

    .title {
      width: 50%;
      display: inline-block;
      font-size: 13px;
      color: #000;
      text-align: center;
      float: left;
      line-height: 30px;
      font-weight: 700;
    }
  }
  .timepicker-inner {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    height: 150px;
    overflow: hidden;

    .hours-block {
      .simplebar-track,
      .mCSB_scrollTools {
        right: auto;
        left: 0;
      }
    }

    .simplebar-track.horizontal {
      display: none;
    }

    .mCSB_scrollTools .mCSB_draggerRail {
      background: #e4e4e4;
    }

    .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
      width: 2px;
      background: #ff5722;
    }

    .simplebar-track {
      width: 2px;
      border-radius: 10px;
      background: #e4e4e4;
      cursor: pointer;
      -webkit-transition: width .3s;
         -moz-transition: width .3s;
          -ms-transition: width .3s;
           -o-transition: width .3s;
              transition: width .3s;
    }

    .simplebar-scrollbar {
      width: 2px;
      background: #ff5722;
      right: auto;
      opacity: 1;
      -webkit-transition: width .3s;
         -moz-transition: width .3s;
          -ms-transition: width .3s;
           -o-transition: width .3s;
              transition: width .3s;
    }

    .inner-block {
      -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      -webkit-align-self: stretch;
      -ms-flex-item-align: stretch;
      align-self: stretch;

      &:hover {
        .simplebar-track {
          width: 6px;

          .simplebar-scrollbar {
            width: 6px;
          }
        }
      }

      &>div {
        text-align: center;
      }

      .value {
        color: #333;
        display: inline-block;
        text-align: center;
        font-size: 13px;
        height: 30px;
        line-height: 30px;
        width: 30px;
        border-radius: 5px;

        &:hover,
        &.active {
          background: #ff5722;
          color: #fff;
        }
      }
    }
  }
}

.map_tooltip {
  max-width: 250px;
  text-align: center;

  a.contact_title {
    color: black;
    font-weight: 600;
  }

  p {
    color: black;
  }
  img {
    display: block;
    max-width: 50px;
    margin: 10px auto;
  }
  a.map_tooltip_btn {
    position: relative;
    background: #4caf50;
    padding: 10px 20px;
    border: 1px solid #4caf50;
    margin: 5px auto;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    -webkit-border-radius: 15px;
    border-radius: 15px;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -webkit-box;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
  }
}

.tippy-tooltip {
  .popover-content {

    display: block;
  }
}

.popover-content {
  display: none;
}

.breadcrumb {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0;
  margin: 0;
  background: none;
  border-radius: 0;

  .breadcrumb-item {
    position: relative;
    padding-left: 40px;
    padding-right: 15px;
    color: #000;
    margin-left: 3px;
    cursor: pointer;

    &:hover {
      a {
        opacity: 1;
      }
    }

    &:first-child {
      &:before {
        display: none;
      }
    }

    &:before {
      position: absolute;
      left: -3px;
      top: 50%;
      content: "";
      padding: 0;
      background: url(../img/icons/@2x/angle-right@2x.png) 0 0 no-repeat;
      width: 4px;
      height: 6px;
      background-size: auto 100%;
      margin-top: -3px;
      margin-left: -2px;
      opacity: .4;
    }

    a {
      opacity: .5;
    }

    .icon {
      left: 15px;
      margin-left: 0;
    }

    .ks-text {
      color: #000;
      font-weight: 600;
    }

    &.ks-active {
      .icon {
        background: url(../img/icons/@2x/icon-folder--green@2x.png) 0 0 no-repeat;
        width: 16px;
        height: 15px;
        -webkit-background-size: auto 100%;
        background-size: auto 100%;
      }
      .ks-text {
        color: #4caf50;
      }
    }
  }
}

table.scroll {
  width: 100%;
  border-spacing: 0;
}

// table.scroll thead,
// table.scroll tbody { display: block; }

// table.scroll tr {
//   display: flex;
// }

table.scroll th,
table.scroll td,
table.scroll tr,
table.scroll thead,
table.scroll tbody { display: block; }

// table.scroll tbody tr,
table.scroll thead tr {
  /* fallback */
  width: 100%;
  /* minus scroll bar width */
  // width: -webkit-calc(100% - 17px);
  // width:    -moz-calc(100% - 17px);
  // width:         calc(100% - 17px);
}

table.scroll tr:after {
  content: ' ';
  display: block;
  visibility: hidden;
  clear: both;
}

table.scroll tbody {
  height: 550px;
  overflow-y: auto;
  overflow-x: hidden;
}

table.scroll tbody td,
table.scroll thead th {
  width: 13.28%;
  float: left;
  // flex: 1;

  &:first-child {
    // flex: 0 75px;w
    width: 7%;
  }
}

table.scroll tbody td {
  height: 44px;
}

table.scroll thead th {
  height: 60px;
}

thead tr th { 
  // height: 30px;
  // line-height: 30px;
  /*text-align: left;*/
}
tbody td:last-child, thead th:last-child {
  border-right: none !important;
}

.custom-file {
  position: relative;
  display: inline-block;
  max-width: 100%;
  height: 2.5rem;
  margin-bottom: 0;
  cursor: pointer;

  .custom-file-input {
    min-width: 14rem;
    max-width: 100%;
    height: 2.5rem;
    margin: 0;
    filter: alpha(opacity=0);
    opacity: 0;
  }

  .custom-file-control {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
    height: 2.5rem;
    padding: .5rem 1rem;
    line-height: 1.5;
    color: #464a4c;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;

    &:before {
      position: absolute;
      top: -1px;
      right: -1px;
      bottom: -1px;
      z-index: 6;
      display: block;
      height: 2.5rem;
      padding: .5rem 1rem;
      line-height: 1.5;
      color: #464a4c;
      background-color: #eceeef;
      border: 1px solid rgba(0,0,0,.15);
      border-radius: 0 .25rem .25rem 0;
      content: "Browse";
    }

    &:after {
      content: "Choose a file...";
    }
  }
}

@keyframes playAnimation {
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: -1350px 0px;
  }
}

@keyframes playAnimationMobile {
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: -900px 0px;
  }
}



.play-btn {
  opacity: 1 !important;
  outline: 0 !important;
  box-shadow: none !important;

  @media (max-width: 768px) {
    height: 42px;
  }

  &:hover {
    .play-icon {
      animation: none;
      background-color: rgba(88,187,92, 1);
      cursor: pointer;
      animation: pulse 2s infinite;
    }
  }

  .play-icon {
    display: inline-block;
    width: 45px;
    height: 45px;
    background: url(../img/animation/play-btn_spritesheet.svg) 0 0 no-repeat;
    cursor: pointer;
    box-shadow: 0 0 0 rgba(76,175,80, 0.6);
    border-radius: 50px;
    transition: all 0.2s ease-in;
    background-size: auto 100%;

    @media (max-width: 768px) {
      width: 30px;
      height: 30px;
    }
  }
}

.is-playing {
  .play-btn {
    .play-icon {
      animation-name: playAnimation;
      animation-duration: 1000ms;
      animation-timing-function: steps(30);
      animation-fill-mode: forwards;
      animation-direction: alternate;
      animation-iteration-count: 1; 

      @media (max-width: 768px) {
        animation-timing-function: steps(30);
        animation-name: playAnimationMobile;
      }
    }

    &:hover {
      .play-icon {
        cursor: pointer;
        animation: pulse-playing 2s infinite, playAnimation none;
        // background-position: 100% 0;
        background: url(../img/animation/play-btn_spritesheet.svg) 100% 0 no-repeat;
      }
    }
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(76,175,80, 0.6);
  }
  70% {
      -webkit-box-shadow: 0 0 0 15px rgba(76,175,80, 0.6);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(76,175,80, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(76,175,80, 0.6);
    box-shadow: 0 0 0 0 rgba(76,175,80, 0.6);
  }
  70% {
      -moz-box-shadow: 0 0 0 15px rgba(76,175,80, 0);
      box-shadow: 0 0 0 15px rgba(76,175,80, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(76,175,80, 0);
      box-shadow: 0 0 0 0 rgba(76,175,80, 0);
  }
}

@-webkit-keyframes pulse-playing {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 87, 34, 0.6);
  }
  70% {
      -webkit-box-shadow: 0 0 0 15px rgba(255, 87, 34, 0.6);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(255, 87, 34, 0);
  }
}
@keyframes pulse-playing {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 87, 34, 0.6);
    box-shadow: 0 0 0 0 rgba(255, 87, 34, 0.6);
  }
  70% {
      -moz-box-shadow: 0 0 0 15px rgba(255, 87, 34, 0);
      box-shadow: 0 0 0 15px rgba(255, 87, 34, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(255, 87, 34, 0);
      box-shadow: 0 0 0 0 rgba(255, 87, 34, 0);
  }
}

.select2-container--default .select2-results>.select2-results__options {
  &::-webkit-scrollbar {
    width: 10px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0,0,0,0.1);
    border: 1px solid #ccc;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: linear-gradient(to left, #fff, #e4e4e4);
    border: 1px solid #aaa;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }
}

.note-editor {
  border-radius: 15px;

  &.note-frame {
    border: 1px solid #e6e6e6;

    .note-statusbar {
      margin: 0 9px;
      background: #fff;
    }
  }

  .note-toolbar {
    border-radius: 15px 15px 0 0;
    background: #fff;
  }

  .btn {
    background: #fff;

    &:hover {
      background: #f8f9fa;
    }
  }

  .note-editing-area {
    border-radius: 0 0 15px 15px;
  }

  .note-toolbar-wrapper {
    height: auto !important;
  }
}

.attachment-block {
  .inner-block {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -webkit-box;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
  }

  .btn-file {
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    position: relative;
    padding: 12px 60px 12px 40px;
    text-align: center;
    background: none;
    -webkit-border-radius: 15px;
    border-radius: 15px;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -webkit-box;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    font-size: 13px;
    font-weight: 600;
    -webkit-box-shadow: 0 2px 2px rgba(0,0,0,0.2);
    box-shadow: 0 2px 2px rgba(0,0,0,0.2);
    border: 1px solid #F3F3F3;
    margin-right: 15px;
    margin-bottom: 10px;

    &:hover {
      color: #4caf50;

      .icon-right {
        background: url(../img/icons/@2x/icon-clouddown--green@2x.png) 0 0 no-repeat;
        background-size: auto 100%;
      }
    }

    .icon-left {
      left: 15px;
      margin-left: 0;
    }

    .icon-right {
      right: 15px;
      left: auto;
      margin-left: 0;
      -webkit-transition: all .3s;
         -moz-transition: all .3s;
          -ms-transition: all .3s;
           -o-transition: all .3s;
              transition: all .3s;
    }

    &:first-child {
      margin-left: 0;
    }
  }
}

.attach-btn {
  color: #3aa2c9;
  padding-right: 25px;
  font-size: 12px;
  float: left;
  margin: 0;
  position: relative;
  padding: 0 10px 0 30px;

  .icon {
    left: 7px;
    margin-left: 0;

    .icon {
      left: 7px;
      margin-left: 0;
    }
  }

  .ks-text {
    line-height: 30px;
    text-transform: uppercase;
    font-size: 12px;
    color: #5b5b5b;
    display: block;
    font-weight: 600;
  }
}

// .custom-tbody-scrollable {
//   width: 100%; /* 140px * 5 column + 16px scrollbar width */
//   border-spacing: 0;
//   border: 2px solid black;

//   tbody,
//   thead {
//     tr { display: block; }
//   }

//   tbody {
//     height: 300px;
//     overflow-y: auto;
//     overflow-x: hidden;
//   }

//   tbody td,
//   thead th {
//     width: 20%;
//     border-right: 1px solid black;
//   }

//   thead th:last-child {
//     width: calc(20% - 16px); /* 140px + 16px scrollbar width */
//   }

//   thead tr th { 
//     // line-height: 30px;
//     /*text-align: left;*/
//   }


//   tbody td:last-child, thead th:last-child {
//     border-right: none !important;
//   }
// }

table.custom-tbody-scrollable {
  width: 100%;
  border-spacing: 0;
}

table.custom-tbody-scrollable::-webkit-scrollbar-track {
  border-radius: 0;
  box-shadow: none;
  background-color: rgba(153, 153, 153, 0.2);
  border: none;
  transition: all .3s;
}

table.custom-tbody-scrollable th,
table.custom-tbody-scrollable td,
table.custom-tbody-scrollable tr,
table.custom-tbody-scrollable thead,
table.custom-tbody-scrollable tbody { display: block; }

table.custom-tbody-scrollable thead tr {
  width: 99.404163%;
}

table.custom-tbody-scrollable tr:after {
  content: ' ';
  display: block;
  visibility: hidden;
  clear: both;
}

table.custom-tbody-scrollable tbody {
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: 15px;
}

table.custom-tbody-scrollable tbody tr {
  margin-right: -15px;
}

table.custom-tbody-scrollable tbody::-webkit-scrollbar-track {
  border-radius: 0;
  box-shadow: none;
  background-color: rgba(153, 153, 153, 0.2);
  border: none;
  transition: all .3s;
}

table.custom-tbody-scrollable tbody::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: #ccc;
  border: none;
  transition: all .3s;
}

table.custom-tbody-scrollable tbody::-webkit-scrollbar
{
  width: 6px;
  transition: all .3s;
}

table.custom-tbody-scrollable tbody::-webkit-scrollbar-thumb:hover {
  background: rgba(153, 153, 153, 0.9);
  transition: all .3s;
}

table.custom-tbody-scrollable tbody td,
table.custom-tbody-scrollable thead th {
  width: calc(38.7% - 60px);
  float: left;
}

table.custom-tbody-scrollable tbody td:first-child,
table.custom-tbody-scrollable thead th:first-child {
  width: 60px;
}

.custom-tbody-scrollable thead tr th { 
  height: 56px;
  line-height: 34px;
}