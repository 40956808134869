.ks-contacts {
	.content {
		.pane-content {
			&.calendar {
				.ks-sidebar {
					.wrapper {
						.collapse-sidebar {
							top: 90px;
						}
					}
				}
			  .contact-content {
			  	padding-bottom: 0;
			  	border-radius: 30px 30px 0 0;

			  	.table {
			  		tbody {
			  			tr {
			  				&:hover {
			  					td {
			  						background: none;
			  					}
			  				}
			  			}
			  		}
			  	}

			    .top {
						.links-group {
							padding: 0 15px;
						}
					}
				}
			}
		}
	}
}

.pane-content {
	&.calendar {
		.contact-content {
			.three_month_blocks{
				width:100%;
				display: -webkit-box;
			    display: -ms-flexbox;
			    display: flex;
			    -webkit-box-align: center;
			    -ms-flex-align: center;
			    align-items: center;
			    -webkit-box-pack: end;
			    -ms-flex-pack: end;
			    justify-content: flex-end;
				-webkit-box-pack: center;
			    -webkit-justify-content: center;
			    -ms-flex-pack: center;
			    justify-content: center;
			    @media (max-width: 768px) {
			    	flex-direction: column;
			    }

				.month_block_33{
					width:33.3%;
					display:block;
					text-decoration:none;
					box-sizing:border-box;
					padding:6px 15px 6px 15px;
					color:#ffffff;
					font-size: 13px;
					color: #ffffff;
					background-position:left center;
					@media (max-width: 768px) {
				      width:100%;
				      float:none;
				    }

					&:hover{
						background-image:url(../img/icons/angle-right--white.png);
					    background-position:95% center;
					    background-repeat:no-repeat;
					    opacity: 1;
					}
					.month_block_count{
							font-size: 22px;
							font-weight: normal;
							  font-style: normal;
							  font-stretch: normal;
							  line-height: 1.32;
							  letter-spacing: -0.2px;
							  text-align: left;
							  color: #ffffff;
							  float:left;
							  margin-right:15px;
							  line-height:32px;
						}
						.month_block_text{
							font-size: 13px;
							  font-weight: normal;
							  font-style: normal;
							  font-stretch: normal;
							  line-height: 1.15;
							  letter-spacing: 0.3px;
							  text-align: left;
							  color: #ffffff;
							  float:left;
							  line-height:32px;
						}

					&.deadlines{
						background-color:#ff5722;
						.month_block_icon{
							width:32px;
							height:32px;
							float:left;
							margin-right:13px;
							background: url('../img/@2x/icon-lg-calendar--white@2x.png') 0 0 no-repeat;
					        -webkit-background-size: 100% auto;
					        -moz-background-size: 100% auto;
					        background-size: 100% auto;
						}
					}
					&.meetings{
						background-color:#00bcd4;
						.month_block_icon{
							width:32px;
							height:32px;
							float:left;
							margin-right:13px;
							background: url('../img/@2x/icon-lg-appointment--white@2x.png') 0 0 no-repeat;
					        -webkit-background-size: 100% auto;
					        -moz-background-size: 100% auto;
					        background-size: 100% auto;
						}
						
					}
					&.tasks{
						background-color:#ff9800;
						.month_block_icon{
							width:32px;
							height:32px;
							float:left;
							margin-right:13px;
							background: url('../img/@2x/icon-lg-task--white@2x.png') 0 0 no-repeat;
					        -webkit-background-size: 100% auto;
					        -moz-background-size: 100% auto;
					        background-size: 100% auto;
						}
					}
				}
			
			}


			.top {
				border-bottom: 1px solid #e6e6e6;
				margin-bottom: 15px;

				.links-group {
					@media (max-width: 992px) {
						flex-wrap: wrap !important;
						height: auto !important;
						padding: 10px !important;
					}

					.breadcrumb {
						@media (max-width: 992px) {
							flex: 1 100%;
							margin-bottom: 10px;
						}

						&.search-calendar {
							.breadcrumb-item {
								&:before {
									background: none;
									top: 50%;
									margin-top: -15px;
									left: 0;
								  height: 30px;
								  border: solid 1px #e8e8e8;
								  width: 0;

								  @media (max-width: 768px) {
								  	display: none;
								  }
								}
								&:first-child {
									padding-left: 0;

									.select2-container--default {
										.select2-selection--single {
											box-shadow: none;
											width: 170px;

											.select2-selection__rendered { 
												padding-left: 40px;
												padding-right: 30px;

											}

											&:hover {
												box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.2);
											}
										}

										&.select2-container--open {
											.select2-selection--single {
												box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.2);
											}
										}
									}
								}

								.ks-text {
									font-size: 16px;
									line-height: 45px;

									@media (max-width: 768px) {
										font-size: 14px;
										line-height: 1.2em;
									}
								}
							}
						}

						.breadcrumb-item {
							padding: 0 15px;

							@media (max-width: 768px) {
								padding: 0 10px;
							}

							@media (max-width: 576px) {
								padding: 0 5px;
							}

							&:before {
								width: 6px;
								height: 9px;
								margin-top: -5.5px;
							}

							a {
								opacity: 1;
								font-size: 20px;

								@media (max-width: 1199px) {
									font-size: 16px;
								}

								@media (max-width: 768px) {
									font-size: 14px;
								}

								.ks-text {
									transition: all .3s;
								}

								&:hover {
									.ks-text {
										color: #4caf50;
									}
								}
							}
						}
					}
				}

				.event-block {
					display: -ms-flexbox;
			    display: -webkit-flex;
			    display: -webkit-box;
			    display: flex;
			    -webkit-flex-direction: row;
			    -ms-flex-direction: row;
			    -webkit-box-orient: horizontal;
			    -webkit-box-direction: normal;
			    flex-direction: row;
			    -webkit-flex-wrap: nowrap;
			    -ms-flex-wrap: nowrap;
			    flex-wrap: nowrap;
			    -webkit-justify-content: flex-start;
			    -ms-flex-pack: start;
			    -webkit-box-pack: start;
			    justify-content: flex-start;
			    -webkit-align-content: stretch;
			    -ms-flex-line-pack: stretch;
			    align-content: stretch;
			    -webkit-align-items: center;
			    -ms-flex-align: center;
			    -webkit-box-align: center;
			    align-items: center;

			    @media (max-width: 768px) {
			    	flex-wrap: wrap;
			    	align-items: flex-start;
			    }

			    .select2 {
			    	width: 180px !important;
			    	margin-right: 15px;

			    	@media (max-width: 768px) {
			    		width: 110px !important;
			    		margin-bottom: 10px;
			    	}
			    }

			    .view-block {
			    	margin-right: 15px;
			    	background: #fff;
			    	border-radius: 15px;
			    	height: 45px;
			    	box-shadow: 0 1px 4px rgba(0,0,0,0.2);
			    	width: 94px;

			    	@media (max-width: 768px) {
			    		margin-bottom: 10px;
			    		width: 80px;
			    	}

			    	a {
			    		display: inline-block;
			    		width: 45px;
			    		height: 45px;
			    		position: relative;

			    		@media (max-width: 768px) {
			    			width: 38px;
			    		}

			    		&:first-child {
			    			margin-left: 0;

				    		&:before {
				    			position: absolute;
				    			top: 50%;
				    			right: -2px;
				    			height: 27px;
				    			margin-top: -13.5px;
				    			border-right: 2px solid #f2f2f2;
				    			content: "";
				    		}
			    		}

			    		&:hover,
			    		&.active {
			    			.icon {
			    				opacity: .8;
			    			}
			    		}
			    	}

			    	.icon	{
			    		opacity: 0.4;
			    	}
			    }

					.custom-search-block {

						@media (max-width: 768px) {}

						input.form-control {
							border: 1px solid #e6e6e6;
							width: 180px;
						}
						.icon-addon {
							border-top: 1px solid #e6e6e6;
							border-right: 1px solid #e6e6e6;
							border-bottom: 1px solid #e6e6e6;
						}
					}
				}
			}

			.calendar-content {
				.content {
					padding: 0 60px 30px;

					@media (max-width: 992px) {
						padding: 0 20px 30px;
					}

					@media (max-width: 768px) {
						padding: 0 10px 20px;
					}
				}
			}

			.custom-scroll-table {
				border-radius: 0 !important;

				&.year {

					.simplebar-content {
						padding: 0 0 20px !important;
					}

					.table {
						min-width: 980px;

						td {
							border: 0;
						}
					}
					.datepicker {
						.datepicker-switch {
							text-align: left;
						}
					}
				}

				&.month {
					.simplebar-content {
						padding: 0 0 33px !important;
					}

					.table {
						min-width: 980px;
						table-layout: fixed;

						tr {
							&:first-child {
								td {
									border-top: 2px solid #ececec;
								}
							}
						}

						td {
							border: 2px solid #ececec;
							position: relative;
							padding: 0;
							transition: all .3s;
							cursor: pointer;

							.hover_event_block {
							    position: relative;
							    // margin: -0.75rem;
							    &:hover{
										.hover_block{
											display:block;
										}
									}
							}

							.hover_event{
									display:block;
									// height:44px;
									box-sizing:border-box;
									// padding:0.75rem;
									width:100%;
									span{
										// font-size:10px;
										color:#ff5722;
									}

									
								}
							.hover_block{
									display:none;
									position: absolute;
									// top:0px;
									// left:0px;
									background:#ffffff;
									width:300px;
									width:100%;
									box-sizing:border-box;
									padding:15px;

									border-radius: 12px;
  									box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.1);
  									z-index: 55;

  									.from_group{
  										margin-bottom:15px;
  									}
  									.filter-content{
										margin-bottom:15px;
  									}
  									ul{
  										li{
  											padding-top:10px;
  											padding-bottom:10px;
  										}
  									}
  							}

							.day-number {
								position: absolute;
								top: 7px;
								left: 7px;
								color: #999999;
								font-weight: 700;
								transition: all .3s;
							}

							.day-body {
								height: 100px;
								background: #fff;
								padding: 30px 5px 5px;
								border: 2px solid transparent;
								transition: all .3s;
								display: -ms-flexbox;
						    display: -webkit-flex;
						    display: flex;
						    -webkit-flex-direction: column;
						    -ms-flex-direction: column;
						    flex-direction: column;
						    -webkit-flex-wrap: nowrap;
						    -ms-flex-wrap: nowrap;
						    flex-wrap: nowrap;
						    -webkit-justify-content: flex-end;
						    -ms-flex-pack: end;
						    justify-content: flex-end;
						    -webkit-align-content: stretch;
						    -ms-flex-line-pack: stretch;
						    align-content: stretch;
						    -webkit-align-items: flex-start;
						    -ms-flex-align: start;
						    align-items: flex-start;

						    .event {
						    	display: block;
						    	width: 100%;
						    	position: relative;
						    	padding-left: 15px;
						    	border:2px solid #fff;

						    	&.deadline {
						    		.icon {
						    			position: absolute;
						    			top: 50%;
						    			left: 0;
				    			    	background: url(../img/icons/@2x/event-point--red@2x.png) 0 0 no-repeat;
									    width: 15px;
									    height: 15px;
									    -webkit-background-size: auto 100%;
									    background-size: auto 100%;
									    margin-top: -7.5px;
						    		}
						    		&:hover{
						    			border:2px solid #ff5722;
						    		}
						    	}

						    	&.meeting {
						    		.icon {
						    			position: absolute;
						    			top: 50%;
						    			left: 0;
				    			    background: url(../img/icons/@2x/event-point--cyan@2x.png) 0 0 no-repeat;
									    width: 15px;
									    height: 15px;
									    -webkit-background-size: auto 100%;
									    background-size: auto 100%;
									    margin-top: -7.5px;
						    		}
						    		&:hover{
						    			border:2px solid #00bcd4;
						    		}
						    	}

						    	&.task {
						    		.icon {
						    			position: absolute;
						    			top: 50%;
						    			left: 0;
				    			    background: url(../img/icons/@2x/event-point--yellow@2x.png) 0 0 no-repeat;
									    width: 15px;
									    height: 15px;
									    -webkit-background-size: auto 100%;
									    background-size: auto 100%;
									    margin-top: -7.5px;
						    		}
						    		&:hover{
						    			border:2px solid #ff9800;
						    		}
						    	}

						    	.ks-text {
						    		color: #000;
						    		font-size: 12px;
						    		text-overflow: ellipsis;
									  overflow: hidden; 
									  width: inherit; 
									  height: 18px;
									  white-space: nowrap;
						    	}
						    }
							}

							&.new,
							&.old {
								.day-number {
									color: #cfcfcf;
								}
							}

							&.today {
								.day-number {
									color: #ff5722;
								}

								.day-body {
									background: #fff6f3;
								}
							}

							&.has-event {
								.day-number {
									color: #000;
								}
							}

							&:hover {
								.day-number {
									color: #ff5722;
								}

								.day-body {
									border: 2px solid #ff5722;
								}
							}

							&:first-child {
								border-left: 0;
							}

							&:last-child {
								border-right: 0;
							}
						}
						thead {
							th {
								background: none;
								border: 0;
							}
						}
					}
				}

				&.agenda {
					.table {
						min-width: 1180px;

						td,
						th {
							cursor: auto;
							vertical-align: top;

							&:first-child {
								width: 150px;
							}

							&:nth-child(2) {
								width: 70px;
							}

							&.time-cell {
								border-right: 0;
							}

							&:last-child {
								border-left: 0;
							}
						}

						td {
							padding: .75rem;
						}

						th {
							text-transform: uppercase;
						}

						.month-title {
							color: #000;
							font-size: 16px;
							font-weight: 600;
							margin-bottom: 2px;
						}

						.week-title {
							color: #999;
							font-weight: 700;
						}

						.time {
					    color: #999;
					    text-align: center;
					    font-weight: 600;
					    font-size: 14px;
						}

						.event-block {
							position: relative;
					    display: -ms-flexbox;
					    display: -webkit-flex;
					    display: -webkit-box;
					    display: flex;
					    -webkit-flex-direction: row;
					    -ms-flex-direction: row;
					    -webkit-box-orient: horizontal;
					    -webkit-box-direction: normal;
					    flex-direction: row;
					    -webkit-flex-wrap: nowrap;
					    -ms-flex-wrap: nowrap;
					    flex-wrap: nowrap;
					    -webkit-justify-content: flex-start;
					    -ms-flex-pack: start;
					    -webkit-box-pack: start;
					    justify-content: flex-start;
					    -webkit-align-content: stretch;
					    -ms-flex-line-pack: stretch;
					    align-content: stretch;
					    -webkit-align-items: center;
					    -webkit-align-items: flex-start;
					    -ms-flex-align: start;
					    align-items: flex-start;
					    margin-bottom: 10px;;

					    .event-item {
					    	width: calc(100% - 200px - 330px - 10px);
					    	margin-right: 10px;
					    }

					    .event-info {
					    	width: 200px;
					    }

					    .event-another {
					    	width: 270px;
					    }
						}

						.event-item {
							.item {
								position: relative;
						    display: flex;
						    height: 45px;
						    width: 100%;
						    margin: 0;
						    padding: 0;
							}
							.event-icon {
								width: 60px;
							}

							.event-title {
								width: calc(100% - 60px);
							}

							&.meeting {
								.icon-block {
									background: #00bcd4;

									&:before {
										background: #00bcd4;
										opacity: .3;
									}
								}
							}

							&.deadline {
								.icon-block {
									background: #ff5722;

									&:before {
										background: #ff5722;
										opacity: .3;
									}
								}
							}

							&.task {
								.icon-block {
									background: #ff9800;

									&:before {
										background: #ff9800;
										opacity: .3;
									}
								}
							}

							.icon-block {
								position: relative;
								width: 30px;
						    height: 30px;
						    margin: 7.5px;
						    border-radius: 100px;

						    &:before {
						    	position: absolute;
							    top: -7.5px;
							    left: -7.5px;
							    content: "";
							    width: 45px;
							    height: 45px;
							    -webkit-border-radius: 100px;
							    border-radius: 100px;
						    }
							}

							.event-title {
								font-size: 13px;
    						color: #a3a3a3;

    						a {
    							color: #000;
    							display: block;
    							font-weight: 600;
    							margin-bottom: 4px;
    						}

    						span {
    							display: block;
							    text-overflow: ellipsis;
							    overflow: hidden;
							    width: 100%;
							    height: 18px;
							    white-space: nowrap;

							    &.address {
							    	position: relative;
							    	background: #fff;
							    	z-index: 2;
							    }
    						}
							}

							.event-adds {
								margin-left: 60px;
								margin-top: 10px;

								.nav-link {
									position: relative;
							    display: inline-block;
							    background: #fff;
							    padding: 10px;
							    text-align: center;
							    text-transform: uppercase;
							    font-weight: 600;
							    color: #4caf50;
							    -webkit-border-radius: 15px;
							    border-radius: 15px;

							    .icon {
					    	    left: 0px;
								    margin-left: 0;
								    margin-top: -7px;
							    }

							    .ks-text {
					    	    display: block;
								    width: 138px;
								    height: 20px;
								    color: #000;
								    -webkit-transition: all .3s;
								    -o-transition: all .3s;
								    transition: all .3s;
							    }
								}

								.attachment-block {
							    display: -ms-flexbox;
							    display: -webkit-flex;
							    display: -webkit-box;
							    display: flex;
							    -webkit-flex-direction: row;
							    -ms-flex-direction: row;
							    -webkit-box-orient: horizontal;
							    -webkit-box-direction: normal;
							    flex-direction: row;
							    -webkit-flex-wrap: wrap;
							    -ms-flex-wrap: wrap;
							    flex-wrap: wrap;
							    -webkit-justify-content: flex-start;
							    -ms-flex-pack: start;
							    -webkit-box-pack: start;
							    justify-content: flex-start;
							    -webkit-align-content: stretch;
							    -ms-flex-line-pack: stretch;
							    align-content: stretch;
							    -webkit-align-items: center;
							    -ms-flex-align: center;
							    -webkit-box-align: center;
							    align-items: center;

							    .btn-file {
					    	    -webkit-flex: 1 1 auto;
								    -ms-flex: 1 1 auto;
								    -webkit-box-flex: 1;
								    flex: 1 1 auto;
								    position: relative;
								    padding: 12px 15px 12px 25px;
								    text-align: center;
								    background: none;
								    -webkit-border-radius: 15px;
								    border-radius: 15px;
								    -webkit-transition: all .3s;
								    -o-transition: all .3s;
								    transition: all .3s;
								    display: -ms-flexbox;
								    display: -webkit-flex;
								    display: -webkit-box;
								    display: flex;
								    -webkit-flex-direction: row;
								    -ms-flex-direction: row;
								    -webkit-box-orient: horizontal;
								    -webkit-box-direction: normal;
								    flex-direction: row;
								    -webkit-flex-wrap: nowrap;
								    -ms-flex-wrap: nowrap;
								    flex-wrap: nowrap;
								    -webkit-justify-content: center;
								    -ms-flex-pack: center;
								    -webkit-box-pack: center;
								    justify-content: center;
								    -webkit-align-content: stretch;
								    -ms-flex-line-pack: stretch;
								    align-content: stretch;
								    -webkit-align-items: center;
								    -ms-flex-align: center;
								    -webkit-box-align: center;
								    align-items: center;
								    -webkit-box-flex: 0;
								    flex: 0 auto;
								    font-size: 13px;
								    font-weight: 600;

								    &.hidden {
								    	padding: 0;
								    	width: 0;
								    	height: 0;
								    }

								    &:focus {
								    	box-shadow: none;
								    }

								    .icon {
								    	left: 5px;
								    	margin-left: 0;
								    }

								    .ks-text {
								    	color: #000;
								    	font-weight: 600;
								    	-webkit-transition: all .3s;
								    	   -moz-transition: all .3s;
								    	    -ms-transition: all .3s;
								    	     -o-transition: all .3s;
								    	        transition: all .3s;
								    }

								    &:hover {
								    	background: #f2fff3;

								    	.ks-text {
								    		color: #4caf50;
								    	}
								    }
							    }

							    .btn-more {
							    	background: none;

							    	&:focus {
								    	box-shadow: none;
								    }

							    	&:hover {
							    		.ks-text {
							    			color: #000;
							    		}
							    	}

							    	.ks-text {
							    		color: #828282;
							    		-webkit-transition: all .3s;
							    		   -moz-transition: all .3s;
							    		    -ms-transition: all .3s;
							    		     -o-transition: all .3s;
							    		        transition: all .3s;
							    	}
							    }
								}
							}
						}

						.event-info {
							.item {
								margin-top: 10px;

								&:first-child {
									margin-top: 0;
								}
							}

							span {
								display: inline-block;

								&.title {
									float: left;
									color: #a3a3a3;
									width: 30%;
								}

								&.value {
									float: left;
									width: 70%;
									color: #666;
									font-weight: 600;
								}
							}
						}

						.event-another {
							span {
								display: inline-block;

								&.title {
									float: left;
									color: #a3a3a3;
									width: 35%;
								}

								&.value {
									float: left;
									width: 65%;
									color: #666;
									font-weight: 600;
								}
							}
						}
					}
				}

				&.week {
					.simplebar-content {
						padding: 0 0 33px !important;
					}

					.table {
						min-width: 980px;
						table-layout: fixed;

						tr {
							&:first-child {
								td {
									border-top: 1px solid #e6e6e6;
								}
							}
						}

						th,
						td {
							border: 1px solid #e6e6e6;
							position: relative;
							transition: all .3s;
						}

						thead {
							border: 1px solid #e6e6e6;
							border-left: 0;
							border-right: 0;
							th {
								background: none;
								color: #999;
								padding: 19px 10px;
								border-top: 0;
								border-bottom: 0;

								&.hover {
									color: #ff5722;
								}
							}
						}

						tbody {
							tr {
								&:hover {
									td:first-child {
										color: #000;
									}
								}
							}
							td {
								&:first-child {
									color: #999;
									text-align: right;
									font-weight: 700;
								}

								&.hover {
									background: #fff6f3 !important;
								}

								.hover_event{
									display:block;
									height:44px;
									box-sizing:border-box;
									padding:0.75rem;
									width:100%;
									span{
										// font-size:10px;
										color:#ff5722;
									}

									
								}
								.custom-search-block{
										margin-bottom:15px;
										position: relative;
										.ks-text{
											line-height:45px;
										}
  									}
								.hover_block{
									display:none;
									position: absolute;
									// top:0px;
									// left:0px;
									background:#ffffff;
									width:300px;
									width:100%;
									box-sizing:border-box;
									padding:15px;

									border-radius: 12px;
  									box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.1);
  									z-index: 55;

  									.from_group{
  										margin-bottom:15px;
  									}
  									.filter-content{
										margin-bottom:15px;
  									}
  									ul{
  										li{
  											padding-top:10px;
  											padding-bottom:10px;
  										}
  									}
  									

  									.button_location{
										margin-bottom:15px;
										.btn {
											width: 100%;
										    position: relative;
										    padding: 12px 15px 12px 15px;
										    text-align: center;
										    color: #000;
										    text-transform: uppercase;
										    -webkit-border-radius: 15px;
										    border-radius: 15px;
										    -webkit-box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.3);
										    box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.3);
										    -webkit-transition: all .3s;
										    -o-transition: all .3s;
										    transition: all .3s;
										    display: -ms-flexbox;
										    display: -webkit-flex;
										    display: -webkit-box;
										    display: flex;
										    -webkit-flex-direction: row;
										    -ms-flex-direction: row;
										    -webkit-box-orient: horizontal;
										    -webkit-box-direction: normal;
										    flex-direction: row;
										    -webkit-flex-wrap: nowrap;
										    -ms-flex-wrap: nowrap;
										    flex-wrap: nowrap;
										    -webkit-justify-content: center;
										    -ms-flex-pack: center;
										    -webkit-box-pack: center;
										    justify-content: center;
										    -webkit-align-content: stretch;
										    -ms-flex-line-pack: stretch;
										    align-content: stretch;
										    -webkit-align-items: center;
										    -ms-flex-align: center;
										    -webkit-box-align: center;
										    align-items: center;
										    font-size: 13px;
										    font-weight: 700;
										    background: none;
										}
  									}
  									.select2-selection__rendered{
  										    padding-left: 40px;
  										    line-height: 45px;
  									}
  									.button_under_location{
  										display: -webkit-box;
									    display: -ms-flexbox;
									    display: flex;
									    -webkit-box-align: center;
									    -ms-flex-align: center;
									    align-items: center;
									    -webkit-box-pack: end;
									    -ms-flex-pack: end;
									    justify-content: content;
										.btn{
											width:120px;
										    position: relative;
										    padding: 12px 15px 12px 15px;
										    text-align: center;
										    color: #000;
										    text-transform: uppercase;
										    -webkit-border-radius: 15px;
										    border-radius: 15px;
										    -webkit-box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.3);
										    box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.3);
										    -webkit-transition: all .3s;
										    -o-transition: all .3s;
										    transition: all .3s;
										    display: -ms-flexbox;
										    display: -webkit-flex;
										    display: -webkit-box;
										    display: flex;
										    -webkit-flex-direction: row;
										    -ms-flex-direction: row;
										    -webkit-box-orient: horizontal;
										    -webkit-box-direction: normal;
										    flex-direction: row;
										    -webkit-flex-wrap: nowrap;
										    -ms-flex-wrap: nowrap;
										    flex-wrap: nowrap;
										    -webkit-justify-content: center;
										    -ms-flex-pack: center;
										    -webkit-box-pack: center;
										    justify-content: center;
										    -webkit-align-content: stretch;
										    -ms-flex-line-pack: stretch;
										    align-content: stretch;
										    -webkit-align-items: center;
										    -ms-flex-align: center;
										    -webkit-box-align: center;
										    align-items: center;
										    font-size: 13px;
										    font-weight: 700;
										    background: none;
	  										&.add{
	  											margin-left:auto;
	  											background-color: #ff5722;
	  											color:#ffffff;
	  										}

	  									}
  									}
  									
								}
								.hover_event_block{
									position:relative;
									margin:-0.75rem;
									&:hover{
										.hover_block{
											display:block;
										}
									}
								}
								.event {
									display: block;
						    	width: 100%;
						    	position: relative;
						    	padding-left: 15px;

						    	&.deadline {
						    		.icon {
						    			position: absolute;
						    			top: 50%;
						    			left: 0;
				    			    background: url(../img/icons/@2x/event-point--red@2x.png) 0 0 no-repeat;
									    width: 15px;
									    height: 15px;
									    -webkit-background-size: auto 100%;
									    background-size: auto 100%;
									    margin-top: -7.5px;
						    		}
						    	}

						    	&.meeting {
						    		.icon {
						    			position: absolute;
						    			top: 50%;
						    			left: 0;
				    			    background: url(../img/icons/@2x/event-point--cyan@2x.png) 0 0 no-repeat;
									    width: 15px;
									    height: 15px;
									    -webkit-background-size: auto 100%;
									    background-size: auto 100%;
									    margin-top: -7.5px;
						    		}
						    	}

						    	&.task {
						    		.icon {
						    			position: absolute;
						    			top: 50%;
						    			left: 0;
				    			    background: url(../img/icons/@2x/event-point--yellow@2x.png) 0 0 no-repeat;
									    width: 15px;
									    height: 15px;
									    -webkit-background-size: auto 100%;
									    background-size: auto 100%;
									    margin-top: -7.5px;
						    		}
						    	}

						    	.ks-text {
						    		color: #000;
						    		font-size: 12px;
						    		text-overflow: ellipsis;
									  overflow: hidden; 
									  width: inherit; 
									  height: 18px;
									  white-space: nowrap;
						    	}
						    }
							}
						}
					}
				}
			}

			.calendar-search-no-result {
				padding: 60px 0 90px;

				.icon {
					position: relative;
					display: block;
			    top: 0;
			    left: 0;
			    margin: 0;
			    margin-bottom: 30px;
				}

				.title {
					font-size: 22px;
				  font-weight: 600;
				  line-height: 1.36em;
				  color: #000000;
				  margin-bottom: 30px;

				  @media (max-width: 768px) {
				  	font-size: 18px;
				  }
				}

				.ks-text {
					display: block;
					font-size: 16px;
					color: #000;
					line-height: 1.88em;
					margin-bottom: 30px;

					@media (max-width: 768px) {
						font-size: 14px;
					}
				}

				ul {
					position: relative;
					margin-bottom: 30px;

					li {
						padding-left: 15px;
						position: relative;
						font-size: 16px;
						color: #000;
						line-height: 1.88em;

						@media (max-width: 768px) {
							font-size: 14px;
						}

						&:before {
							position: absolute;
							top: 50%;
							width: 5px;
							background: #000;
							left: 0;
							margin-top: -0.5px;
							content: "";
							height: 1px;
						}
					}
				}

				.back-to {
					position: relative;
			    right: 0;
			    background: #fff;
			    padding: 12px 20px 12px 25px;
			    text-align: center;
			    color: #333;
			    text-transform: uppercase;
			    -webkit-border-radius: 15px;
			    border-radius: 15px;
			    -webkit-box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.2);
			    box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.2);
			    -webkit-transition: all .3s;
			    -o-transition: all .3s;
			    transition: all .3s;
			    display: -ms-flexbox;
			    display: -webkit-flex;
			    display: -webkit-box;
			    display: inline-flex;
			    -webkit-flex-direction: row;
			    -ms-flex-direction: row;
			    -webkit-box-orient: horizontal;
			    -webkit-box-direction: normal;
			    flex-direction: row;
			    -webkit-flex-wrap: nowrap;
			    -ms-flex-wrap: nowrap;
			    flex-wrap: nowrap;
			    -webkit-justify-content: center;
			    -ms-flex-pack: center;
			    -webkit-box-pack: center;
			    justify-content: center;
			    -webkit-align-content: stretch;
			    -ms-flex-line-pack: stretch;
			    align-content: stretch;
			    -webkit-align-items: center;
			    -ms-flex-align: center;
			    -webkit-box-align: center;
			    align-items: center;
			    font-size: 13px;
			    font-weight: 700;

			    &:hover {
	    	    box-shadow: 0 4px 14px 2px rgba(0,0,0,0.1);
    				color: #4caf50;

    				.icon {
    					background: url(../img/icons/@2x/angle-left--green@2x.png) 0 0 no-repeat;
    					width: 9px;
    					height: 9px;
    					background-size: auto 100%;
    				}
			    }

			    .icon {
			    	left: -7px;
			    	margin-left: 0;
			    	margin-top: 0px;
			    	margin-bottom:0px;
			    	-webkit-transition: all .3s;
			    	   -moz-transition: all .3s;
			    	    -ms-transition: all .3s;
			    	     -o-transition: all .3s;
			    	        transition: all .3s;
			    }

			    .ks-info {
			    	opacity: 1;
				    visibility: visible;
				    min-width: 110px;
			    }
				}
			}
		}
	}
}
