.ks-contacts .content .pane-content.profile-content .contact-content {
	height: -webkit-calc(100% - 60px);
	height: -moz-calc(100% - 60px);
	height: calc(100% - 60px);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  overflow: visible;
}

.ks-contacts .content .pane-content.profile-content .ks-page-content {
	min-height: 650px;
	overflow-y: auto;

	@media (max-width: 768px) {
		padding: 0 5px 10px;
	}
}

.profile-content {
	.profile-information {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;

    .inner-block {
    	background: #fff;
  		-webkit-border-radius: 15px;
  		-moz-border-radius: 15px;
  		border-radius: 15px;
  		-webkit-box-shadow: 0 2px 2px rgba(0,0,0,0.2);
	    -moz-box-shadow: 0 2px 2px rgba(0,0,0,0.2);
	    box-shadow: 0 2px 2px rgba(0,0,0,0.2);
	    margin-top: 30px;

	    &.empty {
	    	.block-more {
	    		display: none;
	    	}
	    }

	    &:first-child {
	    	margin-top: 0;
	    }

	    .block-title {
	    	padding: 20px;
	    	border-bottom: 2px solid #f2f2f2;
	    	height: 60px;

	    	h5 {
	    		display: inline-block;
	    		margin: 0;
	    		padding: 0;
	    		color: #000;
	    		font-size: 16px;
	    		font-weight: 600;
	    		line-height: 1.1em;
	    	}

	    	.btn-edit {
	    		position: relative;
			    top: -6px;
			    float: right;
			    width: 115px;
			    height: 30px;
			    padding: 0 10px 0 30px;
			    -webkit-border-radius: 100px;
			    border-radius: 100px;
			    background: none;
			    opacity: 1;
			    -webkit-transition: all .3s;
			    -o-transition: all .3s;
			    transition: all .3s;

			    .icon {
			    	left: 7px;
    				margin-left: 0;
			    }

			    .ks-text {
	    	    line-height: 31px;
				    text-transform: uppercase;
				    font-size: 12px;
				    color: #5b5b5b;
				    display: block;
				    font-weight: 600;
			    }
	    	}

	    	.search-block {
	    		display: inline-block;
	    		width: 140px;
	    		position: absolute;
	    		right: 60px;
	    		top: -13px;

	    		@media (max-width: 768px) {
	    			right: 40px;
	    		}

	    		&:after {
  			    position: absolute;
				    top: 50%;
				    left: -2px;
				    bottom: 0;
				    height: 30px;
				    content: "";
				    width: 2px;
				    background: #f2f2f2;
				    z-index: 2;
				    margin-top: -15px;
	    		}

	    		&:before {
  			    position: absolute;
				    top: 50%;
				    right: -2px;
				    bottom: 0;
				    height: 30px;
				    content: "";
				    width: 2px;
				    background: #f2f2f2;
				    z-index: 2;
				    margin-top: -15px;
	    		}
	    	}

	    	.block-calendar {
  		    display: inline-block;
			    float: right;
			    line-height: 1em;
			    position: relative;

			    .open-calendar {
	    	    line-height: 1em;
				    font-size: 16px;
				    cursor: pointer;
				    width: 17px;
				    height: 17px;
			    	margin: 0;
			    }

			    input {
			    	display: none;
			    }
	    	}
	    }

	    .block-content {
	    	padding: 15px;

	    	.empty-block {
	    		min-height: 400px;
	    		display: flex;
	    		justify-content: center;
	    		align-items: center;
	    		color: #999;
	    		font-size: 13px;

	    		@media (max-width: 768px) {
	    			min-height: 150px;
	    		}
	    	}
	    }
    }

    .user-information {
	    width: 25%;
	    min-width: 330px;
	    background: #fff;
	    padding: 30px;
	    -webkit-transition: all .3s;
	    -moz-transition: all .3s;
	    transition: all .3s;
	    position: relative;
	    border-radius: 0 0 0 15px;

	    @media (max-width: 1199px) {
  	    position: absolute;
		    top: 0;
		    left: -400px;
		    bottom: 0;
		    min-height: 900px;
		    z-index: 5;
		    border-right: 2px solid #f2f2f2;
	    }

	    @media (max-width: 768px) {
	    	min-width: 295px;
	    }

	    &.show {
	    	left: 0;
	    }

	    .ks-close {
	    	display: none;
  	    position: absolute;
  	    top: 0;
  	    right: 0;
  	    width: 50px;
  	    height: 50px;

	    	@media (max-width: 1199px) {
	  	    display: inline-block;
		    }
	    }

	    .title {
	    	font-weight: 600;
	    	color: #333;
	    	margin: 0;
	    	font-size: 14px;
	    	margin-bottom: 30px;
	    }

	    .info-title {
	    	display: block;
	    	font-size: 11px;
	    	font-weight: 400;
	    	color: #666666;
	    	text-transform: uppercase;
	    	margin: 30px 0 10px;
	    }

	    .info-content {
	    	margin-bottom: 10px;
	    }

	    .address {
	    	display: block;
	    	color: #333;
	    	line-height: 2em;
	    	margin-bottom: 20px;
	    }

	    .ks-show-map-btn {
    		position: relative;
    		right: 0;
    		display: inline-block;
    		background: #fff;
  			padding: 12.5px 10px 12.5px 30px;
  			text-align: center;
  			text-transform: uppercase;
  			font-weight: 600;
  			color: #000;
  			-webkit-border-radius: 15px;
  			-moz-border-radius: 15px;
  			border-radius: 15px;
		    -webkit-box-shadow: 0 2px 2px rgba(0,0,0,0.2);
		    -moz-box-shadow: 0 2px 2px rgba(0,0,0,0.2);
		    box-shadow: 0 2px 2px rgba(0,0,0,0.2);
		    border: 1px solid #F3F3F3;

		    &:active {
		    	top: 2px;
		    	-webkit-box-shadow: 0 0 rgba(0,0,0,0.2);
			    -moz-box-shadow: 0 0 rgba(0,0,0,0.2);
			    box-shadow: 0 0 rgba(0,0,0,0.2);
		    }

		    &:hover {
		    	.icon {
		    		background: url('../img/icons/@2x/sidebar-icon-map@2x.png') 0 0 no-repeat;
				    width: 16px;
				    height: 16px;
				    -webkit-background-size: 100% auto;
				    -moz-background-size: 100% auto;
				    background-size: 100% auto;
		    	}

		    	.ks-text {
		    		color: #4caf50;
		    	}
		    }

		    .icon {
  				left: 15px;
  				margin-left: 0;
  				margin-top: -7px;
  				transition: all .3s;
  			}

  			.ks-text {
  				display: block;
  				opacity: 1;
  				visibility: visible;
  				min-width: 110px;
  				width: 138px;
  				height: 20px;
  				-webkit-transition: all .3s;
					-moz-transition: all .3s;
					transition: all .3s;
  			}
    	}

    	.open-popover {
    		display: inline-block;
    		position: relative;
    		padding: 12.5px 15px 12.5px 40px;
    		border: 1px solid #e6e6e6;
    		-webkit-border-radius: 15px;
    		-moz-border-radius: 15px;
    		border-radius: 15px;
    		cursor: pointer;

    		.icon {
    			left: 15px;
    			margin-left: 0;

    			&.icon-mobile,
    			&.icon-work,
    			&.icon-home,
    			&.icon-phone {
				    width: 15px;
    				height: 15px;
    				margin-top: -7.5px;
    			}

    			&.icon-mobile {

    			}

    			&.icon-message {
    				margin-top: -5.5px;
    			}
    		}
    	}
    }

    .activity-block {
	    -webkit-box-flex: 1;
	    -webkit-flex-grow: 1;
	    -ms-flex-positive: 1;
	    flex-grow: 1;

	    .hidden-xl-up {
	    	display: none;

	    	@media (max-width: 1199px) {
	    		display: block;
	    		text-align: right;
	    		padding: 20px 30px 0;
	    		float: none;
	    	}

	    	.ks-cards-toggle {
	    		display: none;
	    		@media (max-width: 992px) {
	    			display: inline-block;
	    		}
	    	}
	    }

	    .wrapper {
	    	padding: 30px;

	    	@media (max-width: 768px) {
	    		padding: 5px 5px 10px;
	    	}
	    }

    	.activity-block {
		    .block-content {
		    	.nav-tabs {
    		    background: none;
			   	  border: 0;
				    margin-bottom: 25px;

				    .nav-item {
				    	position: relative;
				    	margin-left: 15px;

				    	&:first-child {
				    		margin-left: 0;

					    	&:before {
					    		position: absolute;
					    		height: 15px;
					    		right: -5px;
					    		top: 50%;
					    		margin-top: -7.5px;
					    		width: 2px;
					    		background: #f2f2f2;
					    		content: "";
					    	}
				    	}
				    }

				    .nav-link {
				    	position: relative;
				    	border: 0;
				    	padding: 0 10px 0 10px;
				    	min-width: 30px;
				    	min-height: 25px;

				    	.icon {
				    		left: 7px;
				    		margin-left: 0;
				    	}

				    	&:first-child {
				    		margin-left: 0;
				    	}

				    	&.active {
				    		padding: 0 10px 0 30px;

				    		span {
				    			line-height: 25px;
				    			text-transform: uppercase;
				    			font-size: 12px;
				    			color: #5b5b5b;
				    			display: block;
				    			font-weight: 600;
				    		}
				    	}

				    	span {
				    		display: none;
				    	}
				    }
		    	}

		    	.tab-content {

		    		#add-file {
		    			.ks-upload-block {
		    				position: relative;
    				    -webkit-border-radius: 2px;
						    border-radius: 2px;
						    border: dashed 1px rgba(57,81,155,.2);
						    padding: 14px 20px;
						    display: -webkit-box;
						    display: -webkit-flex;
						    display: -ms-flexbox;
						    -js-display: flex;
						    display: flex;
						    -webkit-box-orient: horizontal;
						    -webkit-box-direction: normal;
						    -webkit-flex-direction: row;
						    -ms-flex-direction: row;
						    flex-direction: row;
						    -webkit-box-align: center;
						    -webkit-align-items: center;
						    -ms-flex-align: center;
						    align-items: center;
						    -webkit-box-pack: center;
						    -webkit-justify-content: center;
						    -ms-flex-pack: center;
						    justify-content: center;

						    @media (max-width: 768px) {
						    	padding-left: 10px;
						    	padding-right: 10px;
						    	padding-top: 30px;
						    }

						    .icon-upload {
						    	position: relative;
							    left: auto;
							    margin-left: auto;
							    margin-top: 0;
							    top: 0;
							    margin-right: 10px;
							    opacity: .4;
							    width: 28px;
							    height: 22px;

							    @media (max-width: 768px) {
							    	position: absolute;
							    	top: 5px;
							    	left: 50%;
							    	margin-left: -14px;
							    }
						    }

						    .ks-text {
				    	    font-size: 12px;
							    color: rgba(51,51,51,.6);
							    line-height: 12px;
						    }

						    .ks-btn-separator {
						    	margin: 0 10px;
						    }

						    .btn {
				    	    position: relative;
							    font-size: 12px;
							    font-weight: 500;
							    -webkit-border-radius: 2px;
							    border-radius: 2px;
							    padding: 10px 25px;
							    font-family: Montserrat,sans-serif;
							    color: #fff;
							    height: 38px;
							    -webkit-box-align: center;
							    -webkit-align-items: center;
							    -ms-flex-align: center;
							    align-items: center;
							    -webkit-box-pack: center;
							    -webkit-justify-content: center;
							    -ms-flex-pack: center;
							    justify-content: center;
							    -webkit-transition: none;
							    transition: none;

							    .icon {
					    	    left: 12px;
								    margin-left: 0;
								    width: 20px;
								    height: 17px;
								    margin-top: -7.5px;
							    }

							    .ks-text {
							    	padding-left: 21px;
    								padding-right: 0!important;
    								color: #fff;
							    }

							    input[type="file"] {
					    	    position: absolute;
								    top: 0;
								    right: 0;
								    min-width: 100%;
								    min-height: 100%;
								    font-size: 26px;
								    text-align: right;
								    opacity: 0;
								    cursor: inherit;
								    display: block;
							    }
						    }
		    			}
		    		}

		    		.top-comment {
		    			.form-control {
		    				margin-bottom: 10px;
		    			}
		    		}

		    		.footer-block {
		    			margin-top: 10px;
		    			position: relative;

		    			.attach-btn {
		    				color: #3aa2c9;
						    padding-right: 25px;
						    font-size: 12px;
						    float: left;
						    margin: 0;
						    position: relative;
						    padding: 0 10px 0 30px;

						    .icon {
				    	    left: 7px;
    							margin-left: 0;
						    }

						    .ks-text {
					        line-height: 30px;
							    text-transform: uppercase;
							    font-size: 12px;
							    color: #5b5b5b;
							    display: block;
							    font-weight: 600;
						    }
		    			}

		    			.post-btn {
    				    float: right;
		    			}
		    		}
		    	}
		    }
    	}

    	.upcoming-block,
    	.history-block {
    		.icon-col {
    			position: relative;
    			text-align: center;

    			&:after {
  					position: absolute;
  					top: 45px;
  					bottom: -60px;
  					left: 50%;
  					content: "";
  					border-left: 1px solid #e6e6e6;
  					margin-left: -1px;

  					@media (max-width: 768px) {
  						display: none;
  					}
  				}
    		}

    		.block-content {
    			.row {
	    			padding: 30px 0;

	    			.block-information {
	    				position: relative;

		    			&:before {
		    				position: absolute;
		    				top: -30px;
		    				left: 0;
		    				right: -15px;
		    				content: "";
		    				border-top: 2px solid #f2f2f2;

		    				@media (max-width: 768px) {
		    					top: -70px;
		    				}
		    			}
	    			}

	    			&:first-child {
	    				padding-top: 15px;

	    				.block-information {
	    					&:before {
	    						display: none;
	    					}
	    				}
	    			}

	    			&:last-child {
	    				padding-bottom: 15px;

	    				.icon-col {
	    					z-index: 2;

	    					&:after {
	    						display: none;
	    					}
	    				}
	    			}
	    		}
    		}

    		.deadline {
    			.icon-block {
    				background: #ff5722;

    				&:before {
    					background: #ff5722;
    					opacity: 0.3;
    				}
    			}
    		}

    		.meeting {
    			.icon-block {
    				background: #ff9800;

    				&:before {
    					background: #ff9800;
    					opacity: 0.3;
    				}
    			}
    		}

    		.history {
    			.icon-block {
    				background: #b5b5b5;

    				&:before {
    					background: #b5b5b5;
    					opacity: 0.3;
    				}
    			}
    		}

  			.icon-block {
  				display: inline-block;
  				position: relative;
  				width: 30px;
  				height: 30px;
  				margin: 7.5px;
  				-webkit-border-radius: 100px;
  				-moz-border-radius: 100px;
  				border-radius: 100px;

  				&:before {
  					position: absolute;
  					top: -7.5px;
  					left: -7.5px;
  					content: "";
  					width: 45px;
  					height: 45px;
  					-webkit-border-radius: 100px;
	  				-moz-border-radius: 100px;
	  				border-radius: 100px;
  				}

  				// &:after {
  				// 	position: absolute;
  				// 	top: 100%;
  				// 	height: 200px;
  				// 	left: 50%;
  				// 	content: "";
  				// 	border-left: 1px solid #e6e6e6;
  				// }
  			}

  			.block-information {
  				.top {
  					.type-block {
  						display: inline-block;
  						margin-left: 40px;

  						@media (max-width: 768px) {
  							margin-right: 20px;
  							margin-left: 0;
  						}

  						&:first-child {
  							margin-left: 0;
  						}

  						.semi-title {
						    position: relative;
						    display: block;
						    font-size: 13px;
						    color: #c5c5c5;
						    margin-bottom: 5px;
  						}

  						.title {
  							position: relative;
						    display: block;
						    font-size: 13px;
						    font-weight: 600;
						    color: #000;
  						}
  					}
  				}

  				.message-block {
  					.title {
							position: relative;
					    display: block;
					    font-size: 13px;
					    font-weight: 600;
					    color: #000;
						}
  					p {
  						font-family: $font-open-sans;
  						font-style: italic;
  						font-size: 14px;
  					}
  				}

  				.bottom {
  					.btn {
  						display: inline-block;
  						position: relative;
					    border: 0;
					    padding: 0 10px 0 10px;
					    min-width: 30px;
					    min-height: 25px;
					    background: none;
					    margin-left: 20px;

					    .icon {
			    	    left: 7px;
    						margin-left: 0;
    						-webkit-transition: all .3s;
    						   -moz-transition: all .3s;
    						    -ms-transition: all .3s;
    						     -o-transition: all .3s;
    						        transition: all .3s;
					    }

					    &:hover {
					    	.icon-edit {
					    		background: url(../img/icons/@2x/icon-edit--green@2x.png) 0 0 no-repeat;
							    -webkit-background-size: auto 100%;
							    -moz-background-size: auto 100%;
							    background-size: auto 100%;
					    	}
					    }

					    &.ks-trash {
					    	&:hover {
						    	.icon-delete {
				    		    background: url(../img/icons/@2x/icon-delete--red@2x.png) 0 0 no-repeat;
								    -webkit-background-size: auto 100%;
								    -moz-background-size: auto 100%;
								    background-size: auto 100%;
						    	}
						    }
					    }

					    &.disabled {
					    	cursor: default;

					    	.icon-edit {
					    		background: url("../img/icons/@2x/icon-edit@2x.png") 0 0 no-repeat;
					    		-webkit-background-size: auto 100%;
							    -moz-background-size: auto 100%;
							    background-size: auto 100%;
					    	}
					    	&.ks-trash {
					    		.icon-delete {
					    			background: url("../img/icons/@2x/icon-delete@2x.png") 0 0 no-repeat;
					    			-webkit-background-size: auto 100%;
								    -moz-background-size: auto 100%;
								    background-size: auto 100%;
					    		}
					    	}
					    }
  					}
  				}
  			}

    		.block-more {
    			text-align: center;
    			padding: 15px;
  				border-top: 2px solid #f2f2f2;

    			.more {
    				display: inline-block;
				    text-transform: uppercase;
				    font-size: 12px;
    				line-height: 1.2em;
				    color: #4caf50;
				    font-weight: 600;
    			}
    		}
    	}
    }

    .cards-block {
    	position: relative;
    	width: 25%;
	    min-width: 330px;
	    background: #fff;
	    -webkit-transition: all .3s;
	    -moz-transition: all .3s;
	    transition: all .3s;
	    border-radius: 0 0 15px;

	    @media (max-width: 992px) {
  	    position: absolute;
		    top: 0;
		    right: -400px;
		    bottom: 0;
		    min-height: 1050px;
		    z-index: 5;
		    border-left: 2px solid #f2f2f2;
	    }

	    @media (max-width: 768px) {
	    	min-width: 295px;
	    }

	    &.show {
	    	right: 0;
	    }

	    .ks-close {
	    	display: none;
	    	position: absolute;
  	    top: 0;
		    left: 0;
		    width: 30px;
		    height: 30px;
		    z-index: 2;

	    	@media (max-width: 992px) {
	  	    display: inline-block;
		    }
	    }

	    .card {
	    	border: 0;
	    	-webkit-border-radius: 0;
	    	-moz-border-radius: 0;
	    	border-radius: 0;

	    	&.deadline {
	    		.card-header {
	    			&:before {
	    				border-bottom: 2px solid #ff5722;
	    			}
	    			.btn {
	    				background: #ff5722;
	    			}
	    		}
	    	}

	    	&.assigned {
	    		.card-header {
	    			&:before {
	    				border-bottom: 2px solid #673ab7;
	    			}
	    			.btn {
	    				background: #673ab7;
	    			}
	    		}
	    	}

	    	&.naroki {
	    		.card-header {
	    			&:before {
	    				border-bottom: 2px solid #009688;
	    			}
	    			.btn {
	    				background: #009688;
	    			}
	    		}
	    	}

	    	&.representative {
	    		.card-header {
	    			&:before {
	    				border-bottom: 2px solid #4caf50;
	    			}
	    			.btn {
	    				background: #4caf50;
	    			}
	    		}
	    	}
	    	&.assigned_cases {
	    		.card-header {
	    			&:before {
	    				border-bottom: 2px solid #ff9800;
	    			}
	    			.btn {
	    				background: #ff9800;
	    			}
	    		}
	    	}
	    }

	    .card-header {
	    	position: relative;
	    	background: none;
	    	-webkit-border-radius: 0;
	    	-moz-border-radius: 0;
	    	border-radius: 0;
	    	padding: 20px 20px 20px 60px;
	    	height: 60px;
	    	border: 0;

	    	&:before {
	    		position: absolute;
	    		left: 20px;
	    		right: 20px;
	    		bottom: 0;
	    		content: "";
	    	}

	    	.ks-text {
  		    display: inline-block;
			    margin: 0;
			    padding: 0;
			    color: #000;
			    font-size: 16px;
			    font-weight: 600;
			    line-height: 1.1em;
	    	}

	    	& > .icon {
	    		left: 30px;
	    		margin-left: 0;
	    	}

	    	.btn {
	    		position: relative;
	    		top: -6px;
	    		float: right;
	    		width: 30px;
	    		height: 30px;
	    		padding: 0;
	    		-webkit-border-radius: 100px;
	    		-moz-border-radius: 100px;
	    		border-radius: 100px;
	    		-webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
	    		-moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
	    		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
	    		opacity: 1;
	    		-webkit-transition: all .3s;
	    		   -moz-transition: all .3s;
	    		    -ms-transition: all .3s;
	    		     -o-transition: all .3s;
	    		        transition: all .3s;

	    		&:hover {
	    			opacity: 0.8;
	    		}

	    		&:before {
  			    position: absolute;
				    top: 50%;
				    left: 50%;
				    background: url(../img/icons/@2x/icon-plus--white@2x.png) 0 0 no-repeat;
				    width: 13px;
				    height: 13px;
				    -webkit-background-size: 100% auto;
				    -moz-background-size: 100% auto;
				    background-size: 100% auto;
				    margin-top: -6.5px;
				    margin-left: -7.5px;
				    content: "";
				    -webkit-transition: all .3s;
				       -moz-transition: all .3s;
				        -ms-transition: all .3s;
				         -o-transition: all .3s;
				            transition: all .3s;
	    		}

	    		&.edit {
	    			&:before {
	    				display: none;
	    			}
	    		}

	    		&.open-block {
	    			&:before {
	    				-webkit-transform: rotate(45deg);
	    				   -moz-transform: rotate(45deg);
	    				    -ms-transform: rotate(45deg);
	    				     -o-transform: rotate(45deg);
	    				        transform: rotate(45deg);
	    			}
	    		}

	    		// .icon {
	    		// 	margin-top: -6.5px;
	    		// 	margin-left: -6.5px;
	    		// }
	    	}
	    }

	    .card-block {
	    	border-bottom: 2px solid #f2f2f2;

	    	blockquote {
	    		position: relative;
	    		margin: 1.42857em 30px;
	    	}

	    	.empty {
	    		min-height: 240px;
	    		margin-top: 40px;

	    		@media (max-width: 768px) {
	    			min-height: 100px;
	    		}

	    		.empty-block {
	    			position: relative;
	    			padding-left: 45px;

	    			.icon {
	    				left: 0;
	    				margin-left: 0;
	    				opacity: .2;
    					width: 30px;
    					height: 30px;
    					margin-top: -15px;
	    			}

	    			.ks-text {
	    				display: block;
	    				font-size: 13px;
	    				color: #999;

	    				.block {
	    					display: block;
	    				}

	    				.btn {
	    					position: relative;
	    					width: 15px;
	    					height: 15px;
	    					margin: 0 5px;
	    					border-radius: 15px;
	    					padding: 0;
	    					cursor: auto;

	    					&:before {
	    						position: absolute;
							    top: 50%;
							    left: 50%;
							    background: url(../img/icons/@2x/icon-plus--white@2x.png) 0 0 no-repeat;
							    width: 7px;
							    height: 7px;
							    -webkit-background-size: 100% auto;
							    background-size: 100% auto;
							    margin-top: -3.5px;
							    margin-left: -3.5px;
							    content: "";
							    -webkit-transition: all .3s;
							    -o-transition: all .3s;
							    transition: all .3s;
	    					}

	    					&.btn-deadline {
	    						background: #ff5722;
	    					}

	    					&.btn-assigned {
	    						background: #673ab7;
	    					}

	    					&.btn-representative {
	    						background: #4caf50;
	    					}
	    				}
	    			}
	    		}
	    	}

	    	.ks-clear {
	    		position: absolute;
	    		top: 0;
	    		right: 0;;
	    	}
	    }

	    .deadline {
	    	.card-blockquote {
	    		.top {
	    			position: relative;
	    			padding-left: 25px;

	    			.icon {
	    				left: 0;
	    				margin-left: 0;
	    			}

	    			.ks-text {
					    display: block;
					    font-size: 13px;
					    color: #c5c5c5;
					    margin-bottom: 5px;
					    line-height: 15px;

					    .priority {
					    	position: relative;
					    	font-weight: 600;
					    	padding-left: 10px;
					    	margin-left: 10px;

					    	&:before {
					    		position: absolute;
					    		top: 50%;
					    		left: 0;
					    		width: 5px;
					    		background: #c5c5c5;
					    		height: 2px;
					    		margin-top: -1px;
					    		content: "";
					    	}

					    	&.low {
					    		color: #4caf50;
					    	}

					    	&.high {
					    		color: #ff5722;
					    	}

					    	&.normal {
					    		color: #ff9800;
					    	}
					    }
					  }
	    		}

	    		.card-link {
	    			display: block;
	    			font-weight: 600;
	    			color: #000;
	    			margin-top: 10px;

	    			&:hover {
	    				color: #ff5722;
	    			}
	    		}

	    		p {
	    			font-family: $font-open-sans;
				    font-style: italic;
    				font-size: 14px;
    				margin: 5px 0 30px;
	    		}

	    		.deadline-link {
						position: relative;
						display: block;
						padding-left: 23px;
						font-size: 16px;
					  font-weight: 600;
					  letter-spacing: 0.4px;
					  text-align: left;
					  color: #000;
					  margin-bottom: 7px;

					  &:before {
					  	position: absolute;
					  	top: 50%;
					  	left: 0;
					  	content: "";
					  	background: url(../img/icons/@2x/icon-time@2x.png) 0 0 no-repeat;
					  	background-size: 100% auto;
					  	width: 16px;
					  	height: 16px;
					  	margin-top: -8px;
					  	filter: contrast(0);
					  }

					  &.today {
					  	&:before {
					  		filter: contrast(100%);
					  		background: url(../img/icons/@2x/icon-time-accent@2x.png) 0 0 no-repeat;
					  		background-size: 100% auto;
					  		width: 15px;
					  		height: 15px;
					  		margin-top: -7.5px;
					  	}
					  }
					}

					.deadline-info {
						display: block;
						font-size: 13px;
					  text-align: left;
					  color: #333;
					}
	    	}

	    	.show-more-deadlines {
	    		display: block;
	    		text-align: center;
	    		padding: 10px 0;
	    		margin: 0 10px;
	    		border-top: 1px solid #f2f2f2;
	    		text-transform: uppercase;
	    		color: #333;
	    		font-weight: 700;
	    		font-size: 13px;

	    		&:hover {
	    			color: #ff5722;
	    		}
	    	}
	    }

	    .representative,
	    .assigned {
	    	.card-blockquote {
	    		.top {
	    			display: table;
	    			height: 45px;
	    			width: 100%;

	    			.avatar {
	    				width: 45px;

	    				img {
	    					width: 100%;
	    					-webkit-border-radius: 100px;
	    					-moz-border-radius: 100px;
	    					border-radius: 100px;
	    				}
	    			}

	    			.name {
					    font-size: 13px;
					    color: #c5c5c5;
					    padding-left: 15px;

	    				b {
  					    display: block;
						    font-weight: 600;
						    color: #000;
	    				}
	    			}

	    			.avatar,
	    			.name {
	    				display: table-cell;
	    				vertical-align: middle;
	    			}
	    		}

	    		.card-information {
	    			padding-top: 20px;
	    			padding-bottom: 10px;

	    			.icon {
	    				left: 15px;
	    				margin-left: 0;
	    				opacity: .6;

	    				&.icon-phone {
	    					width: 15px;
						    height: 15px;
						    margin-top: -7.5px;
	    				}
	    			}

	    			&>div {
	    				position: relative;
	    				margin-top: 10px;
	    				padding-left: 40px;
	    				color: #939393;
	    				font-size: 13px;
	    				cursor: pointer;

	    				&:first-child {
	    					margin-top: 0;
	    				}
	    			}
	    		}
	    	}
	    }

	    .naroki {
	    	.card-blockquote {
	    		margin: 20px;

	    		.table {
	    			.title,
	    			.price,
	    			.active-title,
	    			.value {
	    				display: block;
	    				color: #aeaeae;
	    				font-family: $font-open-sans;
	    			}

	    			td {
	    				text-align: right;
	    				border: 0;

	    				&:first-child {
	    					text-align: left;
	    				}
	    			}

	    			.title,
	    			.active-title {
	    				font-weight: 600;
	    				margin-bottom: 5px;
	    				font-family: $font-mont;
	    			}

	    			.title {
	    				&.red {
	    					color: #ff5722;
	    				}

	    				&.orange {
	    					color: #ff9800;
	    				}
	    			}

	    			.active-title {
	    				color: #000
	    			}
	    		}
	    	}

	    	.empty {
	    		min-height: 170px;

	    		@media (max-width: 768px) {
	    			min-height: 70px;
	    		}
	    	}
	    }

	    .assigned {
	    	.card-block {
	    		position: relative;
	    	}
	    	&.show {
	    		.hover-block {
	    			height: 315px;
	    			opacity: 1;
	    			visibility: visible;
	    		}
	    	}
	    	.hover-block {
	    		// display: none;
	    		position: absolute;
	    		top: -2px;
	    		// top: -100%;
	    		left: 0;
	    		right: 0;
	    		background: #6639b5;
	    		z-index: 2;
	    		height: 0;
	    		opacity: 0;
	    		visibility: hidden;
	    		height: 315px;
	    		-webkit-transition: all .3s;
	    		-moz-transition: all .3s;
	    		transition: all .3s;
	    		overflow: hidden;

	    		.block-scroll {
	    			&:hover {
	    				.simplebar-scrollbar {
	    					opacity: 1;
	    				}
	    			}
	    		}

	    		.simplebar-track {
	    			background: rgba(255,255,255,0.2);
	    		}

	    		.simplebar-scrollbar {
	    			background: rgba(255,255,255,0.8);
	    			cursor: pointer;
	    		}

	    		.card-blockquote {
	    			height: -webkit-calc(100% - 45px);
						height: -moz-calc(100% - 45px);
						height: calc(100% - 45px);
						overflow: hidden;
	    		}

	    		.assigned-val {
	    			display: block;
	    			margin: 20px 15px 0 0;
	    			padding: 5px;

	    			&:first-child {
	    				margin-top: 0;
	    			}

	    			&:hover {
	    				background: #512d90;
	    			}

	    			.name {
	    				color: #fff;

	    				b {
	    					color: inherit;
	    				}
	    			}
	    		}

	    		.block-scroll {
	    			height: 200px;
	    			margin-top: 30px;
	    		}
	    	}
	    }
    }
	}
}
