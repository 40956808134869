.navbar {
	@media (max-width: 768px) {
		flex-wrap: nowrap;
	}
	.navbar-brand {
		padding: 0 20px 0 30px;

		@media (max-width: 768px) {
			padding-left: 10px;
		}

		.ks-sidebar-mobile-toggle {
			width: 30px;
			height: 30px;
			display: block;

			&.open {
				span {
					&:nth-child(1) {
						top: 14px;
				    transform: rotate(-225deg);
					}

					&:nth-child(2) {
						opacity: 0;
					}

					&:nth-child(3) {
						top: 14px;
				    transform: rotate(-135deg);
					}
				}
			}

			span {
				position: absolute;
				left: 0;
				width: 25px;
				display: block;
				height: 3px;
				background: #fff;
				border-radius: 30px;
				-webkit-transition: all .3s;
				   -moz-transition: all .3s;
				    -ms-transition: all .3s;
				     -o-transition: all .3s;
				        transition: all .3s;

				&:nth-child(1) {
					top: 6px;
				}

				&:nth-child(2) {
					top: 14px;
				}

				&:nth-child(3) {
					top: 22px;
				}
			}

			&:focus,
			&:hover {
				opacity: 1;
			}
		}
	}

	.menu-block {
		position: fixed;
		top: 0;
		bottom: 0;
		left: -380px;
		width: 360px;
		padding-top: 90px;
		background: #212121;
		z-index: 3;
		transition: all .3s;

		@media (max-width: 768px) {
			width: 100%;
			max-width: 360px;
		}

		.wrapper {
			overflow: hidden;
			overflow-y: auto;
			height: 100%;

			&::-webkit-scrollbar-track {
				-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
				border-radius: 10px;
				background-color: #F5F5F5;
				display: none;
			}

			&::-webkit-scrollbar {
				width: 12px;
				background-color: #F5F5F5;
				display: none;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 10px;
				-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
				background-color: #D62929;
				display: none;
			}
		}

		.menu-semi-title {
			display: block;
			font-size: 11px;
		  font-weight: 600;
		  letter-spacing: 1.1px;
		  text-align: left;
		  color: #999999;
		  text-transform: uppercase;
		  margin-bottom: 15px;
		  padding-left: 30px;
		}

		.nav {
			flex-direction: column;

			.nav-item {
				display: block;
				width: 100%;
				transition: all .3s;

				&.active {
					.link {
						background: #1a1a1a;

						&:before {
							opacity: 1;
							visibility: visible;
						}
					}
				}
			}

			.link {
				position: relative;
				display: block;
				font-size: 13px;
			  letter-spacing: 0.3px;
			  text-align: left;
			  color: #ffffff;
			  text-transform: uppercase;
			  padding-left: 74px;
			  padding: 12.5px 0 12.5px 74px;
			  background: transparent;

			  &:before {
			  	position: absolute;
			  	top: 50%;
			  	right: 20px;
			  	content: "";
			  	background: url(../img/icons/@2x/angle-right--white@2x.png) 0 0 no-repeat;
			  	background-size: 100% auto;
			  	width: 6px;
			  	height: 9px;
			  	margin-top: -4.5px;
			  	opacity: 0;
			  	visibility: hidden;
			  	transition: all .3s;
			  }

			  &:hover {
			  	background: #1a1a1a;
			  }

			  .icon {
			  	position: absolute;
			  	top: 50%;
			  	left: 40px;

			  	&.icon-dashboard {
			  		background: url(../img/icons/@2x/icon-mainnav-dashboard@2x.png) 0 0 no-repeat;
			  		width: 16px;
			  		height: 16px;
			  		background-size: 100% auto;
			  		margin-top: -8px;
			  	}

			  	&.icon-cases {
			  		background: url(../img/icons/@2x/icon-mainnav-cases@2x.png) 0 0 no-repeat;
			  		width: 16px;
			  		height: 14px;
			  		background-size: 100% auto;
			  		margin-top: -7px;
			  	}

			  	&.icon-documents {
			  		background: url(../img/icons/@2x/icon-mainnav-docs@2x.png) 0 0 no-repeat;
			  		width: 13px;
			  		height: 16px;
			  		background-size: 100% auto;
			  		margin-top: -8px;
			  	}
			  	&.icon-contacts {
			  		background: url(../img/icons/@2x/icon-mainnav-contacts@2x.png) 0 0 no-repeat;
			  		width: 16px;
			  		height: 16px;
			  		background-size: 100% auto;
			  		margin-top: -8px;
			  	}
			  	&.icon-invoices {
			  		background: url(../img/icons/@2x/icon-mainnav-invoices@2x.png) 0 0 no-repeat;
			  		width: 16px;
			  		height: 12px;
			  		background-size: 100% auto;
			  		margin-top: -6px;
			  	}
			  	&.icon-email {
			  		background: url(../img/icons/@2x/icon-mainnav-emails@2x.png) 0 0 no-repeat;
			  		width: 16px;
			  		height: 14px;
			  		background-size: 100% auto;
			  		margin-top: -7px;
			  	}
			  	&.icon-calendar {
			  		background: url(../img/icons/@2x/icon-mainnav-calendar@2x.png) 0 0 no-repeat;
			  		width: 16px;
			  		height: 16px;
			  		background-size: 100% auto;
			  		margin-top: -8px;
			  	}
			  	&.icon-team {
			  		background: url(../img/icons/@2x/icon-mainnav-team@2x.png) 0 0 no-repeat;
			  		width: 16px;
			  		height: 16px;
			  		background-size: 100% auto;
			  		margin-top: -8px;
			  	}
			  	&.icon-settings {
			  		background: url(../img/icons/@2x/icon-mainnav-settings@2x.png) 0 0 no-repeat;
			  		width: 16px;
			  		height: 16px;
			  		background-size: 100% auto;
			  		margin-top: -8px;
			  	}
			  }

			  .text {
			  	cursor: pointer;
			  }
			}
		}

		.block {
			margin-bottom: 15px;
		}

		.dropdown-block {
			margin: 0 15px;
			background: #1a1a1a;
			padding: 15px 15px 15px;
			border-radius: 12px;

			.menu-semi-title {
				padding-left: 0;
				position: relative;
				margin-bottom: 0;

				&.open {
					&:before {
						transform: rotate(0);
					}
				}
				&:before {
					position: absolute;
					top: 50%;
					right: 3px;
					content: "";
					background: url(../img/icons/@2x/angle-up--white@2x.png) 0 0 no-repeat;
					width: 9px;
					height: 6px;
					background-size: 100% auto;
					opacity: 0.4;
					margin-top: -3px;
					transition: all .3s;
					transform: rotate(180deg);
				}
			}

			.custom-dropdown {
				margin-top: 25px;
				padding-bottom: 15px;
			}

			.datepicker-block {
				.datepicker table tr td.new,
				.datepicker table tr td.old {
					opacity: 0.4;
				}
				.datepicker table tr td.day {
					position: relative;
					color: #fff;
					vertical-align: top;
				}

				.datepicker td.day:hover, .datepicker th.day:hover {
					background: #333333 !important;
					color: #fff;
				}
				
				.datepicker td.today,
				.datepicker th.today,
				.datepicker td.today:hover,
				.datepicker th.today:hover {
					background: #000 !important;
					color: #fff !important;
				}

				.datepicker .datepicker-switch,
				.datepicker .next,
				.datepicker .prev,
				.datepicker tfoot tr th {
					color: #fff;
				}

				.datepicker .datepicker-switch:hover,
				.datepicker .next:hover,
				.datepicker .prev:hover,
				.datepicker tfoot tr th:hover {
					color: #000;
				}


				.datepicker td, .datepicker th {
					height: 30px;
				}

				.events-block {
					position: absolute;
					bottom: 5px;
					left: 0;
					right: 0;
					display: flex;
					justify-content: center;

					.event-link {
						display: inline-block;
						width: 4px;
						height: 4px;
						border-radius: 10px;
						margin: 0 1px;

						&.deadline {
							background: #ff5722;
						}

						&.meeting {
							background: #00bcd4;
						}

						&.task {
							background: #ff9800;
						}
					}
				}
			}

			.fc-toolbar.fc-header-toolbar {
				margin-bottom: 0;
			}

			.fc .fc-toolbar > * > :first-child {
		    color: #fff;
		    font-weight: 600;
		    line-height: 1em;
		    font-size: 14px;
			}

			.fc tr {
				position: relative;
			}

			.fc th, .fc td {
				width: 42px;
				height: 42px;
				border: 0;
		    // position: relative;
		    color: #fff;
		    vertical-align: top;
		    font-size: 13px;
		    text-align: center;
		    border-radius: 4px;

		    td {
		    	height: 5px;
		    	width: auto;
		    }
			}

			.fc th {
				color: #ccc;
				padding: 5px 0;
				height: 30px;
				vertical-align: middle;
			}

			.fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
				float: none;
			}

			.fc-highlight {
				background: #333;
				transition: all .3s;
			}

			.fc-unthemed td.fc-today {
				color: #fff;
				background: #000;
			}

			.fc-basic-view .fc-body .fc-row {
				min-height: 42px;
			}

			tr:nth-child(2) > td > .fc-day-grid-event {
				// margin-left: -8px;
			}

			tr > td.fc-event-container {
				// position: absolute;
				// top: 0;
				// left: 0;
				// width: 4px;
				// height: 4px;
				// border-radius: 30px;
			}

			tr > td > .fc-day-grid-event {
				position: absolute;
		    // top: 0; 
		    top: 20px;
		    // left: 50%;
		    width: 4px; 
		    height: 4px; 
		    border: 0; 
		    margin: 0; 
		    border-radius: 30px; 
		    padding: 0;
		    // margin-left: -2px;
		    // margin: 0 auto;
		    margin-top: 0;
		    margin-left: 14px;
		    cursor: pointer;

		    &.deadline_type {
		    	background: #ff5722;
		    	margin-left: 20px;
		    }

		    &.meeting_type {
		    	background: #00bcd4;
		    }

		    &.task_type {
		    	background: #ff9800;
		    	margin-left: 26px;
		    }
			}

			.today-block {
			  background-color: #000000;
			  border-radius: 0 0 12px 12px;
			  padding: 15px 15px 20px;
			  .today-link {
	  	    position: relative;
			    display: block;
			    padding-left: 23px;
			    font-size: 16px;
			    font-weight: 600;
			    letter-spacing: 0.4px;
			    text-align: left;
			    color: #ffffff;
			    margin-bottom: 7px;

			    &:before {
			    	position: absolute;
				    top: 50%;
				    left: 0;
				    content: "";
				    background: url(../img/icons/@2x/icon-time--white@2x.png) 0 0 no-repeat;
				    -webkit-background-size: 100% auto;
				    background-size: 100% auto;
				    width: 16px;
				    height: 16px;
				    margin-top: -8px;
			    }
			  }

			  .event-block {
			  	margin-top: 25px;

			  	.item-row {
			  		display: flex;
			  		margin-top: 10px;

			  		&:first-child {
			  			margin-top: 0;
			  		}
			  	}

			  	.event-title {
			  		position: relative;
			  		flex: 0 100%;
			  		display: inline-block;
			  		padding-left: 15px;
			  		font-size: 13px;
			  		color: #fff;
			  		white-space: nowrap;
			  		overflow: hidden;
			  		text-overflow: ellipsis;

			  		&:before {
			  			position: absolute;
			  			top: 50%;
			  			left: 0;
			  			width: 10px;
			  			height: 10px;
			  			content: "";
			  			border-radius: 20px;
			  			margin-top: -5px;
			  		}

			  		&.meeting {
			  			&:before {
			  				background: #00bcd4;
			  			}
			  		}

			  		&.deadline {
			  			&:before {
			  				background: #ff5722;
			  			}
			  		}

			  		&.task {
			  			&:before {
			  				background: #ffc107;
			  			}
			  		}
			  	}

			  	.time {
			  		display: inline-block;
			  		flex: 0 85px;
			  		min-width: 85px;
			  		font-size: 13px;
					  text-align: right;
					  color: #9e9e9e;
			  	}
			  }
			}

			.deadlines-block {
				.deadline-inner {
					display: block;
					margin-top: 25px;

					&:first-child {
						margin-top: 0;
					}

					.deadline-link {
						position: relative;
						display: block;
						padding-left: 23px;
						font-size: 16px;
					  font-weight: 600;
					  letter-spacing: 0.4px;
					  text-align: left;
					  color: #ffffff;
					  margin-bottom: 7px;

					  &:before {
					  	position: absolute;
					  	top: 50%;
					  	left: 0;
					  	content: "";
					  	background: url(../img/icons/@2x/icon-time@2x.png) 0 0 no-repeat;
					  	background-size: 100% auto;
					  	width: 16px;
					  	height: 16px;
					  	margin-top: -8px;
					  	filter: contrast(0);
					  }

					  &.today {
					  	&:before {
					  		filter: contrast(100%);
					  		background: url(../img/icons/@2x/icon-time-accent@2x.png) 0 0 no-repeat;
					  		background-size: 100% auto;
					  		width: 15px;
					  		height: 15px;
					  		margin-top: -7.5px;
					  	}
					  }
					}

					.deadline-info {
						display: block;
						font-size: 13px;
					  text-align: left;
					  color: #ffffff;
					}
				}
			}

			.cases-block {
				.recent-cases-link {
					position: relative;
					display: block;
					font-size: 13px;
				  font-weight: 600;
				  text-align: left;
				  color: #ffffff;
				  margin-top: 15px;

				  &:first-child {
				  	margin-top: 0;
				  }
				}
			}
		}
	}
}

.open-menu {
	.menu-overflow {
		opacity: 1;
		visibility: visible;
	}
	.navbar .menu-block {
		left: 0;
	}
}
.menu-overflow {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background: rgba(#212121, 0.25);
	cursor: pointer;
	z-index: 2;
	opacity: 0;
	visibility: hidden;
	transition: all .3s;
}