@mixin ellipsis(){
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    width: 100%;
}

@mixin icon-styles(){
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin transform($transform){
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -ms-transform: $transform;
  -o-transform: $transform;
  transform: $transform;
}

@media screen and (max-width: 768px) {
  .nav-tabs-responsive {
    > li {
      display: none;
      width: 23%;
      > a {
        @include ellipsis();
        width: 100%;
        text-align: center;
        vertical-align: top;
      }
      &.active {
        width: 54%;
        &:first-child {
          margin-left: 23%;
        }
      }
      &.active,
      &.prev,
      &.next {
        display: block;
      }
      &.prev,
      &.next {
        // -webkit-transform: scale(0.9);
        // transform: scale(0.9);
      }
      &.next > a,
      &.prev > a {
        -webkit-transition: none;
        transition: none;
        .text {
          display: none;
        }
        &:after,
        &:after {
          @include icon-styles();
        }
      }
      &.prev > a:after {
        border: 0;
		    background: url(../img/icons/@2x/angle-down--white@2x.png) 0 0 no-repeat;
		    -webkit-background-size: 100% auto;
		    -moz-background-size: 100% auto;
		    background-size: 100% auto;
        width: 12px;
		    height: 10px;
		    content: "";
		    -moz-transition: all 0.3s;
		    -o-transition: all 0.3s;
		    -webkit-transition: all 0.3s;
		    transition: all 0.3s;
		    -webkit-transform: rotate(90deg);
		    -moz-transform: rotate(90deg);
		    transform: rotate(90deg);
      }
      &.next > a:after {
        border: 0;
		    background: url(../img/icons/@2x/angle-down--white@2x.png) 0 0 no-repeat;
		    -webkit-background-size: 100% auto;
		    -moz-background-size: 100% auto;
		    background-size: 100% auto;
        width: 12px;
		    height: 10px;
		    content: "";
		    -moz-transition: all 0.3s;
		    -o-transition: all 0.3s;
		    -webkit-transition: all 0.3s;
		    transition: all 0.3s;
		    -webkit-transform: rotate(-90deg);
		    -moz-transform: rotate(-90deg);
		    transform: rotate(-90deg);
      }
      &.dropdown {
        > a > .caret {
          display: none;
        }
        > a:after {
          content: "\e114";
        }
        &.active > a {
          &:after {
            display: none;
          }
          > .caret {
            display: inline-block;
          }
        }

        .dropdown-menu {
          &.pull-xs-left {
            left: 0;
            right: auto;
          }
          &.pull-xs-center {
            right: auto;
            left: 50%;
            @include transform(translateX(-50%));
          }
          &.pull-xs-right {
            left: auto;
            right: 0;
          }
        }
      }
    }
  }
}
