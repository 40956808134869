html {
  background: #ededed;
}

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

a {
  outline: none;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s;
  opacity: 1;

  &:hover {
    outline: none !important;
    opacity: 0.8;
  }

  &:focus {
    outline: none !important;
  }

}

*:focus {
  outline: none;
}

h1 {
  font-size: $h1-font-size;
  line-height: $h1-line-height;
}

h2 {
  font-size: $h2-font-size;
  line-height: $h2-line-height;
}

h3 {
  font-size: $h3-font-size;
  line-height: $h3-line-height;
}

h4 {
  font-size: $h4-font-size;
  line-height: $h4-line-height;
}

h5 {
  font-size: $h5-font-size;
  line-height: $h5-line-height;
}

h6 {
  font-size: $h6-font-size;
  line-height: $h6-line-height;
}

body {
  background: $body-background;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
  color: $body-color;
  -webkit-font-smoothing: antialiased;
}

a {
  color: $a-link-color;
  text-decoration: none;
  
  &:hover,
  &:focus {
    color: $a-hover-color;
    text-decoration: none;
  }
}

input[type="text"],
input[type="email"],
input[type="password"],
textarea {
  font: {
    family: $base-font-family;
  }
  font-size: $base-font-size;
  color: $body-color;
  -webkit-appearance: none;
  background-clip: padding-box;
  border-radius: 0;
}

//clearfix
.clearfix {
  &:before,
  &:after {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }
}


.container {
  padding-left: 15px;
  padding-right: 15px;
}

.left {
  float: left;
}

.right {
  float: right;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}


//Customization

.input-icon {
  position: relative;

  &.icon-right {
    input {
      padding-right: 45px;
    }
  }

  &.icon-left {
    input {
      padding-left: 45px;
    }
  }
}

.mCSB_inside>.mCSB_container {
  margin-right: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.hidden {
  display: none;
  opacity: 0;
  visibility: hidden;
}

.no_icon {
  &:after {
    display: none !important;
  }

  background: none !important;
}

[v-cloak] {
  display: none !important;
}

.no-mg {
  margin: 0 !important;
}

.no-mg-l {
  margin-left: 0 !important;
}

.no-mg-r {
  margin-right: 0 !important;
}

.no-mg-t {
  margin-top: 0 !important;
}

.no-mg-b {
  margin-bottom: 0 !important;
}

.no-pd {
  padding: 0 !important;
}

.no-pd-l {
  padding-left: 0 !important;
}

.no-pd-r {
  padding-right: 0 !important;
}

.no-pd-t {
  padding-top: 0 !important;
}

.no-pd-b {
  padding-bottom: 0 !important;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.swal2-container {
  z-index: 9999999999999999999;
}

.iziToast-wrapper {
  z-index: 999999;
}

