.el-input--suffix .el-input__inner {
  box-shadow: none;
}

.el-input--suffix .el-input__inner:hover {
  box-shadow: 0 1px 4px rgba(0,0,0,0.2);
}

.ql-toolbar.ql-snow {
  border: 0;
  border-bottom: 1px solid #ccc;
}

.ql-container.ql-snow {
  border: 0;
}

.el-input__prefix .el-icon-date {
  display: none;
}

.ks-contacts .content .pane-content.cases .contact-content .table tr td.priority-cell2,
.ks-contacts .content .pane-content.cases .contact-content .table tr td.priority-cell,
.ks-contacts .content .pane-content.cases .contact-content .table tr th.priority-cell2, 
.ks-contacts .content .pane-content.cases .contact-content .table tr th.priority-cell {
  -webkit-box-flex: 1;
  -webkit-flex: 1 105px;
  -ms-flex: 1 105px;
  flex: 1 105px;
  min-width: 80px;
}

.ks-contacts .content .pane-content.cases .contact-content .table tr th.checkbox-cell .dropdown-toggle:after {
  display: none;
}

.el-input__prefix .el-icon-date {
  position: relative;
  z-index: 1;
  display: none;
}

/* ADD MEETING MODAL */
.modal-profile.add-meeting .modal-body .expires-group > .form-control {
  padding: 2px 0 2px 40px;
  -webkit-border-radius: 15px;
  border-radius: 15px;
  border: 1px solid #e6e6e6;
}

.modal-profile.add-meeting .modal-body .expires-group:before {
  z-index: 2;
}

.modal-profile.add-meeting .modal-body .expires-group > .form-control.is-disabled {
  background: #f5f7fa;
  position: relative;
}

.modal-profile.add-meeting .modal-body .expires-group > .form-control .el-input__inner {
  padding-left: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.modal-profile.add-meeting .modal-body .expires-group > .form-control .el-icon-time {
  display: none;
}

.modal-profile.add-meeting .modal-body .el-select.ks-custom-select-no-search {
  padding-left: 0;
}

.modal-profile.add-meeting .modal-body .el-select.ks-custom-select-no-search .el-input__inner {
  padding: 0 15px;
  color: #333 !important;
  font-weight: 400;
  -webkit-box-shadow: 0 1px 4px rgba(0,0,0,0.2);
  box-shadow: 0 1px 4px rgba(0,0,0,0.2);
  height: 45px !important;
}
.modal-profile.add-meeting .modal-body .el-select.ks-custom-select-no-search .el-input__inner:hover {
  -webkit-box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.2);
  box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.2);
    
}

.modal-profile.add-meeting .modal-body > .row > .col-12.col-md-6:nth-child(2) .el-input--suffix .el-input__inner {
  width: 100%;
  border: 1px solid #e6e6e6 !important;
  padding: 0 15px;
  height: 45px;
}

.modal-profile.add-meeting .modal-body > .row > .col-12.col-md-6:nth-child(2) .el-select {
  width: 100%;
}

.el-scrollbar__view.el-select-dropdown__list .el-select-dropdown__item {
  /*padding: 0;*/
}

.el-scrollbar__view.el-select-dropdown__list .el-select-dropdown__item > span {
  display: block;
  padding: 15px;
  -webkit-transition: all .3s;
     -moz-transition: all .3s;
      -ms-transition: all .3s;
       -o-transition: all .3s;
          transition: all .3s;
}

.el-scrollbar__view.el-select-dropdown__list .el-select-dropdown__item.hover {
  background: none;
}

.el-scrollbar__view.el-select-dropdown__list .el-select-dropdown__item.hover > span {
  background: #f2fcfd;
}

.el-scrollbar__view.el-select-dropdown__list .el-select-dropdown__item.hover .person-block {
  background: #f2fcfd;
}
.el-scrollbar__view.el-select-dropdown__list .el-select-dropdown__item.hover .item .btn {
  background: #00bcd4;
  color: #fff;
  -webkit-box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.3);
  box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.3);
}

.el-scrollbar__view.el-select-dropdown__list .item {
  position: relative;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  border-top: 2px solid #f2f2f2;
}

.el-scrollbar__view.el-select-dropdown__list .item:first-child {
  border-top: 0;
}

.el-scrollbar__view.el-select-dropdown__list .item .person-block {
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  padding: 15px;
}


.el-scrollbar__view.el-select-dropdown__list .item .person-block .inner {
  display: table;
  height: 45px;
  width: 100%;
  margin: 0;
  padding: 0;
}

.el-scrollbar__view.el-select-dropdown__list .item .person-block .inner .name,
.el-scrollbar__view.el-select-dropdown__list .item .person-block .inner .avatar {
  display: table-cell;
  vertical-align: middle;
}

.el-scrollbar__view.el-select-dropdown__list .item .person-block .inner .avatar {
  height: 45px;
  width: 45px;
}
.el-scrollbar__view.el-select-dropdown__list .item .person-block .inner .avatar img {
  width: 100%;
  -webkit-border-radius: 100px;
  border-radius: 100px;
}
.el-scrollbar__view.el-select-dropdown__list .item .person-block .inner .name {
  font-size: 13px;
  color: #a3a3a3;
  padding-left: 15px
}
.el-scrollbar__view.el-select-dropdown__list .item .person-block .inner .name b {
  display: block;
  font-weight: 600;
  color: #000;
}

.el-scrollbar__view.el-select-dropdown__list .item .btn {
  position: absolute;
  top: 50%;
  right: 15px;
  margin-top: -22.5px;
  padding: 12px 15px 12px 15px;
  text-align: center;
  text-transform: uppercase;
  -webkit-border-radius: 15px;
  border-radius: 15px;
  background: #fff;
  color: #000;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  font-size: 13px;
  font-weight: 700;
}
.modal-profile.deadline .list-block .list-item .remove-item,
.modal-profile.add-meeting .modal-body .list-block .list-item .remove-item {
  top: 50%;
  margin-top: -15px;
}
/* END ADD MEETING MODAL */

/* MODAL DEADLINE */
.modal-profile.deadline .el-input--suffix .el-input__inner {
  padding: 0 30px 0 15px;
  -webkit-box-shadow: 0 1px 4px rgba(0,0,0,0.2);
  box-shadow: 0 1px 4px rgba(0,0,0,0.2);
  height: 45px;
}
.modal-profile.deadline .el-input--suffix .el-input__inner:hover {
  -webkit-box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.2);
  box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.2);
}
.modal-profile.deadline .list-block {
  padding: 30px 0;
}

.modal-profile.deadline .modal-body .row.notification div[class^="col-"] input {
  padding-left: 30px;
}

.modal-profile.deadline .list-block .no-result {
      display: block;
    text-align: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1 100%;
    -ms-flex: 1 100%;
    flex: 1 100%;
    padding: 27px 0;
    color: #999;
}
.modal-profile.deadline .list-block h6 {
  display: block;
  font-weight: 600;
  color: #949494;
  line-height: 1em;
  font-size: 14px;
  margin-bottom: 15px;
  text-transform: uppercase;
}
.modal-profile.deadline .list-block .list-item {
  position: relative;
  background: #fff;
  -webkit-border-radius: 15px;
  border-radius: 15px;
  -webkit-box-shadow: 0 1px 4px rgba(0,0,0,0.2);
  box-shadow: 0 1px 4px rgba(0,0,0,0.2);
}
.modal-profile.deadline .list-block .list-item .item {
  position: relative;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  border-top: 2px solid #f2f2f2;
}

.modal-profile.deadline .list-block .list-item .item:first-child {
  border-top: 0;
}

.modal-profile.deadline .list-block .list-item .person-block {
      -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    padding: 15px;
}
.modal-profile.deadline .list-block .list-item .person-block .inner {
  display: table;
    height: 45px;
    width: 100%;
    margin: 0;
    padding: 0;
}
.modal-profile.deadline .list-block .list-item .person-block .inner .name,
.modal-profile.deadline .list-block .list-item .person-block .inner .avatar {
  display: table-cell;
    vertical-align: middle;
}
.modal-profile.deadline .list-block .list-item .person-block .inner .avatar {
  width: 45px;
}
.modal-profile.deadline .list-block .list-item .person-block .inner .avatar img{
  width: 100%;
  -webkit-border-radius: 100px;
  border-radius: 100px
}
.modal-profile.deadline .list-block .list-item .person-block .inner .name {
      font-size: 13px;
    color: #a3a3a3;
    padding-left: 15px
}
.modal-profile.deadline .list-block .list-item .person-block .inner .name b {
  display: block;
    font-weight: 600;
    color: #000
}

.modal-profile.deadline .list-block .list-item .remove-item {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  display: block;
}

.modal-profile.deadline .list-block .list-item .remove-item {
  top: 50%;
  margin-top: -15px;
}

.deadline__users_select {
  min-width: 350px !important;
}

/* END MODAL DEADLINE */

.search-row .custom-search-block.custom-datepicker:before {
  position: absolute;
  top: 50%;
  left: 10px;
  content: "";
  background: url(../img/icons/@2x/icon-date@2x.png) 0 0 no-repeat;
  width: 16px;
  height: 16px;
  -webkit-background-size: auto 100%;
  background-size: auto 100%;
  margin-top: -8px;
  opacity: .3;
  z-index: 11;
}

.search-row .custom-search-block .el-input--suffix .el-input__inner {
  padding: 0 15px;
}

.search-row .set-daterange-time .el-input--suffix .el-input__inner {
  padding-left: 45px;
}
/*.custom-search-block.custom-datepicker {
  padding-left: 45px;
}*/

/* CREATE CASE */

.create-case #set-date .el-input--suffix:before {
  position: absolute;
  top: 50%;
  left: 10px;
  content: "";
  background: url(../img/icons/@2x/icon-date@2x.png) 0 0 no-repeat;
  width: 16px;
  height: 16px;
  -webkit-background-size: auto 100%;
  background-size: auto 100%;
  margin-top: -8px;
  opacity: .3;
  z-index: 11;
}

.create-case #set-date .el-input--suffix .el-input__inner {
  padding: 0 15px;
  padding-left: 45px;
  border: 1px solid #e6e6e6 !important;
}

.create-case #set-date .el-input--suffix .el-input__inner:hover {
  -webkit-box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.2);
  box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.2);
}
.create-case .quill-editor {
  margin-bottom: 15px;
  border: 0;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0,0,0,0.16);
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.16);
  -webkit-border-radius: 12px;
  border-radius: 12px;
}

.create-case .quill-editor .ql-editor {
  min-height: 100px;
}

.create-case .client-information .el-input--suffix .el-input__inner {
  padding: 0 15px;
  -webkit-box-shadow: 0 1px 4px rgba(0,0,0,0.2);
 box-shadow: 0 1px 4px rgba(0,0,0,0.2); 
}
.create-case .client-information .el-input--suffix .el-input__inner:hover {
  -webkit-box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.2);
  box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.2);
}

.modal-profile.edit-case.create-case .team-information .list-block .list-item .no-assigned-lawyers {
  position: relative;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
}
.modal-profile.edit-case.create-case .team-information .list-block .list-item .no-assigned-lawyers > span{
  display: block;
  text-align: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1 100%;
  -ms-flex: 1 100%;
  flex: 1 100%;
  padding: 27px 0;
  color: #999;
}

/* END CREATE CASE */

/* task page */
.tasks__priority_select .el-scrollbar__view.el-select-dropdown__list .el-select-dropdown__item {
  padding: 12px 16px;
}

.ks-contacts .content .pane-content .contact-content .table td.checkbox-cell .custom-checkbox {
  margin-top: 2px;
}

.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .priority-cell .el-input--suffix .el-input__inner,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.priority-cell2 .el-input--suffix .el-input__inner {
  width: 30px;
  height: 30px;
  text-indent: -999px;
  padding: 0;
}
.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .priority-cell .el-input--suffix .el-input__suffix,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.priority-cell2 .el-input--suffix .el-input__suffix {
  display: none;
}

.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .priority-cell .el-input--suffix .el-input__prefix,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.priority-cell2 .el-input--suffix .el-input__prefix {
  left: 0;
}

.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .priority-cell .el-input--suffix .drop_priority_nopriority,
.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .priority-cell .el-input--suffix .drop_priority_medium,
.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .priority-cell .el-input--suffix .drop_priority_high,
.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .priority-cell .el-input--suffix .drop_priority_low,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.priority-cell2 .el-input--suffix .drop_priority_nopriority,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.priority-cell2 .el-input--suffix .drop_priority_medium,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.priority-cell2 .el-input--suffix .drop_priority_high,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.priority-cell2 .el-input--suffix .drop_priority_low {
  height: 30px;
  width: 30px;
  padding: 0;
  cursor: pointer;
}

.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .priority-cell .el-input--suffix .drop_priority_nopriority:before,
.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .priority-cell .el-input--suffix .drop_priority_medium:before,
.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .priority-cell .el-input--suffix .drop_priority_high:before,
.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .priority-cell .el-input--suffix .drop_priority_low:before,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.priority-cell2 .el-input--suffix .drop_priority_nopriority:before,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.priority-cell2 .el-input--suffix .drop_priority_medium:before,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.priority-cell2 .el-input--suffix .drop_priority_high:before,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.priority-cell2 .el-input--suffix .drop_priority_low:before {
  line-height: 30px;
  width: 30px;
  height: 30px;
  top: 0;
  left: 0;
  z-index: 1;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .priority-cell .el-input--suffix .drop_priority_low:after,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.priority-cell2 .el-input--suffix .drop_priority_low:after {
  position: absolute;
  height: 30px;
  width: 15px;
  top: 0;
  right: 0;
  margin: 0;
  content: "";
  -webkit-border-radius: 0 100px 100px 0;
  border-radius: 0 100px 100px 0;
  background: #00bcd4 url(../img/icons/angle-down-full--white.png) -7px 50% no-repeat;
  z-index: 1;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  z-index: 0;
}

.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .priority-cell .el-input--suffix .drop_priority_high:after,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.priority-cell2 .el-input--suffix .drop_priority_high:after {
  position: absolute;
  height: 30px;
  width: 15px;
  top: 0;
  right: 0;
  margin: 0;
  content: "";
  -webkit-border-radius: 0 100px 100px 0;
  border-radius: 0 100px 100px 0;
  background: #ff5722 url(../img/icons/angle-down-full--white.png) -7px 50% no-repeat;
  z-index: 1;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  z-index: 0;
}

.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .priority-cell .el-input--suffix .drop_priority_medium:after,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.priority-cell2 .el-input--suffix .drop_priority_medium:after {
  position: absolute;
  height: 30px;
  width: 15px;
  top: 0;
  right: 0;
  margin: 0;
  content: "";
  -webkit-border-radius: 0 100px 100px 0;
  border-radius: 0 100px 100px 0;
  background: #673ab7 url(../img/icons/angle-down-full--white.png) -7px 50% no-repeat;
  z-index: 1;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  z-index: 0;
}

.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .priority-cell .el-input--suffix .drop_priority_nopriority:after,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.priority-cell2 .el-input--suffix .drop_priority_nopriority:after {
  position: absolute;
  height: 30px;
  width: 15px;
  top: 0;
  right: 0;
  margin: 0;
  content: "";
  -webkit-border-radius: 0 100px 100px 0;
  border-radius: 0 100px 100px 0;
  background: #e6e6e6 url(../img/icons/angle-down-full--white.png) -7px 50% no-repeat;
  z-index: 1;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  z-index: 0;
}

.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .priority-cell .el-input--suffix:hover .drop_priority_nopriority:before,
.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .priority-cell .el-input--suffix:hover .drop_priority_medium:before,
.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .priority-cell .el-input--suffix:hover .drop_priority_high:before,
.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .priority-cell .el-input--suffix:hover .drop_priority_low:before,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.priority-cell2 .el-input--suffix:hover .drop_priority_nopriority:before,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.priority-cell2 .el-input--suffix:hover .drop_priority_medium:before,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.priority-cell2 .el-input--suffix:hover .drop_priority_high:before,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.priority-cell2 .el-input--suffix:hover .drop_priority_low:before {
  -webkit-border-radius: 100px 0 0 100px;
  border-radius: 100px 0 0 100px;
}

.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .priority-cell .el-input--suffix:hover .drop_priority_nopriority:after,
.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .priority-cell .el-input--suffix:hover .drop_priority_medium:after,
.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .priority-cell .el-input--suffix:hover .drop_priority_high:after,
.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .priority-cell .el-input--suffix:hover .drop_priority_low:after,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.priority-cell2 .el-input--suffix:hover .drop_priority_nopriority:after,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.priority-cell2 .el-input--suffix:hover .drop_priority_medium:after,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.priority-cell2 .el-input--suffix:hover .drop_priority_high:after,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.priority-cell2 .el-input--suffix:hover .drop_priority_low:after {
  right: -15px;
  background-position: 0 50%;
}

.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .status-cell .el-input--suffix .el-input__inner,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.status-cell .el-input--suffix .el-input__inner {
  padding: 0;
  z-index: 2;
  position: relative;
  background: transparent;
  height: 30px;
  line-height: 30px;
  width: 120px;
  font-size: 13px;
  color: #fff;
  border-radius: 100px;
  padding: 7px 0;
  text-align: center;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .status-cell .el-input--suffix .el-input__prefix,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.status-cell .el-input--suffix .el-input__prefix {
  left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 100px;
}

.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .status-cell .el-input--suffix .el-input__suffix,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.status-cell .el-input--suffix .el-input__suffix {
  display: none;
}


.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .status-cell .el-input--suffix .drop_status_nostatus,
.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .status-cell .el-input--suffix .drop_status_stuck,
.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .status-cell .el-input--suffix .drop_status_done,
.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .status-cell .el-input--suffix .drop_status_workingonit,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.status-cell .el-input--suffix .drop_status_nostatus,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.status-cell .el-input--suffix .drop_status_stuck,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.status-cell .el-input--suffix .drop_status_done,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.status-cell .el-input--suffix .drop_status_workingonit {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .status-cell .el-input--suffix .drop_status_nostatus:before,
.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .status-cell .el-input--suffix .drop_status_stuck:before,
.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .status-cell .el-input--suffix .drop_status_done:before,
.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .status-cell .el-input--suffix .drop_status_workingonit:before,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.status-cell .el-input--suffix .drop_status_nostatus:before,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.status-cell .el-input--suffix .drop_status_stuck:before,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.status-cell .el-input--suffix .drop_status_done:before,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.status-cell .el-input--suffix .drop_status_workingonit:before {
  top: 0;
  width: auto;
  left: 0;
  right: 0;
  bottom: 0;
  height: auto;
  -webkit-transition: all .3s;
     -moz-transition: all .3s;
      -ms-transition: all .3s;
       -o-transition: all .3s;
          transition: all .3s;
}

.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .status-cell .el-input--suffix .drop_status_workingonit:after,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.status-cell .el-input--suffix .drop_status_workingonit:after {
  position: absolute;
  height: 30px;
  width: 15px;
  top: 0;
  right: 0;
  margin: 0;
  content: "";
  -webkit-border-radius: 0 100px 100px 0;
  border-radius: 0 100px 100px 0;
  background: #2196f3 url(../img/icons/angle-down-full--white.png) -7px 50% no-repeat;
  z-index: 1;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  z-index: 0;
}

.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .status-cell .el-input--suffix .drop_status_done:after,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.status-cell .el-input--suffix .drop_status_done:after {
  position: absolute;
  height: 30px;
  width: 15px;
  top: 0;
  right: 0;
  margin: 0;
  content: "";
  -webkit-border-radius: 0 100px 100px 0;
  border-radius: 0 100px 100px 0;
  background: #4caf50 url(../img/icons/angle-down-full--white.png) -7px 50% no-repeat;
  z-index: 1;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  z-index: 0;
}

.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .status-cell .el-input--suffix .drop_status_stuck:after,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.status-cell .el-input--suffix .drop_status_stuck:after {
  position: absolute;
  height: 30px;
  width: 15px;
  top: 0;
  right: 0;
  margin: 0;
  content: "";
  -webkit-border-radius: 0 100px 100px 0;
  border-radius: 0 100px 100px 0;
  background: #ffc107 url(../img/icons/angle-down-full--white.png) -7px 50% no-repeat;
  z-index: 1;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  z-index: 0;
}

.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .status-cell .el-input--suffix .drop_status_nostatus:after,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.status-cell .el-input--suffix .drop_status_nostatus:after {
  position: absolute;
  height: 30px;
  width: 15px;
  top: 0;
  right: 0;
  margin: 0;
  content: "";
  -webkit-border-radius: 0 100px 100px 0;
  border-radius: 0 100px 100px 0;
  background: #e6e6e6 url(../img/icons/angle-down-full--white.png) -7px 50% no-repeat;
  z-index: 1;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  z-index: 0;
}

.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .status-cell .el-input--suffix:hover .drop_status_nostatus:before,
.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .status-cell .el-input--suffix:hover .drop_status_stuck:before,
.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .status-cell .el-input--suffix:hover .drop_status_done:before,
.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .status-cell .el-input--suffix:hover .drop_status_workingonit:before,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.status-cell .el-input--suffix:hover .drop_status_nostatus:before,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.status-cell .el-input--suffix:hover .drop_status_stuck:before,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.status-cell .el-input--suffix:hover .drop_status_done:before,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.status-cell .el-input--suffix:hover .drop_status_workingonit:before {
  -webkit-border-radius: 100px 0 0 100px;
  border-radius: 100px 0 0 100px;
}

.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .status-cell .el-input--suffix:hover .drop_status_nostatus:after,
.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .status-cell .el-input--suffix:hover .drop_status_stuck:after,
.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .status-cell .el-input--suffix:hover .drop_status_done:after,
.ks-contacts .content .pane-content .contact-content .custom-scroll-table .task-table .status-cell .el-input--suffix:hover .drop_status_workingonit:after,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.status-cell .el-input--suffix:hover .drop_status_nostatus:after,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.status-cell .el-input--suffix:hover .drop_status_stuck:after,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.status-cell .el-input--suffix:hover .drop_status_done:after,
.ks-contacts .content .pane-content.cases .contact-content .table .drop tr td.status-cell .el-input--suffix:hover .drop_status_workingonit:after {
  right: -15px;
  background-position: 0 50%;
}
.modal-profile.add-task tr {
  -webkit-align-items: flex-start !important;
  -ms-flex-align: flex-start !important;
  align-items: flex-start !important;
}

.modal-profile.add-task .date-cell .custom-search-block .el-input__inner {
  border: 1px solid #e6e6e6 !important;
  height: 44px;
}

.modal-profile.add-task .date-cell .custom-search-block:before {
  top: 51px;
}

.ks-contacts .content .pane-content.cases .contact-content .table tr td.date-cell .has-danger .form-title {
  display: block;
}

.modal-profile.add-task .options-cell .btn-new {
  cursor: pointer;
}

.modal-profile.add-task .status-cell .el-input--suffix .el-input__inner,
.modal-profile.add-task .priority-cell2 .el-input--suffix .el-input__inner {
  -webkit-box-shadow: 0 1px 4px rgba(0,0,0,0.2);
  box-shadow: 0 1px 4px rgba(0,0,0,0.2);
}
.modal-profile.add-task .status-cell .el-input--suffix:hover .el-input__inner,
.modal-profile.add-task .priority-cell2 .el-input--suffix:hover .el-input__inner {
  -webkit-box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.2);
  box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.2);
}

.modal-profile.add-task .priority-cell2 {
  -webkit-flex: 1 155px !important;
  -ms-flex: 1 155px !important;
  flex: 1 155px !important;
  min-width: 80px;
}

.modal-profile.add-task .assigned-cell,
.modal-profile.add-task .task-name-cell {
  -webkit-flex: 1 22% !important;
  -ms-flex: 1 22% !important;
  flex: 1 22% !important;
  min-width: 300px;
}

.modal-profile.add-task .status-cell {
  -webkit-flex: 1 150px !important;
  -ms-flex: 1 150px !important;
  flex: 1 150px !important;
}

.modal-profile.add-task .table tr .folder-cell {
  height: 45px;
}

.task-view .right-information .el-input--suffix .el-input__inner {
  -webkit-box-shadow: 0 1px 4px rgba(0,0,0,0.2);
  box-shadow: 0 1px 4px rgba(0,0,0,0.2);
}
.task-view .right-information .el-input--suffix:hover .el-input__inner {
  -webkit-box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.2);
  box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.2);
}

/* end task page */

.ks-contacts .content .pane-content.cases .contact-content .table tr.search-row .el-input--suffix .el-input__inner {
  height: 44px;
}

.ks-contacts .content .pane-content .contact-content .custom-scroll-table {
  /*overflow: auto;*/
}

.modal-profile.add-task .mobile-col {
  overflow: visible;
}

.hover_block {
  position: absolute;
  background:#ffffff;
  width: 150px;
  box-sizing:border-box;
  padding:15px;

  border-radius: 12px;
  box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.1);
  z-index: 55;
}

.hover_block .from_group{
  margin-bottom:15px;
}
.hover_block .filter-content{
  margin-bottom:15px;
}
.hover_block ul li {
  padding-top:10px;
  padding-bottom:10px;
}

.hover_block .event {
  display: block;
  width: 100%;
  position: relative;
  padding-left: 15px;
  border:2px solid #fff;
}

.hover_block .event.deadline .icon {
  position: absolute;
  top: 50%;
  left: 0;
  background: url(../img/icons/@2x/event-point--red@2x.png) 0 0 no-repeat;
  width: 15px;
  height: 15px;
  -webkit-background-size: auto 100%;
  background-size: auto 100%;
  margin-top: -7.5px;
}
.hover_block .event.deadline:hover{
  border:2px solid #ff5722;
}

.hover_block .event.meeting .icon {
  position: absolute;
  top: 50%;
  left: 0;
  background: url(../img/icons/@2x/event-point--cyan@2x.png) 0 0 no-repeat;
  width: 15px;
  height: 15px;
  -webkit-background-size: auto 100%;
  background-size: auto 100%;
  margin-top: -7.5px;
}
.hover_block .event.meeting:hover{
  border:2px solid #00bcd4;
}

.hover_block .event.task .icon {
  position: absolute;
  top: 50%;
  left: 0;
  background: url(../img/icons/@2x/event-point--yellow@2x.png) 0 0 no-repeat;
  width: 15px;
  height: 15px;
  -webkit-background-size: auto 100%;
  background-size: auto 100%;
  margin-top: -7.5px;
}
.hover_block .event.task:hover{
  border:2px solid #ff9800;
}

.hover_block .event .ks-text {
  color: #000;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden; 
  width: inherit; 
  height: 18px;
  white-space: nowrap;
}

.calendar-content .calendar-content .fc-view .fc-highlight:before {
  bottom: 1px;
}

.modal-profile.add-meeting .modal-body > .row > .col-12.col-md-6:nth-child(2) .form-group.has-danger .el-input--suffix .el-input__inner,
.modal-profile.add-meeting .modal-body .list-block .form-group.has-danger .no-result,
.modal-profile.deadline .list-block .form-group.has-danger .no-result,
.modal-profile.deadline .form-group.has-danger .el-input--suffix .el-input__inner {
  background: url(../img/icons/@2x/icon-alert@2x.png);
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right .5625rem;
  -webkit-background-size: 1.125rem 1.125rem;
  background-size: 1.125rem 1.125rem;
  border: 1px solid #d9534f !important;
  -webkit-border-radius: 15px;
  border-radius: 15px;
}


.modal-profile.add-meeting .modal-body .expires-group:before {
  top: 15px;
  margin-top: 0;
}

.ks-contacts .content .pane-content.cases .contact-content .table tr th.status-cell, .ks-contacts .content .pane-content.cases .contact-content .table tr td.status-cell {
  min-width: 180px !important;
}

/* НУЖНО УБРАТЬ КОГДА БУДЕТ РАБОТАТЬ ПОИСК  */
.modal-profile.add-task .assigned-cell .search_results {
  display: none;
}
/* END НУЖНО УБРАТЬ КОГДА БУДЕТ РАБОТАТЬ ПОИСК  */

.dashboard .dashboard-block .custom-scroll-table + .show-more {
  padding: 6px;
  background: none;
}

.dashboard .dashboard-block .custom-scroll-table + .show-more .btn-submit {
  position: relative;
  padding: 12px 15px 12px 45px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  -webkit-border-radius: 15px;
  border-radius: 15px;
  -webkit-box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.3);
  box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.3);
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  background: #00bcd4;
}

.dashboard .dashboard-block .custom-scroll-table + .show-more .btn-submit .icon {
  left: 15px;
  margin-left: 0;
}

.dashboard .dashboard-block .custom-scroll-table + .show-more .btn-default {
  position: relative;
  padding: 12px 15px 12px 15px;
  text-align: center;
  color: #000;
  text-transform: uppercase;
  -webkit-border-radius: 15px;
  border-radius: 15px;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  background: none;
  margin-right: 5px;
}

.dashboard .dashboard-block .custom-scroll-table + .show-more .btn-submit:hover,
.dashboard .dashboard-block .custom-scroll-table + .show-more .btn-default:hover {
  -webkit-box-shadow: 0 4px 14px 2px rgba(0,0,0,0.2);
  box-shadow: 0 4px 14px 2px rgba(0,0,0,0.2);
}


.invite-people .dashboard-block .card {
  max-width: 830px;
}

.invite-people .table .el-select .el-input--suffix .el-input__inner {
  -webkit-box-shadow: 0 1px 4px rgba(0,0,0,0.2);
  box-shadow: 0 1px 4px rgba(0,0,0,0.2);
  height: 45px;
  padding: 0 35px 0 15px;
}
.invite-people .table .el-select .el-input--suffix .el-input__inner:hover {
  -webkit-box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.2);
  box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.2);
}

.invite-people .table td {
  vertical-align: top !important;
}

.icon.icon-contacts-nav-black {
  position: absolute;
  top: 50%;
  left: 25px;
  background: url("../img/icons/main-nav-icon-contacts-black.png") 0 0 no-repeat;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
  width: 16px;
  height: 16px;
  margin-top: -8px;
  margin-left: -8px;
}

.icon.icon-documents-nav-black {
  position: absolute;
  top: 50%;
  left: 25px;
  background: url("../img/icons/main-nav-icon-documents-black.png") 0 0 no-repeat;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
  width: 16px;
  height: 16px;
  margin-top: -8px;
  margin-left: -8px;
}

/* user settings */

#modalAddUser .custom-control.ks-custom-select-no-search {
  padding-left: 0;
}

#modalAddUser .el-input--suffix .el-input__inner {
  padding: 0 15px;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0,0,0,0.16);
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.16);
}
#modalAddUser .el-input--suffix .el-input__inner:hover {
  -webkit-box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.2);
  box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.2);
}

#modalAddUser .el-date-editor.el-input,
#modalAddUser .el-date-editor.el-input__inner,
#modalAddUser .el-select {
  display: block;
  width: 100%;
}

.calendar-content .calendar-content .fc-view>table .fc-day:hover:before {
  border: 2px solid #ff5722;
}

#profile .el-date-editor--date {
  padding: 2px 0 2px 40px;
  -webkit-border-radius: 15px;
  border-radius: 15px;
  border: 1px solid #e6e6e6;
}
#profile .el-date-editor--date:before {
  position: absolute;
  top: 13px;
  left: 10px;
  content: "";
  background: url(../img/icons/@2x/icon-date@2x.png) 0 0 no-repeat;
  width: 16px;
  height: 16px;
  -webkit-background-size: auto 100%;
  background-size: auto 100%;
  margin-top: 0;
  opacity: .3;
  z-index: 2;
}
#profile .el-date-editor--date.el-input--suffix .el-input__inner {
  padding-left: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

#profile #__BVID__113 .el-select .el-input--suffix .el-input__inner {
  padding: 0 15px;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0,0,0,0.16);
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.16);
}
#profile #__BVID__113 .el-select .el-input--suffix .el-input__inner:hover {
  -webkit-box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.2);
  box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.2);
}

.modal-profile .modal-footer .btn {
  background: #4caf50;
  color: #fff;
}

.modal-profile .modal-footer .btn.cancel {
  background: none;
  color: #000;
}

.auth-page:not(.content-position) .sign-outer.in {
  max-width: 600px;
}

.sign-outer .el-select {
  padding: 0;
  width: 100%;
}

.sign-outer .el-input--suffix .el-input__inner {
  height: 60px !important;
  padding: 0 15px;
}

@media screen and (max-width: 1023px) {
  .auth-page:not(.content-position) .sign-outer.in {
    max-width: 100%;
  }
  #requestform .auth-page {
    height: auto !important;
  }
}

#root {
  height: 100%;
}

#root .page-container {
  height: 100%;
}

#root #requestform {
  height: 100%;
}

#requestform .auth-page {
  min-height: 100%;
  height: auto;
}

.auth-page .form-wrapper .justify-content-center {
  -webkit-box-pack: justify!important;
  -ms-flex-pack: justify!important;
  justify-content: space-between!important;
}

.sign-outer .form-wrapper form .btn-holder .btn-submit.large {
  width: 100%;
}

.datepicker td.active, .datepicker th.active {
  text-shadow: none !important;
}

.modal-profile.assigned.modal-pin-case .modal-body {
  padding: 0;
}

.modal-profile.assigned.modal-pin-case .modal-body .modal-body {
  padding: 30px;
}

.modal-profile .modal-body .grey_bg {
  display: none;
}

.custom-search-block.custom-datepicker .datepicker-title .title .icon, .custom-search-block.custom-datepicker2 .datepicker-title .title .icon {
  display: none;
}

.profile-content .profile-information .cards-block .card-header .btn:before {
  margin-left: -6.5px;
}

.ks-contacts .content .pane-content.cases .contact-content .table tr td.status-cell .el-select,
.ks-contacts .content .pane-content.cases .contact-content .table tr td.priority-cell2 .el-select {
  margin-top: 1px;
}

.sign-outer .col-sm-6.col-md-6.col-lg-6 > .form-control {
  padding: 0;
}

.swal2-popup {
  border-radius: 12px;
}

.swal2-popup .swal2-title {
  font-size: 26px;
  line-height: 1.2em;
  font-weight: 600;
  margin: 15px 0;
  padding: 0 35px;
}

.swal2-popup #swal2-content {
  line-height: 1.2em;
  font-size: 16px;
  margin-bottom: 35px;
}

.swal2-popup .swal2-footer {
  display: none;
}

.swal2-popup .swal2-actions {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  margin: 0;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
  transition: color .3s ease;
  text-transform: uppercase;
  border-top: 1px solid #cecece;
  position: relative;
  margin: 0 -15px -15px;
}

.swal2-popup .swal2-actions button {
  flex: 0 50%;
  background: none;
  text-align: center;
  border: 0;
  -webkit-transition: all .3s;
     -moz-transition: all .3s;
      -ms-transition: all .3s;
       -o-transition: all .3s;
          transition: all .3s;
}
.swal2-popup .swal2-actions button:hover {
  color: #ffde0e;
}

.dashboard .dashboard-block .case-block .person-block {
  flex: 0 50%;
}

.dashboard .dashboard-block .case-block .person-info {
  flex: 0 50%;
  padding-left: 15px;
}

.ks-contacts .content .pane-content .contact-content .table thead tr.search-row th {
  border-color: #ccc;
}

.documents .el-date-editor.el-range-editor.el-input__inner.el-date-editor--datetimerange.el-range-editor--mini {
  -webkit-box-shadow: none;
  box-shadow: none;
  height: 44px;
}

.ks-contacts  .content .pane-content.documents .contact-content .table thead tr.search-row .custom-search-block.custom-datepicker .datepicker-title .title:hover {
  -webkit-box-shadow: 0 2px 2px rgba(0,0,0,0.2);
  box-shadow: 0 2px 2px rgba(0,0,0,0.2);
}

.documents .custom-search-block.custom-datepicker .datepicker-title .title .ks-text {
  display: block;
  padding: 8px 0;
}

.create-case .case-information .el-select {
  width: 50%;
}

.create-case .case-information .el-input--suffix .el-input__inner {
  border: 1px solid #e6e6e6 !important;
  padding: 10px 15px;
  height: 45px;
}
.create-case .case-information .el-input--suffix .el-input__inner:hover {
  -webkit-box-shadow: 0 2px 2px rgba(0,0,0,0.2);
  box-shadow: 0 2px 2px rgba(0,0,0,0.2);
}
.create-case .case-information .el-input--suffix .el-input__inner:focus {
  -webkit-box-shadow: 0 4px 14px 2px rgba(0,0,0,0.1);
  box-shadow: 0 4px 14px 2px rgba(0,0,0,0.1);
}

.add-row .adding-row.wrapping .form-control {
  max-width: 330px;
}

.add-row .adding-row .row-select {
  max-width: 115px;
}

#edit_contact_modal .add-row .adding-row .add-row-link.ks-remove-row {
  margin-left: 15px;
}

#edit_contact_modal .add-row.col-sm-2 .adding-row .ks-remove-row {
  margin-left: -20px;
  width: 100px;
  flex: 0;
}

.attachment-block .btn-file {
  padding-right: 30px;
}

.modal-profile .modal-header .close {
  top: 11px;
}

.custom-search-block > .custom-control {
  padding-left: 0;
}

.navbar>.ks-wrapper>.nav .create-block .dropdown-menu,
.profile-content .table-heading .profile-create-block .dropdown-menu {
  height: auto;
}

.modal-profile.edit-case.create-case .el-input--suffix .el-input__inner,
.modal-profile.edit-case.create-case .court-details .court-case-block .search-block .el-select {
  height: 45px;
}
.modal-profile.edit-case.create-case .court-details .court-case-block .search-block .el-input--suffix .el-input__inner {
  height: 45px;
  padding: 0 15px;
  border-radius: 0 15px 15px 0 !important;
  border: 1px solid #e6e6e6 !important;
  border-left: 0 !important;
}
.modal-profile.edit-case.create-case .court-details #set-meeting-time .el-input--suffix .el-input__inner {
  border: 1px solid #e6e6e6 !important;
}

#profile .el-select .el-input--suffix .el-input__inner {
  height: 45px;
  border: 1px solid #e6e6e6 !important;
  padding: 0 30px 0 15px;
}


#profile .avatar-uploader .avatar {
  height: 75px;
  width: 75px;
}

#profile .btn.btn-outline-secondary {
  position: relative;
  padding: 13px 15px 13px 15px;
  text-align: center;
  color: #000;
  text-transform: uppercase;
  -webkit-border-radius: 15px;
  border-radius: 15px;
  -webkit-box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.3);
  box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.3);
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  font-size: 13px;
  font-weight: 700;
  background: none;
  border: 0;
}
#profile .btn.btn-outline-secondary:hover {
  -webkit-box-shadow: 0 4px 14px 2px rgba(0,0,0,0.2);
  box-shadow: 0 4px 14px 2px rgba(0,0,0,0.2);
}

#profile .btn.btn-outline-secondary[type="submit"] {
  background: #4caf50;
  color: #fff;
}

#profile .btn.btn-outline-secondary[type="button"] {
  background: #00bcd4;
  color: #fff;
}

.modal-profile.add-note .modal-footer .btn.ks-btn-file {
  background: #fff;
  -webkit-box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.3);
  box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.3);
}
.modal-profile.add-note .modal-footer .btn.ks-btn-file:hover {
  -webkit-box-shadow: 0 4px 14px 2px rgba(0,0,0,0.2);
  box-shadow: 0 4px 14px 2px rgba(0,0,0,0.2);
}

.modal-profile.add-note .modal-footer .btn.ks-btn-file .icon {
  width: 18px;
}

.modal-profile.add-task .assigned-cell .search_results {
  display: block;
}

@media (max-width: 1420px) {
  .modal-profile.add-task .modal-lg {
    max-width: 500px;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
  }
  .modal-profile.add-task .table tr {
    -webkit-justify-content: space-between !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .modal-profile.add-task .table td {
    flex: 0 100% !important;
    margin-top: 15px;
  }

  .modal-profile.add-task .table td:first-child {
    margin-top: 0;
  }

  .modal-profile.add-task .options-cell .btn-new:hover {
    -webkit-box-shadow: 0 4px 14px 2px rgba(0,0,0,0.2);
    box-shadow: 0 4px 14px 2px rgba(0,0,0,0.2);
  }
}

.modal-profile.deadline-item .modal-footer .back-to {
  display: block;
  height: 60px;
  padding: 20px 0;
  margin: 0 -12px;
  border-radius: 12px;
}

.modal-profile.deadline-item .modal-footer .back-to.mark-as-done {
  background: #4caf50;
  color: #fff;
  border-radius: 0 0 0 12px;
}

.navbar>.ks-wrapper>.nav .ks-navbar-actions {
  justify-content: flex-end;
}

.navbar>.ks-wrapper>.nav .ks-player {
  margin-right: 0;
}

.navbar>.ks-wrapper>.nav .create-block .dropdown-toggle {
  background: #383838;
  border-radius: 30px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  width: 170px;
  padding-left: 30px;
}



.navbar>.ks-wrapper>.nav .create-block .dropdown-toggle:before {
  display: block;
  position: absolute;
  top: 50%;
  left: 15px;
  width: 11px;
  height: 11px;
  content: "";
  background: url(../img/icons/@2x/icon-plus--orange@2x.png) 0 0 no-repeat;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
  margin-left: 0;
  margin-top: -5.5px;
}

.navbar>.ks-wrapper>.nav .create-block .dropdown-menu {
  padding: 15px;
}

.navbar>.ks-wrapper>.nav .create-block .dropdown-menu .ks-create-new {
  height: 45px;
  padding-left: 45px;
  padding-right: 10px;
  border-radius: 12px;
}

.navbar>.ks-wrapper>.nav .create-block .dropdown-menu .ks-create-new:hover {
  background: #f7f7f7;
  color: #000;
}

.navbar>.ks-wrapper>.nav .create-block .dropdown-menu .ks-create-new .ks-text {
  margin-left: 0;
}

.navbar>.ks-wrapper>.nav .create-block .dropdown-menu .ks-create-new:hover .icon.icon-meeting,
.navbar>.ks-wrapper>.nav .create-block .dropdown-menu .ks-create-new .icon.icon-meeting {
  background: url(../img/icons/@2x/create-icon-meeting@2x.png) 0 0 no-repeat;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
}

.navbar>.ks-wrapper>.nav .create-block .dropdown-menu .ks-create-new:hover .icon.icon-case,
.navbar>.ks-wrapper>.nav .create-block .dropdown-menu .ks-create-new .icon.icon-case {
  background: url(../img/icons/@2x/create-icon-case@2x.png) 0 0 no-repeat;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
}

.navbar>.ks-wrapper>.nav .create-block .dropdown-menu .ks-create-new:hover .icon.icon-deadline,
.navbar>.ks-wrapper>.nav .create-block .dropdown-menu .ks-create-new .icon.icon-deadline {
  background: url(../img/icons/@2x/create-icon-deadline@2x.png) 0 0 no-repeat;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
}

.navbar>.ks-wrapper>.nav .create-block .dropdown-menu .ks-create-new:hover .icon.icon-time-entry,
.navbar>.ks-wrapper>.nav .create-block .dropdown-menu .ks-create-new .icon.icon-time-entry {
  background: url(../img/icons/@2x/create-icon-time@2x.png) 0 0 no-repeat;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
}

.navbar>.ks-wrapper>.nav .create-block .dropdown-menu .ks-create-new:hover .icon.icon-documents-nav-black,
.navbar>.ks-wrapper>.nav .create-block .dropdown-menu .ks-create-new .icon.icon-documents-nav-black {
  background: url(../img/icons/@2x/create-icon-company@2x.png) 0 0 no-repeat;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
}

.navbar>.ks-wrapper>.nav .create-block .dropdown-menu .ks-create-new:hover .icon.icon-contacts-nav-black,
.navbar>.ks-wrapper>.nav .create-block .dropdown-menu .ks-create-new .icon.icon-contacts-nav-black {
  background: url(../img/icons/@2x/create-icon-person@2x.png) 0 0 no-repeat;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
}


@media (max-width: 1024px) {
  .navbar>.ks-wrapper>.nav .create-block .dropdown-toggle {
    width: 50px;
    padding-left: 10px;
    color: #383838;
  }
  .navbar>.ks-wrapper>.nav .create-block .dropdown-toggle:before {
    left: 50%;
    margin-left: -5.5px;
  }
  .navbar>.ks-wrapper>.nav .create-block .dropdown-toggle:after {
    display: none;
  }

}

.modal-profile.add-timesheet .modal-body .main-form .el-input--suffix .el-input__inner {
  padding: 0 15px;
  border: 1px solid #e6e6e6 !important;
  height: 45px;
}

.modal-profile.timesheet-history .modal-body .head-history .el-input--suffix .el-input__inner {
  padding: 0 30px 0 15px;
  height: 45px;
  -webkit-box-shadow: 0 1px 4px rgba(0,0,0,0.2);
  box-shadow: 0 1px 4px rgba(0,0,0,0.2);
}
.modal-profile.timesheet-history .modal-body .head-history .el-input--suffix .el-input__inner:hover {
  -webkit-box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.2);
  box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.2);
}

.modal-profile.add-timesheet .modal-body .main-form label {
  padding: 12.5px 15px;
  margin: 0;
}

#deadline_form_modal .el-upload-dragger .drag-wrapper {
  position: relative;
  color: #333;
  padding-left: 80px;
}
#deadline_form_modal .el-upload-dragger .drag-wrapper .el-upload__text{
  text-align: left;
}

#deadline_form_modal .el-upload-dragger .drag-wrapper:before {
  position: absolute;
  top: 50%;
  left: 0;
  content: "";
  width: 64px;
  height: 49px;
  background: url(../img/icons/@2x/dnd-files@2x.png) 0 0 no-repeat;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
  margin-top: -24.5px;
}

#uploading-file-modal .modal-header .icon {
  width: 17px;
}
#uploading-file-modal .drag-drop-place .el-upload-dragger > div {
  position: relative;
  text-align: left;
  padding-left: 80px;
}
#uploading-file-modal .drag-drop-place .el-upload-dragger > div:before {
  position: absolute;
  top: 50%;
  left: 0;
  content: "";
  width: 64px;
  height: 49px;
  background: url(../img/icons/@2x/dnd-files@2x.png) 0 0 no-repeat;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
  margin-top: -24.5px;
}
#uploading-file-modal .drag-drop-place .btn-upload {
  position: relative;
  font-size: 12px;
  line-height: 22px;
  font-weight: 500;
  -webkit-border-radius: 15px;
  border-radius: 15px;
  padding: 10px 25px;
  background: #4caf50;
  color: #fff;
  height: 45px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  -webkit-flex: 0 1 135px;
  -ms-flex: 0 1 135px;
  -webkit-box-flex: 0;
  flex: 0 1 135px;
  overflow: hidden;
  margin-top: 10px;
}

#uploading-file-modal .drag-drop-place .btn-upload:before {
  position: absolute;
  top: 50%;
  left: 12px;
  width: 20px;
  height: 17px;
  margin-top: -7.5px;
  background: url("../img/icons/@2x/cloud-upload--white@2x.png") 0 0 no-repeat;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
  content: "";
}

#uploading-file-modal .drag-drop-place .btn-upload .ks-text {
  padding-left: 21px;
  padding-right: 0 !important;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
}

.modal-profile.share-file .modal-body .el-input--suffix .el-input__inner {
  padding: 0 30px;
  height: 45px;
  border: 1px solid #e6e6e6 !important;
}
.modal-profile.share-file .modal-body .el-input--suffix .el-input__inner:hover {
  -webkit-box-shadow: 0 2px 2px rgba(0,0,0,0.2);
  box-shadow: 0 2px 2px rgba(0,0,0,0.2);
}

.modal-profile.share-file .modal-body .form-group button {
  position: relative;
  width: 180px;
  background: #4caf50;
  padding: 12.5px 10px 12.5px 30px;
  text-align: center;
  font-weight: 600;
  -webkit-border-radius: 15px;
  border-radius: 15px;
  -webkit-box-shadow: 0 3px 20px -2px rgba(0,0,0,0.3);
  box-shadow: 0 3px 20px -2px rgba(0,0,0,0.3);
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  border: 0;
  height: 45px;
  color: #fff;
}
.modal-profile.share-file .modal-body .form-group button:hover {
  opacity: 0.8;
}
.modal-profile.share-file .modal-body .form-group button:before {
  position: absolute;
  top: 50%;
  left: 12px;
  margin-top: -7px;
  content: "";
  background: url("../img/icons/@2x/icon-plus--white@2x.png") 0 0 no-repeat;
  width: 13px;
  height: 13px;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;;
}
.modal-profile.share-file .modal-body .form-group .col-copy button {
  padding-left: 10px;
}
.modal-profile.share-file .modal-body .form-group .col-copy button:before {
  display: none;
}

.modal-profile.add-task td.assigned-cell .assigned-avatar-block {
  height: 30px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  margin-top: 15px;
}
.modal-profile.add-task td.assigned-cell .assigned-avatar-block img{
  height: 100%;
}
.modal-profile.add-task td.assigned-cell .assigned-avatar-block .icon-remove {
  transition: all .3s;
  opacity: 0;
  background: url("../img/icons/@2x/icon-close--black@2x.png") 0 0 no-repeat;
  width: 16px;
  height: 16px;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
  margin-top: -8px;
  margin-left: -8px;
}
.modal-profile.add-task td.assigned-cell .assigned-avatar-block .creator:hover .icon-remove {
  opacity: 1;
}

.cases .drag-group .card-body .assigned-cell .assigned-row {
  display: none !important;
}

.cases .drag-group .card-body .assigned-cell .assigned-avatar-block {
  position: relative;
  left: auto;
  top: auto;
  margin-top: 0;
}

.modal-profile.add-task .assigned-cell .search_results {
  top: 45px;
}

.modal-profile.add-task .assigned-cell .icon-addon {
  bottom: auto;
  height: 42px;
}

.cases .drag-group .card-body .assigned-cell .assigned-inner {
  padding: .75rem;
}

.public_page .mobile-main-tabs {
  display: none;
}

.documents-empty .profile-information .activity-folder-block .empty-block .icon {
  background: url(../img/icons/icon-syncronize.svg) 0 0 no-repeat;
}

.search_results {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  background: #fff;
  padding: 5px 0;
  -webkit-box-shadow: 0 6px 20px 0px rgba(0,0,0,0.25);
  box-shadow: 0 6px 20px 0px rgba(0,0,0,0.25);
  -webkit-border-radius: 15px;
  border-radius: 15px;
  z-index: 2;
}

.search_results > div {
  padding: 30px;
  color: #333;
  text-align: center;
  word-break: break-all;
}

.search_results .search_results_list {
  height: 200px;
  overflow-x: auto;
  margin-right: 5px;
}
.search_results .search_results_list::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}
.search_results .search_results_list a {
  display: block;
  padding: 5px 10px;
  color: #333;
  text-align: left;
  word-break: break-all;
}
.search_results .search_results_list a:hover {
  color: #4caf50;
}

.auth-page .custom-search-block .search_results {
  width: 300px;
  left: 50%;
  right: auto;
  margin-left: -150px;
}

.sign-outer .form-wrapper form .form-control.company {
  border: 1px solid #fff;
  padding-right: 35px;
}
.sign-outer .form-wrapper form .form-control.company:focus {
  border: solid 1px #ffdd0d;
}

.navbar>.ks-wrapper>.nav .ks-player .inner-block .playtime-block {
  min-width: 150px;
}

.modal-profile.map .modal-header .icon-map {
  background: url("../img/icons/@2x/sidebar-icon-map@2x.png") 0 0 no-repeat;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
}

#confirmRequest .modal-body {
  padding-bottom: 0;
}
#confirmRequest .modal-body .popup_close span {
  position: relative;
  display: block;
  font-weight: 700;
  color: #000;
  background: none;
  border: 0;
  padding: 12px;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  text-transform: uppercase;
  margin: 0;
}
#confirmRequest .modal-body .popup_close span:hover {
  color: #ff9800;
}
#confirmRequest .modal-body .popup_close span:before {
  position: absolute;
  top: -2px;
  left: -30px;
  right: -30px;
  content: "";
  border-top: 2px solid #eef1f2;
  height: 2px;
}

.dashboard .dashboard-block .add-todo-block .el-input {
  margin-bottom: 15px;
}
.dashboard .dashboard-block .add-todo-block .el-input--suffix .el-input__inner {
  border: 1px solid #e6e6e6 !important;
  height: 45px;
}

.modal-profile.edit-case.create-case .team-information .list-block .list-item .person-block {
  padding: 0;
  max-width: 200px;
}

.modal-profile.edit-case.create-case .team-information .list-block .list-item .item {
  padding: 15px;
  -ms-align-items: flex-start;
  align-items: flex-start;
}

.add-timesheet .modal-body .set-date .set-date-block input {
  border: 1px solid #e6e6e6 !important;
}

.modal-profile.timesheet-history .modal-body .content-history .list-block .list-item .item {
  padding: 15px;
}

.modal-profile.timesheet-history .modal-body .content-history .list-block .list-item .person-block {
  padding: 0;
  max-width: 200px;
  flex: 1 0 200px;
}

.modal-profile.timesheet-history .modal-body .content-history .list-block .list-item .case-block {
  max-width: 240px;
  flex: 1 0 240px;
}

.navbar .navbar-brand {
  z-index: 2;
}

.navbar .menu-block {
  padding-top: 0;
  top: 90px;
  z-index: 2;
}

.menu-overflow {
  z-index: 1;
}

.documents .profile-information .user-information {
  z-index: 1;
}

.documents-empty .profile-information .user-information .empty-block .icon {
  background: url("../img/icons/icon-folder.svg") 0 0 no-repeat;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
}

.modal-profile.modal-case-description .modal-body .description-item-block .description ul {
  list-style-type: disc;
  padding-left: 30px;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .modal-profile.add-task .area-cell,
  .modal-profile.add-task .options-cell .form-title {
    display: none;
  }

  .modal-profile.add-task .table tr .task-name-cell, .modal-profile.add-task .table tr .assigned-cell {
    /*min-width: ;*/
  }

  .modal-profile.add-task .close {
    z-index: 4;
  }
}