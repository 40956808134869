*::-webkit-scrollbar-track {
	border-radius: 10px;
	background: rgba(0,0,0,0.1);
	border: 1px solid #ccc;
}

*::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background: linear-gradient(to left, #fff, #e4e4e4);
	border: 1px solid #aaa;
}

*::-webkit-scrollbar-thumb:hover {
	background: #fff;
}

.table-responsive {
	&::-webkit-scrollbar {
		height: 10px;
		background-color: #F5F5F5;
	}
}

.modal {
	z-index: 1050121;
}
.modal-backdrop.show {
	opacity: .2;
}

.modal-title {
	color: #fff;
}

#modalDeadline {
	@media(max-width: 1199px) {
		padding: 0 10px !important;
	}

	@media(max-width: 768px) {
		padding: 0 2px !important;
	}
}

.modal-profile {
	max-width: 570px;

	.modal-content {
		border: 0;
		-webkit-border-radius: 15px;
		-moz-border-radius: 15px;
		border-radius: 15px;
		box-shadow: 0 6px 20px 0px rgba(0,0,0,0.25);
	}

	.modal-header {
		position: relative;
		background: none;
		padding: 20px 30px 20px 60px;
		height: 60px;
		border: 0;
		-webkit-border-radius: 15px 15px 0 0;
		-moz-border-radius: 15px 15px 0 0;
		border-radius: 15px 15px 0 0;

		.icon {
			left: 30px;
			margin-left: 0;
		}

		.ks-text {
			display: inline-block;
			margin: 0;
			padding: 0;
			color: #fff;
			font-size: 16px;
			font-weight: 600;
			line-height: 23px;
		}

		.close {
			position: relative;
			top: 8px;
			opacity: 1;
			-webkit-transition: all .3s;
			-moz-transition: all .3s;
			-ms-transition: all .3s;
			-o-transition: all .3s;
			transition: all .3s;

			&:focus,
			&:hover {
				opacity: 0.8;
				outline: 0;
			}

			.icon {
				left: 50%;
				margin-left: -7.5px;
			}
		}
	}

	.modal-body {
		padding: 30px;

		@media (max-width: 768px) {
			padding: 10px;
		}
	}

	.modal-footer {
		padding: 0 30px 30px;
		border-top: 0;
		@media (max-width: 768px) {
			padding: 10px;
		}

		.btn {
			position: relative;
			padding: 12px 15px 12px 15px;
			text-align: center;
			color: #000;
			text-transform: uppercase;
			-webkit-border-radius: 15px;
			-moz-border-radius: 15px;
			border-radius: 15px;
			-moz-box-shadow: 0 2px 6px 0.5px rgba(0, 0, 0, 0.3);
			-webkit-box-shadow: 0 2px 6px 0.5px rgba(0, 0, 0, 0.3);
			box-shadow: 0 2px 6px 0.5px rgba(0, 0, 0, 0.3);
			-webkit-transition: all .3s;
			-moz-transition: all .3s;
			transition: all .3s;
			display: -ms-flexbox;
			display: -webkit-flex;
			display: flex;
			-webkit-flex-direction: row;
			-ms-flex-direction: row;
			flex-direction: row;
			-webkit-flex-wrap: nowrap;
			-ms-flex-wrap: nowrap;
			flex-wrap: nowrap;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
			justify-content: center;
			-webkit-align-content: stretch;
			-ms-flex-line-pack: stretch;
			align-content: stretch;
			-webkit-align-items: center;
			-ms-flex-align: center;
			align-items: center;
			font-size: 13px;
			font-weight: 700;
			background: none;

			&:hover,
			&:focus {
				-webkit-box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.2);
				-moz-box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.2);
				box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.2);
			}

			&.cancel {
				-webkit-tbox-shadow: none;
				-moz-box-shadow: none;
				box-shadow: none;

				&:hover,
				&:focus {
					-webkit-box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.2);
					-moz-box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.2);
					box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.2);
				}
			}
		}
	}

	&.deadline {
		max-width: 1140px;
		
		.modal-header {
			background: #ff5722;

			.icon {
				&.icon-deadline {
					background: url("../img/icons/@2x/icon-calendar--white@2x.png") 0 0 no-repeat;
					-webkit-background-size: auto 100%;
					-moz-background-size: auto 100%;
					background-size: auto 100%;
				}
			}
		}

		.modal-body {
			.head {
				text-align: center;
				padding-bottom: 20px;
				border-bottom: 2px solid #f2f2f2;
				margin-bottom: 20px;
				font-weight: 600;

				input {
					background: 0;
					border: 0;
					text-align: center;
					font-size: 14px;
					color: #666666;
					font-weight: 600;
				}

				.title {
					color: #666;
				}
			}

			hr {
				border: 0;
				border-top: 1px solid #e6e6e6;
				margin: 10px 0;
			}
		}

		.modal-body {
			.attach-btn {
				margin-top: 29px;
			}

			.row {
				.add-row-btn {
					position: relative;
					width: 30px;
					height: 30px;
					padding: 0;
					border-radius: 100px;
					box-shadow: 0 2px 4px rgba(0,0,0,0.3);
					opacity: 1;
					transition: all .3s;
					background: #ff5722;
					float: none;
					display: block;
					margin: 37px auto 0;
					opacity: 1;
					border: 0;

					&.remove {
						margin-top: 10px;
						background: #000;
						&:before {
							transform: rotate(45deg);
						}
					}

					&:hover {
						opacity: 0.8;
					}

					&:before {
						position: absolute;
						top: 50%;
						left: 50%;
						background: url(../img/icons/@2x/icon-plus--white@2x.png) 0 0 no-repeat;
						width: 13px;
						height: 13px;
						background-size: 100% auto;
						margin-top: -6.5px;
						margin-left: -6.5px;
						content: "";
					}
				}
				&.notification {
					div[class^="col-"] {

						input {
							padding: 10px;
						}
					}
				}
			}
		}

		.modal-footer {
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			justify-content: center;
			.btn {
				&.add {
					background: #ff5722;
					color: #fff;

					&:hover,
					&:focus {
						background: #ec501f;
					}
				}
			}
		}
	}

	&.add-person,
	&.import-contacts,
	&.add-attach,
	&.add-phone,
	&.edit-steps,
	&.add-folder,
	&.representative {
		.modal-header {
			background: #4caf50;
		}
		.modal-footer {
			.btn {
				&.add {
					background: #4caf50;
					color: #fff;

					&:hover,
					&:focus {
						background: #53bf57;
					}
				}
			}
		}
	}

	&.edit-steps {
		.add-row {
			.form-control {
				border-right: 1px solid #e6e6e6;
				border-radius: 15px;
			}
			.adding-row {
				.add-row-link {
					@media (max-width: 992px) {
						flex: 1 0 140px;
				    text-align: left;
		    		padding-left: 35px;
					}

					@media (max-width: 768px) {
						display: flex;
						align-items: center;
					}

					.icon {
						@media (max-width: 768px) {
							left: 3px;
							margin-left: -7.5px;
						}
					}

					.ks-text {
						@media (max-width: 768px) {
							display: inline-block;
						}
					}
				}
			}
		}
	}

	&.add-sync,
	&.add-sync-detail{
		.modal-header {
			background: #2196f3;
		}
		.modal-footer {
			justify-content: center;
			border-top: solid 1px #e6e6e6;
			padding-top:10px;
			.btn {
				&.add {
					background: #2196f3;
					color: #fff;

					&:hover,
					&:focus {
						background: #53bf57;
					}
				}
			}
		}
	}
	&.add-sync-detail{
		max-width:840px;
		.modal-body {
			padding:0px;
		}
	}

	&.add-attach,
	&.add-phone,
	&.edit-steps,,
	&.add-folder {
		.modal-body {
			.btn {
				@media (max-width: 768px) {
					margin-top: 10px;
				}
			}
		}
	}

	&.add-phone {
		.modal-header {
			.icon {
				width: 15px;
				height: 15px;
				margin-top: -7.5px;
				margin-left: -7.5px;
			}
		}
		.modal-body {
			.btn {
				@media (max-width: 768px) {
					margin-top: 10px;
				}
			}

			.adding-row {
				margin-top: 0;
			}

			.primary-block {
				padding: 15px 10px 0;
			}
		}
	}

	&.add-note {
		.modal-header {
			background: #4caf50;
		}
		.modal-body {
			.file-block {
				position: relative;
				display: block;
				background: #fff;
				padding: 12.5px 40px 12.5px 40px;
				border-radius: 15px;
				box-shadow: 0 2px 2px rgba(0,0,0,0.2);
				margin-top: 15px;
				cursor: pointer;

				&:nth-child(2) {
					margin-top: 0;
				}

				&:hover {
					.ks-text {
						color: #4caf50;
					}

					.icon {
						&.icon-delete {
							background: url(../img/icons/@2x/icon-delete--red@2x.png) 0 0 no-repeat;
							-webkit-background-size: auto 100%;
							background-size: auto 100%;
						}
					}
				}

				.ks-text {
					word-wrap: break-word;
					color: #333;
					-webkit-transition: all .3s;
					-moz-transition: all .3s;
					-ms-transition: all .3s;
					-o-transition: all .3s;
					transition: all .3s;
				}

				.icon {
					&.icon-file {
						left: 15px;
						margin-left: 0;
						opacity: 0.4;
					}
				}

				.delete-file {
					position: absolute;
					right: 10px;
					top: 50%;
					width: 30px;
					height: 45px;
					margin-top: -22.5px;

					.icon {
						webkit-transition: all .3s;
						-o-transition: all .3s;
						transition: all .3s;
					}
				}
			}
		}
		.modal-footer {
			.btn-attach {
				-webkit-justify-content: flex-start;
				-ms-flex-pack: start;
				justify-content: flex-start;
				-webkit-flex: 1 0;
				-ms-flex: 1 0;
				flex: 1 0;
			}
			.btn {
				&.add {
					background: #4caf50;
					color: #fff;
					padding: 12px 30px;

					@media (max-width: 768px) {
						padding: 12px 10px;
					}

					&:hover,
					&:focus {
						background: #53bf57;
					}
				}

				&.ks-btn-file {
					color: #3aa2c9;
					padding-right: 25px;
					font-size: 12px;
					float: left;
					margin: 0;
					position: relative;
					padding: 7px 10px 7px 30px;
					box-shadow: none;

					&:hover {
						background: #f2fff3;

						.icon {
							background: url("../img/icons/@2x/icon-attach--green@2x.png") 0 0 no-repeat;
							background-size: auto 100%;
						}
						.ks-text {
							color: #4caf50;
						}
					}

					.icon {
						left: 7px;
						margin-left: 0;
						-webkit-transition: all .3s;
						-moz-transition: all .3s;
						-ms-transition: all .3s;
						-o-transition: all .3s;
						transition: all .3s;
					}

					.ks-text {
						line-height: 30px;
						text-transform: uppercase;
						font-size: 12px;
						color: #5b5b5b;
						display: block;
						font-weight: 600;
						-webkit-transition: all .3s;
						-moz-transition: all .3s;
						-ms-transition: all .3s;
						-o-transition: all .3s;
						transition: all .3s;
					}

					input {
						position: absolute;
						top: 0;
						right: 0;
						min-width: 100%;
						min-height: 100%;
						font-size: 26px;
						text-align: right;
						opacity: 0;
						cursor: default;
						display: block;
					}
				}
			}
		}
	}

	&.map {
		.modal-header {
			color: #4caf50;
			border-bottom: 2px solid #f2f2f2;

			.icon {
				background: url("../img/icons/@2x/icon-close--green@2x.png") 0 0 no-repeat;
				background-size: 100% auto;
			}

			.ks-text {
				color: #4caf50;
				line-height: 1.2em;
			}
		}

		.modal-body {
			padding: 15px;
		}
	}

	&.share-file {
		.modal-header {
			background: #4caf50;
		}
		.modal-footer {
			.btn {
				&.add {
					background: #4caf50;
					color: #fff;

					&:hover,
					&:focus {
						background: #53bf57;
					}
				}
			}
		}

		.modal-body {
			.col-6 {
				&:nth-child(2) {
					padding-left: 0;
				}
			}
			.btn {
				@media (max-width: 576px) {
					margin-top: 10px;
				}
			}
			.top {
				position: relative;
				padding-left: 45px;
				margin-bottom: 30px;

				.icon {
					left: 0;
					margin-left: 0;
				}

				.ks-text {
					font-size: 17px;
					color: #000;
					font-weight: 600;
					line-height: 32px;
				}
			}

			.col-slider {
				padding-top: 12px;
			}

			.expires-group {
				position: relative;;

				&.has-val {
					&:before {
						background: url(../img/icons/@2x/icon-date--green@2x.png) 0 0 no-repeat;
						background-size: auto 100%;
						opacity: 1;
					}
				}

				&:before {
					position: absolute;
					top: 50%;
					left: 10px;
					content: "";
					background: url(../img/icons/@2x/icon-date@2x.png) 0 0 no-repeat;
					width: 16px;
					height: 16px;
					background-size: auto 100%;
					margin-top: -8px;
					opacity: .3;
				}

				.form-control {
					position: relative;
					padding-left: 40px;
					background: transparent;
				}
			}

			hr {
				border: 1px solid #f3f3f3;
				margin-top: 0;
			}

			.copy-to {
				position: absolute;
				top: 0;
				right: 17px;
				height: 43px;
				width: 30px;
				display: block;
			}

			.col-copy {
				position: relative;

				input {
					padding-right: 30px;
				}

				.ks-text {
					display: block;
					font-size: 13px;
					font-family: $font-open-sans;
					color: #000;
					font-style: italic;
					opacity: .5;
					margin-top: 10px;
				}
			}
		}
	}

	&.version-history {
		max-width: 750px;

		.modal-header {
			background: #4caf50;
		}

		.modal-body {
			padding: 0;

			& > .row {
				&:first-child {
					padding: 30px 30px 0 30px;

					@media (max-width: 768px) {
						padding: 20px 15px 0 15px;
					}
				}
			}
			.top {
				position: relative;
				padding-left: 45px;
				margin-bottom: 30px;

				.icon {
					left: 0;
					margin-left: 0;
				}

				.ks-text {
					font-size: 17px;
					color: #000;
					font-weight: 600;
					line-height: 32px;
				}
			}

			.history-row {
				text-align: right;

				.val,
				.title {
					display: block;
					color: #aaaaaa;
					font-family: "Open Sans",sans-serif;
					font-style: italic;
					margin-bottom: 5px;
				}

				.val {
					color: #000;
					font-style: normal;
					margin-bottom: 0;
				}
			}

			.ks-nav-tabs {
				padding: 0 30px;

				@media (max-width: 768px) {
					margin-top: 20px;
				}

				.nav-link {
					position: relative;
					color: #ccc;
					padding: 06px 25px 25px;
					font-weight: 600;

					&:before {
						position: absolute;
						bottom: 0;
						left: 0;
						right: 0;
						content: "";
						border-bottom: 4px solid #4caf50;
						opacity: 0;
						transition: all .3s;
					}

					&.active {
						color: #000;

						&:before {
							opacity: 1;
						}
					}
				}
			}

			.table {
				margin-bottom: 0;
				@media (max-width: 768px) {
					min-width: 650px;
				}
				th {
					vertical-align: middle;
					background: #f7f7f7;
					border-bottom: 0;
					color: #666666;
					text-transform: uppercase;
					font-size: 11px;
					font-weight: 600;
					border-top: 2px solid #f3f3f3;
					border-bottom: 2px solid #f3f3f3;
				}

				th {
					padding: 20px 10px 20px 30px;
				}
				td {
					padding: 20px 10px 20px 15px;
					vertical-align: middle;
					border: 0;

					&:first-child {
						padding-left: 30px;
					}

					span {
						display: block;
						color: #a3a3a3;
						font-size: 13px;
						line-height: 45px;
						word-wrap: break-word;

						&.black {
							color: #000;
						}

						&.bold {
							font-weight: 600;
						}
					}

					.options-block {
						display: -ms-flexbox;
						display: -webkit-flex;
						display: flex;
						-webkit-flex-direction: row;
						-ms-flex-direction: row;
						flex-direction: row;
						-webkit-flex-wrap: nowrap;
						-ms-flex-wrap: nowrap;
						flex-wrap: nowrap;
						-webkit-justify-content: flex-start;
						-ms-flex-pack: start;
						justify-content: flex-start;
						-webkit-align-content: stretch;
						-ms-flex-line-pack: stretch;
						align-content: stretch;
						-webkit-align-items: center;
						-ms-flex-align: center;
						align-items: center;
					}

					.btn-upload {
						-webkit-flex: 1 1 auto;
						-ms-flex: 1 1 auto;
						-webkit-box-flex: 1;
						flex: 1 1 auto;
						position: relative;
						padding: 12px 15px 12px 40px;
						text-align: center;
						background: none;
						text-transform: uppercase;
						border-radius: 15px;
						transition: all .3s;
						display: -ms-flexbox;
						display: -webkit-flex;
						display: -webkit-box;
						display: flex;
						-webkit-flex-direction: row;
						-ms-flex-direction: row;
						-webkit-box-orient: horizontal;
						-webkit-box-direction: normal;
						flex-direction: row;
						-webkit-flex-wrap: nowrap;
						-ms-flex-wrap: nowrap;
						flex-wrap: nowrap;
						-webkit-justify-content: center;
						-ms-flex-pack: center;
						-webkit-box-pack: center;
						justify-content: center;
						-webkit-align-content: stretch;
						-ms-flex-line-pack: stretch;
						align-content: stretch;
						-webkit-align-items: center;
						-ms-flex-align: center;
						-webkit-box-align: center;
						align-items: center;
						flex: 0 135px;
						font-size: 13px;
						font-weight: 600;

						.icon {
							left: 15px;
							margin-left: 0;
							margin-top: -7px;
							transition: all .3s;
						}

						.ks-text {
							color: #000;
							transition: all .3s;
							line-height: 1.5em;
						}

						&:hover {
							background: #f2fff3;

							.icon {
								background: url(../img/icons/@2x/icon-clouddown--green@2x.png) 0 0 no-repeat;
								-webkit-background-size: auto 100%;
								background-size: auto 100%;
							}

							.ks-text {
								color: #4caf50;
							}
						}
					}

					.btn-remove {
						position: relative;
						display: inline-block;
						padding: 12px;
						height: 45px;
						flex: 1 auto;
						background: none;
						border-radius: 15px;

						.icon {
							transition: all .3s;
						}

						&:hover {
							background: #f2fff3;

							.icon {
								background: url(../img/icons/@2x/icon-trash--green@2x.png) 0 0 no-repeat;
								-webkit-background-size: auto 100%;
								background-size: auto 100%;
							}
						}
					}
				}
			}
		}
	}

	&.deadline-list {
		max-width: 750px;

		.modal-body {
			padding: 0;

			.table {
				margin-bottom: 0;
				@media (max-width: 768px) {
					min-width: 650px;
				}
				th {
					vertical-align: middle;
					background: #f7f7f7;
					border-bottom: 0;
					color: #666666;
					text-transform: uppercase;
					font-size: 11px;
					font-weight: 600;
				}

				th {
					position: relative;
					padding: 20px 10px 20px 30px;

					.filter-content {
						position: absolute;
						top: 50%;
						right: 15px;
						width: 200px;
						margin-top: -22.5px;

						.select2-container--default .select2-selection--single .select2-selection__rendered {
							padding-left: 40px;
						}
					}
				}
				td {
					padding: 30px 10px 20px 15px;
					vertical-align: top;

					&:first-child {
						padding-left: 30px;
						width: 170px;
						max-width: 170px;
						min-width: 170px;
					}

					span {
						display: block;
						color: #a3a3a3;
						font-size: 13px;
						line-height: 1.5em;
						word-wrap: break-word;

						&.black {
							color: #000;
						}

						&.bold {
							font-weight: 600;
						}
					}

					.progress {
						position: relative;
				    display: block;
				    width: 100px;
				    height: 25px;
				    -webkit-border-radius: 100px;
				    border-radius: 100px;
				    font-size: 11px;
				    color: #fff;
				    line-height: 1.5em;
				    padding: 5px 0;
				    z-index: 2;
				    -webkit-transition: all .3s;
				    -o-transition: all .3s;
				    transition: all .3s;
				    margin-top: 10px;
				    text-align: center;

				    &.in-progress {
				    	background: #ffc107;
				    	color: #fff;
				    }

				    &.done {
				    	background: #4caf50;
				    	color: #fff;
				    }

				    &.not-done {
				    	background: #ff5722;
				    	color: #fff;
				    }
					}

					.progress-title {
						margin-top: 15px;
						
						&.done {
							color: #4caf50;
						}
					}
				}

			}
			.deadline-list-block {
				.list-link {
					display: block;
					color: #000;
					font-weight: 600;
					margin-bottom: 5px;

					&:hover {
						color: #ff5722;
					}
				}

				.description {
					text-overflow: ellipsis;
					overflow: hidden; 
					width: 525px; 
					height: 1.4em; 
					white-space: nowrap;
					color: #999;
					font-size: 13px;
					margin-bottom: 15px;
				}

				.attachment-block {
					display: -ms-flexbox;
					display: -webkit-flex;
					display: -webkit-box;
					display: flex;
					-webkit-flex-direction: row;
					-ms-flex-direction: row;
					-webkit-box-orient: horizontal;
					-webkit-box-direction: normal;
					flex-direction: row;
					-webkit-flex-wrap: wrap;
					-ms-flex-wrap: wrap;
					flex-wrap: wrap;
					-webkit-justify-content: flex-start;
					-ms-flex-pack: start;
					-webkit-box-pack: start;
					justify-content: flex-start;
					-webkit-align-content: stretch;
					-ms-flex-line-pack: stretch;
					align-content: stretch;
					-webkit-align-items: center;
					-ms-flex-align: center;
					-webkit-box-align: center;
					align-items: center;

					.btn-file {
						-webkit-flex: 1 1 auto;
						-ms-flex: 1 1 auto;
						-webkit-box-flex: 1;
						flex: 1 1 auto;
						position: relative;
						padding: 12px 15px 12px 25px;
						text-align: center;
						background: none;
						-webkit-border-radius: 15px;
						border-radius: 15px;
						-webkit-transition: all .3s;
						-o-transition: all .3s;
						transition: all .3s;
						display: -ms-flexbox;
						display: -webkit-flex;
						display: -webkit-box;
						display: flex;
						-webkit-flex-direction: row;
						-ms-flex-direction: row;
						-webkit-box-orient: horizontal;
						-webkit-box-direction: normal;
						flex-direction: row;
						-webkit-flex-wrap: nowrap;
						-ms-flex-wrap: nowrap;
						flex-wrap: nowrap;
						-webkit-justify-content: center;
						-ms-flex-pack: center;
						-webkit-box-pack: center;
						justify-content: center;
						-webkit-align-content: stretch;
						-ms-flex-line-pack: stretch;
						align-content: stretch;
						-webkit-align-items: center;
						-ms-flex-align: center;
						-webkit-box-align: center;
						align-items: center;
						-webkit-box-flex: 0;
						flex: 0 auto;
						font-size: 13px;
						font-weight: 600;

						&.hidden {
							padding: 0;
							width: 0;
							height: 0;
						}

						&:focus {
							box-shadow: none;
						}

						.icon {
							left: 5px;
							margin-left: 0;
						}

						.ks-text {
							color: #000;
							font-weight: 600;
							-webkit-transition: all .3s;
							-moz-transition: all .3s;
							-ms-transition: all .3s;
							-o-transition: all .3s;
							transition: all .3s;
						}

						&:hover {
							background: #f2fff3;

							.ks-text {
								color: #4caf50;
							}
						}
					}

					.btn-more {
						background: none;

						&:focus {
							box-shadow: none;
						}

						&:hover {
							.ks-text {
								color: #000;
							}
						}

						.ks-text {
							color: #828282;
							-webkit-transition: all .3s;
							-moz-transition: all .3s;
							-ms-transition: all .3s;
							-o-transition: all .3s;
							transition: all .3s;
						}
					}
				}
			}
		}
	}

	&.deadline-item {
		max-width: 750px;

		&.manager {
			.modal-body {
				@media (max-width: 768px) {
					padding-top: 40px;
				}
				.edit-block {
					display: block;

					@media (max-width: 768px) {
						top: -30px;
					}
				}
			}
		}

		.modal-body {
			.edit-block {
				display: none;
				position: absolute;
				top: 0;
				right: 20px;
				width: 160px;

				.edit-contact-link {
					position: relative;
					display: inline-block;
					text-transform: uppercase;
					font-size: 13px;
					font-weight: 700;
					color: #000;
					padding-left: 25px;
					height: 20px;

					.icon {
						left: 0;
						margin-left: 0;
						-webkit-transition: all .3s;
						-o-transition: all .3s;
						transition: all .3s;
					}
				}

				.trash-link {
					position: relative;
					display: inline-block;
					text-transform: uppercase;
					font-size: 13px;
					font-weight: 700;
					color: #000;
					margin-left: 15px;
					padding-left: 25px;
					height: 20px;

					.icon {
						left: 0;
						margin-left: 0;
						-webkit-transition: all .3s;
						-o-transition: all .3s;
						transition: all .3s;
					}
				}
			}
			.top {
				display: inline-block;
				position: relative;
				padding-left: 35px;

				.icon {
					left: 0;
					margin-left: 0;
					width: 20px;
					height: 20px;
					margin-top: -10px;
					margin-left: 0px;
				}

				.ks-text {
					display: block;
					font-size: 18px;
					color: #000;
					line-height: 1.2em;

					@media (max-width: 768px) {
						font-size: 14px;
					}

					b {
						position: relative;
						display: inline-block;
						margin: 0 10px;
						width: 14px;
						height: 11px;

						&:before {
							position: absolute;
							top: 50%;
							height: 2px;
							background: #ccc;
							content: "";
							margin-top: -1px;
							width: 14px;
						}
					}
				}
			}

			.deadline-item-block {
				margin-top: 30px;

				.list-link {
					display: block;
					font-weight: 600;
					color: #000;
					margin-bottom: 20px;
				}

				.description {
					color: #666;
					font-size: 13px;
				}

				.form-title {
					color: #999;
				}

				.attachment-block {
					margin-top: 40px;
				}

				.link-case-block {
					margin-top: 30px;

					.form-title {
						padding-right: 0;

						& ~ .col-12 {
							padding-left: 0;

							@media (max-width: 576px) {
								padding-left: 15px;
							}
						}
					}

					.link {
						cursor: pointer;
						display: inline-block;
						color: #000;
						font-weight: 600;
						font-size: 13px;
						padding: 5px 10px;
						line-height: 1em;
						border-radius: 15px;
						-webkit-transition: all .3s;
						-moz-transition: all .3s;
						-ms-transition: all .3s;
						-o-transition: all .3s;
						transition: all .3s;

						&:hover {
							background: #f2fff3;
							color: #4caf50;
						}
					}
				}

				.assigned-block {
					& > .row {
						& > div:first-child {
							.form-title {
								margin-top: 0;
							}
						}
					}
					.form-title {
						@media (max-width: 576px) {
							margin-top: 20px;
						}
					}
					.person-block {
						padding: 10px 0 10px;

						.inner {
							display: table;
							height: 45px;
							width: 100%;
							margin: 0;
							padding: 0;

							.open-popover,
							.avatar,
							.name {
								display: table-cell;
								vertical-align: middle;
							}

							.avatar {
								width: 45px;

								img {
									width: 100%;
									border-radius: 100px;
								}
							}

							.name {
								font-size: 13px;
								color: #a3a3a3;
								padding-left: 15px;

								b {
									display: block;
									font-weight: 600;
									color: #000;
								}
							}
						}
					}
				}
			}
		}

		.modal-footer {
			height: 60px;
			padding: 0;
			text-align: center;
			border-top: 1px solid #e6e6e6;

			.back-to {
				position: relative;
				display: inline-block;
				padding: 0 20px;
				font-size: 12px;
				color: #000;
				font-weight: 600;
				text-transform: uppercase;

				.icon {
					left: 0;
					margin-left: 0;
					opacity: .7;
				}
			}
		}
	}

	&.meeting {
		.modal-header {
			background: #00bcd4;
		}

		.modal-body {
			.deadline-item-block {
				.description {
					margin-top: 10px;
				}
			}
			.address {
				.link {
					display: block;
					color: #999;
					font-size: 13px;
					padding: 15px;
					border-top: 2px solid #f3f3f3;
					border-bottom: 2px solid #f3f3f3;
					position: relative;
					padding-left: 40px;

					.icon {
						left: 15px;
						margin-left: 0;
						opacity: .4;
					}
				}
			}

			.map-block {
				height: 225px;
			}
		}
	}

	&.edit-contact {
		max-width: 650px;

		.modal-header {
			background: #4caf50;
		}
		.modal-body {
			.row {
				&:first-child {
					h4 {
						margin-top: 0;
					}
				}
			}
			h4 {
				margin: 0;
				padding: 0;
				font-weight: 600;
				margin: 20px 0 15px;
				color: #4caf50;
			}

			.address-row {
				overflow: hidden;
				position: relative;

				&.show-map {
					.ks-address-map {
						right: 0;

						@media (max-width: 768px) {
							right: 5px;
						}

						@media (max-width: 576px) {
							right: 6px;
						}
					}

					.ks-show-map-btn {
						&:before {
							left: 15px;
							right: auto;
							background: url(../img/icons/@2x/angle-right@2x.png) 0 0 no-repeat;
							background-size: auto 100%;
							transform: rotate(180deg);
						}

						.icon {
							display: none;
						}

						.ks-text {
							display: none;

							&.hidden {
								display: block;
								visibility: visible;
								opacity: 1;
							}
						}
					}
				}

				.ks-address-map {
					position: absolute;
					top: 20px;
					right: 100%;
					width: 100%;
					bottom: 60px;
					border-radius: 15px;
					background: #fff;
					z-index: 1050121;
					transition: all .3s;

					@media (max-width: 768px) {
						width: 98%;
					}

					@media (max-width: 576px) {
						bottom: 107px;
						width: 96%;
					}
				}

			}

			.add-map {
				margin-top: 20px;

				.ks-show-map-btn {
					position: relative;
					display: block;
					background: #fff;
					padding: 12.5px 12px 12.5px 40px;
					text-align: left;
					text-transform: uppercase;
					font-weight: 700;
					color: #000;
					-webkit-border-radius: 15px;
					border-radius: 15px;
					-webkit-box-shadow: 0 2px 2px rgba(0,0,0,0.2);
					box-shadow: 0 2px 2px rgba(0,0,0,0.2);
					border: 1px solid #F3F3F3;

					&:before {
						position: absolute;
						top: 50%;
						right: 15px;
						background: url(../img/icons/@2x/angle-right@2x.png) 0 0 no-repeat;
						background-size: auto 100%;
						width: 9px;
						height: 10px;
						-webkit-transition: rotate .3s;
						-o-transition: rotate .3s;
						transition: rotate .3s;
						margin-top: -5px;
						content: "";
					}

					.icon {
						left: 15px;
						margin-left: 0;
						margin-top: -7px;
						transition: all .3s;
					}

					.ks-text {
						&.hidden {
							display: none;
						}
					}
				}

				.val-map {
					display: block;
					font-size: 13px;
					min-height: 20px;
					color: #4caf50;

					&.not-def {
						color: #a3a3a3;
						line-height: 1em;
					}

					&.move {
						display: none;
						color: #ff5722;
						line-height: 1em;
					}
				}
			}
		}
		.modal-footer {
			.btn {
				&.add {
					position: relative;
					background: #4caf50;
					color: #fff;
					margin-left: 15px;
					padding-left: 40px;

					&:before {
						position: absolute;
						top: 50%;
						left: 20px;
						background: url(../img/icons/@2x/icon-tick--white@2x.png) 0 0 no-repeat;
						width: 13px;
						height: 13px;
						background-size: auto 100%;
						margin-top: -7.5px;
						margin-left: -6.5px;
						content: "";
					}

					&:hover,
					&:focus {
						background: #53bf57;
					}
				}
			}
		}
	}

	&.assigned {
		max-width: 650px;

		&.add-team {
			.modal-body {
				.assigned-block {
					margin-top: 0;
					padding-top: 0;

					&:before {
						display: none;
					}
				}
			}
		}

		.modal-header {
			background: #673ab7;
		}

		.modal-body {
			.assigned-block {
				position: relative;
				margin-top: 15px;
				padding-top: 30px;

				&:before {
					position: absolute;
					top: 0;
					left: -30px;
					right: -30px;
					border-top: 2px solid #f2f2f2;
					content: "";
				}

				h3 {
					text-transform: uppercase;
					font-weight: 600;
					color: #000;
					text-align: center;
					font-size: 20px;
					margin-bottom: 15px;

					@media (max-width: 576px) {
						font-size: 15px;
					}
				}

				.search-block {
					width: 480px;
					margin: 0 auto 30px;

					@media (max-width: 992px) {
						width: 440px;
					}

					@media (max-width: 768px) {
						width: 100%;
						float: none;
					}

					.search_results {
						position: absolute;
			  		// display: none;
			  		top: 100%;
			  		right: 0;
			  		left: 0;
			  		background: #fff;
			  		padding: 5px 0;
			  		box-shadow: 0 6px 20px 0px rgba(0,0,0,0.25);
			  		border-radius: 0 0 15px 15px;
			  		z-index: 2;

			  	}

			  	.search_results_list {
			  		height: 200px;
			  		overflow-x: auto;
			  		margin-right: 5px;

			  		.item {
			  			position: relative;
			  			display: -ms-flexbox;
			  			display: -webkit-flex;
			  			display: flex;
			  			-webkit-flex-direction: row;
			  			-ms-flex-direction: row;
			  			flex-direction: row;
			  			-webkit-flex-wrap: nowrap;
			  			-ms-flex-wrap: nowrap;
			  			flex-wrap: nowrap;
			  			-webkit-justify-content: flex-start;
			  			-ms-flex-pack: start;
			  			justify-content: flex-start;
			  			-webkit-align-content: stretch;
			  			-ms-flex-line-pack: stretch;
			  			align-content: stretch;
			  			-webkit-align-items: center;
			  			-ms-flex-align: center;
			  			align-items: center;
			  			border-top: 2px solid #f2f2f2;

			  			&:first-child {
			  				border-top: 0;
			  			}

			  			&:hover {
			  				.person-block {
			  					background: #faf9fd;
			  				}

			  				.btn {
			  					background: #673ab7;
			  					color: #fff;
			  					box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.3);
			  				}
			  			}

			  			.btn {
			  				position: absolute;
			  				top: 50%;
			  				right: 15px;
			  				margin-top: -22.5px;

			  				padding: 12px 15px 12px 15px;
			  				text-align: center;
			  				text-transform: uppercase;
			  				border-radius: 15px;
			  				background: #fff;
			  				color: #000;
			  				transition: all .3s;
			  				display: -ms-flexbox;
			  				display: -webkit-flex;
			  				display: -webkit-box;
			  				display: flex;
			  				-webkit-flex-direction: row;
			  				-ms-flex-direction: row;
			  				-webkit-box-orient: horizontal;
			  				-webkit-box-direction: normal;
			  				flex-direction: row;
			  				-webkit-flex-wrap: nowrap;
			  				-ms-flex-wrap: nowrap;
			  				flex-wrap: nowrap;
			  				-webkit-justify-content: center;
			  				-ms-flex-pack: center;
			  				-webkit-box-pack: center;
			  				justify-content: center;
			  				-webkit-align-content: stretch;
			  				-ms-flex-line-pack: stretch;
			  				align-content: stretch;
			  				-webkit-align-items: center;
			  				-ms-flex-align: center;
			  				-webkit-box-align: center;
			  				align-items: center;
			  				font-size: 13px;
			  				font-weight: 700;

			  				@media (max-width: 576px) {
			  					padding: 5px;
			  					font-size: 12px;
			  					top: 24px;
			  					right: 2px;
			  				}

						    // &:hover,
						    // &:focus {
						    // 	background: #673ab7;
						    // 	color: #fff;
						    // 	box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.3);
						    // }
						}
					}

					.person-block {
						transition: all .3s;
						-webkit-flex: 1 1 auto;
						-ms-flex: 1 1 auto;
						flex: 1 1 auto;
						-webkit-align-self: auto;
						-ms-flex-item-align: auto;
						align-self: auto;
						padding: 15px;

						@media (max-width: 576px) {
							padding-top: 30px;
							padding-bottom: 10px;
						}

						.inner {
							display: table;
							height: 45px;
							width: 100%;
							margin: 0;
							padding: 0;

							.avatar,
							.name {
								display: table-cell;
								vertical-align: middle;
							}

							.avatar {
								width: 45px;

								img {
									width: 100%;
									border-radius: 100px;
								}
							}

							.name {
								font-size: 13px;
								color: #a3a3a3;
								padding-left: 15px;

								b {
									display: block;
									font-weight: 600;
									color: #000;
								}
							}
						}
					}		    		
				}

				.form-control {
					height: 45px;
					background: #fff;
					border: 1px solid #e6e6e6;
					padding: 10px 15px;
					-webkit-border-radius: 15px;
					-moz-border-radius: 15px;
					border-radius: 15px;
					color: #000;
					font-size: 14px;
					font-family: "Open Sans", sans-serif;
					-webkit-transition: all .3s;
					-moz-transition: all .3s;
					-ms-transition: all .3s;
					-o-transition: all .3s;
					transition: all .3s;
				}

				.icon-addon {
					height: 43px;
					top: 1px;
					right: 1px;
				}
			}

			.list-block {
				margin: 0 -30px;
				padding: 30px;
				background: #f7f7f7;

				@media (max-width: 768px) {
					margin: 0 -10px;
					padding: 15px 10px;
				}

				h6 {
					display: block;
					font-weight: 600;
					color: #949494;
					line-height: 1em;
					font-size: 14px;
					margin-bottom: 15px;
					text-transform: uppercase;
				}

				.list-item {
					position: relative;
					background: #fff;
					border-radius: 15px;
					box-shadow: 0 1px 4px rgba(0,0,0,0.2);

					.item {
						position: relative;
						display: -ms-flexbox;
						display: -webkit-flex;
						display: flex;
						flex-direction: row;
						flex-wrap: wrap;
						justify-content: flex-start;
						align-content: stretch;
						align-items: center;
						border-top: 2px solid #f2f2f2;

						@media (max-width: 576px) {
							-webkit-flex-wrap: wrap;
							-ms-flex-wrap: wrap;
							flex-wrap: wrap;
						}

						&:first-child {
							border-top: 0;
						}
					}

					.person-block {
						-webkit-flex: 1 1 auto;
						-ms-flex: 1 1 auto;
						flex: 1 1 auto;
						-webkit-align-self: auto;
						-ms-flex-item-align: auto;
						align-self: auto;
						padding: 15px;

						@media (max-width: 576px) {
							-webkit-flex: 1 1 100%;
							-ms-flex: 1 1 100%;
							flex: 1 1 100%;
						}

						.inner {
							display: table;
							height: 45px;
							width: 100%;
							margin: 0;
							padding: 0;

							.avatar,
							.name {
								display: table-cell;
								vertical-align: middle;
							}

							.avatar {
								width: 45px;

								img {
									width: 100%;
									border-radius: 100px;
								}
							}

							.name {
								font-size: 13px;
								color: #a3a3a3;
								padding-left: 15px;

								b {
									display: block;
									font-weight: 600;
									color: #000;
								}
							}
						}
					}

					.person-info {
						-webkit-flex: 1 1 auto;
						-ms-flex: 1 1 auto;
						flex: 1 1 auto;
						-webkit-align-self: auto;
						-ms-flex-item-align: auto;
						align-self: auto;

						@media (max-width: 576px) {
							-webkit-flex: 1 1 100%;
							-ms-flex: 1 1 100%;
							flex: 1 1 100%;
							padding: 0 15px 15px;
						}

						.case-title {
							display: block;
							font-size: 13px;
							color: #a3a3a3;
						}

						.case-val {
							display: block;
							font-size: 13px;
							font-weight: 600;
							color: #000;
						}

						&>div {
							position: relative;
							margin-top: 10px;
							padding-left: 40px;
							color: #939393;
							font-size: 13px;

							&:first-child {
								margin-top: 0;
							}
						}
					}

					.remove-item {
						position: absolute;
						top: 10px;
						right: 10px;
						width: 30px;
						height: 30px;
						display: block;
					}

					.person-information {
						flex: 1 1 100%;
						margin: 15px;
						border-radius: 8px;
						background-color: #ffffff;
						border: solid 1px #e6e6e6;
						padding: 20px 15px 20px 20px;
						opacity: 0;
						visibility: hidden;
						margin: 0;
						padding: 0;
						height: 0;
						transition: all .3s;

						@media (max-width: 768px) {
							margin: 5px;
							padding: 5px;
						}

						&.active {
							opacity: 1;
							visibility: visible;
							margin: 15px;
							padding: 20px 15px 20px 20px;
							height: auto;

							@media (max-width: 768px) {
								margin: 5px;
								padding: 5px;
							}
						}

						.inner-block {
							margin-bottom: 15px;
							display: flex;

							@media (max-width: 768px) {
								flex-wrap: wrap;
							}

							&:nth-child(2) {
								.select2 {
									@media (max-width: 768px) {
										width: 130px !important;
									}
								}
							}

							&:last-child {
								.select2 {
									width: 100% !important;
								}
							}

							.block {
								flex: 1 50%;
								max-width: 50%;

								@media (max-width: 768px) {
									flex: 1 100%;
									max-width: 100%;
									text-align: center;
								}
							}

							.title {
								display: block;
								font-size: 13px;
								color: #000;
								line-height: 1.15em;
								margin-bottom: 5px;
							}

							.description {
								display: block;
								font-size: 10px;
								color: #999;
								line-height: 1.5em;
							}

							.select2 {
								width: auto !important;

								.select2-selection--single {
									padding-right: 15px !important;
								}
							}

							.price-block {
								display: inline-block;
								position: relative;
								width: 70px;
								top: 2px;
								margin-left: 13px;

								&:before {
									position: absolute;
									top: 50%;
									left: 8px;
									content: '€';
									color: #999999;
									font-size: 14px;
									margin-top: -9px;
								}

								.form-control {
									padding-left: 25px;
									padding-right: 5px;
								}
							}
						}
					}
				}
			}
		}
	}

	&.modal-pin-case {
		max-width: 870px;

		.modal-header {
			background: #ff9800;
		}

		.modal-body {
			padding-bottom: 0;
		}

		.modal-footer {
			padding: 7.5px 0;
			justify-content: center;
		}

		.assigned-block {
			position: relative;
			padding-top: 0;
			margin-top: 0;

			&:before {
				display: none;
			}

			h3 {
				text-transform: uppercase;
				font-weight: 600;
				color: #000;
				text-align: center;
				font-size: 20px;
				margin-bottom: 15px;

				@media (max-width: 576px) {
					font-size: 15px;
				}
			}

			.search-block {
				width: 480px;
				margin: 0 auto 30px;

				@media (max-width: 992px) {
					width: 440px;
				}

				@media (max-width: 768px) {
					width: 100%;
					float: none;
				}

				.search_results {
					position: absolute;
		  		// display: none;
		  		top: 100%;
		  		right: 0;
		  		left: 0;
		  		background: #fff;
		  		padding: 5px 0;
		  		box-shadow: 0 6px 20px 0px rgba(0,0,0,0.25);
		  		border-radius: 0 0 15px 15px;
		  		z-index: 2;

		  	}

		  	.search_results_list {
		  		height: 200px;
		  		overflow-x: auto;
		  		margin-right: 5px;

		  		.item {
		  			position: relative;
		  			display: -ms-flexbox;
		  			display: -webkit-flex;
		  			display: flex;
		  			-webkit-flex-direction: row;
		  			-ms-flex-direction: row;
		  			flex-direction: row;
		  			-webkit-flex-wrap: nowrap;
		  			-ms-flex-wrap: nowrap;
		  			flex-wrap: nowrap;
		  			-webkit-justify-content: flex-start;
		  			-ms-flex-pack: start;
		  			justify-content: flex-start;
		  			-webkit-align-content: stretch;
		  			-ms-flex-line-pack: stretch;
		  			align-content: stretch;
		  			-webkit-align-items: center;
		  			-ms-flex-align: center;
		  			align-items: center;
		  			border-top: 2px solid #f2f2f2;

		  			&:first-child {
		  				border-top: 0;
		  			}

		  			&:hover {
		  				.person-block {
		  					background: #faf9fd;
		  				}

		  				.btn {
		  					background: #ff9800;
		  					color: #fff;
		  					box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.3);
		  				}
		  			}

		  			.btn {
		  				position: absolute;
		  				top: 50%;
		  				right: 15px;
		  				margin-top: -22.5px;
		  				padding: 12px 15px 12px 15px;
		  				text-align: center;
		  				text-transform: uppercase;
		  				border-radius: 15px;
		  				background: #fff;
		  				color: #000;
		  				transition: all .3s;
		  				display: -ms-flexbox;
		  				display: -webkit-flex;
		  				display: -webkit-box;
		  				display: flex;
		  				-webkit-flex-direction: row;
		  				-ms-flex-direction: row;
		  				-webkit-box-orient: horizontal;
		  				-webkit-box-direction: normal;
		  				flex-direction: row;
		  				-webkit-flex-wrap: nowrap;
		  				-ms-flex-wrap: nowrap;
		  				flex-wrap: nowrap;
		  				-webkit-justify-content: center;
		  				-ms-flex-pack: center;
		  				-webkit-box-pack: center;
		  				justify-content: center;
		  				-webkit-align-content: stretch;
		  				-ms-flex-line-pack: stretch;
		  				align-content: stretch;
		  				-webkit-align-items: center;
		  				-ms-flex-align: center;
		  				-webkit-box-align: center;
		  				align-items: center;
		  				font-size: 13px;
		  				font-weight: 700;

		  				@media (max-width: 576px) {
		  					padding: 5px;
		  					font-size: 12px;
		  					top: 24px;
		  					right: 2px;
		  				}
		  			}
					}
				}

				.person-block {
					transition: all .3s;
					-webkit-flex: 1 1 auto;
					-ms-flex: 1 1 auto;
					flex: 1 1 auto;
					-webkit-align-self: auto;
					-ms-flex-item-align: auto;
					align-self: auto;
					padding: 15px;

					@media (max-width: 576px) {
						padding-top: 30px;
						padding-bottom: 10px;
					}

					.inner {
						display: table;
						height: 45px;
						width: 100%;
						margin: 0;
						padding: 0;

						.avatar,
						.name {
							display: table-cell;
							vertical-align: middle;
						}

						.avatar {
							width: 45px;

							img {
								width: 100%;
								border-radius: 100px;
							}
						}

						.name {
							font-size: 13px;
							color: #a3a3a3;
							padding-left: 15px;

							b {
								display: block;
								font-weight: 600;
								color: #000;
							}
						}
					}
				}		    		
			}

			.form-control {
				height: 45px;
				background: #fff;
				border: 1px solid #e6e6e6;
				padding: 10px 15px;
				-webkit-border-radius: 15px;
				-moz-border-radius: 15px;
				border-radius: 15px;
				color: #000;
				font-size: 14px;
				font-family: "Open Sans", sans-serif;
				-webkit-transition: all .3s;
				-moz-transition: all .3s;
				-ms-transition: all .3s;
				-o-transition: all .3s;
				transition: all .3s;
			}

			.icon-addon {
				height: 43px;
				top: 1px;
				right: 1px;
			}
		}

		.list-block {
			margin: 0 -30px;
			padding: 0 30px 30px !important;
			background: #f7f7f7;

			@media (max-width: 768px) {
				margin: 0 -10px;
				padding: 15px 10px !important;
			}

			.head-title {
				display: flex;
				padding: 15px;

				@media (max-width: 768px) {
					padding: 5px;
				}

				.semi-th {
					display: block;
					flex: 1 33.3333%;
					font-size: 11px;
				  font-weight: 600;
				  color: #666666;

				  @media (max-width: 768px) {
				  	display: none;
				  }
				}
			}

			h6 {
				display: block;
				font-weight: 600;
				color: #949494;
				line-height: 1em;
				font-size: 14px;
				margin-bottom: 15px;
				text-transform: uppercase;
			}

			.semi-title {
				display: none;

				@media (max-width: 768px) {
					display: block;
					font-size: 11px;
				  font-weight: 600;
				  color: #666666;
				  margin-bottom: 15px;
				  text-align: center;
				}
			}

			.list-item {
				position: relative;
				background: #fff;
				border-radius: 15px;
				box-shadow: 0 1px 4px rgba(0,0,0,0.2);

				.item {
					position: relative;
					display: -ms-flexbox;
					display: -webkit-flex;
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					justify-content: flex-start;
					align-content: stretch;
					align-items: flex-start !important;
					border-top: 2px solid #f2f2f2;
					padding: 15px;

					@media (max-width: 768px) {
						-webkit-flex-wrap: wrap;
						-ms-flex-wrap: wrap;
						flex-wrap: wrap;
					}

					&:first-child {
						border-top: 0;
					}
				}

				.person-block {
					flex: 1 33.333% !important;
					-webkit-align-self: auto;
					-ms-flex-item-align: auto;
					align-self: auto;
					padding: 0 !important;

					@media (max-width: 768px) {
						flex: 1 100% !important;
						padding-bottom: 15px !important;
						margin-bottom: 15px;
						border-bottom: 1px solid #e6e6e6;
					}

					.inner {
						display: table;
						height: 45px;
						width: 100%;
						margin: 0;
						padding: 0;

						.avatar,
						.name {
							display: table-cell;
							vertical-align: top;
						}

						.avatar {
							width: 45px;

							img {
								width: 100%;
								border-radius: 100px;
							}
						}

						.name {
							font-size: 13px;
							color: #a3a3a3;
							padding-left: 15px;

							b {
								display: block;
								font-weight: 600;
								color: #000;
							}
						}
					}
				}

				.case-info {
					flex: 1 33.333% !important;
					align-self: center;

					@media (max-width: 768px) {
						flex: 1 100% !important;
						padding-bottom: 15px !important;
						margin-bottom: 15px;
						border-bottom: 1px solid #e6e6e6;
					}

					.case-title {
						display: block;
						font-size: 13px;
						color: #a3a3a3;
					}

					.case-val {
						display: block;
						font-size: 13px;
						font-weight: 600;
						color: #000;
						margin-top: 10px;
						transition: all .3s;

						&:first-child {
							margin-top: 0;
						}

						&:hover {
							color: #ff9800;
						}
					}
				}

				.ref-info {
					flex: 1 33.333% !important;
					align-self: center;

					@media (max-width: 768px) {
						flex: 1 100% !important;
					}

					.ref-val {
						display: block;
						font-size: 13px;
						font-weight: 400;
						color: #999999;
						margin-top: 10px;
						transition: all .3s;

						&:first-child {
							margin-top: 0;
						}

						&:hover {
							color: #ff9800;
						}
					}
				}
			}
		}
	}

	.modal-footer {
		.btn {
			&.add {
				position: relative;
				background: #673ab7;
				color: #fff;
				margin-left: 15px;
				padding-left: 40px;

				&:before {
					position: absolute;
					top: 50%;
					left: 20px;
					background: url(../img/icons/@2x/icon-tick--white@2x.png) 0 0 no-repeat;
					width: 13px;
					height: 13px;
					background-size: auto 100%;
					margin-top: -7.5px;
					margin-left: -6.5px;
					content: "";
				}

				&:hover,
				&:focus {
					background: #5a33a0;
				}
			}
		}
	}
}

&.add-meeting {
	max-width: 1320px;

	.modal-header {
		background: #00bcd4;
	}

	.modal-body {
		padding: 15px;

		& > .row {
			margin: 0;

			& > .col-md-6 {
				padding: 30px 30px 15px;
				background: #f7f7f7;
				border-radius: 15px;

				&:first-child {
					background: 0;
				}
			}
		}
		.meeting-title {
			text-transform: uppercase;
			font-weight: 600;
			text-align: center;
			margin-bottom: 20px;
			margin-top: 60px;

			&:first-child {
				margin-top: 0;
			}
		}

		.col-slider {
			padding-top: 12px;
		}

		.form-group {
			.form-group {
				margin-bottom: 0;
			}
		}

		.expires-group {
			position: relative;

			&:before {
				position: absolute;
				top: 50%;
				left: 10px;
				content: "";
				background: url(../img/icons/@2x/icon-date@2x.png) 0 0 no-repeat;
				width: 16px;
				height: 16px;
				-webkit-background-size: auto 100%;
				background-size: auto 100%;
				margin-top: -8px;
				opacity: .3;
			}

			.form-control {
				position: relative;
				padding-left: 40px;
				background: transparent;
			}
		}

		.add-map {
			margin-bottom: 30px;

			.ks-show-map-btn {
				position: relative;
				display: block;
				background: #fff;
				padding: 12.5px 12px 12.5px 40px;
				text-align: left;
				text-transform: uppercase;
				font-weight: 700;
				color: #000;
				-webkit-border-radius: 15px;
				border-radius: 15px;
				-webkit-box-shadow: 0 2px 2px rgba(0,0,0,0.2);
				box-shadow: 0 2px 2px rgba(0,0,0,0.2);
				border: 1px solid #F3F3F3;

				&:before {
					position: absolute;
					top: 50%;
					right: 15px;
					background: url(../img/icons/@2x/angle-right@2x.png) 0 0 no-repeat;
					background-size: auto 100%;
					width: 9px;
					height: 10px;
					-webkit-transition: rotate .3s;
					-o-transition: rotate .3s;
					transition: rotate .3s;
					margin-top: -5px;
					content: "";
				}

				.icon {
					left: 15px;
					margin-left: 0;
					margin-top: -7px;
					transition: all .3s;
				}

				.ks-text {
					&.hidden {
						display: none;
					}
				}
			}

			.val-map {
				display: block;
				font-size: 13px;
				min-height: 20px;
				color: #4caf50;

				&.not-def {
					color: #a3a3a3;
					line-height: 1em;
				}

				&.move {
					display: none;
					color: #ff5722;
					line-height: 1em;
				}
			}
		}

		.search-block {
			width: 480px;
			margin: 0 auto 30px;

			@media (max-width: 992px) {
				width: 440px;
			}

			@media (max-width: 768px) {
				width: 100%;
				float: none;
			}

			.search_results {
				position: absolute;
		  		// display: none;
		  		top: 100%;
		  		right: 0;
		  		left: 0;
		  		background: #fff;
		  		padding: 5px 0;
		  		box-shadow: 0 6px 20px 0px rgba(0,0,0,0.25);
		  		border-radius: 0 0 15px 15px;
		  		z-index: 2;

		  	}

		  	.search_results_list {
		  		height: 200px;
		  		overflow-x: auto;
		  		margin-right: 5px;

		  		.item {
		  			position: relative;
		  			display: -ms-flexbox;
		  			display: -webkit-flex;
		  			display: flex;
		  			-webkit-flex-direction: row;
		  			-ms-flex-direction: row;
		  			flex-direction: row;
		  			-webkit-flex-wrap: nowrap;
		  			-ms-flex-wrap: nowrap;
		  			flex-wrap: nowrap;
		  			-webkit-justify-content: flex-start;
		  			-ms-flex-pack: start;
		  			justify-content: flex-start;
		  			-webkit-align-content: stretch;
		  			-ms-flex-line-pack: stretch;
		  			align-content: stretch;
		  			-webkit-align-items: center;
		  			-ms-flex-align: center;
		  			align-items: center;
		  			border-top: 2px solid #f2f2f2;

		  			&:first-child {
		  				border-top: 0;
		  			}

		  			&:hover {
		  				.person-block {
		  					background: #f2fcfd;
		  				}

		  				.btn {
		  					background: #00bcd4;
		  					color: #fff;
		  					box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.3);
		  				}
		  			}

		  			.btn {
		  				position: absolute;
		  				top: 50%;
		  				right: 15px;
		  				margin-top: -22.5px;

		  				padding: 12px 15px 12px 15px;
		  				text-align: center;
		  				text-transform: uppercase;
		  				border-radius: 15px;
		  				background: #fff;
		  				color: #000;
		  				transition: all .3s;
		  				display: -ms-flexbox;
		  				display: -webkit-flex;
		  				display: -webkit-box;
		  				display: flex;
		  				-webkit-flex-direction: row;
		  				-ms-flex-direction: row;
		  				-webkit-box-orient: horizontal;
		  				-webkit-box-direction: normal;
		  				flex-direction: row;
		  				-webkit-flex-wrap: nowrap;
		  				-ms-flex-wrap: nowrap;
		  				flex-wrap: nowrap;
		  				-webkit-justify-content: center;
		  				-ms-flex-pack: center;
		  				-webkit-box-pack: center;
		  				justify-content: center;
		  				-webkit-align-content: stretch;
		  				-ms-flex-line-pack: stretch;
		  				align-content: stretch;
		  				-webkit-align-items: center;
		  				-ms-flex-align: center;
		  				-webkit-box-align: center;
		  				align-items: center;
		  				font-size: 13px;
		  				font-weight: 700;

		  				@media (max-width: 576px) {
		  					padding: 5px;
		  					font-size: 12px;
		  					top: 24px;
		  					right: 2px;
		  				}

					    // &:hover,
					    // &:focus {
					    // 	background: #673ab7;
					    // 	color: #fff;
					    // 	box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.3);
					    // }
					}
				}

				.person-block {
					transition: all .3s;
					-webkit-flex: 1 1 auto;
					-ms-flex: 1 1 auto;
					flex: 1 1 auto;
					-webkit-align-self: auto;
					-ms-flex-item-align: auto;
					align-self: auto;
					padding: 15px;

					@media (max-width: 576px) {
						padding-top: 30px;
						padding-bottom: 10px;
					}

					.inner {
						display: table;
						height: 45px;
						width: 100%;
						margin: 0;
						padding: 0;

						.avatar,
						.name {
							display: table-cell;
							vertical-align: middle;
						}

						.avatar {
							width: 45px;

							img {
								width: 100%;
								border-radius: 100px;
							}
						}

						.name {
							font-size: 13px;
							color: #a3a3a3;
							padding-left: 15px;

							b {
								display: block;
								font-weight: 600;
								color: #000;
							}
						}
					}
				}		    		
			}

			.form-control {
				height: 45px;
				background: #fff;
				border: 1px solid #e6e6e6;
				padding: 10px 15px;
				-webkit-border-radius: 15px;
				-moz-border-radius: 15px;
				border-radius: 15px;
				color: #000;
				font-size: 14px;
				font-family: "Open Sans", sans-serif;
				-webkit-transition: all .3s;
				-moz-transition: all .3s;
				-ms-transition: all .3s;
				-o-transition: all .3s;
				transition: all .3s;
			}

			.icon-addon {
				height: 43px;
				top: 1px;
				right: 1px;
			}
		}

		.list-block {
			margin: 0 -30px;
			padding: 30px;
			background: #f7f7f7;

			@media (max-width: 768px) {
				margin: 0 -10px;
				padding: 15px 10px;
			}

			h6 {
				display: block;
				font-weight: 600;
				color: #949494;
				line-height: 1em;
				font-size: 14px;
				margin-bottom: 15px;
				text-transform: uppercase;
			}

			.no-result {
				display: block;
				text-align: center;
				flex: 1 100%;
				padding: 27px 0;
				color: #999;
			}

			.list-item {
				position: relative;
				background: #fff;
				border-radius: 15px;
				box-shadow: 0 1px 4px rgba(0,0,0,0.2);

				.item {
					position: relative;
					display: -ms-flexbox;
					display: -webkit-flex;
					display: flex;
					-webkit-flex-direction: row;
					-ms-flex-direction: row;
					flex-direction: row;
					-webkit-flex-wrap: nowrap;
					-ms-flex-wrap: nowrap;
					flex-wrap: nowrap;
					-webkit-justify-content: flex-start;
					-ms-flex-pack: start;
					justify-content: flex-start;
					-webkit-align-content: stretch;
					-ms-flex-line-pack: stretch;
					align-content: stretch;
					-webkit-align-items: center;
					-ms-flex-align: center;
					align-items: center;
					border-top: 2px solid #f2f2f2;

					@media (max-width: 576px) {
						-webkit-flex-wrap: wrap;
						-ms-flex-wrap: wrap;
						flex-wrap: wrap;
					}

					&:first-child {
						border-top: 0;
					}
				}

				.person-block {
					-webkit-flex: 1 1 auto;
					-ms-flex: 1 1 auto;
					flex: 1 1 auto;
					-webkit-align-self: auto;
					-ms-flex-item-align: auto;
					align-self: auto;
					padding: 15px;

					@media (max-width: 576px) {
						-webkit-flex: 1 1 100%;
						-ms-flex: 1 1 100%;
						flex: 1 1 100%;
					}

					.inner {
						display: table;
						height: 45px;
						width: 100%;
						margin: 0;
						padding: 0;

						.avatar,
						.name {
							display: table-cell;
							vertical-align: middle;
						}

						.avatar {
							width: 45px;

							img {
								width: 100%;
								border-radius: 100px;
							}
						}

						.name {
							font-size: 13px;
							color: #a3a3a3;
							padding-left: 15px;

							b {
								display: block;
								font-weight: 600;
								color: #000;
							}
						}
					}
				}

				.person-info {
					-webkit-flex: 1 1 auto;
					-ms-flex: 1 1 auto;
					flex: 1 1 auto;
					-webkit-align-self: auto;
					-ms-flex-item-align: auto;
					align-self: auto;

					@media (max-width: 576px) {
						-webkit-flex: 1 1 100%;
						-ms-flex: 1 1 100%;
						flex: 1 1 100%;
						padding-bottom: 15px;
					}

					.case-title {
						display: block;
						font-size: 13px;
						color: #a3a3a3;
					}

					.case-val {
						display: block;
						font-size: 13px;
						font-weight: 600;
						color: #000;
					}

					&>div {
						position: relative;
						margin-top: 10px;
						padding-left: 40px;
						color: #939393;
						font-size: 13px;

						&:first-child {
							margin-top: 0;
						}
					}
				}

				.remove-item {
					position: absolute;
					top: 10px;
					right: 10px;
					width: 30px;
					height: 30px;
					display: block;
				}
			}
		}

		.set-date-block {
			position: absolute;
			top: 0;
			left: 0;
			width: 480px;
			box-shadow: 0 6px 20px 0px rgba(0,0,0,0.25);
			background: #fff;
			z-index: 2;
			display: flex;
			flex-wrap: wrap;
			border-radius: 15px;
    		// opacity: 0;
    		// visibility: hidden;
    		transition: all .3s;

    		&.show {
    			opacity: 1;
    			visibility: visible;
    		}

    		.timepicker-block,
    		.datepicker-block {
    			flex: 0 0 50%;
    			padding: 15px;
    		}

    		.head {
    			position: relative;
    			text-align: right;
    			padding-bottom: 10px;
    			border-bottom: 2px solid #f2f2f2;
    			margin-bottom: 20px;
    			font-weight: 400;
    			font-size: 13px;

    			.title {
    				position: absolute;
    				top: 50%;
    				left: 0;
    				color: #ccc;
    				font-weight: 400;
    				font-size: 13px;
    				padding-left: 25px;
    				margin-top: -13px;

    				.icon {
    					left: 0;
    					margin-left: 0;
    					opacity: .3;
    				}
    			}

    			input {
    				background: 0;
    				border: 0;
    				text-align: right;
    				font-size: 13px;
    				color: #666666;
    				font-weight: 400;
    			}
    		}

    		.timepicker-block {
    			.timepicker-inner .inner-block .value:hover,
    			.timepicker-inner .inner-block .value.active {
    				background: #00bcd4;
    				color: #fff;
    			}

    			.timepicker-inner .inner-block .value:hover {
    				background: #e4f7fa;
    				color: #00bcd4;
    			}

    			.timepicker-inner .simplebar-scrollbar {
    				background: #00bcd4;
    			}
    		}

    		.datepicker-block {
    			.datepicker {
    				td,
    				th {
    					background: none !important;
    					&.dow {
    						padding: 5px 0;
    						color: #cccccc !important;
    					}

    					&.active {
    						background: #00bcd4 !important;
    						color: #fff !important;
    					}

    					&.today {
    						background: #e4f7fa !important;
    						color: #00bcd4 !important;

    						&:hover {
    							color: #fff !important;        
    						}
    					}

    					&.day {
    						&:hover {
    							background: #00bcd4 !important;
    							color: #fff;
    						}
    					}
    				}
    			}
    		}
    	}
    }

    .modal-footer {
    	justify-content: center;
    	padding: 30px;
    	border-top: 1px solid #e6e6e6;

    	.btn {
    		&.add {
    			background: #00bcd4;
    			color: #fff;
    			padding: 12px 30px;

    			@media (max-width: 768px) {
    				padding: 12px 10px;
    			}

    			&:hover,
    			&:focus {
    				background: #00bcd4bd;
    			}
    		}

    	}
    }
}

&.add-attach {
	.modal-body {
		.attach-block {
			position: relative;
			padding: 12.5px 25px 12.5px 40px;
			border: 1px solid #e6e6e6;
			-webkit-border-radius: 15px;
			border-radius: 15px;

			&.upload-success {
				.icon {
					left: 15px;
					margin-left: 0;
					opacity: .2;
				}

				&:before {
					position: absolute;
					top: 50%;
					right: 10px;
					content: "";
					background: url(../img/icons/@2x/icon-tick--green@2x.png) 0 0 no-repeat;
					width: 13px;
					height: 13px;
					margin-top: -6.5px;
					background-size: auto 100%;
					opacity: 1;
					visibility: visible;
					transition: all .3s;
				}

				&:hover {
					&:before {
						opacity: 0;
						visibility: hidden;
					}

					.remove {
						opacity: 1;
						visibility: visible;
					}
				}
			}

			&.uploading {
				.icon {
					left: 15px;
					margin-left: 0;
					opacity: .2;
				}

				.upload-icon {
					position: absolute;
					top: 50%;
					right: 10px;
					background: url(../img/icons/@2x/icon-attach-loading@2x.png) 0 0 no-repeat;
					width: 15px;
					height: 15px;
					margin-top: -7.5px;
					background-size: auto 100%;
					opacity: 1;
					visibility: visible;
					transition: all .3s;
					animation: fa-spin 2s infinite linear;
				}
			}

			&.error-uploading {
				.icon {
					left: 15px;
					margin-left: 0;
					opacity: .2;
				}

				&:before {
					position: absolute;
					top: 50%;
					right: 10px;
					content: "";
					background: url(../img/icons/@2x/icon-attach-error@2x.png) 0 0 no-repeat;
					width: 15px;
					height: 15px;
					margin-top: -7.5px;
					background-size: auto 100%;
					opacity: 1;
					visibility: visible;
					transition: all .3s;
				}
			}

			.ks-text {
				font-size: 13px;
				color: #000;
			}

			.remove {
				position: absolute;
				top: 50%;
				right: 10px;
				background: url('../img/icons/@2x/clear-item@2x.png') 0 0 no-repeat;
				width: 15px;
				height: 15px;
				background-size: 100% auto;
				margin-top: -7.6px;
				opacity: 0;
				visibility: hidden;
				transition: all .3s;
			}
		}

		.ks-btn-file {
			position: relative;
			font-size: 12px;
			line-height: 22px;
			font-weight: 500;
			border-radius: 15px;
			padding: 10px 25px;
			background: #fff;
			color: #fff;
			height: 45px;
			-webkit-box-align: center;
			-webkit-align-items: center;
			-ms-flex-align: center;
			align-items: center;
			-webkit-box-pack: center;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
			justify-content: center;
			overflow: hidden;
			width: 100%;
			box-shadow: 0 4px 14px 2px rgba(0,0,0,0.2);
			cursor: default;

			&:hover {
				.ks-text {
					opacity: 1;
				}
			}

			.ks-text {
				padding-right: 0 !important;
				color: #000;
				font-size: 13px;
				font-weight: 700;
				text-transform: uppercase;
				opacity: .3;
				transition: all .3s;
			}

			input {
				position: absolute;
				top: 0;
				right: 0;
				min-width: 100%;
				min-height: 100%;
				font-size: 26px;
				text-align: right;
				opacity: 0;
				cursor: inherit;
				display: block;
			}
		}

		.btn-add {
			display: block;
			margin: 8px auto;
			position: relative;
			width: 30px;
			height: 30px;
			padding: 0;
			border-radius: 100px;
			box-shadow: 0 2px 4px rgba(0,0,0,0.3);
			opacity: 1;
			transition: all .3s;
			background: #4caf50;

			&:hover {
				opacity: .8;
			}

			&:before {
				position: absolute;
				top: 50%;
				left: 50%;
				background: url(../img/icons/@2x/icon-plus--white@2x.png) 0 0 no-repeat;
				width: 13px;
				height: 13px;
				-webkit-background-size: 100% auto;
				background-size: 100% auto;
				margin-top: -6.5px;
				margin-left: -6.5px;
				content: "";
				-webkit-transition: all .3s;
				-o-transition: all .3s;
				transition: all .3s;
			}
		}
	}

	.modal-footer {
		justify-content: center;

		.add {
			padding: 12px 25px 12px 25px	
		}
	}
}

&.timesheet-history {
	max-width: 750px;

	.modal-header {
		background: #ff5722;
	}

	.modal-body {
		padding-bottom: 0;

		.head-history {
			margin-bottom: 45px;

			.week-block {
				position: relative;
				height: 45px;
				box-shadow: 0 1px 4px rgba(0,0,0,0.2);
				border-radius: 15px;
				text-align: center;
				padding: 12px 0;
				font-weight: 600;
				color: #666;

				@media (max-width: 768px) {
					margin-top: 20px;
				}

				.angle-left {
					position: absolute;
					top: 1px;
					left: 1px;
					width: 35px;
					height: 45px;

					&:before {
						position: absolute;
						top: 50%;
						left: 50%;
						background: url(../img/icons/@2x/angle-down@2x.png) 0 0 no-repeat;
						width: 9px;
						height: 9px;
						-webkit-background-size: 100% auto;
						background-size: 100% auto;
						margin-top: -4.5px;
						margin-left: -4.5px;
						border: 0;
						opacity: .5;
						-webkit-transition: all .3s;
						-o-transition: all .3s;
						transition: all .3s;
						content: "";
						transform: rotate(90deg);
					}
				}

				.angle-right {
					position: absolute;
					top: 1px;
					right: 1px;
					width: 35px;
					height: 45px;

					&:before {
						position: absolute;
						top: 50%;
						left: 50%;
						background: url(../img/icons/@2x/angle-down@2x.png) 0 0 no-repeat;
						width: 9px;
						height: 9px;
						-webkit-background-size: 100% auto;
						background-size: 100% auto;
						margin-top: -4.5px;
						margin-left: -4.5px;
						border: 0;
						opacity: .5;
						-webkit-transition: all .3s;
						-o-transition: all .3s;
						transition: all .3s;
						content: "";
						transform: rotate(-90deg);
					}
				}

				.val {
					display: inline-block;
					font-style: normal;
					margin: 0 5px;
				}
			}

			.month {
				.select2-container--default .select2-selection--single .select2-selection__rendered {
					padding-left: 35px;
					position: relative;

					&:before {
						position: absolute;
						top: 50%;
						left: 10px;
						content: "";
						background: url(../img/icons/@2x/icon-calendar-month--red@2x.png) 0 0 no-repeat;
						width: 15px;
						height: 15px;
						-webkit-background-size: auto 100%;
						background-size: auto 100%;
						margin-top: -7.5px;
					}
				}
			}

			.year {
				order: 1;

				@media (max-width: 768px) {
					order: 0;
				}

				@media (max-width: 576px) {
					margin-top: 20px;
				}

				.select2-container--default .select2-selection--single .select2-selection__rendered {
					padding-left: 35px;
					position: relative;

					&:before {
						position: absolute;
						top: 50%;
						left: 10px;
						content: "";
						background: url(../img/icons/@2x/icon-calendar--red@2x.png) 0 0 no-repeat;
						width: 15px;
						height: 15px;
						-webkit-background-size: auto 100%;
						background-size: auto 100%;
						margin-top: -7.5px;
					}
				}
			}

			.select2-container--default .select2-selection--single .select2-selection__rendered {
				font-weight: 600;
				color: #666;
			}
		}

		.content-history {
			.ks-nav-tabs {
				flex-wrap: nowrap;
				border-right: 2px solid #f2f2f2;
				margin-right: 85px;

				@media (max-width: 768px) {
					overflow: hidden;
					overflow-x: auto;
				}

				&::-webkit-scrollbar {
					height: 10px;
					background-color: #F5F5F5;
				}

				&::-webkit-scrollbar-track {
					border-radius: 10px;
					background: rgba(0,0,0,0.1);
					border: 1px solid #ccc;
				}

				&::-webkit-scrollbar-thumb {
					border-radius: 10px;
					background: linear-gradient(to left, #fff, #e4e4e4);
					border: 1px solid #aaa;
				}

				&::-webkit-scrollbar-thumb:hover {
					background: #fff;
				}

				& > li {
					flex: 1 100%;
				}

				.nav-link {
					position: relative;
					color: #ccc;
					padding: 15px 25px;
					font-weight: 600;
					text-align: center;

					&:before {
						position: absolute;
						bottom: 0;
						left: 0;
						right: 0;
						content: "";
						border-bottom: 4px solid #ff5722;
						opacity: 0;
						transition: all .3s;
					}

					&.active {
						color: #000;

						&:before {
							opacity: 1;
						}
					}

					.time {
						display: block;
						font-weight: 400;
						font-size: 13px;
						margin-top: 5px;
					}
				}
			}

			.total-block {
				position: absolute;
				top: 0;
				right: 0;
				color: #666;
				padding: 15px 25px;
				font-weight: 600;
				text-align: center;

				.time {
					display: block;
					font-weight: 400;
					font-size: 13px;
					margin-top: 5px;
				}
			}

			.list-block {
				margin: 0 -30px;
				padding: 30px;
				background: #f7f7f7;

				@media (max-width: 768px) {
					margin: 0 -10px;
					padding: 15px 10px;
				}

				.tab-pane {
					box-shadow: 0 1px 4px rgba(0,0,0,0.2);
					border-radius: 15px;
					background: #fff;
				}

				.list-item {
					position: relative;
					background: #fff;
					border-top: 2px solid #f3f3f3;


					&:first-child {
						border-top: 0;
						border-radius: 15px 15px 0 0;
					}

					&:last-child {
						border-radius: 0 0 15px 15px;
					}

					.item {
						position: relative;
						display: -ms-flexbox;
						display: -webkit-flex;
						display: flex;
						-webkit-flex-direction: row;
						-ms-flex-direction: row;
						flex-direction: row;
						-webkit-flex-wrap: nowrap;
						-ms-flex-wrap: nowrap;
						flex-wrap: nowrap;
						-webkit-justify-content: flex-start;
						-ms-flex-pack: start;
						justify-content: flex-start;
						-webkit-align-content: stretch;
						-ms-flex-line-pack: stretch;
						align-content: stretch;
						-webkit-align-items: center;
						-ms-flex-align: center;
						align-items: center;
						border-top: 2px solid #f2f2f2;

						@media (max-width: 576px) {
							-webkit-flex-wrap: wrap;
							-ms-flex-wrap: wrap;
							flex-wrap: wrap;
						}

						&:first-child {
							border-top: 0;
						}
					}

					.person-block {
						-webkit-flex: 1 1 auto;
						-ms-flex: 1 1 auto;
						flex: 1 1 auto;
						-webkit-align-self: auto;
						-ms-flex-item-align: auto;
						align-self: auto;
						padding: 15px;

						@media (max-width: 576px) {
							-webkit-flex: 1 1 100%;
							-ms-flex: 1 1 100%;
							flex: 1 1 100%;
							padding: 10px;
						}

						.inner {
							display: table;
							height: 45px;
							width: 100%;
							margin: 0;
							padding: 0;

							.avatar,
							.name {
								display: table-cell;
								vertical-align: middle;
							}

							.avatar {
								width: 45px;

								img {
									width: 100%;
									border-radius: 100px;
								}
							}

							.name {
								font-size: 13px;
								color: #a3a3a3;
								padding-left: 15px;

								b {
									display: block;
									font-weight: 600;
									color: #000;
								}
							}
						}
					}

					.time-block,
					.event-block,
					.task-block,
					.case-block {
						-webkit-flex: 1 1 auto;
						-ms-flex: 1 1 auto;
						flex: 1 1 auto;
						-webkit-align-self: auto;
						-ms-flex-item-align: auto;
						align-self: auto;

						@media (max-width: 576px) {
							flex: 1 1 50%;
							padding: 10px;
						}

						.case-title {
							display: block;
							font-size: 13px;
							color: #a3a3a3;
						}

						.case-val {
							display: block;
							font-size: 13px;
							font-weight: 600;
							color: #000;
						}

						&>div {
							position: relative;
							margin-top: 10px;
							color: #939393;
							font-size: 13px;

							&:first-child {
								margin-top: 0;
							}
						}
					}

					.time-block {
						text-align: center;
						font-size: 15px;
						color: #000;
						flex: 0 100px;

						@media (max-width: 576px) {
							flex: 1 1 50%;
							padding: 10px;
						}
					}

					.event-block {
						flex: 0 110px;

						@media (max-width: 576px) {
							flex: 1 1 50%;
							padding: 10px;
						}
					}

					.player-block {
						height: 75px;
						padding: 15px 0;
						display: inline-block;

						@media (max-width: 576px) {
							padding: 0;
							height: auto;
						}

						&.is-playing {
							.play-btn {
								.play-icon {
									animation-name: playAnimationMobile;
									animation-duration: 1000ms;
									animation-timing-function: steps(30);
									animation-fill-mode: forwards;
									animation-direction: alternate;
									animation-iteration-count: 1; 
								}
							}
						}

						.play-btn {
							opacity: 1 !important;
							outline: 0 !important;
							box-shadow: none !important;

							@media (max-width: 768px) {
								height: 42px;
							}

							&:hover {
								.play-icon {
									animation: none;
									background-color: rgba(88,187,92, 1);
									cursor: pointer;
									animation: pulse 2s infinite;
								}
							}

							.play-icon {
								display: inline-block;
								width: 30px;
								height: 30px;
								background: url(../img/animation/play-btn_spritesheet.svg) 0 0 no-repeat;
								cursor: pointer;
								box-shadow: 0 0 0 rgba(76,175,80, 0.6);
								border-radius: 50px;
								transition: all 0.2s ease-in;
								background-size: auto 100%;
							}
						}

							// @-webkit-keyframes pulse {
							//   0% {
							//     -webkit-box-shadow: 0 0 0 0 rgba(76,175,80, 0.6);
							//   }
							//   70% {
							//       -webkit-box-shadow: 0 0 0 15px rgba(76,175,80, 0.6);
							//   }
							//   100% {
							//       -webkit-box-shadow: 0 0 0 0 rgba(76,175,80, 0);
							//   }
							// }
							// @keyframes pulse {
							//   0% {
							//     -moz-box-shadow: 0 0 0 0 rgba(76,175,80, 0.6);
							//     box-shadow: 0 0 0 0 rgba(76,175,80, 0.6);
							//   }
							//   70% {
							//       -moz-box-shadow: 0 0 0 15px rgba(76,175,80, 0);
							//       box-shadow: 0 0 0 15px rgba(76,175,80, 0);
							//   }
							//   100% {
							//       -moz-box-shadow: 0 0 0 0 rgba(76,175,80, 0);
							//       box-shadow: 0 0 0 0 rgba(76,175,80, 0);
							//   }
							// }
						}

						.btn-edit {
							width: 30px;
							height: 30px;
							padding: 0;
							position: relative;
							border-radius: 30px;
						}
					}
				}
			}
		}
	}

	&.add-timesheet {
		max-width: 750px;
		
		.modal-header {
			background: #ff5722;
		}

		.modal-body {
			.head-block {
				padding-top: 20px;
				padding-bottom: 20px;

				.set-time-block {
					position: relative;
					padding-left: 27px;
					display: -ms-flexbox;
					display: -webkit-flex;
					display: flex;
					-webkit-flex-direction: row;
					-ms-flex-direction: row;
					flex-direction: row;
					-webkit-flex-wrap: nowrap;
					-ms-flex-wrap: nowrap;
					flex-wrap: nowrap;
					-webkit-justify-content: flex-start;
					-ms-flex-pack: start;
					justify-content: flex-start;
					-webkit-align-content: stretch;
					-ms-flex-line-pack: stretch;
					align-content: stretch;
					-webkit-align-items: center;
					-ms-flex-align: center;
					align-items: center;

					@media (max-width: 576px) {
						margin-bottom: 30px;
					}

					&:before {
						position: absolute;
						top: 50%;
						left: 0;
						background: url(../img/icons/@2x/icon-clock--red@2x.png) 0 0 no-repeat;
						width: 20px;
						height: 20px;
						background-size: auto 100%;
						margin-top: -10px;
						content: "";
					}

					.clock {
						position: relative;
						flex: 1 1 30px;
						border-top: 2px solid #f2f2f2;
						border-bottom: 2px solid #f2f2f2;
						cursor: pointer;

						&:hover {
							input {
								color: #ff5722;
							}

							.set-top,
							.set-bottom {
								opacity: 1;
								visibility: visible;
							}
						}

						input {
							display: inline-block;
							width: 100%;
							height: 43px;
							font-size: 16px;
							color: #000;
							text-align: center;
							border: 0;
							background: #fff;
							cursor: pointer;
						}

						.set-top {
							top: -26px;
							left: 0;
							right: 0;
							position: absolute;
							height: 25px;
							opacity: 0;
							visibility: hidden;
							transition: all .3s;

							&:before {
								position: absolute;
								top: 50%;
								left: 50%;
								background: url(../img/icons/@2x/angle-down@2x.png) 0 0 no-repeat;
								width: 9px;
								height: 9px;
								-webkit-background-size: 100% auto;
								background-size: 100% auto;
								margin-top: -4.5px;
								margin-left: -4.5px;
								opacity: .5;
								transition: all .3s;
								content: "";
								transform: rotate(180deg)
							}
						}

						.set-bottom {
							bottom: -26px;
							left: 0;
							right: 0;
							position: absolute;
							height: 25px;
							opacity: 0;
							visibility: hidden;
							transition: all .3s;

							&:before {
								position: absolute;
								top: 50%;
								left: 50%;
								background: url(../img/icons/@2x/angle-down@2x.png) 0 0 no-repeat;
								width: 9px;
								height: 9px;
								-webkit-background-size: 100% auto;
								background-size: 100% auto;
								margin-top: -4.5px;
								margin-left: -4.5px;
								opacity: .5;
								transition: all .3s;
								content: "";
							}
						}
					}

					b {
						font-weight: 400;
						flex: 0 auto;
						margin: 0 7px;
						color: #000;
					}
				}

				.set-range-time {
					.setter-time-block {
						display: -ms-flexbox;
						display: -webkit-flex;
						display: flex;
						-webkit-flex-direction: row;
						-ms-flex-direction: row;
						flex-direction: row;
						-webkit-flex-wrap: nowrap;
						-ms-flex-wrap: nowrap;
						flex-wrap: nowrap;
						-webkit-justify-content: flex-start;
						-ms-flex-pack: start;
						justify-content: flex-start;
						-webkit-align-content: stretch;
						-ms-flex-line-pack: stretch;
						align-content: stretch;
						-webkit-align-items: center;
						-ms-flex-align: center;
						align-items: center;

						@media (max-width: 576px) {
							margin-top: 15px;
						}

						&:first-child {
							margin-top: 0;
						}

						label {
							color: #999;
							text-transform: uppercase;
							padding-right: 15px;
							flex: 0 auto;
							margin: 0;
							font-weight: 600;
							font-size: 12px;

							@media (max-width: 576px) {
								flex: 0 70px;
							}

						}

						.block {
							position: relative;
							flex: 1 auto;

							&:before {
								position: absolute;
								top: 50%;
								left: 14px;
								background: url(../img/icons/@2x/icon-time@2x.png) 0 0 no-repeat;
								width: 16px;
								height: 16px;
								background-size: auto 100%;
								opacity: 0.3;
								margin-top: -8px;
								content: "";
							}

							input {
								padding-left: 40px;
								font-size: 13px;
							}
						}
					}
				}
			}

			.set-date {
				position: relative;
				margin-bottom: 30px;

				&:after {
					position: absolute;
					top: 50%;
					border-bottom: 2px solid #f3f3f3;
					left: -15px;
					right: -15px;
					content: "";
					margin-top: -1px;

					@media (max-width: 768px) {
						left: 5px;
						right: 5px;
					}
				}

				.set-date-block {
					position: relative;
					z-index: 2;

					&:before {
						position: absolute;
						top: 50%;
						left: 14px;
						background: url(../img/icons/@2x/icon-date@2x.png) 0 0 no-repeat;
						width: 16px;
						height: 16px;
						background-size: auto 100%;
						opacity: 0.3;
						margin-top: -8px;
						content: "";

					}
					input {
						padding-left: 40px;
						font-size: 13px;
					}
				}
			}

			.main-form {
				label {
					color: #999;
					text-transform: uppercase;
					font-weight: 600;
					font-size: 12px;
				}

				.open {
					.form-control {
						border-radius: 15px 15px 0 0;
					}

					.search_results {
						display: block;
					}
				}

				.search_results {
					position: absolute;
					display: none;
					top: 100%;
					right: 15px;
					left: 15px;
					background: #fff;
					border: 1px solid #e6e6e6;
					border-top: 0;
					padding: 5px 0;
					box-shadow: 0 6px 20px 0px rgba(0,0,0,0.25);
					border-radius: 0 0 15px 15px;
					z-index: 2;

				}

				.search_results_list {
					height: 100px;
					overflow-x: auto;
					margin-right: 5px;

					li {
						font-size: 13px;
						color: #ccc;

						.item-selector {
							display: block;
							color: #ccc;
							padding: 5px 10px;
							background: #fff;

							&:hover {
								background: #fff5f2;
								color: #ff5722;

								b {
									color: #ff5722;		  						
								}
							}

							b {
								font-weight: 400;
								color: #000;
								transition: all .3s;
							}
						}
					}		    		
				}
			}
		}

		.modal-footer {
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			-webkit-justify-content: center;
			justify-content: center;

			.btn {
				&.add {
					position: relative;
					background: #ff5722;
					color: #fff;
					padding-left: 40px;

					&:before {
						position: absolute;
						top: 50%;
						left: 20px;
						background: url(../img/icons/@2x/icon-tick--white@2x.png) 0 0 no-repeat;
						width: 13px;
						height: 13px;
						-webkit-background-size: auto 100%;
						background-size: auto 100%;
						margin-top: -7.5px;
						margin-left: -6.5px;
						content: "";
					}
				}
			}
		}
	}

	&.modal-case-description {
		max-width: 750px;

		.modal-header {
			background: #ff9800;
		}

		&.manager {
			.modal-body {
				@media (max-width: 768px) {
					padding-top: 40px;
				}
				.edit-block {
					display: block;

					@media (max-width: 768px) {
						top: -30px;
					}
				}
			}
		}

		.modal-body {
			.edit-block {
				display: none;
				position: absolute;
				top: 0;
				right: 20px;
				width: 160px;

				.edit-contact-link {
					position: relative;
					display: inline-block;
					text-transform: uppercase;
					font-size: 13px;
					font-weight: 700;
					color: #000;
					padding-left: 25px;
					height: 20px;

					.icon {
						left: 0;
						margin-left: 0;
						-webkit-transition: all .3s;
						-o-transition: all .3s;
						transition: all .3s;
					}
				}

				.trash-link {
					position: relative;
					display: inline-block;
					text-transform: uppercase;
					font-size: 13px;
					font-weight: 700;
					color: #000;
					margin-left: 15px;
					padding-left: 25px;
					height: 20px;

					.icon {
						left: 0;
						margin-left: 0;
						-webkit-transition: all .3s;
						-o-transition: all .3s;
						transition: all .3s;
					}
				}
			}
			.top {
				display: inline-block;

				.ks-text {
					display: block;
					font-size: 18px;
					color: #000;
					line-height: 1.2em;
					font-weight: 600;

					@media (max-width: 768px) {
						font-size: 14px;
					}

					b {
						position: relative;
						display: inline-block;
						margin: 0 10px;
						width: 14px;
						height: 11px;

						&:before {
							position: absolute;
							top: 50%;
							height: 2px;
							background: #ccc;
							content: "";
							margin-top: -1px;
							width: 14px;
						}
					}
				}
			}

			.description-form-item {
				margin-top: 30px;
				padding-top: 30px;
				border-top: 2px solid #f3f3f3;

				@media (max-width: 576px) {
					padding-top: 15px;
					margin-top: 15px;
				}

				.info-title {
					display: inline-block;
					font-size: 11px;
					font-weight: 600;
					color: #666666;
					text-transform: uppercase;
					margin: 10px 0;
					min-width: 135px;
				}

				.info-content {
					margin-bottom: 10px;
				}
			}

			.description-item-block {
				margin-top: 10px;
				padding-top: 30px;
				border-top: 2px solid #f3f3f3;

				@media (max-width: 576px) {
					padding-top: 15px;
				}

				.info-title {
					display: block;
					font-size: 11px;
					font-weight: 600;
					color: #666666;
					text-transform: uppercase;
					margin-bottom: 15px;
				}

				.description {
					color: #666;
					font-size: 13px;
				}

				.form-title {
					color: #999;
				}

				.attachment-block {
					margin-top: 40px;
				}
			}
		}
	}

	&.edit-case {
		max-width: 750px;

		.modal-header {
			background: #ff9800;
		}

		.modal-body {
			.top {
				position: relative;
				display: block;
				margin-bottom: 25px;

				.ks-text {
					display: block;
					font-size: 18px;
					color: #000;
					line-height: 1.2em;
					font-weight: 600;

					@media (max-width: 768px) {
						font-size: 14px;
					}
				}
			}

			.case-information {
				padding-bottom: 30px;
				
				.attach-btn {
					margin-top: 15px;
				}
			}

			.billing-details {
				padding-bottom: 15px;
				.top {
					padding-top: 25px;

					&:before {
						position: absolute;
						top: 0;
						left: -30px;
						right: -30px;
						content: "";
						border-top: 2px solid #f3f3f3;

						@media (max-width: 768px) {
							left: -10px;
							right: -10px;
						}
					}
				}

				.ks-nav-tabs {
					.nav-item {
						flex: 1 auto;

						&:first-child {
							.nav-link {
								border-left: 1px solid #e6e6e6;
								border-radius: 15px 0 0 15px;
							}
						}

						&:last-child {
							.nav-link {
								border-radius: 0 15px 15px 0;
							}
						}
					}

					.nav-link {
						position: relative;
						color: #000;
						padding: 12.5px 0;
						font-weight: 400;
						border: 1px solid #e6e6e6;
						border-left: 1px solid #fff;
						text-align: center;

						@media (max-width: 576px) {
							font-size: 12px;
						}

						&:hover,
						&.active {
							border: 1px solid #ff9800 !important;
							color: #ff9800;
						}
					}
				}

				.tab-content {
					margin-top: 15px;
					border: 1px solid #e6e6e6;
					border-radius: 15px;
					padding: 30px;

					@media (max-width: 576px) {
						padding: 15px;
					}

					.info-content {
						font-size: 12px;
						color: #b7b7b7;
						min-height: 40px;
					}

					.price-block {
						position: relative;

						&:before {
							position: absolute;
							top: 50%;
							left: 13px;
							content: '€';
							color: #000;
							font-size: 14px;
							margin-top: -9px;
						}

						.form-control {
							padding-left: 30px;
						}
					}

					.time-tab,
					.fixed-fee-tab {
						.no-pd-l {
							@media (max-width: 576px) {
								padding-left: 15px !important;
								padding-top: 15px;
							}
						}
					}

					.non-billable-tab {
						text-align: center;

						.non-billible-block {
							position: relative;
							padding-left: 40px;
							display: inline-block;
							color: #b7b7b7;

							&:before {
								position: absolute;
								top: 50%;
								left: 0;
								content: "";
								background: url(../img/euro.png) 0 0 no-repeat;
								width: 30px;
								height: 30px;
								background-size: auto 100%;
								opacity: 0.2;
								margin-top: -15px;
							}
						}
					}
				}
			}

			.teammates-block {
				padding-bottom: 15px;

				.top {
					padding-top: 25px;

					&:before {
						position: absolute;
						top: 0;
						left: -30px;
						right: -30px;
						content: "";
						border-top: 2px solid #f3f3f3;

						@media (max-width: 768px) {
							left: -10px;
							right: -10px;
						}
					}

					.btn {
						position: relative;
						top: -25px;
						float: right;
						width: 30px;
						height: 30px;
						padding: 0;
						background: #673ab7;
						border-radius: 100px;
						box-shadow: 0 2px 4px rgba(0,0,0,0.3);
						opacity: 1;
						transition: all .3s;

						&:before {
							position: absolute;
							top: 50%;
							left: 50%;
							background: url(../img/icons/@2x/icon-plus--white@2x.png) 0 0 no-repeat;
							width: 13px;
							height: 13px;
							background-size: 100% auto;
							margin-top: -6.5px;
							margin-left: -6.5px;
							content: "";
							transition: all .3s;
						}
					}
				}

				.attorney-row {
					@media (max-width: 768px) {
						margin: 0;
					}

					.table-responsive {
						@media (max-width: 768px) {
							width: 87vw;
							margin: 0 auto;
						}

						@media (max-width: 567px) {
							width: 75vw;
						}
					}

					.table {
						min-width: 450px;
						thead {
							th {
								font-size: 11px;
								font-weight: 400;
								color: #666666;
								text-transform: uppercase;
								border: 0;
								background: none;
								border: 0;
							}
						}

						tbody {
							td {
								vertical-align: middle;
								border: 0;

								.info-content {
									font-family: "Montserrat",sans-serif;
									font-size: 14px;
									line-height: 20px;
									color: #333;
									margin: 0;
								}

								&:last-child {
									width: 15px;
								}
							}
						}
					}

					.person-block {
						-webkit-flex: 1 1 auto;
						-ms-flex: 1 1 auto;
						flex: 1 1 auto;
						-webkit-align-self: auto;
						-ms-flex-item-align: auto;
						align-self: auto;

						@media (max-width: 576px) {
							-webkit-flex: 1 1 100%;
							-ms-flex: 1 1 100%;
							flex: 1 1 100%;
						}

						.inner {
							display: table;
							height: 45px;
							width: 100%;
							margin: 0;
							padding: 0;

							.avatar,
							.name {
								display: table-cell;
								vertical-align: middle;
							}

							.avatar {
								width: 45px;

								img {
									width: 100%;
									border-radius: 100px;
								}
							}

							.name {
								font-size: 13px;
								color: #a3a3a3;
								padding-left: 15px;

								b {
									display: block;
									font-weight: 600;
									color: #000;
								}
							}
						}
					}

					.price-block {
						display: inline-block;
						position: relative;
						width: 90px;

						&:before {
							position: absolute;
							top: 50%;
							left: 13px;
							content: '€';
							color: #000;
							font-size: 14px;
							margin-top: -9px;
						}

						.form-control {
							padding-left: 30px;
						}
					}

					.options-block {
						.delete-link {
							display: block;
							width: 15px;
							height: 15px;
							position: relative;
							opacity: 1;
							-webkit-transition: all .3s;
							-o-transition: all .3s;
							transition: all .3s;
						}
					}

					.no-teammates-block {
						border: 1px solid #e6e6e6;
						padding: 30px 0;
						text-align: center;
						border-radius: 15px;

						.empty-block {
							position: relative;
							display: inline-block;
							padding-left: 45px;
							text-align: left;

							.icon {
								left: 0;
								margin-left: 0;
								opacity: .2;
								width: 30px;
								height: 30px;
								margin-top: -15px;
							}

							.ks-text {
								display: block;
								font-size: 13px;
								color: #999;
							}

							.btn-add {
								position: absolute;
								top: 50%;
								right: -110px;
								width: 90px;
								height: 30px;
								border-radius: 10px;
								padding: 0;
								cursor: auto;
								background: #673ab7;
								color: #fff;
								text-transform: uppercase;
								font-size: 12px;
								font-weight: 600;
								margin-top: -10px;
								cursor: pointer;
								transition: all .3s;

								&:hover {
									box-shadow: 0 2px 4px rgba(0,0,0,0.3);
								}
							}
						}
					}
				}
			}
		}

		.modal-footer {
			padding-top: 15px;
			border-top: 2px solid #f2f2f2;

			.btn {
				&.add {
					position: relative;
					background: #ff9800;
					color: #fff;
					margin-left: 15px;
					padding-left: 40px;

					&:before {
						position: absolute;
						top: 50%;
						left: 20px;
						background: url(../img/icons/@2x/icon-tick--white@2x.png) 0 0 no-repeat;
						width: 13px;
						height: 13px;
						background-size: auto 100%;
						margin-top: -7.5px;
						margin-left: -6.5px;
						content: "";
					}

					&:hover,
					&:focus {
						background: #e48800;
					}
				}
			}
		}

		&.create-case {
			max-width: 870px;

			.modal-body {
				padding: 15px;
				background: #f7f7f7;

				.case-information {
					padding-bottom: 0;
				}
			}

			.card {
				margin-bottom: 15px;
				border: 0;
				box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
				border-radius: 12px;

				&.open {
					& > .card-header {
						border-radius: 12px 12px 0 0;
					}
				}

				& > .card-header {
					padding: 0;
					border-bottom: solid 1px #e6e6e6;
					border-radius: 12px;
					background: #fff;
				}
			}

			.card-header {
				& > .btn {
					display: flex;
					width: 100%;
					padding: 13.5px 22px;
					text-align: left;
					border-radius: 12px;
					background: #fff;
					align-items: center;

					@media (max-width: 575px) {
						padding: 10px 7.5px;
					}

					&:focus,
					&:hover {
						text-decoration: none;
						outline: 0;
						box-shadow: 0;
					}

					// .heading-right,
					// .heading-left {
					// 	flex: 1 0 25%;
					// 	max-width: 25%;

					// 	@media (max-width: 768px) {
					// 		flex: 1 0 50%;
					// 		max-width: 50%;
					// 	}
					// }

					.heading-left {
						position: relative;
						padding-left: 30px;
						flex: 1 100%;

						.ks-text {
							display: inline-block;
							margin: 0;
							padding: 0;
							color: #000;
							font-size: 13px;
							font-weight: 600;
							line-height: 23px;
							text-transform: uppercase;

							@media (max-width: 575px) {
								font-size: 10px;
							}
						}

						.icon {
							left: 0;
							margin-left: 0;
						}
					}

					.heading-right {
						text-align: right;
						flex: 1 75px;

						.percent-of-done {
							display: inline-block;
							width: 75px;
							height: 30px;
							border-radius: 8px;
							border: solid 1px #e6e6e6;
							font-size: 13px;
							line-height: 2em;
							font-weight: 600;
							letter-spacing: 0.3px;
							text-align: center;
							color: #999999;
							min-width: 40px;

							@media (max-width: 575px) {
								position: relative;
								width: auto;
								padding: 0 5px;
								font-size: 10px;
								height: auto;
							}
						}

						.edit-link {
							display: inline-block;
							position: relative;
							width: 60px;
							height: 30px;
							padding: 0 0 0 30px;
							border-radius: 100px;
							background: none;
							opacity: 1;
							transition: all .3s;
							margin-left: 30px;
							opacity: .7;

							@media (max-width: 575px) {
								width: 30px;
								margin-left: 0;
								top: 5px;
							}

							&:focus,
							&:hover {
								opacity: 1;
							}

							.icon {
								left: 7px;
								margin-left: 0;
							}

							.ks-text {
								line-height: 31px;
								text-transform: uppercase;
								font-size: 12px;
								color: #5b5b5b;
								display: block;
								font-weight: 600;

								@media (max-width: 575px) {
									display: none;
								}
							}
						}
					}
				}
			}

			.search_results {
				position: absolute;
	  		// display: none;
	  		top: 76px;
	  		right: 15px;
	  		left: 15px;
	  		background: #fff;
	  		padding: 5px 0;
	  		box-shadow: 0 6px 20px 0px rgba(0,0,0,0.25);
	  		border-radius: 0 0 15px 15px;
	  		z-index: 999;    		
	  	}

	  	.search_results_list {
	  		height: auto;
	  		// overflow-x: auto;

	  		.item {
	  			position: relative;
	  			display: -ms-flexbox;
	  			display: -webkit-flex;
	  			display: flex;
	  			-webkit-flex-direction: row;
	  			-ms-flex-direction: row;
	  			flex-direction: row;
	  			-webkit-flex-wrap: nowrap;
	  			-ms-flex-wrap: nowrap;
	  			flex-wrap: nowrap;
	  			-webkit-justify-content: flex-start;
	  			-ms-flex-pack: start;
	  			justify-content: flex-start;
	  			-webkit-align-content: stretch;
	  			-ms-flex-line-pack: stretch;
	  			align-content: stretch;
	  			-webkit-align-items: center;
	  			-ms-flex-align: center;
	  			align-items: center;
	  			border-top: 2px solid #f2f2f2;

	  			&:first-child {
	  				border-top: 0;
	  			}

	  			&:hover {
	  				.person-block {
	  					background: rgba(255, 152, 0, 0.03);;
	  				}
	  			}
	  		}

	  		.person-block {
	  			transition: all .3s;
	  			-webkit-flex: 1 1 auto;
	  			-ms-flex: 1 1 auto;
	  			flex: 1 1 auto;
	  			-webkit-align-self: auto;
	  			-ms-flex-item-align: auto;
	  			align-self: auto;
	  			padding: 15px;

	  			@media (max-width: 576px) {
	  				padding-top: 30px;
	  				padding-bottom: 10px;
	  			}

	  			.inner {
	  				display: table;
	  				height: 45px;
	  				width: 100%;
	  				margin: 0;
	  				padding: 0;

	  				.avatar,
	  				.name {
	  					display: table-cell;
	  					vertical-align: middle;
	  				}

	  				.avatar {
	  					width: 45px;

	  					img {
	  						width: 100%;
	  						border-radius: 100px;
	  					}
	  				}

	  				.name {
	  					font-size: 13px;
	  					color: #a3a3a3;
	  					padding-left: 15px;

	  					b {
	  						display: block;
	  						font-weight: 600;
	  						color: #000;
	  					}
	  				}
	  			}
	  		}

	  		.btn {
	  			display: block;
	  			width: 100%;
	  			background: none;
	  			padding: 11px 15px 11px 45px;
	  			text-align: left;

	  			&:hover {
	  				background: rgba(255, 152, 0, 0.03);;
	  			}

	  			.icon {
	  				margin-top: -7px;
	  				-webkit-transition: all .3s;
	  				-o-transition: all .3s;
	  				transition: all .3s;

	  				&.left {
	  					left: 15px;
	  					margin-left: 0;
	  				}

	  				&.right-arrow {
	  					position: absolute;
	  					top: 50%;
	  					right: 15px;
	  					background: url(../img/icons/@2x/angle-down@2x.png) 0 0 no-repeat;
	  					width: 9px;
	  					height: 9px;
	  					-webkit-background-size: 100% auto;
	  					background-size: 100% auto;
	  					margin-top: -4.5px;
	  					margin-left: -4.5px;
	  					border: 0;
	  					opacity: .5;
	  					-webkit-transition: all .3s;
	  					-o-transition: all .3s;
	  					transition: all .3s;
	  					content: "";
	  					-webkit-transform: rotate(-90deg);
	  					-ms-transform: rotate(-90deg);
	  					transform: rotate(-90deg);
	  				}
	  			}

	  			.ks-text {
	  				font-weight: 600;
	  				font-size: 13px;
	  			}
	  		}
	  	}

	  	.done-form-group {
	  		display: flex;
	  		justify-content: flex-end;
	  		align-items: center;
	  		margin-top: 12px;

	  		.btn {
	  			position: relative;
	  			padding: 12px 30px;
	  			text-align: center;
	  			color: #000;
	  			text-transform: uppercase;
	  			-webkit-border-radius: 15px;
	  			-moz-border-radius: 15px;
	  			border-radius: 15px;
	  			-moz-box-shadow: 0 2px 6px 0.5px rgba(0, 0, 0, 0.3);
	  			-webkit-box-shadow: 0 2px 6px 0.5px rgba(0, 0, 0, 0.3);
	  			box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
	  			-webkit-transition: all .3s;
	  			-moz-transition: all .3s;
	  			transition: all .3s;
	  			display: -ms-flexbox;
	  			display: -webkit-flex;
	  			display: flex;
	  			-webkit-flex-direction: row;
	  			-ms-flex-direction: row;
	  			flex-direction: row;
	  			-webkit-flex-wrap: nowrap;
	  			-ms-flex-wrap: nowrap;
	  			flex-wrap: nowrap;
	  			-webkit-justify-content: center;
	  			-ms-flex-pack: center;
	  			justify-content: center;
	  			-webkit-align-content: stretch;
	  			-ms-flex-line-pack: stretch;
	  			align-content: stretch;
	  			-webkit-align-items: center;
	  			-ms-flex-align: center;
	  			align-items: center;
	  			font-size: 13px;
	  			font-weight: 700;
	  			background: none;

	  			@media (max-width: 768px) {
	  				padding: 4.5px 20px;
	  			}

	  			&:hover,
	  			&:focus {
	  				-webkit-box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.2);
	  				-moz-box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.2);
	  				box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.2);
	  			}

	  			&.cancel {
	  				&:hover,
	  				&:focus {
	  					-webkit-box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.2);
	  					-moz-box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.2);
	  					box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.2);
	  				}
	  			}

	  			&.add {
	  				position: relative;
	  				color: #fff;
	  				margin-left: 15px;
	  				padding-left: 48px;

	  				&:before {
	  					position: absolute;
	  					top: 50%;
	  					left: 30px;
	  					background: url(../img/icons/@2x/icon-tick--white@2x.png) 0 0 no-repeat;
	  					width: 13px;
	  					height: 13px;
	  					-webkit-background-size: auto 100%;
	  					background-size: auto 100%;
	  					margin-top: -7.5px;
	  					margin-left: -6.5px;
	  					content: "";
	  				}
	  			}
	  		}
	  	}

	  	.case-information {
	  		.expires-group {
	  			position: relative;

	  			&:before {
	  				position: absolute;
	  				top: 50%;
	  				right: 10px;
	  				content: "";
	  				background: url(../img/icons/@2x/icon-calendar@2x.png) 0 0 no-repeat;
	  				width: 16px;
	  				height: 16px;
	  				-webkit-background-size: auto 100%;
	  				background-size: auto 100%;
	  				margin-top: -8px;
	  				opacity: .3;
	  			}

	  			.form-control {
	  				position: relative;
	  				padding-right: 40px;
	  				background: transparent;
	  			}
	  		}

	  		.set-date-time,
	  		.set-date-block {
	  			position: absolute;
	  			top: 0;
	  			left: 0;
	  			width: 520px;
	  			box-shadow: 0 6px 20px 0px rgba(0,0,0,0.25);
	  			background: #fff;
	  			z-index: 2;
	  			display: flex;
	  			flex-wrap: wrap;
	  			border-radius: 15px;
	    		// opacity: 0;
	    		// visibility: hidden;
	    		transition: all .3s;
	    		z-index: 501;

	    		@media (max-width: 768px) {
	    			width: 250px;
	    		}

	    		&.show {
	    			opacity: 1;
	    			visibility: visible;
	    		}

	    		.timepicker-block,
	    		.datepicker-block {
	    			flex: 0 0 50%;
	    			padding: 15px;

	    			@media (max-width: 768px) {
	    				flex: 0 0 100%;
	    			}
	    		}

	    		.head {
	    			position: relative;
	    			text-align: right;
	    			padding-bottom: 10px;
	    			border-bottom: 2px solid #f2f2f2;
	    			margin-bottom: 20px;
	    			font-weight: 400;
	    			font-size: 13px;

	    			.title {
	    				position: absolute;
	    				top: 50%;
	    				left: 0;
	    				color: #ccc;
	    				font-weight: 400;
	    				font-size: 13px;
	    				padding-left: 25px;
	    				margin-top: -13px;

	    				.icon {
	    					left: 0;
	    					margin-left: 0;
	    					opacity: .3;
	    				}
	    			}

	    			input {
	    				background: 0;
	    				border: 0;
	    				text-align: right;
	    				font-size: 13px;
	    				color: #666666;
	    				font-weight: 400;
	    			}
	    		}

	    		.timepicker-block {
	    			.timepicker-inner .inner-block .value:hover,
	    			.timepicker-inner .inner-block .value.active {
	    				background: #00bcd4;
	    				color: #fff;
	    			}

	    			.timepicker-inner .inner-block .value:hover {
	    				background: #e4f7fa;
	    				color: #00bcd4;
	    			}

	    			.timepicker-inner .simplebar-scrollbar {
	    				background: #00bcd4;
	    			}
	    		}

	    		.datepicker-block {
	    			.datepicker {
	    				td,
	    				th {
	    					background: none !important;
	    					&.dow {
	    						padding: 5px 0;
	    						color: #cccccc !important;
	    					}

	    					&.active {
	    						background: #00bcd4 !important;
	    						color: #fff !important;
	    					}

	    					&.today {
	    						background: #e4f7fa !important;
	    						color: #00bcd4 !important;

	    						&:hover {
	    							color: #fff !important;        
	    						}
	    					}

	    					&.day {
	    						&:hover {
	    							background: #00bcd4 !important;
	    							color: #fff;
	    						}
	    					}
	    				}
	    			}
	    		}
	    	}

	    	.set-date-block {
	    		width: 250px;
	    	}

	    	.done-form-group {
	    		.btn {
	    			&.add {
	    				background: #ff9800;
	    			}
	    		}
	    	}
	    }

	    .client-information {
	    	.done-form-group {
	    		.btn {
	    			&.add {
	    				background: #4caf50;
	    			}
	    		}
	    	}
	    }

	    .court-details {
	    	.expires-group {
	    		position: relative;

	    		&:before {
	    			position: absolute;
	    			top: 50%;
	    			right: 10px;
	    			content: "";
	    			background: url(../img/icons/@2x/icon-calendar@2x.png) 0 0 no-repeat;
	    			width: 16px;
	    			height: 16px;
	    			-webkit-background-size: auto 100%;
	    			background-size: auto 100%;
	    			margin-top: -8px;
	    			opacity: .3;
	    		}

	    		.form-control {
	    			position: relative;
	    			padding-right: 40px;
	    			background: transparent;
	    		}
	    	}

	    	.set-date-time,
	    	.set-date-block {
	    		position: absolute;
	    		top: 0;
	    		left: 0;
	    		width: 520px;
	    		box-shadow: 0 6px 20px 0px rgba(0,0,0,0.25);
	    		background: #fff;
	    		z-index: 2;
	    		display: flex;
	    		flex-wrap: wrap;
	    		border-radius: 15px;
	    		// opacity: 0;
	    		// visibility: hidden;
	    		transition: all .3s;
	    		z-index: 501;

	    		&.show {
	    			opacity: 1;
	    			visibility: visible;
	    		}

	    		.timepicker-block,
	    		.datepicker-block {
	    			flex: 0 0 50%;
	    			padding: 15px;
	    		}

	    		.head {
	    			position: relative;
	    			text-align: right;
	    			padding-bottom: 10px;
	    			border-bottom: 2px solid #f2f2f2;
	    			margin-bottom: 20px;
	    			font-weight: 400;
	    			font-size: 13px;

	    			.title {
	    				position: absolute;
	    				top: 50%;
	    				left: 0;
	    				color: #ccc;
	    				font-weight: 400;
	    				font-size: 13px;
	    				padding-left: 25px;
	    				margin-top: -13px;

	    				.icon {
	    					left: 0;
	    					margin-left: 0;
	    					opacity: .3;
	    				}
	    			}

	    			input {
	    				background: 0;
	    				border: 0;
	    				text-align: right;
	    				font-size: 13px;
	    				color: #666666;
	    				font-weight: 400;
	    			}
	    		}

	    		.timepicker-block {
	    			.timepicker-inner .inner-block .value:hover,
	    			.timepicker-inner .inner-block .value.active {
	    				background: #00bcd4;
	    				color: #fff;
	    			}

	    			.timepicker-inner .inner-block .value:hover {
	    				background: #e4f7fa;
	    				color: #00bcd4;
	    			}

	    			.timepicker-inner .simplebar-scrollbar {
	    				background: #00bcd4;
	    			}
	    		}

	    		.datepicker-block {
	    			.datepicker {
	    				td,
	    				th {
	    					background: none !important;
	    					&.dow {
	    						padding: 5px 0;
	    						color: #cccccc !important;
	    					}

	    					&.active {
	    						background: #00bcd4 !important;
	    						color: #fff !important;
	    					}

	    					&.today {
	    						background: #e4f7fa !important;
	    						color: #00bcd4 !important;

	    						&:hover {
	    							color: #fff !important;        
	    						}
	    					}

	    					&.day {
	    						&:hover {
	    							background: #00bcd4 !important;
	    							color: #fff;
	    						}
	    					}
	    				}
	    			}
	    		}
	    	}

	    	.done-form-group {
	    		.btn {
	    			&.add {
	    				background: #00bcd4;
	    			}
	    		}
	    	}

	    	.court-case-block {
	    		display: flex;

	    		.country-block {
	    			flex: 0 156px;

	    			.select2-container--default .select2-selection--single {
	    				border-radius: 15px 0 0 15px;
	    				box-shadow: none;
	    				border: solid 1px #e6e6e6;
	    			}
	    		}

	    		.search-block {
	    			position: relative;
	    			flex: 0 100%;

	    			.form-control {
	    				border-radius: 0 15px 15px 0;
	    				border-left: 0;
	    				padding-right: 30px;

	    				&:focus,
	    				&:hover {
	    					box-shadow: none;
	    				}
	    			}

	    			.icon {
	    				position: absolute;
	    				right: 10px;
	    				left: auto;
	    				opacity: .4;
	    				-webkit-transition: all .3s;
	    				-moz-transition: all .3s;
	    				-ms-transition: all .3s;
	    				-o-transition: all .3s;
	    				transition: all .3s;
	    			}
	    		}
	    	}
	    }

	    .billing-details {
	    	.ks-nav-tabs .nav-link:hover,
	    	.ks-nav-tabs .nav-link.active {
	    		border: 1px solid #009688 !important;
	    		color: #009688;
	    	}

	    	.tab-content {
	    		margin-top: 30px;
	    	}

	    	.done-form-group {
	    		.btn {
	    			&.add {
	    				background: #009688;
	    			}
	    		}
	    	}
	    }

	    .team-information {
	    	position: relative;

	    	h3 {
	    		text-transform: uppercase;
	    		font-weight: 600;
	    		color: #000;
	    		text-align: center;
	    		font-size: 16px;

	    		@media (max-width: 576px) {
	    			font-size: 15px;
	    		}
	    	}

	    	h5 {
	    		font-size: 13px;
	    		text-align: center;
	    		color: #999999;
	    	}

	    	.search-block {
	    		position: relative;
	    		width: 480px;
	    		margin: 0 auto 30px;

	    		

	    		@media (max-width: 992px) {
	    			width: 440px;
	    		}

	    		@media (max-width: 768px) {
	    			width: 100%;
	    			float: none;
	    		}

	    		.search_results {
	    			position: absolute;
			  		// display: none;
			  		top: 100%;
			  		right: 0;
			  		left: 0;
			  		background: #fff;
			  		padding: 5px 0;
			  		box-shadow: 0 6px 20px 0px rgba(0,0,0,0.25);
			  		border-radius: 0 0 15px 15px;
			  		z-index: 2;

			  	}

			  	.search_results_list {
			  		height: 200px;
			  		overflow-x: auto;
			  		margin-right: 5px;

			  		.item {
			  			position: relative;
			  			display: -ms-flexbox;
			  			display: -webkit-flex;
			  			display: flex;
			  			-webkit-flex-direction: row;
			  			-ms-flex-direction: row;
			  			flex-direction: row;
			  			-webkit-flex-wrap: nowrap;
			  			-ms-flex-wrap: nowrap;
			  			flex-wrap: nowrap;
			  			-webkit-justify-content: flex-start;
			  			-ms-flex-pack: start;
			  			justify-content: flex-start;
			  			-webkit-align-content: stretch;
			  			-ms-flex-line-pack: stretch;
			  			align-content: stretch;
			  			-webkit-align-items: center;
			  			-ms-flex-align: center;
			  			align-items: center;
			  			border-top: 2px solid #f2f2f2;

			  			&:first-child {
			  				border-top: 0;
			  			}

			  			&:hover {
			  				.person-block {
			  					background: #faf9fd;
			  				}

			  				.btn {
			  					background: #673ab7;
			  					color: #fff;
			  					box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.3);
			  				}
			  			}

			  			.btn {
			  				position: absolute;
			  				top: 50%;
			  				right: 15px;
			  				margin-top: -22.5px;

			  				padding: 12px 15px 12px 15px;
			  				text-align: center;
			  				text-transform: uppercase;
			  				border-radius: 15px;
			  				background: #fff;
			  				color: #000;
			  				transition: all .3s;
			  				display: -ms-flexbox;
			  				display: -webkit-flex;
			  				display: -webkit-box;
			  				display: flex;
			  				-webkit-flex-direction: row;
			  				-ms-flex-direction: row;
			  				-webkit-box-orient: horizontal;
			  				-webkit-box-direction: normal;
			  				flex-direction: row;
			  				-webkit-flex-wrap: nowrap;
			  				-ms-flex-wrap: nowrap;
			  				flex-wrap: nowrap;
			  				-webkit-justify-content: center;
			  				-ms-flex-pack: center;
			  				-webkit-box-pack: center;
			  				justify-content: center;
			  				-webkit-align-content: stretch;
			  				-ms-flex-line-pack: stretch;
			  				align-content: stretch;
			  				-webkit-align-items: center;
			  				-ms-flex-align: center;
			  				-webkit-box-align: center;
			  				align-items: center;
			  				font-size: 13px;
			  				font-weight: 700;
			  				width: auto;

			  				@media (max-width: 576px) {
			  					padding: 5px;
			  					font-size: 12px;
			  					top: 24px;
			  					right: 2px;
			  				}

						    // &:hover,
						    // &:focus {
						    // 	background: #673ab7;
						    // 	color: #fff;
						    // 	box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.3);
						    // }
						}
					}

					.person-block {
						transition: all .3s;
						-webkit-flex: 1 1 auto;
						-ms-flex: 1 1 auto;
						flex: 1 1 auto;
						-webkit-align-self: auto;
						-ms-flex-item-align: auto;
						align-self: auto;
						padding: 15px;

						@media (max-width: 576px) {
							padding-top: 30px;
							padding-bottom: 10px;
						}

						.inner {
							display: table;
							height: 45px;
							width: 100%;
							margin: 0;
							padding: 0;

							.avatar,
							.name {
								display: table-cell;
								vertical-align: middle;
							}

							.avatar {
								width: 45px;

								img {
									width: 100%;
									border-radius: 100px;
								}
							}

							.name {
								font-size: 13px;
								color: #a3a3a3;
								padding-left: 15px;

								b {
									display: block;
									font-weight: 600;
									color: #000;
								}
							}
						}
					}		    		
				}

				.form-control {
					height: 45px;
					background: #fff;
					border: 1px solid #e6e6e6;
					padding: 10px 15px;
					-webkit-border-radius: 15px;
					-moz-border-radius: 15px;
					border-radius: 15px;
					color: #000;
					font-size: 14px;
					font-family: "Open Sans", sans-serif;
					-webkit-transition: all .3s;
					-moz-transition: all .3s;
					-ms-transition: all .3s;
					-o-transition: all .3s;
					transition: all .3s;
				}

				.icon-addon {
					height: 43px;
					top: 1px;
					right: 1px;
				}
			}

			.list-block {
				position: relative;
				padding-top: 15px;

				&:before {
					position: absolute;
					top: -10px;
					left: -18px;
					right: -18px;
					content: "";
					border-bottom: 1px solid #e6e6e6;
				}
				h6 {
					display: block;
					font-weight: 600;
					color: #949494;
					line-height: 1em;
					font-size: 13px;
					margin-bottom: 15px;
					text-transform: uppercase;
				}

				.list-item {
					position: relative;
					background: #fff;
					border-radius: 15px;
					box-shadow: 0 1px 4px rgba(0,0,0,0.2);

					.item {
						position: relative;
						display: -ms-flexbox;
						display: -webkit-flex;
						display: flex;
						flex-direction: row;
						flex-wrap: wrap;
						justify-content: flex-start;
						align-content: stretch;
						align-items: center;
						border-top: 2px solid #f2f2f2;

						@media (max-width: 576px) {
							-webkit-flex-wrap: wrap;
							-ms-flex-wrap: wrap;
							flex-wrap: wrap;
						}

						&:first-child {
							border-top: 0;
						}
					}

					.person-block {
						-webkit-flex: 1 1 auto;
						-ms-flex: 1 1 auto;
						flex: 1 1 auto;
						-webkit-align-self: auto;
						-ms-flex-item-align: auto;
						align-self: auto;
						padding: 15px;

						@media (max-width: 576px) {
							-webkit-flex: 1 1 100%;
							-ms-flex: 1 1 100%;
							flex: 1 1 100%;
						}

						.inner {
							display: table;
							height: 45px;
							width: 100%;
							margin: 0;
							padding: 0;

							.avatar,
							.name {
								display: table-cell;
								vertical-align: middle;
							}

							.avatar {
								width: 45px;

								img {
									width: 100%;
									border-radius: 100px;
								}
							}

							.name {
								font-size: 13px;
								color: #a3a3a3;
								padding-left: 15px;

								b {
									display: block;
									font-weight: 600;
									color: #000;
								}
							}
						}
					}

					.person-info {
						-webkit-flex: 1 1 auto;
						-ms-flex: 1 1 auto;
						flex: 1 1 auto;
						-webkit-align-self: auto;
						-ms-flex-item-align: auto;
						align-self: auto;

						@media (max-width: 576px) {
							-webkit-flex: 1 1 100%;
							-ms-flex: 1 1 100%;
							flex: 1 1 100%;
							padding: 0 15px 15px;
						}

						.case-title {
							display: block;
							font-size: 13px;
							color: #a3a3a3;
						}

						.case-val {
							display: block;
							font-size: 13px;
							font-weight: 600;
							color: #000;
						}

						&>div {
							position: relative;
							margin-top: 10px;
							padding-left: 40px;
							color: #939393;
							font-size: 13px;

							&:first-child {
								margin-top: 0;
							}
						}
					}

					.remove-block {
						flex: 1 1 160px;
						-webkit-align-self: auto;
						-ms-flex-item-align: auto;
						align-self: auto;
						display: flex;
						justify-content: flex-end;
						padding-right: 15px;

						@media (max-width: 576px) {
							-webkit-flex: 1 1 100%;
							-ms-flex: 1 1 100%;
							flex: 1 1 100%;
							padding: 0 15px 15px;
						}

						&.show {
							.btn-edit {
								background: rgba(103, 58, 183, 0.05);
								color: #673ab7;
								outline: 0;
								box-shadow: none;

								.icon {
									background: url('../img/icons/@2x/icon-edit--purple@2x.png') 0 0 no-repeat;
									background-size: auto 100%;
								}
							}
						}

						.btn {
							display: inline-block;
							position: relative;
							height: 45px;
							padding: 0 15px 0 30px;
							-webkit-border-radius: 100px;
							border-radius: 100px;
							background: none;
							opacity: 1;
							transition: all .3s;
							text-transform: uppercase;
							font-size: 12px;
							color: #000;
							font-weight: 600;
							border-radius: 12px;

							&:focus,
							&:hover {
								background: rgba(103, 58, 183, 0.05);
								color: #673ab7;
								outline: 0;
								box-shadow: none;
							}

							&:after {
								display: none;
							}

							&.btn-edit {
								width: 115px;

								&:hover {
									.icon {
										background: url('../img/icons/@2x/icon-edit--purple@2x.png') 0 0 no-repeat;
										background-size: auto 100%;
									}
								}
							}
							&.btn-remove {

								&:hover {
									.icon {
										background: url('../img/icons/@2x/icon-minus--purple@2x.png') 0 0 no-repeat;
										background-size: auto 100%;
									}
								}
							}

							.icon {
								left: 7px;
								margin-left: 0;
								-webkit-transition: all .3s;
								-moz-transition: all .3s;
								-ms-transition: all .3s;
								-o-transition: all .3s;
								transition: all .3s;
							}
						}
					}

					.dropdown-menu {
						width: 630px;
						left: auto;
						right: 0;
						padding: 0;
						margin: 0;
						border: 0;
						border-radius: 8px;
						box-shadow: 0 15px 60px 0 rgba(0, 0, 0, 0.16);

						@media (max-width: 768px) {
							width: 280px;
						}

						&[x-placement="top-end"] {
							&:after {
								top: 100%;
								right: 47px;
								border: solid transparent;
								content: " ";
								height: 0;
								width: 0;
								position: absolute;
								pointer-events: none;
								border-color: rgba(255, 255, 255, 0);
								border-top-color: #fff;
								border-width: 8px;
								margin-left: -8px;
							}
						}

						&:after {
							bottom: 100%;
							right: 47px;
							border: solid transparent;
							content: " ";
							height: 0;
							width: 0;
							position: absolute;
							pointer-events: none;
							border-color: rgba(255, 255, 255, 0);
							border-bottom-color: #fff;
							border-width: 8px;
							margin-left: -8px;
						}
					}

					.person-information {
						flex: 1 1 100%;
						border-radius: 8px;
						background-color: #ffffff;
						padding: 15px 15px 0 20px;
						transition: all .3s;

						@media (max-width: 768px) {
							margin: 5px;
							padding: 5px;
						}


						.inner-block {
							margin-bottom: 15px;
							display: flex;

							@media (max-width: 768px) {
								flex-wrap: wrap;
							}

							&:nth-child(2) {
								.select2 {
									@media (max-width: 768px) {
										width: 130px !important;
									}
								}
							}

							&:last-child {
								.select2 {
									width: 100% !important;
								}
							}

							.block {
								flex: 1 50%;
								max-width: 50%;

								@media (max-width: 768px) {
									flex: 1 100%;
									max-width: 100%;
									text-align: center;
								}
							}

							.block-btn {
								flex: 0 auto;
								display: block;
								width: 100%;

								.btn {
									display: inline-block;
									position: relative;
									padding: 0px 20px;
									text-align: center;
									height: 30px;
									color: #000;
									text-transform: uppercase;
									border-radius: 15px;
									box-shadow: 0 1px 3px 0 rgba(0,0,0,0.16);
									transition: all .3s;
									font-size: 13px;
									font-weight: 700;
									background: none;

									@media (max-width: 768px) {
										padding: 0 10px;
									}

									&:hover,
									&:focus {
										box-shadow: 0 4px 14px 2px rgba(0,0,0,0.2);
									}

									&.btn-add {
										margin-left: 20px;
										background: #673ab7;
										color: #fff;
									}
								}
							}

							.title {
								display: block;
								font-size: 13px;
								color: #000;
								line-height: 1.15em;
								margin-bottom: 5px;
							}

							.description {
								display: block;
								font-size: 10px;
								color: #999;
								line-height: 1.5em;
							}

							.select2 {
								width: auto !important;

								.select2-selection--single {
									padding-right: 15px !important;
								}
							}

							.price-block {
								display: inline-block;
								position: relative;
								width: 70px;
								top: 2px;
								margin-left: 13px;

								&:before {
									position: absolute;
									top: 50%;
									left: 8px;
									content: '€';
									color: #999999;
									font-size: 14px;
									margin-top: -9px;
								}

								.form-control {
									padding-left: 25px;
									padding-right: 5px;
								}
							}
						}
					}
				}
			}
		}

		#caseInformation {
			&>.btn {
				.heading-right {
					.percent-of-done {
						&.started {
							border-color: #ff9800;
							color: #ff9800;
						}

						&.done {
							background: #ff9800;
							color: #fff;
							border: solid 1px #ff9800;
						}
					}
				}
			}
		}

		#clientInformation {
			&>.btn {
				.heading-right {
					.percent-of-done {
						&.started {
							border-color: #4caf50;
							color: #4caf50;
						}

						&.done {
							background: #4caf50;
							color: #fff;
							border: solid 1px #4caf50;
						}
					}
				}
			}
		}

		#courtDetails {
			&>.btn {
				.heading-right {
					.percent-of-done {
						&.started {
							border-color: #00bcd4;
							color: #00bcd4;
						}

						&.done {
							background: #00bcd4;
							color: #fff;
							border: solid 1px #00bcd4;
						}
					}
				}
			}
		}

		#billing {
			&>.btn {
				.heading-right {
					.percent-of-done {
						&.started {
							border-color: #009688;
							color: #009688;
						}

						&.done {
							background: #009688;
							color: #fff;
							border: solid 1px #009688;
						}
					}
				}
			}
		}

		#team {
			&>.btn {
				.heading-right {
					.percent-of-done {
						&.started {
							border-color: #673ab7;
							color: #673ab7;
						}

						&.done {
							background: #673ab7;
							color: #fff;
							border: solid 1px #673ab7;
						}
					}
				}
			}
		}
	}
}

&.edit-attorney {
	max-width: 750px;

	.modal-content {
		overflow: hidden;
	}

	.modal-header {
		background: #ff9800;
	}

	.modal-body {
		padding: 0;

		.edit-wrapper {
			padding: 15px 30px 0;
			overflow: hidden;

			@media (max-width: 576px) {
				padding-top: 40px;
			}
		}


		&.active {
			.main-edit-attorney {
				left: 0;
				height: auto;
				transition: all .3s;
			}

			.edit-task-block {
				right: -150%;
				height: 0;
				transition: all .3s;
			}

			.footer-block {
				right: -150%;
				height: 0;
				transition: all .3s;
				opacity: 0;
			}
		}

		.main-edit-attorney {
			position: relative;
			background: #fff;
			left: -150%;
			height: 0;
			transition: all .3s;
		}

		.edit-task-block {
			position: relative;
			right: 0;
			height: auto;
			background: #fff;
			transition: all .3s;

			.main-information {
				display: flex;
				flex-wrap: no-wrap;
				margin: 0 -30px;
				padding-bottom: 15px;

				.back {
					flex: 0 0 60px;

					.btn {
						position: relative;
						width: 60px;
						height: 45px;
						text-align: center;
					}
				}

				.person-information {
					flex: 1 auto;
					display: flex;
					flex-wrap: nowrap;
					align-items: center;

					.date,
					.case,
					.user {
						display: table;
						height: 45px;
						width: 100%;
						flex: 1 auto;

						.avatar {
							display: table-cell;
							vertical-align: middle;
							width: 45px;

							img {
								width: 100%;
								border-radius: 100px;
							}
						}

						.name {
							display: table-cell;
							vertical-align: middle;
							font-size: 13px;
							color: #c5c5c5;
							padding-left: 15px;

							b {
								display: block;
								font-weight: 600;
								color: #000;
							}
						}
					}
				}

				.activity-block {
					flex: 0 120px;
					display: flex;

					.time-history,
					.action-block {
						flex: 0 60px;
						border-left: solid 1px #e6e6e6;
						display: flex;
						align-items: center;
						justify-content: center;

						.btn,
						.more-options {
							position: relative;
							display: block;
							cursor: pointer;
							height: 45px;
							width: 60px;

							.icon {
								transition: all .3s;
								background-size: auto 100%;
								width: 3px;
								height: 15px;
							}
						}

						.btn {
							.icon {
								background-size: 100% auto;
								width: 16px;
								height: 15px;
							}
						}
					}
				}
			}

			.attachment-block {
				padding: 15px 30px;
				margin: 0 -30px;
				background: #f7f7f7;
				border-bottom: 1px solid  #e6e6e6;

				.form-title {
					color: #999;
				}

				.btn-file {
					flex: 1 1 30%;
					text-align: left;
					justify-content: flex-start;
					background: #fff;

					&:hover {
						.icon-right {
							background: url("../img/icons/@2x/icon-remove--green@2x.png") 0 0 no-repeat;
							background-size: 100% auto;
							opacity: 1;
						}
					}

					.icon-right {
						opacity: 0.6;
					}
				}
			}

			.comments-block {
				padding: 15px 0;

				.inner-top {
					height: 330px;

					.title {
						font-size: 10px;
						font-weight: 600;
						color: #999;
						line-height: 1.5em;
						text-transform: uppercase;
						text-align: center;
					}

					.scroll-block {
						overflow: hidden;
						overflow-y: scroll;
						height: 302px;

						&::-webkit-scrollbar-track {
							border-radius: 0;
							box-shadow: none;
							background-color: rgba(153, 153, 153, 0.2);
							border: none;
							transition: all .3s;
						}

						&::-webkit-scrollbar-thumb {
							border-radius: 0;
							background: #ccc;
							border: none;
							transition: all .3s;
						}

						&::-webkit-scrollbar
						{
							width: 3px;
							transition: all .3s;
						}

						&::-webkit-scrollbar-thumb:hover {
							background: rgba(153, 153, 153, 0.9);
							transition: all .3s;
						}
					}
				}

				.person-comment {
					margin-bottom: 30px;

					.top {
						display: table;
						height: 45px;
						width: 100%;
						margin-bottom: 15px;

						.avatar {
							display: table-cell;
							vertical-align: middle;
							width: 45px;

							img {
								width: 100%;
								border-radius: 100px;
							}
						}

						.name {
							display: table-cell;
							vertical-align: middle;
							font-size: 13px;
							color: #c5c5c5;
							padding-left: 15px;

							b {
								font-weight: 600;
								color: #000;
							}
						}

						.time-ago {
							font-size: 13px;
							color: #999999;
							padding-left: 10px;
						}
					}

					.comment-text {
						padding-left: 60px;

						p {
							font-size: 16px;
							color: #666;
							line-height: 1.8em;
							margin-bottom: 15px;
						}
					}
				}
			}


			.inner-bottom {
				padding: 30px 30px 0;
				margin: 0 -30px;
				box-shadow: 0 -20px 20px 0 rgba(0, 0, 0, 0.05);

				.write-comment {
					display: table;
					height: 45px;
					width: 100%;

					.avatar {
						display: table-cell;
						vertical-align: top;
						width: 45px;

						img {
							width: 100%;
							border-radius: 100px;
						}
					}

					.caseEditor {
						display: table-cell;
						padding-left: 15px;
					}

					.note-editor {
						margin-left: 15px;
					}
				}

				.btn {
					&.submit-btn {
						float: right;
						width: 81px;
						height: 30px;
						border-radius: 12px;
						background: #ff9800;
						box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);;
						font-size: 13px;
						font-weight: 600;
						color: #fff;
						letter-spacing: 0.3px;
						text-align: center;
						text-transform: uppercase;
						margin-top: 15px;
						-webkit-transition: all .3s;
						-moz-transition: all .3s;
						-ms-transition: all .3s;
						-o-transition: all .3s;
						transition: all .3s;
						&:hover {
							background: #e48800;
							box-shadow: 0 4px 14px 2px rgba(0,0,0,0.2);
						}
					}
				}
			}
		}


		.footer-block {
			position: absolute;
			bottom: -77px;
			height: 75px;
			left: 0;
			right: 0;
			background: #fff;
			border-radius: 0 0 15px 15px;
			z-index: 2;
		}

		.back-to-btn {
			font-weight: 700;
			color: #000;
			background: none;
			border: 0;
			width: 100%;
			padding: 27px;
			transition: all .3s;
			text-transform: uppercase;
			margin: 0;
			border-radius: 0;

			&:hover {
				box-shadow: none;
				opacity: .8;
			}

			span {
				position: relative;

				&:before {
					position: absolute;
					top: 50%;
					left: -15px;
					background: url(../img/icons/@2x/angle-left@2x.png) 0 0 no-repeat;
					width: 9px;
					height: 9px;
					-webkit-background-size: auto 100%;
					background-size: auto 100%;
					margin-top: -3.5px;
					content: "";
				}
			}
		}

		.top {
			position: relative;
			display: inline-block;

			.person-block {
				-webkit-flex: 1 1 auto;
				-ms-flex: 1 1 auto;
				flex: 1 1 auto;
				-webkit-align-self: auto;
				-ms-flex-item-align: auto;
				align-self: auto;

				@media (max-width: 576px) {
					-webkit-flex: 1 1 100%;
					-ms-flex: 1 1 100%;
					flex: 1 1 100%;
				}

				.inner {
					display: table;
					height: 45px;
					width: 100%;
					margin: 0;
					padding: 0;

					.avatar,
					.name {
						display: table-cell;
						vertical-align: middle;
					}

					.avatar {
						width: 45px;

						img {
							width: 100%;
							border-radius: 100px;
						}
					}

					.name {
						font-size: 13px;
						color: #a3a3a3;
						padding-left: 15px;

						b {
							display: block;
							font-weight: 600;
							color: #000;
						}
					}
				}
			}
		}

		.edit-block {
			display: block;
			position: absolute;
			top: 50%;
			right: 20px;
			width: 180px;
			margin-top: -10px;

			@media (max-width: 576px) {
				top: -10px;
				right: 0;
			}

			.edit-contact-link {
				position: relative;
				display: inline-block;
				text-transform: uppercase;
				font-size: 13px;
				font-weight: 700;
				color: #000;
				padding-left: 25px;
				height: 20px;

				.icon {
					left: 0;
					margin-left: 0;
					-webkit-transition: all .3s;
					-o-transition: all .3s;
					transition: all .3s;
				}
			}

			.trash-link {
				position: relative;
				display: inline-block;
				text-transform: uppercase;
				font-size: 13px;
				font-weight: 700;
				color: #000;
				margin-left: 15px;
				padding-left: 25px;
				height: 20px;

				.icon {
					left: 0;
					margin-left: 0;
					-webkit-transition: all .3s;
					-o-transition: all .3s;
					transition: all .3s;
				}
			}
		}

		.main-modal-tabs {
			justify-content: center;
			border-top: 2px solid #f3f3f3;
			border-bottom: 2px solid #f3f3f3;
			margin: 15px 0;

			@media (max-width: 576px) {
				margin: 15px -30px;
			}

			.nav-link {
				position: relative;
				color: #000;
				padding: 19px 20px;
				font-weight: 600;

				@media (max-width: 576px) {
					padding: 19px 10px;
				}

				&:hover,
				&.active {
					color: #ffa015;
				}
			}
		}

		.head-history {
			margin-bottom: 15px;

			.no-pd-l,
			.no-pd-r {
				@media (max-width: 768px) {
					padding: 0 15px !important;
				}
			}

			.custom-search-block {
				@media (max-width: 768px) {
					margin-bottom: 15px;
				}

				input {
					&.form-control {
						box-shadow: 0 1px 4px rgba(0,0,0,0.2);
					}
				}
			}

			.week-block {
				position: relative;
				height: 45px;
				box-shadow: 0 1px 4px rgba(0,0,0,0.2);
				border-radius: 15px;
				text-align: center;
				padding: 12px 0;
				font-weight: 600;
				color: #666;

				@media (max-width: 768px) {
					margin-top: 20px;
				}

				.angle-left {
					position: absolute;
					top: 1px;
					left: 1px;
					width: 35px;
					height: 45px;

					&:before {
						position: absolute;
						top: 50%;
						left: 50%;
						background: url(../img/icons/@2x/angle-down@2x.png) 0 0 no-repeat;
						width: 9px;
						height: 9px;
						-webkit-background-size: 100% auto;
						background-size: 100% auto;
						margin-top: -4.5px;
						margin-left: -4.5px;
						border: 0;
						opacity: .5;
						-webkit-transition: all .3s;
						-o-transition: all .3s;
						transition: all .3s;
						content: "";
						transform: rotate(90deg);
					}
				}

				.angle-right {
					position: absolute;
					top: 1px;
					right: 1px;
					width: 35px;
					height: 45px;

					&:before {
						position: absolute;
						top: 50%;
						left: 50%;
						background: url(../img/icons/@2x/angle-down@2x.png) 0 0 no-repeat;
						width: 9px;
						height: 9px;
						-webkit-background-size: 100% auto;
						background-size: 100% auto;
						margin-top: -4.5px;
						margin-left: -4.5px;
						border: 0;
						opacity: .5;
						-webkit-transition: all .3s;
						-o-transition: all .3s;
						transition: all .3s;
						content: "";
						transform: rotate(-90deg);
					}
				}

				.val {
					display: inline-block;
					font-style: normal;
					margin: 0 5px;
				}
			}

			.month {
				.select2-container--default .select2-selection--single .select2-selection__rendered {
					padding-left: 35px;
					position: relative;

					&:before {
						position: absolute;
						top: 50%;
						left: 10px;
						content: "";
						background: url(../img/icons/@2x/icon-calendar-month--red@2x.png) 0 0 no-repeat;
						width: 15px;
						height: 15px;
						-webkit-background-size: auto 100%;
						background-size: auto 100%;
						margin-top: -7.5px;
					}
				}
			}

			.year {
				order: 1;

				@media (max-width: 768px) {
					order: 0;
				}

				@media (max-width: 576px) {
					margin-top: 20px;
				}

				.select2-container--default .select2-selection--single .select2-selection__rendered {
					padding-left: 35px;
					position: relative;

					&:before {
						position: absolute;
						top: 50%;
						left: 10px;
						content: "";
						background: url(../img/icons/@2x/icon-calendar--red@2x.png) 0 0 no-repeat;
						width: 15px;
						height: 15px;
						-webkit-background-size: auto 100%;
						background-size: auto 100%;
						margin-top: -7.5px;
					}
				}
			}

			.select2-container--default .select2-selection--single .select2-selection__rendered {
				font-weight: 600;
				color: #666;
			}
		}

		.activity-table {
			margin: 0 -30px;

			&::-webkit-scrollbar {
				height: 10px;
				background-color: #F5F5F5;
			}

			@media (max-width: 768px) {
				width: 97vw;
				overflow: hidden;
				overflow-x: auto;
			}

			@media (max-width: 576px) {
				width: 90vw;
			}

			.table {
				min-width: 650px;
			}

			thead {
				th {
					vertical-align: middle;
					background: #f7f7f7;
					border-bottom: 0;
					color: #666666;

					&:last-child {
						padding-right: 30px;
					}
					&:first-child {
						padding-left: 30px;
					}

					.th-title {
						position: relative;
						font-size: 11px;
						text-transform: uppercase;
						line-height: 39px;

						&.sortable {
							background-image: url(../img/both.png);
							cursor: pointer;
							background-position: right;
							background-repeat: no-repeat;

							&.sorted {
								color: #000;

								&.sorted_desc {
									background-image: url(../img/desc.png);
									background-position: right 7px;
									background-repeat: no-repeat;
								}

								&.sorted_asc {
									background-image: url(../img/asc.png);
									background-position: right 0px;
									background-repeat: no-repeat;
								}
							}
						}
					}
				}

				tr.search-row {
					th {
						padding: 0;
						border-bottom: 2px solid #ccc;
					}
				}
			}

			tbody {
				tr:hover,
				tr.row-checked {
					td {
						background: #f7fff8;
					}
				}

				tr:first-child {
					td {
						border-top: 0;
					}
				}

				td {
					vertical-align: middle;
					border-bottom: 0;

					span {
						display: block;
						color: #a3a3a3;
						font-size: 13px;
						line-height: 1.5em;
						word-wrap: break-word;

						&.black {
							color: #000;
						}

						&.bold {
							font-weight: 600;
						}
					}

					&:last-child {
						width: 100px;
						padding-right: 30px;
					}

					&:first-child {
						padding-left: 30px;
						width: 170px;
					}

					.link-activity {
						display: block;
						padding: 4px 0;
						text-align: center;
						color: #000;
						border: 1px solid #000;
						border-radius: 15px;

						&:hover,
						&:focus {
							background: #000;
							color: #fff;
						}

						&.deadline {
							color: #ff5722;
							border-color: #ff5722;

							&:hover {
								background: #ff5722;
								color: #fff;
							}
						}
					}
				}
			}

			.text-center {
				text-align: center;
			}

			.search-row {
				border-bottom: 1px solid #dee2e6;

				&:hover {
					th {
						background: transparent;
					}
				}
				th {
					position: relative;
					padding: 0;
					vertical-align: middle;
					height: 44px;
					background: #fff;

					&:first-child {
						padding-left: 15px;
						&:before {
							display: none;
						}
					}

					&:before {
						position: absolute;
						top: 50%;
						left: 0;
						bottom: 0;
						height: 15px;
						content: "";
						width: 2px;
						background: #f2f2f2;
						z-index: 3;
						margin-top: -7.5px;
					}
				}

			}
		}

		.head-overview {
			padding-bottom: 15px;
			border-bottom: 2px solid #f3f3f3;
			margin-bottom: 30px;

			@media (max-width: 576px) {
				margin: 0 -30px 30px;
			}

			.form-title {
				font-size: 11px;
				text-transform: uppercase;
				font-weight: 600;
				margin-bottom: 15px;

				@media (max-width: 576px) {
					font-size: 9px;
					line-height: 1em;
				}
			}

			.val {
				display: inline-block;
				font-size: 25px;
				color: #000;

				@media (max-width: 576px) {
					font-size: 20px;
					line-height: 1em;
				}
			}

			.abr {
				display: inline-block;
				font-size: 23px;
				color: #000;

				@media (max-width: 576px) {
					display: block;
					font-size: 16px;
					line-height: 1em;
				}
			}

			.semi-title {
				font-style: normal;
				font-size: 11px;
				color: #a8a8a8;
				display: block;
				margin-top: 5px;

				@media (max-width: 576px) {
					font-size: 9px;
					line-height: 1em;
				}

				b {
					font-weight: normal;
				}
			}
		}

		.content-history {
			.ks-nav-tabs {
				flex-wrap: nowrap;
				border-right: 2px solid #f2f2f2;
				border-bottom: 2px solid #f2f2f2;
				margin-right: 70px;
				margin-bottom: 15px;

				@media (max-width: 768px) {
					overflow: hidden;
					overflow-x: auto;
				}

				&::-webkit-scrollbar {
					height: 10px;
					background-color: #F5F5F5;
				}



				& > li {
					flex: 1 100%;
				}

				.nav-link {
					position: relative;
					color: #ccc;
					padding: 15px 25px;
					font-weight: 600;
					text-align: center;

					&:before {
						position: absolute;
						bottom: 0;
						left: 0;
						right: 0;
						content: "";
						border-bottom: 4px solid #ff9800;
						opacity: 0;
						transition: all .3s;
					}

					&.active {
						color: #000;

						&:before {
							opacity: 1;
						}
					}

					.time {
						display: block;
						font-weight: 400;
						font-size: 13px;
						margin-top: 5px;
					}
				}
			}

			.total-block {
				position: absolute;
				top: 0;
				right: 0;
				color: #666;
				padding: 15px 25px;
				font-weight: 600;
				text-align: center;
				border-bottom: 2px solid #f2f2f2;

				.time {
					display: block;
					font-weight: 400;
					font-size: 13px;
					margin-top: 5px;
				}
			}

			.table {
				min-width: 450px;
				table-layout: fixed;

				.table {
					tbody {
						td {
							vertical-align: top;

						}
					}
				}

				thead {
					th {
						font-size: 11px;
						font-weight: 400;
						color: #666666;
						text-transform: uppercase;
						border: 0;
						background: none;
						border: 0;
					}
				}

				tbody {
					td {
						vertical-align: middle;
						border: 0;

						&.hiddenRow {
							padding: 0;
						}

						.info-content {
							font-family: "Montserrat",sans-serif;
							font-size: 14px;
							line-height: 20px;
							color: #333;
							margin: 0;
						}

						span {
							display: block;
							color: #a3a3a3;
							font-size: 13px;
							line-height: 1.5em;
							word-wrap: break-word;

							&.black {
								color: #000;
							}

							&.bold {
								font-weight: 600;
							}
						}

						&.accordion-toggle {
							.status-block {
								.toggle-link {
									display: block;
									position: relative;
									min-width: 105px;

									.ks-text {
										color: #ff5722;
										font-weight: 600;
									}

									.icon {
										top: 0;
										left: auto;
										margin-left: 0;
										right: 0;
										width: 30px;
										height: 30px;
										background-size: 6.5px;
										background-position: 50% 50%;
										border-radius: 15px;
										opacity: .3;
										border: 1px solid #999;
										-webkit-transition: all .3s;
										-moz-transition: all .3s;
										-ms-transition: all .3s;
										-o-transition: all .3s;
										transition: all .3s;
										-webkit-transform: rotate(90deg);
										-moz-transform: rotate(90deg);
										-ms-transform: rotate(90deg);
										-o-transform: rotate(90deg);
										transform: rotate(90deg);
									}
								}
							}
							&.collapsed {
								.status-block {
									.toggle-link {
										display: block;
										position: relative;

										.ks-text {
											color: #ff5722;
											font-weight: 600;
										}

										.icon {
											top: 0;
											left: auto;
											margin-left: 0;
											right: 0;
											width: 30px;
											height: 30px;
											background-size: 6.5px;
											background-position: 50% 50%;
											border-radius: 15px;
											opacity: .3;
											border: 1px solid #999;
											-webkit-transition: all .3s;
											-moz-transition: all .3s;
											-ms-transition: all .3s;
											-o-transition: all .3s;
											transition: all .3s;
											-webkit-transform: rotate(-90deg);
											-moz-transform: rotate(-90deg);
											-ms-transform: rotate(-90deg);
											-o-transform: rotate(-90deg);
											transform: rotate(-90deg);
										}
									}
								}
							}
						}
					}
				}
			}

			.person-block {
				-webkit-flex: 1 1 auto;
				-ms-flex: 1 1 auto;
				flex: 1 1 auto;
				-webkit-align-self: auto;
				-ms-flex-item-align: auto;
				align-self: auto;

				@media (max-width: 576px) {
					-webkit-flex: 1 1 100%;
					-ms-flex: 1 1 100%;
					flex: 1 1 100%;
				}

				.inner {
					display: table;
					height: 45px;
					width: 100%;
					margin: 0;
					padding: 0;

					.avatar,
					.name {
						display: table-cell;
						vertical-align: middle;
					}

					.avatar {
						width: 45px;

						img {
							width: 100%;
							border-radius: 100px;
						}
					}

					.name {
						font-size: 13px;
						color: #a3a3a3;
						padding-left: 15px;

						b {
							display: block;
							font-weight: 600;
							color: #000;
						}
					}
				}
			}

			.price-block {
				display: inline-block;
				position: relative;
				width: 90px;

				&:before {
					position: absolute;
					top: 50%;
					left: 13px;
					content: '€';
					color: #000;
					font-size: 14px;
					margin-top: -9px;
				}

				.form-control {
					padding-left: 30px;
				}
			}

			.add-new-rate {
				position: relative;
				right: 0;
				width: 100%;
				background: #fff;
				padding: 12px 10px 12px 30px;
				text-align: center;
				color: #333;
				text-transform: uppercase;
				-webkit-border-radius: 15px;
				-moz-border-radius: 15px;
				border-radius: 15px;
				-moz-box-shadow: 0 2px 6px 0.5px rgba(0, 0, 0, 0.2);
				-webkit-box-shadow: 0 2px 6px 0.5px rgba(0, 0, 0, 0.2);
				box-shadow: 0 2px 6px 0.5px rgba(0, 0, 0, 0.2);
				-webkit-transition: all .3s;
				-moz-transition: all .3s;
				transition: all .3s;
				display: -ms-flexbox;
				display: -webkit-flex;
				display: flex;
				-webkit-flex-direction: row;
				-ms-flex-direction: row;
				flex-direction: row;
				-webkit-flex-wrap: nowrap;
				-ms-flex-wrap: nowrap;
				flex-wrap: nowrap;
				-webkit-justify-content: center;
				-ms-flex-pack: center;
				justify-content: center;
				-webkit-align-content: stretch;
				-ms-flex-line-pack: stretch;
				align-content: stretch;
				-webkit-align-items: center;
				-ms-flex-align: center;
				align-items: center;
				font-size: 13px;
				font-weight: 700;

				@media (max-width: 576px) {
					margin-bottom: 10px;
				}

				.icon {
					left: 15px;
					margin-left: 0;
					margin-top: -7px;
					-webkit-transition: all .3s;
					-moz-transition: all .3s;
					-ms-transition: all .3s;
					-o-transition: all .3s;
					transition: all .3s;
				}

				.ks-text {
					opacity: 1;
					visibility: visible;
					min-width: 110px;
				}

				&:hover {
					-webkit-box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.1);
					-moz-box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.1);
					box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.1);
					color: #4caf50;

					.icon {
						background: url(../img/icons/@2x/icon-plus--green@2x.png) 0 0 no-repeat;
						-webkit-background-size: 100% auto;
						-moz-background-size: 100% auto;
						background-size: 100% auto;
					}
				}
			}
		}

		.overview-tab {
			padding-bottom: 30px;
		}

		.tasks-tab {
			.activity-table {
				.table {
					table-layout: fixed;
				}

				.link-to-task {
					display: block;
					font-size: 13px;
					color: #000;
					line-height: 1.54em;
				}
			}

			.btn-new-task {
				position: absolute;
				left: 0;
				bottom: -64px;
				width: 175px;
				background: #fff;
				padding: 12px 10px 12px 20px;
				text-align: center;
				color: #333;
				text-transform: uppercase;
				-webkit-border-radius: 15px;
				-moz-border-radius: 15px;
				border-radius: 15px;
				-moz-box-shadow: 0 2px 6px 0.5px rgba(0, 0, 0, 0.2);
				-webkit-box-shadow: 0 2px 6px 0.5px rgba(0, 0, 0, 0.2);
				box-shadow: 0 2px 6px 0.5px rgba(0, 0, 0, 0.2);
				-webkit-transition: all .3s;
				-moz-transition: all .3s;
				transition: all .3s;
				display: -ms-flexbox;
				display: -webkit-flex;
				display: flex;
				-webkit-flex-direction: row;
				-ms-flex-direction: row;
				flex-direction: row;
				-webkit-flex-wrap: nowrap;
				-ms-flex-wrap: nowrap;
				flex-wrap: nowrap;
				-webkit-justify-content: center;
				-ms-flex-pack: center;
				justify-content: center;
				-webkit-align-content: stretch;
				-ms-flex-line-pack: stretch;
				align-content: stretch;
				-webkit-align-items: center;
				-ms-flex-align: center;
				align-items: center;
				font-size: 13px;
				font-weight: 700;

				@media (max-width: 768px) {
					margin-bottom: 10px;
				}

				.icon {
					left: 15px;
					margin-left: 0;
					margin-top: -7px;
					-moz-transition: all 0.3s;
					-o-transition: all 0.3s;
					-webkit-transition: all 0.3s;
					transition: all 0.3s;

					&.icon-plus {
						background: url(../img/icons/@2x/icon-add@2x.png) 0 0 no-repeat;
						-webkit-background-size: 100% auto;
						-moz-background-size: 100% auto;
						background-size: 100% auto;
					}
				}

				.ks-info {
					opacity: 1;
					visibility: visible;
					min-width: 110px;
				}

				&:hover {
					-webkit-box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.1);
					-moz-box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.1);
					box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.1);
					color: #4caf50;

					.icon {
						background: url(../img/icons/@2x/icon-add--green@2x.png) 0 0 no-repeat;
						-webkit-background-size: 100% auto;
						-moz-background-size: 100% auto;
						background-size: 100% auto;
					}
				}
			}
		}

		.files-tab {
			.table {
				margin: 0;
  				// overflow-x: scroll;

  				&.table-tbody {}

  				thead {
  					th {
  						vertical-align: middle;
  						background: #f7f7f7;
  						border-bottom: 0;
  						color: #666666;

  						.th-title {
  							position: relative;
  							font-size: 13px;

  							&.sortable {
  								background-image: url(../img/both.png);
  								cursor: pointer;
  								background-position: right;
  								background-repeat: no-repeat;

  								&.sorted {
  									color: #000;

  									&.sorted_desc {
  										background-image: url(../img/desc.png);
  										background-position: right 7px;
  										background-repeat: no-repeat;
  									}

  									&.sorted_asc {
  										background-image: url(../img/asc.png);
  										background-position: right 0px;
  										background-repeat: no-repeat;
  									}
  								}
  							}
  						}
  					}

  					tr.search-row {
  						th {
  							padding: 0;
  							// border-bottom: 2px solid #e6e6e6;
  						}
  					}
  				}

  				tbody {
  					tr:hover,
  					tr.row-checked {
  						td {
  							background: #fffaf2;
  						}
  					}

  					tr:first-child {
  						td {
  							border-top: 0;
  						}
  					}

  					td {
  						vertical-align: middle;
  						border-bottom: 0;
  						height: 55px;

  						span {
  							display: block;
  							color: #a3a3a3;
  							font-size: 13px;
  							line-height: 34px;
  							word-wrap: break-word;
  						}
  					}
  				}

  				// .checkbox-cell {
  				// 	min-width: 50px;
  				// 	max-width: 50px;
  				// 	width: 50px;
  				// }

  				// .boniteta-cell {
  				// 	max-width: 120px;
  				// 	width: 100px;
  				// 	min-width: 100px;
  				// }

  				// .email-cell,
  				// .phone-cell {
  				// 	// @media (max-width: 1439px) {
	  			// 		// width: 180px;
	  			// 		max-width: 250px;
	  			// 		min-width: 180px;
  				// 	// }
  				// }

  				// .name-cell {
  				// 	min-width: 160px;
  				// 	max-width: 200px;
  				// 	width: 160px;
  				// }

  				// .address-cell {
  				// 	max-width: 220px;
  				// 	min-width: 170px;
  				// }

  				// .area-cell {
  				// 	width: 100px;
  				// }

  				.checkbox-cell {
  					.custom-checkbox {
  						margin-top: 8px;
  					}
  				}

  				.text-center {
  					text-align: center;
  				}

  				.search-row {
  					border-bottom: 2px solid #e6e6e6;

  					&:hover {
  						th {
  							background: transparent;
  						}
  					}
  					th {
  						position: relative;
  						padding: 0;
  						vertical-align: middle;
  						height: 45px;
  						background: #fff;

  						&:first-child {
  							&:before {
  								display: none;
  							}
  						}

  						&:before {
  							position: absolute;
  							top: 50%;
  							left: 0;
  							bottom: 0;
  							height: 15px;
  							content: "";
  							width: 2px;
  							background: #f2f2f2;
  							z-index: 3;
  							margin-top: -7.5px;
  						}
  					}

  					td {
  						position: relative;
  						padding: 0;
  						vertical-align: middle;
  						height: 45px;
  						background: #fff;
  					}

  					.action-block {
  						position: relative;
  						height: 44px;
  						
  						&.tippy-active {

  							.dropdown-toggle {
  								position: relative;
  								top: -1px;
  								background: #4caf50;
  								color: #fff;
  								opacity: 1;
  								height: 46px;
  								-webkit-border-radius: 15px;
  								-moz-border-radius: 15px;
  								border-radius: 15px;
  								padding: 13px 15px;

  								&:after {
  									background: url(../img/icons/@2x/angle-down--white@2x.png) 0 0 no-repeat;
  									-webkit-background-size: 100% auto;
  									-moz-background-size: 100% auto;
  									background-size: 100% auto;
  									opacity: 1;
  								}

  								span {
  									color: #fff;
  								}
  							}
  						}

  						.dropdown-toggle {
  							background: transparent;
  							padding: 12px 15px;
  							opacity: 0.7;
  							cursor: pointer;
  							-webkit-border-radius: 0;
  							-moz-border-radius: 0;
  							border-radius: 0;
  							-webkit-transition: all .3s;
  							-moz-transition: all .3s;
  							-ms-transition: all .3s;
  							-o-transition: all .3s;
  							transition: all .3s;

  							.ks-info {
  								font-weight: 600;
  							}

  							span {
  								color: #333;
  							}

  							&:after {
  								background: url(../img/icons/@2x/angle-down@2x.png) 0 0 no-repeat;
  								-webkit-background-size: 100% auto;
  								-moz-background-size: 100% auto;
  								background-size: 100% auto;
  								position: absolute;
  								right: 15px;
  								top: 50%;
  								margin-top: -3px;
  								opacity: 0.6;
  							}
  						}
  					}
  				}

  				.cell-td {
  					display: -ms-flexbox;
  					display: -webkit-flex;
  					display: flex;
  					-webkit-flex-direction: row;
  					-ms-flex-direction: row;
  					flex-direction: row;
  					-webkit-flex-wrap: nowrap;
  					-ms-flex-wrap: nowrap;
  					flex-wrap: nowrap;
  					-webkit-justify-content: flex-start;
  					-ms-flex-pack: start;
  					justify-content: flex-start;
  					-webkit-align-content: stretch;
  					-ms-flex-line-pack: stretch;
  					align-content: stretch;
  					-webkit-align-items: center;
  					-ms-flex-align: center;
  					align-items: center;

  					&.avatar-block {
  						-webkit-justify-content: flex-end;
  						-ms-flex-pack: end;
  						justify-content: flex-end;
  						.avatar {
  							width: 40px;
  							height: 40px;
  							-webkit-border-radius: 100px;
  							-moz-border-radius: 100px;
  							border-radius: 100px;
  							margin-left: 15px;

  							@media (max-width: 1340px) {
  								margin-left: 5px;
  							}
  						}
  					}

  					&.name-block {
  						.corp {
  							display: block;
  							color: #000;
  							margin-bottom: 5px;
  							line-height: 1em;
  							font-weight: 600;
  						}
  					}
  				}

  				.email-block,
  				.phone-block {
  					display: inline-block;
  					position: relative;
  					padding: 5px 35px 5px 30px;
  					cursor: pointer;
  					background: transparent;
  					color: #333;
  					margin-bottom: 2px;
  					-webkit-border-radius: 0;
  					-moz-border-radius: 0;
  					border-radius: 0;
  					-webkit-transition: all .3s;
  					-moz-transition: all .3s;
  					-ms-transition: all .3s;
  					-o-transition: all .3s;
  					transition: all .3s;
  					-webkit-border-radius: 15px;
  					-moz-border-radius: 15px;
  					border-radius: 15px;

  					&.tippy-active,
  					&:hover {
  						background: #4caf50;

  						span {
  							color: #fff;
  						}

  						.icon {
  							&.icon-phone {
  								background: url('../img/icons/@2x/icon-phone--white@2x.png') 0 0 no-repeat;
  								-webkit-background-size: 100% auto;
  								-moz-background-size: 100% auto;
  								background-size: 100% auto;
  							}

  							&.icon-mobile {
  								background: url('../img/icons/@2x/icon-mobile--white@2x.png') 0 0 no-repeat;
  								-webkit-background-size: auto 100%;
  								-moz-background-size: auto 100%;
  								background-size: auto 100%;
  							}
  						}
  					}

  					&.open-popover {
  						&:before {
  							position: absolute;
  							top: 50%;
  							right: 10px;
  							border: 0;
  							background: url(../img/icons/@2x/angle-down--white@2x.png) 0 0 no-repeat;
  							-webkit-background-size: 100% auto;
  							-moz-background-size: 100% auto;
  							background-size: 100% auto;
  							width: 9px;
  							height: 6px;
  							content: "";
  							margin-top: -3px;
  							-moz-transition: all 0.3s;
  							-o-transition: all 0.3s;
  							-webkit-transition: all 0.3s;
  							transition: all 0.3s;
  						}
  					}

  					span {
  						-webkit-transition: all .3s;
  						-moz-transition: all .3s;
  						-ms-transition: all .3s;
  						-o-transition: all .3s;
  						transition: all .3s;
  						color: #333;
  						word-break: break-all;
  					}

  					.popover-content {
  						display: none;
  					}

  					.icon {
  						left: 10px;
  						margin-left: 0;
  					}
  				}

  				.email-block {
  					padding: 5px 35px 5px 10px;
  				}

  				.area-cell {
  					text-align: center;;

  					.area {
  						color: #333;
  					}

  					.open-popover {
  						height: 21px;
  						.popover-content {
  							display: none;
  						}
  					}

  					.text {
  						color: #333;
  						cursor: pointer;
  					}
  				}

  				.options-cell {
  					.open-popover-link {
  						position: relative;
  						width: 34px;
  						height: 31px;
  						-webkit-flex: 1 100%;
  						-ms-flex: 1 100%;
  						flex: 1 100%;

  						&.tippy-active {
  							.more-options {
  								.icon {
  									background: url("../img/icons/@2x/icon-more--green@2x.png") 0 0 no-repeat;
  									-webkit-background-size: auto 100%;
  									-moz-background-size: auto 100%;
  									background-size: auto 100%;
  								}
  							}
  						}

  						.open-popover {
  							width: 34px;
  							height: 31px;
  							float: right;

  							.popover-content {
  								display: none;
  							}
  						}

  						.more-options {
  							position: relative;
  							display: block;
  							height: 31px;
  							cursor: pointer;
  							width: 34px;
  							float: right;

  							.icon {
  								-webkit-transition: all .3s;
  								-moz-transition: all .3s;
  								-ms-transition: all .3s;
  								-o-transition: all .3s;
  								transition: all .3s;
  							}

  							&:hover {
  								.icon {
  									background: url("../img/icons/@2x/icon-more--green@2x.png") 0 0 no-repeat;
  									-webkit-background-size: auto 100%;
  									-moz-background-size: auto 100%;
  									background-size: auto 100%;
  								}
  							}
  						}
  					}
  					.btn {
  						-webkit-flex: 0 1 auto;
  						-ms-flex: 0 1 auto;
  						flex: 0 1 auto;
  						-webkit-border-radius: 15px;
  						-moz-border-radius: 15px;
  						border-radius: 15px;
  						font-weight: 600;
  						font-size: 13px;
  						background: none;
  						color: #333;
  						border: 1px solid transparent;
  						-webkit-transition: all .3s;
  						-moz-transition: all .3s;
  						-ms-transition: all .3s;
  						-o-transition: all .3s;
  						transition: all .3s;

  						&:hover {
  							background: #28a745;
  							color: #fff;
  						}
  					}
  				}
  			}

  			.custom-checkbox>.custom-control-input:checked ~ .custom-control-indicator,
  			.custom-checkbox:hover>.custom-control-input:not(:disabled) ~ .custom-control-indicator {
  				border-color: #ff9800;
  			}

  			.custom-checkbox>.custom-control-input ~ .custom-control-indicator:before {
  				background: url("../img/icons/@2x/checkbox-checked--orange@2x.png") 50% 50% no-repeat;
  				background-size: 80% auto;
  			}

  			.custom-scroll-table {
  				margin: 0 -30px;

  				&::-webkit-scrollbar-track {
  					border-radius: 0;
  					box-shadow: none;
  					background-color: rgba(153, 153, 153, 0.2);
  					border: none;
  					transition: all .3s;
  				}

  				&::-webkit-scrollbar-thumb {
  					border-radius: 0;
  					background: #ccc;
  					border: none;
  					transition: all .3s;
  				}

  				&::-webkit-scrollbar {
  					height: 6px;
  					transition: all .3s;
  				}

  				&::-webkit-scrollbar-thumb:hover {
  					background: rgba(153, 153, 153, 0.9);
  					transition: all .3s;
  				}

  				@media (max-width: 768px) {
  					overflow: hidden;
  					overflow-x: auto
  				}

  				.table {

  					@media (max-width: 768px) {
  						min-width: 750px;
  					}

  					td,
  					th {
  						border: 0 none;
  					}

  					.checkbox-cell {
  						width: 60px !important;
  						max-width: 60px !important;
  						min-width: 60px !important;
  					}

  					.action-block {
  						&.tippy-active {
  							.dropdown-toggle {
  								.icon {
  									background: url(../img/icons/@2x/icon-menu--white@2x.png) 0 0 no-repeat;
  									background-size: auto 100%;
  								}
  							}
  						}
  						.dropdown-toggle {
  							height: 44px;

  							.ks-info {
  								display: block;
  								min-height: 20px;
  								opacity: .7;
  							}
  							.icon {
  								transition: all .3s;
  							}
  							&:after {
  								display: none;
  							}
  						}
  					}

  					th,
  					td {
  						&:last-child {
  							min-width: 125px;
  						}
  					}

  					.name-cell {
  						position: relative;
  						max-width: auto;
  						width: auto;
  						min-width: auto;

  						.ks-open-details {
  							display: block;
  							padding-left: 30px;

  							&.active {
  								.case-name {
  									color: #4caf50;
  								}
  							}
  						}

  						.icon {
  							left: 0;
  							margin-left: 0;
  						}
  					}

  					.case-name-cell {
  						min-width: 160px;
  					}

  					.owner-cell {
  						min-width: 180px;
  						padding-left: 0;
  						padding-right: 0;
  					}

  					.size-cell {
  						min-width: 70px;
  					}
  				}
  			}
  		}
  	}

  	.modal-footer {
  		padding-top: 15px;
  		border-top: 2px solid #f2f2f2;
  		padding-bottom: 15px;

  		.btn {
  			&.add {
  				position: relative;
  				background: #ff9800;
  				color: #fff;
  				margin-left: 15px;
  				padding-left: 40px;

  				&:before {
  					position: absolute;
  					top: 50%;
  					left: 20px;
  					background: url(../img/icons/@2x/icon-tick--white@2x.png) 0 0 no-repeat;
  					width: 13px;
  					height: 13px;
  					background-size: auto 100%;
  					margin-top: -7.5px;
  					margin-left: -6.5px;
  					content: "";
  				}

  				&:hover,
  				&:focus {
  					background: #e48800;
  				}
  			}
  		}
  	}
  }

  &.import-contacts {
  	max-width: 450px;

  	.modal-header {
  		.ks-text {
  			@media (max-width: 768px) {
  				font-size: 13px;
  				line-height: 20px;
  			}
  		}
  	}

  	.modal-body {
  		.buttons-group {

  			.btn {
  				position: relative;
  				padding: 20px 13px 20px 60px;
  				text-align: left;
  				color: #000;
  				width: 100%;
  				border-radius: 12px;
  				box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  				-webkit-transition: all .3s;
  				-o-transition: all .3s;
  				transition: all .3s;
  				font-size: 12px;
  				font-weight: 600;
  				background: #fff;
  				margin-top: 15px;
  				white-space: inherit;

  				&:first-child {
  					margin-top: 0;
  				}

  				&:hover {
  					box-shadow: 0 4px 14px 2px rgba(0,0,0,0.2);
  				}

  				.icon {
  					left: 30px;

  					&.icon-right {
  						left: auto;
  						margin-left: 0;
  						right: 23px;
  					}
  				}
  			}
  		}
  	}

  	.modal-footer {
  		padding: 0;
  		border: 0;

  		.btn {
  			flex: 1 1 auto;
  			font-weight: 700;
  			color: #000;
  			background: none;
  			border: 0;
  			padding: 12px;
  			border-top: 2px solid #eef1f2;
  			border-left: 2px solid #eef1f2;
  			transition: all .3s;
  			text-transform: uppercase;
  			margin: 0;
  			border-radius: 0;

  			&:hover {
  				box-shadow: none;
  			}

  			&.ok {
  				&:hover {
  					color: #4caf50;
  				}
  			}

  			&.cancel {
  				&:hover {
  					color: rgb(255, 152, 0);
  				}
  			}

  			&.delete {
  				&:hover {
  					color: rgb(255, 87, 34);
  				}
  			}

  			&:first-child {
  				border-left: 0;
  			}
  		}
  	}
  }

  &.add-person {
  	max-width: 750px;

  	.modal-body {
  		padding: 60px;
  		display: block;
  		overflow: hidden;

  		@media (max-width: 768px) {
  			padding: 30px 15px;
  		}

  		.row {
  			position: relative;
  			height: auto;
  			right: 0;
  			transition: all .3s;

  			&.first {
  				right: auto;
  				left: 0;

  				&.hide {
  					left: -150%;
  					right: auto;
  				}
  			}
  		}

  		.hide {
  			position: relative;
  			right: -150%;
  			height: 0;
  			-webkit-transition: all .3s;
  			-o-transition: all .3s;
  			transition: all .3s;
  		}

  		h4 {
  			font-weight: 600;
  			margin: 0 0 30px;
  			padding: 0;
  		}
  	}

  	.modal-footer {
  		display: block;
  		padding-top: 15px;
  		border-top: 2px solid #f2f2f2;

  		.btn {
  			box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.2);
  			float: left;
  			padding: 12px 30px;

  			@media (max-width: 768px) {
  				padding: 12px 5px;
  			}

  			&:hover {
  				box-shadow: 0 4px 14px 2px rgba(0,0,0,0.2);
  			}

  			&[disabled="disabled"] {
  				display: none;
  			}

  			&.back {
  				float: right;
  				padding-left: 36px;

  				@media (max-width: 768px) {
  					padding-left: 25px;
  				}

  				&:before {
  					position: absolute;
  					top: 50%;
  					left: 15px;
  					background: url('../img/icons/@2x/angle-left@2x.png') 0 0 no-repeat;
  					width: 9px;
  					height: 9px;
  					-webkit-background-size: auto 100%;
  					-moz-background-size: auto 100%;
  					background-size: auto 100%;
  					margin-top: -4.5px;
  					opacity: .5;
  					content: "";

  					@media (max-width: 768px) {
  						left: 5px;
  					}
  				}

  				&:hover {
  					box-shadow: 0 4px 14px 2px rgba(0,0,0,0.2);
  				}
  			}

  			&.add {
  				position: relative;
  				padding-right: 40px;
  				padding-left: 30px;
  				float: right;
  				margin-left: 15px;

  				@media (max-width: 768px) {
  					padding-left: 10px;
  					padding-right: 25px;
  				}

  				&:before {
  					position: absolute;
  					top: 50%;
  					right: 15px;
  					background: url('../img/icons/@2x/angle-right--white@2x.png') 0 0 no-repeat;
  					width: 9px;
  					height: 9px;
  					content: "";
  					-webkit-background-size: auto 100%;
  					-moz-background-size: auto 100%;
  					background-size: auto 100%;
  					margin-top: -4.5px;

  					@media (max-width: 768px) {
  						right: 5px;
  					}

  				}

  				&[data-step="complete"] {
  					padding-left: 40px;
  					padding-right: 15px;

  					@media (max-width: 768px) {
  						padding-right: 5px;
  						padding-left: 25px;
  					}

  					&:before {
  						position: absolute;
  						top: 50%;
  						left: 20px;
  						background: url(../img/icons/@2x/icon-tick--white@2x.png) 0 0 no-repeat;
  						width: 13px;
  						height: 13px;
  						-webkit-background-size: auto 100%;
  						background-size: auto 100%;
  						margin-top: -7.5px;
  						margin-left: -6.5px;
  						content: "";

  						@media (max-width: 768px) {
  							left: 13px;
  						}
  					}
  				}
  			}
  		}
  	}
  }

  &.add-task {
  	min-height: auto;
  	top: 364px;
		// max-width: 1410px;
		max-width: 100%;
		.modal-lg{
			max-width: 100%;
		}
		//max-width: 100%;
		margin: 1.75rem 15px;

		.mobile-col {
			// overflow: hidden;
			// overflow-x: auto;
		}


		/*.modal-body {*/
			padding: 30px 25px 20px;

			.close {
				position: absolute;
				top: 15px;
				right: 15px;
				width: 15px;
				height: 15px;
				opacity: .7;
				display: block;
				transistion: all .3s;

				.icon {
					background-size: auto 100%;
					width: 12px;
					height: 12px;
					margin-top: -6px;
					margin-left: -6px;
				}
			}

			.table {
				margin: 0;
				td {
					border: 0;
					padding: 0 7.5px;
				}

				tr {
					display: flex;
					justify-content: center;

					.folder-cell {
						flex: 1 60px;
						position: relative;
					}

					.group-cell {
						flex: 1 150%;

						.form-group {
							margin-bottom: 0;
						}
					}

					.priority-cell2 {
						flex: 1 200px;
						min-width: 150px;
					}

					.task-name-cell,
					.assigned-cell {
						// flex: 1 23%;
						flex: 1 25%;
						min-width: 300px;
					}

					.date-cell {
						// flex: 1 18%;
						flex: 1 17.5%;
						min-width: 210px;
					}

					.status-cell {
						// flex: 1 170px;
						flex: 1 183px;
						min-width: 160px;
					}

					.area-cell {
						// flex: 1 10%;
						flex: 1 12.5%;
						min-width: 100px;
					}

					.options-cell {
						flex: 1 7%;
						// flex: 1 20%;
					}
				}
			}

			.priority-cell2 {
				.select2-container--default {
					.select2-selection--single .select2-selection__rendered:before {
						width: 20px;
						height: 20px;
						margin-top: -10px;
						left: 10px;
					}

					ul {
						margin-right: 0;
					}
				}
			}

			.task-name-cell {
				.form-control {
					font-size: 13px;
				}
			}

			.assigned-cell {
				.form-control {
					border: 1px solid #e6e6e6;
					font-size: 13px;
					font-family: $font-open-sans;
				}

				.icon-addon {
					top: 1px;
					bottom: 1px;
					right: 1px;
					height: auto;
				}

				.search_results {
					position: absolute;
		  		// display: none;
		  		top: 100%;
		  		right: 0;
		  		left: 0;
		  		background: #fff;
		  		padding: 5px 0;
		  		box-shadow: 0 6px 20px 0px rgba(0,0,0,0.25);
		  		border-radius: 0 0 15px 15px;
		  		z-index: 2;

		  	}

		  	.search_results_list {
		  		height: 200px;
		  		overflow-x: auto;
		  		margin-right: 5px;

		  		.item {
		  			position: relative;
		  			display: -ms-flexbox;
		  			display: -webkit-flex;
		  			display: flex;
		  			-webkit-flex-direction: row;
		  			-ms-flex-direction: row;
		  			flex-direction: row;
		  			-webkit-flex-wrap: nowrap;
		  			-ms-flex-wrap: nowrap;
		  			flex-wrap: nowrap;
		  			-webkit-justify-content: flex-start;
		  			-ms-flex-pack: start;
		  			justify-content: flex-start;
		  			-webkit-align-content: stretch;
		  			-ms-flex-line-pack: stretch;
		  			align-content: stretch;
		  			-webkit-align-items: center;
		  			-ms-flex-align: center;
		  			align-items: center;
		  			border-top: 2px solid #f2f2f2;

		  			&:first-child {
		  				border-top: 0;
		  			}

		  			&:hover {
		  				.person-block {
		  					background: #faf9fd;
		  				}

		  				.btn {
		  					background: #673ab7;
		  					color: #fff;
		  					box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.3);
		  				}
		  			}

		  			.btn {
		  				position: absolute;
		  				top: 50%;
		  				right: 15px;
		  				margin-top: -22.5px;

		  				padding: 12px 15px 12px 15px;
		  				text-align: center;
		  				text-transform: uppercase;
		  				border-radius: 15px;
		  				background: #fff;
		  				color: #000;
		  				transition: all .3s;
		  				display: -ms-flexbox;
		  				display: -webkit-flex;
		  				display: -webkit-box;
		  				display: flex;
		  				-webkit-flex-direction: row;
		  				-ms-flex-direction: row;
		  				-webkit-box-orient: horizontal;
		  				-webkit-box-direction: normal;
		  				flex-direction: row;
		  				-webkit-flex-wrap: nowrap;
		  				-ms-flex-wrap: nowrap;
		  				flex-wrap: nowrap;
		  				-webkit-justify-content: center;
		  				-ms-flex-pack: center;
		  				-webkit-box-pack: center;
		  				justify-content: center;
		  				-webkit-align-content: stretch;
		  				-ms-flex-line-pack: stretch;
		  				align-content: stretch;
		  				-webkit-align-items: center;
		  				-ms-flex-align: center;
		  				-webkit-box-align: center;
		  				align-items: center;
		  				font-size: 13px;
		  				font-weight: 700;

		  				@media (max-width: 576px) {
		  					padding: 5px;
		  					font-size: 12px;
		  					top: 24px;
		  					right: 2px;
		  				}

					    // &:hover,
					    // &:focus {
					    // 	background: #673ab7;
					    // 	color: #fff;
					    // 	box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.3);
					    // }
					}
				}

				.person-block {
					transition: all .3s;
					-webkit-flex: 1 1 auto;
					-ms-flex: 1 1 auto;
					flex: 1 1 auto;
					-webkit-align-self: auto;
					-ms-flex-item-align: auto;
					align-self: auto;
					padding: 15px;

					@media (max-width: 576px) {
						padding-top: 30px;
						padding-bottom: 10px;
					}

					.inner {
						display: table;
						height: 45px;
						width: 100%;
						margin: 0;
						padding: 0;

						.avatar,
						.name {
							display: table-cell;
							vertical-align: middle;
						}

						.avatar {
							width: 45px;

							img {
								width: 100%;
								border-radius: 100px;
							}
						}

						.name {
							font-size: 13px;
							color: #a3a3a3;
							padding-left: 15px;

							b {
								display: block;
								font-weight: 600;
								color: #000;
							}
						}
					}
				}		    		
			}
		}

		.date-cell {
			.custom-search-block {
				position: relative;

				&:before {
					position: absolute;
					top: 50%;
					left: 10px;
					content: "";
					background: url(../img/icons/@2x/icon-date@2x.png) 0 0 no-repeat;
					width: 16px;
					height: 16px;
					-webkit-background-size: auto 100%;
					background-size: auto 100%;
					margin-top: -8px;
					opacity: .3;
					z-index: 11;
				}

				& > .form-control {
					padding-left: 40px;
					border: 1px solid #e6e6e6;
				}
				.el-input__inner{
					padding-left: 40px;
					border: 1px solid #e6e6e6;
				}

			}
			.set-date-block {
				position: absolute;
				top: 0;
				left: 0;
				width: 500px;
				box-shadow: 0 6px 20px 0px rgba(0,0,0,0.25);
				background: #fff;
				display: flex;
				flex-wrap: wrap;
				border-radius: 15px;
	    		// opacity: 0;
	    		// visibility: hidden;
	    		transition: all .3s;
	    		z-index: 99;

	    		&.show {
	    			opacity: 1;
	    			visibility: visible;
	    		}

	    		.timepicker-block,
	    		.datepicker-block {
	    			flex: 0 0 50%;
	    			padding: 15px;
	    		}

	    		.head {
	    			position: relative;
	    			text-align: right;
	    			padding-bottom: 10px;
	    			border-bottom: 2px solid #f2f2f2;
	    			margin-bottom: 20px;
	    			font-weight: 400;
	    			font-size: 13px;

	    			.title {
	    				position: absolute;
	    				top: 50%;
	    				left: 0;
	    				color: #ccc;
	    				font-weight: 400;
	    				font-size: 13px;
	    				padding-left: 25px;
	    				margin-top: -13px;

	    				.icon {
	    					left: 0;
	    					margin-left: 0;
	    					opacity: .3;
	    				}
	    			}

	    			input {
	    				background: 0;
	    				border: 0;
	    				text-align: right;
	    				font-size: 13px;
	    				color: #666666;
	    				font-weight: 400;
	    			}
	    		}

	    		.timepicker-block {
	    			.inner-block {
	    				box-shadow: none;
	    				border-radius: 0;
	    				margin-top: 0;

	    				.simplebar-track.vertical {
	    					display: block !important;
	    				}
	    			}
	    			.timepicker-inner .inner-block .value:hover,
	    			.timepicker-inner .inner-block .value.active {
	    				background: #ff9800;
	    				color: #fff;
	    			}

	    			.timepicker-inner .inner-block .value:hover {
	    				background: rgba(#ff9800, 0.1);
	    				color: #ff9800;
	    			}

	    			.timepicker-inner .simplebar-scrollbar {
	    				background: #ff9800;
	    			}
	    		}

	    		.datepicker-block {
	    			.datepicker {
	    				td,
	    				th {
	    					background: none !important;
	    					border: 0;

	    					&.dow {
	    						padding: 5px 0;
	    						color: #cccccc !important;
	    					}

	    					&.active {
	    						background: #ff9800 !important;
	    						color: #fff !important;
	    					}

	    					&.today {
	    						background: rgba(#ff9800, 0.1) !important;
	    						color: #ff9800 !important;

	    						&.active {
	    							background: #ff9800 !important;
	    							color: #fff !important;
	    						}

	    						&:hover {
	    							color: #fff !important;        
	    						}
	    					}

	    					&:last-child {
	    						min-width: auto !important;
	    					}

	    					&.day {
	    						&:hover {
	    							background: #ff9800 !important;
	    							color: #fff;
	    						}
	    					}
	    				}
	    			}
	    		}
	    	}
	    }

	    .status-cell {
	    	.dropdown-toggle {
	    		padding: 0;
	    		background: none;
	    		border: 0;
	    		box-shadow: none;
	    		text-align: left;

	    		&:hover {
	    			span {
	    				box-shadow: 0 2px 2px rgba(0,0,0,0.2);
	    			}
	    		}

	    		&:after {
	    			display: none;
	    		}

	    		span.working ~ &:after {
	    			background-color: #2196f3;
	    		}

	    		span {
	    			position: relative;
	    			display: block;
	    			width: 145px;
	    			height: 45px;
	    			border-radius: 15px;
	    			font-size: 11px;
	    			color: #fff;
	    			padding: 13px 5px 13px 15px;
	    			z-index: 2;
	    			transition: all .3s;

	    			&:after {
	    				position: absolute;
	    				height: 45px;
	    				width: 15px;
	    				top: 0;
	    				right: 0;
	    				margin: 0;
	    				content: "";
	    				border-radius: 0 100px 100px 0;
	    				background: url(../img/icons/angle-down-full--white.png) 0 50% no-repeat;
	    				z-index: 1;
	    				transition: all .3s;
	    			}

	    			&.working {
	    				background: #2196f3;

	    				&:after {
	    					background-color: #2196f3;
	    				}
	    			}

	    			&.done {
	    				background: #4caf50;

	    				&:after {
	    					background-color: #4caf50;
	    				}
	    			}

	    			&.stuck {
	    				background: #ffc107;

	    				&:after {
	    					background-color: #ffc107;
	    				}
	    			}

	    			&.no-status {
	    				background: #e6e6e6;
	    				color: #999;

	    				&:after {
	    					background-color: #e6e6e6;
	    				}
	    			}

	    			&.user-label {
	    				background: #999999;

	    				&:after {
	    					background-color: #999999;
	    				}
	    			}
	    		}
	    	}

	    	.dropdown-menu {
	    		box-shadow: 0 6px 14px 2px rgba(0,0,0,0.1);
	    		border-radius: 15px;
	    		border: 0;
	    		width: 195px;
	    		margin-top: -45px;

	    		.dropdown-divider {
	    			margin: 0;
	    		}

	    		.checked,
	    		.dropdown-item {
	    			position: relative;
	    			padding: 10px 15px;
	    			cursor: pointer;

	    			&:active {
	    				background: none;
	    			}

	    			span {
	    				display: block;
	    				font-size: 13px;
	    				color: #000;
	    				padding-left: 30px;

	    				&:before {
	    					position: absolute;
	    					top: 50%;
	    					left: 15px;
	    					content: "";
	    					width: 20px;
	    					height: 20px;
	    					border-radius: 50px;
	    					margin-top: -10px;
	    				}

	    				&.working {
	    					&:before {
	    						background: #2196f3;
	    					}
	    				}

	    				&.done {
	    					&:before {
	    						background: #4caf50;
	    					}
	    				}

	    				&.stuck {
	    					&:before {
	    						background: #ffc107;
	    					}
	    				}

	    				&.no-status {
	    					&:before {
	    						background: #e6e6e6;
	    					}
	    				}
	    			}
	    		}

	    		.form-group {
	    			position: relative;
	    			padding: 8px 10px 7px;
	    			margin-bottom: 0;

	    			&:before {
	    				position: absolute;
	    				top: 50%;
	    				left: 15px;
	    				content: "";
	    				width: 20px;
	    				height: 20px;
	    				border-radius: 50px;
	    				margin-top: -10px;
	    				background: #999999;
	    			}


	    			.form-control {
	    				padding-left: 30px;
	    				height: 30px;
	    				border-radius: 8px;
	    				width: 140px;
	    				float: left;
	    				font-size: 13px;
	    			}

	    			.btn {
	    				display: inline-block;
	    				font-size: 11px;
	    				font-weight: 600;
	    				background: none;
	    				border: 0;
	    				float: left;
	    				padding: 7px 10px;
	    				transition: all .3s;
	    				border-radius: 8px;

	    				&:hover {
	    					background: rgb(221, 221, 221);
	    				}
	    			}
	    		}
	    	}
	    }

	    .options-cell {
	    	.btn-new {
	    		position: relative;
	    		padding: 12px 20px 12px 20px;
	    		text-align: center;
	    		color: #fff;
	    		text-transform: uppercase;
	    		-webkit-border-radius: 15px;
	    		border-radius: 15px;
	    		-webkit-box-shadow: 0 1px 3px 0 rgba(0,0,0,0.16);
	    		box-shadow: 0 1px 3px 0 rgba(0,0,0,0.16);
	    		-webkit-transition: all .3s;
	    		-o-transition: all .3s;
	    		transition: all .3s;
	    		display: -ms-flexbox;
	    		display: -webkit-flex;
	    		display: -webkit-box;
	    		display: flex;
	    		-webkit-flex-direction: row;
	    		-ms-flex-direction: row;
	    		-webkit-box-orient: horizontal;
	    		-webkit-box-direction: normal;
	    		flex-direction: row;
	    		-webkit-flex-wrap: nowrap;
	    		-ms-flex-wrap: nowrap;
	    		flex-wrap: nowrap;
	    		-webkit-justify-content: center;
	    		-ms-flex-pack: center;
	    		-webkit-box-pack: center;
	    		justify-content: center;
	    		-webkit-align-content: stretch;
	    		-ms-flex-line-pack: stretch;
	    		align-content: stretch;
	    		-webkit-align-items: center;
	    		-ms-flex-align: center;
	    		-webkit-box-align: center;
	    		align-items: center;
	    		font-size: 13px;
	    		font-weight: 700;
	    		background: none;
	    		background: #ff9800;
	    	}
	    }
	/*}*/
}

&.employee {
	max-width: 690px;

	.modal-header {
		background: #fff;
		border-bottom: 1px solid rgba(0, 0, 0, 0.25);

		.ks-text {
			color: #000;
		}

		.icon-clear {
			background: url("../img/icons/@2x/icon-close--black@2x.png") 0 0 no-repeat;
			background-size: 100% auto;
		}
	}

	.modal-body {
		padding-bottom: 75px;
	}

	.employee-block {
		.top {
			display: flex;
			justify-content: space-between;
			padding-bottom: 15px;

			@media (max-width: 768px) {
				flex-wrap: wrap;
			}

			.person-block {
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;
        padding-left: 0;

        @media (max-width: 768px) {
        	flex: 0 1 70%;
        	order: 1;
        }

        .inner {
          display: table;
          height: 45px;
          width: 100%;
          margin: 0;
          padding: 0;

          .avatar,
          .name {
            display: table-cell;
            vertical-align: middle;
          }

          .avatar {
            width: 45px;

            img {
              width: 100%;
              border-radius: 100px;
            }
          }

          .name {
            font-size: 13px;
            color: #a3a3a3;
            padding-left: 15px;

            b {
              display: block;
              font-weight: 600;
              color: #000;
            }
          }
        }
      }

			.block-tabs {
				position: relative;
				flex: 0 1 315px;
				padding: 0 15px;

				@media (max-width: 768px) {
					flex: 0 100%;
					order: 3;
					padding: 15px 0 0;
					margin-top: 15px;
					border-top: 1px solid #e6e6e6;
				}

				&:before {
					position: absolute;
					top: -5px;
					bottom: -5px;
					left: 0;
					content: "";
					width: 1px;
					border-left: 1px solid #e6e6e6;

					@media (max-width: 768px) {
						display: none;
					}
				}

				&:after {
					position: absolute;
					top: -5px;
					bottom: -5px;
					right: 0;
					content: "";
					width: 1px;
					border-right: 1px solid #e6e6e6;

					@media (max-width: 768px) {
						display: none;
					}
				}

	      .main-modal-tabs {
					justify-content: space-between;

					@media (max-width: 576px) {
						// margin: 15px -30px;
					}

					li {
						flex: 0 25%;
					}

					.nav-link {
						position: relative;
						color: #000;
						padding: 5px 0 0;
						font-weight: 600;
						font-size: 22px;
						text-align: center;

						&:after {
							position: absolute;
							bottom: -15px;
							left: 0;
							right: 0;
							content: "";
							height: 5px;
							transition: all .3s;
							opacity: 0;
						}

						b {
							display: block;
              font-size: 11px;
              letter-spacing: 0.3px;
              color: #000;
              font-weight: 400;
              text-transform: capitalize;
              margin-top: 5px;
              transition: all .3s;
						}

						@media (max-width: 576px) {
							// padding: 19px 10px;
						}

						// &:hover,
						// &.active {
						// 	color: #ffa015;
						// }

						&.cases {
              &.active,
              &:hover {
                color: #ff9800;

                &:after {
                	background-color: #ff9800;
                	opacity: 1;
                }

                b {
                  color: #ff9800;
                }
              }
            }

            &.deadlines {
              &.active,
              &:hover {
                color: #ff5722;

                &:after {
                	background-color: #ff5722;
                	opacity: 1;
                }

                b {
                  color: #ff5722;
                }
              }
            }

            &.meetings {
              &.active,
              &:hover {
                color: #00bcd4;

                &:after {
                	background-color: #00bcd4;
                	opacity: 1;
                }

                b {
                  color: #00bcd4;
                }
              }
            }

            &.tasks {
              &.active,
              &:hover {
                color: #ffc107;

                &:after {
                	background-color: #ffc107;
                	opacity: 1;
                }

                b {
                  color: #ffc107;
                }
              }
            }

						&.important {
              &:before {
                display: inline-block;
                position: absolute;
                top: -7px;
                // right: 9px;
                margin-left: 20px;
                content: "";
                background: url(../img/icons/@2x/icon-flame--red@2x.png) 0 0 no-repeat;
                background-size: 100% auto;
                width: 14px;
                height: 15px;
              }
            }
					}
				}
			}

			.block-button {
				flex: 0 1 115px;
				display: flex;
				justify-content: flex-end;

				@media (max-width: 768px) {
					flex: 0 30%;
					order: 2;
					border-left: 1px solid #e6e6e6;
				}

				.btn {
          position: relative;
          width: 45px;
          height: 45px;
          padding: 0;
          -webkit-border-radius: 100px;
          border-radius: 100px;
          -webkit-box-shadow: 0 3px 6px 0 rgba(0,0,0,0.16);
          box-shadow: 0 3px 6px 0 rgba(0,0,0,0.16);
          opacity: 1;
          -webkit-transition: all .3s;
          -o-transition: all .3s;
          transition: all .3s;
          background: #4caf50;

          &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            background: url(../img/icons/@2x/icon-plus--white@2x.png) 0 0 no-repeat;
            width: 11px;
            height: 11px;
            -webkit-background-size: 100% auto;
            background-size: 100% auto;
            margin-top: -5.5px;
            margin-left: -5.5px;
            content: "";
            -webkit-transition: all .3s;
            -o-transition: all .3s;
            transition: all .3s;
          }
        }
			}
		}

		.middle {
			margin: 0 -30px;
			border-top: 1px solid #e6e6e6;

			@media (max-width: 768px) {
				margin: 0;
			}

			.activity-table {
				max-height: 390px;
				overflow: hidden;
				overflow-y: auto;

				&::-webkit-scrollbar {
					height: 10px;
					background-color: #F5F5F5;
				}

				@media (max-width: 768px) {
					width: 100%;
					overflow: hidden;
					overflow-x: auto;
				}

				@media (max-width: 576px) {
					// width: 90vw;
				}

				.table {
					min-width: 650px;
				}

				thead {
					th {
						vertical-align: middle;
						background: #f7f7f7;
						border-bottom: 0;
						color: #666666;

						&:last-child {
							padding-right: 30px;
						}
						&:first-child {
							padding-left: 30px;
						}

						.th-title {
							position: relative;
							font-size: 11px;
							text-transform: uppercase;
							line-height: 39px;

							&.sortable {
								background-image: url(../img/both.png);
								cursor: pointer;
								background-position: right;
								background-repeat: no-repeat;

								&.sorted {
									color: #000;

									&.sorted_desc {
										background-image: url(../img/desc.png);
										background-position: right 7px;
										background-repeat: no-repeat;
									}

									&.sorted_asc {
										background-image: url(../img/asc.png);
										background-position: right 0px;
										background-repeat: no-repeat;
									}
								}
							}
						}
					}

					tr.search-row {
						th {
							padding: 0;
							border-bottom: 2px solid #ccc;
						}
					}
				}

				tbody {
					tr:hover,
					tr.row-checked {
						td {
							background: #f7fff8;
						}
					}

					tr:first-child {
						td {
							border-top: 0;
						}
					}

					td {
						vertical-align: middle;
						border-bottom: 0;
						padding: 20px;

						a {
							font-weight: 600;
							color: #000;
						}

						span {
							display: block;
							color: #a3a3a3;
							font-size: 13px;
							line-height: 1.5em;
							word-wrap: break-word;

							&.black {
								color: #333;
							}

							&.bold {
								font-weight: 600;
							}
						}

						&:last-child {
							width: 150px;
							padding-right: 30px;
						}

						&:first-child {
							padding-left: 30px;
							// width: 170px;
						}

						.link-activity {
							display: block;
							padding: 4px 0;
							text-align: center;
							color: #000;
							border: 1px solid #000;
							border-radius: 15px;

							&:hover,
							&:focus {
								background: #000;
								color: #fff;
							}

							&.deadline {
								color: #ff5722;
								border-color: #ff5722;

								&:hover {
									background: #ff5722;
									color: #fff;
								}
							}
						}
					}
				}

				.text-center {
					text-align: center;
				}

				.search-row {
					border-bottom: 1px solid #dee2e6;

					&:hover {
						th {
							background: transparent;
						}
					}
					th {
						position: relative;
						padding: 0;
						vertical-align: middle;
						height: 44px;
						background: #fff;

						&:first-child {
							padding-left: 15px;
							&:before {
								display: none;
							}
						}

						&:before {
							position: absolute;
							top: 50%;
							left: 0;
							bottom: 0;
							height: 15px;
							content: "";
							width: 2px;
							background: #f2f2f2;
							z-index: 3;
							margin-top: -7.5px;
						}
					}

				}
			}

			.employee-modal-cases-tab {
				padding-bottom: 30px;
			}

			.employee-modal-deadlines {
				.table-responsive {

					max-height: 390px;
					overflow: hidden;
					overflow-y: auto;

					@media (max-width: 768px) {
						overflow-x: auto;
					}

					&::-webkit-scrollbar {
						width: 8px;
						background-color: #F5F5F5;
					}
				}
				.table {
					margin-bottom: 0;

					@media (max-width: 768px) {
						min-width: 650px;
					}
					th {
						vertical-align: middle;
						background: #f7f7f7;
						border-bottom: 0;
						color: #666666;
						text-transform: uppercase;
						font-size: 11px;
						font-weight: 600;
					}

					th {
						position: relative;
						padding: 20px 10px 20px 30px;
					}
					td {
						padding: 30px 10px 20px 15px;
						vertical-align: top;

						&:first-child {
							padding-left: 30px;
							width: 170px;
							max-width: 170px;
							min-width: 170px;
						}

						span {
							display: block;
							color: #a3a3a3;
							font-size: 13px;
							line-height: 1.5em;
							word-wrap: break-word;

							&.black {
								color: #000;
							}

							&.bold {
								font-weight: 600;
							}
						}

						.progress {
							position: relative;
					    display: block;
					    width: 100px;
					    height: 25px;
					    -webkit-border-radius: 100px;
					    border-radius: 100px;
					    font-size: 11px;
					    color: #fff;
					    line-height: 1.5em;
					    padding: 5px 0;
					    z-index: 2;
					    -webkit-transition: all .3s;
					    -o-transition: all .3s;
					    transition: all .3s;
					    margin-top: 10px;
					    text-align: center;

					    &.in-progress {
					    	background: #ffc107;
					    	color: #fff;
					    }

					    &.done {
					    	background: #4caf50;
					    	color: #fff;
					    }

					    &.not-done {
					    	background: #ff5722;
					    	color: #fff;
					    }
						}

						.progress-title {
							margin-top: 15px;
							
							&.done {
								color: #4caf50;
							}
						}
					}

				}
				.deadline-list-block {
					.list-link {
						display: block;
						color: #000;
						font-weight: 600;
						margin-bottom: 5px;

						&:hover {
							color: #ff5722;
						}
					}

					.description {
						// text-overflow: ellipsis;
						// overflow: hidden; 
						// width: 525px; 
						// height: 1.4em; 
						// white-space: nowrap;
						color: #999;
						font-size: 13px;
						margin-bottom: 15px;
					}

					.attachment-block {
						display: -ms-flexbox;
						display: -webkit-flex;
						display: -webkit-box;
						display: flex;
						-webkit-flex-direction: row;
						-ms-flex-direction: row;
						-webkit-box-orient: horizontal;
						-webkit-box-direction: normal;
						flex-direction: row;
						-webkit-flex-wrap: wrap;
						-ms-flex-wrap: wrap;
						flex-wrap: wrap;
						-webkit-justify-content: flex-start;
						-ms-flex-pack: start;
						-webkit-box-pack: start;
						justify-content: flex-start;
						-webkit-align-content: stretch;
						-ms-flex-line-pack: stretch;
						align-content: stretch;
						-webkit-align-items: center;
						-ms-flex-align: center;
						-webkit-box-align: center;
						align-items: center;

						.btn-file {
							-webkit-flex: 1 1 auto;
							-ms-flex: 1 1 auto;
							-webkit-box-flex: 1;
							flex: 1 1 auto;
							position: relative;
							padding: 12px 15px 12px 25px;
							text-align: center;
							background: none;
							-webkit-border-radius: 15px;
							border-radius: 15px;
							-webkit-transition: all .3s;
							-o-transition: all .3s;
							transition: all .3s;
							display: -ms-flexbox;
							display: -webkit-flex;
							display: -webkit-box;
							display: flex;
							-webkit-flex-direction: row;
							-ms-flex-direction: row;
							-webkit-box-orient: horizontal;
							-webkit-box-direction: normal;
							flex-direction: row;
							-webkit-flex-wrap: nowrap;
							-ms-flex-wrap: nowrap;
							flex-wrap: nowrap;
							-webkit-justify-content: center;
							-ms-flex-pack: center;
							-webkit-box-pack: center;
							justify-content: center;
							-webkit-align-content: stretch;
							-ms-flex-line-pack: stretch;
							align-content: stretch;
							-webkit-align-items: center;
							-ms-flex-align: center;
							-webkit-box-align: center;
							align-items: center;
							-webkit-box-flex: 0;
							flex: 0 auto;
							font-size: 13px;
							font-weight: 600;

							&.hidden {
								padding: 0;
								width: 0;
								height: 0;
							}

							&:focus {
								box-shadow: none;
							}

							.icon {
								left: 5px;
								margin-left: 0;
							}

							.ks-text {
								color: #000;
								font-weight: 600;
								-webkit-transition: all .3s;
								-moz-transition: all .3s;
								-ms-transition: all .3s;
								-o-transition: all .3s;
								transition: all .3s;
							}

							&:hover {
								background: #f2fff3;

								.ks-text {
									color: #4caf50;
								}
							}
						}

						.btn-more {
							background: none;

							&:focus {
								box-shadow: none;
							}

							&:hover {
								.ks-text {
									color: #000;
								}
							}

							.ks-text {
								color: #828282;
								-webkit-transition: all .3s;
								-moz-transition: all .3s;
								-ms-transition: all .3s;
								-o-transition: all .3s;
								transition: all .3s;
							}
						}
					}
				}
			}

			.employee-modal-meetings {
				padding: 15px 0;

				.table-responsive {

					max-height: 390px;
					overflow: hidden;
					overflow-y: auto;

					@media (max-width: 768px) {
						overflow-x: auto;
					}

					&::-webkit-scrollbar {
						width: 8px;
						background-color: #F5F5F5;
					}
				}

				.card-blockquote {
		      padding: 15px 0 25px 30px;
		      display: flex;
		      transition: all .3s;
		      margin-bottom: 0;

		      @media (max-width: 768px) {
		        padding: 15px;
		        min-width: 650px;
		      }

		      &:hover {
		        .right-block {
		          .open-popover-link {
		            opacity: 1;
		            visibility: visible;
		          }
		        }
		      }

		      .left-block {
		        flex: 1 100%;
		      }

		      .right-block {
		        flex: 0 200px;
		        min-width: 200px;

		        .title {
		          display: block;
		          font-size: 11px;
		          font-weight: 600;
		          color: #666666;
		          text-transform: uppercase;
		          margin-bottom: 15px;
		        }

		        .client-name {
		        	display: block;
		        	font-size: 16px;
		        	color: #000;
		        	margin-top: 15px;
		        }
		      }

		      .meeting-link {
		        position: relative;
		        display: block;
		        padding-left: 23px;
		        font-size: 16px;
		        font-weight: 600;
		        letter-spacing: 0.4px;
		        text-align: left;
		        color: #000;
		        margin-bottom: 12px;

		        &:before {
		          position: absolute;
		          top: 50%;
		          left: 0;
		          content: "";
		          width: 16px;
		          height: 16px;
		          margin-top: -8px;
		          background: url(../img/icons/@2x/icon-time--cyan@2x.png) 0 0 no-repeat;
					    -webkit-background-size: 100% auto;
					    background-size: 100% auto;
		        }
		      }

		      .meeting-info {
		        display: block;
		        font-size: 13px;
		        text-align: left;
		        color: #666;
		        transition: all .3s;
		      }

		      .meeting-info-2 {
		        display: block;
		        font-size: 13px;
		        text-align: left;
		        color: #aaaaaa;
		      }
		    }
			}

			.employee-modal-tasks {
				.activity-table {
					// overflow: visible;
					height: auto;

					thead {
						th {
							.th-title {
								text-transform: none;
							}
						}
					}

					tbody {
						td {
							padding: .75rem;

							&:last-child {
								width: 150px;
								padding-right: 30px;
							}

							&:first-child {
								padding-left: 30px;
								// width: 170px;
								text-align: right;
							}

							.nowrap {
								text-overflow: ellipsis;
							  overflow: hidden;
							  display: -webkit-box;
							  -webkit-line-clamp: 1;
							  -webkit-box-orient: vertical;

							}
						}
					}
				}
				td.priority-cell {
					padding-right: 30px !important;
					text-align: center !important;
				}
				.priority-cell {
					.dropdown-toggle {
						padding: 0;
						background: none;
						border: 0;
						box-shadow: none;

						&:hover {
							span {
								border-radius: 100px 0 0 100px;

								&:after {
									right: -15px;
									background-position: 0 50%;
								}
							}
						}

						&:after {
							display: none;
						}

						span {
							position: relative;
							display: block;
							width: 30px;
							height: 30px;
							border-radius: 100px;
							font-size: 11px;
							color: #fff;
							padding: 7px 0;
							z-index: 2;
							transition: all .3s;
							text-indent: -99999999px;

							&:after {
								position: absolute;
						    height: 30px;
						    width: 15px;
						    top: 0;
						    right: 0;
						    margin: 0;
						    content: "";
						    border-radius: 0 100px 100px 0;
						    background: url(../img/icons/angle-down-full--white.png) -7px 50% no-repeat;
						    z-index: 1;
						    transition: all .3s;
							}

							&:before {
								position: absolute;
								top: 50%;
								left: 50%;
								font-family: $font-mont;
								font-size: 11px;
								font-weight: 600;
								color: #fff;
								content: "";
								width: 30px;
								height: 30px;
								line-height: 30px;
								margin-top: -15px;
								margin-left: -15px;
								text-indent: 0;
								z-index: 2;
							}

							&.high {
								background: #ff5722;

								&:after {
									background-color: #ff5722;
								}

								&:before {
									content: "!!!";
								}
							}

							&.medium {
								background: #673ab7;

								&:after {
									background-color: #673ab7;
								}

								&:before {
									content: "!!";
								}
							}

							&.low {
								background: #00bcd4;

								&:after {
									background-color: #00bcd4;
								}

								&:before {
									content: "!";
								}
							}

							&.no-priority {
								background: #e6e6e6;

								&:after {
									background-color: #e6e6e6;
								}
							}
						}
					}

					.dropdown-menu {
						box-shadow: 0 6px 14px 2px rgba(0,0,0,0.1);
						border-radius: 15px;
						border: 0;
						width: 195px;

						.dropdown-divider {
							margin: 0;
						}

						.checked,
						.dropdown-item {
							position: relative;
							padding: 10px 15px;
							cursor: pointer;

							&:active {
								background: none;
							}

							span {
								display: block;
								font-size: 13px;
								color: #000;
								padding-left: 30px;

								&:before {
									position: absolute;
									top: 50%;
									left: 15px;
									content: "";
									font-family: $font-mont;
									font-size: 11px;
									color: #fff;
									font-weight: 600;
									width: 20px;
									height: 20px;
									border-radius: 50px;
									margin-top: -10px;
									text-align: center;
								}

								&.high {
									&:before {
										background: #ff5722;
										content: "!!!";
									}
								}

								&.medium {
									&:before {
										background: #673ab7;
										content: "!!";
									}
								}

								&.low {
									&:before {
										background: #00bcd4;
										content: "!";
									}
								}

								&.no-priority {
									&:before {
										background: #e6e6e6;
									}
								}
							}
						}
					}
				}

				.task-name-cell {
					padding-left: .75rem;
					&:hover {
						cursor: pointer;

						.btn-edit {
							opacity: 1;
							visibility: visible;
						}

						.name {
							color: #ff9800 !important;
						}
					}

					.name-block {
						position: relative;
					}

					.name {
						position: relative;
				    display: inline-block;
						font-size: 13px;
						font-weight: 600;
				    width: inherit;
						transition: all .3s;
						padding: 6px 19px 6px 0;
						max-width: 225px;

						text-overflow: ellipsis;
					  overflow: hidden;
					  display: -webkit-box;
					  -webkit-line-clamp: 1;
					  -webkit-box-orient: vertical
					}

					.count {
						position: absolute;
						top: 50%;
						right: 0;
						width: 12px;
						height: 12px;
						background: #ff5722;
						border-radius: 20px;
						font-size: 8px;
						color: #fff;
						text-align: center;
						line-height: 12px;
						font-weight: 600;
						margin-top: -13px;
						font-style: normal;
					}

					.btn-edit {
						position: absolute;
						right: -5px;
						top: 50%;
						margin-top: -15px;
						width: 30px;
						height: 30px;
						border: solid 1px rgba(0, 0, 0, 0.1);
						background: #fffaf2;
						transition: all .3s;
						border-radius: 100px;
						opacity: 0;
						visibility: hidden;

						.icon {
							left: 50%;
							margin-left: -5px;
						}

						&:hover {
							background: #FFF5E5;
							// border-color: #FFF5E5;

							.icon {
								background: url('../img/icons/@2x/icon-pencil--orange@2x.png') 0 0 no-repeat;
								background-size: 100% auto;
							}
						}
					}
				}

				.case-cell {
					max-width: 170px;
				}

			}
		}
	}

	.footer-block {
		position: absolute;
		bottom: 0;
		height: 75px;
		left: 0;
		right: 0;
		background: #fff;
		border-radius: 0 0 15px 15px;
		z-index: 2;
		border-top: 1px solid #e6e6e6;
	}

	.back-to-btn {
		font-weight: 700;
		color: #000;
		background: none;
		border: 0;
		width: 100%;
		padding: 27px;
		transition: all .3s;
		text-transform: uppercase;
		margin: 0;
		border-radius: 0;
		border-radius: 0 0 15px 15px;


		&:hover {
			opacity: 1;
			background: rgba(0,0,0,0.2);
		}

		span {
			position: relative;

			&:before {
				position: absolute;
				top: 50%;
				right: -25px;
				background: url(../img/icons/@2x/angle-right@2x.png) 0 0 no-repeat;
				width: 9px;
				height: 9px;
				-webkit-background-size: auto 100%;
				background-size: auto 100%;
				margin-top: -5.5px;
				content: "";
			}
		}
	}
}

}

.modal-action {
	max-width: 375px;

	.modal-content {
		border: 0;
		border-radius: 15px;
		box-shadow: 0 6px 20px 0px rgba(0,0,0,0.25);
	}

	.modal-body {
		padding: 30px;
		text-align: center;

		.icon-block {
			position: relative;
			margin-bottom: 15px;

			.icon {
				position: relative;
				display: inline-block;
				left: 0;
				margin-left: 0;
				top: 0;
				margin-top: 0;
			}
		}

		.title {
			display: inline-block;
			margin: 0;
			padding: 0;
			color: #000;
			font-size: 16px;
			font-weight: 600;
			line-height: 1.1em;
			margin-bottom: 15px;
		}

		.info-content {
			font-size: 13px;
		}
	}

	.modal-footer {
		padding: 0;
		border: 0;

		.btn {
			flex: 1 1 auto;
			font-weight: 700;
			color: #000;
			background: none;
			border: 0;
			padding: 12px;
			border-top: 2px solid #eef1f2;
			border-left: 2px solid #eef1f2;
			transition: all .3s;
			text-transform: uppercase;
			margin: 0;

			&.ok {
				&:hover {
					color: #4caf50;
				}
			}

			&.cancel {
				&:hover {
					color: rgb(255, 152, 0);
				}
			}

			&.delete {
				&:hover {
					color: rgb(255, 87, 34);
				}
			}

			&:first-child {
				border-left: 0;
			}
		}
	}
}

#modalViewCalendar,
#modalViewFiles,
#modalViewTask {
	padding-right: 0 !important;
}

#modalViewCalendar.modal.fade,
#modalViewFiles.modal.fade,
#modalViewTask.modal.fade {
	.task-view {
		transform: translate(100%,0);
		margin: 0;
		float: right;
	}
}

#modalViewCalendar.modal.show,
#modalViewFiles.modal.show,
#modalViewTask.modal.show {
	.task-view {
		transform: translate(0,0);
		margin: 0;
		float: right;
	}
}

.task-view .ks-contacts .content .pane-content.calendar .contact-content {
	border-radius: 0;
}

.task-view {
	max-width: 1350px;
	width: 100%;

	.modal-body {
		padding: 0;
	}

	.modal-header {
		position: relative;
		height: 45px;
		padding: 0;

		.close {
			position: relative;
			flex: 0 45px;
			margin: 0;
			padding: 0;
			height: 45px;
			background: #ff5722;
			opacity: 1;
			min-width: 45px;
			transition: all .3s;

			.icon {
				left: 50%;
				margin-left: -7.5px;
			}
		}

		.title {
			position: relative;
			flex: 0 100%;

			.icon {
				left: 15px;
				margin-left: 0;
			}

			.ks-text {
				display: block;
				font-size: 13px;
				color: #000;
				font-weight: 600;
				line-height: 45px;
				padding-left: 45px;
			}
		}

		.open-popover-link {
			position: absolute;
			top: 50%;
			margin-top: -15px;
			right: 0;
			width: 30px;
			height: 31px;

			.open-popover {
				width: 34px;
				height: 31px;
				float: right;
			}

			.more-options {
				position: relative;
				display: block;
				height: 31px;
				cursor: pointer;
				width: 30px;
				float: right;
				border-radius: 15px;
				transition: all .3s;

				&:hover {
					background: rgba(255, 152, 0, 0.1);
					.icon {
						background: url(../img/icons/@2x/icon-dots-more--orange@2x.png) 0 0 no-repeat;
						width: 4px;
						height: 15px;
						-webkit-background-size: auto 100%;
						background-size: auto 100%;
					}
				}

				.icon {
					height: 15px;
					margin-top: -7.5px;
					transition: all .3s;
				}
			}
		}
	}

	.left-information {
		border-right: solid 1px #e6e6e6;

		@media (max-width: 991px) {
			margin-right: 15px;
			border-right: 0;
			border-bottom: solid 1px #e6e6e6;

		}

		.inner-middle {
			padding: 30px 15px 30px 60px;

			@media (max-width: 991px) {
				padding: 15px;
			}
		}

		.scroll-block {
			overflow: hidden;
			overflow-y: scroll;
			height: 550px;
			padding-right: 45px;

			@media (max-width: 991px) {
				height: 300px;
				padding-right: 15px;
			}

			&::-webkit-scrollbar-track {
				border-radius: 0;
				box-shadow: none;
				background-color: rgba(153, 153, 153, 0.2);
				border: none;
				transition: all .3s;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 0;
				background: #ccc;
				border: none;
				transition: all .3s;
			}

			&::-webkit-scrollbar
			{
				width: 3px;
				transition: all .3s;
			}

			&::-webkit-scrollbar-thumb:hover {
				background: rgba(153, 153, 153, 0.9);
				transition: all .3s;
			}

			.form-title {
				position: relative;
				font-weight: 600;
				color: #666666;
				text-transform: uppercase;
				font-size: 11px;

				&.has-icon {
					padding-left: 18px;

					.icon {
						left: 0;
						margin-left: 0;
						margin-top: -3px;
					}
				}
			}

			input.form-control {
				font-size: 18px;
				font-weight: 600;
			}

			.form-area {
				font-size: 16px;
				line-height: 1.36em;
			}

			.attachment-block {
				.btn-file {
					&:hover {
						.icon-right {
							background: url(../img/icons/@2x/icon-remove--green@2x.png) 0 0 no-repeat;
							-webkit-background-size: 100% auto;
							background-size: 100% auto;
							opacity: 1;
						}
					}
				}

				.form-title {
					font-weight: 400;
					clear: left;
					padding-top: 20px;
				}
			}

			.comments-block {
				margin-top: 30px;

				.title {
					font-size: 10px;
					font-weight: 600;
					color: #666666;
					line-height: 1.5em;
					text-transform: uppercase;
					text-align: center;
				}
			}


			.person-comment {
				margin-bottom: 30px;

				&:hover {
					.top {
						.action-block {
							transform: translateY(0);
						}

						.new-comment {
							transform: translateY(-110%);
						}
					}
				}

				.top {
					display: table;
					height: 30px;
					width: 100%;
					margin-bottom: 15px;

					.avatar {
						display: table-cell;
						vertical-align: middle;
						width: 30px;

						img {
							width: 100%;
							border-radius: 100px;
						}
					}

					.name {
						display: table-cell;
						vertical-align: middle;
						font-size: 13px;
						color: #c5c5c5;
						padding-left: 15px;
						overflow: hidden;

						b {
							font-weight: 600;
							color: #000;
						}
					}

					.time-ago {
						display: inline-block;
						position: relative;
						font-size: 13px;
						color: #999999;
						padding-left: 10px;
					}

					.new-comment {
						display: inline-block;
						position: relative;
						padding-left: 17px;
						margin-left: 15px;
						font-size: 13px;
						color: #ff5722;
						transition: all .3s;
						transform: translateY(0);

						&:before {
							position: absolute;
							top: 50%;
							left: 0;
							content: "";
							width: 12px;
							height: 12px;
							background: #ff5722;
							border-radius: 20px;
							margin-top: -6px;
						}
					}

					.action-block {
						position: absolute;
						top: -3px;
						display: flex;
						left: 100%;
						background: #fff;
						z-index: 2;
						transition: all .3s;
						transform: translateY(100%);

						.action-link {
							position: relative;
							padding: 3px 10px 3px 25px;
							margin-left: 15px;
							text-align: center;
							background: none;
							text-transform: uppercase;
							border-radius: 15px;
							transition: all .3s;
							display: flex;
							-webkit-flex-direction: row;
							-ms-flex-direction: row;
							-webkit-box-orient: horizontal;
							-webkit-box-direction: normal;
							flex-direction: row;
							-webkit-flex-wrap: nowrap;
							-ms-flex-wrap: nowrap;
							flex-wrap: nowrap;
							-webkit-justify-content: center;
							-ms-flex-pack: center;
							-webkit-box-pack: center;
							justify-content: center;
							-webkit-align-content: stretch;
							-ms-flex-line-pack: stretch;
							align-content: stretch;
							-webkit-align-items: center;
							-ms-flex-align: center;
							-webkit-box-align: center;
							align-items: center;
							-webkit-box-flex: 0;
							flex: 0 85px;
							font-size: 13px;
							font-weight: 600;

							&:hover {
								opacity: .7;
							}

							.icon {
								left: 0;
								margin-left: 0;
								transition: all .3s;

								&.edit {
									margin-top: -9.5px;
								}

								&.delete {

								}
							}

							.ks-text {
								color: #000;
								transition: all .3s;
								line-height: 1.5em;
							}
						}
					}
				}

				.comment-text {
					padding-left: 15px;

					p {
						font-size: 16px;
						color: #666;
						line-height: 1.8em;
						margin-bottom: 15px;
					}
				}
			}
		}

		.inner-bottom {
			padding: 30px 60px 15px 15px;
			box-shadow: 0 -20px 20px 0 rgba(0, 0, 0, 0.05);

			@media (max-width: 991px) {
				padding: 15px;
			}

			.write-comment {
				display: table;
				height: 45px;
				width: 100%;

				.avatar {
					display: table-cell;
					vertical-align: top;
					width: 45px;

					img {
						width: 100%;
						border-radius: 100px;
					}
				}

				.caseEditor {
					display: table-cell;
					padding-left: 15px;
				}

				.note-editor {
					margin-left: 15px;
				}
			}

			.btn {
				&.submit-btn {
					float: right;
					width: 81px;
					height: 30px;
					border-radius: 12px;
					background: #ff9800;
					box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);;
					font-size: 13px;
					font-weight: 600;
					color: #fff;
					letter-spacing: 0.3px;
					text-align: center;
					text-transform: uppercase;
					margin-top: 15px;
					-webkit-transition: all .3s;
					-moz-transition: all .3s;
					-ms-transition: all .3s;
					-o-transition: all .3s;
					transition: all .3s;
					&:hover {
						background: #e48800;
						box-shadow: 0 4px 14px 2px rgba(0,0,0,0.2);
					}
				}
			}
		}
	}

	.right-information {
		padding: 60px 75px 60px 60px;

		@media (max-width: 1280px) {
			padding: 60px 10px;
		}

		.form-title {
			position: relative;
			font-weight: 600;
			color: #666666;
			text-transform: uppercase;
			font-size: 11px;

			&.has-icon {
				padding-left: 18px;

				.icon {
					left: 0;
					margin-left: 0;
					margin-top: -3px;

					&.icon-deadline {
						width: 10px;
						height: 10px;
						margin-top: -5px;
					}

					&.icon-team-black {
						width: 10px;
						height: 10px;
						margin-top: -5px;
					}
				}
			}
		}

		.priority-group {
			margin-bottom: 30px;
			.select2-container--default {
				.select2-selection--single .select2-selection__rendered:before {
					width: 20px;
					height: 20px;
					margin-top: -10px;
					left: 10px;
				}

				ul {
					margin-right: 0;
				}
			}
		}

		.assigned-group {
			.form-control {
				border: 1px solid #e6e6e6;
				font-size: 13px;
				font-family: $font-open-sans;
			}

			.icon-addon {
				top: 1px;
				bottom: 1px;
				right: 1px;
				height: auto;
			}

			.search_results {
				position: absolute;
	  		// display: none;
	  		top: 100%;
	  		right: 0;
	  		left: 0;
	  		background: #fff;
	  		padding: 5px 0;
	  		box-shadow: 0 6px 20px 0px rgba(0,0,0,0.25);
	  		border-radius: 0 0 15px 15px;
	  		z-index: 2;

	  	}

	  	.search_results_list {
	  		height: 200px;
	  		overflow-x: auto;
	  		margin-right: 5px;

	  		.item {
	  			position: relative;
	  			display: -ms-flexbox;
	  			display: -webkit-flex;
	  			display: flex;
	  			-webkit-flex-direction: row;
	  			-ms-flex-direction: row;
	  			flex-direction: row;
	  			-webkit-flex-wrap: nowrap;
	  			-ms-flex-wrap: nowrap;
	  			flex-wrap: nowrap;
	  			-webkit-justify-content: flex-start;
	  			-ms-flex-pack: start;
	  			justify-content: flex-start;
	  			-webkit-align-content: stretch;
	  			-ms-flex-line-pack: stretch;
	  			align-content: stretch;
	  			-webkit-align-items: center;
	  			-ms-flex-align: center;
	  			align-items: center;
	  			border-top: 2px solid #f2f2f2;

	  			&:first-child {
	  				border-top: 0;
	  			}

	  			&:hover {
	  				.person-block {
	  					background: #faf9fd;
	  				}

	  				.btn {
	  					background: #ff5722;
	  					color: #fff;
	  					box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.3);
	  				}
	  			}

	  			.btn {
	  				position: absolute;
	  				top: 50%;
	  				right: 15px;
	  				margin-top: -22.5px;

	  				padding: 12px 15px 12px 15px;
	  				text-align: center;
	  				text-transform: uppercase;
	  				border-radius: 15px;
	  				background: #fff;
	  				color: #000;
	  				transition: all .3s;
	  				display: -ms-flexbox;
	  				display: -webkit-flex;
	  				display: -webkit-box;
	  				display: flex;
	  				-webkit-flex-direction: row;
	  				-ms-flex-direction: row;
	  				-webkit-box-orient: horizontal;
	  				-webkit-box-direction: normal;
	  				flex-direction: row;
	  				-webkit-flex-wrap: nowrap;
	  				-ms-flex-wrap: nowrap;
	  				flex-wrap: nowrap;
	  				-webkit-justify-content: center;
	  				-ms-flex-pack: center;
	  				-webkit-box-pack: center;
	  				justify-content: center;
	  				-webkit-align-content: stretch;
	  				-ms-flex-line-pack: stretch;
	  				align-content: stretch;
	  				-webkit-align-items: center;
	  				-ms-flex-align: center;
	  				-webkit-box-align: center;
	  				align-items: center;
	  				font-size: 13px;
	  				font-weight: 700;

	  				@media (max-width: 576px) {
	  					padding: 5px;
	  					font-size: 12px;
	  					top: 24px;
	  					right: 2px;
	  				}

				    // &:hover,
				    // &:focus {
				    // 	background: #673ab7;
				    // 	color: #fff;
				    // 	box-shadow: 0 2px 6px 0.5px rgba(0,0,0,0.3);
				    // }
				}
			}

			.person-block {
				transition: all .3s;
				-webkit-flex: 1 1 auto;
				-ms-flex: 1 1 auto;
				flex: 1 1 auto;
				-webkit-align-self: auto;
				-ms-flex-item-align: auto;
				align-self: auto;
				padding: 15px;

				@media (max-width: 576px) {
					padding-top: 30px;
					padding-bottom: 10px;
				}

				.inner {
					display: table;
					height: 45px;
					width: 100%;
					margin: 0;
					padding: 0;

					.avatar,
					.name {
						display: table-cell;
						vertical-align: middle;
					}

					.avatar {
						width: 45px;

						img {
							width: 100%;
							border-radius: 100px;
						}
					}

					.name {
						font-size: 13px;
						color: #a3a3a3;
						padding-left: 15px;

						b {
							display: block;
							font-weight: 600;
							color: #000;
						}
					}
				}
			}		    		
		}
	}

	.expires-group {
		margin-bottom: 30px;

		&:before {
			position: absolute;
			top: 50%;
			left: 10px;
			content: "";
			background: url(../img/icons/@2x/icon-date@2x.png) 0 0 no-repeat;
			width: 16px;
			height: 16px;
			-webkit-background-size: auto 100%;
			background-size: auto 100%;
			margin-top: -8px;
			opacity: .3;
			z-index: 11;
		}

		& > .form-control {
			padding-left: 40px;
			border: 1px solid #e6e6e6;
		}
		.set-date-block {
			position: absolute;
			top: 0;
			left: 0;
			width: 250px;
			box-shadow: 0 6px 20px 0px rgba(0,0,0,0.25);
			background: #fff;
			display: flex;
			flex-wrap: wrap;
			border-radius: 15px;
    		// opacity: 0;
    		// visibility: hidden;
    		transition: all .3s;
    		z-index: 99;

    		&.show {
    			opacity: 1;
    			visibility: visible;
    		}

    		.timepicker-block,
    		.datepicker-block {
    			flex: 0 0 50%;
    			padding: 15px;
    		}

    		.head {
    			position: relative;
    			text-align: right;
    			padding-bottom: 10px;
    			border-bottom: 2px solid #f2f2f2;
    			margin-bottom: 20px;
    			font-weight: 400;
    			font-size: 13px;

    			.title {
    				position: absolute;
    				top: 50%;
    				left: 0;
    				color: #ccc;
    				font-weight: 400;
    				font-size: 13px;
    				padding-left: 25px;
    				margin-top: -13px;

    				.icon {
    					left: 0;
    					margin-left: 0;
    					opacity: .3;
    				}
    			}

    			input {
    				background: 0;
    				border: 0;
    				text-align: right;
    				font-size: 13px;
    				color: #666666;
    				font-weight: 400;
    			}
    		}

    		.timepicker-block {
    			.inner-block {
    				box-shadow: none;
    				border-radius: 0;
    				margin-top: 0;

    				.simplebar-track.vertical {
    					display: block !important;
    				}
    			}
    			.timepicker-inner .inner-block .value:hover,
    			.timepicker-inner .inner-block .value.active {
    				background: #ff9800;
    				color: #fff;
    			}

    			.timepicker-inner .inner-block .value:hover {
    				background: rgba(#ff9800, 0.1);
    				color: #ff9800;
    			}

    			.timepicker-inner .simplebar-scrollbar {
    				background: #ff9800;
    			}
    		}

    		.datepicker-block {
    			.datepicker {
    				td,
    				th {
    					background: none !important;
    					border: 0;

    					&.dow {
    						padding: 5px 0;
    						color: #cccccc !important;
    					}

    					&.active {
    						background: #ff9800 !important;
    						color: #fff !important;
    					}

    					&.today {
    						background: rgba(#ff9800, 0.1) !important;
    						color: #ff9800 !important;

    						&.active {
    							background: #ff9800 !important;
    							color: #fff !important;
    						}

    						&:hover {
    							color: #fff !important;        
    						}
    					}

    					&:last-child {
    						min-width: auto !important;
    					}

    					&.day {
    						&:hover {
    							background: #ff9800 !important;
    							color: #fff;
    						}
    					}
    				}
    			}
    		}
    	}
    }

    .status-group {
    	margin-bottom: 30px;
    	.custom-dropdown-select {
    		display: flex;
    		width: 100%;
    	}
    	.dropdown-toggle {
    		padding: 0;
    		background: none;
    		border: 0;
    		box-shadow: none;
    		text-align: left;
    		display: block;
    		width: 100%;

    		&:hover {
    			span {
    				box-shadow: 0 2px 2px rgba(0,0,0,0.2);
    			}
    		}

    		&:after {
    			display: none;
    		}

    		span.working ~ &:after {
    			background-color: #2196f3;
    		}

    		span {
    			position: relative;
    			display: block;
    			width: 100%;
    			height: 45px;
    			border-radius: 15px;
    			font-size: 11px;
    			color: #fff;
    			padding: 13px 5px 13px 15px;
    			z-index: 2;
    			transition: all .3s;

    			&:after {
    				position: absolute;
    				height: 45px;
    				width: 15px;
    				top: 0;
    				right: 0;
    				margin: 0;
    				content: "";
    				border-radius: 0 100px 100px 0;
    				background: url(../img/icons/angle-down-full--white.png) 0 50% no-repeat;
    				z-index: 1;
    				transition: all .3s;
    			}

    			&.working {
    				background: #2196f3;

    				&:after {
    					background-color: #2196f3;
    				}
    			}

    			&.done {
    				background: #4caf50;

    				&:after {
    					background-color: #4caf50;
    				}
    			}

    			&.stuck {
    				background: #ffc107;

    				&:after {
    					background-color: #ffc107;
    				}
    			}

    			&.no-status {
    				background: #e6e6e6;
    				color: #999;

    				&:after {
    					background-color: #e6e6e6;
    				}
    			}

    			&.user-label {
    				background: #999999;

    				&:after {
    					background-color: #999999;
    				}
    			}
    		}
    	}

    	.dropdown-menu {
    		box-shadow: 0 6px 14px 2px rgba(0,0,0,0.1);
    		border-radius: 15px;
    		border: 0;
    		width: 195px;
    		margin-top: -45px;

    		.dropdown-divider {
    			margin: 0;
    		}

    		.checked,
    		.dropdown-item {
    			position: relative;
    			padding: 10px 15px;
    			cursor: pointer;

    			&:active {
    				background: none;
    			}

    			span {
    				display: block;
    				font-size: 13px;
    				color: #000;
    				padding-left: 30px;

    				&:before {
    					position: absolute;
    					top: 50%;
    					left: 15px;
    					content: "";
    					width: 20px;
    					height: 20px;
    					border-radius: 50px;
    					margin-top: -10px;
    				}

    				&.working {
    					&:before {
    						background: #2196f3;
    					}
    				}

    				&.done {
    					&:before {
    						background: #4caf50;
    					}
    				}

    				&.stuck {
    					&:before {
    						background: #ffc107;
    					}
    				}

    				&.no-status {
    					&:before {
    						background: #e6e6e6;
    					}
    				}
    			}
    		}

    		.form-group {
    			position: relative;
    			padding: 8px 10px 7px;
    			margin-bottom: 0;

    			&:before {
    				position: absolute;
    				top: 50%;
    				left: 15px;
    				content: "";
    				width: 20px;
    				height: 20px;
    				border-radius: 50px;
    				margin-top: -10px;
    				background: #999999;
    			}


    			.form-control {
    				padding-left: 30px;
    				height: 30px;
    				border-radius: 8px;
    				width: 140px;
    				float: left;
    				font-size: 13px;
    			}

    			.btn {
    				display: inline-block;
    				font-size: 11px;
    				font-weight: 600;
    				background: none;
    				border: 0;
    				float: left;
    				padding: 7px 10px;
    				transition: all .3s;
    				border-radius: 8px;

    				&:hover {
    					background: rgb(221, 221, 221);
    				}
    			}
    		}
    	}
    }

    .assign-people-group {
    	margin-top: 30px;

    	.scroll-block {
    		overflow: hidden;
    		overflow-y: scroll;
    		height: 400px;

    		@media (max-width: 991px) {
    			height: 200px;
    		}

    		&::-webkit-scrollbar-track {
    			border-radius: 0;
    			box-shadow: none;
    			background-color: rgba(153, 153, 153, 0.2);
    			border: none;
    			transition: all .3s;
    		}

    		&::-webkit-scrollbar-thumb {
    			border-radius: 0;
    			background: #ccc;
    			border: none;
    			transition: all .3s;
    		}

    		&::-webkit-scrollbar
    		{
    			width: 3px;
    			transition: all .3s;
    		}

    		&::-webkit-scrollbar-thumb:hover {
    			background: rgba(153, 153, 153, 0.9);
    			transition: all .3s;
    		}

    		.item {
    			position: relative;
    			display: -ms-flexbox;
    			display: -webkit-flex;
    			display: flex;
    			-webkit-flex-direction: row;
    			-ms-flex-direction: row;
    			flex-direction: row;
    			-webkit-flex-wrap: nowrap;
    			-ms-flex-wrap: nowrap;
    			flex-wrap: nowrap;
    			-webkit-justify-content: flex-start;
    			-ms-flex-pack: start;
    			justify-content: flex-start;
    			-webkit-align-content: stretch;
    			-ms-flex-line-pack: stretch;
    			align-content: stretch;
    			-webkit-align-items: center;
    			-ms-flex-align: center;
    			align-items: center;

    			&.creator {
    				.avatar {
    					&:before {
    						position: absolute;
    						bottom: 0;
    						right: 0;
    						content: "";
    						background: url(../img/@2x/creator-star@2x.png) 0 0 no-repeat;
    						width: 10px;
    						height: 10px;
    						-webkit-background-size: auto 100%;
    						background-size: auto 100%;
    					}
    				}
    			}

    			&:hover {
    				.person-block {
    					background: #faf9fd;
    				}
    			}

    			.btn {
    				position: absolute;
    				top: 50%;
    				right: 15px;
    				margin-top: -15.5px;
    				padding-left: 30px;
    				text-align: center;
    				text-transform: uppercase;
    				border-radius: 15px;
    				background: #fff;
    				color: #000;
    				transition: all .3s;
    				display: -ms-flexbox;
    				display: -webkit-flex;
    				display: -webkit-box;
    				display: flex;
    				-webkit-flex-direction: row;
    				-ms-flex-direction: row;
    				-webkit-box-orient: horizontal;
    				-webkit-box-direction: normal;
    				flex-direction: row;
    				-webkit-flex-wrap: nowrap;
    				-ms-flex-wrap: nowrap;
    				flex-wrap: nowrap;
    				-webkit-justify-content: center;
    				-ms-flex-pack: center;
    				-webkit-box-pack: center;
    				justify-content: center;
    				-webkit-align-content: stretch;
    				-ms-flex-line-pack: stretch;
    				align-content: stretch;
    				-webkit-align-items: center;
    				-ms-flex-align: center;
    				-webkit-box-align: center;
    				align-items: center;
    				font-size: 13px;
    				font-weight: 700;

    				@media (max-width: 576px) {
    					padding: 5px;
    					font-size: 12px;
    					top: 24px;
    					right: 2px;
    				}

    				.icon {
    					left: 5px;
    					margin-left: 0;
    					transition: all .3s;
    				}

    				.ks-text {
    					color: #000;
    					transition: all .3s;
    					line-height: 1.5em;
    					font-size: 12px;
    					font-weight: 600;
    					text-transform: uppercase;
    				}

    				&:focus,
    				&:hover {
    					.icon {
    						background: url(../img/icons/@2x/icon-minus--red@2x.png) 0 0 no-repeat;
    						-webkit-background-size: auto 100%;
    						background-size: auto 100%;
    					}

    					.ks-text {
    						color: #ff5722;
    					}
    				}

    			}
    		}

    		.person-block {
    			transition: all .3s;
    			-webkit-flex: 1 1 auto;
    			-ms-flex: 1 1 auto;
    			flex: 1 1 auto;
    			-webkit-align-self: auto;
    			-ms-flex-item-align: auto;
    			align-self: auto;
    			padding: 7.5px 15px;

    			@media (max-width: 576px) {
    				padding-top: 30px;
    				padding-bottom: 10px;
    			}

    			.inner {
    				display: table;
    				height: 45px;
    				width: 100%;
    				margin: 0;
    				padding: 0;

    				.avatar,
    				.name {
    					display: table-cell;
    					vertical-align: middle;
    				}

    				.avatar {
    					position: relative;
    					width: 45px;

    					img {
    						width: 100%;
    						border-radius: 100px;
    					}
    				}

    				.name {
    					font-size: 13px;
    					color: #a3a3a3;
    					padding-left: 15px;

    					b {
    						display: block;
    						font-weight: 600;
    						color: #000;
    					}
    				}
    			}
    		}		    		
    	}
    }
}
}

.modal-profile{
	&.modal-create-user {
		max-width:765px;
		.modal-body{
			padding:0;
			padding-lefT:15px;
			padding-right:15px;
		}
		.modal-footer{
			border-top: solid 1px #e6e6e6;
			padding-top:15px;
		}
		.create_user_line{
			border-top: solid 1px #e6e6e6;
			margin-top:1rem;
			margin-bottom:2rem;
		}
		.first-form-group{
			margin-top:30px;
		}
		.user-avatar-load{
			.user-avatar{
				margin-top:30px;
				margin-bottom:15px;
				width:135px;
				height:135px;
				margin-left:auto;
				margin-right:auto;
				border-radius:50%;
				border: solid 1px #e6e6e6;

				img{
					border-radius:50%;
				}
			}
			.icon.icon-upload, .icon.icon-delete{
				left:15px;
				top:16px;
				margin-top:0px;
				margin-left:0px;
			}

			input[type='file'] {
				position: absolute;
				top: 0;
				right: 0;
				min-width: 100%;
				min-height: 100%;
				font-size: 26px;
				text-align: right;
				opacity: 0;
				cursor: inherit;
				display: block;
			}
		}
	}
}			   
.calendar-view {
	.content,
	.ks-contacts {
		padding-top: 0 !important;
	}
}
.grey_bg{
	background:#f7f7f7;
}

.files-view {
	.content,
	.ks-contacts {
		padding-top: 0 !important;
	}

	.ks-contacts .content .pane-content .contact-content,
	.ks-contacts .content .pane-content {
		width: 100%;
	}

	.documents .profile-information .activity-folder-block,
	.documents .profile-information {
		border-radius: 0;
	}

}
.ks-contacts{
	.content{
		.pane-content{
			.contact-content{
				.new_super_modal {
					.table {
						.options-cell{
							.btn{
								&.btn-new{
									position: relative;
									padding: 12px 20px 12px 20px;
									text-align: center;
									color: #fff;
									text-transform: uppercase;
									-webkit-border-radius: 15px;
									border-radius: 15px;
									-webkit-box-shadow: 0 1px 3px 0 rgba(0,0,0,0.16);
									box-shadow: 0 1px 3px 0 rgba(0,0,0,0.16);
									-webkit-transition: all .3s;
									-o-transition: all .3s;
									transition: all .3s;
									display: -ms-flexbox;
									display: -webkit-flex;
									display: -webkit-box;
									display: flex;
									-webkit-flex-direction: row;
									-ms-flex-direction: row;
									-webkit-box-orient: horizontal;
									-webkit-box-direction: normal;
									flex-direction: row;
									-webkit-flex-wrap: nowrap;
									-ms-flex-wrap: nowrap;
									flex-wrap: nowrap;
									-webkit-justify-content: center;
									-ms-flex-pack: center;
									-webkit-box-pack: center;
									justify-content: center;
									-webkit-align-content: stretch;
									-ms-flex-line-pack: stretch;
									align-content: stretch;
									-webkit-align-items: center;
									-ms-flex-align: center;
									-webkit-box-align: center;
									align-items: center;
									font-size: 13px;
									font-weight: 700;
									background: none;
									background: #ff9800;
								}
							}

						}


					}
				}
			}
		}
	} 
}
/*  **************************************************/
/*.el-input__prefix{
	display:none !important;					
}*/
.el-date-picker{
	z-index: 9999999 !important;
}
.el-select-dropdown{
	z-index: 9999999 !important;
}
.el-input__inner{
	-webkit-box-shadow: 0 1px 4px rgba(0,0,0,0.2);
    box-shadow: 0 1px 4px rgba(0,0,0,0.2);
    -webkit-transition: box-shadow .3s, color .3s, opacity .3s;
    -o-transition: box-shadow .3s, color .3s, opacity .3s;
    -webkit-transition: color .3s, opacity .3s, -webkit-box-shadow .3s;
    transition: color .3s, opacity .3s, -webkit-box-shadow .3s;
    transition: box-shadow .3s, color .3s, opacity .3s;
    transition: box-shadow .3s, color .3s, opacity .3s, -webkit-box-shadow .3s;
	border-radius: 15px !important;
	border:none !important;
}


.el-select-dropdown__item{
	padding: 12px 16px;
    font-size: 12px;
    line-height:normal;
    height:auto;
}
.drop_priority_low{
	position: relative;
	display:block;
    padding-left: 30px;
    &:before{
    	
	    position: absolute;
	    top: 50%;
	    left: 0px;
	    content: "!";
	    font-family: "Montserrat",sans-serif;
	    font-size: 11px;
	    font-weight: 600;
	    color: #fff;
	    width: 20px;
	    height: 20px;
	    text-align: center;
	    background: #00bcd4;
	    -webkit-border-radius: 100px;
	    border-radius: 100px;
	    margin-top: -10px;
	    line-height: 20px;
    }
}
.drop_priority_medium{
	position: relative;
	display:block;
    padding-left: 30px;
    &:before{
    	
	    position: absolute;
	    top: 50%;
	    left: 0px;
	    content: "!!";
	    font-family: "Montserrat",sans-serif;
	    font-size: 11px;
	    font-weight: 600;
	    color: #fff;
	    width: 20px;
	    height: 20px;
	    text-align: center;
	    background: #673ab7;
	    -webkit-border-radius: 100px;
	    border-radius: 100px;
	    margin-top: -10px;
	    line-height: 20px;
    }
}
.drop_priority_high{
	position: relative;
	display:block;
    padding-left: 30px;
    &:before{
    	position: absolute;
	    top: 50%;
	    left: 0px;
	    content: "!!!";
	    font-family: "Montserrat",sans-serif;
	    font-size: 11px;
	    font-weight: 600;
	    color: #fff;
	    width: 20px;
	    height: 20px;
	    text-align: center;
	    background: #ff5722;
	    -webkit-border-radius: 100px;
	    border-radius: 100px;
	    margin-top: -10px;
	    line-height: 20px;
    }
}
.drop_priority_nopriority{
	position: relative;
	display:block;
    padding-left: 30px;
    &:before{
    	position: absolute;
	    top: 50%;
	    left: 0px;
	    content: "";
	    font-family: "Montserrat",sans-serif;
	    font-size: 11px;
	    font-weight: 600;
	    color: #fff;
	    width: 20px;
	    height: 20px;
	    text-align: center;
	    background: #e6e6e6;
	    -webkit-border-radius: 100px;
	    border-radius: 100px;
	    margin-top: -10px;
	    line-height: 20px;
    }
}
.drop_status_workingonit{
	position: relative;
	display:block;
    padding-left: 30px;
    &:before{
    	position: absolute;
	    top: 50%;
	    left: 0px;
	    content: "";
	    width: 20px;
	    height: 20px;
	    -webkit-border-radius: 50px;
	    border-radius: 50px;
	    margin-top: -10px;
	    background: #2196f3;
    }
}
.drop_status_done{
	position: relative;
	display:block;
    padding-left: 30px;
    &:before{
    	position: absolute;
	    top: 50%;
	    left: 0px;
	    content: "";
	    width: 20px;
	    height: 20px;
	    -webkit-border-radius: 50px;
	    border-radius: 50px;
	    margin-top: -10px;
	    background: #4caf50;
    }
}
.drop_status_stuck{
	position: relative;
	display:block;
    padding-left: 30px;
    &:before{
    	position: absolute;
	    top: 50%;
	    left: 0px;
	    content: "";
	    width: 20px;
	    height: 20px;
	    -webkit-border-radius: 50px;
	    border-radius: 50px;
	    margin-top: -10px;
	    background: #ffc107;
    }
}
.drop_status_nostatus{
	position: relative;
	display:block;
    padding-left: 30px;
    &:before{
    	position: absolute;
	    top: 50%;
	    left: 0px;
	    content: "";
	    width: 20px;
	    height: 20px;
	    -webkit-border-radius: 50px;
	    border-radius: 50px;
	    margin-top: -10px;
	    background: #e6e6e6;
    }
}

.new_super_modal{
	td{
		vertical-align:top !important;
	}
}
.el-input--suffix .el-input__inner {
    padding-right: 30px;
    padding-left: 46px;
    font-size: 12px;
}
.el-input__prefix{
  .drop_priority_low, .drop_priority_medium, .drop_priority_high, .drop_priority_nopriority, .drop_status_nostatus, .drop_status_stuck, .drop_status_done, .drop_status_workingonit{
     &:before{
        top: 10px;
        margin-top:0px;
        left:10px;
       }
    }
}
.el-input--suffix .el-input__inner {
  box-shadow: none;
}

.el-input--suffix .el-input__inner:hover {
  box-shadow: 0 1px 4px rgba(0,0,0,0.2);
}

.ql-toolbar.ql-snow {
	border: 0;
	border-bottom: 1px solid #ccc;
}

.ql-container.ql-snow {
	border: 0;
}

.el-input__prefix {
	.el-icon-date {
		display: none;
	}
}