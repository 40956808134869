.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999999;
  height: 90px;
  border: none;
  border-radius: 0;
  padding: 0;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  background: #212121;

  .create-block {
    position: relative;
    z-index: 2;
    display: flex;
    height: 45px;
    margin-left: 40px;
    @media (max-width: 991px) {
      margin-left: 0;
    }
    .dropdown-toggle {
      position: relative;
      width: 200px;
      height: 45px;
      background: #4caf50;
      padding: 12.5px 10px 12.5px 10px;
      text-align: center;
      text-transform: uppercase;
      font-weight: 600;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      border-radius: 15px;
      -moz-box-shadow: 0 3px 20px -2px rgba(0,0,0,0.3);
      -webkit-box-shadow: 0 3px 20px -2px rgba(0,0,0,0.3);
      box-shadow: 0 3px 20px -2px rgba(0,0,0,0.3);
      -webkit-transition: all .3s;
      -moz-transition: all .3s;
      transition: all .3s;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-align-content: stretch;
      -ms-flex-line-pack: stretch;
      align-content: stretch;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      @media (max-width: 991px) {
        width: 50px;
        text-indent: -999px;
        overflow: hidden;
        color: #4caf50;
      }
      @media (max-width: 768px) {
        width: 100%;
        overflow-y: visible;
        color: #fff !important;
        border-bottom: 0 !important;
        text-indent: 0;
        padding: 7px 10px 7px 10px;
      }
      &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        background: url(../img/icons/@2x/icon-plus--white@2x.png) 0 0 no-repeat;
        width: 16px;
        height: 16px;
        -webkit-background-size: 100% auto;
        background-size: 100% auto;
        margin-top: -8px;
        margin-left: -16.5px;
        content: "";
        -webkit-transition: all .3s;
        -o-transition: all .3s;
        transition: all .3s;
        display: none;
        @media (max-width: 991px) {
          display: block;
        }
        @media (max-width: 768px) {
          display: none;
        }
      }
      &:after {
        @media (max-width: 991px) {
          position: absolute;
        }
        @media (max-width: 768px) {
          position: relative;
          background: url("../img/icons/@2x/angle-down--white@2x.png") 0 0 no-repeat !important;
          background-size: 100% auto !important;
          width: 9px !important;
          height: 6px !important;
        }
      }
    }
    .dropdown-menu {
      display: none;
      width: 360px;
      min-width: 360px;
      height: 285px;
      margin: 0;
      right: 0;
      left: auto;
      padding: 20px;
      border: 0 none;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      border-radius: 15px;
      box-shadow: 0 15px 60px rgba(0,0,0,0.16);
      @media (max-width: 768px) {
        width: 170px;
        min-width: auto;
        max-width: 360px;
        height: auto;
      }
      &.show {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
      }
      .ks-wrapper {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        -js-display: flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        @media (max-width: 768px) {
          flex-direction: column
          }
      }
      .ks-create-new {
        position: relative;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        -js-display: flex;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        height: 50px;
        width: 160px;
        list-style: none;
        font-weight: 600;
        padding: 0 24px;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        color: #333;
        @media (max-width: 768px) {
          width: 100%;
          padding-right: 0;
        }
        .ks-text {
          margin-left: 20px;
          font-weight: 400;
        }
        .icon {
          left: 25px;
          -webkit-transition: all .3s;
          -moz-transition: all .3s;
          -ms-transition: all .3s;
          -o-transition: all .3s;
          transition: all .3s;
        }
        &:hover {
          color: #4caf50;
          .icon {
            &.icon-task {
              background: url('../img/icons/@2x/icon-tick-border--green@2x.png') 0 0 no-repeat;
              -webkit-background-size: auto 100%;
              -moz-background-size: auto 100%;
              background-size: auto 100%;
            }
            &.icon-file {
              background: url('../img/icons/@2x/icon-file--green@2x.png') 0 0 no-repeat;
              -webkit-background-size: auto 100%;
              -moz-background-size: auto 100%;
              background-size: auto 100%;
            }
            &.icon-meeting {
              background: url('../img/icons/@2x/icon-meeting--green@2x.png') 0 0 no-repeat;
              -webkit-background-size: auto 100%;
              -moz-background-size: auto 100%;
              background-size: auto 100%;
            }
            &.icon-case {
              background: url('../img/icons/@2x/icon-case--green@2x.png') 0 0 no-repeat;
              -webkit-background-size: auto 100%;
              -moz-background-size: auto 100%;
              background-size: auto 100%;
            }
            &.icon-deadline {
              background: url('../img/icons/@2x/icon-calendar--green@2x.png') 0 0 no-repeat;
              -webkit-background-size: auto 100%;
              -moz-background-size: auto 100%;
              background-size: auto 100%;
            }
            &.icon-invoice {
              background: url('../img/icons/@2x/icon-invoice--green@2x.png') 0 0 no-repeat;
              -webkit-background-size: auto 100%;
              -moz-background-size: auto 100%;
              background-size: auto 100%;
            }
            &.icon-time-entry {
              background: url('../img/icons/@2x/icon-time--green@2x.png') 0 0 no-repeat;
              -webkit-background-size: auto 100%;
              -moz-background-size: auto 100%;
              background-size: auto 100%;
            }
            &.icon-expense {
              background: url('../img/icons/@2x/icon-expense--green@2x.png') 0 0 no-repeat;
              -webkit-background-size: auto 100%;
              -moz-background-size: auto 100%;
              background-size: auto 100%;
            }
            &.icon-message {
              background: url('../img/icons/@2x/icon-email--green@2x.png') 0 0 no-repeat;
              -webkit-background-size: auto 100%;
              -moz-background-size: auto 100%;
              background-size: auto 100%;
            }
            &.icon-edit {
              background: url('../img/icons/@2x/icon-edit--green@2x.png') 0 0 no-repeat;
              -webkit-background-size: auto 100%;
              -moz-background-size: auto 100%;
              background-size: auto 100%;
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    height: 60px;
  }

  .navbar-brand {
    height: 90px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 140px;
    padding: 0 25px;
    margin: 0;
    color: #fff;
    z-index: 4;

    @media (max-width: 1300px) {
      width: auto;
    }

    @media (max-width: 768px) {
      height: 60px;
      padding: 0 10px;
    }

    .ks-sidebar-mobile-toggle {
      // display: none;
      color: #fff;
      margin-right: 14px;
      font-size: 21px;
      line-height: 21px;
      position: relative;

      .ks-icon {
        display: block;
        width: 24px;
        height: 24px;
        background: url(../img/menu.png) 0 0 no-repeat;
        -webkit-background-size: 100% auto;
        -moz-background-size: 100% auto;
        background-size: 100% auto;
      }
      @media (max-width: 768px) {
        display: none;
      }
    }

    .ks-navbar-logo {
      -webkit-box-flex: 1;
      -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      -js-display: flex;
      display: flex;

      .ks-logo {
        display: block;

        img {
          width: 128px;
        }
      }
    }
  }

  & > .ks-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;

    & > .nav {
      width: 100%;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      -js-display: flex;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;

      .ks-navbar-menu {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        -js-display: flex;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        width: 450px;
        

        @media (max-width: 1225px) {
          position: fixed;
          right: -230px;
          // right: 0;
          height: 100%;
          z-index: 6;
          width: 230px;
          top: 90px;
          background: #ededed;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          -js-display: flex;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -webkit-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-pack: start;
          -webkit-justify-content: flex-start;
          -ms-flex-pack: start;
          justify-content: flex-start;
          -webkit-transition: all .3s;
          -moz-transition: all .3s;
          transition: all .3s;
        }

        @media (max-width: 768px) {
          top: 60px;
        }

        &.ks-open {
          .ks-search-form {
            & > .ks-wrapper {
              @media (max-width: 1300px) {
                width: 100%;
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                -js-display: flex;
                display: flex;
                -webkit-box-align: center;
                -webkit-align-items: center;
                -ms-flex-align: center;
                align-items: center;
              }
            }

            .ks-search-open {
              display: none;
            }
          }

          @media (max-width: 1225px) {
            right: 0;
          }
        }

        .ks-search-form {
          padding: 0 30px;

          @media(max-width: 1500px) {
            height: 90px;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            -js-display: flex;
            display: flex;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            margin: 0;
            padding: 0 20px;
          }

          @media (max-width: 1225px) {
            position: static;
            padding: 10px 5px;
            border-bottom: 1px solid #dee0e1;
            height: auto;
            width: 100%;
          }

          & > .ks-wrapper {
            @media (max-width: 1300px) {
              display: none;
            }

            @media (max-width: 1225px) {
              width: 100%;
              display: -webkit-box;
              display: -webkit-flex;
              display: -ms-flexbox;
              -js-display: flex;
              display: flex;
              -webkit-box-align: center;
              -webkit-align-items: center;
              -ms-flex-align: center;
              align-items: center;
            }

            .input-icon {
              @media (max-width: 1225px) {
                width: 100%;
              }
            }
          }

          .ks-search-close,
          .ks-search-open {
            display: none;
            width: 20px;
            height: 20px;

            @media (max-width: 1300px) {
              display: inline-block;
              font-size: 20px;
              position: relative;
              top: 1px;
            }

            @media (max-width: 1225px) {
              display: none;
            }
          }

          .ks-search-close {
            display: none;
            margin-left: 20px;

            @media (max-width: 1300px) {
              display: block;
            }

            @media (max-width: 1225px) {
              display: none;
            }
          }

          .form-control {
            width: 430px;
            height: 45px;
            border: 1px solid #212121;
            border-radius: 12px;
            background: #333333;
            font-size: 13px;
            color: #adadad;
            padding-left: 35px;
            transition: all .3s;

            @media (max-width: 1225px) {
              width: 100%;
            }

            &:focus {
              -webkit-box-shadow: none;
              -moz-box-shadow: none;
              box-shadow: none;
              border: 1px solid #adadad;
            }
          }

          .icon-addon {
            position: absolute;
            padding: 0;
            top: 0;
            left: 0;
            padding-right: 6px;
            border: 0;
            background: none;
            cursor: pointer;
            color: rgba(58,82,155,.6);
            font-size: 12px;
            width: 36px;
            height: 100%;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            -js-display: flex;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            justify-content: center;

            .icon {
              opacity: 0.5;
              -webkit-background-size: 100% auto;
              -moz-background-size: 100% auto;
              background-size: 100% auto;
              transition: all .3s;
            }

            &:hover {
              .icon {
                opacity: 1;
              }
            }
          }
        }
      }

      .ks-navbar-actions {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        -js-display: flex;
        display: flex;
        padding-right: 30px;

        @media (max-width: 1225px) {
          padding-right: 0;
          -webkit-justify-content: flex-end;
          -ms-flex-pack: end;
          justify-content: flex-end;
          -webkit-flex: 1;
          -ms-flex: 1;
          flex: 1;
        }

        @media (max-width: 768px) {
          position: fixed;
          right: -230px;
          height: 100%;
          z-index: 6;
          width: 230px;
          top: 60px;
          background: #ededed;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          -js-display: flex;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -webkit-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-pack: start;
          -webkit-justify-content: flex-start;
          -ms-flex-pack: start;
          justify-content: flex-start;
          -webkit-transition: all .3s;
          -moz-transition: all .3s;
          transition: all .3s;
        }

        &.ks-open {
          right: 0;
        }

        .nav-item {
          padding: 0;
          -webkit-transition: all .3s;
          -moz-transition: all .3s;
          transition: all .3s;

          &.show {
            background: #333333;

            @media (max-width: 768px) {
              background: none;
            }
          }

          &.ks-languages {
            .nav-link {
              & > .ks-text {
                display: none;

                @media (max-width: 768px) {
                  display: block;
                  margin-left: 27px;
                  -webkit-box-flex: 1;
                  -webkit-flex: 1 1 auto;
                  -ms-flex: 1 1 auto;
                  flex: 1 1 auto;
                }
              }
            }
          }

          .nav-link {
            @media (max-width: 768px) {
              color: #333;
              border-bottom: 1px solid #dee0e1;
            }

            &.dropdown-toggle {
              &:after {
                @media (max-width: 768px) {
                  background: url(../img/icons/@2x/angle-down@2x.png) 0 0 no-repeat;
                  -webkit-background-size: 100% auto;
                  -moz-background-size: 100% auto;
                  background-size: 100% auto;
                  width: 9px;
                  height: 6px;
                  margin-left: 10px;
                  -moz-transition: all 0.3s;
                  -o-transition: all 0.3s;
                  -webkit-transition: all 0.3s;
                  transition: all 0.3s;
                }
              }
            }
          }
        }

        .ks-user {
          .dropdown-toggle {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            -js-display: flex;
            display: flex;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;

            .ks-avatar {
              width: 36px;
              height: 36px;
            }

            .ks-info {
              margin-left: 12px;

              @media (max-width: 1610px) {
                display: none;
              }

              @media (max-width: 768px) {
                -webkit-box-flex: 1;
                -webkit-flex: 1 1 auto;
                -ms-flex: 1 1 auto;
                flex: 1 1 auto;
                display: block;
              }

              .ks-name {
                display: block;
              }
            }
          }

          .dropdown-menu {
            right: 0;
            left: auto;
            font-size: 12px;
            padding: 0;
            min-width: 220px;
            overflow: visible;
            -webkit-border-radius: 0 0 2px 2px;
            -moz-border-radius: 0 0 2px 2px;
            border-radius: 0 0 2px 2px;
            width: 230px;
            border: 0;
            -webkit-box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24);
            -moz-box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24);
            box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24);

            .dropdown-item {
              margin: 0;
              border: none;
              height: 50px;
              display: -webkit-box;
              display: -webkit-flex;
              display: -ms-flexbox;
              -js-display: flex;
              display: flex;
              -webkit-box-align: center;
              -webkit-align-items: center;
              -ms-flex-align: center;
              align-items: center;
              line-height: 13px;
              font-weight: 400;
              padding: 0 24px 0 50px;
              position: relative;
              color: #333;
              border-bottom: 1px solid #e1e5f0;
              -webkit-transition: all .3s;
              -moz-transition: all .3s;
              transition: all .3s;

              .icon {
                left: 25px;
              }
            }
          }
        }

        .ks-languages {
          .dropdown-menu {
            width: 336px;
            min-width: 336px;
            height: 300px;
            margin: 0;
            right: 0;
            left: auto;
            padding: 0;
            display: none;

            &.show {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
            }

            .ks-wrapper {
              display: -webkit-box;
              display: -webkit-flex;
              display: -ms-flexbox;
              -js-display: flex;
              display: flex;
              -webkit-flex-wrap: wrap;
              -ms-flex-wrap: wrap;
              flex-wrap: wrap;

              .ks-language {
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                -js-display: flex;
                display: flex;
                -webkit-box-align: center;
                -webkit-align-items: center;
                -ms-flex-align: center;
                align-items: center;
                height: 50px;
                width: 167px;
                list-style: none;
                font-weight: 600;
                padding: 0 24px;
                border-bottom: 1px solid #dee0e1;
                border-right: 1px solid #dee0e1;
                transition: all .3s;

                &.ks-selected {
                  opacity: .6;
                }

                &:hover {
                  background: #f5f7fa;
                }

                .ks-text {
                  color: #333;
                  margin-left: 20px;
                  font-weight: 400;
                }
              }
            }
          }
        }
      }

      .ks-player {
        position: relative;
        width: 300px;
        margin-right: 60px;

        @media (max-width: 1350px) {
          margin-right: 20px;
        }

        @media (max-width: 768px) {
          width: 230px;
          margin-right: 0;
        }

        .inner-block {
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-flex-wrap: nowrap;
          -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
          -webkit-justify-content: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-align-content: stretch;
          -ms-flex-line-pack: stretch;
          align-content: stretch;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
          height: 90px;

          &.is-playing {
            .playtime-block {
              color: rgba(255,255,255,1);

              &:before {
                background: url("../img/icon-time--green.svg") 0 0 no-repeat;
                background-size: 100% auto;
                opacity: .8;
              }

              .value {
                opacity: 1;
              }
            }

            .trash-btn {
              display: inline-block;
            }
          }

          .playtime-block {
            position: relative;
            height: 45px;
            border-radius: 15px;
            color: rgba(255,255,255, 0.3);
            padding: 13px 25px 13px 45px;
            margin: 0 5px 0 15px;
            background: #333;
            transition: all .3s;

            @media (max-width: 768px) {
              margin: 0 0 0 5px;
              padding: 5px 5px 5px 25px;
              font-size: 11px;
              height: 30px;
            }

            &:before {
              position: absolute;
              top: 50%;
              left: 15px;
              background: url("../img/icon-time--white.svg") 0 0 no-repeat;
              width: 15px;
              height: 15px;
              background-size: 100% auto;
              content: "";
              opacity: .3;
              margin-top: -7.5px;
              transition: all .3s;

              @media (max-width: 768px) {
                left: 5px;
              }
            }

            .value {
              font-style: normal;
              margin: 0 3px;
              color: #fff;
              opacity: .3;
              transition: all .3s;
            }
          }

          .history-btn {
            position: relative;
            display: inline-block;
            width: 30px;
            height: 30px;
            padding: 0;
            background: #333;
            border-radius: 30px;
            box-shadow: none;
            -webkit-transition: all .3s;
               -moz-transition: all .3s;
                -ms-transition: all .3s;
                 -o-transition: all .3s;
                    transition: all .3s;
          }

          .trash-btn {
            position: relative;
            display: none;
            width: 30px;
            height: 30px;
            padding: 0;
            background: #333;
            border-radius: 30px;
            box-shadow: none;

            .icon {
              transition: all .3s;
            }

            &:hover {
              .icon {
                background: url(../img/icons/@2x/icon-delete--red@2x.png) 0 0 no-repeat;
                background-size: auto 100%;
              }
            }
          }
        }
      }

      .ks-notification {
        position: relative;
        width: 45px;
        margin-right: 15px;
        display: flex;
        align-items: center;

        @media (max-width: 768px) {
          width: 230px;
          margin-right: 0;
          padding: 0 15px !important;
        }

        &.show {
          background: inherit !important;
        }

        .nav-link {
          position: relative;
          width: 45px;
          height: 45px;
          background: #333333;
          border-radius: 50px;
          padding: 0;

          &.new-notification {
            animation: notification-box-shadow 0.4s;

            .icon {
              animation: notification-icon 0.6s;
            }

            .val {
              animation: notification-value 0.6s;
              animation-delay: 0.6s;
            }
          }


          &:after {
            display: none;
          }

          .icon {
            transform-origin: top;
          }

          .val {
            position: absolute;
            top: -1px;
            right: -2px;
            width: 15px;
            height: 15px;
            border-radius: 15px;
            background-color: #ff5722;
            font-size: 10px;
            color: #fff;
            font-weight: 600;
            line-height: 15px;
            text-align: center;
            transition: all .3s;
          }
        }

        @keyframes notification-box-shadow {
          0% {
            box-shadow: 0 0 0 0 #ff5722;
          }
          50% {
            box-shadow: 0 0 0 9px #ff5722;
          }
          100% {
            box-shadow: 0 0 0 0 #ff5722;
          }
        }

        @keyframes notification-icon {
          0% {
            transform: rotate(0);
          }
          50% {
            transform: rotate(-35deg);
          }
          75% {
            transform: rotate(20deg);
          }
          100% {
            transform: rotate(0);
          }
        }

        @keyframes notification-value {
          0% {
            transform: scale(0,0);
          }
          100% {
            transform: scale(1,1);
          }
        }

        .dropdown-menu {
          width: 390px;
          border-radius: 18px;
          box-shadow: 0 15px 60px 0 rgba(0, 0, 0, 0.16);
          background-color: #ffffff;
          // right: 0;
          // left: auto;
          padding: 0;
          border: 0;

          @media (max-width: 768px) {
            width: 290px;
            right: 0;
            left: auto;
          }

          .top {
            display: flex;
            justify-content: space-between;
            height: 45px;
            align-items: center;
            padding: 0 15px;
            border-bottom: 2px solid rgba(0, 0, 0, 0.03);

            .dropdown-title {
              position: relative;
              display: block;
              font-size: 11px;
              color: #666;
              font-weight: 600;
              padding-left: 23px;

              .icon {
                left: 0;
                margin-left: 0;
              }
            }

            .mark-all-read-link {
              display: block;
              font-size: 13px;
              font-weight: 600;
              color: rgba(0,0,0,0.75);
            }
          }

          .drp-content {
            .content-item {
              .item {
                position: relative;
                padding: 20px 30px 20px 15px;
                display: flex;
                transition: all .3s;

                &.new {
                  background-color: rgba(255, 193, 7, 0.05);
                }

                &.read {
                  &:hover {
                    background-color: rgba(0, 0, 0, 0.03);
                    .open-popover-link {
                      opacity: 1;
                      visibility: visible;
                    }
                  }
                }

                .logo {
                  flex: 1 0 30px;
                  margin-right: 15px;

                  img {
                    border-radius: 100px;
                  }
                }

                .info {
                  flex: 0 100%;
                }

                .first-line {
                  display: flex;

                  .name {
                    display: inline-block;
                    font-size: 13px;
                    color: #404040;
                    font-weight: 600;

                    &:hover {
                      opacity: 1;
                      text-decoration: underline;
                    }
                  }

                  span {
                    font-size: 13px;
                    color: #404040;
                    font-weight: 400;
                    font-style: italic;
                    padding-left: 5px;
                  }
                }

                .second-line {
                  position: relative;
                  margin-top: 7px;

                  .icon {
                    left: 0;
                    margin-left: 0;
                  }

                  .actions-link {
                    display: inline-block;
                    font-size: 13px;
                    color: #404040;
                    font-weight: 600;
                    padding-left: 23px;

                    &:hover {
                      opacity: 1;
                      text-decoration: underline;
                    }
                  }
                }

                .third-line {
                  position: relative;
                  margin-top: 7px;

                  .icon {
                    left: 0;
                    margin-left: 0;
                    opacity: 0.5;
                  }

                  .time {
                    display: block;
                    font-size: 13px;
                    color: #aaa;
                    padding-left: 23px;
                  }
                }
              }
            }
            .content-title {
              display: flex;
              font-size: 11px;
              font-weight: 600;
              color: #666;
              text-transform: uppercase;
              align-items: center;
              padding: 0 15px;
            }

            .open-popover-link {
              position: absolute;
              top: 15px;
              right: 0;
              width: 30px;
              height: 30px;
              flex: 0 auto;
              opacity: 0;
              visibility: hidden;
              transition: all .3s;

              .open-popover {
                width: 30px;
                height: 30px;
                float: right;
              }

              .more-options {
                position: relative;
                display: block;
                height: 30px;
                width: 30px;
                cursor: pointer;
                float: right;
                border-radius: 15px;
                transition: all .3s;

                &:hover {
                  background-color: rgba(255, 193, 7, 0.05);
                }

                .icon {
                  height: 15px;
                  margin-top: -7.5px;
                  transition: all .3s;
                }
              }
            }
          }

          .bottom-block {
            position: relative;
            border-top: 2px solid rgba(0, 0, 0, 0.03);            

            .show-more {
              display: block;
              font-weight: 700;
              color: #000;
              background: none;
              border: 0;
              width: 100%;
              padding: 12.5px 15px;
              -webkit-transition: all .3s;
              -o-transition: all .3s;
              transition: all .3s;
              text-transform: uppercase;
              margin: 0;
              -webkit-border-radius: 0;
              border-radius: 0;
              -webkit-border-radius: 0 0 15px 15px;
              border-radius: 0 0 15px 15px;
              text-align: center;

              &:hover {
                opacity: 1;
                background: rgba(0,0,0,0.2);
              }

              span {
                position: relative;

                &:before {
                  position: absolute;
                  top: 50%;
                  right: -25px;
                  background: url(../img/icons/@2x/angle-right@2x.png) 0 0 no-repeat;
                  width: 9px;
                  height: 9px;
                  -webkit-background-size: auto 100%;
                  background-size: auto 100%;
                  margin-top: -5.5px;
                  content: "";
                }
              }
            }
          }
        }
      }
    }

    .ks-navbar-actions-toggle,
    .ks-navbar-menu-toggle {
      width: auto;
      display: none;
      height: 60px;

      a {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        -js-display: flex;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        border: none;
        border-left: 1px solid #333;
      }
    }
    
    .ks-navbar-actions-toggle {
      @media (max-width: 768px) {
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        display: block;
        text-align: center;
        height: 60px;
      }

      a {
        .icon-more {
          width: 24px;
          height: 24px;
          background: url(../img/more.png) 0 0 no-repeat;
          -webkit-background-size: 100% auto;
          -moz-background-size: 100% auto;
          background-size: 100% auto;
        }

        .icon-clear {
          display: none;
          position: relative;
          top: 0;
          margin-top: 0;
          width: 24px;
          -webkit-background-size: auto 100%;
          -moz-background-size: auto 100%;
          background-size: auto 100%;
          background-position-x: 50%;
        }
      }
    }
    .ks-navbar-menu-toggle {
      @media (max-width: 1225px) {
        height: 90px;
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        display: block;
        text-align: center;
      }

      @media (max-width: 768px) {
        height: 60px;
      }

      a {
        .icon-right-menu {
          width: 24px;
          height: 24px;
          background: url(../img/menu.png) 0 0 no-repeat;
          -webkit-background-size: 100% auto;
          -moz-background-size: 100% auto;
          background-size: 100% auto;
        }

        .icon-clear {
          display: none;
          position: relative;
          top: 0;
          margin-top: 0;
          width: 24px;
          -webkit-background-size: auto 100%;
          -moz-background-size: auto 100%;
          background-size: auto 100%;
          background-position-x: 50%;
        }
      }
    }
  }
 .header-activity-status-toggle {
    display: flex;
    position: relative;
    align-items: center;
    padding: 0 30px;

    .current-activity-status {
      color: white;
    }

    .toggle-activity-status-link {
      color: darkgrey;
    }
  }

  .create-new-block {
    display: flex;
    position: relative;
    align-items: center;
  }
}

.ks-navbar {
  .nav-link {
    margin: 0;
    font-size: 13px;
    font-weight: 600;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 20px;
    height: 90px;
    color: #fff;

    @media (max-width: 768px) {
      height: 60px;
    }
  }

  @keyframes menu_Normal_To_Ham {
    0% {
      background-position: 0px 0px;
    }
    100% {
      background-position: -2160px 0px;
    }
  }

  @keyframes menu_Ham_To_Normal {
    0% {
      background-position: -2160px 0px;
    }
    100% {
      background-position: 0px 0px;
    }
  }

  @keyframes menu_Ham_To_Close {
    0% {
      background-position: -5400px 0px;
    }
    100% {
      background-position: -7560px 0px;
    }
  }

  @keyframes menu_Close_To_CloseHover {
    0% {
      background-position: -10800px 0px;
    }
    100% {
      background-position: -12960px 0px;
    }
  }

  @keyframes menu_CloseHover_To_Close {
    0% {
      background-position: -12960px 0px;
    }
    100% {
      background-position: -10800px 0px;
    }
  }

  @keyframes menu_CloseHover_To_Normal {
    0% {
      background-position: -16200px 0px;
    }
    100% {
      background-position: -18360px 0px;
    }
  }

  .menu-btn {
    background-repeat: no-repeat;
    background-image: url(../img/menu_sprite_anim.svg);

    animation-duration: 300ms;
    animation-direction: normal;
    animation-fill-mode: forwards;


    animation-timing-function: steps(24);

    width: 90px;
    height: 90px;
    cursor: pointer;
  }

  .menu-close-btn {
    display: none;

    background-repeat: no-repeat;
    background-image: url(../img/menu_sprite_anim.svg);
    background-position: -5400px 0;

    animation-duration: 300ms;
    animation-direction: normal;
    animation-fill-mode: forwards;

    animation-timing-function: steps(24);

    width: 90px;
    height: 90px;
    cursor: pointer;
  }

  body{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #212121;
  }
}

.ks-avatar {
  border-radius: 100px;

  &>img {
    border-radius: 100px;

    &:focus {
      border: 1px solid #fff;
    }
  }
}

.show {
  &>.dropdown-menu {
    position: absolute;
  }
  &>.dropdown-toggle::after {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg)
  }
}

.dropdown-toggle::after {
  border: 0;
  background: url('../img/icons/@2x/angle-down--white@2x.png') 0 0 no-repeat;
  -webkit-background-size: 100% auto;
  -moz-background-size: 100% auto;
  background-size: 100% auto;
  width: 9px;
  height: 6px;
  margin-left: 10px;
  transition: all .3s;
}

