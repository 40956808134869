.case-description {
	.profile-information {
		.information-block {
		  width: 25%;
	    min-width: 330px;
	    background: #fff;
	    -webkit-transition: all .3s;
	    -o-transition: all .3s;
	    transition: all .3s;
	    position: relative;
	    -webkit-border-radius: 0 0 0 15px;
	    border-radius: 0 0 0 15px;

	    &.show {
	    	left: 0;
	    }

	    @media (max-width: 1199px) {
	    	position: absolute;
		    top: 0;
		    left: -400px;
		    bottom: 0;
		    min-height: 900px;
		    z-index: 5;
		    border-right: 2px solid #f2f2f2;
		    box-shadow: 0 6px 20px 0px rgba(0,0,0,0.25);
	    }

	    @media (max-width: 768px) {
	    	min-width: 295px;
	    }

	    .ks-close {
  	    display: none;
		    position: absolute;
		    top: 0;
		    right: 0;
		    width: 20px;
		    height: 20px;
		    z-index: 1;

		    @media (max-width: 1199px) {
		    	display: inline-block;
		    }
	    }

	    .card {
	    	border: 0;
	    	-webkit-border-radius: 0;
	    	-moz-border-radius: 0;
	    	border-radius: 0;

	    	&.assigned {
	    		.card-header {
	    			&:before {
	    				border-bottom: 2px solid #673ab7;
	    			}

	    			.btn {
	    				&:hover {
	    					background: #673ab7;
	    				}
	    			}
	    		}
	    	}

	    	&.case-information {
	    		.card-header {
	    			&:before {
	    				border-bottom: 2px solid #f2b707;
	    			}
	    			.btn {
	    				&:hover {
	    					background: #f2b707;
	    				}
	    			}
	    		}
	    	}

	    	&.client-information {
	    		.card-header {
	    			&:before {
	    				border-bottom: 2px solid #4caf50;
	    			}
	    			.btn {
	    				&:hover {
	    					background: #4caf50;
	    				}
	    			}
	    		}
	    	}

	    	&.deadline {
	    		.card-header {
	    			&:before {
	    				border-bottom: 2px solid #ff5722;
	    			}
	    			.btn {
	    				background: #ff5722;

	    				&:before {
  					    position: absolute;
						    top: 50%;
						    left: 50%;
						    background: url(../img/icons/@2x/icon-plus--white@2x.png) 0 0 no-repeat;
						    width: 13px;
						    height: 13px;
						    -webkit-background-size: 100% auto;
						    background-size: 100% auto;
						    margin-top: -6.5px;
						    margin-left: -7.5px;
						    content: "";
						    -webkit-transition: all .3s;
						    -o-transition: all .3s;
						    transition: all .3s;
	    				}
	    			}
	    		}
	    	}
	    }

	    .card-header {
	    	position: relative;
	    	background: none;
	    	-webkit-border-radius: 0;
	    	-moz-border-radius: 0;
	    	border-radius: 0;
	    	padding: 20px 20px 20px 60px;
	    	height: 60px;
	    	border: 0;

	    	&:before {
	    		position: absolute;
	    		left: 20px;
	    		right: 20px;
	    		bottom: 0;
	    		content: "";
	    	}

	    	.ks-text {
  		    display: inline-block;
			    margin: 0;
			    padding: 0;
			    color: #000;
			    font-size: 16px;
			    font-weight: 600;
			    line-height: 1.1em;
	    	}

	    	& > .icon {
	    		left: 30px;
	    		margin-left: 0;
	    	}

	    	.btn {
	    		position: relative;
	    		top: -6px;
	    		float: right;
	    		width: 30px;
	    		height: 30px;
	    		padding: 0;
	    		-webkit-border-radius: 100px;
	    		-moz-border-radius: 100px;
	    		border-radius: 100px;
	    		background: none;
	    		opacity: 1;
	    		-webkit-transition: all .3s;
	    		   -moz-transition: all .3s;
	    		    -ms-transition: all .3s;
	    		     -o-transition: all .3s;
	    		        transition: all .3s;

	    		&:hover {
	    			opacity: 0.8;
	    		}


	    		&.edit {
	    			&:before {
	    				display: none;
	    			}
	    		}

	    		&.open-block {
	    			&:before {
	    				-webkit-transform: rotate(45deg);
	    				   -moz-transform: rotate(45deg);
	    				    -ms-transform: rotate(45deg);
	    				     -o-transform: rotate(45deg);
	    				        transform: rotate(45deg);
	    			}
	    		}

	    		// .icon {
	    		// 	margin-top: -6.5px;
	    		// 	margin-left: -6.5px;
	    		// }
	    	}
	    }

	    .card-block {
	    	border-bottom: 2px solid #f2f2f2;

	    	blockquote {
	    		position: relative;
	    		margin: 1.42857em 30px;
	    	}

	    	.empty {
	    		min-height: 240px;
	    		margin-top: 40px;

	    		@media (max-width: 768px) {
	    			min-height: 100px;
	    		}

	    		.empty-block {
	    			position: relative;
	    			padding-left: 45px;

	    			.icon {
	    				left: 0;
	    				margin-left: 0;
	    				opacity: .2;
    					width: 30px;
    					height: 30px;
    					margin-top: -15px;
	    			}

	    			.ks-text {
	    				display: block;
	    				font-size: 13px;
	    				color: #999;

	    				.block {
	    					display: block;
	    				}

	    				.btn {
	    					position: relative;
	    					width: 15px;
	    					height: 15px;
	    					margin: 0 5px;
	    					border-radius: 15px;
	    					padding: 0;
	    					cursor: auto;

	    					&:before {
	    						position: absolute;
							    top: 50%;
							    left: 50%;
							    background: url(../img/icons/@2x/icon-plus--white@2x.png) 0 0 no-repeat;
							    width: 7px;
							    height: 7px;
							    -webkit-background-size: 100% auto;
							    background-size: 100% auto;
							    margin-top: -3.5px;
							    margin-left: -3.5px;
							    content: "";
							    -webkit-transition: all .3s;
							    -o-transition: all .3s;
							    transition: all .3s;
	    					}

	    					&.btn-deadline {
	    						background: #ff5722;
	    					}

	    					&.btn-assigned {
	    						background: #673ab7;
	    					}

	    					&.btn-representative {
	    						background: #4caf50;
	    					}
	    				}
	    			}
	    		}
	    	}

	    	.ks-clear {
	    		position: absolute;
	    		top: 0;
	    		right: 0;;
	    	}
	    }

	    .case-information {
	    	.card-blockquote {
	    		.info-group{
	    			&:after{
						content:'';
						display:block;
						clear:both;
			    	}
	    		}
	    		.info-title {
			    	display: inline-block;
			    	float:left;
			    	font-size: 11px;
			    	font-weight: 600;
			    	color: #666666;
			    	text-transform: uppercase;
			    	margin: 10px 0;
			    	width:34%;
			    }
			    .info-content {
			    	margin: 10px 0;
			    	float:left;
			    	display:block;
					width:66%;
			   //  	&:after{
						// content:'';
						// display:block;
						// clear:both;
			   //  	}
			    }
	    	}

	    	.case-description-link {
	    		display: block;
			    text-align: center;
			    padding: 10px 0;
			    margin: 0 10px;
			    border-top: 1px solid #f2f2f2;
			    text-transform: uppercase;
			    color: #4caf50;
			    font-weight: 700;
			    font-size: 13px;

			    &:hover {
			    	color: #f2b707;
			    }
	    	}
	    }
	    .client-information {
	    	.card-blockquote {
	    		.user {
	    			display: table;
	    			height: 45px;
	    			width: 100%;
	    			margin-top: 30px;

	    			&:first-child {
	    				margin-top: 0;
	    			}

	    			.avatar {
	    				width: 45px;

	    				img {
	    					width: 100%;
	    					-webkit-border-radius: 100px;
	    					-moz-border-radius: 100px;
	    					border-radius: 100px;
	    				}
	    			}

	    			.name {
					    font-size: 13px;
					    color: #c5c5c5;
					    padding-left: 15px;

	    				b {
  					    display: block;
						    font-weight: 600;
						    color: #000;
	    				}
	    			}

	    			.avatar,
	    			.name {
	    				display: table-cell;
	    				vertical-align: middle;
	    			}
	    		}

	    		.info-title {
			    	display: block;
			    	font-size: 11px;
			    	font-weight: 600;
			    	color: #666666;
			    	text-transform: uppercase;
			    	margin: 30px 0 10px;
			    }

			    .info-content {
			    	margin-bottom: 10px;
			    }

			    .open-popover {
		    		display: inline-block;
		    		position: relative;
		    		padding: 12.5px 15px 12.5px 40px;
		    		border: 1px solid #e6e6e6;
		    		-webkit-border-radius: 15px;
		    		-moz-border-radius: 15px;
		    		border-radius: 15px;
		    		cursor: pointer;

		    		.icon {
		    			left: 15px;
		    			margin-left: 0;

		    			&.icon-mobile,
		    			&.icon-work,
		    			&.icon-home,
		    			&.icon-phone {
						    width: 15px;
		    				height: 15px;
		    				margin-top: -7.5px;
		    			}

		    			&.icon-mobile {

		    			}

		    			&.icon-message {
		    				margin-top: -5.5px;
		    			}
		    		}
		    	}
	    	}
	    }

	    .deadline {
	    	.card-blockquote {
	    		.top {
	    			position: relative;
	    			padding-left: 25px;

	    			.icon {
	    				left: 0;
	    				margin-left: 0;
	    			}

	    			.ks-text {
					    display: block;
					    font-size: 13px;
					    color: #c5c5c5;
					    margin-bottom: 5px;
					    line-height: 15px;

					    .priority {
					    	position: relative;
					    	font-weight: 600;
					    	padding-left: 10px;
					    	margin-left: 10px;

					    	&:before {
					    		position: absolute;
					    		top: 50%;
					    		left: 0;
					    		width: 5px;
					    		background: #c5c5c5;
					    		height: 2px;
					    		margin-top: -1px;
					    		content: "";
					    	}

					    	&.low {
					    		color: #4caf50;
					    	}

					    	&.high {
					    		color: #ff5722;
					    	}

					    	&.normal {
					    		color: #ff9800;
					    	}
					    }
					  }
	    		}

	    		.card-link {
	    			display: block;
	    			font-weight: 600;
	    			color: #000;
	    			margin-top: 10px;

	    			&:hover {
	    				color: #ff5722;
	    			}
	    		}

	    		p {
	    			font-family: $font-open-sans;
				    font-style: italic;
    				font-size: 14px;
    				margin: 5px 0 30px;
	    		}

	    		.deadline-link {
						position: relative;
						display: block;
						padding-left: 23px;
						font-size: 16px;
					  font-weight: 600;
					  letter-spacing: 0.4px;
					  text-align: left;
					  color: #000;
					  margin-bottom: 7px;

					  &:before {
					  	position: absolute;
					  	top: 50%;
					  	left: 0;
					  	content: "";
					  	background: url(../img/icons/@2x/icon-time@2x.png) 0 0 no-repeat;
					  	background-size: 100% auto;
					  	width: 16px;
					  	height: 16px;
					  	margin-top: -8px;
					  	filter: contrast(0);
					  }

					  &.today {
					  	&:before {
					  		filter: contrast(100%);
					  		background: url(../img/icons/@2x/icon-time-accent@2x.png) 0 0 no-repeat;
					  		background-size: 100% auto;
					  		width: 15px;
					  		height: 15px;
					  		margin-top: -7.5px;
					  	}
					  }
					}

					.deadline-info {
						display: block;
						font-size: 13px;
					  text-align: left;
					  color: #333;
					}
	    	}

	    	.show-more-deadlines {
	    		display: block;
	    		text-align: center;
	    		padding: 10px 0;
	    		margin: 0 10px;
	    		border-top: 1px solid #f2f2f2;
	    		text-transform: uppercase;
	    		color: #333;
	    		font-weight: 700;
	    		font-size: 13px;

	    		&:hover {
	    			color: #ff5722;
	    		}
	    	}
	    }

	    .assigned {
	    	.card-blockquote {
	    		.top {
	    			display: table;
	    			height: 45px;
	    			width: 100%;

	    			.avatar {
	    				width: 45px;

	    				img {
	    					width: 100%;
	    					-webkit-border-radius: 100px;
	    					-moz-border-radius: 100px;
	    					border-radius: 100px;
	    				}
	    			}

	    			.name {
					    font-size: 13px;
					    color: #c5c5c5;
					    padding-left: 15px;

	    				b {
  					    display: block;
						    font-weight: 600;
						    color: #000;
	    				}
	    			}

	    			.avatar,
	    			.name {
	    				display: table-cell;
	    				vertical-align: middle;
	    			}
	    		}

	    		.card-information {
	    			padding-top: 20px;
	    			padding-bottom: 10px;

	    			.icon {
	    				left: 15px;
	    				margin-left: 0;
	    				opacity: .6;

	    				&.icon-phone {
	    					width: 15px;
						    height: 15px;
						    margin-top: -7.5px;
	    				}
	    			}

	    			&>div {
	    				position: relative;
	    				margin-top: 10px;
	    				padding-left: 40px;
	    				color: #939393;
	    				font-size: 13px;
	    				cursor: pointer;

	    				&:first-child {
	    					margin-top: 0;
	    				}
	    			}
	    		}
	    	}
	    }
		}

		.status-block {
			.block-title {
				@media (max-width: 576px) {
					padding-right: 5px;
				}
			}
			.block-content {
				padding-bottom: 0;
			}

			.close-case-link {
				display: block;
		    text-align: center;
		    padding: 18px 0;
		    text-transform: uppercase;
		    color: #ff5722;
		    font-weight: 700;
		    font-size: 13px;
		    width: 100%;
		    border-top: 2px solid #f2f2f2;

		    .icon {
		    	display: inline-block;
		    	position: relative;
		    	left: auto;
		    	margin-left: 0;
		    	margin-right: 10px;
		    	top: 3px;
		    }
			}
		}

		.chart-block {
			.block-content {
				padding: 30px;

				@media (max-width: 768px) {
					padding: 10px;
				}
			}

			.head {
				display: -ms-flexbox;
		    display: -webkit-flex;
		    display: flex;
		    -webkit-flex-direction: row;
		    -ms-flex-direction: row;
		    flex-direction: row;
		    -webkit-flex-wrap: nowrap;
		    -ms-flex-wrap: nowrap;
		    flex-wrap: nowrap;
		    -webkit-justify-content: flex-start;
		    -ms-flex-pack: start;
		    justify-content: flex-start;
		    -webkit-align-content: stretch;
		    -ms-flex-line-pack: stretch;
		    align-content: stretch;
		    -webkit-align-items: flex-start;
		    -ms-flex-align: start;
		    align-items: flex-start;
		    margin-bottom: 50px;

		    @media (max-width: 768px) {
		    	flex-wrap: wrap;
		    	justify-content: center;
		    }

		    .legend-block {
		    	flex: 1 0 auto;
		    	display: -ms-flexbox;
			    display: -webkit-flex;
			    display: flex;
			    -webkit-flex-direction: row;
			    -ms-flex-direction: row;
			    flex-direction: row;
			    -webkit-flex-wrap: nowrap;
			    -ms-flex-wrap: nowrap;
			    flex-wrap: nowrap;
			    -webkit-justify-content: flex-start;
			    -ms-flex-pack: start;
			    justify-content: flex-start;
			    -webkit-align-content: stretch;
			    -ms-flex-line-pack: stretch;
			    align-content: stretch;
			    -webkit-align-items: flex-start;
			    -ms-flex-align: start;
			    align-items: flex-start;

			    @media (max-width: 768px) {
		    		flex: 1 100%;
		    		margin-bottom: 15px;
		    	}

		    	@media (max-width: 576px) {
		    		flex-wrap: wrap;
		    	}

			    .block {
			    	display: table;
			    	height: 45px;
			    	vertical-align: middle;
			    	margin-left: 50px;

			    	@media (max-width: 1280px) {
			    		margin-left: 20px;
			    	}

			    	@media (max-width: 992px) {
			    		margin-left: 10px;
			    	}

			    	@media (max-width: 768px) {
			    		flex: 1 auto;
			    		margin: 0;
			    	}

			    	@media (max-width: 576px) {
			    		flex: 1 50%;
			    	}


			    	&.remaining {
			    		.title,
			    		.value {
			    			color: #ff9800;
			    		}
			    	}

			    	&.completed {
			    		.title,
			    		.value {
			    			color: #4caf50;
			    		}
			    	}

			    	&.overdue {
			    		.title,
			    		.value {
			    			color: #2196f3;
			    		}
			    	}

			    	&.deadlines {
			    		.title,
			    		.value {
			    			color: #ff5722;
			    		}
			    	}

			    	&:first-child {
			    		margin-left: 0;
			    	}

			    	.title,
			    	.value {
			    		display: table-cell;
			    		vertical-align: middle;
			    	}

			    	.value {
			    		font-size: 30px;
			    		line-height: 1em;
			    	}

			    	.title {
			    		font-size: 13px;
					    font-weight: 400;
					    color: #666666;
					    line-height: 1em;
			    		padding-left: 10px;

			    		b {
			    			display: block;
			    			font-weight: normal;
			    		}
			    	}
			    }
		    }

		    .select-block {
		    	flex: 0 180px;
		    	order: 1;

		    	@media (max-width: 768px) {
		    		flex: 1 100%;
		    		max-width: 180px;
		    	}
		    }
			}

			.chart {
				position: relative;
			  margin: auto;
			  height: auto;
			  width: 57vw;

			  @media (max-width: 576px) {
			  	width: 79vw;
			  }
			}
		}

		.timesheet-block {
			.block-content {
				padding: 0;
			}
			.info-title {
	    	display: block;
	    	font-size: 11px;
	    	font-weight: 600;
	    	color: #666666;
	    	text-transform: uppercase;
	    	margin: 10px 0;
	    }
	    .info-content {
	    	margin-bottom: 10px;
	    }

	    .total-row {
	    	padding: 30px 30px 15px;

	    	@media (max-width: 768px) {
	    		margin: 0;
	    		padding: 15px 15px;
	    	}
	    }

	    .budget-total-row {
	    	padding: 15px 30px 45px;

	    	@media (max-width: 768px) {
	    		margin: 0;
	    		padding: 15px 15px;
	    	}

	    	.progress::-webkit-progress-bar {
			    background-color: #f7f7f7;
			    border-radius: 15px;
				}

				.progress::-moz-progress-bar { 
				  background-color: #4caf50;
				  border-radius: 15px;
				}

				.progress::-webkit-progress-value {
				  background-color: #4caf50;
				  border-radius: 15px;
				}

				.progress {
				  background-color: #4caf50;	
				  display: block;
				  height: 15px;
				  font-size: 0;
				  border-radius: 15px;
				  width: 100%;
				  border: 1px solid #e6e6e6;
				}
	    }

	    .attorney-row {
	    	border-top: 2px solid #ececec;
	    	padding: 0 30px;

	    	@media (max-width: 768px) {
	    		margin: 0;
	    		padding: 0;
	    	}

	    	.table-responsive {
	    		@media (max-width: 768px) {
	    			width: 80vw;
	    			margin: 0 auto;
	    		}

	    		@media (max-width: 567px) {
	    			width: 75vw;
	    		}
	    	}

	    	.table {
	    		min-width: 650px;
	    		thead {
	    			th {
	    				font-size: 11px;
				    	font-weight: 400;
				    	color: #666666;
				    	text-transform: uppercase;
				    	border: 0;
				    	background: none;
				    	border-bottom: 2px solid #ececec;
	    			}
	    		}

	    		tbody {
	    			td {
	    				border-bottom: 2px solid #ececec;

	    				.info-content {
	    					font-family: "Montserrat",sans-serif;
						    font-size: 14px;
						    line-height: 20px;
						    color: #333;
						    margin: 0;
	    				}

					    &:last-child {
					    	width: 210px;
					    }
	    			}
	    		}
	    	}

	    	.person-block {
  				-webkit-flex: 1 1 auto;
			    -ms-flex: 1 1 auto;
			    flex: 1 1 auto;
			    -webkit-align-self: auto;
			    -ms-flex-item-align: auto;
			    align-self: auto;

  				@media (max-width: 576px) {
  					-webkit-flex: 1 1 100%;
				    -ms-flex: 1 1 100%;
				    flex: 1 1 100%;
  				}

  				.inner {
  					display: table;
				    height: 45px;
				    width: 100%;
				    margin: 0;
				    padding: 0;

				    .avatar,
				    .name {
				    	display: table-cell;
				    	vertical-align: middle;
				    }

				    .avatar {
				    	width: 45px;

				    	img {
				    		width: 100%;
						    border-radius: 100px;
				    	}
				    }

				    .name {
		    	    font-size: 13px;
					    color: #a3a3a3;
					    padding-left: 15px;

					    b {
			    	    display: block;
						    font-weight: 600;
						    color: #000;
					    }
				    }
  				}
  			}

  			.options-block {
					display: -ms-flexbox;
			    display: -webkit-flex;
			    display: flex;
			    -webkit-flex-direction: row;
			    -ms-flex-direction: row;
			    flex-direction: row;
			    -webkit-flex-wrap: nowrap;
			    -ms-flex-wrap: nowrap;
			    flex-wrap: nowrap;
			    -webkit-justify-content: flex-start;
			    -ms-flex-pack: start;
			    justify-content: flex-start;
			    -webkit-align-content: stretch;
			    -ms-flex-line-pack: stretch;
			    align-content: stretch;
			    -webkit-align-items: center;
			    -ms-flex-align: center;
			    align-items: center;
				}

				.btn-edit {
			    -webkit-flex: 1 1 auto;
			    -ms-flex: 1 1 auto;
			    -webkit-box-flex: 1;
			    flex: 1 1 auto;
			    position: relative;
			    padding: 10px 15px 10px 10px;
			    text-align: center;
			    background: none;
			    text-transform: uppercase;
			    border-radius: 15px;
			    transition: all .3s;
			    display: -ms-flexbox;
			    display: -webkit-flex;
			    display: -webkit-box;
			    display: flex;
			    -webkit-flex-direction: row;
			    -ms-flex-direction: row;
			    -webkit-box-orient: horizontal;
			    -webkit-box-direction: normal;
			    flex-direction: row;
			    -webkit-flex-wrap: nowrap;
			    -ms-flex-wrap: nowrap;
			    flex-wrap: nowrap;
			    -webkit-justify-content: center;
			    -ms-flex-pack: center;
			    -webkit-box-pack: center;
			    justify-content: center;
			    -webkit-align-content: stretch;
			    -ms-flex-line-pack: stretch;
			    align-content: stretch;
			    -webkit-align-items: center;
			    -ms-flex-align: center;
			    -webkit-box-align: center;
			    align-items: center;
			    flex: 0 85px;
			    font-size: 13px;
			    font-weight: 600;
			    box-shadow: none;

			    .icon {
	    	    left: 0;
				    margin-left: 0;
				    transition: all .3s;
			    }

			    .ks-text {
			    	color: #000;
			    	transition: all .3s;
			    	line-height: 1.5em;
			    }

			    &:hover {
			    	background: #f2fff3;

			    	.icon {
			    		background: url(../img/icons/@2x/icon-edit--green@2x.png) 0 0 no-repeat;
					    -webkit-background-size: auto 100%;
					    background-size: auto 100%;
			    	}

			    	.ks-text {
			    		color: #4caf50;
			    	}
			    }
				}

				.btn-remove {
			    position: relative;
			    display: inline-block;
			    padding: 12px;
			    height: 45px;
			    flex: 1 auto;
			    background: none;
			    border-radius: 15px;
			    flex: 0 100px;
			    box-shadow: none;
			    margin-left: 10px;

			    .icon {
	    	    left: 0;
				    margin-left: 0;
				    transition: all .3s;
			    }

			    .ks-text {
			    	color: #000;
			    	transition: all .3s;
			    	line-height: 1.5em;
			    }

			    &:hover {
			    	background: #f2fff3;

			    	.icon {
	    		    background: url(../img/icons/@2x/icon-minus--red@2x.png) 0 0 no-repeat;
					    -webkit-background-size: auto 100%;
					    background-size: auto 100%;
			    	}

			    	.ks-text {
			    		color: #ff5722;
			    	}
			    }
				}

	    	.show-all-link {
  		    display: block;
			    text-align: center;
			    padding: 18px 0;
			    text-transform: uppercase;
			    color: #4caf50;
			    font-weight: 700;
			    font-size: 13px;
			    width: 100%;
	    	}
	    }
		}

		.dashboard-block {
			margin-top: 30px;

			.card {
				margin-top: 0;
			}
		}
	}
}


.page-case-description.modal-open {
	.daterangepicker{
		z-index: 1050121;
	}
}