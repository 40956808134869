.ks-contacts .content .pane-content.profile-content .contact-content {
	height: -webkit-calc(100% - 60px);
	height: -moz-calc(100% - 60px);
	height: calc(100% - 60px);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}

.ks-contacts .content .pane-content.profile-content .ks-page-content {
	min-height: 650px;
	overflow-y: auto;

	@media (max-width: 768px) {
		padding: 0 5px 10px;
	}
}

.profile-content {
	.contact-content {
		.table {
			.checkbox-cell {
				width: 100px;
				max-width: 100px;
				min-width: 100px;
			}

			.case-name-cell {
				max-width: 200px;
				min-width: 180px;
			}

			.ref-number-cell {
				max-width: 190px;
				min-width: 165px;
			}

			.status-cell {
				max-width: 180px;
				min-width: 150px;
			}

			.options-cell,
			.close-date-cell,
			.open-date-cell {
				max-width: 135px;
				min-width: 135px;
				width: 135px;
			}

			.assigned-cell {
				max-width: 200px;
				min-width: 180px !important;
			}

			.practice-area-cell {
				max-width: 150px;
				min-width: 150px;
			}
		}
	}
}

.ks-contacts {
	.content {
		.pane-content {
			&.profile-content {
				.contact-content {
					.table {
						.checkbox-cell {
							width: 100px;
							max-width: 100px;
							min-width: 100px;
						}

						tbody {
							.row-checked {
								td {
									span {
										// color: #000;
									}
								}
							}

							td {
								span {
									&.bold {
										font-weight: 600;
									}

									&.black {
										color: #000;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.empty-block-cases {
		position: relative;
		max-width:360px;
		margin: 94px auto 0;
		padding-left:45px;
		min-height: 300px;

		.icon {
			top: 12px;
	    background-size: 100% auto;
	    left: 0;
	    width: 30px;
	    height: 30px;
	    opacity: 0.4;
		}

		.ks-text {
			font-size: 13px;
			opacity: 0.4;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.54;
			letter-spacing: normal;
			text-align: left;
			color: #000000;
			float:left;
		}

		.cases_button{
			width:105px;
			margin-top:6px;
			display:block;
			float:right;
			padding-left:0px;
			padding-right:0px;
			padding-top:7px;
			padding-bottom:7px;
			font-size: 12px;
			line-height:16px;
			border-radius: 12px;
			font-weight: 600;
			background: #4caf50;
			text-align: center;
		}
	}
}